import libPhoneNumber from 'google-libphonenumber';
import { isValidPhoneNumber as isValidNumber } from 'react-phone-number-input';

const PNF = libPhoneNumber.PhoneNumberFormat;
const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

export const formatPhoneForE164 = (phoneNumber: string): string => {
  const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
  return phoneUtil.format(number, PNF.E164);
};

export const isValidPhoneNumber = (
  phoneNumber: string,
): boolean => isValidNumber(phoneNumber);

export const parsePhoneNumber = (
  phoneNumber?: string,
): {
  number?: number,
  code?: number,
} | null => {
  if (phoneNumber && isValidNumber(phoneNumber)) {
    return {
      number: phoneUtil.parseAndKeepRawInput(phoneNumber).getNationalNumber(),
      code: phoneUtil.parseAndKeepRawInput(phoneNumber).countryCodeCount(),
    };
  }

  return {
    code: undefined,
    number: undefined,
  };
};

import React from 'react';
// Protected route
import DashboardRoute from 'router/DashboardRoute';
import Home from 'pages/Dashboard/Home';
// Catalog pages
import Catalog from 'pages/Dashboard/Catalog';
import ItemDetailsPage from 'pages/Dashboard/Catalog/ItemDetailsPage';
// Transaction Pages
import Transaction from 'pages/Dashboard/Transaction';
import TransactionDetails from 'pages/Dashboard/Transaction/TransactionDetails';
// Order Pages
import OrdersDetails from 'pages/Dashboard/Orders/OrdersDetailsPage';
import Orders from 'pages/Dashboard/Orders';
// Directories Pages
import DirectoryPage from 'pages/Dashboard/DirectoryPage';
import DirectoryDetailsPage from 'pages/Dashboard/DirectoryDetailsPage';
// Next pages
import Profile from 'pages/Dashboard/Profile';
import Languages from 'pages/Dashboard/Languages';
import OrganisationsDetails from 'pages/Dashboard/OrganisationsDetails';

export const DashboardRoutePath = {
  HOME: '/dashboard',
  ORGANISATION: '/dashboard/:orgId/:reportName',
  TRANSACTIONS: '/dashboard/transactions',
  TRANSACTION: '/dashboard/transactions/:transactionId',
  CATALOG: '/dashboard/catalog',
  CATEGORIES: '/dashboard/catalog/categories',
  CATEGORY: '/dashboard/catalog/categories/:categoryName',
  ITEM: '/dashboard/catalog/items/:productId',
  ORDERS: '/dashboard/orders',
  ORDER: '/dashboard/orders/:orderId',
  PROFILE: '/dashboard/profile',
  LANGUAGES: '/dashboard/languages',
  ORGANIZATION: '/dashboard/organization',
  DIRECTORY: '/dashboard/directory',
  DIRECTORY_DETAILS: '/dashboard/directory/:clientId',
};

export const DashboardRoutes = [
  <DashboardRoute key="dashboard" exact path={DashboardRoutePath.HOME} component={Home} />,
  <DashboardRoute
    key="transaction2"
    exact
    path={DashboardRoutePath.TRANSACTION}
    component={TransactionDetails}
  />,
  <DashboardRoute key="order" exact path={DashboardRoutePath.ORDER} component={OrdersDetails} />,
  <DashboardRoute key="transaction" exact path={DashboardRoutePath.TRANSACTIONS} component={Transaction} />,
  <DashboardRoute key="directoryDetails" exact path={DashboardRoutePath.DIRECTORY_DETAILS} component={DirectoryDetailsPage} />,
  <DashboardRoute key="directory" exact path={DashboardRoutePath.DIRECTORY} component={DirectoryPage} />,
  <DashboardRoute key="dashboard2" exact path={DashboardRoutePath.ORGANISATION} component={Home} />,
  <DashboardRoute key="catalog" exact path={DashboardRoutePath.CATALOG} component={Catalog} />,
  <DashboardRoute key="orders" exact path={DashboardRoutePath.ORDERS} component={Orders} />,
  <DashboardRoute key="items2" exact path={DashboardRoutePath.ITEM} component={ItemDetailsPage} />,
  <DashboardRoute key="profile" exact path={DashboardRoutePath.PROFILE} component={Profile} />,
  <DashboardRoute key="languages" exact path={DashboardRoutePath.LANGUAGES} component={Languages} />,
  <DashboardRoute key="organization" exact path={DashboardRoutePath.ORGANIZATION} component={OrganisationsDetails} />,

];

import React from 'react';
import SwiperCore, {
  Navigation,
} from 'swiper';
import { useLoadScript } from '@react-google-maps/api';
// Routes
import Router from 'router';
// Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation]);

const libraries: ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[] = ['places'];

const App: React.FC = () => {
  useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_FIREBASE_KEY}`,
    language: 'en',
    libraries,
  });

  return (
    <>
      <Router />
    </>
  );
};

export default App;

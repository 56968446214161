import React from 'react';
import classNames from 'classnames';
//  Styles
import classes from './ShippingOption.module.scss';

interface Props {
  checked: boolean;
  onCheck: () => void;
  type: string;
  address?: string;
  fee_label: string;
  fee?: string;
  variant?: 'one' | 'two';
}

const ShippingOption: React.FC<Props> = ({
  checked = false, onCheck, address, type, fee_label, fee, variant,
}) => {
  const variantClassNames = classNames(classes.option, {
    [classes.option_selected]: checked,
  });

  if (variant === 'two') {
    return (
      <label className={variantClassNames}>
        <div className={classes.option_button}>
          <input
            checked={checked}
            type="checkbox"
            onChange={onCheck}
          />
          <div className={classes.option_description}>
            <div className={classes.option_description_title}>
              <span className={classes.option_description_title_type}>
                {type}
              </span>
            </div>
            <div className={classes.option_description_fee}>
              <span className={classes.option_description_fee_title}>
                {fee_label}
              </span>
            </div>
          </div>
          <div className={classes.option_radio_button}>
            <div className={classes.option_check} />
          </div>
        </div>
      </label>
    );
  }

  return (
    <label className={variantClassNames}>
      <div className={classes.option_button}>
        <input
          checked={checked}
          type="checkbox"
          onChange={onCheck}
        />
        <div className={classes.option_description}>
          <div className={classes.option_description_title}>
            <span className={classes.option_description_title_type}>
              {type}
            </span>
            {' '}
            <span className={classes.option_description_title_address}>
              {address}
            </span>
          </div>
          <div className={classes.option_description_fee}>
            <span className={classes.option_description_fee_title}>
              {fee_label}
            </span>
            {' '}
            <span className={classes.option_description_fee_value}>
              {fee}
            </span>
          </div>
        </div>
        <div className={classes.option_radio_button}>
          <div className={classes.option_check} />
        </div>
      </div>
    </label>
  );
};

export default ShippingOption;

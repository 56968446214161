/* eslint-disable no-undef */
import React, { ReactNode, useCallback, useState } from 'react';
import Select from 'react-select';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Utils
import { getDefaultCode } from 'utils/functions';
// Data
import phoneCodes from 'data/phone-codes.json';
import CountriesList from 'data/countries-list.json';
// Assets
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';
// Styles
import { customStyles } from './styles';
import classes from './CountrySelect.module.scss';

interface DropdownProps {
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
  readonly widthMenu?: string;
}

const Menu = (props: JSX.IntrinsicElements['div']) => (
  <div
    className={classes.menu}
    {...props}
  />
);

const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);

const Dropdown: React.FC<DropdownProps> = ({
  children,
  isOpen,
  target,
  onClose,
  widthMenu,
}) => (
  <div className={classes.dropdown}>
    {target}
    {isOpen && (
      <Menu style={{ width: widthMenu }}>
        <SearchIcon className={classes.search_icon} />
        {children}
      </Menu>
    )}
    {isOpen && <Blanket onClick={onClose} />}
  </div>
);

type Props = {
  control?: UseFormReturn['control'];
  defaultCountry?: {
    label: string;
    value: string;
  } | undefined;
  label?: string;
  className?: string;
  setValue: UseFormReturn['setValue'];
  addManually?: boolean;
  menuWidth?: string;
}

interface State {
  readonly isOpen: boolean;
  readonly value: {
    value: string;
    label: string;
  } | null | undefined;
}

const CountrySelect: React.FC<Props> = ({
  defaultCountry,
  control,
  setValue,
  addManually,
  label,
  menuWidth,
}) => {
  const [isOpen, setIsOpen] = useState<State>();
  const translations = useTranslationsStorefront();

  const toggleOpen = useCallback(() => {
    setIsOpen((state) => ({ isOpen: !state?.isOpen, value: state?.value }));
  }, []);

  return (
    <div className={classes.countries}>
      {label && (
        <label className={classes.customer_info_country_label}>
          {label}
        </label>
      )}
      <Controller
        control={control}
        defaultValue={defaultCountry}
        rules={{ required: true }}
        name="country"
        render={({
          field: {
            onChange, value, ref,
          },
        }) => (
          <Dropdown
            isOpen={!!isOpen?.isOpen}
            onClose={toggleOpen}
            widthMenu={menuWidth}
            target={(
              <button
                type="button"
                onClick={toggleOpen}
                className={isOpen?.isOpen ? classes.dropdown_btn_open : classes.dropdown_btn}
              >
                <p>
                  {value ? value.label : translations.checkout.card_form.select}
                </p>
                <div className={isOpen?.isOpen ? classes.triangle_open : classes.triangle} />
              </button>
            )}
          >
            <Select
              inputRef={ref}
              autoFocus
              menuIsOpen
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              placeholder={false}
              options={CountriesList}
              value={CountriesList.find((c) => c.value === value)}
              onChange={(val) => {
                onChange(val);
                toggleOpen();
                if (!addManually) {
                  setValue('code', `${getDefaultCode(phoneCodes, val?.value || 'US')}`);
                }
              }}
              components={{ DropdownIndicator: null, IndicatorSeparator: null }}
              styles={customStyles}
            />
          </Dropdown>
        )}
      />
    </div>
  );
};

export default CountrySelect;

import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
import React, {
  Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { auth } from 'services/firebase';
// Styles
import classes from './ResetPassword.module.scss';

interface Props {
  setActive: Dispatch<SetStateAction<boolean>>;
  handleCancelResetPassword: () => void;
}

const ResetPassword: React.FC<Props> = ({ setActive, handleCancelResetPassword }) => {
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const { fulfillment } = useTypeSelector(({ storefront }) => storefront.checkout);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const { checkout } = useTranslationsStorefront();
  const {
    register, reset, trigger, watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: useMemo(() => ({
      email: fulfillment.contact?.email,
    }), [fulfillment.contact?.email]),
  });

  const email = watch('email');

  const handleResetPassword = useCallback(async () => {
    if (email) {
      await auth.sendPasswordResetEmail(email);
      setEmailSent(true);
    }
  }, [email]);

  const partialEmail = useMemo(() => email?.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2'), [email]);

  useEffect(() => {
    reset({
      email: fulfillment?.contact?.email,
    });
    trigger();
  }, [fulfillment.contact?.email, reset, trigger]);

  if (emailSent) {
    return (
      <div className={classes.root}>
        <div>
          <HeaderModal onClick={() => setActive(false)} className={classes.header} />
          <div className={classes.content}>
            <p className={classes.title}> {checkout.reset_password.email_sent}</p>
            <p className={classes.subtitle}>
              {checkout.reset_password.email_sent_subtitle
                .replace('{StoreName}', currentSalesChannel?.name || '')
                .replace('{email}', partialEmail || '')}
            </p>
            <p className={classes.description}>
              {checkout.reset_password.email_sent_description}
            </p>
          </div>
        </div>
        <div className={`${classes.footer} ${classes.email_sent}`}>
          <button type="button" onClick={handleCancelResetPassword}>
            {checkout.notifications.ok}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div>
        <HeaderModal onClick={() => setActive(false)} className={classes.header} />
        <div className={classes.content}>
          <p className={classes.title}>{checkout.reset_password.title}</p>
          <p className={classes.subtitle}>
            {checkout.reset_password.subtitle.replace('{StoreName}', currentSalesChannel?.name || '')}
          </p>
          <input
            {...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: checkout.email_input.error,
              },
            })}
            placeholder={checkout.card_form.email_label}
            className={classes.input}
          />
          <p className={classes.text}>
            {checkout.reset_password.text}
          </p>
          <p className={classes.description}>
            {checkout.reset_password.description.replace('{StoreName}', currentSalesChannel?.name || '')}
          </p>
        </div>
      </div>
      <div className={classes.footer}>
        <button type="button" onClick={handleCancelResetPassword}>
          {checkout.reset_password.cancel_btn}
        </button>
        <button type="button" onClick={handleResetPassword}>
          {checkout.reset_password.reset_password_btn}
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;

import { useEffect, useState } from 'react';
import { remoteConfig } from 'services/firebase';

interface ReturnHookType {
  dynamicLink: string;
}

remoteConfig.defaultConfig = {
  'dynamicLink': 'https://leja.page.link/welcome',
};

export const useFirebaseRemoteConfig = (): ReturnHookType => {
  const [dynamicLink, setDynamicLink] = useState<string>('');

  const getRemoteConfigValue = async () => {
    try {
      await remoteConfig.fetchAndActivate();
      const val = await remoteConfig.getValue('dynamicLink');
      setDynamicLink(val.asString());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    getRemoteConfigValue();
  }, []);

  return {
    dynamicLink,
  };
};

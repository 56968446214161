import React, { useEffect } from 'react';
import DashboardLayout from 'layouts/DashboardLayout';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';
import { useUser } from 'hooks/useUser';
import {
  ordersGetSummary,
} from 'store/dashboard/orders/orderActions';
// Components
import OrdersTabs from './OrdersTabs';
// Styles
import classes from './Orders.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Orders: React.FC = () => {
  const user = useUser();
  const translations = useTranslations();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(ordersGetSummary(user.currentOrganisation.id));
    }
  }, [dispatch, user]);

  return (
    <DashboardLayout>
      <div className={classes.orders}>
        <OrdersTabs tabs={translations.orders.tabs} show={false} />
      </div>
    </DashboardLayout>
  );
};

export default Orders;

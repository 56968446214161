import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
// Hooks
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { ReactSelectTime } from 'types/globalTypes';
import { useUser } from 'hooks/useUser';
// Actions
import { getReportSelectCards, getSalesData } from 'store/dashboard/report/reportActions';
// Utils
import { getYear } from 'utils/dates';
// Types
import { ReportReq } from 'store/dashboard/report/reportTypes';
// Components
import Card from 'components/Card';
import CardSelect from 'components/CardSelect';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import Graph from 'components/Graph';
import CustomSpinner from 'components/CustomSpinner';
import MobileSalesTab from './MobileTab';
// Styles
import classes from './SalesTab.module.scss';

export interface Props {
  month?: string;
  value?: number;
}

export interface SalesChartData {
  month: string;
  value: number;
}

const SalesTab: React.FC<Props> = () => {
  const { isMobile, windowWidth } = useMobileScreen();
  const width = windowWidth && windowWidth <= 1388;
  const translations = useTranslations();
  const ts = translations.home.report;
  const user = useUser();
  const dispatch = useDispatch();
  const [time, setTime] = useState<ReactSelectTime>(ReactSelectTime.yesterday);

  const {
    reportSelectCards,
    salesChart,
    monthlyAverage,
    loading,
  } = useTypeSelector(({ dashboard }) => dashboard.report);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!user?.currentOrganisation?.id) {
      return;
    }

    dispatch(getReportSelectCards(user.currentOrganisation.id, time, ReportReq.sales));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, user]);

  const getTotal = () => salesChart.data.map((item) => item.value).reduce((a, b) => a + b, 0);
  const getTotalContacts = () => {
    if (!reportSelectCards?.paid_you || !reportSelectCards?.owes_you) {
      return '';
    }
    return `${reportSelectCards.paid_you.contacts + reportSelectCards.owes_you.contacts} ${translations.home.report.customers}`;
  };

  const months = useMemo(
    () => [
      ts.re_jan,
      ts.re_feb,
      ts.re_mar,
      ts.re_apr,
      ts.re_may,
      ts.re_jun,
      ts.re_jul,
      ts.re_aug,
      ts.re_sep,
      ts.re_oct,
      ts.re_nov,
      ts.re_dec,
    ],
    [
      ts.re_apr,
      ts.re_aug,
      ts.re_dec,
      ts.re_feb,
      ts.re_jan,
      ts.re_jul,
      ts.re_jun,
      ts.re_mar,
      ts.re_may,
      ts.re_nov,
      ts.re_oct,
      ts.re_sep],
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!user && !months) {
      return;
    }

    if (!user?.currentOrganisation?.id) {
      return;
    }

    dispatch(getSalesData(user.currentOrganisation.id, months));
  }, [user, months, dispatch]);

  if (loading) {
    return <CustomSpinner variant="two" />;
  }

  return isMobile ? (
    <MobileSalesTab
      total={getTotal()}
      time={time}
      setTime={setTime}
      translations={translations.home.report}
    />
  ) : (
    <div className={classes.content_container}>
      <div className={classes.chart_container}>
        <div className={classes.chart}>
          <div className={classes.chart_title}>
            <div className={classes.chart_years}>
              {getYear()} {ts.ytd}
            </div>
            <div className={getTotal() ? classes.chart_text : classes.chart_text_nodata}>
              {ts.tabs.re_sales}
            </div>
          </div>
          {!getTotal() ? (
            <div className={classes.nodata}>
              <div className={classes.lines}>
                <div className={classes.line} />
                <div className={classes.line} />
                <div className={classes.line} />
              </div>
              <p>{ts.no_data_available_yet}</p>
            </div>
          ) : (
            <Graph datasets={salesChart.data} color="rgba(0, 211, 200, 0.8)" name={ts.tabs.re_sales} isIso />
          )}
        </div>
      </div>
      <div className={classes.monthly_average}>
        <CardMonthlyAverage
          title={width ? 'Sales/mo' : ts.sales_report.month}
          value={monthlyAverage?.value}
          change={monthlyAverage?.percentage}
        />
      </div>
      <div className={classes.high}>
        <Card
          data={{
            title: ts.highest_month,
            label: salesChart.highestMonth?.month,
            value: salesChart.highestMonth?.value,
            second: ts.high,
            change: salesChart.highestMonth?.percentage,
          }}
        />
      </div>
      <div className={classes.low}>
        <Card
          data={{
            title: ts.lowest_month,
            label: salesChart.lowestMonth?.month,
            value: salesChart.lowestMonth?.value,
            second: ts.low,
            change: salesChart.lowestMonth?.percentage,
          }}
        />
      </div>
      <div className={classes.total}>
        <CardSelect
          data={{
            title: ts.all,
            value: reportSelectCards?.revenue?.entrytotal,
            label: getTotalContacts(),
            change: reportSelectCards?.revenue?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
      <div className={classes.paid}>
        <CardSelect
          data={{
            title: ts.sales_report.paid_invoice,
            value: reportSelectCards?.paid_you?.entrytotal,
            label: `${reportSelectCards?.paid_you?.contacts ? `${reportSelectCards?.paid_you?.contacts}  ${translations.home.report.customers}` : ''}`,
            change: reportSelectCards?.paid_you?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
      <div className={classes.unpaid}>
        <CardSelect
          data={{
            title: ts.sales_report.unpaid_invoice,
            value: reportSelectCards?.owes_you?.entrytotal,
            label: `${reportSelectCards?.owes_you?.contacts ? `${reportSelectCards?.owes_you?.contacts}  ${translations.home.report.customers}` : ''}`,
            change: reportSelectCards?.owes_you?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
    </div>
  );
};

export default SalesTab;

import React from 'react';
// Assets
import { ReactComponent as CloseIcon } from 'assets/dashboard-icons/close-icon.svg';
// Styles
import classes from './SettingsPage.module.scss';

interface Props {
  handleClose: () => void;
  title?: string;
}

const SettingsPage: React.FC<Props> = ({ handleClose, children, title }) => (
  <div className={classes.settings}>
    <div className={classes.settings_wrapper}>
      <div className={classes.settings_close_btn}>
        <CloseIcon
          className={classes.settings_close_btn_icon}
          onClick={handleClose}
        />
      </div>
      <h1 className={classes.settings_title}>
        {title}
      </h1>
      {children}
    </div>
  </div>
);

export default SettingsPage;

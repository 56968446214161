import React from 'react';
import classNames from 'classnames';
// Assets
import { ReactComponent as Spinner } from 'assets/storefront-icons/spinner/Group2.svg';
import { ReactComponent as Spinner2 } from 'assets/storefront-icons/spinner/Group.svg';
import { ReactComponent as SpinnerSmall } from 'assets/icons/spinner-small.svg';

// Styles
import classes from './CustomSpinner.module.scss';

interface Props {
  size?: 'small' | 'medium';
  variant?: 'one' | 'two';
}

const CustomSpinner: React.FC<Props> = ({ size = 'medium', variant = 'one' }) => {
  const styles = classNames({
    [classes['lds-dual-ring']]: size === 'medium',
    [classes['lds-dual-ring-small']]: size === 'small',
    [classes['lds-dual-ring_two']]: size === 'medium' && variant === 'two',
    [classes['lds-dual-ring-small_two']]: size === 'small' && variant === 'two',
  });

  if (size === 'small') {
    return (
      <div className={classes.container}>
        <div className={styles}>
          <SpinnerSmall />
        </div>
      </div>
    );
  }

  if (variant === 'two') {
    return (
      <div className={classes.container_two}>
        <div>
          <Spinner />
          <Spinner2 />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={styles} />
    </div>
  );
};

export default CustomSpinner;

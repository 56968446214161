import React from 'react';
import classNames from 'classnames';
// Assets
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// Styles
import classes from './HeaderModal.module.scss';

interface Props {
  className?: string;
  onClick?: () => void;
  LeftArrowClick?: () => void;
}

const HeaderModal: React.FC<Props> = ({ className, onClick, LeftArrowClick }) => {
  const style = classNames(className, classes.header, className);

  return (
    <div className={style}>
      <CloseIcon
        role="button"
        className={classes.close_icon}
        onClick={onClick}
      />
      <LeftArrowIcon
        role="button"
        className={classes.left_arrow_icon}
        onClick={LeftArrowClick || onClick}
      />
    </div>
  );
};

export default HeaderModal;

/* eslint-disable no-nested-ternary */
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
// Styles
import classes from './SummaryBarChart.module.scss';

const SummaryBarChart: React.FC = () => {
  const { summary } = useTypeSelector(({ order }) => order);
  const translations = useTranslations();
  return (
    <div className={classes.card}>
      <h3 className={classes.title}>{translations.orders.top_order_channels}</h3>
      <div className={classes.progress_list}>
        {summary?.locations.length ? summary.locations.map((salesChannel) => (
          <div className={classes.progress_list_item} key={uuidv4()}>
            <p className={classes.percent}>{`${salesChannel.percentage.toFixed()}%`}</p>
            <div className={classes.progress_bar}>
              <span style={{ width: `${salesChannel.percentage.toFixed()}%` }} />
            </div>
            <p className={classes.title_progress}>
              {salesChannel.saleschannel === 'Whatsapp'
                ? salesChannel.saleschannel.replace('Whatsapp', 'WhatsApp')
                : salesChannel.saleschannel
                  ? salesChannel.saleschannel
                  : translations.orders.other}
            </p>
          </div>
        )) : (
          <div className={classes.nodata}>{translations.home.report.no_data_available_yet}</div>
        )}
      </div>
    </div>
  );
};

export default SummaryBarChart;

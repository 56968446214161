import React from 'react';
// Styles
import classes from './Notes.module.scss';

interface Props {
  title: string;
  notes: string | null;
}

const Notes: React.FC<Props> = ({ notes, title }) => {
  if (!notes) {
    return (
      null
    );
  }

  return (
    <div className={classes.notes}>
      <p className={classes.notes_title}>
        {title}
      </p>
      <p className={classes.notes_text}>
        {notes}
      </p>
    </div>
  );
};

export default Notes;

import { PayMethodsEnum } from 'constants/paymentsMethods';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import React, { useCallback } from 'react';
import StripeLogo from 'assets/images/stripe-logo.png';
// Styles
import classes from './PaymentCard.module.scss';

interface Props {
  title: string;
  subtitle: string;
  logos?: string[];
  isStripe: boolean;
  btnTitle: string;
  handlePay?: (paymentMethodTypes: PayMethodsEnum) => Promise<void>
  handleClick?: () => void;
  type?: PayMethodsEnum
  text?: string;
}

const PaymentCard: React.FC<Props> = ({
  title, subtitle, logos, isStripe, btnTitle, text, children, handlePay, type, handleClick,
}) => {
  const translations = useTranslationsStorefront();

  const handleButton = useCallback(() => {
    if (type && handlePay) {
      handlePay(type);
    }

    if (handleClick) {
      handleClick();
    }
  }, [handleClick, handlePay, type]);

  return (
    <div className={classes.card}>
      <div className={classes.card_left}>
        <div className={classes.card_left_title}>
          {title} {' '}
          <span>
            {subtitle}
          </span>
          <p>
            {text}
          </p>
        </div>
        <div className={classes.logos}>
          {logos?.map(
            (logo) => (
              <React.Fragment key={logo}>
                <img src={logo} alt={logo} />
              </React.Fragment>
            ),
          )}
          {children}
        </div>
        {isStripe && (
          <div className={classes.stripe_text}>
            <img src={StripeLogo} alt="stripe" />
            <span>
              {translations.checkout.payment_page.stripe}
            </span>
          </div>
        )}
      </div>
      <div className={classes.card_right}>
        <button type="button" className={classes.pay_btn} onClick={handleButton}>
          {btnTitle}
        </button>
      </div>
    </div>
  );
};

export default PaymentCard;

import React, {
  useState, useEffect, useCallback,
} from 'react';
import ReactCodeInput from 'react-code-input';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useDispatch } from 'react-redux';
// Assets
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Spinner } from 'assets/icons/spinner-small.svg';
// Actions
import {
  setAuthByOTP,
  setCodeConfirm,
  setAuthError,
} from 'store/auth/authActions';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Styles
import classes from './LoginPhone.module.scss';
import MessageError from '../MessageError';

interface Props {
  countryPhoneCode: string | null;
  phoneNumber: string;
  authSmsCode: string;
  setAuthSmsCode: (value: string) => void;
  translations: TranslationDashboardModel['login'];
}

const LoginPhone: React.FC<Props> = ({
  countryPhoneCode,
  phoneNumber,
  authSmsCode,
  setAuthSmsCode,
  translations,
}) => {
  const dispatch = useDispatch();
  const { loading, error } = useTypeSelector((state) => state.auth);
  const [resendCode, setResendCode] = useState(false);
  const [pin, setPin] = useState('');
  const [key, setKey] = useState(1);
  const [seconds, setSeconds] = useState(16);
  const tsLoginPhone = translations.login_phone;
  useEffect(() => {
    const timer = setTimeout(() => {
      setResendCode(false);
      setSeconds(15);
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, [resendCode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setAuthError(null));
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  const handleResendCode = useCallback(() => {
    setResendCode(true);
    dispatch(setAuthByOTP(`${countryPhoneCode}${phoneNumber}`));
  }, [countryPhoneCode, dispatch, phoneNumber]);

  const propsReactCodeInput = {
    className: classes.react_code_input,
  };

  const handlePinChange = (pinCode: string) => {
    setPin(pin);
    dispatch(setAuthError(null));
    setAuthSmsCode(pinCode);
  };

  const handleError = () => {
    dispatch(setAuthError(null));
  };

  const handleCloseResendCode = () => {
    setResendCode(false);
    setSeconds(15);
  };

  useEffect(() => {
    if (authSmsCode.length === 6) {
      setPin('');
      setKey(key + 1);
      dispatch(setCodeConfirm(authSmsCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authSmsCode]);

  useEffect(() => {
    if (pin.length === 0) {
      setKey(key + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  return (
    <div className={classes.root}>
      <form className={classes.login}>
        <h1>{tsLoginPhone.login_code}</h1>
        <div className={classes.login_subtitle}>
          <p className={classes.login_text}>
            {tsLoginPhone.this_is_the_digit}
          </p>
          <p className={classes.login_phone}>
            {countryPhoneCode} {phoneNumber}
          </p>
        </div>
        <ReactCodeInput
          name="pinCode"
          type="number"
          fields={6}
          onChange={handlePinChange}
          inputMode="numeric"
          value={pin}
          key={key}
          disabled={loading || !!error}
          {...propsReactCodeInput}
        />
        {(loading || error) && (
          <div className={classes.disabled_input}>
            {authSmsCode.split('').map((s, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <p className={classes.disabled_input_letter} key={i}>
                {s}
              </p>
            ))}
          </div>
        )}
        {!loading && !error && !resendCode && (
          <div className={classes.login_support}>
            {seconds ? (
              <p
                style={{ color: 'rgba(255, 207, 38, 1)' }}
                className={classes.login_support_text}
              >
                {tsLoginPhone.resend_code_seconds.replace('{sec}', `${Math.floor(seconds / 10)}${seconds % 10}` || '00')}
              </p>
            ) : (
              <p
                className={classes.login_support_text}
                style={{ color: 'rgba(255, 255, 255, 1)' }}
              >
                {tsLoginPhone.having_issues}
              </p>
            )}
            <button
              type="button"
              onClick={handleResendCode}
              disabled={!!seconds}
            >
              {tsLoginPhone.resend}
            </button>
          </div>
        )}
        <MessageError
          handleError={handleError}
          error={error}
          translations={translations.messages_error}
        />
        {loading && !resendCode && (
          <div className={classes.login_loader}>
            <div className={classes.login_loader_spinner}>
              <Spinner />
            </div>
            <p className={classes.login_loader_text}>
              {tsLoginPhone.verifying_login_code}
            </p>
          </div>
        )}
        {resendCode && (
          <div className={classes.msg_fail} style={{ backgroundColor: 'rgba(107, 89, 237, 1)' }}>
            <div className={classes.msg_fail_top}>
              <p className={classes.msg_fail_top_text}>
                {tsLoginPhone.code_resent}
              </p>
              <Close onClick={handleCloseResendCode} />
            </div>
            <p className={classes.msg_fail_text}>
              {tsLoginPhone.check_code}
            </p>
          </div>
        )}
      </form>
    </div>
  );
};

export default LoginPhone;

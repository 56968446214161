import React, { useState, useEffect, useRef } from 'react';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useUser } from 'hooks/useUser';
import { useDispatch } from 'react-redux';
// D3
import {
  select, scaleBand, scaleLinear, stack, max, axisBottom,
} from 'd3';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { calcLayer, yAixDraw } from 'utils/functions';
// Types
import { DataChartStacked } from 'store/dashboard/report/reportTypes';
// Actions
import { changeAverageMonth } from 'store/dashboard/report/reportActions';
// Classes
import classes from './StackedGraph.module.scss';

interface Props {
  datasets: DataChartStacked[];
  keys: string[];
  colors: Record<number, string>;
  names: {
    first: string;
    second: string;
  };
  isIso?: boolean;
}

const StackedGraph: React.FC<Props> = ({
  datasets,
  keys,
  colors,
  names,
  isIso,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const xAxisRef = useRef<SVGSVGElement>(null);
  const yAxisRef = useRef<SVGSVGElement>(null);
  const [check, setCheck] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [toolTip, setToolTip] = useState<{
    month: string;
    value: DataChartStacked[];
  }>();
  const { isMobile } = useMobileScreen();
  const iso = (value?: number) => (isIso ? formatCurrencyByISO(value) : value);
  const user = useUser();
  const formatCurrencyByISO = CurrencyFormatByISO(
    user?.currentOrganisation?.country?.currencyISO,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!svgRef.current || !wrapperRef.current) {
      return;
    }

    const { width, height } = wrapperRef.current.getBoundingClientRect();
    const svg = select(svgRef.current);
    const x0Scale = scaleBand()
      .domain(datasets.map((d) => d.month))
      .range([0, width])
      .padding(0.7);

    const stackGenerator = stack().keys(keys);
    const datasetsIterable = datasets[Symbol.iterator]();
    const layers = stackGenerator(
      datasetsIterable as Iterable<Record<string, number>>,
    );

    const getMax = max(layers, (layer) => max(layer, (sequence) => sequence.data.value))
      || 0;

    const extent = [0, getMax];
    const yScale = scaleLinear().domain(extent).rangeRound([height, 50]);
    const xAxis = axisBottom(x0Scale).tickFormat((d) => (isMobile ? d[0] : d.substring(0, 3)));

    if (!xAxisRef.current || !yAxisRef.current) {
      return;
    }

    const svgXAxis = select(xAxisRef.current);
    const svgYAxis = select(yAxisRef.current);
    const x1Scale = scaleBand()
      .domain(datasets.map((d) => String(d.type)))
      .rangeRound([0, 20]);

    svg.on('click', () => {
      if (!check) {
        setShowToolTip(false);
        svg
          .selectAll('.layer')
          .data(layers)
          .join('g')
          .attr('class', 'layer')
          .selectAll('rect')
          .data(calcLayer)
          .join('rect')
          .attr('fill', (seq) => (seq.data.value === 0
            ? 'rgba(203, 198, 215, 0.1)'
            : colors[seq.data.type]))
          .attr('x', (sequence) => +(x0Scale(sequence?.data?.month) || 0) + 10)
          .attr('width', x1Scale.bandwidth())
          .attr('style', 'outline: none;');
      }
      setCheck(false);
    });

    svgXAxis
      .call(xAxis)
      .call((g) => g.select('.domain').remove())
      .attr(
        'style',
        `font-size: ${
          isMobile ? '9px' : '10px'
        }; color: rgba(107, 105, 116, 1); font-weight: 700; line-height: 20px;`,
      )
      .attr('transform', `translate(8, ${height - 19})`);

    svgYAxis
      .attr(
        'style',
        `
        font-size: 12px;
        color: rgba(203, 198, 215, 1);
        font-weight: 600;
        line-height:18px;
        display: ${isMobile ? 'none' : 'initial'}
        `,
      )
      .attr('transform', 'translate(20,-45)')
      .call(yAixDraw(yScale))
      .call((g) => g.select('.domain').remove());

    svg
      .selectAll('.layer')
      .data(layers)
      .join('g')
      .attr('class', 'layer')
      .selectAll('rect')
      .data(calcLayer)
      .join('rect')
      .attr('fill', (seq) => (seq.data.value === 0
        ? 'rgba(203, 198, 215, 0.1)'
        : colors[seq.data.type]))
      .attr('x', (sequence) => +(x0Scale(sequence.data.month) || 0) + 10)
      .attr('width', x1Scale.bandwidth())
      .on('click', (e, seq) => {
        setShowToolTip(true);
        setCheck(true);
        dispatch(
          changeAverageMonth({
            selectedMonth: seq.data,
            datasets,
          }),
        );

        setToolTip({
          month: seq.data.month,
          value: datasets
            .filter((item) => item.month === seq.data.month)
            .sort((a, b) => (a?.type || 0) - (b?.type || 0)),
        });

        svg
          .selectAll('.layer')
          .data(layers)
          .join('g')
          .attr('class', 'layer')
          .selectAll('rect')
          .data(calcLayer)
          .join('rect')
          .attr('fill', (s) => (s.data.value === 0 ? '#F8F8F8' : colors[s.data.type]))
          .attr('style', (s) => {
            if (s.data.month === seq.data.month) {
              const maxV = datasets
                .filter((item) => item.month === s.data.month)
                .reduce((prev, current) => (prev.value > current.value ? prev : current)).value;
              if (s.data.value === maxV) {
                return 'outline: 4px solid rgba(255, 199, 38, 0.4); border-radius: 10px; transition: all 0.1s ease-out;';
              }
            }
            return '';
          })
          .attr(
            'x',
            (sequence) => (x0Scale(`${sequence?.data?.month} `) || 0) + 10,
          )
          .attr('width', x1Scale.bandwidth());
      })
      .transition()
      .duration(800)
      .attr('y', (sequence) => {
        if (sequence.data.extra) {
          return sequence.data.value === 0
            ? height - 45
            : yScale(sequence.data.value) - 44;
        }

        return sequence.data.value === 0
          ? height - 45
          : yScale(sequence.data.value) - 41;
      })
      .attr('height', (sequence) => {
        if (sequence.data.extra) {
          return sequence.data.value === 0
            ? 5
            : Math.abs(height - yScale(sequence.data.value));
        }

        return sequence.data.value === 0
          ? 5
          : height - yScale(sequence.data.value);
      })
      .attr('rx', (sequence) => {
        if (sequence.data.extra) {
          return 0;
        }
        return 4.75;
      })
      .delay((d, i) => i * 100);

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', height - 20)
      .attr('height', '0.01px')
      .attr('width', width)
      .style('stroke', '#EEEAFC')
      .style('fill', 'none');
  }, [datasets, showToolTip, check, keys, colors, isMobile, dispatch]);

  return (
    <div ref={wrapperRef} className={classes.graph_wrapper}>
      {showToolTip && (
      <div className={classes.tooltip}>
        <p className={classes.tooltip_month}>
          {toolTip?.month.substring(0, 3)}:
        </p>
        <div className={classes.tooltip_container_first}>
          <p className={classes.tooltip_container_first_name}>
            {names.first}
          </p>
          <p
            className={classes.tooltip_container_first_value}
            style={{ color: colors[2] }}
          >
            {iso(
              toolTip?.value[1].value
                ? (Math.trunc(toolTip?.value[1].value) * 100) / 100
                : 0,
            )}
          </p>
        </div>
        <div className={classes.tooltip_container_second}>
          <p className={classes.tooltip_container_second_name}>
            {names.second}
          </p>
          <p
            className={classes.tooltip_container_second_value}
            style={{ color: colors[1] }}
          >
            {iso(toolTip?.value[0].value)}
          </p>
        </div>
      </div>
      )}
      <svg ref={svgRef} style={{ width: '100%', height: '100%' }}>
        <g ref={xAxisRef} className="x-axis" />
        <g ref={yAxisRef} className="y-axis" />
      </svg>
    </div>
  );
};

export default StackedGraph;

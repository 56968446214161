import React from 'react';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Components
import StorefrontLayout from 'layouts/StorefrontLayout';
// Styles
import classes from './About.module.scss';

const About: React.FC = () => {
  const translations = useTranslationsStorefront();
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  return (
    <div className={classes.about_us}>
      <StorefrontLayout type="two" />
      <div className={classes.about_us_title}>{`${translations.about_page.navbar_title} ${currentSalesChannel?.name}`}</div>
      <div className={classes.about_us_content}>
        {currentSalesChannel?.description ? currentSalesChannel?.description.replace('\n', '\n\n\n') : ''}
      </div>
    </div>
  );
};

export default About;

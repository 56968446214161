import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTypeSelector } from 'hooks/useTypeSelector';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { useUser } from 'hooks/useUser';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { DashboardRoutePath } from 'router/Routes/Dashboard';
import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
import useOnClickOutside from 'hooks/useOnClickOutside';
// Assets
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// Types
import { SearchTypes } from 'store/dashboard/app/appTypes';
// Actions
import { getSearchResult, searchLoadingAction, setSearchResult } from 'store/dashboard/app/appActions';
// Components
import CustomSpinner from 'components/CustomSpinner';
import SearchResultList from './SearchResultList';
import SearchTags from './SearchTags';
// Classes
import classes from './Search.module.scss';

const Search: React.FC = () => {
  const location = useLocation();
  const searchRef = useRef(null);
  const { isMobile } = useMobileScreen();

  const dispatch = useDispatch();
  const { getDashboardHomeUrl } = useDashboardRoutes();
  const user = useUser();
  const [searchValue, setSearchValue] = useState('');
  const [focusInput, setFocusInput] = useState(false);
  const query = useQuery();
  const basePath = location.pathname.split('/');
  const [tags, setTags] = useState('');
  const typeQuery = query.get('type');
  const [page, setPage] = useState(1);
  const history = useHistory();
  const { activeTranslation, search } = useTypeSelector(({ dashboard }) => dashboard.app);
  const { search: tsSearch } = activeTranslation;
  const showDetailsMobileSearch = location.pathname === `/dashboard/transaction/${location.pathname.match(/\d+/)?.[0]}` && isMobile;
  const showOrdersMobile = location.pathname === `/dashboard/orders/${location.pathname.match(/\d+/)?.[0]}` && isMobile;
  const isOtherSearch = location.pathname !== getDashboardHomeUrl(basePath[2], basePath[3]);
  const isTransactionsPage = location.pathname === DashboardRoutePath.TRANSACTIONS;
  const isDirectoriesPage = location.pathname === DashboardRoutePath.DIRECTORY;
  const isItemsPage = location.pathname === DashboardRoutePath.CATALOG;
  const isOrdersPage = location.pathname === DashboardRoutePath.ORDERS;

  const searchStyles = classNames({
    [classes.search]: !focusInput,
    [classes.search_active]: focusInput,
    [classes.search_active_tags]: tags && focusInput,
  });

  const placeholderInput = useMemo(() => {
    if (typeQuery === SearchTypes.documents || isTransactionsPage) {
      return tsSearch.placeholder.transactions;
    }

    if (typeQuery === SearchTypes.contacts || isDirectoriesPage) {
      return tsSearch.placeholder.directory;
    }

    if (typeQuery === SearchTypes.products || isItemsPage) {
      return tsSearch.placeholder.catalog;
    }

    if (typeQuery === SearchTypes['sales-orders'] || isOrdersPage) {
      return tsSearch.placeholder.orders;
    }

    if (focusInput) {
      return isMobile ? tsSearch.placeholder.all_mobile : tsSearch.placeholder.all;
    }

    return tsSearch.title;
  }, [typeQuery,
    isTransactionsPage,
    isDirectoriesPage, isItemsPage, isOrdersPage, focusInput, tsSearch, isMobile]);

  const searchTags = useMemo(() => [{
    name: tsSearch.filters.transactions,
    value: SearchTypes.documents,
    icon: 'transactions',
  }, {
    name: tsSearch.filters.catalog,
    value: SearchTypes.products,
    icon: 'catalog',
  }, {
    name: tsSearch.filters.orders,
    value: SearchTypes['sales-orders'],
    icon: 'orders',
  }, {
    name: tsSearch.filters.directory,
    value: SearchTypes.contacts,
    icon: 'directory',
  }], [tsSearch]);

  const searchHandler = useCallback(
    (e, type) => {
      if (user?.currentOrganisation?.id && e.target.value.length >= 3) {
        if (type && !isOtherSearch) {
          setPage(1);
          dispatch(getSearchResult(e.target.value || '',
            (type as SearchTypes) || '',
            user?.currentOrganisation?.id));
        }

        if (isTransactionsPage) {
          setPage(1);
          dispatch(getSearchResult(e.target.value || '',
            SearchTypes.documents,
            user?.currentOrganisation?.id));
        }

        if (isDirectoriesPage) {
          setPage(1);
          dispatch(getSearchResult(e.target.value || '',
            SearchTypes.contacts,
            user?.currentOrganisation?.id));
        }

        if (isItemsPage) {
          setPage(1);
          dispatch(getSearchResult(e.target.value || '',
            SearchTypes.products,
            user?.currentOrganisation?.id));
        }

        if (isOrdersPage) {
          setPage(1);
          dispatch(getSearchResult(e.target.value || '',
            SearchTypes['sales-orders'],
            user?.currentOrganisation?.id));
        }
      }
    },
    [dispatch, isDirectoriesPage,
      isItemsPage,
      isOrdersPage, isOtherSearch, isTransactionsPage, user?.currentOrganisation?.id],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(searchHandler, 1000), []);

  const handleBlur = useCallback(() => {
    setFocusInput(false);
    setSearchValue('');
    query.delete('search');
    query.delete('type');
    setTags('');
    history.replace({
      search: query.toString(),
    });
  }, [history, query]);

  useOnClickOutside(searchRef, handleBlur);

  const handleFocus = useCallback(() => {
    setFocusInput(true);
  }, []);

  const handleClose = useCallback(() => {
    setSearchValue('');
    setFocusInput(true);
    query.delete('search');
    history.replace({
      search: query.toString(),
    });
  }, [history, query]);

  const deleteTag = useCallback(() => {
    setTags('');

    if (query.has('type')) {
      query.delete('type');
      query.delete('search');
      setSearchValue('');
      history.replace({
        search: query.toString(),
      });
    }
  }, [history, query]);

  const handleChangeInput = useCallback(
    (e) => {
      setSearchValue(e.target.value);

      if (!query.has('search')) {
        query.append('search', e.target.value);
      } else {
        query.set('search', e.target.value);
      }

      if (!e.target.value) {
        query.delete('search');
      }

      dispatch(searchLoadingAction('LoadingItems'));
      debounceFn(e, query.get('type'));

      history.replace({
        search: query.toString(),
      });
    },
    [debounceFn, dispatch, history, query],
  );

  useEffect(() => {
    if (query.has('type')) {
      const findTag = searchTags.find((tag) => tag.value === query.get('type'));
      setTags(findTag?.name || '');
    }

    if (query.has('search')) {
      setSearchValue(query.get('search') || '');
    }
  }, [query, searchTags]);

  useEffect(() => {
    if (
      query.has('type')
      && user?.currentOrganisation?.id
      && query.get('search') && (query.get('search')?.length || 0) >= 3) {
      dispatch(getSearchResult(query.get('search') || '',
        (query.get('type') as SearchTypes) || '',
        user?.currentOrganisation?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!query.has('search')) {
      dispatch(setSearchResult([]));
    }
  }, [dispatch, query]);

  if (showDetailsMobileSearch && showOrdersMobile) {
    return null;
  }

  return (
    <div className={classes.search_wrapper} ref={searchRef}>
      <div className={searchStyles}>
        {tags && (
          <div className={classes.select_tag_wrapper}>
            <div className={classes.select_tag}>
              {tags}
              <CloseIcon
                onClick={deleteTag}
                className={classes.select_tag_close_icon}
              />
            </div>
          </div>
        )}
        <div className={searchValue
          ? classes.search_input_wrapper_active
          : classes.search_input_wrapper}
        >
          <input
            type="text"
            value={searchValue}
            onChange={handleChangeInput}
            onFocus={handleFocus}
            placeholder={placeholderInput}
          />
          <SearchIcon className={classes.search_icon} />
          {focusInput
            && (
              <CloseIcon
                className={
                  searchValue ? classes.close_icon_active : classes.close_icon
                }
                onClick={handleClose}
              />
            )}
        </div>
      </div>
      {!isOtherSearch && (
        <SearchTags
          searchTags={searchTags}
          focusInput={focusInput}
          setFocusInput={setFocusInput}
        />
      )}

      <div className={searchValue.length >= 3 && (query.has('type') || isOtherSearch)
        ? classes.search_result_active
        : classes.search_result}
      >
        {search.loading === 'LoadingItems' && (
          <div className={classes.loading_wrapper}>
            <CustomSpinner variant="two" />
            <span>{tsSearch.loading_text}</span>
          </div>
        )}

        {search.loading !== 'LoadingItems' && !!search.data?.length && searchValue.length >= 3
          && (
            <SearchResultList
              data={search.data}
              loading={search.loading}
              hasNextPage={search.hasNextPage}
              page={page}
              setPage={setPage}
            />
          )}

        {!search.data?.length && !search.loading && (
          <div className={classes.no_items}>
            {tsSearch.no_result_text}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;

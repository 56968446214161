import { PaymentIntentResult, Stripe } from '@stripe/stripe-js';

export const giropay = async (
  clientSecret: string,
  stripe: Stripe,
  name: string,
  returnUrl: string,
): Promise<PaymentIntentResult> => {
  const payload = await stripe.confirmGiropayPayment(
    clientSecret,
    {
      payment_method: {
        billing_details: {
          name,
        },
      },
      return_url: returnUrl,
    },
  );
  return payload;
};

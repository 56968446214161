import { Stripe, StripeError } from '@stripe/stripe-js';

export const alipay = async (
  clientSecret: string, stripe: Stripe,
): Promise<StripeError | undefined> => {
  const { error } = await stripe.confirmAlipayPayment(
    clientSecret,
    {
      return_url: window.location.href,
    },
  );
  return error;
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
// Constants
import { DirectoryTabsEnum, DirectoryTabsMixPanel } from 'constants/directory';
// Actions
import { SetPrevDirectoryTab } from 'store/dashboard/app/appActions';
// Utils
import { mixpanelActions } from 'utils/mixpanel';
// Components
import DirectoryTab from './DirectoryTab/DirectoryTab';
// Styles
import classes from './DirectoryTabs.module.scss';

interface Props {
  tabs: string[],
}

const DirectoryTabs: React.FC<Props> = ({ tabs }) => {
  const [prevTab, setPrevTab] = useState<string>(DirectoryTabsMixPanel[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    mixpanelActions.time_event('View Directory / All Page');
    setPrevTab(DirectoryTabsMixPanel[0]);
    dispatch(SetPrevDirectoryTab(DirectoryTabsMixPanel[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs
      selectedTabPanelClassName={classes.react_tabs__tab_panel__selected}
      selectedTabClassName={classes.react_tabs__tab__selected}
      className={classes.react_tabs}
      onSelect={(index) => {
        if (prevTab) {
          mixpanelActions.track(`View Directory / ${prevTab} Page`, {
            'Page Name': `Directory - ${prevTab}`,
          });
        }
        mixpanelActions.time_event(`View Directory / ${DirectoryTabsMixPanel[index]} Page`);
        setPrevTab(DirectoryTabsMixPanel[index]);
        dispatch(SetPrevDirectoryTab(DirectoryTabsMixPanel[index]));
      }}
    >
      <TabList className={classes.react_tabs__tab_list}>
        {tabs.map((tab) => (
          <Tab key={tab} className={classes.react_tabs__tab}>
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanel>
        <DirectoryTab name={DirectoryTabsEnum.ALL} />
      </TabPanel>
      <TabPanel>
        <DirectoryTab name={DirectoryTabsEnum.CUSTOMERS} />
      </TabPanel>
      <TabPanel>
        <DirectoryTab name={DirectoryTabsEnum.VENDORS} />
      </TabPanel>
    </Tabs>
  );
};

export default DirectoryTabs;

import { StylesConfig } from 'react-select';
import { ReactSelectOption } from 'types/globalTypes';

type IsMulti = false;

export const customStyles: StylesConfig<ReactSelectOption, IsMulti> = {

  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 1)',
    background: 'transparent',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: 'Visuelt Pro',
    lineHeight: '18px',
    fontWeight: 700,
    outline: 'none',
    '&:hover': {
      opacity: '0.5',
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '@media only screen and (max-width: 450px)': {
      fontSize: '12px',
      lineHeight: '12px',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    padding: '3px 0',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Visuelt Pro',
    color: 'rgba(25, 28, 35, 1)',
  }),
  control: () => ({
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    padding: '0px 20px',
    border: '1px solid rgba(221, 227, 232, 1)',
    borderRadius: '3px',
  }),
  container: (provided, state) => ({
    position: 'relative',
    width: '160px',
    height: '60px',
    fontSize: '16px',
    cursor: 'pointer',
    '&:hover': {
      opacity: state.selectProps.menuIsOpen ? 'inherit' : '0.5',
    },
    '@media only screen and (max-width: 450px)': {
      width: '100%',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    background: 'linear-gradient(55.52deg, #7233CC -5.2%, #6B59ED 65.07%)',
    borderRadius: '4px',
    border: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    width: '180px',
    right: '0px',
    padding: 0,
    transition: 'all 0.5s',
    marginTop: 6,
    borderRadius: '4px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'rgba(107, 89, 237, 1)',
    transition: 'all 0.5s',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    '@media only screen and (max-width: 450px)': {
      svg: {
        width: '20px',
        height: '20px',
      },
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px',
    fontFamily: 'Visuelt Pro',
    lineHeight: '24px',
    fontWeight: 400,
    color: 'rgba(25, 28, 35, 0.3)',
    '@media only screen and (max-width: 772px)': {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
  }),

  indicatorSeparator: () => ({}),
};

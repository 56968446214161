import React, { useState, useRef, useCallback } from 'react';
import cn from 'classnames';
import { Controller, UseFormReturn } from 'react-hook-form';
// Phone codes
import phoneCodes from 'data/phone-codes.json';
// Types
import { PhoneCode } from 'types/globalTypes';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Hooks
import useOnClickOutside from 'hooks/useOnClickOutside';
// Components
import FormError from 'components/StorefrontComponents/FormError';
import FormInfo from 'components/StorefrontComponents/FormInfo';
// Styles
import classes from './PhoneInput.module.scss';

interface Props {
  countryIso: string | null;
  translations: TranslationStorefrontModel['checkout']['phone_input']
  placeholder?: string;
  control?: UseFormReturn['control']
  isValidPhoneNumber?: boolean;
}

const PhoneInput: React.FC<Props> = ({
  countryIso,
  translations,
  placeholder,
  control,
  isValidPhoneNumber,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [countryPhones, setCountryPhones] = useState<PhoneCode[]>(phoneCodes);
  const [info, setInfo] = useState<boolean>(false);

  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => {
    setOpen((prev) => !prev);
  });

  const handlerCodeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setSearch(event.target.value);
    }

    if (!(/^[a-zA-Z0-9]+$/i.test(event.target.value))) {
      return;
    }

    setSearch(event.target.value);
    if (!Number.isNaN(Number(event.target.value))) {
      const countryPhoneCodes = phoneCodes.filter((item) => (
        item.dial_code.toLowerCase().match(String(event.target.value))
      ));

      setCountryPhones(countryPhoneCodes);
      return;
    }

    const countryName = event.target.value.trim().toLowerCase();
    const countryPhoneCodes = phoneCodes.filter((item) => (
      item.name.toLowerCase().match(countryName)
    ));

    setCountryPhones(countryPhoneCodes);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <div className={classes.phone__input_wrapper}>
      <div className={classes.phone__input}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="code"
          defaultValue=""
          render={({
            field: {
              value, ref, onChange,
            },
          }) => (
            <>
              <button
                tabIndex={-1}
                className={cn(classes.phone__input_dropdown_btn, {
                  [classes.phone__input_dropdown_btn_active]: countryIso,
                })}
                type="button"
                onClick={handleOpen}
              >
                <span>
                  {value}
                </span>
                <div className={cn(classes.icon, { [classes.icon_open]: open })} />
              </button>
              {open && (
                <div className={classes.phone__input_menu} ref={dropdownRef}>
                  <div className={classes.phone__input_menu_search}>
                    <label>
                      <SearchIcon />
                    </label>
                    <input
                      value={search}
                      onChange={handlerCodeSearch}
                      type="text"
                    />
                  </div>
                  <ul className={classes.phone__input_list}>
                    {countryPhones.map((item: PhoneCode) => (
                      <li
                        key={item.name}
                      >
                        <div
                          className={classes.phone__input_list_item}
                          ref={ref}
                          role="button"
                          tabIndex={-1}
                          data-code={item.dial_code}
                          onClick={(val: React.MouseEvent<HTMLDivElement>) => {
                            setOpen(false);
                            onChange(val.currentTarget.getAttribute('data-code'));
                          }}
                        >
                          <span> {item.name}</span>
                          <span> {item.dial_code}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="phoneNumber"
          defaultValue=""
          render={({ field: { value, ref, onChange } }) => (
            <div className={classes.input_number_wrapper}>
              <input
                ref={ref}
                type="number"
                autoComplete="tel-national"
                onFocus={() => setInfo(true)}
                onBlur={() => setInfo(false)}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
              />
            </div>
          )}
        />
      </div>
      <FormError
        active={!isValidPhoneNumber}
        text={translations.error}
        direction="down"
      />
      <FormInfo
        active={info}
        setActive={setInfo}
        text={translations.info}
        direction="up"
      />
    </div>
  );
};

export default PhoneInput;

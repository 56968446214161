import React from 'react';
// Styles
import classes from './TextButton.module.scss';

interface Props {
  onClick?: React.MouseEventHandler<Element>;
  disabled?: boolean;
  style?: React.CSSProperties | undefined;
}

const TextButton: React.FC<Props> = ({
  children,
  onClick,
  disabled,
  style,
}) => (
  <button
    className={classes.text_btn}
    type="button"
    onClick={onClick}
    disabled={disabled}
    style={style}
  >
    {children}
  </button>
);

export default TextButton;

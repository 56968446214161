import React from 'react';
// Types
import { OrderItem } from 'store/storefront/checkout/checkoutTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { twoLetterString } from 'utils/strings';
// Styles
import classes from './Item.module.scss';

interface Props {
  data: OrderItem,
  currencyIso: string,
  translation: TranslationStorefrontModel['cart']['cart_item']
}

const Item: React.FC<Props> = ({ data, currencyIso, translation }) => {
  const formatCurrencyByISO = CurrencyFormatByISO(currencyIso);

  return (
    <div className={classes.item}>
      <div className={classes.item_description}>
        <div className={classes.item_avatar}>
          {data.image ? (
            <img src={data.image} alt={data.name} />
          ) : (
            <span>
              {twoLetterString(data.name)}
            </span>
          )}
        </div>
        <h3>
          {data.name}
          {data.options.map((option, index) => (
            <div
              key={option.variant}
            >
              {option.variant}{index === data.options.length - 1 ? '' : ', '}
            </div>
          ))}
        </h3>
        <div className={classes.item_quantity}>
          {data.quantity}
        </div>
      </div>
      <div className={classes.item_price}>
        <span>
          {formatCurrencyByISO(data.unitPrice * data.quantity)}
        </span>
      </div>
      <div className={classes.item_taxes}>
        {data?.taxes?.map((tax) => (
          <div className={classes.item_tax} key={tax.name}>
            <div>
              <p>
                {(tax.included)
                  ? translation.taxes_included
                  : translation.taxes_excluded}
              </p>
              <p>
                {formatCurrencyByISO(
                  tax.amount,
                )}
              </p>
            </div>
            <span>
              {`${tax.name} (${tax.percentage}%)`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Item;

import React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { StorefrontRoutePath } from 'router/Routes/Storefront';
// Hooks
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Styles
import classes from './ShopInfo.module.scss';

interface Props {
  onClick: () => void;
}

const ShopInfo: React.FC<Props> = ({ onClick }) => {
  const { id } = useParams<{ id: string, orderId: string }>();
  const { pathToAbout, getShopPath } = useStorefrontRoutes();
  const location = useLocation();
  const translations = useTranslationsStorefront();
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  return (
    <div className={classes.shop__info}>
      <p className={classes.shop__info_title}>
        {translations.menu.shop_info}
      </p>
      <div className={classes.shop__info_list}>
        <NavLink to={getShopPath(id)} className={classes.shop__info_list_item} onClick={onClick}>
          {translations.menu_links.home}
        </NavLink>
        <NavLink
          to={pathToAbout(id, location.search)}
          className={classes.shop__info_list_item}
        >
          {translations.menu_links.about} {currentSalesChannel?.name}
        </NavLink>
        <NavLink
          to={`${StorefrontRoutePath.SHIPPING.replace(':id', id)}${location.search}`}
          className={classes.shop__info_list_item}
        >
          {translations.menu_links.shipping}
        </NavLink>
        <NavLink
          to={`${StorefrontRoutePath.REFUND_POLICY.replace(':id', id)}${location.search}`}
          className={classes.shop__info_list_item}
        >
          {translations.menu_links.return_policy}
        </NavLink>
      </div>
    </div>
  );
};

export default ShopInfo;

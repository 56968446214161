/* eslint-disable object-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useHistory, useLocation } from 'react-router-dom';
import { useUser } from 'hooks/useUser';
import { useDispatch } from 'react-redux';
import { changeAverageMonth } from 'store/dashboard/report/reportActions';
import { mixpanelActions } from 'utils/mixpanel';
import { SetPrevNavLink } from 'store/dashboard/app/appActions';
import UnpaidBillsTab from './UnpaidBillsTab/UnpaidBillsTab';
import AgingInvoiceTab from './UnpaidInvoiceTab/UnpaidInvoiceTab';
import ExpensesTab from './ExpensesTab/ExpensesTab';
import IncomeStatementTab from './IncomeStatementTab/IncomeStatementTab';
import SalesTab from './SalesTab/SalesTab';
import SalesTaxesTab from './SalesTaxesTab/SalesTaxesTab';
import classes from './HomeTabs.module.scss';
import VendorsTab from './VendorsTab/VendorsTab';
import QuoteTab from './QuoteTab/QuoteTab';
import EstimateTab from './EstimateTab/EstimateTab';
import OrdersTab from './OrdersTab/OrdersTab';
import CustomersTab from './CustomerTab';

interface HomeTabsProps {
  tabs: { [key: string]: number }[]
}

const HomeTabs: React.FC<HomeTabsProps> = ({ tabs }) => {
  const { pathname } = useLocation();
  const basePath = pathname.split('/');
  const user = useUser();
  const [selectIndex, setSelectIndex] = useState<number | null>(null);
  const [prevTab, setPrevTab] = useState<number | undefined>();
  const history = useHistory();
  const dispatch = useDispatch();
  const baseTabs = tabs.map((tab) => {
    const name = Object.keys(tab)[0].toLowerCase().replace(' ', '');
    return {
      [name]: tab[Object.keys(tab)[0]],
    };
  });
  const estimateTab = Object.keys(baseTabs[5])[0];
  const quoteTab = Object.keys(baseTabs[6])[0];
  const nameReports = basePath[3] && basePath[3]
    .replace('sales-orders', estimateTab)
    .replace('purchase-orders', quoteTab);
  const currentTab = baseTabs.find(
    (tab) => tab[nameReports] !== undefined
      && tab[nameReports] !== null,
  );

  useEffect(() => {
    if (currentTab) {
      setSelectIndex(
        (currentTab[nameReports]),
      );
      dispatch(changeAverageMonth({
        selectedMonth: null,
        datasets: null,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath, tabs, selectIndex, currentTab, nameReports]);

  useEffect(() => {
    if (!selectIndex) {
      return;
    }

    if (prevTab) {
      mixpanelActions.track(`View Dashboard / ${Object.keys(tabs[prevTab])[0]} Page`, {
        'Page Name': `Dashboard - ${Object.keys(tabs[prevTab])[0]}`,
      });
      dispatch(SetPrevNavLink(pathname));
    }
    mixpanelActions.time_event(`View Dashboard / ${Object.keys(tabs[selectIndex])[0]} Page`);
    setPrevTab(selectIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectIndex]);

  const handleOnSelect = useCallback(
    (i: number) => {
      setSelectIndex(i);
      if (user?.currentOrganisation?.id) {
        const param = Object.keys(baseTabs[i])[0]
          .replace(estimateTab, 'sales-orders')
          .replace(quoteTab, 'purchase-orders');
        history.push(`/dashboard/${user?.currentOrganisation?.id}/${param}`);
      }
    },
    [baseTabs, estimateTab, history, quoteTab, user?.currentOrganisation?.id],
  );
  if (selectIndex === null) {
    return null;
  }

  return (
    <Tabs
      selectedTabPanelClassName={classes.react_tabs__tab_panel__selected}
      selectedTabClassName={classes.react_tabs__tab__selected}
      className={classes.react_tabs}
      selectedIndex={selectIndex}
      onSelect={handleOnSelect}
    >
      <TabList className={classes.react_tabs__tab_list}>
        {tabs.map((tab) => (
          <Tab key={Object.keys(tab)[0]} className={classes.react_tabs__tab}>
            {Object.keys(tab)}
          </Tab>
        ))}
      </TabList>
      <TabPanel>
        <SalesTab />
      </TabPanel>
      <TabPanel>
        <ExpensesTab />
      </TabPanel>
      <TabPanel>
        <AgingInvoiceTab />
      </TabPanel>
      <TabPanel>
        <UnpaidBillsTab />
      </TabPanel>
      <TabPanel>
        <OrdersTab />
      </TabPanel>
      <TabPanel>
        <EstimateTab />
      </TabPanel>
      <TabPanel>
        <QuoteTab />
      </TabPanel>
      <TabPanel>
        <SalesTaxesTab />
      </TabPanel>
      <TabPanel>
        <CustomersTab />
      </TabPanel>
      <TabPanel>
        <VendorsTab />
      </TabPanel>
      <TabPanel>
        <IncomeStatementTab />
      </TabPanel>
    </Tabs>
  );
};

export default HomeTabs;

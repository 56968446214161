import React from 'react';
import Select from 'react-select';
import { ReactSelectOption } from 'types/globalTypes';
// Styles
import { customStyles, customStylesTwo } from './styles';

interface Props {
  options: ReactSelectOption[],
  defaultValue: ReactSelectOption
  handleChange: (e: ReactSelectOption | null) => void;
  type?: 'two'
}

const SelectSort: React.FC<Props> = ({
  options, defaultValue, handleChange, type,
}) => (
  <Select
    name="sort"
    options={options}
    menuPortalTarget={document.body}
    defaultValue={defaultValue}
    isSearchable={false}
    styles={type ? customStylesTwo : customStyles}
    onChange={handleChange}
  />
);

export default SelectSort;

import React, { useState } from 'react';
// Images
import { ReactComponent as DownIcon } from 'assets/icons/down-chevron.svg';
// Styles
import classes from './Accordion.module.scss';

interface Props {
  title: string;
  className?: string;
}

const Accordion: React.FC<Props> = ({ title, className, children }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={className || classes.root}>
      <div
        role="button"
        tabIndex={0}
        className={classes.accordion_header}
        onClick={() => setOpen(!isOpen)}
      >
        <h3>{title}</h3>
        <DownIcon className={isOpen ? classes.icon_open : classes.icon} />
      </div>
      <div className={isOpen
        ? `${classes.accordion_content} ${classes.open}`
        : classes.accordion_content}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;

import {
  INVOICE_LOADING,
  INVOICE_GET_DATA,
  InvoiceState,
  InvoiceActions,
  INVOICE_SET_CLIENT_SECRET,
  SET_PAYMENT_STATUS,
  SET_PAYMENT_SCHEDULE,
  INVOICE_PAYMENT_LOADING,
  INVOICE_PAYMENT_ERROR,
  INVOICE_PAYMENT_SUCCESS,
} from './invoiceTypes';

const initialState: InvoiceState = {
  loading: false,
  loadingPay: false,
  data: null,
  payment: {
    loading: '',
    error: null,
    success: false,
  },
};

export const invoiceReducer = (
  state: InvoiceState = initialState,
  action: InvoiceActions,
): InvoiceState => {
  switch (action.type) {
    case INVOICE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case INVOICE_GET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case INVOICE_SET_CLIENT_SECRET:
      return {
        ...state,
        stripe: action.payload,
      };
    case SET_PAYMENT_STATUS:
      return {
        ...state,
        loadingPay: action.payload.loading,
        status: action.payload.status,
      };
    case SET_PAYMENT_SCHEDULE:
      return {
        ...state,
        paymentSchedule: action.payload,
      };
    case INVOICE_PAYMENT_LOADING:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: action.payload,
        },
      };
    case INVOICE_PAYMENT_ERROR:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: null,
          error: action.payload,
        },
      };
    case INVOICE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: null,
          success: true,
        },
      };
    default:
      return state;
  }
};

import React from 'react';
import Notification from 'components/StorefrontComponents/Checkout/Notification';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import LoadingPage from 'pages/LoadingPage';

const PayPalLoading: React.FC = () => {
  const translations = useTranslationsStorefront();

  return (
    <div>
      <Notification
        active
        text={translations.checkout.paypal_loading.notification}
      />
      <LoadingPage
        title={translations.checkout.paypal_loading.title}
        backgroundColor="rgba(32, 39, 43, 0.8)"
      />
    </div>

  );
};

export default PayPalLoading;

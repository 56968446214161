import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import classes from './LinkCard.module.scss';

interface Props {
  title: string;
  link: string;
  link_label: string;
}

const LinkCard: React.FC<Props> = ({ title, link, link_label }) => (
  <div className={classes.root}>
    <p>
      {title}
    </p>
    <Link to={link} className={classes.link}>
      {link_label}
    </Link>
  </div>
);

export default LinkCard;

import React, { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Components
import FormError from 'components/StorefrontComponents/FormError';
import FormInfo from 'components/StorefrontComponents/FormInfo';
// Styles
import classes from './InputEmail.module.scss';

interface Props {
  register: UseFormReturn['register']
  isError: boolean;
  isFormInfo?: boolean;
}

const InputEmail: React.FC<Props> = ({
  register, isError, isFormInfo = true,
}) => {
  const [info, setInfo] = useState<boolean>(false);
  const { checkout } = useTranslationsStorefront();

  const onFocus = useCallback(() => {
    setInfo(true);
  }, []);

  const onBlur = useCallback(() => {
    setInfo(false);
  }, []);

  return (
    <div className={classes.inputEmail}>
      <input
        autoComplete="username"
        {...register('email', {
          required: false,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: checkout.email_input.error,
          },
        })}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={checkout.card_form.email_label}
        className={classes.input}
      />
      {isFormInfo && (
      <FormInfo
        active={info}
        setActive={setInfo}
        text={checkout.card_form.email_info}
        direction="up"
      />
      )}
      <FormError
        active={isError}
        text={checkout.card_form.email_error}
      />
    </div>
  );
};

export default InputEmail;

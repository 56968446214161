import React from 'react';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Assets
import { ReactComponent as VisaIcon } from 'assets/payment-icons/accepted-icons/visa.svg';
import { ReactComponent as GPayIcon } from 'assets/payment-icons/accepted-icons/gpay.svg';
import { ReactComponent as ApplePayIcon } from 'assets/payment-icons/accepted-icons/apple-pay.svg';
import { ReactComponent as AmexIcon } from 'assets/payment-icons/accepted-icons/amex.svg';
import { ReactComponent as MasterCardIcon } from 'assets/payment-icons/accepted-icons/mastercard.svg';
import { ReactComponent as PayPalIcon } from 'assets/payment-icons/accepted-icons/paypal.svg';
// Styles
import classes from './Payments.module.scss';

const Payments: React.FC = () => {
  const translations = useTranslationsStorefront();
  const paymentsTranslations = translations.cart.payments;

  return (
    <div className={classes.payments}>
      <h2 className={classes.payments_title}>
        {paymentsTranslations.title}
      </h2>
      <div className={classes.payments_cards}>
        <p>
          {paymentsTranslations.cards}
        </p>
        <div>
          <VisaIcon />
          <AmexIcon />
          <MasterCardIcon />
        </div>
      </div>
      <div className={classes.payments_wallets}>
        <p>
          {paymentsTranslations.wallets}
        </p>
        <div>
          <GPayIcon />
          <ApplePayIcon />
        </div>
      </div>
      <div className={classes.payments_bank_transfer}>
        <p>
          {paymentsTranslations.bank_transfer}
        </p>
        <div>
          {paymentsTranslations.options_on_checkout}
        </div>
      </div>
      <div className={classes.payments_other}>
        <p>
          {paymentsTranslations.other}
        </p>
        <div>
          <p>
            {paymentsTranslations.cash}
          </p>
          <PayPalIcon />
        </div>
      </div>
    </div>
  );
};

export default Payments;

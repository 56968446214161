import React, { useState } from 'react';
// Utils
import { isEmptyObject, openInNewTab } from 'utils/functions';
// Images
import { ReactComponent as WhatsAppIcon } from 'assets/storefront-icons/whatsApp.svg';
import { ReactComponent as TwitterIcon } from 'assets/storefront-icons/twitter.svg';
import { ReactComponent as FacebookIcon } from 'assets/storefront-icons/facebook.svg';
import { ReactComponent as CopyIcon } from 'assets/storefront-icons/copy.svg';
// Styles
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import classes from './ShareBar.module.scss';

type RedirectType = 'whatsapp' | 'email' | 'twitter' | 'facebook';

const SocialLinks = [
  {
    title: 'WhatsApp',
    image: <WhatsAppIcon />,
    href: 'whatsapp',
  },
  {
    title: 'Facebook',
    image: <FacebookIcon />,
    href: 'facebook',
  },
  {
    title: 'Twitter',
    image: <TwitterIcon />,
    href: 'twitter',
  },
];

interface Props {
  shopName: string;
  itemName: string;
  itemURL: string;
}

const ShareBar: React.FC<Props> = ({ shopName, itemName, itemURL }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { share_bar, share_shop } = useTranslationsStorefront();

  const shareShopHandler: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const type = event.currentTarget.getAttribute('data-link')! as RedirectType;

    switch (type) {
      case 'whatsapp': {
        const link = `https://api.whatsapp.com/send?text=Check out the ${itemName} on ${shopName}... ${itemURL}`;
        openInNewTab(link);
      }
        break;
      case 'twitter': {
        const link = `https://twitter.com/share?text=Check out the ${itemName} on ${shopName}... ${itemURL}`;
        openInNewTab(link);
      }
        break;
      case 'facebook': {
        const link = `https://www.facebook.com/sharer/sharer.php?u=${itemURL}`;
        openInNewTab(link);
      }
        break;
      default:
        break;
    }
  };

  const copyClickHandler = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2500);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <div className={classes.share}>
      <span>{share_bar.title}</span>
      <div className={classes.root}>
        <div className={classes.content}>
          {SocialLinks.map((link) => (isEmptyObject(link)
            ? null
            : (
              <button
                type="button"
                data-link={link.href}
                onClick={shareShopHandler}
                key={link.title}
              >
                {link.image}
              </button>
            )))}
          <div className={classes.copy}>
            <button
              type="button"
              onClick={() => copyClickHandler(`${itemURL}`)}
              disabled={isCopied}
            >
              <CopyIcon />
            </button>
            <span>
              {isCopied ? share_shop.copied : share_bar.copy}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareBar;

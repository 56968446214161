import axios from 'axios';

export interface StrapiResponse {
  id: number;
  ['screen_name']: string;
  translations: {
    id: number;
    en: {
      [key: string]: string;
    };
    fr: {
      [key: string]: string;
    };
    pt: {
      [key: string]: string;
    };
  }
}

export const STRAPI = axios.create({
  baseURL: 'https://cms-dot-leja-core.ey.r.appspot.com',
  withCredentials: false,
});

import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import React from 'react';
import { Link } from 'react-scroll';
// Types
import { TransactionStatus } from 'types/globalTypes';
// Utils
import { dateFormatA, formatReg } from 'utils/dates';
// Classes
import classes from './Status.module.scss';

interface Props {
  status: TransactionStatus;
  date?: string;
  dueDate?: string;
  isPaymentScheduled?: boolean;
}

const Status: React.FC<Props> = ({
  status,
  date,
  dueDate,
  isPaymentScheduled,
}) => {
  const translations = useTranslationsStorefront();

  if (status === TransactionStatus.PENDING) {
    return (
      <div className={classes.status}>
        <div
          className={classes.status_name_pending}
        >
          {translations.documents.status.pending}
        </div>
        <div className={classes.dates}>
          <p className={classes.dates_date}>{dateFormatA(date)}</p>
          <div className={classes.dates_due_date}>
            <span className={classes.dates_due_date_title}>
              {translations.documents.status.due_date_text}
            </span>
            <span className={classes.dates_due_date_value}>
              {' '}
              {formatReg(dueDate)}
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (status === TransactionStatus.ACCEPTED) {
    return (
      <div className={classes.status}>
        <div
          className={classes.status_name}
        >
          {translations.documents.status.accepted}
        </div>
        <div className={classes.dates}>
          <p className={classes.dates_date}>{dateFormatA(date)}</p>
          <div className={classes.dates_due_date}>
            <span className={classes.dates_due_date_title}>
              {translations.documents.status.due_date_text}
            </span>
            <span className={classes.dates_due_date_value}>
              {' '}
              {formatReg(dueDate)}
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (status === TransactionStatus.CANCELLED) {
    return (
      <div className={classes.status}>
        <div
          className={classes.status_name_unpaid}
        >
          {translations.documents.status.decline}
        </div>
        <div className={classes.dates}>
          <p className={classes.dates_date}>{dateFormatA(date)}</p>
          <div className={classes.dates_due_date}>
            <span className={classes.dates_due_date_title}>
              {translations.documents.status.due_date_text}
            </span>
            <span className={classes.dates_due_date_value}>
              {' '}
              {formatReg(dueDate)}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.status}>
      <div
        className={
          status === TransactionStatus.PAID
            ? classes.status_name
            : classes.status_name_unpaid
        }
      >
        {status === TransactionStatus.PAID
          ? translations.documents.status.paid
          : translations.documents.status.unpaid}
      </div>
      <div className={classes.dates}>
        <p className={classes.dates_date}>{dateFormatA(date)}</p>
        <div className={classes.dates_due_date}>
          <span className={classes.dates_due_date_title}>
            {translations.documents.status.due_date_text}
          </span>
          {isPaymentScheduled ? (
            <Link to="Schedule" className={classes.dates_btn}>
              {translations.documents.status.see_schedule_text}
            </Link>
          ) : (
            <span className={classes.dates_due_date_value}>
              {' '}
              {formatReg(dueDate)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Status;

import React, { useCallback, useMemo, useState } from 'react';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
// Components
import CompanyAvatar from 'components/StorefrontComponents/CompanyAvatar';
import Menu from 'pages/Storefront/Shop/Menu';
import Bag from './Bag';
import Search from './Search';
// Styles
import classes from './NavBar.module.scss';

const NavBar: React.FC = () => {
  const { bag } = useTypeSelector(({ storefront }) => storefront.shop);
  const { isMobile } = useMobileScreen();
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const formatCurrencyByISO = useMemo(
    () => CurrencyFormatByISO(currentSalesChannel?.address?.currencyISO),
    [currentSalesChannel?.address?.currencyISO],
  );

  const handleMenu = useCallback(() => {
    setMenuIsOpen(!menuIsOpen);
  }, [menuIsOpen]);

  return (
    <header className={classes.header}>
      <div className={classes.nav_bar_container}>
        <div className={classes.logo}>
          {
            currentSalesChannel && (
              <CompanyAvatar
                title={currentSalesChannel.name}
                logoUrl={currentSalesChannel.logoUrl}
              />
            )
          }
        </div>
        {!isMobile && (
          <Bag
            formatCurrencyByISO={formatCurrencyByISO}
            bag={bag}
          />
        )}
      </div>
      <div className={classes.header_container}>
        <button
          className={classes.hamburger}
          type="button"
          onClick={handleMenu}
        >
          <span />
          <span />
          <span />
        </button>
        <Search />
        <div />
      </div>
      <Menu isOpen={menuIsOpen} closeHandler={handleMenu} />
    </header>
  );
};

export default NavBar;

/* eslint-disable no-undef */
import React, { useMemo } from 'react';
import 'firebase/remote-config';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
// Utils
import { dateFormatA, formatReg } from 'utils/dates';
import { CurrencyFormatByISO } from 'utils/price';
import { makeValue } from 'utils/functions';
// Types
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { TransactionStatus, TransactionType } from 'types/globalTypes';
// Components
import Footer from './Footer';
// Styles
import classes from './OverviewSection.module.scss';

interface Props {
  transaction: TransactionType;
  type: string | null;
  handlePaymentScheduleModal: () => void;
  handleSendReminderModal: () => void;
  handleOpenRecordPayment: (amount?: number) => void;
}

interface IInfoContent {
  name?: string | JSX.Element;
  value?: string | JSX.Element;
}

const OverviewSection: React.FC<Props> = ({
  type,
  transaction,
  handlePaymentScheduleModal,
  handleSendReminderModal,
  handleOpenRecordPayment,
}) => {
  const translations = useTranslations();
  const formatCurrencyByISO = CurrencyFormatByISO(transaction.currencyISO);
  const ts = translations.transaction_overview;
  const { goToTransactionDetails } = useDashboardRoutes();
  const isPaid = transaction.status === TransactionStatus.PAID;
  const infoContent = useMemo(() => {
    let content: IInfoContent[] = [
      makeValue(translations.transactions.tabs_header.created, dateFormatA(transaction.date)),
    ];

    if (type === TransactionTypes.INVOICE) {
      if (isPaid && transaction?.payments) {
        content.push(
          makeValue(ts.paid_on, dateFormatA(
            transaction?.payments[transaction?.payments.length - 1].paymentDate,
          )),
          makeValue(
            ts.paid_with,
            transaction?.payments[transaction?.payments.length - 1].paymentMethod,
          ),
          makeValue(ts.notes, transaction.notes),
        );
        return content;
      }

      content.push(
        makeValue(
          ts.balance_remaining,
          formatCurrencyByISO(Number(transaction?.balance)),
        ),
        makeValue(
          ts.final_payment_due_by,
          dateFormatA(transaction.dueDate),
        ),
        makeValue(ts.payment_schedule, ''),
        makeValue(ts.notes, transaction.notes),
      );

      return content;
    }

    if (type === TransactionTypes.BILL) {
      if (isPaid && transaction?.payments) {
        content.push(
          makeValue(
            ts.bill_due_date,
            dateFormatA(transaction.dueDate),
          ),
          makeValue(ts.payment_date, dateFormatA(
            transaction?.payments[transaction?.payments.length - 1].paymentDate,
          )),
          makeValue(ts.paid_with,
            transaction?.payments[transaction?.payments.length - 1].paymentMethod),
          makeValue(translations.transaction_overview.notes, transaction.notes),
        );

        return content;
      }

      content.push(
        makeValue(
          ts.bill_due_date,
          dateFormatA(transaction.dueDate),
        ),
        makeValue(
          ts.payment_schedule,
          formatCurrencyByISO(Number(transaction?.balance)),
        ),
        makeValue(ts.amount_paid, formatCurrencyByISO(Number(transaction?.balance))),
        makeValue(translations.transaction_overview.notes, transaction.notes),
      );

      return content;
    }

    if (type === TransactionTypes.SALES_ORDER) {
      if (transaction.status === TransactionStatus.EXPIRED) {
        return [
          makeValue(ts.created, dateFormatA(transaction.date)),
          makeValue(ts.expired_on, dateFormatA(transaction.terms?.paymentDueDate)),
          makeValue(ts.notes, transaction.notes),
        ];
      }

      if (transaction.status === TransactionStatus.PENDING) {
        return [
          makeValue(ts.created, dateFormatA(transaction.date)),
          makeValue(ts.expires_on, dateFormatA(transaction.terms?.paymentDueDate)),
          makeValue(ts.notes, transaction.notes),
        ];
      }

      content = [
        makeValue(ts.created, dateFormatA(transaction.date)),
        makeValue(ts.accepted_on, dateFormatA(transaction.updatedAt)),
        makeValue(ts.invoice_associated, ts.none),
        makeValue(ts.notes, transaction.notes),
      ];

      return content;
    }

    if (type === TransactionTypes.PURCHASE_ORDER) {
      if (transaction.status === TransactionStatus.ACCEPTED) {
        return [
          makeValue(ts.created, dateFormatA(transaction.date)),
          makeValue(ts.accepted_on, formatReg(transaction.updatedAt)),
          makeValue(ts.bill_associated, ts.none),
          makeValue(ts.notes, transaction.notes),
        ];
      }

      if (transaction.status === TransactionStatus.EXPIRED) {
        return [
          makeValue(ts.created, dateFormatA(transaction.date)),
          makeValue(ts.expired_on, transaction.expiryDate),
          makeValue(ts.notes, transaction.notes),
        ];
      }

      return [
        makeValue(ts.created, dateFormatA(transaction.date)),
        makeValue(ts.expires_on, formatReg(transaction.expiryDate)),
        makeValue(ts.price_provided_on, dateFormatA(transaction.updatedAt)),
        makeValue(ts.notes, transaction.notes),
      ];
    }

    return content;
  }, [transaction, ts, isPaid, translations, type, formatCurrencyByISO]);

  const handleOpenInvoice = () => {
    goToTransactionDetails(`${transaction?.associations?.id}`, 'Invoice');
  };

  const handleOpenBill = () => {
    goToTransactionDetails(`${transaction?.associations?.id}`, 'Bill');
  };

  const isButton = (
    name: string | JSX.Element | undefined,
    value: string | JSX.Element | undefined,
  ) => {
    if (ts.payment_schedule === name) {
      if (transaction.terms) {
        return (
          <button
            type="button"
            onClick={handlePaymentScheduleModal}
            className={classes.payment_schedule}
          >
            {translations.transactions.view}
          </button>
        );
      }
      return <p className={classes.na}>{ts.none}</p>;
    }
    if (ts.invoice_associated === name) {
      if (transaction.associations) {
        return (
          <button
            type="button"
            onClick={handleOpenInvoice}
            className={classes.payment_schedule}
          >
            {ts.yes_view}
          </button>
        );
      }
      return <p>{value}</p>;
    }
    if (name === ts.bill_associated) {
      if (transaction.associations) {
        return (
          <button
            type="button"
            onClick={handleOpenBill}
            className={classes.payment_schedule}
          >
            {ts.yes_view}
          </button>
        );
      }
      return <p>{value}</p>;
    }
    return <p>{value}</p>;
  };

  return (
    <div className={classes.overview}>
      <div className={classes.overview_activity}>
        {infoContent.map(({ name, value }, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={key}>
            <p>{name}</p>
            {isButton(name, value)}
          </div>
        ))}
      </div>
      <Footer
        handleSendReminderModal={handleSendReminderModal}
        handleOpenRecordPayment={handleOpenRecordPayment}
      />
    </div>
  );
};
export default OverviewSection;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TransactionStatus } from 'types/globalTypes';
import { TransactionState } from './transactionTypes';

export const selectTransactionDetails = ({ transaction }: { transaction: TransactionState }) => (
  transaction.detailItem
);

export const selectPaymentMethodId = ({ transaction }: { transaction: TransactionState }) => {
  if (transaction?.detailItem?.status === TransactionStatus.UNPAID
    || transaction?.detailItem?.status === TransactionStatus.UNPROCESSED) {
    return 0;
  }
  return transaction.detailItem
    && transaction?.detailItem?.payments
    && transaction?.detailItem?.payments[0].paymentMethod;
};

export const selectTransactionContact = ({ transaction }: { transaction: TransactionState }) => {
  if (transaction.detailItem?.contact) {
    const addressContact = typeof transaction.detailItem?.contact.address === 'object' && transaction.detailItem?.contact.address !== null;
    return {
      contacts: transaction.detailItem?.contact,
      address: addressContact,
    };
  }

  return {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    address: {
      address1: '',
      city: '',
      postalCode: '',
    },
  };
};

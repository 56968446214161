import { PaymentIntentResult, Stripe } from '@stripe/stripe-js';

export const bancontactPay = async (
  clientSecret: string,
  stripe: Stripe,
  name: string,
  returnUrl: string,
): Promise<PaymentIntentResult> => {
  const paymentIntentResult = await stripe.confirmBancontactPayment(clientSecret, {
    payment_method: {
      billing_details: {
        name,
      },
    },

    return_url: returnUrl,
  });
  return paymentIntentResult;
};

import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Actions
import { directoryCheckContactType, directoryGetProfile } from 'store/dashboard/directory/directoryActions';
// Types
import { DirectoryType } from 'store/dashboard/directory/directoryTypes';
// Hooks
import { useUser } from 'hooks/useUser';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Components
import DetailsPageHeader from 'components/DashboardComponents/DetailsPageHeader';
import LoadingPage from 'pages/LoadingPage';
import DirectorySummary from './DirectorySummary';
import DirectoryDetailsSidebar from './DirectoryDetailsSidebar';
import DirectoryDetailsTransactions from './DirectoryDetailsTransactions';
import ProfileCard from './ProfileCard';
import Overview from './Overview';
// Styles
import classes from './DirectoryDetailsPage.module.scss';

const DirectoryDetailsPage: React.FC = () => {
  const { goBack } = useDashboardRoutes();
  const ref = useRef<null | number>(null);
  const { details, loading } = useTypeSelector(({ directory }) => directory);
  const { isMobile } = useMobileScreen();
  const params: { clientId?: string } = useParams();
  const user = useUser();
  const [openTransactions, setOpenTransactions] = useState(false);
  const [transactionTabIndex, setTransactionTabIndex] = useState(0);
  const dispatch = useDispatch();
  const translations = useTranslations();

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(
        directoryCheckContactType(
          user.currentOrganisation.id,
          `page=${0}&contactId=${Number(params.clientId)}`,
        ),
      );
      ref.current = 1;
    }
  }, [user?.currentOrganisation?.id, params.clientId, dispatch]);

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(directoryGetProfile(user.currentOrganisation.id, Number(params.clientId)));
    }
  }, [dispatch, params.clientId, user?.currentOrganisation?.id]);

  const handleOpenTransactions = useCallback((tabIndex?: number) => {
    setOpenTransactions((prev) => !prev);
    setTransactionTabIndex(tabIndex || 0);
  }, []);

  if (!ref.current || loading === 'directoryDetails' || !details.type) {
    return (
      <LoadingPage
        title={translations.layout.loading_text}
      />
    );
  }

  if (openTransactions) {
    return (
      <div className={classes.root}>
        <DetailsPageHeader
          goBack={handleOpenTransactions}
          title={isMobile
            ? translations.sidebar.transactions
            : translations.directory.details.title_transaction.replace('{name}', `${details.profile?.firstName} ${details.profile?.lastName}`)}
        />
        <DirectoryDetailsTransactions transactionTabIndex={transactionTabIndex} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <DetailsPageHeader
        goBack={goBack}
        title={translations.directory.details.title}
      />
      <div className={classes.wrapper}>
        <div className={classes.info_wrapper}>
          <div className={classes.profile}>
            <ProfileCard contactId={Number(params.clientId)} />
          </div>
          <div className={classes.overview}>
            <Overview handleOpenTransactions={handleOpenTransactions} />
          </div>
        </div>
        {details.type === DirectoryType.both ? (
          <>
            <DirectorySummary
              type={DirectoryType.customer}
              contactId={Number(params.clientId)}
            />
            <DirectorySummary
              type={DirectoryType.supplier}
              contactId={Number(params.clientId)}
            />
          </>
        ) : (
          <DirectorySummary
            type={details.type}
            contactId={Number(params.clientId)}
          />
        )}
        <DirectoryDetailsSidebar handleOpenTransactions={handleOpenTransactions} />
      </div>
    </div>
  );
};

export default DirectoryDetailsPage;

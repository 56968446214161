import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import React from 'react';
import { CartItemType } from 'store/storefront/shop/shopTypes';
// Styles
import classes from './ProductList.module.scss';
import ShoppingCartItem from './ShoppingCartItem';

interface Props {
  products: CartItemType[]
  handleDelete: (item: CartItemType) => void;
  currencyIso?: string;
}

const ProductList: React.FC<Props> = ({ products, handleDelete, currencyIso = 'EUR' }) => {
  const { cart } = useTranslationsStorefront();
  const totalCountProducts = products.reduce((acc, product) => acc + product.quantity, 0);

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <p>
          {cart.cart_item.cart_text}
        </p>
        <p>
          {totalCountProducts} {totalCountProducts === 1
            ? cart.cart_item.single_item_text
            : cart.cart_item.items}
        </p>
      </div>
      <div className={classes.table_items}>
        {products.map((cartItem) => (
          <ShoppingCartItem
            key={cartItem.variations[0].id}
            item={cartItem}
            currencyISO={currencyIso}
            deleteItem={handleDelete}
            translation={cart}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductList;

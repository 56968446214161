import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
// Types
import { Variation as VariationType } from 'store/dashboard/catalog/items/itemsTypes';
// Styles
import classes from './Variation.module.scss';

interface Props {
  id: string;
  variation: VariationType;
  checked: boolean;
  setOption: React.Dispatch<React.SetStateAction<string | null>>;
  translation: {
    in_stock: string;
    out_stock: string;
  };
}

const Variation: React.FC<Props> = ({
  id,
  variation,
  checked = false,
  setOption,
}) => {
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const location = useMemo(() => variation?.inventories.find(
    (inventory) => inventory.locationId === currentSalesChannel?.id,
  ), [currentSalesChannel, variation?.inventories]);

  const disabled = !(location?.quantity !== 0 && location);
  const variant = classNames(classes.variation, {
    [classes.variation_sold_out]: disabled,
    [classes.variation_selected]: checked,
  });

  const handleOption = useCallback(
    () => {
      setOption(id);
    },
    [id, setOption],
  );

  return (
    <label
      className={variant}
    >
      <input
        onChange={handleOption}
        checked={checked}
        type="checkbox"
        disabled={!!disabled}
      />
      <div className={classes.variation_radio_button}>
        {variation.name}
      </div>
    </label>
  );
};

export default Variation;

import React, {
  memo, useCallback, useEffect,
} from 'react';
import {
  NavLink, useHistory, useLocation, useParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslations } from 'hooks/useTranslations';
// Utils
import { getRemoteConfig, openInNewTab } from 'utils/functions';
import { mixpanelActions } from 'utils/mixpanel';
// Svg sprite
import SvgSprite from 'components/SvgSprite';
// Actions
import { setCurrentOrganisationAction, signOut } from 'store/auth/authActions';
// Types
import { DashboardRoutePath } from 'router/Routes/Dashboard';
import { IOrganisationData } from 'types/globalTypes';
// Components
import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
import { SetPrevNavLink } from 'store/dashboard/app/appActions';
import { useTabs } from 'hooks/useTabs';
import NavbarDropdown from '../NavBar/NavbarDropdown';
import FooterSideBar from '../FooterSideBar';
// Styles
import classes from './SideBar.module.scss';

const SideBar: React.FC = () => {
  const { pathname } = useLocation();
  const params: { orgId?: string } = useParams();
  const basePath = pathname.split('/');

  const { menuLinks, analytics } = useTypeSelector(
    ({ dashboard }) => dashboard.app,
  );

  const { user } = useTypeSelector(({ auth }) => auth);
  const history = useHistory();
  const tabs = useTabs();
  const { getDashboardHomeUrl } = useDashboardRoutes();
  const prevBasePath = analytics?.prevLink?.split('/');
  const dispatch = useDispatch();
  const defaultRemoteConfig = getRemoteConfig();
  const linkHelpCenter = defaultRemoteConfig.getValue('HelpCenter');
  const translation = useTranslations();
  const ts = translation.navbar;
  const tsSidebar = translation.sidebar;
  type P = typeof tsSidebar;

  const switchOrganisationHandler = useCallback((org: IOrganisationData) => {
    dispatch(setCurrentOrganisationAction(org));
    localStorage.setItem('leja-org-id', `${org.id}`);
    history.push(pathname.replace(`${params.orgId}`, `${org.id}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const baseTabs = tabs.map((tab) => {
    const name = Object.keys(tab)[0].toLowerCase().replace(' ', '');
    return {
      [name]: tab[Object.keys(tab)[0]],
    };
  });
  const estimateTab = Object.keys(baseTabs[5])[0];
  const quoteTab = Object.keys(baseTabs[6])[0];
  const nameReports = basePath[3] && basePath[3]
    .replace('sales-orders', estimateTab)
    .replace('purchase-orders', quoteTab);
  const currentTab = baseTabs.find(
    (tab) => tab[nameReports] !== undefined
      && tab[nameReports] !== null,
  );

  useEffect(() => {
    if (pathname === DashboardRoutePath.HOME) {
      return;
    }

    if (pathname === getDashboardHomeUrl(basePath[2], basePath[3]) && currentTab) {
      mixpanelActions.time_event(`View Dashboard / ${Object.keys(tabs[currentTab[nameReports]])[0]} Page`);
    }

    if (pathname === DashboardRoutePath.TRANSACTIONS) {
      mixpanelActions.time_event('View Transactions / All Page');
    }

    if (pathname === DashboardRoutePath.ORDERS) {
      mixpanelActions.time_event('View Orders / All Page');
    }

    if (pathname === DashboardRoutePath.DIRECTORY) {
      mixpanelActions.time_event('View Directory Page');
    }

    if (analytics?.prevLink === DashboardRoutePath.CATALOG) {
      mixpanelActions.time_event('View Catalog Page');
    }

    if (pathname === DashboardRoutePath.PROFILE) {
      mixpanelActions.time_event('View Settings/ Profile Page');
    }

    if (prevBasePath) {
      const prevTabName = prevBasePath && prevBasePath[3] && prevBasePath[3]
        .replace('sales-orders', estimateTab)
        .replace('purchase-orders', quoteTab);

      const prevTab = baseTabs.find(
        (tab) => tab[prevTabName] !== undefined
          && tab[prevTabName] !== null,
      );

      if (analytics?.prevLink === getDashboardHomeUrl(prevBasePath[2], prevBasePath[3])
        && prevTab) {
        mixpanelActions.track(`View Dashboard / ${Object.keys(tabs[prevTab[prevTabName]])[0]} Page`, {
          'Page Name': `Dashboard - ${Object.keys(tabs[prevTab[prevTabName]])[0]}`,
        });
      }

      if (analytics?.prevLink === DashboardRoutePath.TRANSACTIONS) {
        mixpanelActions.track(`View Transactions / ${analytics?.prevTransaction} Page`, {
          'Page Name': `Transactions - ${analytics?.prevTransaction}`,
        });
      }

      if (analytics?.prevLink === DashboardRoutePath.CATALOG) {
        mixpanelActions.track('View Catalog Page', {
          'Page Name': 'Catalog',
        });
      }

      if (analytics?.prevLink === DashboardRoutePath.ORDERS) {
        mixpanelActions.track(`View Orders / ${analytics?.prevOrder} Page`, {
          'Page Name': `Orders - ${analytics?.prevOrder}`,
        });
      }

      if (analytics?.prevLink === DashboardRoutePath.DIRECTORY) {
        mixpanelActions.track('View Directory Page', {
          'Page Name': 'Directory',
        });
      }

      if (analytics?.prevLink === DashboardRoutePath.PROFILE) {
        mixpanelActions.track('View Settings/ Profile Page', {
          'Page Name': 'Settings - Profile',
        });
      }
    }
    dispatch(SetPrevNavLink(pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignOut = useCallback(
    () => {
      mixpanelActions.track('Log Out', {
        'Page Name': 'Log Out',
      });
      mixpanelActions.reset();
      dispatch(signOut());
    },
    [dispatch],
  );

  const handleHelpCenter = useCallback(() => {
    mixpanelActions.track('Click Help Center', {
      'Page Name': 'Help Center',
    });

    openInNewTab(linkHelpCenter.asString());
  }, [linkHelpCenter]);

  return (
    <div className={classes.sidebar}>
      <NavbarDropdown translation={ts} switchOrganisation={switchOrganisationHandler} />
      <div className={classes.sidebar_wrapper}>
        <h1 className={classes.sidebar_title}>{tsSidebar.menu}</h1>
        <ul className={classes.sidebar__menu}>
          {menuLinks.map((link) => (
            <li className={classes.sidebar__menu_item} key={link.title}>
              <NavLink
                exact
                aria-current="true"
                to={link.route}
                className={classes.sidebar__menu_link}
                activeClassName={classes.sidebar__menu_link_active}
                isActive={() => {
                  if (basePath[2] !== `${user?.currentOrganisation?.id}`) {
                    return pathname === link.route;
                  }

                  return `/${basePath[1]}` === link.route;
                }}
              >
                <SvgSprite name={link.icon} />
                <span
                  className={classes.sidebar__menu_link_name}
                >{tsSidebar[link.title as keyof P]}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
        <div>
          <h1 className={classes.sidebar_title}>{tsSidebar.support}</h1>
          <div className={classes.sidebar__menu_link}>
            <SvgSprite name="help" />
            <span
              role="button"
              tabIndex={0}
              onClick={handleHelpCenter}
            >
              {tsSidebar.help_center}
            </span>
          </div>
        </div>
        <div>
          <h1 className={classes.sidebar_title}>{tsSidebar.setting}</h1>
          <NavLink
            to={DashboardRoutePath.LANGUAGES}
            className={classes.sidebar__menu_link}
            activeClassName={classes.sidebar__menu_link_active}
          >
            <SvgSprite name="globe" />
            <p className={classes.sidebar__menu_link_name}>
              {tsSidebar.language}
            </p>
          </NavLink>
          <NavLink
            to={DashboardRoutePath.PROFILE}
            className={classes.sidebar__menu_link}
            activeClassName={classes.sidebar__menu_link_active}
          >
            <SvgSprite name="user" />
            <p className={classes.sidebar__menu_link_name}>
              {user?.firstName ? `${user.firstName} ${user?.lastName}` : ''}
            </p>
          </NavLink>
          <div className={classes.sidebar__menu_link}>
            <SvgSprite name="logout" />
            <span
              role="button"
              tabIndex={0}
              onClick={handleSignOut}
            >{tsSidebar.log_out}
            </span>
          </div>
        </div>
      </div>
      <FooterSideBar />
    </div>
  );
};

export default memo(SideBar);

import React, { useCallback, useState } from 'react';
import cn from 'classnames';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Assets
import { ReactComponent as SecurePayment } from 'assets/storefront-icons/secure-payment.svg';

import StripeLogo from 'assets/images/stripe-logo.png';
// Styles
import YellowSwitch from 'components/Switch/YellowSwitch';
import classes from './FooterPaymentModal.module.scss';

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  isPayLaterInvoice?: boolean;
}

const FooterPaymentModal: React.FC<Props> = ({ onClick, disabled, isPayLaterInvoice }) => {
  const translations = useTranslationsStorefront();
  const paymentModalTs = translations.checkout.payment_modal;
  const [activeSwitch, setActiveSwitch] = useState(false);

  const handleActive = useCallback(() => {
    setActiveSwitch((prev) => !prev);
  }, []);

  if (!onClick) {
    return (
      <div className={cn(classes.footer_without_button, {
        [classes.footer_without_button_disabled]: disabled,
      })}
      >
        <SecurePayment />
        <span>
          {paymentModalTs.secure_stripe_text}
        </span>
      </div>
    );
  }

  if (isPayLaterInvoice) {
    return (
      <div className={classes.footer}>
        <div className={classes.footer_left}>
          <YellowSwitch onClick={handleActive} active={activeSwitch} />
          <p>
            {translations.checkout.card_form.description_switch}
          </p>
        </div>
        <button
          type="button"
          disabled={disabled || !activeSwitch}
          onClick={onClick}
        >
          {translations.checkout.card_form.pay_btn_label}
        </button>
      </div>
    );
  }

  return (
    <div className={classes.footer}>
      <div className={classes.footer_left}>
        <SecurePayment />
        <div>
          <img src={StripeLogo} alt="stripe" />
          <span>
            {translations.checkout.card_form.description}
          </span>
        </div>
      </div>
      <button
        type="button"
        disabled={disabled}
        onClick={onClick}
      >
        {translations.checkout.card_form.pay_btn_label}
      </button>
    </div>
  );
};

export default FooterPaymentModal;

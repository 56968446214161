import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Types
import { CartItemType } from 'store/storefront/shop/shopTypes';
// Utils
import { CartFunctions } from 'utils/storefront/CartFunctions';
// Actions
import { SetAddToBagAction } from 'store/storefront/shop/shopActions';
import { preCheckout } from 'store/storefront/checkout/checkoutActions';
// Images
import { ReactComponent as ShoppingCartIcon } from 'assets/storefront-icons/shopping-bag-empty.svg';
// Components
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import StorefrontLayout from 'layouts/StorefrontLayout';
import { StorefrontRoutePath } from 'router/Routes/Storefront';
import LoadingPage from 'pages/LoadingPage';
import Modal from 'components/Modal';
import Summary from './Summary';
import Payments from './Payments';
import LinkCard from './LinkCard';
import ProductList from './ProductList';
// Styles
import classes from './ShoppingCart.module.scss';

const ShoppingCart: React.FC = () => {
  const { id }: { id: string, productId: string } = useParams();
  const { goToShop, goToCheckout } = useStorefrontRoutes();
  const { isDesktop } = useMobileScreen();
  const dispatch = useDispatch();
  const locations = useLocation();
  const [checkout, setCheckout] = useState<boolean>(false);
  const {
    loading, bag, currentSalesChannel, error, categories,
  } = useTypeSelector(({ storefront }) => storefront.shop);
  const {
    fulfillment,
    isReadyForCheckout,
    preCheckoutLoading,
  } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { cart, server_error } = useTranslationsStorefront();
  const location = useLocation();
  const [errorCheckout, setErrorCheckout] = useState<boolean>(false);

  const toggleClose = useCallback(() => {
    goToShop(id, locations.search);
  }, [goToShop, id, locations.search]);

  const deleteBagItemHandler = useCallback((item: CartItemType) => {
    const updatedBag = CartFunctions.RemoveInCart(bag, item);
    localStorage.setItem(`leja-${id}-cart`, JSON.stringify(updatedBag));
    dispatch(SetAddToBagAction(updatedBag));
  }, [bag, dispatch, id]);

  const checkoutHandler = useCallback(() => {
    setCheckout(true);
    if (!currentSalesChannel) {
      return;
    }

    const addCategoriesName = bag.map((item) => ({
      ...item,
      category: {
        name: categories.data.find((category) => category.id === item.categoryId)?.name,
      },
    }));

    dispatch(preCheckout(id, addCategoriesName, fulfillment, currentSalesChannel));
  }, [bag, categories.data, currentSalesChannel, dispatch, fulfillment, id]);

  useEffect(() => {
    if (typeof isReadyForCheckout.success === 'undefined' || !currentSalesChannel || !checkout) {
      return;
    }

    if (isReadyForCheckout.errorCode) {
      setErrorCheckout(true);
      return;
    }

    goToCheckout(id, location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyForCheckout]);

  useEffect(() => {
    document.body.style.overflow = 'unset';
  }, []);

  if (error) {
    return (
      <div className={classes.root}>
        <StorefrontLayout type="two" />
        <h1 className={classes.error}>
          {server_error}
        </h1>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {loading && <LoadingPage backgroundColor="rgba(32, 39, 43, 0.8)" />}
      {preCheckoutLoading && <LoadingPage title={cart.checking_available_loading} backgroundColor="rgba(32, 39, 43, 0.8)" />}
      <StorefrontLayout type="two" />
      {!bag.length ? (
        <div className={classes.empty}>
          <ShoppingCartIcon />
          <span>{cart.empty}</span>
        </div>
      ) : (
        <div className={classes.main}>
          <div className={classes.product_list}>
            <ProductList
              products={bag}
              handleDelete={deleteBagItemHandler}
              currencyIso={currentSalesChannel?.address.currencyISO}
            />
          </div>
          <div className={classes.summary}>
            <Summary
              handleCheckout={checkoutHandler}
              handleContinueShopping={toggleClose}
            />
          </div>
          <div className={classes.payments}>
            <Payments />
          </div>
          <div className={classes.link_card}>
            <LinkCard
              title={cart.links.title_shipping}
              link={`${StorefrontRoutePath.SHIPPING.replace(':id', id)}${location.search}`}
              link_label={cart.links.label_shipping}
            />
          </div>
          <div className={classes.link_card}>
            <LinkCard
              title={cart.links.title_return_policy}
              link={`${StorefrontRoutePath.REFUND_POLICY.replace(':id', id)}${location.search}`}
              link_label={cart.links.label_return_policy}
            />
          </div>
        </div>
      )}

      <Modal
        setActive={setErrorCheckout}
        active={errorCheckout}
        type="four"
      >
        <div className={classes.modal}>
          <h3>
            {cart.unable_checkout_modal.title}
          </h3>
          <p>
            {isDesktop ? cart.unable_checkout_modal.text_second : cart.unable_checkout_modal.text}
          </p>
          <button type="button" onClick={() => setErrorCheckout(false)}>
            {cart.unable_checkout_modal.btn_text}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ShoppingCart;

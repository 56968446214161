export const firebaseConfigDev = {
  apiKey: 'AIzaSyCwsN6tG6BZ0LSL1jB-LD-iiSOlvw-aReA',
  authDomain: 'leja-6f1f8.firebaseapp.com',
  databaseURL: 'https://leja-6f1f8.firebaseio.com',
  projectId: 'leja-6f1f8',
  storageBucket: 'leja-6f1f8.appspot.com',
  messagingSenderId: '348028054556',
  appId: '1:348028054556:web:30cba1d95372cced5dc3d9',
  measurementId: 'G-WQE2RFH5G6',
};

export const firebaseConfigProd = {
  apiKey: 'AIzaSyBusHuCcyULTRDRgpCHkhg1lQNUWbTx2AM',
  authDomain: 'leja-apps-live.firebaseapp.com',
  databaseURL: 'https://leja-apps-live.firebaseio.com',
  projectId: 'leja-apps-live',
  storageBucket: 'leja-apps-live.appspot.com',
  messagingSenderId: '705320721381',
  appId: '1:705320721381:web:721e3e3f725f8f86b0ad0c',
  measurementId: 'G-4X7L4E4DM3',
};

export const firebaseConfigStaging = {
  apiKey: 'AIzaSyBpg-skuBh3XrGcTAXRxQt_Kmkfkf1TZlA',
  authDomain: 'leja-staging.firebaseapp.com',
  databaseURL: 'https://leja-staging.firebaseio.com',
  projectId: 'leja-staging',
  storageBucket: 'leja-staging.appspot.com',
  messagingSenderId: '828987151585',
  appId: '1:828987151585:web:69531837ecc884d43453a0',
  measurementId: 'G-XEEF3J8NWW',
};

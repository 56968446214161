import React, { useState, useEffect, useCallback } from 'react';
// Hooks
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
import { useUser } from 'hooks/useUser';
import { useTranslations } from 'hooks/useTranslations';
// Actions
import {
  getCurrentProductVariation,
  deleteProduct,
  getLocations,
} from 'store/dashboard/catalog/items/itemsActions';
// Components
import Accordion from 'components/Accordion';
import LoadingPage from 'pages/LoadingPage';
import ConfirmModal from 'components/Modal/ConfirmModal';
import DetailsPageHeader from 'components/DashboardComponents/DetailsPageHeader';
import classes from './ItemDetailsPage.module.scss';
import SideBar from './SideBar';
import ItemDetailsOptions from './ItemDetailsOptions';
import ItemDetailsStock from './ItemDetailsStock';

const ItemDetailsPage: React.FC = () => {
  const { goBack, goToCatalog } = useDashboardRoutes();
  const { productId } = useParams<{ productId: string }>();
  const { isMobile } = useMobileScreen();
  const [isDeleteConfirm, setIsDeleteConfirm] = useState<boolean>(false);
  const dispatch = useDispatch();
  const user = useUser();
  const translations = useTranslations();
  const {
    currentProduct,
    loading,
    locations,
  } = useTypeSelector(({ dashboard }) => dashboard.catalog.items);

  const deleteItemHandler = useCallback(() => {
    if (!currentProduct.product || !user?.currentOrganisation?.id) {
      return;
    }

    dispatch(deleteProduct(
      currentProduct.product.id,
      user?.currentOrganisation?.id,
      currentProduct.product.name,
    ));
    goToCatalog();
  }, [currentProduct.product, dispatch, goToCatalog, user?.currentOrganisation?.id]);

  useEffect(() => {
    if (user?.currentOrganisation?.id && !locations) {
      dispatch(getLocations(user.currentOrganisation.id));
    } else if (currentProduct.error) {
      goBack();
    }
  }, [currentProduct.error, dispatch, goBack, locations, user?.currentOrganisation?.id]);

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(getCurrentProductVariation(
        user.currentOrganisation.id,
        productId,
      ));
    } else if (currentProduct.error) {
      goBack();
    }
  }, [
    currentProduct.error, dispatch, goBack, productId, user?.currentOrganisation?.id]);

  if (!currentProduct.product || loading || currentProduct.loading) {
    return <LoadingPage />;
  }

  if (isMobile) {
    return (
      <div className={classes.root}>
        <DetailsPageHeader
          goBack={goBack}
          title={translations.catalog.item_details.page_label}
        />
        <div className={classes.wrapper}>
          <div className={classes.wrapper__col_left}>
            <ItemDetailsOptions product={currentProduct.product} />
            <ItemDetailsStock product={currentProduct.product} />
          </div>
          <Accordion
            title={translations.catalog.item_details.overview_text}
            className={classes.accordion_sidebar}
          >
            <SideBar
              translations={translations.sidebar_transaction}
              product={currentProduct.product}
              openDeleteConfirm={() => setIsDeleteConfirm(true)}
            />
          </Accordion>
          <button type="button" className={classes.btn_delete} onClick={() => setIsDeleteConfirm(true)}>
            {translations.catalog.item_details.delete_item_text}
          </button>
        </div>
        <ConfirmModal
          active={isDeleteConfirm}
          setActive={setIsDeleteConfirm}
          data={
            {
              title: translations.catalog.item_details.delete_item_question,
              description: translations.catalog.item_details.delete_item_description.replace('{ItemName}', currentProduct.product.name),
              button_first: translations.catalog.item_details.delete_item_cancel,
              button_second: translations.catalog.item_details.delete_item_confirm,
              onClick: deleteItemHandler,
              className: classes.confirm_modal,
            }
          }
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {loading && (
        <LoadingPage
          title={translations.layout.loading_text_secondary}
          backgroundColor="rgba(32, 39, 43, 0.8)"
        />
      )}
      <DetailsPageHeader
        goBack={goBack}
        title={translations.catalog.item_details.page_label}
      />
      <div className={classes.wrapper}>
        <div className={classes.wrapper__col_left}>
          <ItemDetailsOptions product={currentProduct.product} />
          <ItemDetailsStock product={currentProduct.product} />
        </div>
        <SideBar
          translations={translations.sidebar_transaction}
          product={currentProduct.product}
          openDeleteConfirm={() => setIsDeleteConfirm(true)}
        />
      </div>
      <ConfirmModal
        active={isDeleteConfirm}
        setActive={setIsDeleteConfirm}
        data={
          {
            title: translations.catalog.item_details.delete_item_question,
            description: translations.catalog.item_details.delete_item_description.replace('{ItemName}', currentProduct.product.name),
            button_first: translations.catalog.item_details.delete_item_cancel,
            button_second: translations.catalog.item_details.delete_item_confirm,
            onClick: deleteItemHandler,
          }
        }
      />
    </div>
  );
};

export default ItemDetailsPage;

import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
import { useQuery } from 'hooks/useQuery';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DashboardRoutePath } from 'router/Routes/Dashboard';
import { useTranslations } from 'hooks/useTranslations';
// Types
import { SearchResult, SearchTypes } from 'store/dashboard/app/appTypes';
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Assets
import { ReactComponent as TransactionIcon } from 'assets/dashboard-icons/transactions.svg';
import { ReactComponent as OrdersIcon } from 'assets/dashboard-icons/orders-purple.svg';
import { ReactComponent as CatalogIcon } from 'assets/dashboard-icons/catalog.svg';
import { ReactComponent as DirectoryIcon } from 'assets/dashboard-icons/directory.svg';

// Styles
import classes from './SearchItem.module.scss';

interface Props {
  item: SearchResult
}

const SearchItem: React.FC<Props> = ({ item }) => {
  const {
    pathToTransactionDetails,
    pathToOrderDetails,
    pathToDirectoryDetails,
    pathToItemDetails,
  } = useDashboardRoutes();
  const query = useQuery();
  const location = useLocation();
  const isDirectoriesPage = location.pathname === DashboardRoutePath.DIRECTORY;
  const isItemsPage = location.pathname === DashboardRoutePath.CATALOG;
  const isOrdersPage = location.pathname === DashboardRoutePath.ORDERS;
  const translations = useTranslations();

  if (query.get('type') === SearchTypes['sales-orders'] || isOrdersPage) {
    return (
      <div className={classes.item_transaction} key={item.id}>
        <OrdersIcon className={classes.order_icon} />
        <Link to={pathToOrderDetails(
          item.id,
        )}
        >
          {`${item.firstName} ${item.lastName} (${translations.transaction_type.SALES_ORDER})`}
        </Link>
      </div>
    );
  }

  if (query.get('type') === SearchTypes.contacts || isDirectoriesPage) {
    return (
      <div className={classes.item_transaction} key={item.id}>
        <DirectoryIcon className={classes.catalog_icon} />
        <Link to={pathToDirectoryDetails(
          item.id,
        )}
        >
          {`${item.firstName} ${item.lastName}`}
        </Link>
      </div>
    );
  }

  if (query.get('type') === SearchTypes.products || isItemsPage) {
    return (
      <div className={classes.item_transaction} key={item.id}>
        <CatalogIcon className={classes.catalog_icon} />
        <Link to={pathToItemDetails(
          item.id,
        )}
        >
          {`${item.firstName} ${item.lastName}`}
        </Link>
      </div>
    );
  }

  return (
    <div className={classes.item_transaction} key={item.id}>
      <TransactionIcon className={classes.transaction_icon} />
      <Link to={pathToTransactionDetails(
        item.id,
        TransactionTypes[item.type],
      )}
      >
        {`${item.firstName} ${item.lastName} (${translations.transaction_type[TransactionTypes[item.type as keyof typeof TransactionTypes] as keyof TranslationDashboardModel['transaction_type']]})`}
      </Link>
    </div>
  );
};

export default SearchItem;

import React from 'react';
// Assets
import { ReactComponent as Close } from 'assets/storefront-icons/close.svg';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Styles
import classes from './MessageError.module.scss';

interface Props {
  handleError: () => void;
  error: {
    msg: string;
    code: string;
  } | null
  translations: TranslationDashboardModel['login']['messages_error'];
}

const MessageError: React.FC<Props> = ({
  handleError, error, translations,
}) => {
  if (error?.code && error.code === 'auth/invalid-verification-code') {
    return (
      <div className={classes.msg_fail}>
        <div className={classes.msg_fail_top}>
          <p className={classes.msg_fail_top_text}>
            {translations.oops}
          </p>
          <Close onClick={handleError} />
        </div>
        <p className={classes.msg_fail_text}>
          {translations.incorrect_code}
        </p>
      </div>
    );
  }
  if (error?.code && error.code === 'auth/too-many-requests') {
    return (
      <div className={classes.msg_fail}>
        <div className={classes.msg_fail_top}>
          <p className={classes.msg_fail_top_text}>
            {translations.oops}
          </p>
          <Close onClick={handleError} />
        </div>
        <p className={classes.msg_fail_text}>
          {translations.too_many_attempts}
        </p>
      </div>
    );
  }

  if (error?.code && error.code === 'auth/invalid-phone-number') {
    return (
      <div className={classes.msg_fail}>
        <div className={classes.msg_fail_top}>
          <p className={classes.msg_fail_top_text}>
            {translations.oops}
          </p>
          <Close onClick={handleError} />
        </div>
        <p className={classes.msg_fail_text}>
          {translations.invalid_phone_number}
        </p>
      </div>
    );
  }
  return null;
};

export default MessageError;

import React from 'react';
import Card from 'components/Card';
// Styles
import { useTypeSelector } from 'hooks/useTypeSelector';
import CardSelect from 'components/CardSelect/CardSelect';
import { ReactSelectTime } from 'types/globalTypes';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import { getYear } from 'utils/dates';
import Graph from 'components/Graph';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import classes from './MobileExpensesTab.module.scss';

export interface AgingBillsData {
  month: string;
  type: 1 | 2;
  value: number;
}

interface Props {
  total: number;
  time: ReactSelectTime;
  setTime: React.Dispatch<React.SetStateAction<ReactSelectTime>>
  translations: TranslationDashboardModel['home']['report']
}

const MobileExpensesTab: React.FC<Props> = ({
  total, time, setTime, translations,
}) => {
  const { expensesChart, reportSelectCards, monthlyAverage } = useTypeSelector(
    ({ dashboard }) => dashboard.report,
  );

  return (
    <>
      <div className={classes.content_container}>
        <div className={classes.chart_container}>
          <div className={classes.chart}>
            <div className={classes.chart_title}>
              {!total ? (
                <div className={classes.chart_years}>
                  {`${getYear()} ${translations.year_to_date}`}
                </div>
              ) : (
                <div className={classes.chart_years}>
                  {`${getYear()} ${translations.ytd}`}
                </div>
              )}
              <div className={classes.chart_text}>
                {translations.tabs.re_expenses}
              </div>
            </div>
            {!total ? (
              <div className={classes.nodata}>
                <div className={classes.lines}>
                  <div className={classes.line} />
                  <div className={classes.line} />
                  <div className={classes.line} />
                </div>
                <p>{translations.no_data_available_yet}</p>
              </div>
            ) : (
              <Graph
                datasets={expensesChart.data}
                color="rgba(132, 146, 162, 0.8)"
                name={translations.tabs.re_expenses}
                isIso
              />
            )}
          </div>
        </div>
        <div className={classes.monthly_average}>
          <CardMonthlyAverage
            title={translations.expenses_report.month}
            value={monthlyAverage?.value}
            change={monthlyAverage?.percentage}
          />
        </div>
        <div className={classes.high}>
          <Card
            data={{
              title: translations.highest_month,
              label: expensesChart.highestMonth?.month,
              value: expensesChart.highestMonth?.value,
              second: translations.high,
              change: expensesChart.highestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.low}>
          <Card
            data={{
              title: translations.lowest_month,
              label: expensesChart.lowestMonth?.month,
              value: expensesChart.lowestMonth?.value,
              second: translations.low,
              change: expensesChart.lowestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.total}>
          <CardSelect
            data={{
              title: translations.all,
              value: reportSelectCards?.expenses?.entrytotal,
              label: `${reportSelectCards?.expenses?.contacts
                ? `${reportSelectCards?.expenses?.contacts} ${translations.tabs.re_vendors.toLowerCase()}`
                : ''}`,
              change: reportSelectCards?.expenses?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        <div className={classes.paid}>
          <CardSelect
            data={{
              title: translations.expenses_report.paid_bills,
              value: reportSelectCards?.you_paid?.entrytotal,
              label: `${reportSelectCards?.you_paid?.contacts
                ? `${reportSelectCards?.you_paid?.contacts} ${translations.tabs.re_vendors.toLowerCase()}`
                : ''}`,
              change: reportSelectCards?.you_paid?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        <div className={classes.unpaid}>
          <CardSelect
            data={{
              title: translations.expenses_report.unpaid_bills,
              value: reportSelectCards?.you_owe?.entrytotal,
              label: `${reportSelectCards?.you_owe?.contacts
                ? `${reportSelectCards?.you_owe?.contacts} ${translations.tabs.re_vendors.toLowerCase()}`
                : ''}`,
              change: reportSelectCards?.you_owe?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
      </div>
    </>
  );
};

export default MobileExpensesTab;

import React, {
  ChangeEventHandler, useCallback, useMemo, useState,
} from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import cn from 'classnames';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Utils
import { AutocompletePrediction, getGooglePlaces, getPlaceDetail } from 'utils/google';
// Components
import FormInfo from 'components/StorefrontComponents/FormInfo';
import FormError from 'components/StorefrontComponents/FormError';
import GooglePlaces from './GooglePlaces';
// Styles
import classes from './GooglePlacesInput.module.scss';

interface searchLocationState {
  touched: boolean;
  placesPage: boolean;
  loading: boolean;
  show: boolean;
}

type GooglePlaceValues = 'line1' | 'city' | 'code' | 'address2';

interface Props {
  setAddManually: React.Dispatch<React.SetStateAction<boolean | undefined>>
  setAddress: React.Dispatch<React.SetStateAction<boolean>>
  setValue: UseFormReturn['setValue']
  watch: UseFormReturn['watch']
  control: UseFormReturn['control']
  address: boolean
}

const GooglePlacesInput: React.FC<Props> = ({
  setAddManually, setAddress, setValue, watch, control, address,
}) => {
  const [searchLocation, setSearchLocation] = useState<searchLocationState>({
    touched: false,
    placesPage: false,
    loading: false,
    show: false,
  });
  const [keyword, setKeyword] = useState<string>('');
  const [info, setInfo] = useState<boolean>(false);
  // eslint-disable-next-line no-undef
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [googlePlaces, setGooglePlaces] = useState<AutocompletePrediction[] | null>(null);
  const { checkout: { card_form } } = useTranslationsStorefront();
  const country = watch('country');
  const postalCode = useMemo(() => watch('postCode') || '', [watch]);

  const showModalAddress = useMemo(
    () => searchLocation.show && !(searchLocation.placesPage && !searchLocation.touched),
    [searchLocation.placesPage, searchLocation.show, searchLocation.touched],
  );

  const handleCloseAddress = useCallback(() => {
    setSearchLocation({
      ...searchLocation,
      show: false,
    });
  }, [searchLocation]);

  const handleManually = useCallback(
    () => {
      setAddManually(true);
      setSearchLocation({
        ...searchLocation,
        show: false,
      });
    },
    [searchLocation, setAddManually],
  );

  const googlePlaceSearchHandler: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setAddress(false);
    setKeyword(event.target.value);
    setValue(event.target.name as GooglePlaceValues, event.target.value);

    if (timer) {
      clearTimeout(timer);
    }

    setSearchLocation({
      ...searchLocation,
      show: true,
      loading: true,
    });

    const debounceHandler = setTimeout(async () => {
      try {
        const result = await getGooglePlaces(event.target.value, country?.value);
        if (result === null) {
          setSearchLocation({
            ...searchLocation,
            show: false,
          });
        }

        setGooglePlaces(result);
      } catch (error) {
        setGooglePlaces(null);
        setSearchLocation({
          ...searchLocation,
          show: false,
        });
      }
    }, 500);

    setTimer(debounceHandler);
  }, [country?.value, searchLocation, setAddress, setValue, timer]);

  const setAutocompleteValues = useCallback(async (placeId: string) => {
    const response = await getPlaceDetail(placeId);
    if (response) {
      const values = {
        address: '',
        city: '',
        postCode: '',
        line1: '',
      };

      response.address_components?.forEach((item) => {
        if (item.types.includes('street_number')) {
          values.line1 += `${item.long_name} `;
        } else if (item.types.includes('route')) {
          values.line1 += `${item.long_name} `;
        } else if (item.types.includes('locality') || item.types.includes('postal_town')) {
          values.city = item.long_name;
        } else if (item.types.includes('postal_code')) {
          values.postCode = item.long_name;
        }
      });

      values.address = `${values.line1} ${values.postCode} ${values.city}`;

      Object.entries(values).forEach(
        ([field, value]) => setValue(field as GooglePlaceValues, value),
      );

      setSearchLocation({
        touched: true,
        placesPage: false,
        loading: false,
        show: false,
      });

      if (values.line1 && values.city && values.postCode) {
        setAddress(true);
      }
    }
  }, [setAddress, setValue]);

  return (
    <div
      className={cn(classes.address_input_wrapper, {
        [classes.address_input_wrapper_active]: address,
      })}
    >
      <Controller
        control={control}
        rules={{ required: true }}
        name="address"
        defaultValue=""
        render={({ field: { value, ref, onChange } }) => (
          <input
            ref={ref}
            type="text"
            id="address"
            autoComplete="address"
            name="address"
            placeholder={card_form.address_placeholder}
            onFocus={() => {
              setInfo(true);
              if (keyword.length > 2) {
                setSearchLocation({
                  ...searchLocation,
                  show: true,
                });
              }
            }}
            onBlur={() => {
              setInfo(false);
            }}
            value={value}
            onChange={(e) => {
              googlePlaceSearchHandler(e);
              onChange(e);
            }}
          />
        )}
      />
      <FormError
        active={!postalCode && postalCode !== ''}
        text={card_form.postal_code_error}
      />
      <FormInfo
        active={info}
        setActive={setInfo}
        text={card_form.address_info}
        direction="up"
      />
      {showModalAddress && (
        <GooglePlaces
          handleCloseAddress={handleCloseAddress}
          places={googlePlaces}
          keyword={keyword}
          setAutocomplete={setAutocompleteValues}
          loading={searchLocation.loading}
          handleAddManually={handleManually}
        />
      )}
    </div>
  );
};

export default GooglePlacesInput;

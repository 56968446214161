import React from 'react';
// Types
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { ContactType, IOrganisationData } from 'types/globalTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Styles
import classes from './Contacts.module.scss';

interface Props {
  organisation: IOrganisationData;
  contact: ContactType & {fullName: string};
  translations: TranslationStorefrontModel['documents']['contacts'];
  type: TransactionTypes;
}

const Contacts: React.FC<Props> = ({ organisation, contact, translations }) => (
  <div className={classes.contacts}>
    <div className={classes.contacts_wrapper}>
      <div className={classes.contact}>
        <h2 className={classes.contact_title}>{translations.to_text}</h2>
        <div className={classes.contact_address}>
          <p>{contact.fullName}</p>
          <p>
            {contact.city} {contact.postalCode} {contact.country}
          </p>
          <p>{contact.mobileNumber}</p>
          <p>{contact.email}</p>
        </div>
      </div>
      <div className={classes.contact}>
        <h2 className={classes.contact_title}>{translations.from_text}</h2>
        <div className={classes.contact_address}>
          <p>{organisation?.address?.line1}</p>
          <p>{organisation?.address?.line2}</p>
          <p>
            {organisation?.address?.city}, {organisation?.address?.postalCode},{' '}
            {organisation?.country?.name}
          </p>
          <p>{organisation.phoneNumber}</p>
          <p>{organisation.email}</p>
        </div>
      </div>
    </div>
  </div>
);

export default Contacts;

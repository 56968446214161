import React from 'react';
import cn from 'classnames';
// Styles
import classes from './FormError.module.scss';

interface Props {
  active: boolean;
  text?: string;
  direction?: 'up' | 'down'
}

const FormError: React.FC<Props> = ({ active, text, direction = 'down' }) => (
  <div className={cn(classes.error, {
    [classes.error_up]: direction === 'up',
    [classes.error_active]: active,
  })}
  >
    <div className={classes.error_triangle} />
    <p>{text}</p>
  </div>
);

export default FormError;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { setOrders } from 'store/dashboard/orders/orderActions';
// Types
import { OrderType } from 'store/dashboard/orders/orderTypes';
// Components
import MoreButton from 'components/Buttons/MoreButton';
import CustomSpinner from 'components/CustomSpinner';
import Header from 'pages/Dashboard/Transaction/TransactionTabContent/Header';
import Order from './Order';
// Styles
import classes from './OrderTable.module.scss';

interface TableProps {
  items: OrderType[];
  handleMoreOrders: () => void;
  pages: number;
}

const OrderTable: React.FC<TableProps> = ({ items, handleMoreOrders, pages }) => {
  const [sort, setSort] = useState<string>();
  const { morePage, loading, orders } = useTypeSelector(({ order }) => order);
  const { isMobile } = useMobileScreen();
  const translations = useTranslations();
  const dispatch = useDispatch();
  const tsOrders = translations.orders;

  const sortOrders = useCallback((header: string, sortConfig = 'asc') => {
    setSort(header);
    if (header === translations.orders.headers[0]) {
      dispatch(setOrders(orders.sort((a, b) => {
        if (a.date < b.date) return sortConfig === 'asc' ? 1 : -1;
        if (a.date > b.date) return sortConfig === 'asc' ? -1 : 1;
        return 0;
      })));
    }

    if (header === translations.orders.headers[1]) {
      dispatch(setOrders(orders.sort((a, b) => {
        if (`${a.client}` > `${b.client}`) return sortConfig === 'asc' ? 1 : -1;
        if (`${a.client}` < `${b.client}`) return sortConfig === 'asc' ? -1 : 1;
        return 0;
      })));
    }

    if (header === translations.orders.headers[2]) {
      dispatch(setOrders(orders.sort((a, b) => {
        if (`${a.salesChannel}` > `${b.salesChannel}`) return sortConfig === 'asc' ? 1 : -1;
        if (`${a.salesChannel}` < `${b.salesChannel}`) return sortConfig === 'asc' ? -1 : 1;
        return 0;
      })));
    }

    if (header === translations.orders.headers[3]) {
      dispatch(setOrders(orders.sort((a, b) => {
        const isUnpaidCurrent = a.balance ? translations.orders.unpaid : translations.orders.paid;
        const isUnpaidNext = b.balance ? translations.orders.unpaid : translations.orders.paid;
        if (`${isUnpaidCurrent}` > `${isUnpaidNext}`) {
          return sortConfig === 'asc' ? 1 : -1;
        }

        if (`${isUnpaidCurrent}` < `${isUnpaidNext}`) {
          return sortConfig === 'asc' ? -1 : 1;
        }

        return 0;
      })));
    }

    if (header === translations.orders.headers[4]) {
      dispatch(setOrders(orders.sort((a, b) => {
        if (`${a.amount}` > `${b.amount}`) return sortConfig === 'asc' ? 1 : -1;
        if (`${a.amount}` < `${b.amount}`) return sortConfig === 'asc' ? -1 : 1;
        return 0;
      })));
    }

    if (header === translations.orders.headers[6]) {
      dispatch(setOrders(orders.sort((a, b) => {
        if (`${a.status}` > `${b.status}`) return sortConfig === 'asc' ? 1 : -1;
        if (`${a.status}` < `${b.status}`) return sortConfig === 'asc' ? -1 : 1;
        return 0;
      })));
    }
  }, [dispatch, orders, translations]);

  useEffect(() => {
    if (isMobile) {
      dispatch(setOrders(orders.sort((a, b) => {
        if (`${a.date}` > `${b.date}`) return 1;
        if (`${a.date}` < `${b.date}`) return -1;
        return 0;
      })));
    }
  }, [orders, dispatch, isMobile]);

  if (!items.length) {
    return (
      <div className={classes.table}>
        <div className={classes.table_header_nodata}>
          {tsOrders.headers.map((header) => (
            <Header
              key={header}
              header={header}
              sortItems={sortOrders}
              isIconShow={sort === header}
              disabled={!!items.length}
            />
          ))}
        </div>
        <div className={classes.nodata}>
          {translations.orders.no_orders}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.table}>
      {!isMobile && (
        <div className={classes.table_header}>
          {tsOrders.headers.map((header) => (
            <Header
              key={header}
              header={header}
              sortItems={sortOrders}
              isIconShow={sort === header}
            />
          ))}
        </div>
      )}
      <div className={classes.table_content}>
        {items.map((item) => (
          <Order
            key={item.uniqId}
            item={item}
          />
        ))}
        {loading && pages !== 0 && (
          <div className={classes.loading}>
            <CustomSpinner variant="two" />
          </div>
        )}
      </div>
      {!morePage && !loading && (
        <div
          role="button"
          tabIndex={0}
          onClick={handleMoreOrders}
          className={classes.more_btn}
        >
          <MoreButton />
        </div>
      )}
    </div>
  );
};

export default OrderTable;

import ModalAddPrice from 'pages/Documents/Quote/ModalAddPrice';
import React, { useState } from 'react';
import { Items } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import { CurrencyFormatByISO } from 'utils/price';
import { twoLetterString } from 'utils/strings';
import { useTypeSelector } from 'hooks/useTypeSelector';
import cn from 'classnames';
// Styles
import { QuoteTermsPaymentType } from 'store/quote/quoteTypes';
import classes from './Item.module.scss';

interface Props {
  data: Items,
  currencyIso: string,
  termsPayment: QuoteTermsPaymentType,
  translations: TranslationDashboardModel['sidebar_transaction']['transaction_item']
}

const Item: React.FC<Props> = ({
  data, currencyIso, translations, termsPayment,
}) => {
  const [addPrice, setAddPrice] = useState<boolean>(false);
  const formatCurrencyByISO = CurrencyFormatByISO(currencyIso);
  const { sent } = useTypeSelector((state) => state.quote);
  const handleAddPrice = () => {
    setAddPrice((show) => !show);
  };

  return (
    <>
      {addPrice && (
        <ModalAddPrice
          handleClose={handleAddPrice}
          iso={currencyIso}
          currentItem={data}
        />
      )}
      <div className={classes.item}>
        <div className={classes.item_description}>
          <div className={classes.item_avatar}>
            {data?.src ? (
              <img src={data?.src} alt={data.name} />
            ) : (
              <span>
                {twoLetterString(data.name)}
              </span>
            )}
          </div>
          <h3>
            {data.name}
            {!!data.options?.length && data.options.map((option, index) => (
              data?.options && (
                <div
                  key={option.variant}
                >
                  {option.variant}{index === data.options.length - 1 ? '' : ', '}
                </div>
              )
            ))}
          </h3>
          <div className={classes.item_quantity}>
            {data.quantity}
          </div>
        </div>
        {data.unitPrice && (
          <div className={classes.item_price}>
            <div>
              <span>
                {formatCurrencyByISO(data.unitPrice * data.quantity)}
              </span>
            </div>
          </div>
        )}
        {data.unitPrice && (
          <div className={classes.item_taxes}>
            {data.unitPrice && data?.taxes?.map((tax) => (
              <div className={classes.item_tax} key={tax.name}>
                <div>
                  <p>
                    {(tax.included)
                      ? translations.taxes_included
                      : translations.taxes_excluded}
                  </p>
                  <p>
                    {formatCurrencyByISO(
                      tax.amount,
                    )}
                  </p>
                </div>
                <span>
                  {`${tax.name} (${tax.percentage}%)`}
                </span>
              </div>
            ))}
          </div>
        )}

        {!sent && (
          <button
            type="button"
            className={cn(classes.item_price_btn, {
              [classes.item_price_btn_edit]: data.unitPrice,
            })}
            onClick={handleAddPrice}
            disabled={termsPayment.isExpired}
          >
            {data.unitPrice ? translations.edit : translations.price_this_item}
          </button>
        )}
      </div>
    </>
  );
};

export default Item;

import { CheckoutFunctions } from 'utils/storefront/CheckoutFunctions';
import {
  CHECKOUT_SET_READY,
  CHECKOUT_SET_ITEMS,
  CHECKOUT_SET_FULFILLMENT_METHOD,
  CHECKOUT_SET_FULFILLMENT_DATA,
  CHECKOUT_SET_LOCATION,
  CHECKOUT_SET_CONTACT,
  CHECKOUT_SET_PAYMENT,
  CheckoutState,
  CheckoutActions,
  CHECKOUT_REMOVE_ITEM,
  CHECKOUT_UPDATE_ITEM,
  CHECKOUT_SET_PRE_CHECKOUT_LOADING,
  CHECKOUT_SET_IS_USER,
  CHECKOUT_SET_IS_NEW_USER,
  CHECKOUT_SET_ORDER_LOADING,
} from './checkoutTypes';

const initialState: CheckoutState = {
  isReadyForCheckout: {
    data: [],
    errorCode: '',
  },
  preCheckoutLoading: false,
  isUser: false,
  isNewUser: false,
  items: [],
  fulfillment: {
    method: null,
    country: {
      name: '',
      iso: '',
      currency: '',
    },
    location: {
      name: '',
      address: '',
    },
    amount: {
      total: 0,
      subtotal: 0,
      fee: 0,
      taxesIncluded: 0,
      taxesExcluded: 0,
    },
    contact: null,
  },
  order: {
    loading: null,
    error: undefined,
    orderId: undefined,
    isPayed: false,
  },
};

export const checkoutReducer = (
  state: CheckoutState = initialState,
  action: CheckoutActions,
): CheckoutState => {
  switch (action.type) {
    case CHECKOUT_SET_READY:
      return {
        ...state,
        isReadyForCheckout: action.payload,
      };
    case CHECKOUT_SET_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case CHECKOUT_SET_FULFILLMENT_DATA:
      return {
        ...state,
        fulfillment: action.payload,
      };
    case CHECKOUT_SET_FULFILLMENT_METHOD:
      return {
        ...state,
        fulfillment: {
          ...state.fulfillment,
          method: action.payload,
        },
      };
    case CHECKOUT_SET_LOCATION:
      return {
        ...state,
        fulfillment: {
          ...state.fulfillment,
          location: action.payload,
        },
      };
    case CHECKOUT_SET_CONTACT:
      return {
        ...state,
        fulfillment: {
          ...state.fulfillment,
          contact: action.payload,
        },
      };
    case CHECKOUT_SET_PAYMENT:
      return {
        ...state,
        order: {
          ...state.order,
          ...action.payload,
        },
      };
    case CHECKOUT_REMOVE_ITEM: {
      const items = [
        ...state.items.filter(
          (i) => i.variations[0].id !== action.payload.item.variations[0].id,
        ),
      ];
      const amount = CheckoutFunctions.calcAmount(items, state.fulfillment.amount.fee || 0);
      localStorage.setItem(`leja-${action.payload.url}-cart`, JSON.stringify(items));
      localStorage.setItem(`leja-${action.payload.url}-checkout`, JSON.stringify({
        isReadyForCheckout: state.isReadyForCheckout,
        items,
        fulfillment: state.fulfillment,
      } as CheckoutState));
      return {
        ...state,
        items,
        fulfillment: {
          ...state.fulfillment,
          amount,
        },
      };
    }
    case CHECKOUT_UPDATE_ITEM: {
      const productInCheckout = state.items.findIndex((item) => item.variations.find(
        (variation) => variation.id === action.payload.item.variations[0].id,
      ));
      const updateItems = state.items.map((bagItem, index) => {
        if (index === productInCheckout) {
          return {
            ...bagItem,
            quantity: action.payload.item.quantity,
          };
        }

        return bagItem;
      });

      const amount = CheckoutFunctions.calcAmount(updateItems, state.fulfillment.amount.fee || 0);
      localStorage.setItem(`leja-${action.payload.url}-cart`, JSON.stringify(updateItems));
      localStorage.setItem(`leja-${action.payload.url}-checkout`, JSON.stringify({
        isReadyForCheckout: state.isReadyForCheckout,
        items: updateItems,
        fulfillment: {
          ...state.fulfillment,
          amount,
        },
      } as CheckoutState));
      return {
        ...state,
        items: updateItems,
        fulfillment: {
          ...state.fulfillment,
          amount,
        },
      };
    }
    case CHECKOUT_SET_PRE_CHECKOUT_LOADING:
      return {
        ...state,
        preCheckoutLoading: action.payload,
      };
    case CHECKOUT_SET_IS_USER:
      return {
        ...state,
        isUser: action.payload,
      };
    case CHECKOUT_SET_IS_NEW_USER:
      return {
        ...state,
        isNewUser: action.payload,
      };
    case CHECKOUT_SET_ORDER_LOADING:
      return {
        ...state,
        order: {
          ...state.order,
          loading: action.payload,
        },
      };
    default:
      return state;
  }
};

import { PaymentIntentResult, Stripe } from '@stripe/stripe-js';

export const googleApplePay = async (
  clientSecret: string,
  stripe: Stripe,
  paymentMethodId: string,
): Promise<PaymentIntentResult> => {
  const paymentIntentResult = await stripe.confirmCardPayment(
    clientSecret,
    { payment_method: paymentMethodId },
    { handleActions: false },
  );
  return paymentIntentResult;
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { getTranslations } from 'store/storefront/app/appActions';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
// Styles
import classes from './PaymentLinkLayout.module.scss';

const PaymentLinkLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const {
    isTranslationsFetched,
  } = useTypeSelector(({ storefront }) => storefront.app);

  useEffect(() => {
    if (!isTranslationsFetched) {
      dispatch(getTranslations('en'));
    }
  }, [isTranslationsFetched, dispatch]);

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        {children}
      </div>
    </div>
  );
};

export default PaymentLinkLayout;

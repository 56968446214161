import React from 'react';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
import StorefrontLayout from 'layouts/StorefrontLayout';
// Styles
import classes from './Refund.module.scss';

const Refund: React.FC = () => {
  const { refund_page, menu_links } = useTranslationsStorefront();
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const refundTitle = currentSalesChannel?.refundPolicy
    ? refund_page.returns.replace('{day}', currentSalesChannel?.refundPolicy) : refund_page.all_sales;

  return (
    <div className={classes.refund}>
      <StorefrontLayout type="two" />
      <div className={classes.refund_content}>
        <h2>{menu_links.return_policy}</h2>
        <h3>{refundTitle}</h3>
        <div className={classes.refund_content_description}>
          {currentSalesChannel?.refundPolicy
            ? refund_page.returns_description.replace('{day}', currentSalesChannel?.refundPolicy)
            : refund_page.all_sales_description}
        </div>
      </div>
    </div>
  );
};

export default Refund;

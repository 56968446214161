import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import React from 'react';
import { CurrencyFormatByISO } from 'utils/price';
// Styles
import classes from './PaymentTitle.module.scss';

interface Props {
  currencyIso?: string;
  value?: number
}

const PaymentTitle: React.FC<Props> = ({ currencyIso, value }) => {
  const formatCurrencyByISO = CurrencyFormatByISO(currencyIso);
  const translations = useTranslationsStorefront();
  const paymentModalTs = translations.checkout.payment_modal;

  return (
    <div className={classes.title}>
      {paymentModalTs.pay_text} {formatCurrencyByISO(value)}
    </div>
  );
};

export default PaymentTitle;

import React from 'react';
import { ReactSelectOption } from 'types/globalTypes';
import SelectSort from '../SelectSort';
// Styles
import classes from './MobileSort.module.scss';

interface Props {
  options: ReactSelectOption[],
  defaultValue: ReactSelectOption
  handleChange: (e: ReactSelectOption | null) => void;
  title: string;
}

const MobileSort: React.FC<Props> = ({
  options, defaultValue, handleChange, title,
}) => (
  <div className={classes.sort_mob}>
    <p className={classes.sort_mob_label}>
      {title}
    </p>
    <SelectSort
      options={options}
      defaultValue={defaultValue}
      handleChange={handleChange}
    />
  </div>
);

export default MobileSort;

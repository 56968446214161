import React, { useEffect, useMemo, useState } from 'react';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { getAgingBillsData, getReportSelectCards } from 'store/dashboard/report/reportActions';
import CardSelect from 'components/CardSelect';
import { ReactSelectTime } from 'types/globalTypes';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import Card from 'components/Card';
import { getYear } from 'utils/dates';
import { ReportReq } from 'store/dashboard/report/reportTypes';
import StackedGraph from 'components/StackedGraph';
import CustomSpinner from 'components/CustomSpinner';
import MobileUnpaidBillsTab from './MobileTab';
// Styles
import classes from './UnpaidBillsTab.module.scss';

export interface AgingBillsData {
  month: string;
  type: 1 | 2;
  value: number;
}

const allKeys = ['1'];

const colors = {
  1: 'rgba(0, 185, 255, 1)',
  2: 'rgba(132, 146, 162, 0.8)',
};

const UnpaidBillsTab: React.FC = () => {
  const { isMobile } = useMobileScreen();

  const dispatch = useDispatch();
  const translations = useTranslations();
  const ts = translations.home.report;
  const params: { orgId?: string; reportName?: string } = useParams();
  const [time, setTime] = useState<ReactSelectTime>(ReactSelectTime.yesterday);

  const {
    agingBillsChart, reportSelectCards, monthlyAverage, loading,
  } = useTypeSelector(
    ({ dashboard }) => dashboard.report,
  );
  const user = useUser();

  const months = useMemo(() => [
    ts.re_jan,
    ts.re_feb,
    ts.re_mar,
    ts.re_apr,
    ts.re_may,
    ts.re_jun,
    ts.re_jul,
    ts.re_aug,
    ts.re_sep,
    ts.re_oct,
    ts.re_nov,
    ts.re_dec,
  ], [
    ts.re_apr,
    ts.re_aug,
    ts.re_dec,
    ts.re_feb,
    ts.re_jan, ts.re_jul, ts.re_jun, ts.re_mar, ts.re_may, ts.re_nov, ts.re_oct, ts.re_sep]);

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(
        getAgingBillsData(
          user.currentOrganisation.id,
          months,
        ),
      );
    }
  }, [dispatch, params.orgId, user?.currentOrganisation?.id, months]);

  useEffect(() => {
    if (!user || !params.orgId || !params?.reportName) {
      return;
    }

    if (!(user?.currentOrganisation?.id)) {
      return;
    }
    dispatch(getReportSelectCards(user.currentOrganisation.id, time, ReportReq.agedbills));
  }, [dispatch, params.orgId, params?.reportName, time, user]);

  const getTotal = () => agingBillsChart.data.map((item) => item.value).reduce((a, b) => a + b, 0);

  if (loading) {
    return <CustomSpinner variant="two" />;
  }

  return isMobile ? (
    <MobileUnpaidBillsTab
      total={getTotal()}
      time={time}
      setTime={setTime}
    />
  ) : (
    <>
      <div className={classes.content_container}>
        <div className={classes.chart_container}>
          <div className={classes.chart}>
            <div className={classes.chart_title}>
              <div className={classes.chart_years}>
                {getYear()} {ts.ytd}
              </div>
              <div className={getTotal() ? classes.chart_text : classes.chart_text_nodata}>
                {ts.bills}
              </div>
            </div>
            {!getTotal() ? (
              <div className={classes.nodata}>
                <div className={classes.lines}>
                  <div className={classes.line} />
                  <div className={classes.line} />
                  <div className={classes.line} />
                </div>
                <p>{ts.no_data_available_yet}</p>
              </div>
            ) : (
              <StackedGraph
                datasets={agingBillsChart.data}
                colors={colors}
                keys={allKeys}
                names={
                  {
                    first: ts.unpaid_bills_report.past_due_first,
                    second: ts.unpaid_bills_report.past_due_second,
                  }
                }
                isIso
              />
            )}
          </div>
        </div>
        <div className={classes.monthly_average}>
          <CardMonthlyAverage
            title={ts.unpaid_bills_report.month}
            value={monthlyAverage?.value}
            change={monthlyAverage?.percentage}
          />
        </div>
        <div className={classes.high}>
          <Card
            data={{
              title: ts.highest_month,
              label: agingBillsChart.highestMonth?.month,
              value: agingBillsChart.highestMonth?.value,
              second: ts.high,
              change: agingBillsChart.highestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.low}>
          <Card
            data={{
              title: ts.lowest_month,
              label: agingBillsChart.lowestMonth?.month,
              value: agingBillsChart.lowestMonth?.value,
              second: ts.low,
              change: agingBillsChart.lowestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.total}>
          <CardSelect
            data={{
              title: ts.all,
              value: reportSelectCards?.all?.entrytotal,
              label: `${reportSelectCards?.all?.count
                ? `${reportSelectCards?.all?.count} ${ts.tabs.re_orders}`
                : ''}`,
              change: reportSelectCards?.all?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        <div className={classes.paid}>
          <CardSelect
            data={{
              title: ts.unpaid_bills_report.past_due_second,
              value: reportSelectCards?.['aging30+']?.totalamount,
              label: `${reportSelectCards?.['aging30+']?.contacts
                ? `${reportSelectCards?.['aging30+']?.contacts} ${ts.tabs.re_customers.toLowerCase()}`
                : ''}`,
              change: reportSelectCards?.['aging30+']?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        <div className={classes.unpaid}>
          <CardSelect
            data={{
              title: ts.unpaid_bills_report.past_due_first,
              value: reportSelectCards?.aging30?.totalamount,
              label: `${reportSelectCards?.aging30?.contacts
                ? `${reportSelectCards?.aging30?.contacts} ${ts.tabs.re_vendors.toLowerCase()}`
                : ''}`,
              change: reportSelectCards?.aging30?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
      </div>
    </>
  );
};

export default UnpaidBillsTab;

import React, { InputHTMLAttributes, useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
// Components
import EyeButton from 'components/Buttons/EyeButton';
// Styles
import classes from './PasswordInput.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormReturn['register']
  name: string;
  disabled?: boolean;
  isEye?: boolean
}

const PasswordInput: React.FC<Props> = React.forwardRef(({
  register, name, disabled, isEye = true, children, ...rest
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
}, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  return (
    <div className={classes.inputs_wrapper}>
      <input
        className={classes.input}
        type={showPassword && isEye ? 'text' : 'password'}
        {...register(name)}
        {...rest}
        disabled={disabled}
      />
      {isEye && (
      <EyeButton
        active={showPassword}
        setActive={handleShowPassword}
        disabled={!!disabled}
      />
      )}
      {children}
    </div>
  );
});

export default PasswordInput;

import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { StripeKeys } from 'store/invoice/invoiceTypes';
import { Fees } from 'types/globalTypes';
import { OrderItem } from '../checkout/checkoutTypes';

export const ORDER_SET_LOADING = 'ORDER_SET_LOADING';
export const ORDER_SET_DATA = 'ORDER_SET_DATA';
export const ORDER_SET_PAYMENT = 'ORDER_SET_PAYMENT';

export interface OrderState {
  loading: boolean;
  data: IOrderData | undefined;
  payment: {
    loading: boolean;
    error: string | undefined;
    success: boolean;
    pay: boolean;
    stripe?: StripeKeys
  }
}

export interface IOrderData {
  id: string;
  associations: {
    id: string;
  } | null;
  contact: IOrderContact;
  reference: string;
  date: Date;
  fees: Fees[] | null;
  discountAmount: number | null;
  dueDate: Date;
  expiryDate: null | string;
  items: OrderItem[];
  notes: null | string;
  organisationId: number;
  fulfillmentDetails: IFulfillmentDetails;
  currencyISO: string;
  status: {
    id: number;
    name: 'UNPAID' | 'UNPROCESSED' | 'ACCEPTED' | 'PROCESSED' | 'CANCELLED';
    alias: 'Unpaid' | 'Unprocessed' | 'Accepted' | 'Processed' | 'Cancelled';
  }
  total: number;
  totalTax: number;
  type: string;
  balance: number;
  terms: {
    paymentDueDate: Date | string;
  };
  payments: {
    date: number,
    amount: string,
    paymentMethod: string,
  }[] | null;
}

export interface IOrderContact {
  id: string
  address: {
    city: string
    line1: string
    line2: string
    country: string
    postalCode: string
  }
  createdAt: string
  firstName: string
  lastName: string
  organisationName: null
  mobileNumber: string
  email: string
  updatedAt: string
  uid: string
  organisationId: string
}
export interface IFulfillmentDetails {
  fee: number;
  name: string;
  fulfillmentType: DeliveryMethodsEnum;
  country: { iso: string; name: string };
  address?: string;
  trackingId?: string;
  shippingCourier?: string;
  shippingPhone?: string;
  providerName?: string;
}

interface OrderSetLoadinAction {
  type: typeof ORDER_SET_LOADING;
  payload: boolean;
}
interface OrderGetAction {
  type: typeof ORDER_SET_DATA;
  payload: IOrderData;
}

interface OrderSetPaymentAction {
  type: typeof ORDER_SET_PAYMENT;
  payload: OrderState['payment'];
}

export type OrderActions =
  OrderSetLoadinAction |
  OrderGetAction |
  OrderSetPaymentAction;

import React from 'react';
import { UseFormReturn } from 'react-hook-form';
// Assets
import { ReactComponent as CheckMark } from 'assets/icons/check.svg';
// Styles
import classes from './Checkbox.module.scss';

interface Props {
  register: UseFormReturn['register']
  name: string;
  disabled?: boolean;
}

const Checkbox: React.FC<Props> = ({ register, name, disabled }) => (
  <div className={classes.checkbox}>
    <input
      className={classes.checkbox_input}
      type="checkbox"
      id={name}
      disabled={disabled}
      {...register(name)}
    />
    <label className={classes.checkbox_label} htmlFor={name}>
      <CheckMark />
    </label>
  </div>
);

export default Checkbox;

import { useTranslations } from 'hooks/useTranslations';
import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

const DocumentsRoute: React.FC<RouteProps> = (props) => {
  const translations = useTranslations();

  useEffect(() => {
    document.title = translations?.documents?.title_page;
  }, [translations?.documents?.title_page]);

  return (
    <Route {...props} />
  );
};

export default DocumentsRoute;

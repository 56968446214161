/* eslint-disable no-nested-ternary */
import React from 'react';
// styles
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { QuoteData, QuotePriceType } from 'store/quote/quoteTypes';
import { useDispatch } from 'react-redux';
import { SendItemsData } from 'store/quote/quoteActions';
import { useParams } from 'react-router-dom';
import classes from './DocumentsLayout.module.scss';
import Footer from './Footer';
import SideBar from './SideBar';

interface IParams {
  id: string;
}

interface Props {
  price: QuotePriceType;
  data: QuoteData;
  translations: TranslationDashboardModel;
  handleClick?: () => void;
  isUnpaid?: boolean;
  status?: string;
  type: TransactionTypes
}

const DocumentsLayout: React.FC<Props> = ({
  children,
  price,
  isUnpaid,
  translations,
  type,
  data,
  status,
}) => {
  const { id } = useParams<IParams>();
  const { windowWidth } = useMobileScreen();
  const mobileWidth = windowWidth && windowWidth > 425;
  const dispatch = useDispatch();
  const sendPricing = () => {
    dispatch(SendItemsData(id, data));
  };

  return (
    <HelmetProvider>
      <div className={classes.layout}>
        <Helmet>
          <meta name="description" content="Leja Invoices Features: ✓ Automatic Reminders, ✓ACH Payments, ✓ Online Invoices, ✓ Credit Card Payments, ✓ CRM." />
          <meta name="keywords" content="Online invoicing software, invoices, Leja invoices, estimates, expense tracking, invoicing, online invoices" />
        </Helmet>
        <div className={classes.wrapper}>
          <div className={classes.layout_content}>
            {children}
          </div>
          {mobileWidth && (
            <Footer
              type={type}
              translations={translations}
              price={price}
              handleClick={sendPricing}
              isUnpaid={isUnpaid}
              status={status}
              termsPayment={data.termsPayment}
            />
          )}
        </div>
        <SideBar
          items={data.items}
          price={price}
          translations={translations.sidebar_transaction}
          termsPayment={data.termsPayment}
        />
        {!mobileWidth && (
          <Footer
            type={type}
            translations={translations}
            price={price}
            status={status}
            handleClick={sendPricing}
            isUnpaid={isUnpaid}
            termsPayment={data.termsPayment}
          />
        )}
      </div>
    </HelmetProvider>
  );
};

export default DocumentsLayout;

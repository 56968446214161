import { FunctionComponent, SVGProps } from 'react';
// Images
import { ReactComponent as WhatsAppIcon } from 'assets/storefront-icons/order/socials/whatsapp.svg';
import { ReactComponent as TwitterIcon } from 'assets/storefront-icons/order/socials/twitter.svg';
import { ReactComponent as InstagramIcon } from 'assets/storefront-icons/order/socials/instagram.svg';
import { ReactComponent as FacebookIcon } from 'assets/storefront-icons/order/socials/facebook.svg';
// Types
import { SalesChannel } from 'store/storefront/shop/shopTypes';

export interface ISellerContactLink {
  title: string;
  image: FunctionComponent<SVGProps<SVGElement>>;
  link: string;
}

export class StoreFrontFunctions {
  static getContactSellerLinks(sellerContacts: SalesChannel['customerService']): ISellerContactLink[] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sellerContactsLinks = Object.entries(sellerContacts!).map(([key, value]) => {
      if (key === 'whatsapp') {
        return {
          title: 'WhatsApp',
          image: WhatsAppIcon,
          link: `https://wa.me/${value}`,
        };
      }

      if (key === 'instagram') {
        return {
          title: 'Instagram DM',
          image: InstagramIcon,
          link: `https://www.instagram.com/${value}`,
        };
      }

      if (key === 'twitter') {
        return {
          title: 'Twitter DM',
          image: TwitterIcon,
          link: `https://twitter.com/${value}`,
        };
      }

      if (key === 'facebook') {
        return {
          title: 'Facebook',
          image: FacebookIcon,
          link: `https://facebook.com/${value}`,
        };
      }

      return null;
    });

    return sellerContactsLinks as ISellerContactLink[];
  }
}

// Images
import GoogleLogo from 'assets/storefront-icons/payment/google.png';
import AlipayLogo from 'assets/storefront-icons/payment/alipay.png';
import VisaLogo from 'assets/storefront-icons/payment/visa.png';
import MastercardLogo from 'assets/storefront-icons/payment/mastercard.png';
import AmexLogo from 'assets/storefront-icons/payment/amex.png';
import UnionpayLogo from 'assets/storefront-icons/payment/unionpay.png';
import SofortLogo from 'assets/storefront-icons/payment/sofort.svg';
import GiroPayLogo from 'assets/storefront-icons/payment/giropay.svg';
import WeChatLogo from 'assets/storefront-icons/payment/wechat.png';
import IDealLogo from 'assets/storefront-icons/payment/ideal.png';
import EpsLogo from 'assets/storefront-icons/payment/eps.svg';
import BancontactLogo from 'assets/storefront-icons/payment/bancontact.svg';
import P24Logo from 'assets/storefront-icons/payment/p24.svg';
import GrabPayLogo from 'assets/storefront-icons/payment/grab-pay.png';
import SEPALogo from 'assets/storefront-icons/payment/sepa.png';
import BoletoLogo from 'assets/storefront-icons/payment/boleto.png';
import OxxoLogo from 'assets/storefront-icons/payment/oxxo.png';
import AppleLogo from 'assets/storefront-icons/payment/apple.png';
import BacsLogo from 'assets/storefront-icons/payment/bacs.png';
import ElevenLogo from 'assets/storefront-icons/payment/eleven.png';
import FamilyMartLogo from 'assets/storefront-icons/payment/family-mart.png';
import LawsonLogo from 'assets/storefront-icons/payment/lawson.png';
import PaypalLogo from 'assets/storefront-icons/payment/paypal.png';
import KlarnaLogo from 'assets/storefront-icons/payment/klarna.svg';
import AfterpayClearpayLogo from 'assets/storefront-icons/payment/afterpay.svg';

export enum PayMethodsEnum {
  ALIPAY = 'alipay',
  WECHAT = 'wechat_pay',
  CARD = 'card',
  APPLE = 'apple_pay',
  GOOGLE = 'google_pay',
  SOFORT = 'sofort',
  GIROPAY = 'giropay',
  IDEAL = 'ideal',
  EPS = 'eps',
  BANCONTACT = 'bancontact',
  P24 = 'p24',
  GRABPAY = 'grabpay',
  BOLETO = 'boleto',
  OXXO = 'oxxo',
  BACS = 'bacs_debit',
  SEPA = 'sepa_debit',
  BLIK = 'blik',
  KONBINI = 'konbini',
  PAYPAL = 'paypal',
  KLARNA = 'klarna',
  AFTERPAY_CLEARPAY = 'afterpay_clearpay',
  PAY_LATER = 'payLater',
}

export const euCountries = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB'];
export const supportBLIKPayCountries = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GI', 'GR', 'HR', 'HU', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK'];
export const cardLogos = [UnionpayLogo, VisaLogo, MastercardLogo, AmexLogo];

export const klarnaSupportCountries = ['AT', 'BE', 'DE', 'DK', 'ES', 'FI', 'GB', 'IE', 'IT', 'NL', 'NO', 'SE', 'US', 'FR', 'EE', 'GR', 'LV', 'LT', 'SK', 'SI'];
export const klarnaSupportCurrencies = ['EUR', 'DKK', 'GBP', 'NOK', 'SEK', 'USD', 'AUD', 'NZD', 'CAD', 'PLN', 'CHF'];
export const klarnaSupportCustomerCountries = ['AT', 'BE', 'DE', 'DK', 'ES', 'FI', 'GB', 'IE', 'IT', 'NL', 'NO', 'SE', 'FR'];

export const afterpayClearpaySupportCountries = ['US', 'CA', 'GB', 'AU', 'NZ', 'FR', 'ES'];
export const afterpayClearpaySupportCurrencies = ['USD', 'CAD', 'GBP', 'AUD', 'NZD', 'EUR'];
export const afterpayClearpaySupportCustomerCountries = ['US', 'AU', 'NZ', 'UK', 'FR'];

export const supportSofort = ['AT', 'BE', 'DE', 'ES', 'IT', 'NL'];

export const PaymentsOptions = [
  {
    name: PayMethodsEnum.ALIPAY,
    isStripe: true,
    logos: [AlipayLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.WECHAT,
    isStripe: true,
    logos: [WeChatLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.CARD,
    isStripe: true,
    logos: [UnionpayLogo, VisaLogo, MastercardLogo, AmexLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.APPLE,
    isStripe: true,
    logos: [AppleLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.GOOGLE,
    isStripe: true,
    logos: [GoogleLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.SOFORT,
    isStripe: true,
    logos: [SofortLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.GIROPAY,
    isStripe: true,
    logos: [GiroPayLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.IDEAL,
    isStripe: true,
    logos: [IDealLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.EPS,
    isStripe: true,
    logos: [EpsLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.BANCONTACT,
    isStripe: true,
    logos: [BancontactLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.P24,
    isStripe: true,
    logos: [P24Logo],
    show: true,
  },
  {
    name: PayMethodsEnum.GRABPAY,
    isStripe: true,
    logos: [GrabPayLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.BOLETO,
    isStripe: true,
    logos: [BoletoLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.OXXO,
    isStripe: true,
    logos: [OxxoLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.BACS,
    isStripe: true,
    logos: [BacsLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.KONBINI,
    isStripe: true,
    logos: [ElevenLogo, FamilyMartLogo, LawsonLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.BLIK,
    isStripe: true,
    logos: [PaypalLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.SEPA,
    isStripe: true,
    logos: [SEPALogo],
    show: true,
  },
  {
    name: PayMethodsEnum.PAYPAL,
    isStripe: false,
    logos: [PaypalLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.KLARNA,
    isStripe: true,
    logos: [KlarnaLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.AFTERPAY_CLEARPAY,
    isStripe: true,
    logos: [AfterpayClearpayLogo],
    show: true,
  },
  {
    name: PayMethodsEnum.PAY_LATER,
    isStripe: true,
    logos: [],
    show: true,
  },
];

export const enum PaymentOptionsEnum {
  BANK_TRANSFER = 'Bank transfer',
  CARD = 'Card',
  CASH = 'Cash',
  GOOGLE_PAY = 'Google Pay',
  APPLE_PAY = 'Apple Pay',
  OTHER = 'Other',
}

import Modal from 'components/Modal';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import React from 'react';
// Styles
import classes from './PaymentError.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
}

const PaymentError: React.FC<Props> = ({ active, setActive }) => {
  const { checkout } = useTranslationsStorefront();
  const paymentErrTs = checkout.payment_error_modal;

  return (
    <Modal active={active} setActive={setActive} type="four">
      <div className={classes.main}>
        <h2>
          {paymentErrTs.title}
        </h2>
        <div>
          {paymentErrTs.description}
          <ul>
            {paymentErrTs.points.map((point) => (
              <li key={point}>
                {point}
              </li>
            ))}
          </ul>
        </div>
        <button type="button" onClick={setActive}>
          {paymentErrTs.title_btn}
        </button>
      </div>
    </Modal>
  );
};

export default PaymentError;

import React from 'react';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Types
import { Items, PriceType } from 'types/globalTypes';
// Components
import Item from './Item/Item';
import Total from './Total';
// Styles
import classes from './ItemsTable.module.scss';

interface Props {
  items: Items[];
  price: PriceType;
}

const ItemsTable: React.FC<Props> = ({ items, price }) => {
  const {
    cart: { cart_item: translation },
  } = useTranslationsStorefront();
  const { isMobile } = useMobileScreen();

  if (isMobile) {
    return (
      <div className={classes.items}>
        <p className={classes.items_title}>{translation.item_service_text}</p>
        {items.map((item) => (
          <Item
            key={item.id}
            data={item}
            currencyIso={price.currencyIso}
            translation={translation}
          />
        ))}
        <Total translation={translation} price={price} items={items} />
      </div>
    );
  }

  return (
    <div className={classes.items}>
      <div className={classes.items_headers}>
        <p>{translation.item_service_text}</p>
        <p>{translation.quantity}</p>
        <p>{translation.unit_price}</p>
        <p>{translation.total}</p>
      </div>
      {items.map((item) => (
        <Item
          key={item.id}
          data={item}
          currencyIso={price.currencyIso}
          translation={translation}
        />
      ))}
      <Total translation={translation} price={price} items={items} />
    </div>
  );
};

export default ItemsTable;

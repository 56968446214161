import React, { useState } from 'react';
// Images
import { ReactComponent as DownIcon } from 'assets/icons/down-chevron.svg';
// Styles
import { useTranslations } from 'hooks/useTranslations';
import classes from './Menu.module.scss';

interface Option {
  value: string;
  label: string;
}

interface Props {
  options: Option[];
  isSelected: (value: React.SetStateAction<string>) => void
  onClick: () => void;
  defaultValue?: Option;
}

const Menu: React.FC<Props> = ({
  options, isSelected, defaultValue, onClick,
}) => {
  const [isOpen, setOpen] = useState(false);
  const translations = useTranslations();

  return (
    <div className={classes.menu}>
      <button type="button" className={classes.menu_button} onClick={onClick}>
        {translations.learn_more}
      </button>
      <div className={classes.menu_accordion}>
        <div
          role="button"
          tabIndex={0}
          className={classes.accordion_header}
          onClick={() => setOpen(!isOpen)}
        >
          <h3>{translations.languages}</h3>
          <DownIcon className={isOpen ? classes.icon_open : classes.icon} />
        </div>
        <div className={isOpen
          ? `${classes.accordion_content} ${classes.open}`
          : classes.accordion_content}
        >
          {options.map((lang) => (
            <div tabIndex={0} role="button" onClick={() => isSelected(lang.value)} className={classes.lang}>
              <p>{lang.label}</p>
              {
                defaultValue?.value === lang.value
                && <DownIcon className={classes.lang_icon} />
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Menu;

import React from 'react';
import { Link } from 'react-router-dom';
// Images
import { ReactComponent as LejaLogo } from 'assets/icons/leja-loading.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/white-left-arrow.svg';
// Styles
import { useTranslations } from 'hooks/useTranslations';
import classes from './Error404.module.scss';

const Error404: React.FC = () => {
  const translations = useTranslations();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <LejaLogo />
        <h1>
          {translations.not_found_page.title}
        </h1>
        <Link to="/dashboard">
          <ArrowLeft className={classes.arrow_icon} />
          {translations.not_found_page.link}
        </Link>
      </div>
    </div>
  );
};

export default Error404;

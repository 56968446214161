import React, { useCallback, useState } from 'react';

interface IUseFormField {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const useFormField = (initialValue = ''): IUseFormField => {
  const [value, setValue] = useState(initialValue);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    [],
  );
  return { value, onChange };
};

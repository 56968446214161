import React from 'react';
// Styles
import classes from './Title.module.scss';

interface Props {
  name: string;
  number: string
}

const Title: React.FC<Props> = ({ name, number }) => (
  <div className={classes.title}>
    <p className={classes.title_name}>
      {name}
    </p>
    <p className={classes.title_number}>
      № {number}
    </p>
  </div>
);

export default Title;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { GetQuoteData } from 'store/quote/quoteActions';
import { getTranslations } from 'store/auth/authActions';
// Types
import { TransactionStatus } from 'types/globalTypes';
// Components
import DocumentsGetApp from 'components/Documents/GetApp';
import ModalSuccess from 'components/Modal/ModalSuccess';
import LoadingPage from 'pages/LoadingPage';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import TransactionOverview from './TransactionOverview';
import DocumentsLayout from './Layout';

interface IParams {
  id: string;
}

const Quote: React.FC = () => {
  const { id } = useParams<IParams>();
  const {
    loading,
    data,
    sent,
    loadingSent,
  } = useTypeSelector((state) => state.quote);
  const {
    isTranslationsFetched,
    activeTranslation,
  } = useTypeSelector(({ dashboard }) => dashboard.app);
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const tsQuotes = activeTranslation.documents.quotes;

  useEffect(() => {
    if (!isTranslationsFetched) {
      dispatch(getTranslations('en'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTranslationsFetched]);

  const status = () => {
    if (!data) {
      return '';
    }

    if (sent) {
      return tsQuotes.sent;
    }

    if (data?.termsPayment.isExpired) {
      return tsQuotes.expired;
    }

    return activeTranslation.status[data?.details.status as keyof TranslationDashboardModel['status']];
  };

  const handleModalSuccessClose = () => {
    setSuccess(!success);
  };

  useEffect(() => {
    setSuccess(sent);
  }, [sent]);

  useEffect(() => {
    dispatch(GetQuoteData(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loadingSent) {
    return (
      <LoadingPage
        title={activeTranslation.documents.quotes.loading}
      />
    );
  }

  if (!data || loading) {
    return (
      <LoadingPage />
    );
  }

  return (
    <DocumentsLayout
      translations={activeTranslation}
      data={data}
      price={data.price}
      type={data.details.type}
      status={data.details.status}
      isUnpaid={data.items.some((i) => i.unitPrice)}
    >
      {/* <Contacts
        organisation={data.organisation}
        contact={data.contact}
        type={data.details.type}
        // translations={activeTranslation.documents.contacts}
      /> */}
      <TransactionOverview
        details={data.details}
        price={data.price}
        termsPayment={data.termsPayment}
        isUnpaid={status() === TransactionStatus.PENDING}
        translations={activeTranslation.transaction_overview}
      />
      {success && (
        <ModalSuccess
          onClick={handleModalSuccessClose}
          btnTitle={tsQuotes.modal_success.btn_title}
          text={tsQuotes.modal_success.text.replace('{orgId}', data.organisation.name)}
          title={tsQuotes.modal_success.title}
          closeButton
        />
      )}
      {/* <DocumentsGetApp
        type={data.details.type}
        name={data.contact.fullName}
        phone={`${data.organisation.country.mobileIDD} ${data.contact?.phone}`}
        text={tsQuotes.get_app_text}
      /> */}
    </DocumentsLayout>
  );
};

export default Quote;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
// Dashboard components
import Login from 'pages/Login';
// 404 component
import Error404 from 'pages/Error404';
import ShopItemDetails from 'pages/Storefront/ShopItemDetails';
import { DashboardRoutes, DocumentsRoutes, StorefrontRoutes } from './Routes';
import { StorefrontRoutePath } from './Routes/Storefront';

const Router: React.FC = () => {
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const background = location.state && (location?.state as any).background;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  return (
    <div>
      <Switch location={background || location}>
        <Route exact path="/login" component={Login} />
        {StorefrontRoutes}
        {DocumentsRoutes}
        {DashboardRoutes}
        <Redirect from="/" to="/dashboard" />
        <Route component={Error404} />
      </Switch>
      {background
        // eslint-disable-next-line react/no-children-prop
        && <Route path={StorefrontRoutePath.PRODUCT_DETAILS} children={<ShopItemDetails />} />}
    </div>
  );
};

export default Router;

/* eslint-disable max-len */
import { useUser } from 'hooks/useUser';
import React from 'react';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
// Assets
import { ReactComponent as ArrowDown } from 'assets/dashboard-icons/arrow-down.svg';
// Utils
import { getYear } from 'utils/dates';
import { CurrencyFormatByISO } from 'utils/price';
// Styles
import classes from './Card.module.scss';

interface Props {
  data: {
    title: string;
    label?: string;
    value?: number;
    second?: string;
    change?: number;
  };
  type?: 'one' | 'two' | 'three';
  unit?: string;
}

const Card: React.FC<Props> = ({
  data, unit, type,
}) => {
  const user = useUser();
  const { isMobile } = useMobileScreen();
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.country?.currencyISO);
  const translations = useTranslations();
  const isUnit = (v: number | undefined) => (unit && v ? (
    <span>
      {v}
      &nbsp;
      <span className={classes.unit}>
        {unit}
      </span>
    </span>
  ) : formatCurrencyByISO(v));
  const ts = translations.home.report;

  if (type === 'two') {
    return (
      <div className={classes.card_two}>
        <h2 className={classes.title}>{data.title}</h2>
        <h3
          className={classes.subtitle}
          style={
            data.value || data.value === 0
              ? { color: 'rgba(107, 105, 116, 1)' }
              : { color: 'rgb(107, 105, 116, 0.5)' }
          }
        >
          {data.value || data.value === 0 ? data.label : '--'}
        </h3>
        <div className={classes.details}>
          <div
            className={classes.value}
            style={
              data.value || data.value === 0
                ? { color: 'rgba(107, 89, 237, 1)' }
                : { color: 'rgb(203, 198, 215)' }
            }
          >
            {isUnit(data.value)}
          </div>
          {data.value || data.value === 0 ? (
            <div className={classes.right_side}>
              <div
                className={
                  Math.sign(data.change ? data.change : 0) === 1
                    ? classes.percents_container_growth
                    : classes.percents_container_decline
                }
              >
                {data.change !== 0 && (
                  <div>
                    <ArrowDown />
                  </div>
                )}
                <p
                  className={
                    `${data.change}%` === '0%' ? classes.percents_zero : classes.percents
                  }
                >
                  {Math.sign(data.change ? data.change : 0) === 1
                    ? `+${data.change}% ${ts.growth}`
                    : `${data.change}% ${data.change !== 0 && data.change ? ts.decline : ''
                    }`}
                </p>
              </div>
              <p className={classes.vs_previous}>{ts.vs_previous} {data.second}</p>
              {!isMobile && <h6 className={classes.ytd}> {`${getYear()} ${ts.ytd}`}</h6>}
            </div>
          ) : (
            <span className={classes.nodata}>{ts.no_data_available_yet}</span>
          )}
        </div>
        {isMobile && <h6 className={classes.ytd}> {`${getYear()} ${ts.ytd}`}</h6>}
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <h2 className={classes.title}>{data.title}</h2>
      <h3
        className={classes.subtitle}
        style={
          data.value || data.value === 0
            ? { color: 'rgba(107, 105, 116, 1)' }
            : { color: 'rgb(107, 105, 116, 0.5)' }
        }
      >
        {data.value || data.value === 0 ? data.label : '--'}
      </h3>
      <div className={classes.details}>
        <div
          className={classes.value}
          style={
            data.value || data.value === 0
              ? { color: 'rgba(107, 89, 237, 1)' }
              : { color: 'rgb(203, 198, 215)' }
          }
        >
          {isUnit(data.value)}
        </div>
        {data.value || data.value === 0 ? (
          <div className={classes.right_side}>
            <div
              className={
                Math.sign(data.change ? data.change : 0) === 1
                  ? classes.percents_container_growth
                  : classes.percents_container_decline
              }
            >
              {data.change !== 0 && (
                <div>
                  <ArrowDown />
                </div>
              )}
              <p
                className={
                  `${data.change}%` === '0%' ? classes.percents_zero : classes.percents
                }
              >
                {Math.sign(data.change ? data.change : 0) === 1
                  ? `+${data.change}% ${ts.growth}`
                  : `${data.change}% ${data.change !== 0 && data.change ? ts.decline : ''
                  }`}
              </p>
            </div>
            <p className={classes.vs_previous}>{ts.vs_previous} {data.second}</p>
            <p className={classes.ytd}> {`${getYear()} ${ts.ytd}`}</p>
          </div>
        ) : (
          <>
            <span className={classes.nodata}>{ts.no_data_available_yet}</span>
            {isMobile && <p className={classes.ytd}>{`${getYear()} ${ts.ytd}`}</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default Card;

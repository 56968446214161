import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import isEqual from 'lodash/isEqual';
import { useQuery } from 'hooks/useQuery';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
// Actions
import {
  getShop, getCategories, SetAddToBagAction, getSalesChannel,
} from 'store/storefront/shop/shopActions';
import { getGeoInfo, getTranslations, setNotificationAction } from 'store/storefront/app/appActions';
// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close-white.svg';
// Utils
import { isEmptyObject } from 'utils/functions';
import { CurrencyFormatByISO } from 'utils/price';
// Types
import { CartItemType, NotificationEnum } from 'store/storefront/shop/shopTypes';
// Components
import Footer from 'layouts/StorefrontLayout/Footer';
import Notification from 'components/StorefrontComponents/Notification';
import CompanyAvatar from 'components/StorefrontComponents/CompanyAvatar';
import NavBar from './NavBar';
import Bag from './NavBar/Bag';
// Styles
import classes from './StorefrontLayout.module.scss';

interface Props {
  type?: 'one' | 'two';
  handleClose?: () => void;
}

const StorefrontLayout: React.FC<Props> = ({ children, type, handleClose }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { isMobile } = useMobileScreen();
  const {
    isTranslationsFetched,
    activeTranslation,
    notification,
    geoInfo,
  } = useTypeSelector(({ storefront }) => storefront.app);
  const {
    products,
    categories,
    bag,
    shop,
    currentSalesChannel,
  } = useTypeSelector(({ storefront }) => storefront.shop);

  const { goToCart, goBack, goToShop } = useStorefrontRoutes();
  const query = useQuery();

  const location = useLocation();

  const formatCurrencyByISO = CurrencyFormatByISO(currentSalesChannel?.address?.currencyISO);

  const handleShop = useCallback(() => {
    goToShop(id, location.search);
  }, [goToShop, id, location.search]);

  const handleBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const handleToCart = useCallback(() => {
    dispatch(setNotificationAction(null));
    goToCart(id, location);
  }, [dispatch, goToCart, id, location]);

  const handleCloseNotification = useCallback(() => {
    dispatch(setNotificationAction(null));
  }, [dispatch]);

  useEffect(() => {
    if (categories.data.length === 0 && shop.businessAs) {
      dispatch(getCategories(id));
    }
  }, [shop.businessAs, categories.data.length, dispatch, id]);

  useEffect(() => {
    if (isEmptyObject(shop) && typeof id !== 'undefined') {
      dispatch(getShop(id));
    }
  }, [id, dispatch, shop]);

  useEffect(() => {
    if (typeof id === 'undefined') {
      return;
    }

    const cachedCart = localStorage.getItem(`leja-${id}-cart`);

    if (!cachedCart || typeof cachedCart === 'undefined') {
      return;
    }

    const cart: CartItemType[] = JSON.parse(cachedCart);
    if (isEqual(cart, bag)) {
      return;
    }
    dispatch(SetAddToBagAction(cart));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.loading, id]);

  useEffect(() => {
    if (typeof id !== 'undefined' && !currentSalesChannel && shop.businessAs) {
      dispatch(getSalesChannel(id, query.get('scid')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop.businessAs]);

  useEffect(() => {
    if (!geoInfo) {
      dispatch(getGeoInfo());
    }
  }, [dispatch, geoInfo]);

  useEffect(() => {
    if (!isTranslationsFetched) {
      dispatch(getTranslations('en'));
    }
  }, [isTranslationsFetched, dispatch]);

  if (type === 'two') {
    return (
      <div className={classes.header_two}>
        <header>
          {currentSalesChannel?.name && currentSalesChannel.logoUrl
            && (
              <CompanyAvatar
                title={currentSalesChannel?.name}
                logoUrl={currentSalesChannel?.logoUrl}
                onClick={handleShop}
              />
            )}
          <CloseIcon onClick={handleBack || handleClose} />
        </header>
      </div>
    );
  }

  return (
    <div className={classes.storefront}>
      <div className={classes.header}>
        <NavBar />
      </div>
      <div className={classes.storefront_wrapper}>
        {!query.has('search') && (
          <main className={classes.storefront__content}>
            {children}
          </main>
        )}
      </div>
      {isMobile && (
        <Bag
          formatCurrencyByISO={formatCurrencyByISO}
          bag={bag}
        />
      )}
      {!isMobile && <Footer />}
      {notification === NotificationEnum.ADD_TO_CART && (
        <Notification
          text={activeTranslation.cart.notification.text}
          active={notification === NotificationEnum.ADD_TO_CART}
          handleClose={handleCloseNotification}
          onClick={handleToCart}
          textBtn={activeTranslation.cart.notification.btn_text}
          variant="two"
        />
      )}
    </div>
  );
};
export default StorefrontLayout;

import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  AutoSizer,
  InfiniteLoader,
  List,
} from 'react-virtualized';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Actions
import { getMoreOrders, getOrders } from 'store/storefront/account/accountActions';
// Components
import AccountLayout from 'layouts/StorefrontLayout/AccountLayout';
import Card from './Card';

const AccountOrders: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    orders,
    isNextPageOrdersLoading,
  } = useTypeSelector(({ storefront }) => storefront.account);
  const { shop } = useTypeSelector(({ storefront }) => storefront.shop);
  const { isMobile } = useMobileScreen();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!orders && shop.businessAs) {
      dispatch(getOrders(id));
    }
  }, [dispatch, id, orders, shop.businessAs]);

  const rowRenderer = useCallback(({
    key, index, style,
  }) => orders && (
    <Card order={orders[index]} key={key} style={style} />
  ), [orders]);

  const isRowLoaded = useCallback((index) => !!orders?.[index], [orders]);

  const handleNewPageLoad = useCallback(async () => {
    if (isNextPageOrdersLoading) {
      dispatch(getMoreOrders(id, page));
    }
    setPage((prev) => prev + 1);
  }, [dispatch, id, isNextPageOrdersLoading, page]);

  const loadMoreRows = useCallback(async () => {
    if (isNextPageOrdersLoading) {
      handleNewPageLoad();
    }
  }, [handleNewPageLoad, isNextPageOrdersLoading]);

  if (!orders || orders.length === 0) {
    return (
      <AccountLayout />
    );
  }

  return (
    <AccountLayout>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={1000}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                onRowsRendered={onRowsRendered}
                height={height}
                ref={registerChild}
                rowCount={orders.length}
                rowHeight={isMobile ? 180 : 100}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </AccountLayout>
  );
};

export default AccountOrders;

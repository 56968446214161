import React from 'react';
import cn from 'classnames';
// Hooks
import { useMobileScreen } from 'hooks/useMobileScreen';
// Types
import { IOrderData } from 'store/storefront/order/orderTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Components
import Item from './Item';
// Styles
import classes from './OrderItems.module.scss';
import Total from './Total';

interface Props {
  items: IOrderData['items'];
  currencyIso: string;
  translation: TranslationStorefrontModel['cart']['cart_item']
  status: IOrderData['status']['name'];
}

const OrderItems: React.FC<Props> = ({
  items, currencyIso, translation, status,
}) => {
  const { isMobile } = useMobileScreen();

  if (isMobile) {
    return (
      <div className={cn(classes.items, {
        [classes.items_cancelled]: status === 'CANCELLED',
      })}
      >
        <p className={classes.items_title}>
          {translation.items}
        </p>
        {items.map((item) => (
          <Item
            key={item.sku}
            data={item}
            currencyIso={currencyIso}
            translation={translation}
          />
        ))}
        <Total translation={translation} />
      </div>
    );
  }

  return (
    <div className={cn(classes.items, {
      [classes.items_cancelled]: status === 'CANCELLED',
    })}
    >
      <div className={classes.items_headers}>
        <p>
          {translation.single_item_text}
        </p>
        <p>
          {translation.quantity}
        </p>
        <p>
          {translation.unit_price}
        </p>
        <p>
          {translation.total}
        </p>
      </div>
      {items.map((item) => (
        <Item
          key={item.sku}
          data={item}
          currencyIso={currencyIso}
          translation={translation}
        />
      ))}
      <Total translation={translation} />
    </div>
  );
};

export default OrderItems;

import React, {
  Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
import { useMobileScreen } from 'hooks/useMobileScreen';
// constants
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
// Actions
import { selectOrderDetails } from 'store/dashboard/orders/orderSelectors';
import { processOrder } from 'store/dashboard/orders/orderActions';
// Types
import { ReactSelectOption, TransactionStatus } from 'types/globalTypes';
// Assets
import { ReactComponent as CloseIcon } from 'assets/dashboard-icons/close-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/back-arrow.svg';
// Components
import Modal from 'components/Modal';
// Styles
import SelectPickupAddress from 'components/DashboardComponents/SelectPickupAddress';
import { CurrencyFormatByISO } from 'utils/price';
import classes from './ProcessModal.module.scss';

interface ModalProps {
  setActive: Dispatch<SetStateAction<boolean>>;
  active: boolean;
}

const ProcessModal: React.FC<ModalProps> = ({ setActive, active }) => {
  const params: { orderId?: string } = useParams();
  const orderDetails = useTypeSelector(selectOrderDetails);
  const [shipValues, setShipValues] = useState({
    providerName: orderDetails?.fulfillmentDetails?.providerName || '',
    trackingId: orderDetails?.fulfillmentDetails?.trackingId || '',
  });
  const [pickUpValues, setPickUpValues] = useState('');
  const [deliveryValues, setDeliveryValues] = useState({
    shippingCourier: '',
    shippingPhone: '',
  });
  const user = useUser();
  const translations = useTranslations();
  const dispatch = useDispatch();
  const formatCurrencyByISO = CurrencyFormatByISO(orderDetails?.price.currencyIso);
  const { isMobile } = useMobileScreen();
  const { locations } = useTypeSelector((state) => state.dashboard.catalog.items);
  const countItems = orderDetails?.items.reduce((sum, item) => sum + item.quantity, 0);

  const handleClose = () => {
    setActive(false);
  };

  const handleChange = (e: ReactSelectOption | null) => {
    setPickUpValues(String(e?.value));
  };

  const currentSalesChannel = locations?.find(
    (salesChannel) => salesChannel.name === orderDetails?.fulfillmentDetails?.name,
  );

  const pickupLocations = currentSalesChannel?.fulfilment?.pickup.locations.map((location) => ({
    label: location.address,
    value: location.address,
  }));

  const defaultPickupLocation = useMemo(() => pickupLocations?.find(
    (
      pickupLocation,
    ) => pickupLocation.label === orderDetails?.fulfillmentDetails?.address,
  ) || null, [orderDetails?.fulfillmentDetails?.address, pickupLocations]);

  useEffect(() => {
    setDeliveryValues({
      shippingCourier: orderDetails?.fulfillmentDetails?.shippingCourier || '',
      shippingPhone: orderDetails?.fulfillmentDetails?.shippingPhone || '',
    });
  }, [
    orderDetails?.fulfillmentDetails?.shippingCourier,
    orderDetails?.fulfillmentDetails?.shippingPhone,
  ]);

  const shipped = useCallback(
    () => {
      if (params.orderId && user?.currentOrganisation?.id && orderDetails) {
        setActive(false);
        dispatch(processOrder(
          params.orderId,
          user?.currentOrganisation?.id,
          TransactionStatus.PROCESSED,
          orderDetails,
          {
            providerName: shipValues.providerName,
            trackingId: shipValues.trackingId,
          },
        ));
      }
    },
    [
      dispatch,
      orderDetails,
      params.orderId,
      setActive, shipValues, user?.currentOrganisation?.id],
  );

  const pickUp = useCallback(
    () => {
      if (params.orderId && user?.currentOrganisation?.id && orderDetails) {
        setActive(false);
        dispatch(processOrder(
          params.orderId,
          user?.currentOrganisation?.id,
          TransactionStatus.PROCESSED,
          orderDetails,
          {
            address: pickUpValues || defaultPickupLocation?.value,
          },
        ));
      }
    },
    [
      defaultPickupLocation?.value,
      dispatch,
      orderDetails, params.orderId, pickUpValues, setActive, user?.currentOrganisation?.id],
  );

  const outOfDelivery = useCallback(
    () => {
      if (params.orderId && user?.currentOrganisation?.id && orderDetails) {
        dispatch(processOrder(
          params.orderId,
          user?.currentOrganisation?.id,
          TransactionStatus.PROCESSED,
          orderDetails,
          { ...deliveryValues },
        ));
        setActive(false);
      }
    },
    [
      deliveryValues,
      dispatch, orderDetails, params.orderId, setActive, user?.currentOrganisation?.id],
  );

  if (orderDetails?.fulfillmentName === DeliveryMethodsEnum.SHIPPING) {
    return (
      <Modal active={active} setActive={setActive} type="three">
        <div className={classes.process_modal}>
          {isMobile ? (
            <ArrowIcon
              className={classes.close_icon}
              onClick={handleClose}
            />
          ) : (
            <CloseIcon
              className={classes.close_icon}
              onClick={handleClose}
            />
          )}
          <div className={classes.content}>
            <h2>
              {translations.orders.process_modal.shipped_title}
            </h2>
            <div className={classes.order}>
              <p className={classes.order_id}>
                {orderDetails.orderName}
              </p>
              <p className={classes.order_price}>
                {formatCurrencyByISO(orderDetails.price.total)}
              </p>
            </div>
            <div className={classes.items}>
              <p className={classes.items_labels}>
                {translations.orders.process_modal.items_text}
              </p>
              <p className={classes.items_value}>
                {countItems}
              </p>
            </div>
            <div className={classes.delivery}>
              <p className={classes.delivery_label}>
                {translations.orders.process_modal.delivery_method}
              </p>
              <p className={classes.delivery_value}>
                {translations.orders.process_modal.shipping_text}
              </p>
            </div>
            <div className={classes.shipping_provider}>
              <p className={classes.shipping_provider_label}>
                {translations.orders.process_modal.shipping_provider_text}
              </p>
              <input
                className={classes.shipping_provider_input}
                placeholder={orderDetails.fulfillmentDetails?.providerName}
                value={shipValues.providerName}
                onChange={(e) => setShipValues((prev) => ({
                  ...prev,
                  providerName: e.target.value,
                }))}
              />
            </div>
            <div className={classes.tracking}>
              <p className={classes.tracking_label}>
                {translations.orders.process_modal.tracking}
              </p>
              <input
                className={classes.tracking_input}
                value={shipValues.trackingId}
                onChange={(e) => setShipValues((prev) => ({
                  ...prev,
                  trackingId: e.target.value,
                }))}
              />
            </div>
            <div className={classes.shipped_btn_wrapper}>
              <button
                type="button"
                className={classes.shipped_btn}
                disabled={!shipValues.providerName || !shipValues.trackingId}
                onClick={shipped}
              >
                {translations.orders.process_modal.shipped_btn}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  if (orderDetails?.fulfillmentName === DeliveryMethodsEnum.PICKUP) {
    return (
      <Modal active={active} setActive={setActive} type="three">
        <div className={classes.process_modal}>
          {isMobile ? (
            <ArrowIcon
              className={classes.close_icon}
              onClick={handleClose}
            />
          ) : (
            <CloseIcon
              className={classes.close_icon}
              onClick={handleClose}
            />
          )}
          <div className={classes.content}>
            <h2>
              {translations.orders.process_modal.pickup_title}
            </h2>
            <div className={classes.order}>
              <p className={classes.order_id}>
                {orderDetails?.orderName}
              </p>
              <p className={classes.order_price}>
                {formatCurrencyByISO(orderDetails.price.total)}
              </p>
            </div>
            <div className={classes.items}>
              <p className={classes.items_labels}>
                {translations.orders.process_modal.items_text}
              </p>
              <p className={classes.items_value}>
                {countItems}
              </p>
            </div>
            <div className={classes.delivery}>
              <p className={classes.delivery_label}>
                {translations.orders.process_modal.delivery_method}
              </p>
              <p className={classes.delivery_value}>
                {translations.orders.process_modal.in_store_pickup}
              </p>
            </div>
            <div className={classes.pickup_select}>
              <p className={classes.pickup_select_label}>
                {translations.orders.process_modal.pickup_location}
              </p>
              {pickupLocations
                && (
                  <SelectPickupAddress
                    options={pickupLocations as ReactSelectOption[]}
                    defaultValue={defaultPickupLocation}
                    handleChange={handleChange}
                  />
                )}
            </div>
            <div className={classes.shipped_btn_wrapper}>
              <button type="button" className={classes.shipped_btn} onClick={pickUp}>
                {translations.orders.process_modal.pickup_btn}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal active={active} setActive={setActive} type="three">
      <div className={classes.process_modal}>
        {isMobile ? (
          <ArrowIcon
            className={classes.close_icon}
            onClick={handleClose}
          />
        ) : (
          <CloseIcon
            className={classes.close_icon}
            onClick={handleClose}
          />
        )}
        <div className={classes.content}>
          <h2>
            {translations.orders.process_modal.delivery_title}
          </h2>
          <div className={classes.order}>
            <p className={classes.order_id}>
              {orderDetails?.orderName}
            </p>
            <p className={classes.order_price}>
              {formatCurrencyByISO(orderDetails?.price.total)}
            </p>
          </div>
          <div className={classes.items}>
            <p className={classes.items_labels}>
              {translations.orders.process_modal.items_text}
            </p>
            <p className={classes.items_value}>
              {countItems}
            </p>
          </div>
          <div className={classes.delivery}>
            <p className={classes.delivery_label}>
              {translations.orders.process_modal.delivery_method}
            </p>
            <p className={classes.delivery_value}>
              {translations.orders.process_modal.delivery_text}
            </p>
          </div>
          <div className={classes.shipping_provider}>
            <p className={classes.shipping_provider_label}>
              {isMobile
                ? translations.orders.process_modal.driver_name_mobile_text
                : translations.orders.process_modal.driver_name}
            </p>
            <input
              className={classes.shipping_provider_input}
              value={deliveryValues.shippingCourier}
              onChange={(e) => setDeliveryValues((prev) => ({
                ...prev,
                shippingCourier: e.target.value,
              }))}
            />
          </div>
          <div className={classes.tracking}>
            <p className={classes.tracking_label}>
              {isMobile
                ? translations.orders.process_modal.driver_phone_mobile_text
                : translations.orders.process_modal.driver_phone}
            </p>
            <input
              className={classes.tracking_input}
              value={deliveryValues.shippingPhone}
              onChange={(e) => setDeliveryValues((prev) => ({
                ...prev,
                shippingPhone: e.target.value,
              }))}
            />
          </div>
          <div className={classes.shipped_btn_wrapper}>
            <button
              type="button"
              className={classes.shipped_btn}
              onClick={outOfDelivery}
              disabled={!deliveryValues.shippingCourier || !deliveryValues.shippingPhone}
            >
              {translations.orders.process_modal.delivery_btn}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProcessModal;

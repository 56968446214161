import { useTranslations } from 'hooks/useTranslations';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { ReactSelectTime } from 'types/globalTypes';
import { customStyles } from './styles';

interface Props {
  setTime: React.Dispatch<React.SetStateAction<ReactSelectTime>>;
  time: string
}

const SelectorCard: React.FC<Props> = ({ setTime, time }) => {
  const translation = useTranslations();
  const ts = translation.orders.overview.select_option_label;
  const handleChange = (e: {
    value: ReactSelectTime;
    label: string;
} | null) => {
    if (e) {
      setTime(e.value);
    }
  };
  const options = useMemo(
    () => [
      { value: ReactSelectTime.yesterday, label: ts.yesterday },
      { value: ReactSelectTime.lastWeek, label: ts.last_week },
      { value: ReactSelectTime.lastMonth, label: ts.last_month },
      { value: ReactSelectTime.lastYear, label: ts.last_year },
    ],
    [ts],
  );

  return (
    <Select
      name="date"
      options={options}
      menuPortalTarget={document.body}
      value={options.find((obj) => obj.value === time)}
      defaultValue={{
        value: ReactSelectTime.yesterday,
        label: ts.yesterday,
      }}
      menuPosition="fixed"
      isSearchable={false}
      // @ts-ignore
      styles={customStyles}
      onChange={handleChange}
    />
  );
};

export default SelectorCard;

import React, { memo } from 'react';
// Images
import { ReactComponent as CloseIcon } from 'assets/dashboard-icons/close-icon.svg';
// Styles
import classes from './DetailsPageHeader.module.scss';

interface Props {
  goBack: () => void;
  title: string;
}

const DetailsPageHeader: React.FC<Props> = ({
  goBack,
  title,
}) => (
  <div className={classes.header}>
    <h1 className={classes.header_title}>{title}</h1>
    <CloseIcon className={classes.close} onClick={goBack} />
  </div>
);

export default memo(DetailsPageHeader);

import { TransactionStatus } from 'types/globalTypes';
import {
  ESTIMATE_LOADING,
  ESTIMATE_GET_DATA,
  ESTIMATE_GET_STATUS,
  EstimateState,
  EstimateActions,
  ESTIMATE_NOTIFICATION,
  ESTIMATE_GET_ACCEPT,
} from './estimateTypes';

const initialState: EstimateState = {
  loading: false,
  data: null,
  notification: false,
  paymentSchedule: false,
  status: TransactionStatus.PENDING,
  loadingAccept: false,
};

export const estimateReducer = (
  state: EstimateState = initialState,
  action: EstimateActions,
): EstimateState => {
  switch (action.type) {
    case ESTIMATE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ESTIMATE_GET_DATA:
      return {
        ...state,
        data: action.payload,
        status: action.payload.details.status,
      };
    case ESTIMATE_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload,
      };
    }
    case ESTIMATE_GET_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }
    case ESTIMATE_GET_ACCEPT: {
      return {
        ...state,
        loadingAccept: action.payload,
      };
    }
    default:
      return state;
  }
};

import { nanoid } from 'nanoid';
import { OrderType } from 'store/dashboard/orders/orderTypes';
import {
  TransactionTypes,
} from 'store/dashboard/transaction/transactionTypes';
import { Documents, TransactionType } from 'types/globalTypes';
import { checkStatusExpired } from 'utils/functions';

export interface Destructuring {
  uniqId: string;
  id: string;
  date?: string;
  type: string;
  amount: number;
  status: string;
  name: string;
}

export const destructuringTransaction = (
  data: Documents[],
): Destructuring[] => data?.map((item) => ({
  uniqId: nanoid(),
  id: String(item.id),
  date: item.date,
  type: TransactionTypes[item.type as keyof typeof TransactionTypes],
  amount: item.total,
  status: checkStatusExpired(item),
  name: `${item?.contact?.firstName} ${item?.contact?.lastName}`,
}));

export const destructuringOrders = (data: Documents[]): Destructuring[] => data?.map((item) => ({
  id: String(item.id),
  uniqId: nanoid(),
  date: item.createdAt,
  type: 'Order',
  amount: item.total,
  currencyISO: item.currencyISO,
  balance: item.balance,
  name: `${item.contact.firstName} ${item.contact.lastName}`,
  salesChannel:
    item.salesChannel === 'whatsapp'
      ? item.salesChannel.replace('whatsapp', 'WhatsApp')
      : item.salesChannel,
  client: `${item.contact.firstName} ${item.contact.lastName}`,
  status: checkStatusExpired(item),
}));

export const convertTransactionItemToOrderItem = (data: TransactionType): OrderType => ({
  id: data.id,
  uniqId: nanoid(),
  date: data.date,
  amount: data.amount,
  currencyISO: data.currencyISO || 'EUR',
  balance: data.balance ? +data.balance : 0,
  // eslint-disable-next-line no-nested-ternary
  salesChannel: data.salesChannel
    ? data.salesChannel === 'whatsapp'
      ? data.salesChannel.replace('whatsapp', 'WhatsApp')
      : data.salesChannel
    : '',
  client: `${data?.contact?.firstName} ${data?.contact?.lastName}`,
  status: data?.status,
  items: data.items,
});

import { TranslationDashboardModel, TranslationsType } from 'types/TranslationDashboardModel';
import { TransactionTypes } from '../transaction/transactionTypes';

export const APP_SET_MENULINKS = 'APP_SET_MENULINKS';
export const APP_SET_MOBILE_MENU_OPEN = 'APP_SET_MOBILE_MENU_OPEN';
export const APP_SET_NOTIFICATION = 'APP_SET_NOTIFICATION';
export const APP_SET_TRANSLATIONS_FETCHED = 'APP_SET_TRANSLATIONS_FETCHED';
export const APP_SET_TRANSLATIONS = 'APP_SET_TRANSLATIONS';
export const APP_SET_IS_MOB_REQUIRED = 'APP_SET_IS_MOB_REQUIRED';
export const APP_SET_CURRENT_LANGUAGE = 'APP_SET_CURRENT_LANGUAGE';
export const APP_SET_ACTIVE_TRANSLATION = 'APP_SET_ACTIVE_TRANSLATION';
export const APP_SET_PREV_NAV_LINK = 'APP_SET_PREV_NAV_LINK';
export const APP_SET_PREV_ORDER = 'APP_SET_PREV_ORDER';
export const APP_SET_PREV_TRANSACTION = 'APP_SET_PREV_TRANSACTION';
export const APP_SET_PREV_DIRECTORY_TAB = 'APP_SET_PREV_DIRECTORY_TAB';
export const APP_SET_SEARCH_RESULT = 'APP_SET_SEARCH_RESULT';
export const APP_SET_SEARCH_LOADING = 'APP_SET_SEARCH_LOADING';
export const APP_SET_ADD_SEARCH_RESULT = 'APP_SET_ADD_SEARCH_RESULT';
export const APP_SET_HAS_NEXT_PAGE = 'APP_SET_HAS_NEXT_PAGE';

export interface SubLinks {
  title: string;
  route: string;
}

export interface MenuLinks {
  title: string,
  route: string,
  icon: string,
  sublinks?: SubLinks[];
}

export interface AppState {
  menuLinks: MenuLinks[];
  search: {
    loading: string;
    data: SearchResult[] | null;
    hasNextPage: boolean;
  }
  analytics?: {
    prevLink?: string;
    prevOrder?: string;
    prevTransaction?: string;
    prevDirectoryTab?: string;
  }
  profileLink: MenuLinks;
  isMobileMenuOpen: boolean;
  notification: string | null;
  isMobAppRequired: boolean;
  translations: TranslationsType | null;
  isTranslationsFetched: boolean;
  currentLanguage: string;
  activeTranslation: TranslationDashboardModel;

}

export interface SearchResult {
  id: string;
  firstName: string;
  lastName: string;
  type: keyof typeof TransactionTypes;
}

export enum SearchTypes {
  documents = 'documents',
  products = 'products',
  'sales-orders' = 'sales-orders',
  contacts = 'contacts'
}

export interface SearchResultDocuments {
  contactid: number,
  firstname: string,
  lastname: string,
  entryid: number,
  entrytype: keyof typeof TransactionTypes,
  entrytotal: number,
  date: string,
  createdat: string,
  updatedat: string,
  brand: string,
  name: string,
  id: string,
}

interface AppSetMenuLinksAction {
  type: typeof APP_SET_MENULINKS;
  payload: MenuLinks[];
}

interface AppMobileMenuOpenAction {
  type: typeof APP_SET_MOBILE_MENU_OPEN;
  payload: boolean;
}

interface AppSetNotificationAction {
  type: typeof APP_SET_NOTIFICATION;
  payload: string | null;
}

interface AppSetTranslationsFetchedAction {
  type: typeof APP_SET_TRANSLATIONS_FETCHED;
  payload: boolean;
}

interface AppSetTranslationsAction {
  type: typeof APP_SET_TRANSLATIONS;
  payload: TranslationsType;
}

interface AppSetMobileAppRequiredAction {
  type: typeof APP_SET_IS_MOB_REQUIRED;
  payload: boolean;
}

interface AppSetCurrentLanguage {
  type: typeof APP_SET_CURRENT_LANGUAGE;
  payload: string;
}

interface AppSetActiveTranslation {
  type: typeof APP_SET_ACTIVE_TRANSLATION;
  payload: TranslationDashboardModel;
}

interface AppSetPrevNavLinkAction {
  type: typeof APP_SET_PREV_NAV_LINK;
  payload: string;
}

interface AppSetPrevOrderAction {
  type: typeof APP_SET_PREV_ORDER;
  payload: string;
}

interface AppSetPrevTransactionAction {
  type: typeof APP_SET_PREV_TRANSACTION;
  payload: string;
}

interface AppSetPrevDirectoryTabAction {
  type: typeof APP_SET_PREV_DIRECTORY_TAB;
  payload: string;
}

interface AppSetSearchResultAction {
  type: typeof APP_SET_SEARCH_RESULT;
  payload: SearchResult[];
}

interface AppSetSearchLoadingAction {
  type: typeof APP_SET_SEARCH_LOADING;
  payload: string;
}

interface AppSetAddSearchResultAction {
  type: typeof APP_SET_ADD_SEARCH_RESULT;
  payload: SearchResult[];
}

interface AppSetHasNextPageAction {
  type: typeof APP_SET_HAS_NEXT_PAGE;
  payload: boolean;
}

export type AppActions =
  AppSetMenuLinksAction |
  AppSetNotificationAction |
  AppMobileMenuOpenAction |
  AppSetTranslationsAction |
  AppSetTranslationsFetchedAction |
  AppSetMobileAppRequiredAction |
  AppSetCurrentLanguage |
  AppSetActiveTranslation |
  AppSetPrevNavLinkAction |
  AppSetPrevOrderAction |
  AppSetPrevDirectoryTabAction |
  AppSetPrevTransactionAction |
  AppSetSearchLoadingAction |
  AppSetAddSearchResultAction |
  AppSetHasNextPageAction |
  AppSetSearchResultAction;

import mixpanel, { Dict, Query } from 'mixpanel-browser';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN!, { debug: true });

const actions = {
  identify: (id: string): void => {
    mixpanel.identify(id);
  },

  alias: (id: string, existingId?: string): void => {
    mixpanel.alias(id, existingId);
  },

  track: (name: string, props?: Dict): void => {
    mixpanel.track(name, props);
  },

  reset: (): void => {
    mixpanel.reset();
  },

  people: {
    set: (props: Dict): void => {
      mixpanel.people.set(props);
    },
  },

  time_event: (event_name: string): void => {
    mixpanel.time_event(event_name);
  },

  get_distinct_id: (): unknown => mixpanel.get_distinct_id(),

  track_links: (query: Query,
    event_name: string,
    properties?: Dict | (() => void) | undefined): void => {
    mixpanel.track_links(query, event_name, properties);
  },
};

export const mixpanelActions = actions;

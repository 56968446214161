import React, { useMemo } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { ChartsData, DirectoryType } from 'store/dashboard/directory/directoryTypes';
// Utils
import { getYear } from 'utils/dates';
// Components
import Graph from 'components/Graph';
// Classes
import classes from './Chart.module.scss';

interface Props {
  type: DirectoryType
}

const Chart: React.FC<Props> = ({ type }) => {
  const translations = useTranslations();
  const ts = translations.home.report;
  const { details } = useTypeSelector(({ directory }) => directory);
  const summary = details.summary[type as keyof ChartsData];
  const getTotal = useMemo(
    () => summary?.chartData?.map((item) => item.value).reduce((a, b) => a + b, 0),
    [summary?.chartData],
  );

  return (
    <div className={classes.chart}>
      <div className={classes.chart_title}>
        {getTotal && (
          <div className={classes.chart_years}>
            {`${getYear()} ${ts.ytd}`}
          </div>
        )}
        <div className={getTotal ? classes.chart_text : classes.chart_text_nodata}>
          {type === DirectoryType.supplier ? ts.tabs.re_expenses : ts.tabs.re_sales}
        </div>
      </div>
      {!summary ? (
        <div className={classes.nodata}>
          <div className={classes.lines}>
            <div className={classes.line} />
            <div className={classes.line} />
            <div className={classes.line} />
          </div>
          <p>{ts.no_data_available_yet}</p>
        </div>
      ) : (
        <Graph
          datasets={summary.chartData}
          color={
            type === DirectoryType.supplier
              ? 'rgba(132, 146, 162, 0.8)'
              : 'rgba(0, 211, 200, 0.8)'
          }
          name={type === DirectoryType.supplier ? ts.tabs.re_expenses : ts.tabs.re_sales}
          tooltipClassName={classes.tooltip}
          isIso
          type={type}
          heightChart={160}
        />
      )}
    </div>
  );
};

export default Chart;

import React from 'react';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Images
import { ReactComponent as InstagramIcon } from 'assets/storefront-icons/socials/instagram.svg';
import { ReactComponent as TwitterIcon } from 'assets/storefront-icons/socials/twitter.svg';
import { ReactComponent as FacebookIcon } from 'assets/storefront-icons/socials/facebook.svg';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// classes
import classes from './AboutUs.module.scss';

const AboutUs: React.FC = () => {
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const { footer } = useTranslationsStorefront();

  return (
    <div className={classes.about__us_wrapper}>
      <h3>{footer.follow_us.title}</h3>
      <ol className={classes.about__us}>
        {currentSalesChannel?.customerService?.facebook && (
          <a
            href={`https://facebook.com/${currentSalesChannel?.customerService?.facebook}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.contact}
          >
            <FacebookIcon />
            {footer.follow_us.facebook}
          </a>
        )}
        {currentSalesChannel?.customerService?.twitter && (
          <a
            href={`https://twitter.com/${currentSalesChannel?.customerService?.twitter}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.contact}
          >
            <TwitterIcon />
            {footer.follow_us.twitter}
          </a>
        )}
        {currentSalesChannel?.customerService?.instagram && (
          <a
            href={`https://instagram.com/${currentSalesChannel?.customerService?.instagram}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.contact}
          >
            <InstagramIcon />
            {footer.follow_us.instagram}
          </a>
        )}
      </ol>
    </div>
  );
};

export default AboutUs;

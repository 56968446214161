import React, { useMemo, useRef } from 'react';
import Slider from 'react-slick';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useUser } from 'hooks/useUser';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
// Types
import { OrdersSummary } from 'store/dashboard/orders/orderTypes';
// Components
import SummaryBarChart from 'pages/Dashboard/Orders/SummaryBarChart';
import DonutChartCard from 'components/DashboardComponents/DonutChartCard';
// Styles
import classes from './OrderSummary.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Props {
  summary: OrdersSummary;
}

const OrderSummary: React.FC<Props> = ({ summary }) => {
  const sliderRef = useRef<Slider>(null);
  const { isDesktop, windowWidth } = useMobileScreen();
  const user = useUser();
  const translations = useTranslations();
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.country?.currencyISO || 'USD');
  const translationsSummary = translations.orders.summary;
  const total = (summary.orders.processedCount || 0)
    + (summary?.orders.unprocessedCount || 0)
    + (summary?.canceled.instoreCount || 0)
    + (summary?.canceled.onlineCount || 0);
  const value = (summary.orders.processedAmount || 0)
    + (summary?.orders.unprocessedAmount || 0)
    + (summary?.canceled.instoreAmount || 0)
    + (summary?.canceled.onlineAmount || 0);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: (windowWidth && windowWidth > 768 && windowWidth <= 886)
      || (windowWidth && windowWidth < 620) ? 2.5 : 3.1,
    slidesToScroll: 1,
  };

  const orderSummary = useMemo(() => [{
    name: translationsSummary.total.title,
    percent: 100,
    total,
    data: {
      subtitle: translationsSummary.total.subtitle,
      value: formatCurrencyByISO(value),
      format: ' ',
    },
  },
  {
    name: translationsSummary.processed.title,
    percent: ((summary?.orders.processedCount || 0) * 100) / total,
    total: (summary?.orders.processedCount || 0) / total,
    data: {
      subtitle: translationsSummary.processed.subtitle,
      value: (summary.orders.unprocessedCount || 0),
      format: '.1%',
    },
  },
  {
    name: translationsSummary.canceled.title,
    percent: (((summary?.canceled.instoreCount || 0)
      + (summary?.canceled.onlineCount || 1)) * 100) / total,
    total: ((summary?.canceled.instoreCount || 0) + (summary?.canceled.onlineCount || 0)) / total,
    data: {
      subtitle: translationsSummary.canceled.subtitle,
      value: formatCurrencyByISO((summary?.canceled.instoreAmount || 0)
        + (summary?.canceled.onlineAmount || 0)),
      format: '.1%',
    },
  }], [formatCurrencyByISO, summary, total, value, translationsSummary]);

  return (isDesktop ? (
    <div className={classes.slider}>
      <Slider {...settings} ref={sliderRef}>
        {orderSummary.map((item) => (
          <DonutChartCard
            key={item.name}
            title={item.name}
            percentage={item.percent}
            value={item.total}
            type="two"
            data={item.data}
          />
        ))}
        <SummaryBarChart />
      </Slider>
    </div>
  ) : (
    <div className={classes.table_summary}>
      {orderSummary?.map((item) => (
        <DonutChartCard
          key={item.name}
          title={item.name}
          percentage={item.percent}
          value={item.total}
          type="two"
          data={item.data}
        />
      ))}
      <SummaryBarChart />
    </div>
  )
  );
};

export default OrderSummary;

import { Documents } from 'types/globalTypes';

// Action Types
export const PAYMENT_LINK_LOADING = 'PAYMENT_LINK_LOADING';
export const PAYMENT_LINK_GET_DATA = 'PAYMENT_LINK_GET_DATA';
export const PAYMENT_LINK_SET_PAYMENT = 'PAYMENT_LINK_SET_PAYMENT';
// Reducer type
export interface PaymentLinkState {
  loading: boolean
  data?: Documents
  payment: {
    loading: boolean;
    error?: string;
    success: boolean;
    cancel: {
      loading: boolean;
      canceled: boolean;
    };
  }
}

interface PaymentLinkLoadingAction {
  type: typeof PAYMENT_LINK_LOADING
  payload: boolean
}

interface PaymentLinkGetDataAction {
  type: typeof PAYMENT_LINK_GET_DATA
  payload: PaymentLinkState['data']
}

interface PaymentLinkSetPaymentAction {
  type: typeof PAYMENT_LINK_SET_PAYMENT;
  payload: PaymentLinkState['payment'];
}

export type PaymentLinkActions =
  PaymentLinkLoadingAction
  | PaymentLinkGetDataAction
  | PaymentLinkSetPaymentAction;

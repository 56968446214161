import { combineReducers } from '@reduxjs/toolkit';
import catalogReducers, { CatalogState } from './catalog';
import { appReducer } from './app/appReducer';
import { AppState } from './app/appTypes';
import { ReportState } from './report/reportTypes';
import { reportReducer } from './report/reportReducer';

export interface DashboardState {
  app: AppState;
  catalog: CatalogState;
  report: ReportState;
}

const dashboardReducers = combineReducers<DashboardState>({
  app: appReducer,
  catalog: catalogReducers,
  report: reportReducer,
});

export default dashboardReducers;

/* eslint-disable indent */
import { useHistory } from 'react-router-dom';
import { DocumentRoutePath } from 'router/Routes/Documents';

interface HookReturnType {
  goToInvoice: (invoiceId: string) => void;
  goToBill: (billId: string) => void;
  goToEstimate: (estimateId: string) => void;
  goToQuote: (quoteId: string) => void;
  getEstimatePath: (invoiceId: string) => string;
  getInvoicePath: (invoiceId: string) => string;
}

export const useDocumentRoutes = (): HookReturnType => {
  const history = useHistory();

  const getEstimatePath = (estimateId: string) => DocumentRoutePath.ESTIMATE.replace(':id', estimateId);

  const getInvoicePath = (invoiceId: string) => DocumentRoutePath.INVOICE.replace(':id', invoiceId);

  const goToInvoice = (invoiceId: string) => history.push({
    pathname: DocumentRoutePath.INVOICE.replace(':id', invoiceId),
  });

  const goToBill = (billId: string) => history.push({
    pathname: DocumentRoutePath.BILL.replace(':id', billId),
  });

  const goToEstimate = (estimateId: string) => history.push({
    pathname: DocumentRoutePath.ESTIMATE.replace(':id', estimateId),
  });

  const goToQuote = (quoteId: string) => history.push({
    pathname: DocumentRoutePath.QUOTE.replace(':id', quoteId),
  });

  return {
    getInvoicePath,
    goToInvoice,
    getEstimatePath,
    goToBill,
    goToEstimate,
    goToQuote,
  };
};

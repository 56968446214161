import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import React, { useMemo } from 'react';
import { IOrderData } from 'store/storefront/order/orderTypes';
// Utils
import { dateToFormat } from 'utils/dates';
// Styles
import classes from './Summary.module.scss';

interface Props {
  date: Date;
  paymentDue?: string | Date;
  fulfillmentType: DeliveryMethodsEnum;
  status: IOrderData['status']['name'];
}

const Summary: React.FC<Props> = ({
  date, status, fulfillmentType, paymentDue,
}) => {
  const { order: { order_details: translations } } = useTranslationsStorefront();
  const fulfillmentTypeTranslations = useMemo(() => {
    if (fulfillmentType === DeliveryMethodsEnum.SHIPPING) {
      return translations.shipped;
    }

    if (fulfillmentType === DeliveryMethodsEnum.DELIVERY) {
      return translations.out_for_delivery;
    }

    return translations.ready_for_pickup;
  }, [fulfillmentType,
    translations.out_for_delivery,
    translations.ready_for_pickup,
    translations.shipped]);

  if (status === 'CANCELLED') {
    return (
      <div className={classes.summary}>
        <div className={classes.summary_status}>
          {translations.payment_canceled}
        </div>
        <span className={classes.summary_date}>
          {dateToFormat(date, ' DD MMM YYYY HH:mm A')}
        </span>
      </div>
    );
  }

  if (status === 'UNPAID') {
    return (
      <div className={classes.summary}>
        <div className={classes.summary_status}>
          {translations.unpaid_payment}
        </div>
        <div className={classes.right}>
          <p className={classes.summary_date}>
            {dateToFormat(date, ' DD MMM YYYY HH:mm A')}
          </p>
          <div className={classes.summary_payment_due}>
            <span>
              {translations.payment_due}
            </span>
            {' '}
            <span>
              {dateToFormat(paymentDue, 'MMMM DD, YYYY')}
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (status === 'ACCEPTED') {
    return (
      <div className={classes.summary}>
        <div className={classes.summary_status_completed}>
          {translations.payment_completed}
        </div>
        <span className={classes.summary_date}>
          {dateToFormat(date, ' DD MMM YYYY HH:mm A')}
        </span>
      </div>
    );
  }

  if (status === 'UNPROCESSED') {
    return (

      <div className={classes.summary}>
        <div className={classes.summary_status}>
          {translations.pending_payment}
        </div>
        <span className={classes.summary_date}>
          {dateToFormat(date, ' DD MMM YYYY HH:mm A')}
        </span>
      </div>
    );
  }

  return (
    <div className={classes.summary}>
      <div className={classes.summary_status_active}>
        {fulfillmentTypeTranslations}
      </div>
      <span className={classes.summary_date}>
        {dateToFormat(date, ' DD MMM YYYY HH:mm A')}
      </span>
    </div>
  );
};

export default Summary;

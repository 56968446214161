import { useTranslations } from 'hooks/useTranslations';
import React from 'react';
import Card from 'components/Card';
// Styles
import { useTypeSelector } from 'hooks/useTypeSelector';
import CardSelect from 'components/CardSelect/CardSelect';
import { ReactSelectTime } from 'types/globalTypes';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import { getYear } from 'utils/dates';
import Graph from 'components/Graph';
import classes from './MobileSalesTaxesTab.module.scss';

interface Props {
  total: number;
  time: ReactSelectTime;
  setTime: React.Dispatch<React.SetStateAction<ReactSelectTime>>
}

const MobileSalesTaxesTab: React.FC<Props> = ({ total, time, setTime }) => {
  const translations = useTranslations();
  const ts = translations.home.report;
  const {
    reportSelectCards,
    salesTaxesChart,
    monthlyAverage,
  } = useTypeSelector(({ dashboard }) => dashboard.report);
  const otherTax = reportSelectCards ? Object.keys(reportSelectCards)[2] : undefined;

  return (
    <>
      <div className={classes.content_container}>
        <div className={classes.chart_container}>
          <div className={classes.chart}>
            <div className={classes.chart_title}>
              {!total ? (
                <div className={classes.chart_years}>
                  {`${getYear()} ${ts.year_to_date}`}
                </div>
              ) : (
                <div className={classes.chart_years}>
                  {`${getYear()} ${ts.ytd}`}
                </div>
              )}
              <div className={classes.chart_text}>
                {ts.tabs.re_sales_taxes}
              </div>
            </div>
            {!total ? (
              <div className={classes.nodata}>
                <div className={classes.lines}>
                  <div className={classes.line} />
                  <div className={classes.line} />
                  <div className={classes.line} />
                </div>
                <p>{ts.no_data_available_yet}</p>
              </div>
            ) : (
              <Graph
                datasets={salesTaxesChart.data}
                name={ts.tabs.re_sales_taxes}
                color="rgba(107, 89, 237, 1)"
                isIso
              />
            )}
          </div>
        </div>
        <div className={classes.monthly_average}>
          <CardMonthlyAverage
            title={ts.sales_taxes.month}
            value={monthlyAverage?.value}
            change={monthlyAverage?.percentage}
          />
        </div>
        <div className={classes.high}>
          <Card
            data={{
              title: ts.highest_month,
              label: salesTaxesChart.highestMonth?.month,
              value: salesTaxesChart.highestMonth?.value,
              second: 'high',
              change: salesTaxesChart.highestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.low}>
          <Card
            data={{
              title: ts.lowest_month,
              label: salesTaxesChart.lowestMonth?.month,
              value: salesTaxesChart.lowestMonth?.value,
              second: 'low',
              change: salesTaxesChart.lowestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.total}>
          <CardSelect
            data={{
              title: ts.tabs.re_sales_taxes,
              label: ts.all.toUpperCase(),
              value: reportSelectCards?.all?.entrytotal,
              change: reportSelectCards?.all?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        <div className={classes.paid}>
          <CardSelect
            data={{
              title: ts.sales_taxes.vat,
              label: ts.all.toUpperCase(),
              value: reportSelectCards?.VAT?.entrytotal,
              change: reportSelectCards?.VAT?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        {otherTax
          && (
            <div className={classes.unpaid}>
              <CardSelect
                data={{
                  title: reportSelectCards ? `${reportSelectCards[otherTax]}` : '',
                  label: ts.all.toUpperCase(),
                  value: reportSelectCards?.[otherTax]?.entrytotal,
                  change: reportSelectCards?.[otherTax]?.change || 0,
                }}
                time={time}
                setTime={setTime}
              />
            </div>
          )}
      </div>
    </>
  );
};

export default MobileSalesTaxesTab;

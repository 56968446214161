import React, {
  Dispatch, SetStateAction, useCallback, useMemo, useState,
} from 'react';
import cn from 'classnames';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Data
import phoneCodes from 'data/phone-codes.json';
// Services
import { defaultCountry, getDefaultCode } from 'utils/functions';
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import PhoneInput from 'components/StorefrontComponents/PhoneInput';
import { registrationUser } from 'store/storefront/checkout/checkoutActions';
// Components
import CountrySelect from 'components/StorefrontComponents/CountrySelect';
import { isValidPhoneNumber } from 'utils/phoneNumber';
import Address from 'pages/Storefront/Checkout/CustomerInfoModal/Address';
import { auth } from 'services/firebase';
import GooglePlacesInput from 'components/Inputs/GooglePlacesInput';
import InputEmail from './InputEmail';
import InputPassword from './InputPassword';
import InputCheckPassword from './InputCheckPassword';
// Styles
import classes from './SignUp.module.scss';

interface Props {
  setActive: Dispatch<SetStateAction<boolean>>;
  handleSignIn: () => void
}

const SignUp: React.FC<Props> = ({ setActive, handleSignIn }) => {
  const { id } = useParams<{ id: string, orderId: string }>();
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const { fulfillment } = useTypeSelector(({ storefront }) => storefront.checkout);
  const [addManually, setAddManually] = useState<boolean>();
  const { geoInfo } = useTypeSelector(({ storefront }) => storefront.app);
  const [address, setAddress] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const defaultCountryBrowser = useMemo(
    () => defaultCountry(fulfillment.country.iso || geoInfo?.countryCode),
    [fulfillment.country.iso, geoInfo?.countryCode],
  );

  const handleCloseModalAddress = useCallback(() => {
    setAddManually(false);
  }, []);

  const defaultCode = useMemo(
    () => getDefaultCode(phoneCodes, defaultCountryBrowser?.value),
    [defaultCountryBrowser?.value],
  );

  const {
    register, handleSubmit, control, watch, setValue, getValues,
    formState: { errors, isValid },
  } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({
      code: defaultCode,
      country: defaultCountryBrowser,
    }), [defaultCode, defaultCountryBrowser]),
  });

  const watchCountry = watch('country');
  const watchPhoneNumber = watch('phoneNumber');
  const watchDialCode = watch('code');
  const watchPassword = watch('password');

  const handleContinue = useCallback(
    () => {
      handleCloseModalAddress();
      setAddress(true);
      setValue('address', `${getValues().line1} ${getValues().postCode} ${getValues().city}`);
    },
    [getValues, handleCloseModalAddress, setValue],
  );

  const { checkout } = useTranslationsStorefront();
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    if (!addManually) {
      setActive(false);
      return;
    }

    setAddManually(false);
  }, [addManually, setActive]);

  const isValidNumber = useMemo(() => {
    if (!watchPhoneNumber) {
      return true;
    }

    if (watchDialCode && watchPhoneNumber) {
      return isValidPhoneNumber(`${watchDialCode}${watchPhoneNumber}`);
    }

    return false;
  }, [watchDialCode, watchPhoneNumber]);

  const onSubmit = useCallback(async (data) => {
    const emailInFirebase = await auth.fetchSignInMethodsForEmail(data.email);
    if (emailInFirebase.length) {
      setIsEmail(!!emailInFirebase.length);
      return;
    }

    setActive(false);
    dispatch(registrationUser(data, id));
  }, [dispatch, id, setActive]);

  const onChangeLine2 = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('line2', e.target.value);
  }, [setValue]);

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <HeaderModal onClick={handleClose} className={classes.header} />
        <p className={classes.title}>{checkout.customer_info_modal.title_information}</p>
        <div className={cn(classes.form, {
          [classes.form_hide]: addManually,
        })}
        >
          <div className={classes.content}>
            <p className={classes.subtitle}>{checkout.customer_info_modal.sign_up.subtitle}</p>
            <div className={classes.inputs}>
              <CountrySelect
                control={control}
                defaultCountry={defaultCountryBrowser}
                setValue={setValue}
                addManually={addManually}
              />
              <div className={classes.fullName}>
                <input
                  {...register('firstName', {
                    required: true,
                  })}
                  placeholder={checkout.card_form.first_name}
                  className={classes.input}
                />
                <input
                  {...register('lastName', {
                    required: true,
                  })}
                  placeholder={checkout.card_form.last_name}
                  className={classes.input}
                />
              </div>
              <div className={classes.email_and_phone}>
                <PhoneInput
                  countryIso={watchCountry?.value}
                  control={control}
                  translations={checkout.phone_input}
                  placeholder={checkout.phone_input.placeholder_phone_number}
                  isValidPhoneNumber={isValidNumber}
                />
                <InputEmail
                  register={register}
                  isError={isEmail}
                />
              </div>
              <div className={classes.email_and_phone}>
                <GooglePlacesInput
                  setAddManually={setAddManually}
                  setAddress={setAddress}
                  setValue={setValue}
                  watch={watch}
                  control={control}
                  address={address}
                />
                {address && (
                  <input
                    type="text"
                    id="line2"
                    className={`${classes.input} ${classes.address}`}
                    placeholder={checkout.address_input.address2_placeholder}
                    autoComplete="address-line2"
                    {...register('line2', {
                      required: false,
                    })}
                    onChange={onChangeLine2}
                  />
                )}
              </div>
              <div className={classes.email_and_phone}>
                <InputPassword
                  register={register}
                  error={errors?.password?.message}
                  disabled={!watchPassword}
                />
                <InputCheckPassword
                  register={register}
                  error={errors?.repassword?.type}
                  watch={watch}
                />
              </div>
            </div>
            <div className={classes.sign_in}>
              <div className={classes.sign_in_title}>
                {checkout.customer_info_modal.sign_in.title.replace('{StoreName}', currentSalesChannel?.name || '')}
              </div>
              <div className={classes.sign_in_subtitle}>
                {checkout.customer_info_modal.sign_up.subtitle_one_text}
                {' '}
                <button type="button" onClick={handleSignIn}>
                  {checkout.customer_info_modal.sign_in_btn}
                </button>
                {' '}
                {checkout.customer_info_modal.sign_in.subtitle_two_text}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!addManually && (
        <div className={classes.footer}>
          <button type="button" onClick={handleSignIn}>
            {checkout.customer_info_modal.sign_in_btn}
          </button>
          <button type="submit" disabled={!isValid || !isValidNumber || !address}>
            {checkout.customer_info_modal.sign_up.sign_up_btn_submit}
          </button>
        </div>
      )}
      <Address
        register={register}
        getValues={getValues}
        addManually={!!addManually}
        handleContinue={handleContinue}
        control={control}
        defaultCountryBrowser={defaultCountryBrowser}
        setValue={setValue}
        errors={errors}
      />
    </form>
  );
};

export default SignUp;

import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Hooks
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { checkoutRemoveItemAction, checkoutUpdateItemAction } from 'store/storefront/checkout/checkoutActions';
// Types
import { CartItemType } from 'store/storefront/shop/shopTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
// Components
import ProductAvatar from 'components/StorefrontComponents/ProductAvatar';
import QuantityButtons from 'components/StorefrontComponents/QuantityButtons';
import Remove from 'components/StorefrontComponents/Remove';

// Styles
import classes from './Item.module.scss';

interface Props {
  item: CartItemType;
  translation: TranslationStorefrontModel['cart'];
  currencyISO: string;
}

const Item: React.FC<Props> = ({ item, translation, currencyISO }) => {
  const { id } = useParams<{ id: string }>();
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const location = useLocation();
  const { goToShop } = useStorefrontRoutes();

  const { items } = useTypeSelector(({ storefront }) => storefront.checkout);
  const [quantity, setQuantity] = useState(item.quantity);
  const dispatch = useDispatch();
  const formatCurrencyByISO = CurrencyFormatByISO(currencyISO);

  const itemVariationStock = useMemo(() => {
    const itemInventory = item.variations[0].inventories.find(
      (inventory) => inventory.locationId === currentSalesChannel?.id,
    );

    return itemInventory?.quantity;
  }, [currentSalesChannel?.id, item.variations]);

  const deleteBagItemHandler = () => {
    if (items.length === 1) {
      goToShop(id, location.search);
      localStorage.removeItem(`leja-${id}-checkout`);
    }
    dispatch(checkoutRemoveItemAction({
      url: id,
      item,
    }));
  };

  useEffect(() => {
    const updatedItem = {
      ...item,
      quantity,
    };

    dispatch(checkoutUpdateItemAction({
      url: id,
      item: updatedItem,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  return (
    <div className={classes.product}>
      <div className={classes.product_top}>
        <div className={itemVariationStock
          ? classes.product_description
          : classes.product_description_sold_out}
        >
          {!!itemVariationStock
            && itemVariationStock < 6
            && (
              <div className={classes.product_description_count}>
                {itemVariationStock
                  ? `${itemVariationStock} ${translation.warning.left}`
                  : `${translation.cart_item.quantity_sold_out}`}
              </div>
            )}
          {!itemVariationStock && (
            <div className={classes.product_description_count}>
              {translation.cart_item.quantity_sold_out}
            </div>
          )}
          <div className={classes.product_description_name}>
            {item.name}
          </div>
          <div className={classes.product_description_price}>
            <p>
              {formatCurrencyByISO(item.variations[0].price)}
            </p>
            <p>
              {item.variations[0].name}
            </p>
          </div>
        </div>
        <ProductAvatar name={item.name} images={item.images} />
      </div>
      {item.taxes && (
        <div className={itemVariationStock ? classes.taxes : classes.taxes_sold_out}>
          {item.taxes.map((tax) => (
            <React.Fragment key={tax.name}>
              <div className={classes.tax_name}>
                {`${tax.name} - ${tax.percentage}% ${tax.included
                  ? translation.cart_item.taxes_included
                  : translation.cart_item.taxes_excluded}`}
              </div>
              <div className={classes.tax_amount}>
                {formatCurrencyByISO(tax.amount)}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      <div className={itemVariationStock
        ? classes.product_bottom
        : classes.product_bottom_sold_out}
      >
        <div className={classes.quantity}>
          <QuantityButtons
            quantity={quantity}
            setQuantity={setQuantity}
            stock={itemVariationStock}
            disabled={itemVariationStock === 0}
          />
          <div className={classes.delete}>
            <Remove deleteItem={deleteBagItemHandler} item={item} />
          </div>
        </div>
        <div className={classes.item_price}>
          {formatCurrencyByISO(item.variations[0].price * item.quantity)}
        </div>
      </div>
    </div>
  );
};

export default Item;

import React, { useCallback, useState } from 'react';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Components
import Modal from 'components/Modal';
import LoadingPage from 'pages/LoadingPage';
import { LoadingEnum } from 'store/storefront/checkout/checkoutTypes';
import SignIn from './SignIn';
import SignUp from './SignUp';
// Styles
import classes from './InfoModal.module.scss';
import ResetPassword from './ResetPassword';

interface Modals {
  signIn: boolean;
  signUp: boolean;
  resetPassword: boolean;
}

const initialStateModals = {
  signIn: true,
  signUp: false,
  resetPassword: false,
};

interface Props {
  active: boolean;
  setActive: () => void;
}

const InfoModal: React.FC<Props> = ({ active, setActive }) => {
  const { order } = useTypeSelector(({ storefront }) => storefront.checkout);
  const [modals, setModals] = useState<Modals>(initialStateModals);
  const { checkout } = useTranslationsStorefront();

  const handleClickModals = useCallback((modal: keyof Modals) => () => {
    setModals({
      ...initialStateModals,
      signIn: false,
      [modal]: true,
    });
  }, []);

  return (
    <>
      <Modal active={active} setActive={setActive} className={classes.info_modal}>
        {modals.signIn && (
          <SignIn
            setActive={setActive}
            handleSignUp={handleClickModals('signUp')}
            handleResetPassword={handleClickModals('resetPassword')}
          />
        )}
        {modals.signUp && <SignUp setActive={setActive} handleSignIn={handleClickModals('signIn')} />}
        {modals.resetPassword
          && (
            <ResetPassword
              setActive={setActive}
              handleCancelResetPassword={handleClickModals('signIn')}
            />
          )}
      </Modal>
      {order.loading === LoadingEnum.SIGNIN && (
        <LoadingPage
          title={checkout.sign_in_loading}
          backgroundColor="rgba(32, 39, 43, 0.8)"
        />
      )}
      {order.loading === LoadingEnum.SIGNUP && (
        <LoadingPage
          title={checkout.sign_up_loading}
          backgroundColor="rgba(32, 39, 43, 0.8)"
        />
      )}
    </>
  );
};

export default InfoModal;

import React, { useMemo } from 'react';
// Hooks
import { useMobileScreen } from 'hooks/useMobileScreen';
// Types
import { OrderItem } from 'store/storefront/checkout/checkoutTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Utils
import { CurrencyFormatByISO, totalPriceItem } from 'utils/price';
import { twoLetterString } from 'utils/strings';
// Styles
import classes from './Item.module.scss';

interface Props {
  data: OrderItem,
  currencyIso: string,
  translation: TranslationStorefrontModel['cart']['cart_item']
}

const Item: React.FC<Props> = ({ data, currencyIso, translation }) => {
  const formatCurrencyByISO = useMemo(() => CurrencyFormatByISO(currencyIso), [currencyIso]);
  const { isMobile } = useMobileScreen();

  const totalItem = useMemo(() => {
    const taxExcluded = data?.taxes?.reduce((
      acc, item,
    ) => acc + (item.included ? 0 : item.amount), 0);

    return totalPriceItem(data.unitPrice, data.quantity, (taxExcluded || 0));
  }, [data.quantity, data?.taxes, data.unitPrice]);

  if (isMobile) {
    return (
      <div className={classes.item}>
        <div className={classes.item_description}>
          <div className={classes.item_quantity}>
            {data.quantity}
          </div>
          <div className={classes.item_avatar}>
            {data.image ? (
              <img src={data.image} alt={data.name} />
            ) : (
              <span>
                {twoLetterString(data.name)}
              </span>
            )}
          </div>
          <div className={classes.item_description_name_option}>
            <p>
              {data.name}
            </p>
            {data.options.map((option, index) => (
              <span
                key={option.variant}
              >
                {option.variant}{index === data.options.length - 1 ? '' : ', '}
              </span>
            ))}
          </div>
        </div>
        <div className={classes.item_mob_unit_price}>
          <p>
            {translation.unit_price}
          </p>
          <p>
            {formatCurrencyByISO(data.unitPrice)}
          </p>
        </div>
        <div className={classes.item_mob_taxes}>
          {data?.taxes?.map((tax) => (
            <div className={classes.item_price_taxes_tax}>
              <p>
                {tax.name}
              </p>
              <p>
                {formatCurrencyByISO(tax.amount)}
                ({tax.included ? translation.taxes_included : translation.taxes_excluded})
              </p>
            </div>
          ))}
        </div>
        <div className={classes.item_mob_total}>
          <p>
            {translation.total}
          </p>
          <p>
            {formatCurrencyByISO(totalItem)}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.item}>
      <div className={classes.item_description}>
        <div className={classes.item_avatar}>
          {data.image ? (
            <img src={data.image} alt={data.name} />
          ) : (
            <span>
              {twoLetterString(data.name)}
            </span>
          )}
        </div>
        <div className={classes.item_description_name_option}>
          <p>
            {data.name}
          </p>
          {data.options.map((option, index) => (
            <span
              key={option.variant}
            >
              {option.variant}{index === data.options.length - 1 ? '' : ', '}
            </span>
          ))}
        </div>
      </div>
      <div className={classes.item_quantity}>
        {data.quantity}
      </div>
      <div className={classes.item_price}>
        <div className={classes.item_price_subtotal}>
          {formatCurrencyByISO(data.unitPrice)}
        </div>
        <div className={classes.item_price_taxes}>
          {data?.taxes?.map((tax) => (
            <div className={classes.item_price_taxes_tax}>
              <p>
                {tax.name}
              </p>
              <p>
                {formatCurrencyByISO(tax.amount)}
                ({tax.included ? translation.taxes_included : translation.taxes_excluded})
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.item_total}>
        {formatCurrencyByISO(totalItem)}
      </div>
    </div>
  );
};

export default Item;

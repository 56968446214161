import React from 'react';
// Icon
import { ReactComponent as BackArrowIcon } from 'assets/icons/back-arrow.svg';
// Styles
import classes from './BackBtnWithArrow.module.scss';

interface Props {
  title: string;
  onClick?: (...args: unknown[]) => void;
}

const BackBtnWithArrow: React.FC<Props> = ({ title, onClick }) => (
  <button
    className={classes.root}
    type="button"
    onClick={onClick}
  >
    <BackArrowIcon className={classes.root_icon} />
    {title}
  </button>
);

export default BackBtnWithArrow;

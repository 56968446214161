import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
// Hooks
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useUser } from 'hooks/useUser';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslations } from 'hooks/useTranslations';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useQuery } from 'hooks/useQuery';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
// Actions
import {
  getTransactionDataDetail,
  setNotificationTransactionAction,
  TransactionRecordPaymentIsSuccessAction,
  TransactionReminderAction,
} from 'store/dashboard/transaction/transactionActions';
import { selectTransactionDetails } from 'store/dashboard/transaction/transactionSelectors';
// Types
import { ResPaymentStatus, TransactionStatus } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Assets
import { ReactComponent as CloseIcon } from 'assets/dashboard-icons/close-icon.svg';
// Components
import LoadingPage from 'pages/LoadingPage';
import ModalSuccess from 'components/Modal/ModalSuccess';
import Accordion from 'components/Accordion';
import SocialTransaction from './SocialTransaction';
import OverviewSection from './OverviewSection';
import SideBar from './SideBar';
import Contacts from './Contacts';
import PaymentScheduleModal from './PaymentScheduleModal';
import RecordPayment from './RecordPayment';
import SendReminderModal from './SendReminderModal';
// Styles
import classes from './TransactionDetails.module.scss';

const TransactionDetails: React.FC = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const params: { transactionId?: string } = useParams();
  const history = useHistory();
  const [modalPaymentScheduleActive, setModalPaymentScheduleActive] = useState(false);
  const [modalRecordPaymentActive, setModalRecordPaymentActive] = useState(false);
  const [modalSendReminderActive, setModalSendReminderActive] = useState(false);
  const [nextSchedulePayment, setNextSchedulePayment] = useState<number | undefined>();
  const query = useQuery();
  const type = query.get('type');
  const { isMobile } = useMobileScreen();
  const translations = useTranslations();
  const transactionData = useTypeSelector(selectTransactionDetails);
  const {
    recordPayment,
    reminder,
    notification,
    loading,
    acceptQuote,
  } = useTypeSelector(({ transaction }) => transaction);
  const transactionTs = translations.transactions;
  const formatCurrencyByISO = CurrencyFormatByISO(transactionData?.currencyISO);
  const isPaid = useCallback((
    status: ResPaymentStatus,
  ) => status.toLowerCase() === TransactionStatus.PAID, []);

  const totalAmountPaymentSchedule = useMemo(() => {
    if (transactionData?.terms) {
      return transactionData?.terms?.paymentSchedule?.reduce(
        (acc, curr) => (isPaid(curr.status) ? acc : acc + (curr.balance || curr.amount)), 0,
      );
    }
    return transactionData?.balance;
  }, [transactionData?.balance, transactionData?.terms, isPaid]);

  const titleLoading = useMemo(() => {
    if (recordPayment.loading) {
      return transactionTs.title_loading_record_payment;
    }

    if (reminder.loading) {
      if (transactionData?.status === TransactionStatus.PENDING) {
        return transactionTs.title_loading_reminder_expiration;
      }
      return transactionTs.title_loading_reminder;
    }

    return transactionTs.title_loading_accept;
  }, [recordPayment.loading, reminder.loading, transactionData?.status, transactionTs]);

  const handleOpenRecordPayment = useCallback(
    (amount?: number) => {
      setModalPaymentScheduleActive(false);
      setModalRecordPaymentActive(true);
      setNextSchedulePayment(amount);
    },
    [],
  );

  const handleSuccessRecordPayment = useCallback(() => {
    dispatch(TransactionRecordPaymentIsSuccessAction(null));
  }, [dispatch]);

  const handleSuccessReminder = useCallback(() => {
    dispatch(TransactionReminderAction(false));
  }, [dispatch]);

  const handlePaymentScheduleModal = useCallback(() => {
    setModalPaymentScheduleActive(true);
  }, []);

  const handleSendReminderModal = useCallback(() => {
    setModalSendReminderActive(true);
  }, []);

  const handleCloseNotification = useCallback(() => {
    dispatch(setNotificationTransactionAction(null));
  }, [dispatch]);

  useEffect(() => {
    if (user?.currentOrganisation?.id && params.transactionId) {
      dispatch(
        getTransactionDataDetail(
          user.currentOrganisation.id,
          type,
          params?.transactionId,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.currentOrganisation, type, params.transactionId]);

  if (!transactionData || loading) {
    return (
      <LoadingPage
        title={translations.layout.loading_text}
      />
    );
  }

  return (
    <div className={classes.transaction}>
      {(recordPayment.loading || acceptQuote.loading || reminder.loading) && (
        <LoadingPage
          title={titleLoading}
          backgroundColor="rgba(32, 39, 43, 0.8)"
        />
      )}
      <div className={classes.header}>
        <div className={classes.header_logo}>
          {user?.currentOrganisation?.logo ? (
            <img src={user?.currentOrganisation?.logo} alt={user.currentOrganisation.name} />
          ) : (
            <span>
              {user?.currentOrganisation?.name && user?.currentOrganisation?.name[0].toUpperCase()}
            </span>
          )}
        </div>
        <CloseIcon className={classes.closeIcon} onClick={history.goBack} />
      </div>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Contacts />
          <OverviewSection
            transaction={transactionData}
            type={type}
            handlePaymentScheduleModal={handlePaymentScheduleModal}
            handleSendReminderModal={handleSendReminderModal}
            handleOpenRecordPayment={handleOpenRecordPayment}
          />
          {!isMobile
            && <SocialTransaction translations={translations} transaction={transactionData} />}
        </div>
        {isMobile ? (
          <Accordion title={transactionTs.items} className={classes.accordion_sidebar}>
            <SideBar
              items={transactionData.items}
              price={transactionData.price}
              translations={translations.sidebar_transaction}
            />
          </Accordion>
        ) : (
          <SideBar
            items={transactionData.items}
            price={transactionData.price}
            translations={translations.sidebar_transaction}
          />
        )}
      </div>
      <PaymentScheduleModal
        active={modalPaymentScheduleActive}
        setActive={setModalPaymentScheduleActive}
        handleOpenRecordPayment={handleOpenRecordPayment}
        totalAmountPaymentSchedule={totalAmountPaymentSchedule}
      />
      <RecordPayment
        totalAmountPaymentSchedule={totalAmountPaymentSchedule}
        active={modalRecordPaymentActive}
        setActive={setModalRecordPaymentActive}
        nextSchedulePayment={nextSchedulePayment}
      />
      <SendReminderModal
        active={modalSendReminderActive}
        setActive={setModalSendReminderActive}
      />
      {recordPayment.isSuccess && (
        <ModalSuccess
          onClick={handleSuccessRecordPayment}
          btnTitle={transactionTs.title_button_success}
          title={transactionTs.payment_recoded}
          text={transactionTs.record_payment_success_text
            .replace('{amount}', formatCurrencyByISO(recordPayment.isSuccess))
            .replace('{type}', `${translations.transaction_type[transactionData.type as keyof TranslationDashboardModel['transaction_type']]}`.toLowerCase())}
          variant="two"
        />
      )}
      {reminder.isSuccess && (
        <ModalSuccess
          onClick={handleSuccessReminder}
          btnTitle={transactionTs.title_button_success}
          title={transactionTs.reminder_success_title}
          text={transactionData.status === TransactionStatus.PENDING
            ? transactionTs.reminder_success_text_expiration.replace('{name}', `${transactionData.contact?.firstName} ${transactionData.contact?.lastName}`)
            : transactionTs.reminder_success_text.replace('{name}', `${transactionData.contact?.firstName} ${transactionData.contact?.lastName}`)}
          variant="two"
        />
      )}
      {notification && (
        <ModalSuccess
          onClick={handleCloseNotification}
          btnTitle={transactionTs.title_button_success}
          title=""
          text={notification}
          variant="two"
        />
      )}
    </div>
  );
};

export default TransactionDetails;

import React from 'react';
// Types
import { IOrderData } from 'store/storefront/order/orderTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Components
import Item from './Item';
// Styles
import classes from './OrderItems.module.scss';

interface Props {
  items: IOrderData['items'];
  currencyIso: string;
  translation: TranslationStorefrontModel['cart']['cart_item']
}

const OrderItems: React.FC<Props> = ({
  items, currencyIso, translation,
}) => (
  <div className={classes.items}>
    {items.map((item) => (
      <Item
        key={item.sku}
        data={item}
        currencyIso={currencyIso}
        translation={translation}
      />
    ))}
  </div>
);

export default OrderItems;

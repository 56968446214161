import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { DashboardRoutePath } from 'router/Routes/Dashboard';

interface HookReturnType {
  goToOrganisation: (orgId: string) => void;
  goToCategories: () => void;
  goToTransactionDetails: (id: string, type: string) => void;
  goToItemDetails: (variationId: string) => void;
  goToDirectoryDetails: (clientId: string) => void;
  pathToCategory: (categoryName: string) => string;
  pathToItemDetails: (variationId: string) => string;
  goToCatalog: () => void;
  goBack: () => void;
  getDashboardHomeUrl: (orgId?: string, reportName?: string) => string;
  pathToOrderDetails: (orderId: string) => string;
  pathToDirectoryDetails: (clientId: string) => string;
  pathToTransactionDetails: (transactionId: string, type: string) => string;
}

export const useDashboardRoutes = (): HookReturnType => {
  const history = useHistory();

  const goToOrganisation = useCallback((orgId: string) => history.push(
    DashboardRoutePath.ORGANISATION.replace(':orgId', orgId),
  ), [history]);

  const getDashboardHomeUrl = (orgId?: string, reportName?: string) => DashboardRoutePath.ORGANISATION.replace(':orgId', orgId || '').replace(':reportName', reportName || '');

  const goToCategories = useCallback(() => history.push(DashboardRoutePath.CATEGORIES), [history]);

  const goToTransactionDetails = useCallback(
    (id: string, type: string) => history.push(
      {
        pathname: DashboardRoutePath.TRANSACTION.replace(':transactionId', id),
        search: `?type=${type}`,
      },
    ),
    [history],
  );

  const goToItemDetails = useCallback((
    variationId: string,
  ) => history.push(DashboardRoutePath.ITEM.replace(':variationId', variationId)), [history]);

  const goToCatalog = useCallback(() => history.push(DashboardRoutePath.CATALOG), [history]);

  const goToDirectoryDetails = useCallback(
    (clientId: string) => history.push(DashboardRoutePath.DIRECTORY_DETAILS.replace(':clientId', clientId), []), [history],
  );

  const pathToOrderDetails = useCallback((orderId: string): string => DashboardRoutePath.ORDER.replace(':orderId', orderId), []);

  const pathToTransactionDetails = useCallback((transactionId: string, type: string): string => `${DashboardRoutePath.TRANSACTION.replace(':transactionId', transactionId)}?type=${type}`, []);

  const pathToCategory = useCallback((
    categoryName: string,
  ): string => DashboardRoutePath.CATEGORY.replace(':categoryName', categoryName), []);

  const pathToItemDetails = useCallback((
    variationId: string,
  ): string => DashboardRoutePath.ITEM.replace(':productId', variationId), []);

  const pathToDirectoryDetails = useCallback((
    clientId: string,
  ): string => DashboardRoutePath.DIRECTORY_DETAILS.replace(':clientId', clientId), []);

  const goBack = useCallback(() => history.goBack(), [history]);

  return {
    pathToCategory,
    pathToItemDetails,
    pathToOrderDetails,
    pathToDirectoryDetails,
    pathToTransactionDetails,
    getDashboardHomeUrl,
    goToOrganisation,
    goToTransactionDetails,
    goToCategories,
    goToItemDetails,
    goToDirectoryDetails,
    goToCatalog,
    goBack,
  };
};

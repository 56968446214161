import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { mixpanelActions } from 'utils/mixpanel';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useQuery } from 'hooks/useQuery';
// Components
import StorefrontLayout from 'layouts/StorefrontLayout';
import CategoriesTabs from 'pages/Storefront/Shop/CategoriesTabs';
import CustomSpinner from 'components/CustomSpinner';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
// Styles
import classes from './Shop.module.scss';

const Shop: React.FC = () => {
  const translations = useTranslationsStorefront();
  const query = useQuery();
  const { goToProduct } = useStorefrontRoutes();
  const location = useLocation();
  const history = useHistory();

  const { id, productId }: { id: string, productId: string } = useParams();
  const { categories, error } = useTypeSelector(({ storefront }) => storefront.shop);

  useEffect(() => {
    mixpanelActions.track(`Viewed a ${id} Shop`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    query.delete('payment_intent_client_secret');
    query.delete('payment_intent');
    query.delete('redirect_status');
    history.replace({
      search: query.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productId) {
      goToProduct(id, productId, location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <StorefrontLayout>
        <h1 className={classes.error}>
          {translations.server_error}
        </h1>
      </StorefrontLayout>
    );
  }

  return (
    <StorefrontLayout>
      {!query.has('search') && categories.data.length ? (
        <div className={classes.content}>
          <CategoriesTabs tabs={categories.data} />
        </div>
      ) : (
        <div className={classes.loading_wrapper}>
          <CustomSpinner variant="two" />
        </div>
      )}
    </StorefrontLayout>
  );
};

export default Shop;

import React, {
  Dispatch, SetStateAction, useCallback, useEffect,
} from 'react';
import cn from 'classnames';
// Styles
import classes from './Modal.module.scss';

interface Props {
  setActive: Dispatch<SetStateAction<boolean>>;
  active: boolean;
  type?: 'two' | 'three' | 'four',
  closeModal?: () => void;
  className?: string;
}

const Modal: React.FC<Props> = ({
  active, setActive, children, type, closeModal, className,
}) => {
  const showHideClassName = cn(classes.modal, {
    [classes.modal_show]: active,
    [classes.modal_hide]: !active,
  });

  const handleClose = useCallback(() => {
    setActive(false);
  }, [setActive]);

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [active]);

  if (type === 'three') {
    return (
      <div
        className={showHideClassName}
        tabIndex={0}
        role="button"
        onClick={handleClose}
      >
        <div
          className={active ? classes.modal_wrapper_three_active : classes.modal_wrapper_three}
          style={{ minWidth: 'auto' }}
          tabIndex={0}
          role="button"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    );
  }

  if (type === 'two') {
    return (
      <div
        className={showHideClassName}
        tabIndex={0}
        role="button"
        onClick={closeModal || handleClose}
      >
        <div
          className={active ? classes.modal_wrapper_two_active : classes.modal_wrapper_two}
          tabIndex={0}
          role="button"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    );
  }

  if (type === 'four') {
    return (
      <div
        className={showHideClassName}
        tabIndex={0}
        role="button"
        onClick={closeModal || handleClose}
      >
        <div
          className={active ? classes.modal_wrapper_four_active : classes.modal_wrapper_four}
          tabIndex={0}
          role="button"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    );
  }

  return (
    <div
      className={showHideClassName}
      tabIndex={0}
      role="button"
      onClick={closeModal || handleClose}
    >
      <div
        className={cn(`${classes.modal_wrapper} ${className}`, {
          [`${classes.modal_wrapper_active} ${className}`]: active,
        })}
        tabIndex={0}
        role="button"
        onClick={(e) => e.stopPropagation()}
      >
        {active && children}
      </div>
    </div>
  );
};

export default Modal;

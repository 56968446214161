import React, { useRef } from 'react';
import Slider from 'react-slick';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslations } from 'hooks/useTranslations';
// Components
import DonutChartCard from 'components/DashboardComponents/DonutChartCard';
// Styles
import classes from './DirectorySummary.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const DirectorySummary: React.FC = () => {
  const sliderRef = useRef<Slider>(null);
  const { isMobile, windowWidth } = useMobileScreen();
  const { summary } = useTypeSelector(({ directory }) => directory);
  const translations = useTranslations();
  const summaryTs = translations.directory.summary;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: (windowWidth && windowWidth > 768 && windowWidth <= 886)
      || (windowWidth && windowWidth < 620) ? 2.5 : 3.1,
    slidesToScroll: 1,
  };

  const directorySummary = [
    {
      name: summaryTs.total_directory,
      percent: 100,
      total: summary.total,
      data: {
        subtitle: '',
        value: `${summary.total} ${summary.total > 1 ? summaryTs.contacts : summaryTs.contact}`,
        format: '.1s',
      },
    },
    {
      name: summaryTs.customers,
      percent: 100,
      total: summary.customers,
      data: {
        subtitle: '',
        value: `${summary.customers} ${summary.customers > 1 ? summaryTs.contacts : summaryTs.contact}`,
        format: '.1s',
      },
    },
    {
      name: summaryTs.vendors,
      percent: 100,
      total: summary.suppliers,
      data: {
        subtitle: '',
        value: `${summary.suppliers} ${summary.suppliers > 1 ? summaryTs.contacts : summaryTs.contact}`,
        format: '.1s',
      },
    },
  ];

  return (isMobile ? (
    <div className={classes.slider}>
      <Slider {...settings} ref={sliderRef}>
        {directorySummary?.map((item) => (
          <div className={classes.card} key={item.name}>
            <DonutChartCard
              key={item.name}
              title={item.name}
              percentage={item.percent}
              value={item.total}
              type="two"
              data={item.data}
            />
          </div>
        ))}
      </Slider>
    </div>
  ) : (
    <div className={classes.table_summary}>
      {directorySummary?.map((item) => (
        <div className={classes.card} key={item.name}>
          <DonutChartCard
            key={item.name}
            title={item.name}
            percentage={item.percent}
            value={item.total}
            type="two"
            data={item.data}
          />
        </div>
      ))}
    </div>
  ));
};

export default DirectorySummary;

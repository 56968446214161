import { StylesConfig } from 'react-select';
import { ReactSelectOption } from 'types/globalTypes';

type IsMulti = false;

export const customStyles: StylesConfig<ReactSelectOption, IsMulti> = {

  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 1)',
    background: 'transparent',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: 'Visuelt Pro',
    lineHeight: '18px',
    fontWeight: 700,
    outline: 'none',
    '&:hover': {
      opacity: '0.5',
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '@media only screen and (max-width: 450px)': {
      fontSize: '12px',
      lineHeight: '12px',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Visuelt Pro',
    color: 'rgba(25, 28, 35, 1)',
    marginRight: '20px',
    '@media only screen and (max-width: 450px)': {
      width: '93%',
    },
  }),
  control: () => ({
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
  }),
  container: (provided, state) => ({
    position: 'relative',
    padding: '18px 14px 18px 91px',
    border: '1px solid #DDE3E8',
    width: '240px',
    fontSize: '12px',
    cursor: 'pointer',
    '&:hover': {
      opacity: state.selectProps.menuIsOpen ? 'inherit' : '0.5',
    },
    '@media only screen and (max-width: 450px)': {
      width: '100%',
      padding: '18px 14px 18px 20px',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    background: 'linear-gradient(55.52deg, #7233CC -5.2%, #6B59ED 65.07%)',
    borderRadius: '4px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    padding: 0,
    margin: 0,
    left: 0,
    borderRadius: '4px',
  }),
  // @ts-ignore
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 1)',
    transition: 'all .2s ease',
    padding: 0,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    '@media only screen and (max-width: 450px)': {
      svg: {
        width: '15px',
        height: '15px',
      },
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
    color: 'rgba(32, 39, 43, 0.5)',
    '@media only screen and (max-width: 772px)': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  }),
  indicatorContainer: () => ({
    backgroundColor: '#7233CC',
    display: 'block',
    padding: 0,
  }),

  indicatorSeparator: () => ({}),
};

export const customStylesTwo: StylesConfig<ReactSelectOption, IsMulti> = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 1)',
    background: 'transparent',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: 'Visuelt Pro',
    lineHeight: '18px',
    letterSpacing: '-0.16875px',
    fontWeight: 700,
    outline: 'none',
    '&:hover': {
      opacity: '0.5',
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '@media only screen and (max-width: 450px)': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    padding: '3px 0',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    fontFamily: 'Visuelt Pro',
    color: 'rgba(107, 89, 237, 1)',
  }),
  control: () => ({
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
  }),
  container: (provided, state) => ({
    position: 'relative',
    width: '140px',
    fontSize: '12px',
    cursor: 'pointer',
    '&:hover': {
      opacity: state.selectProps.menuIsOpen ? 'inherit' : '0.5',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    border: '1px solid #FFFF',
    background: 'linear-gradient(55.52deg, #7233CC -5.2%, #6B59ED 65.07%)',
    borderRadius: '4px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    padding: 0,
    margin: 0,
    borderRadius: '4px',
  }),
  // @ts-ignore
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'rgba(107, 89, 237, 1)',
    transition: 'all .2s ease',
    padding: 0,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    '@media only screen and (max-width: 450px)': {
      svg: {
        width: '15px',
        height: '15px',
      },
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
    color: 'rgba(32, 39, 43, 0.5)',
    '@media only screen and (max-width: 772px)': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  }),
  indicatorContainer: () => ({
    backgroundColor: '#7233CC',
    display: 'block',
    padding: 0,
  }),

  indicatorSeparator: () => ({}),
};

import React, { FC } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Components
import Item from './Item';
import Price from './Price';
// Classes
import classes from './SideBar.module.scss';

const SideBarOrderDetails: FC = () => {
  const { sidebar_transaction } = useTranslations();
  const { orderDetails } = useTypeSelector(({ order }) => order);

  if (!orderDetails) {
    return null;
  }

  return (
    <div className={classes.sidebar}>
      <h2 className={classes.sidebar_title}>
        {sidebar_transaction.items}
      </h2>
      <div className={classes.sidebar_items}>
        {orderDetails.items.map((item) => (
          <Item
            key={item.sku || item.id || item.name}
            data={item}
            currencyIso={orderDetails.currencyISO}
            translations={sidebar_transaction.transaction_item}
          />
        ))}
      </div>
      <div className={classes.price}>
        <Price
          price={orderDetails.price}
          items={orderDetails.items}
          translations={sidebar_transaction.price}
        />
      </div>
    </div>
  );
};

export default SideBarOrderDetails;

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/remote-config';
// Configs
import { firebaseConfigDev, firebaseConfigProd, firebaseConfigStaging } from './firebaseConfigs';

let firebaseConfig;
switch (process.env.MODE) {
  case 'dev':
    firebaseConfig = firebaseConfigDev;
    break;
  case 'prod':
    firebaseConfig = firebaseConfigProd;
    break;
  case 'staging':
    firebaseConfig = firebaseConfigStaging;
    break;
  default:
    firebaseConfig = firebaseConfigProd;
    break;
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfigDev);
}

export const auth = firebase.auth();
export const remoteConfig = firebase.remoteConfig();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EmailAuthProvider = (<any> firebase.auth.EmailAuthProvider).credential;

export default firebase;

/* eslint-disable no-nested-ternary */
import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';
// Types
import { QuotePriceType, QuoteTermsPaymentType } from 'store/quote/quoteTypes';
import { DetailsType } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Utils
import { makeValue } from 'utils/functions';
// Styles
import classes from './TransactionOverview.module.scss';

interface Props {
  details: DetailsType;
  price: QuotePriceType;
  termsPayment: QuoteTermsPaymentType;
  isUnpaid: boolean;
  translations: TranslationDashboardModel['transaction_overview']
}

const TransactionOverview: React.FC<Props> = ({
  details,
  isUnpaid,
  translations,
  termsPayment,
}) => {
  const transactionOverview = useMemo(() => {
    if (isUnpaid) {
      return [
        makeValue(translations.created, details.createdAt),
        makeValue(translations.expires_on, termsPayment.expiryDate),
        makeValue(translations.notes, termsPayment.notes),
      ];
    }
    if (termsPayment.isExpired) {
      return [
        makeValue(translations.created, details.createdAt),
        makeValue(translations.expires_on, termsPayment.expiryDate),
        makeValue(translations.notes, termsPayment.notes),
      ];
    }
    return [
      makeValue(translations.created, details.createdAt),
      makeValue(translations.expires_on, termsPayment.expiryDate),
      makeValue(translations.pricing_sent_on, termsPayment.updatedAt),
      makeValue(translations.notes, termsPayment.notes),
    ];
  }, [isUnpaid,
    translations,
    termsPayment.isExpired,
    details.createdAt,
    termsPayment.notes,
    termsPayment.updatedAt,
    termsPayment.expiryDate]);

  return (
    <div className={classes.transaction__overview}>
      {transactionOverview.map((el) => typeof el.value !== 'undefined' && (
        <div className={classes.transaction__overview_element} key={nanoid()}>
          <span className={classes.transaction__overview_element_name}>
            {el.name}
          </span>
          <span className={classes.transaction__overview_element_value}>
            {el.value}
          </span>
        </div>
      ))}
    </div>

  );
};

export default TransactionOverview;

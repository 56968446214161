import React from 'react';
import Select from 'react-select';
// Styles
import classes from './SelectLanguages.module.scss';
import { customStyles } from './stylesSelect';

interface Props {
  options: {
    value: string;
    label: string;
  }[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: (e: any) => void;
  defaultLang?: {
    value: string;
    label: string;
  }
}

const SelectLanguages: React.FC<Props> = ({ options, handleChange, defaultLang }) => (
  <div className={classes.world_languages}>
    <div className={classes.world_languages_change}>
      <Select
        options={options}
        defaultValue={
          { label: defaultLang?.label ?? '', value: defaultLang?.value ?? '' }
        }
        onChange={handleChange}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        styles={customStyles}
        menuPlacement="top"
      />
    </div>
  </div>
);

export default SelectLanguages;

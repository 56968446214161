import { useTranslations } from 'hooks/useTranslations';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import React from 'react';
import classes from './MoreButton.module.scss';

const MoreButton: React.FC = () => {
  const translations = useTranslations();
  const storefrontTranslations = useTranslationsStorefront();

  return (
    <div className={classes.more_btn}>
      {translations.layout.more || storefrontTranslations.layout.more}
    </div>
  );
};

export default MoreButton;

export const EPS_ELEMENT_OPTIONS = {
  accountHolderType: 'company' as const,
  classes: {
    base: 'StripeElementFpx',
    focus: 'StripeElementFpx--focus',
  },
  style: {
    base: {
      padding: '20px',
      fontSize: '16px',
      color: 'rgba(25, 28, 35, 1)',
      fontWeight: 400,
      fontFamily: 'Visuelt Pro',
      ':-webkit-autofill': {
        color: 'rgba(25, 28, 35, 0.4)',
      },
      letterSpacing: '0.025em',
      '::placeholder': {
        color: 'rgba(25, 28, 35, 1)',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

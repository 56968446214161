import React, { useCallback, useState } from 'react';
// Hooks
import { useMobileScreen } from 'hooks/useMobileScreen';
// Assets
import { ReactComponent as DownIcon } from 'assets/dashboard-icons/down-arrow.svg';
// Types
import { Variation } from 'store/dashboard/catalog/items/itemsTypes';
// Styles
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import classes from './ItemDetailsVariant.module.scss';

interface Props {
  variant: Variation
}

const ItemDetailsVariant: React.FC<Props> = ({ variant }) => {
  const [stockLocation, setStockLocation] = useState<boolean>();
  const { locations } = useTypeSelector((state) => state.dashboard.catalog.items);
  const { catalog } = useTranslations();
  const { isMobile } = useMobileScreen();
  const handleClick = useCallback(() => {
    setStockLocation(!stockLocation);
  }, [stockLocation]);

  const findSalesChannelName = useCallback((salesChannelId: string) => locations?.find(
    (salesChannel) => salesChannelId === salesChannel.id,
  )?.name, [locations]);

  if (isMobile) {
    return (
      <div className={classes.variant}>
        <div className={classes.variant_description}>
          <p className={classes.variant_name}>
            {variant.name.replace(',', ', ')}
          </p>
          <p className={classes.variant_sku}>
            {catalog.item_details.details_stock.sku.replace('{sku}', variant.sku || '')}
          </p>
        </div>
        <div className={classes.variant_stock}>
          <p className={classes.variant_stock_label}>
            {catalog.item_details.details_stock.total_stock_label}
          </p>
          <p className={classes.variant_stock_value}>{variant.stock || 0}</p>
        </div>
        <div className={classes.location}>
          <p className={classes.location_label}>
            {catalog.item_details.details_stock.stock_location_label}
          </p>
          <div className={stockLocation ? classes.more_stock_active : classes.more_stock} tabIndex={0} role="button" onClick={handleClick}>
            <p className={classes.more_stock_title}>{stockLocation
              ? catalog.item_details.details_stock.collapse
              : catalog.item_details.details_stock.expand}
            </p>
            <DownIcon />
          </div>
        </div>
        {variant.inventories.map((inventory) => (
          <div
            key={inventory.locationId}
            className={stockLocation
              ? classes.inventory__sales_channel_active
              : classes.inventory__sales_channel}
          >
            <p className={(inventory.lowStockCount || 0) >= (inventory.quantity || 0)
              ? classes.inventory__sales_channel_name_low
              : classes.inventory__sales_channel_name}
            >

              {findSalesChannelName(inventory.locationId) || ''}
            </p>
            <p className={(inventory.lowStockCount || 0) >= (inventory.quantity || 0)
              ? classes.inventory_quantity_name_low
              : classes.inventory_quantity_name}
            >
              {inventory.quantity}
            </p>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={classes.variant}>
      <div className={classes.variant_description}>
        <p className={classes.variant_name}>
          {variant.name.replace(',', ', ')}
        </p>
        <p className={classes.variant_sku}>
          {catalog.item_details.details_stock.sku.replace('{sku}', variant.sku || '')}
        </p>
      </div>
      <div className={classes.variant_stock}>
        <p className={classes.variant_stock_label}>
          {catalog.item_details.details_stock.total_stock_label}
        </p>
        <p className={classes.variant_stock_value}>{variant.stock || 0}</p>
        {variant.inventories.map((inventory) => (
          <div
            key={inventory.locationId}
            className={stockLocation
              ? classes.inventory_quantity_active
              : classes.inventory_quantity}
          >
            <p className={(inventory.lowStockCount || 0) >= (inventory.quantity || 0)
              ? classes.inventory_quantity_name_low
              : classes.inventory_quantity_name}
            >
              {inventory.quantity}
            </p>
          </div>
        ))}
      </div>
      <div className={classes.location}>
        <p className={classes.location_label}>
          {catalog.item_details.details_stock.stock_location_label}
        </p>
        <div className={stockLocation ? classes.more_stock_active : classes.more_stock} tabIndex={0} role="button" onClick={handleClick}>
          <p className={classes.more_stock_title}>{stockLocation
            ? catalog.item_details.details_stock.collapse
            : catalog.item_details.details_stock.expand}
          </p>
          <DownIcon />
        </div>
        {variant.inventories.map((inventory) => (
          <div
            key={inventory.locationId}
            className={stockLocation
              ? classes.inventory__sales_channel_active
              : classes.inventory__sales_channel}
          >
            <p className={(inventory.lowStockCount || 0) >= (inventory.quantity || 0)
              ? classes.inventory__sales_channel_name_low
              : classes.inventory__sales_channel_name}
            >
              {findSalesChannelName(inventory.locationId) || ''}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemDetailsVariant;

import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
// Styles;
import classes from './AfterpayClearpay.module.scss';

interface Props {
  register: UseFormReturn['register']
}

const AfterpayClearpay: React.FC<Props> = ({ register }) => {
  const { checkout } = useTranslationsStorefront();

  return (
    <div className={classes.pay_later_input}>
      <label
        htmlFor="email"
        className={classes.input}
      >
        <span>
          {checkout.card_form.email_label}
        </span>
        <input
          {...register('email', {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: checkout.email_input.error,
            },
          })}
          placeholder={checkout.card_form.email_label}
        />
      </label>
      <label
        htmlFor="fullName"
        className={classes.input}
      >
        <span>
          {checkout.card_form.name_label}
        </span>
        <input
          {...register('fullName', { required: true })}
          placeholder={checkout.address_input.full_name}
        />
      </label>
      <label
        htmlFor="address1"
        className={classes.input}
      >
        <span>
          {checkout.address_input.label}
        </span>
        <input
          placeholder={checkout.address_input.placeholder}
          {...register('address1', { required: true })}
        />
        <input
          {...register('address2', { required: true })}
          placeholder={checkout.address_input.suite_placeholder}
        />
      </label>
      <label
        htmlFor="city"
        className={classes.input}
      >
        <span>
          {checkout.address_input.town_city_label}
        </span>
        <input
          {...register('city', { required: true })}
          placeholder={checkout.address_input.town_city_placeholder}
        />
      </label>
      <label
        htmlFor="state"
        className={classes.input}
      >
        <span>
          {checkout.address_input.state_label}
        </span>
        <input
          {...register('state', { required: true })}
          placeholder={checkout.address_input.state_label}
        />
      </label>
      <label
        htmlFor="postalCode"
        className={classes.input}
      >
        <span>
          {checkout.address_input.postcode_label}
        </span>
        <input
          {...register('postalCode', { required: true })}
          placeholder={checkout.address_input.postcode_placeholder}
        />
      </label>
      <p>{checkout.card_form.shipping}</p>
      <label
        htmlFor="fullName"
        className={classes.input}
      >
        <span>
          {checkout.card_form.name_label}
        </span>
        <input
          {...register('shipping.fullName', { required: true })}
          placeholder={checkout.address_input.full_name}
        />
      </label>
      <label
        htmlFor="address1"
        className={classes.input}
      >
        <span>
          {checkout.address_input.label}
        </span>
        <input
          placeholder={checkout.address_input.placeholder}
          {...register('shipping.address1', { required: true })}
        />
        <input
          placeholder={checkout.address_input.suite_placeholder}
          {...register('shipping.address2', { required: true })}
        />
      </label>
      <label
        htmlFor="city"
        className={classes.input}
      >
        <span>
          {checkout.address_input.town_city_label}
        </span>
        <input
          {...register('shipping.city', { required: true })}
          placeholder={checkout.address_input.town_city_placeholder}
        />
      </label>
      <label
        htmlFor="state"
        className={classes.input}
      >
        <span>
          {checkout.address_input.state_label}
        </span>
        <input
          {...register('shipping.state', { required: true })}
          placeholder={checkout.address_input.state_label}
        />
      </label>
      <label
        htmlFor="postalCode"
        className={classes.input}
      >
        <span>
          {checkout.address_input.postcode_label}
        </span>
        <input
          {...register('shipping.postalCode', { required: true })}
          placeholder={checkout.address_input.postcode_placeholder}
        />
      </label>
    </div>
  );
};

export default AfterpayClearpay;

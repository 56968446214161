import { useMemo } from 'react';
import { useTranslations } from './useTranslations';

export const useTabs = (): { [x: string]: number }[] => {
  const translations = useTranslations();
  const tsTabs = translations?.home?.report?.tabs;
  return useMemo(() => [
    {
      [tsTabs?.re_sales]: 0,
    },
    {
      [tsTabs?.re_expenses]: 1,
    },
    {
      [tsTabs?.re_aged_invoices]: 2,
    },
    {
      [tsTabs?.re_aged_bills]: 3,
    },
    {
      [tsTabs?.re_orders]: 4,
    },
    {
      [tsTabs?.re_estimates]: 5,
    },
    {
      [tsTabs?.re_quotes]: 6,
    },
    {
      [tsTabs?.re_sales_taxes]: 7,
    },
    {
      [tsTabs?.re_customers]: 8,
    },
    {
      [tsTabs?.re_vendors]: 9,
    },
    {
      [tsTabs?.re_income_statement]: 10,
    },
  ], [tsTabs]);
};

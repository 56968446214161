import React, { useEffect, useMemo, useState } from 'react';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Types
import { IDeliveryData, IPickupData, IShippingData } from 'store/storefront/shop/shopTypes';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { CartFunctions } from 'utils/storefront/CartFunctions';
// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/back-arrow.svg';
// Styles
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import classes from './Summary.module.scss';

interface Props {
  handleCheckout: () => void;
  handleContinueShopping: () => void;
  existInfoCustomer: boolean;
  currDeliveryPlace: IDeliveryData | undefined
  currentShippingCountry: IShippingData | undefined
  currentPickupCountry: IPickupData[] | undefined
}

const Summary: React.FC<Props> = ({
  handleCheckout,
  handleContinueShopping,
  existInfoCustomer,
  currentShippingCountry,
  currDeliveryPlace,
  currentPickupCountry,
}) => {
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const { fulfillment } = useTypeSelector(({ storefront }) => storefront.checkout);
  const [disableCheckout, setDisableCheckout] = useState<boolean>(false);

  const formatCurrencyByISO = CurrencyFormatByISO(currentSalesChannel?.address.currencyISO);

  const { cart, checkout } = useTranslationsStorefront();
  const tsSummary = cart.summary;

  const shippingLabel = useMemo(() => {
    if (fulfillment.method === DeliveryMethodsEnum.DELIVERY) {
      return {
        label: tsSummary.delivery_fee_text,
        value: formatCurrencyByISO(fulfillment.amount.fee),
      };
    }

    if (fulfillment.method === DeliveryMethodsEnum.PICKUP) {
      return {
        label: tsSummary.pickup_fee_text,
        value: formatCurrencyByISO(fulfillment.amount.fee),
      };
    }

    if (fulfillment.method === DeliveryMethodsEnum.SHIPPING) {
      return {
        label: tsSummary.shipping_fee_text,
        value: formatCurrencyByISO(fulfillment.amount.fee),
      };
    }

    return {
      label: tsSummary.shipping_label,
      value: tsSummary.shipping_text,
    };
  }, [formatCurrencyByISO,
    fulfillment.amount.fee,
    fulfillment.method, tsSummary]);

  useEffect(() => {
    const isDisabled = CartFunctions.isDisableCheckout(fulfillment);
    const existShippingCountry = !(currentPickupCountry && !!currentPickupCountry.length)
      && !currDeliveryPlace && !currentShippingCountry && existInfoCustomer;

    if (existShippingCountry) {
      setDisableCheckout(!existShippingCountry);
    } else {
      setDisableCheckout(isDisabled);
    }
  }, [
    currDeliveryPlace,
    currentShippingCountry, existInfoCustomer, fulfillment, currentPickupCountry]);

  return (
    <div className={classes.summary}>
      <h2 className={classes.summary_title}>
        {tsSummary.title}
      </h2>
      <div className={classes.summary_list}>
        <div className={classes.summary_list_subtotal}>
          <p>
            {tsSummary.subtotal_label}
          </p>
          <p>
            {formatCurrencyByISO(fulfillment.amount.subtotal)}
          </p>
        </div>
        <div className={classes.summary_list_shipping}>
          <p>
            {shippingLabel.label}
          </p>
          <p className={fulfillment.method ? classes.fee_value_active : classes.fee_value}>
            {shippingLabel.value}
          </p>
        </div>
        <div className={classes.summary_list_taxes__included}>
          <p>
            {tsSummary.taxes_included_label}
          </p>
          <p>
            {formatCurrencyByISO(fulfillment.amount.taxesIncluded)}
          </p>
        </div>
        <div className={classes.summary_list_taxes__excluded}>
          <p>
            {tsSummary.taxes_excluded_label}
          </p>
          <p>
            {formatCurrencyByISO(fulfillment.amount.taxesExcluded)}
          </p>
        </div>
      </div>
      <div className={classes.summary_total}>
        <p>
          {tsSummary.total_label}
        </p>
        <p>
          {formatCurrencyByISO(fulfillment.amount.total)}
        </p>
      </div>
      <div className={classes.summary_buttons}>
        <button type="button" className={classes.summary_buttons_continue} onClick={handleContinueShopping}>
          <div className={classes.summary_buttons_continue_icon}>
            <ArrowIcon />
          </div>
          {checkout.summary.back_text_btn}
        </button>
        <button
          type="button"
          className={classes.summary_buttons_checkout}
          onClick={handleCheckout}
          disabled={disableCheckout}
        >
          {checkout.summary.place__order_text_btn}
        </button>
      </div>
    </div>
  );
};

export default Summary;

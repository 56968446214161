import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import Notifications from 'layouts/DashboardLayout/Notifications';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setNotificationOrderAction } from 'store/dashboard/orders/orderActions';
import { selectOrderDetails } from 'store/dashboard/orders/orderSelectors';
import { TransactionStatus } from 'types/globalTypes';
// Styles
import classes from './NotificationsOrderDetails.module.scss';

const NotificationsOrderDetails: React.FC = () => {
  const { notification } = useTypeSelector(({ order }) => order);
  const translations = useTranslations();
  const dispatch = useDispatch();
  const transactionTs = translations.transactions;
  const orderDetails = useTypeSelector(selectOrderDetails);
  const closeNotification = () => {
    dispatch(setNotificationOrderAction(null));
  };

  if (notification === 'reminder') {
    return (
      <Notifications
        active={notification === 'reminder'}
        onClick={closeNotification}
        btnTitle={translations.orders.notifications.btn_title}
        title={transactionTs.reminder_success_title}
        text={transactionTs.reminder_success_text.replace('{name}', `${orderDetails?.contact?.firstName} ${orderDetails?.contact?.lastName}`)}
        className={classes.notification}
      />
    );
  }

  if (notification === TransactionStatus.CANCELLED) {
    return (
      <Notifications
        active={notification === TransactionStatus.CANCELLED}
        onClick={closeNotification}
        btnTitle={translations.orders.notifications.btn_title}
        title={translations.orders.notifications.title_canceled}
        text={translations.orders.notifications.text_canceled}
        className={classes.notification}
      />
    );
  }

  if (notification === TransactionStatus.PROCESSED
    && orderDetails?.fulfillmentName === DeliveryMethodsEnum.PICKUP) {
    return (
      <Notifications
        active
        onClick={closeNotification}
        btnTitle={translations.orders.notifications.btn_title}
        title={translations.orders.notifications.title_processed}
        text={translations.orders.notifications.text_pickup.replace('{OrderCustomerName}', orderDetails.contact?.firstName || '')}
        className={classes.notification}
      />
    );
  }

  if (notification === TransactionStatus.PROCESSED
    && orderDetails?.fulfillmentName === DeliveryMethodsEnum.SHIPPING) {
    return (
      <Notifications
        active
        onClick={closeNotification}
        btnTitle={translations.orders.notifications.btn_title}
        title={translations.orders.notifications.title_processed}
        text={translations.orders.notifications.text_shipping.replace('{OrderCustomerName}', orderDetails.contact?.firstName || '')}
        className={classes.notification}
      />
    );
  }

  if (notification === TransactionStatus.PROCESSED
    && orderDetails?.fulfillmentName === DeliveryMethodsEnum.DELIVERY) {
    return (
      <Notifications
        active
        onClick={closeNotification}
        btnTitle={translations.orders.notifications.btn_title}
        title={translations.orders.notifications.title_processed}
        text={translations.orders.notifications.text_delivery.replace('{OrderCustomerName}', orderDetails.contact?.firstName || '')}
        className={classes.notification}
      />
    );
  }

  return null;
};

export default NotificationsOrderDetails;

import {
  OrderActions,
  OrderState,
  SET_ORDER_DETAILS,
  PAYMENTS_METHODS_SET_DATA,
  APP_SET_NOTIFICATION_ORDER,
  ORDER_SET_LOADING,
  SET_UPDATE_DETAILS,
  SET_ORDERS,
  CLEAR_ORDERS,
  MORE_PAGE,
  ORDERS_SET_SUMMARY,
  ORDERS_IS_REMINDER,
} from './orderTypes';

const initialState: OrderState = {
  orderDetails: null,
  paymentsMethods: [],
  notification: null,
  loading: '',
  orders: [],
  summary: null,
  morePage: false,
  isRemind: false,
};

export const OrderDashboardReducer = (
  state: OrderState = initialState,
  action: OrderActions,
): OrderState => {
  switch (action.type) {
    case SET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload,
      };
    case PAYMENTS_METHODS_SET_DATA:
      return {
        ...state,
        paymentsMethods: action.payload,
      };
    case APP_SET_NOTIFICATION_ORDER:
      return {
        ...state,
        notification: action.payload,
      };
    case ORDER_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_UPDATE_DETAILS: {
      return {
        ...state,
        orderDetails: {
          contact: state.orderDetails?.contact,
          ...action.payload,
        },
      };
    }
    case SET_ORDERS: {
      return {
        ...state,
        orders: action.payload,
      };
    }
    case CLEAR_ORDERS: {
      return {
        ...state,
        orders: action.payload,
      };
    }
    case MORE_PAGE:
      return {
        ...state,
        morePage: action.payload,
      };
    case ORDERS_SET_SUMMARY:
      action.payload.locations.sort((a: { percentage: number }, b: { percentage: number }) => {
        if (a.percentage > b.percentage) {
          return -1;
        }
        if (a.percentage < b.percentage) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        summary: action.payload,
      };
    case ORDERS_IS_REMINDER:
      return {
        ...state,
        isRemind: action.payload,
      };
    default:
      return state;
  }
};

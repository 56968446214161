/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
import { ICheckoutFulfillment } from 'store/storefront/checkout/checkoutTypes';
import { CartItemType, IFulfillment, SalesChannel } from 'store/storefront/shop/shopTypes';

export class CartFunctions {
  static getFulfilmentMethod(
    fulfilment: IFulfillment,
  ): DeliveryMethodsEnum {
    if (fulfilment.shipping.countries.length !== 0) {
      return DeliveryMethodsEnum.SHIPPING;
    }

    if (fulfilment.pickup.locations.length !== 0) {
      return DeliveryMethodsEnum.PICKUP;
    }

    return DeliveryMethodsEnum.DELIVERY;
  }

  static getProductWithVariation(
    product: ProductItem,
    currentOptionId: string,
  ): ProductItem {
    const foundedProduct = {
      ...product,
      variations: product.variations.length ? [product.variations.find(
        (variation) => variation.id === currentOptionId,
      )!] : [],
    };

    return foundedProduct;
  }

  static isProductInCart(
    bag: CartItemType[],
    product: ProductItem,
    currentOptionId?: string | null,
  ): boolean {
    const productInCart = bag.findIndex((item) => {
      if (currentOptionId) {
        const productVariations = item.variations.find(
          (variation) => variation.id === currentOptionId,
        );
        return productVariations;
      }
      const productVariations = item.id === product.id;
      return productVariations;
    });

    if (productInCart === -1) {
      return false;
    }

    return true;
  }

  static updateCart(
    bag: CartItemType[],
    productId: string,
    quantity: number,
    option?: string | null,
    isShoppingCart?: boolean,
  ): { bag: CartItemType[], product: CartItemType } {
    const productInCart = bag.findIndex((item) => item.variations.find(
      (variation) => variation.id === option,
    ));
    const updatedBag = bag.map((bagItem, index) => {
      if (index === productInCart) {
        return {
          ...bagItem,
          quantity: isShoppingCart ? quantity : bagItem.quantity + quantity,
        };
      }

      return bagItem;
    });
    return {
      bag: updatedBag,
      product: updatedBag[productInCart],
    };
  }

  static calculateTotalPrice(bag: CartItemType[]): number {
    const totalPrice = bag.reduce(
      (acc, next) => acc + next.variations.reduce(
        (p, c) => p + c?.price, 0,
      ) * next.quantity,
      0,
    );

    return totalPrice;
  }

  static calculateTotalPriceProduct(quantity: number, item?: CartItemType): number {
    if (!item) {
      return 0;
    }
    return item.variations?.reduce((
      prev, curr,
    ) => prev + (curr.price || 0), 0) * quantity;
  }

  static RemoveInCart = (bag: CartItemType[], product: CartItemType): CartItemType[] => {
    const productInCart = bag.findIndex((item) => {
      const productVariations = item.variations.find(
        (variation) => variation.id === product.variations[0].id
          && variation.itemId === product.id,
      );
      if (productVariations) {
        return true;
      }
      return false;
    });

    const updateBag = bag.filter((products, i) => i !== productInCart);

    return updateBag;
  };

  static isStockValid(
    bag: CartItemType[],
    currentSalesChannel: SalesChannel | null,
  ): boolean {
    return bag.length !== 0 && !bag.every((item) => {
      const inventory = item?.variations[0].inventories?.find(
        (i) => i.locationId === currentSalesChannel?.id,
      );

      if (!inventory) {
        return false;
      }

      return inventory.quantity >= item.quantity && inventory.quantity !== 0;
    });
  }

  static isDisableCheckout(
    fulfilment: ICheckoutFulfillment,
  ): boolean {
    if (fulfilment.method === DeliveryMethodsEnum.SHIPPING && fulfilment.contact?.address?.country === '') {
      return true;
    }

    if (fulfilment.method === DeliveryMethodsEnum.PICKUP && fulfilment?.location?.name === '') {
      return true;
    }

    if (fulfilment.method === DeliveryMethodsEnum.DELIVERY
      && fulfilment?.location?.name === ''
      && fulfilment.contact?.address?.country === '') {
      return true;
    }

    if (fulfilment.method === null) {
      return true;
    }

    return false;
  }
}

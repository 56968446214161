import React, { useCallback } from 'react';
import { useQuery } from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useUser } from 'hooks/useUser';
import { useTranslations } from 'hooks/useTranslations';
// Actions
import { getSearchResult } from 'store/dashboard/app/appActions';
// Types
import { SearchTypes } from 'store/dashboard/app/appTypes';
// Components
import SvgSprite from 'components/SvgSprite';
// Classes
import classes from './SearchTags.module.scss';

interface Props {
  searchTags: {
    name: string;
    value: string;
    icon: string;
  }[],
  focusInput: boolean
  setFocusInput: (value: React.SetStateAction<boolean>) => void
}

const SearchTags: React.FC<Props> = ({ searchTags, focusInput, setFocusInput }) => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const translation = useTranslations();
  const user = useUser();
  const tags = query.get('type');

  const handleTag = useCallback((tag) => {
    if (!query.has('type')) {
      query.append('type', tag.value);

      if (query.has('search') && user?.currentOrganisation?.id) {
        dispatch(getSearchResult(query.get('search') || '',
          tag.value as SearchTypes,
          user?.currentOrganisation?.id));
      }
    } else {
      query.set('type', tag.value);
    }

    if (!tag.value) {
      query.delete('type');
    }

    history.replace({
      search: query.toString(),
    });

    setFocusInput(true);
  }, [dispatch, history, query, setFocusInput, user?.currentOrganisation?.id]);

  return (
    <div className={focusInput && !tags ? classes.search_tags_active : classes.search_tags}>
      <h3 className={classes.search_tags_title}>
        {translation.search.filters.title}
      </h3>
      <div className={classes.search_tags_list}>
        {searchTags.map((tag) => (
          <button
            key={tag.name}
            name={tag.name}
            type="button"
            className={classes.search_tag}
            onClick={() => handleTag(tag)}
          >
            <SvgSprite name={tag.icon} />
            {tag.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SearchTags;

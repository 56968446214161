import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { getAddDirectories, getDirectories } from 'store/dashboard/directory/directoryActions';
// Constants
import { DirectoryTabsEnum } from 'constants/directory';
// Components
import CustomSpinner from 'components/CustomSpinner';
import DirectorySummary from './DirectorySummary';
import DirectoryTable from './DirectoryTable';
// Styles
import classes from './DirectoryTab.module.scss';

interface Props {
  name: string;
}

const DirectoryTab: React.FC<Props> = ({ name }) => {
  const [page, setPage] = useState<number>(1);
  const { loading } = useTypeSelector(({ directory }) => directory);
  const dispatch = useDispatch();
  const user = useUser();
  const translations = useTranslations();
  const tsDirectory = translations.directory;

  const type = useMemo(() => {
    if (name === DirectoryTabsEnum.ALL) {
      return null;
    }

    if (name === DirectoryTabsEnum.CUSTOMERS) {
      return '&type=customers';
    }

    return '&type=suppliers';
  }, [name]);

  const handleMoreClicked = useCallback(() => {
    if (!user?.currentOrganisation?.id) {
      return;
    }

    setPage((prev) => prev + 1);
    dispatch(getAddDirectories(user?.currentOrganisation?.id, page, type, tsDirectory.time));
  }, [dispatch, page, tsDirectory.time, user?.currentOrganisation?.id, type]);

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(getDirectories(user?.currentOrganisation?.id, 0, type, tsDirectory.time));
    }
  }, [dispatch, page, tsDirectory.time, tsDirectory.time.MM, user?.currentOrganisation?.id, type]);

  return (
    <div className={classes.directory_tab}>
      {loading === 'directories' ? (
        <div className={classes.loading}>
          <CustomSpinner variant="two" />
        </div>
      ) : (
        <>
          <DirectorySummary />
          <DirectoryTable
            handleMoreDirectories={handleMoreClicked}
            pages={page}
          />
        </>
      )}
    </div>

  );
};

export default DirectoryTab;

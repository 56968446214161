import React from 'react';
import {
  Stripe,
  StripeError,
  PaymentRequest,
} from '@stripe/stripe-js';
import {
  afterpayClearpaySupportCountries,
  afterpayClearpaySupportCurrencies,
  euCountries,
  klarnaSupportCountries,
  klarnaSupportCurrencies,
  klarnaSupportCustomerCountries,
  PayMethodsEnum,
  supportSofort,
} from 'constants/paymentsMethods';
import { LoadingEnum } from 'store/storefront/checkout/checkoutTypes';
import { NotificationEnum } from 'store/storefront/shop/shopTypes';
import { alipay } from './PaymentMethods/AliPay';
import { grabPay } from './PaymentMethods/GrabPay';
import { googleApplePay } from './PaymentMethods/GoogleApplePay';

export interface IPaymentMethods {
  name: PayMethodsEnum;
  isStripe: boolean;
  logos: string[];
  show: boolean;
}

export class PaymentFunctions {
  static async handlePay(
    handleLoading: (loading: LoadingEnum | null) => void,
    handleError: (error: StripeError | undefined | unknown) => void,
    handleSuccess: () => void,
    handleNotificationError: (typeNotification: NotificationEnum) => void,
    stripe: Stripe,
    clientSecret: string,
    setPaymentRequest: (value: React.SetStateAction<PaymentRequest | null>) => void,
    fullName: string,
    countryIso: string,
    currencyIso: string,
    label: string,
    totalPrice: number,
    type?: string | null,
  ): Promise<void> {
    switch (type) {
      case (PayMethodsEnum.ALIPAY): {
        handleLoading(LoadingEnum.SET_ORDER);
        const error = await alipay(clientSecret, stripe);
        handleError(error);
        break;
      }
      case (PayMethodsEnum.GRABPAY): {
        const { error } = await grabPay(
          clientSecret,
          stripe,
        );
        handleError(error);
        break;
      }
      case (PayMethodsEnum.GOOGLE):
      case (PayMethodsEnum.APPLE): {
        const pr = stripe.paymentRequest({
          country: countryIso,
          currency: currencyIso.toLowerCase(),
          total: {
            label,
            amount: totalPrice,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
        pr.canMakePayment().then((result) => {
          if (result) {
            setPaymentRequest(pr);
          } else {
            handleNotificationError(NotificationEnum.ERROR_NOT_WALLET);
          }
        });

        pr.on('paymentmethod', async (event) => {
          if (!clientSecret) {
            return;
          }

          try {
            const { error, paymentIntent } = await googleApplePay(
              clientSecret,
              stripe,
              event.paymentMethod.id,
            );

            if (error) {
              handleError(error.message);

              event.complete('fail');
            } else if (paymentIntent && paymentIntent.status === 'succeeded') {
              event.complete('success');
              handleSuccess();
            }
          } catch (error) {
            event.complete('fail');
            handleError('Server Error');
          }
        });
        break;
      }
      default:
        break;
    }
  }

  static expressPayments(
    isApple: boolean,
    paymentsMethods: IPaymentMethods[],
    currencyIso?: string,
    countryIso?: string,
  ): IPaymentMethods[] {
    let filteredPaymentsMethods = paymentsMethods;
    if (!isApple) {
      filteredPaymentsMethods = filteredPaymentsMethods.filter(
        (method) => method.name !== PayMethodsEnum.APPLE,
      );
    } else {
      filteredPaymentsMethods = filteredPaymentsMethods.filter(
        (method) => method.name !== PayMethodsEnum.GOOGLE,
      );
    }

    if (countryIso !== 'MY' && countryIso !== 'SG') {
      filteredPaymentsMethods = filteredPaymentsMethods.filter(
        (method) => method.name !== PayMethodsEnum.GRABPAY,
      );
    }

    if (countryIso !== 'CN' || currencyIso !== 'EUR') {
      filteredPaymentsMethods = filteredPaymentsMethods.filter(
        (method) => method.name !== PayMethodsEnum.ALIPAY,
      );
    }

    return filteredPaymentsMethods;
  }

  static bankPayments(
    paymentsMethods: IPaymentMethods[],
    countryIso: string,
    currencyIso?: string,
  ): IPaymentMethods[] {
    const banks: IPaymentMethods[] = [];
    if (countryIso === 'DE' && currencyIso === 'EUR') {
      banks.push(...paymentsMethods.filter(
        (method) => method.name === PayMethodsEnum.GIROPAY,
      ));
    }

    if (supportSofort.includes(countryIso) && currencyIso === 'EUR') {
      banks.push(...paymentsMethods.filter(
        (method) => method.name === PayMethodsEnum.SOFORT,
      ));
    }

    if (countryIso === 'PL' && currencyIso === 'PLN') {
      banks.push(...paymentsMethods.filter(
        (method) => method.name === PayMethodsEnum.BLIK,
      ));
    }

    if (countryIso === 'BE' && currencyIso === 'EUR') {
      banks.push(...paymentsMethods.filter(
        (method) => method.name === PayMethodsEnum.BANCONTACT,
      ));
    }

    if (countryIso === 'AT' && currencyIso === 'EUR') {
      banks.push(...paymentsMethods.filter(
        (method) => method.name === PayMethodsEnum.EPS,
      ));
    }

    if (countryIso === 'PL' && (currencyIso === 'EUR' || currencyIso === 'PLN')) {
      banks.push(...paymentsMethods.filter(
        (method) => method.name === PayMethodsEnum.P24,
      ));
    }

    if (euCountries.includes(countryIso) && currencyIso === 'EUR') {
      banks.push(...paymentsMethods.filter(
        (method) => method.name === PayMethodsEnum.SEPA,
      ));
    }

    return banks;
  }

  static payLaterPayments(
    paymentsMethods: IPaymentMethods[],
    countryIso: string,
    currencyIso?: string,
    countryCustomerIso?: string,
  ): IPaymentMethods[] {
    const payLaterMethods: IPaymentMethods[] = [];
    if (klarnaSupportCountries.includes(countryIso)
      && klarnaSupportCurrencies.includes(currencyIso || '')
      && klarnaSupportCustomerCountries.includes(countryCustomerIso || '')
    ) {
      payLaterMethods.push(...paymentsMethods.filter(
        (method) => method.name === PayMethodsEnum.KLARNA,
      ));
    }

    if (afterpayClearpaySupportCountries.includes(countryIso)
      && afterpayClearpaySupportCurrencies.includes(currencyIso || '')
      && countryCustomerIso === countryIso
    ) {
      payLaterMethods.push(...paymentsMethods.filter(
        (method) => method.name === PayMethodsEnum.AFTERPAY_CLEARPAY,
      ));
    }

    payLaterMethods.push(...paymentsMethods.filter(
      (method) => method.name === PayMethodsEnum.PAY_LATER,
    ));

    return payLaterMethods;
  }
}

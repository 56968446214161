import React from 'react';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Components
import Contacts from './Contacts';
import OverviewSection from './OverviewSection';
import OrderDetailsFooter from './OrderDetailsFooter';
import NotificationsOrderDetails from './NotificationsOrderDetails';
// Styles
import classes from './OverviewOrderDetails.module.scss';

const OverviewOrderDetails: React.FC = () => {
  const { isMobile } = useMobileScreen();

  return (
    <div className={!isMobile ? classes.overview : classes.overview_mobile}>
      <Contacts />
      <OverviewSection />
      <OrderDetailsFooter />
      <NotificationsOrderDetails />
    </div>
  );
};

export default OverviewOrderDetails;

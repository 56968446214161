/* eslint-disable no-console */
import { ApiOrgId } from 'services/api';
import { STRAPI } from 'services/strapi';
import { AppThunk } from 'store';
import { TranslationDashboardModel, TranslationsType } from 'types/TranslationDashboardModel';
import {
  APP_SET_MENULINKS,
  APP_SET_MOBILE_MENU_OPEN,
  APP_SET_NOTIFICATION,
  AppActions,
  MenuLinks,
  APP_SET_TRANSLATIONS,
  APP_SET_TRANSLATIONS_FETCHED,
  APP_SET_IS_MOB_REQUIRED,
  APP_SET_CURRENT_LANGUAGE,
  APP_SET_ACTIVE_TRANSLATION,
  APP_SET_PREV_NAV_LINK,
  APP_SET_PREV_TRANSACTION,
  APP_SET_PREV_ORDER,
  APP_SET_PREV_DIRECTORY_TAB,
  SearchResult,
  APP_SET_SEARCH_RESULT,
  SearchTypes,
  SearchResultDocuments,
  APP_SET_SEARCH_LOADING,
  APP_SET_ADD_SEARCH_RESULT,
  APP_SET_HAS_NEXT_PAGE,
} from './appTypes';

export const setMenuLinksAction = (payload: MenuLinks[]): AppActions => ({
  type: APP_SET_MENULINKS,
  payload,
});

export const SetPrevNavLink = (payload: string): AppActions => ({
  type: APP_SET_PREV_NAV_LINK,
  payload,
});

export const SetPrevTransaction = (payload: string): AppActions => ({
  type: APP_SET_PREV_TRANSACTION,
  payload,
});

export const SetPrevOrder = (payload: string): AppActions => ({
  type: APP_SET_PREV_ORDER,
  payload,
});

export const SetPrevDirectoryTab = (payload: string): AppActions => ({
  type: APP_SET_PREV_DIRECTORY_TAB,
  payload,
});

export const setMobileMenuOpenAction = (payload: boolean): AppActions => ({
  type: APP_SET_MOBILE_MENU_OPEN,
  payload,
});

export const setNotificationAction = (payload: string | null): AppActions => ({
  type: APP_SET_NOTIFICATION,
  payload,
});

export const setMobAppRequiredAction = (payload: boolean): AppActions => ({
  type: APP_SET_IS_MOB_REQUIRED,
  payload,
});

export const setCurrentLanguage = (payload: string): AppActions => ({
  type: APP_SET_CURRENT_LANGUAGE,
  payload,
});

export const setActiveTranslations = (payload: TranslationDashboardModel): AppActions => ({
  type: APP_SET_ACTIVE_TRANSLATION,
  payload,
});

export const setSearchResult = (payload: SearchResult[]): AppActions => ({
  type: APP_SET_SEARCH_RESULT,
  payload,
});

export const setAddSearchResult = (payload: SearchResult[]): AppActions => ({
  type: APP_SET_ADD_SEARCH_RESULT,
  payload,
});

export const searchLoadingAction = (payload: string): AppActions => ({
  type: APP_SET_SEARCH_LOADING,
  payload,
});

export const setHasNextPage = (payload: boolean): AppActions => ({
  type: APP_SET_HAS_NEXT_PAGE,
  payload,
});

export const getUpdatedMenuLinks = (
  menuLinks: MenuLinks[],
  productsLength: number,
  categoriesLength: number,
): MenuLinks[] => {
  const newLinks = menuLinks.map((link) => {
    if (typeof link.sublinks === 'undefined') {
      return link;
    }

    return {
      ...link,
      sublinks: link.sublinks.map((sublink) => {
        if (sublink.title.match('Items')) {
          return {
            ...sublink,
            title: `Items (${productsLength})`,
          };
        }

        if (sublink.title.match('Categories')) {
          return {
            ...sublink,
            title: `Categories (${categoriesLength})`,
          };
        }

        return sublink;
      }),
    };
  });

  return newLinks;
};

const setTranslationsFetchedAction = (payload: boolean): AppActions => ({
  type: APP_SET_TRANSLATIONS_FETCHED,
  payload,
});

const setTranslationsAction = (payload: TranslationsType): AppActions => ({
  type: APP_SET_TRANSLATIONS,
  payload,
});

export const getTranslations = (
  translations: keyof TranslationsType,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await STRAPI.get<{ id: number, translations: TranslationsType }[]>('/Homes?id=20');
    // eslint-disable-next-line dot-notation
    if (typeof data[0].translations !== 'undefined' && typeof data[0].translations.en !== 'undefined') {
      dispatch(setTranslationsFetchedAction(true));
      dispatch(setTranslationsAction({ ...data[0].translations }));
      dispatch(setCurrentLanguage(translations));
      dispatch(setActiveTranslations(data[0].translations[translations]));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSearchResult = (
  search: string,
  type: SearchTypes,
  organisationId: string,
  page?: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(searchLoadingAction(page ? 'LoadingMoreItems' : 'LoadingItems'));
    let data: SearchResultDocuments[] = [];
    if (type === SearchTypes.documents) {
      data = await ApiOrgId(`${type}?search=${search}&page=${page || 0}`, organisationId);
    }

    if (type === SearchTypes['sales-orders']) {
      data = await ApiOrgId(`${type}?search=${search}&page=${page || 0}`, organisationId);
    }

    if (type === SearchTypes.contacts) {
      data = await ApiOrgId(`${type}?search=${search}&page=${page || 0}`, organisationId);
    }

    if (type === SearchTypes.products) {
      data = await ApiOrgId(`${type}?search=${search}&page=${page || 0}`, organisationId);
    }

    if (page) {
      dispatch(setAddSearchResult(
        data.map(
          (item) => ({
            id: String(item.entryid || item.id || item.contactid),
            firstName: item.firstname || item.brand,
            lastName: item.lastname || item.name,
            type: item.entrytype,
          }),
        ),
      ));
    } else {
      dispatch(setSearchResult(data.map(
        (item) => ({
          id: String(item.entryid || item.id || item.contactid),
          firstName: item.firstname || item.brand,
          lastName: item.lastname || item.name,
          type: item.entrytype,
        }),
      )));
    }

    dispatch(searchLoadingAction(''));
    dispatch(setHasNextPage(false));
    if (data.length >= 10) {
      dispatch(setHasNextPage(true));
    }
  } catch (err) {
    console.log(err);
    dispatch(searchLoadingAction(''));
  }
};

// Fallback translations
import en from 'translations/dashboard/en.json';
import {
  AUTH_SET_USER,
  AUTH_SET_TYPE,
  AUTH_SET_LOADING,
  AUTH_SET_ERROR,
  AUTH_SET_LOGIN,
  AUTH_SET_SUBSCRIPTION,
  AUTH_SET_CHECK_LOGIN,
  AUTH_SET_CURRENT_ORGANISATION,
  AuthState,
  AuthActions,
  APP_SET_ACTIVE_TRANSLATION,
  APP_SET_CURRENT_LANGUAGE,
  APP_SET_TRANSLATIONS_FETCHED,
  APP_SET_SIGN_OUT,
} from './authTypes';

const initialState: AuthState = {
  user: null,
  subscription: null,
  authType: null,
  loading: false,
  error: null,
  isLogin: false,
  isLoginCheck: true,
  isActivate: true,
  translations: null,
  isTranslationsFetched: false,
  currentLanguage: 'en',
  activeTranslation: en,
  signOut: false,
};

export const authReducer = (
  state: AuthState = initialState,
  action: AuthActions,
): AuthState => {
  switch (action.type) {
    case AUTH_SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case AUTH_SET_TYPE:
      return {
        ...state,
        authType: action.payload,
      };
    case AUTH_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AUTH_SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case AUTH_SET_LOGIN:
      return {
        ...state,
        isLogin: action.payload,
      };
    case AUTH_SET_CHECK_LOGIN:
      return {
        ...state,
        isLoginCheck: action.payload,
      };
    case AUTH_SET_CURRENT_ORGANISATION:
      return {
        ...state,
        user: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.user!,
          currentOrganisation: action.payload,
        },
      };
    case APP_SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case APP_SET_ACTIVE_TRANSLATION:
      return {
        ...state,
        activeTranslation: action.payload,
      };
    case APP_SET_TRANSLATIONS_FETCHED:
      return {
        ...state,
        isTranslationsFetched: action.payload,
      };
    case AUTH_SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case APP_SET_SIGN_OUT:
      return {
        ...state,
        signOut: action.payload,
      };
    default:
      return state;
  }
};

import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import ShareShopPopup from './ShareShopPopup';
// classes
import classes from './ShareShop.module.scss';

const ShareShop: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [openPopup, setOpenPopup] = useState(false);
  const { share_shop } = useTranslationsStorefront();

  const handleShare = useCallback(
    () => {
      setOpenPopup((prev) => !prev);
    },
    [],
  );

  return (
    <div className={classes.share__shop_container}>
      <h3>
        {share_shop.scan_shop_buy}
      </h3>
      <div className={classes.share__shop}>
        {id && (<QRCode value={id} size={44} level="Q" />)}
        <div className={classes.share__shop_text}>
          <p>{share_shop.or}</p>
          <button className={classes.share__shop_btn} type="button" onClick={handleShare}>
            {share_shop.share_shop_link}
          </button>
        </div>
      </div>
      <ShareShopPopup
        close={handleShare}
        active={openPopup}
      />
    </div>
  );
};

export default ShareShop;

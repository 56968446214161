import { StylesConfig } from 'react-select';

type MyOptionType = {
  label: string;
  value: string;
};

type IsMulti = false;

export const customStyles: StylesConfig<MyOptionType, IsMulti> = {
  option: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: 'rgba(25, 28, 35, 1)',
    textTransform: 'uppercase',
    '&:hover': {
      opacity: '0.5',
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
    height: '30px',
    backgroundColor: 'transparent',
    '&:hover': {
      outline: 'none',
      opacity: '0.5',
    },
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 0,
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    outline: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#FFF',
    width: '360px',
    padding: '30px 20px',
    transition: '0.3s all',
  }),
  multiValue: (provided) => ({
    ...provided,
    '&:hover': {
      opacity: '0.5',
    },
  }),
};

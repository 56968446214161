import React from 'react';
import cn from 'classnames';
// Assets
import { ReactComponent as EyeIcon } from 'assets/icons/eye/eye.svg';
import { ReactComponent as EyeOffIcon } from 'assets/icons/eye/eye-off.svg';
// Classes
import classes from './EyeButton.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
  disabled: boolean;
}

const EyeButton: React.FC<Props> = ({ active, setActive, disabled }) => (
  <button
    type="button"
    onClick={setActive}
    disabled={disabled}
    className={cn(classes.eye_btn, {
      [classes.eye_btn_active]: active,
    })}
  >
    {active ? (<EyeIcon />) : (<EyeOffIcon />)}
  </button>
);

export default EyeButton;

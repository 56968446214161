import React, { useState } from 'react';
import { QuotePriceType, QuoteTermsPaymentType } from 'store/quote/quoteTypes';
import { Items } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import ModalAddDiscount from 'pages/Documents/Quote/ModalAddDiscount';
import Item from './Item';
import Price from './Price';
// Styles
import classes from './SideBar.module.scss';

interface Props {
  items: Items[];
  price: QuotePriceType;
  translations: TranslationDashboardModel['sidebar_transaction']
  termsPayment: QuoteTermsPaymentType
}

const SideBar: React.FC<Props> = ({
  items, price, translations, termsPayment,
}) => {
  const [addDiscount, setAddDiscount] = useState<boolean>(false);
  const handleAddDiscount = () => {
    setAddDiscount((show) => !show);
  };

  return (
    <div className={classes.sidebar}>
      <h2 className={classes.sidebar_title}>
        {translations.items}
      </h2>
      <div className={classes.sidebar_items}>
        {items.map((item) => (
          <Item
            key={item.id}
            data={item}
            currencyIso={price.currencyIso}
            translations={translations.transaction_item}
            termsPayment={termsPayment}
          />
        ))}
      </div>

      <Price
        price={price}
        items={items}
        handleAddDiscount={handleAddDiscount}
        translations={translations.price}
        termsPayment={termsPayment}
      />
      {addDiscount && (
        <ModalAddDiscount
          price={price}
          handleClose={handleAddDiscount}
        />
      )}
    </div>
  );
};

export default SideBar;

import React, { memo } from 'react';
// Styles
import classes from './NoItems.module.scss';

interface Props {
  translation: {
    label: string;
    btn_text: string;
  };
}

const NoItems: React.FC<Props> = ({ translation }) => (
  <div className={classes.no_items}>
    <h4>
      {translation.label}
    </h4>
  </div>
);

export default memo(NoItems);

import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
// Types
import { CartItemType } from 'store/storefront/shop/shopTypes';
// Utils
import { CartFunctions } from 'utils/storefront/CartFunctions';
// Assets
import { ReactComponent as ShoppingIcon } from 'assets/storefront-icons/bag.svg';
// Styles
import classes from './Bag.module.scss';

interface Props {
  formatCurrencyByISO: (valueRaw: string | number | undefined) => string;
  bag: CartItemType[];
}

const Bag: React.FC<Props> = ({ formatCurrencyByISO, bag }) => {
  const { goToCart } = useStorefrontRoutes();
  const { id }: { id: string } = useParams();
  const location = useLocation();
  const handleToBag = () => {
    if (id) {
      goToCart(id, location);
    }
  };

  const price = formatCurrencyByISO(CartFunctions.calculateTotalPrice(bag));

  return (
    <button type="button" className={bag.length ? classes.bag_active : classes.bag} onClick={handleToBag} disabled={!bag.length}>
      <ShoppingIcon />
      <p className={classes.bag_value}>
        {price}
      </p>
    </button>
  );
};

export default Bag;

import React from 'react';
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
// Types
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Components
import Item from './Item';
// Styles
import classes from './SideBar.module.scss';

interface Props {
  product: ProductItem;
  translations: TranslationDashboardModel['sidebar_transaction']
  openDeleteConfirm: () => void;
}

const SideBar: React.FC<Props> = ({ product, translations, openDeleteConfirm }) => (
  <div className={classes.sidebar}>
    <h2 className={classes.sidebar_title}>
      {translations.overview_text}
    </h2>
    <div className={classes.sidebar_items}>
      <Item
        data={product}
        translations={translations.catalog_item}
        openDeleteConfirm={openDeleteConfirm}
      />
    </div>
  </div>
);

export default SideBar;

import React, { useEffect } from 'react';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Utils
import { getDayName, getWeekTradingHours } from 'utils/dates';
// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';
//  Styles
import classes from './MoreInfoModal.module.scss';

interface Props {
  active: boolean;
  handleModal: () => void;
}

const MoreInfoModal: React.FC<Props> = ({ active, handleModal }) => {
  const showHideClassName = active ? classes.modal_show : classes.modal_hide;
  const { fulfillment } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const { checkout } = useTranslationsStorefront();

  const tradingHours = currentSalesChannel?.fulfilment?.pickup?.locations?.find(
    (loc) => loc.address === fulfillment?.location?.address,
  )?.tradingHours;

  const weekTradingHours = getWeekTradingHours(tradingHours);

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [active]);

  return (
    <div
      className={showHideClassName}
    >
      <div
        className={active ? classes.modal_wrapper_active : classes.modal_wrapper}
        tabIndex={0}
        role="button"
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.root}>
          <div className={classes.header}>
            <CloseIcon
              role="button"
              className={classes.close_icon}
              onClick={handleModal}
            />
            <LeftArrowIcon
              role="button"
              className={classes.left_arrow_icon}
              onClick={handleModal}
            />
          </div>
          <div className={classes.title}>
            {fulfillment.location?.name}
          </div>
          <div className={classes.address}>
            <p className={classes.address_title}>
              {checkout.more_info_modal.address_text}
            </p>
            <p className={classes.address_value}>
              {fulfillment.location?.address}
            </p>
          </div>
          <div className={classes.operation_hours}>
            <div className={classes.operation_hours_title}>
              {checkout.more_info_modal.operating_hours_text}
            </div>
            <div className={classes.operation_hours_values}>
              {weekTradingHours?.map((item) => (
                <div className={classes.operation_hours_values_value} key={item.day}>
                  <span>
                    {getDayName(item.day)}
                  </span>
                  {item.closed ? (
                    <span>
                      {checkout.more_info_modal.closed_text}
                    </span>
                  ) : (
                    <span>
                      {item.from} - {item.to}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreInfoModal;

import { PaymentIntentResult, Stripe, StripeP24BankElement } from '@stripe/stripe-js';

export const p24Pay = async (
  clientSecret: string,
  stripe: Stripe,
  email: string,
  bank: StripeP24BankElement,
  name: string,
  returnUrl: string,
): Promise<PaymentIntentResult> => {
  const paymentIntentResult = await stripe.confirmP24Payment(clientSecret, {
    payment_method: {
      p24: bank,
      billing_details: {
        name,
        email,
      },
    },
    payment_method_options: {
      p24: {
        tos_shown_and_accepted: true,
      },
    },
    return_url: returnUrl,
  });
  return paymentIntentResult;
};

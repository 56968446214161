import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useUser } from 'hooks/useUser';
import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslations } from 'hooks/useTranslations';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Actions
import { getOrderDetails } from 'store/dashboard/orders/orderActions';
// Components
import OverviewOrderDetails from 'pages/Dashboard/Orders/OrdersDetailsPage/OverviewOrderDetails';
import DetailsPageHeader from 'components/DashboardComponents/DetailsPageHeader';
import LoadingPage from 'pages/LoadingPage';
import { getLocations } from 'store/dashboard/catalog/items/itemsActions';
import Accordion from 'components/Accordion';
import SideBarOrderDetails from './SideBar';
import Price from './SideBar/Price';
// Classes
import classes from './OrderDetailsPage.module.scss';

const OrderDetailsPage: React.FC = () => {
  const { loading, orderDetails } = useTypeSelector(({ order }) => order);
  const translations = useTranslations();
  const { isMobile } = useMobileScreen();
  const { goBack } = useDashboardRoutes();

  const transactionTs = translations.transactions;
  const dispatch = useDispatch();
  const user = useUser();
  const params: { orderId?: string } = useParams();

  useEffect(() => {
    if (user?.currentOrganisation?.id && params.orderId) {
      dispatch(getOrderDetails(params.orderId, user.currentOrganisation.id));
      dispatch(getLocations(user.currentOrganisation.id));
    }
  }, [dispatch, user, params]);

  if (!orderDetails) {
    return <LoadingPage />;
  }

  return (
    <div className={classes.root}>
      {loading === 'process' && (
        <LoadingPage
          title={translations.orders.loading_text}
          backgroundColor="rgba(0, 0, 0, 0.8)"
        />
      )}
      {loading === 'cancel' && (
        <LoadingPage
          title={translations.orders.loading_cancel_text}
          backgroundColor="rgba(0, 0, 0, 0.8)"
        />
      )}
      {loading === 'reminder' && (
        <LoadingPage
          title={transactionTs.title_loading_reminder}
          backgroundColor="rgba(0, 0, 0, 0.8)"
        />
      )}
      <DetailsPageHeader
        goBack={goBack}
        title={translations.orders.details.title}
      />
      <div className={classes.wrapper}>
        <OverviewOrderDetails />
        {isMobile ? (
          <>
            <Accordion title={transactionTs.items} className={classes.accordion_sidebar}>
              <SideBarOrderDetails />
            </Accordion>
            <Price
              price={orderDetails.price}
              items={orderDetails.items}
              translations={translations.sidebar_transaction.price}
            />
          </>
        ) : (
          <SideBarOrderDetails />
        )}
      </div>

    </div>

  );
};

export default OrderDetailsPage;

import { CartFunctions } from 'utils/storefront/CartFunctions';
import {
  SHOP_SET,
  SHOP_SET_PRODUCTS,
  SHOP_SET_CATEGORIES,
  SHOP_ADD_TO_BAG,
  SHOP_SET_TO_SEARCH,
  SHOP_SET_SEARCH_KEYWORD,
  SHOP_SET_SEARCH_LOADING,
  SHOP_SET_SEARCH_DATA,
  SHOP_SET_LOADING,
  SHOP_SET_ERROR,
  ShopState,
  ShopActions,
  SHOP_SET_FILTER,
  SHOP_GET_FILTER,
  SHOP_CLEAN_FILTER,
  ProductSort,
  SHOP_SET_SORT,
  SHOP_SET_TO_FILTER,
  SHOP_SET_TO_PRODUCT_DETAILS,
  SHOP_SET_SALES_CHANNEL,
  SHOP_SET_TO_CONFIRM,
  SHOP_REMOVE_PRODUCT,
  SHOP_SET_PARAMS,
  SHOP_SET_CURRENT_SALES_CHANNEL,
  SHOP_SET_PRODUCT,
} from './shopTypes';

const initialState: ShopState = {
  shop: {},
  currentSalesChannel: null,
  productDetails: null,
  salesChannels: null,
  params: [],
  categories: {
    data: [],
    loading: false,
    error: false,
  },
  products: {
    data: [],
    loading: false,
    error: false,
  },
  search: {
    data: [],
    keyword: '',
    isOpen: false,
    loading: false,
  },
  bag: [],
  filters: [
    {
      name: 'available',
      value: false,
    },
  ],
  isFilter: false,
  sort: ProductSort.LATEST,
  details: {
    show: false,
    productId: '',
  },
  confirm: {
    show: false,
  },
  loading: true,
  error: false,
};

export const shopReducer = (
  state: ShopState = initialState,
  action: ShopActions,
): ShopState => {
  switch (action.type) {
    case SHOP_SET:
      return {
        ...state,
        shop: action.payload,
      };
    case SHOP_SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SHOP_SET_PRODUCTS: {
      if (action.payload.loading) {
        return {
          ...state,
          products: {
            ...state.products,
            page: action.payload.page,
            loading: action.payload.loading,
          },
        };
      }

      if (action.payload.page === '0') {
        return {
          ...state,
          products: action.payload,
        };
      }

      if (state?.products?.data?.find(
        (product) => product?.id === action?.payload?.data[0]?.id,
      )) {
        return {
          ...state,
          products: action.payload,
        };
      }
      return {
        ...state,
        products: {
          ...action.payload,
          data: [...state.products.data, ...action.payload.data],
        },
      };
    }
    case SHOP_ADD_TO_BAG:
      return {
        ...state,
        bag: action.payload,
      };
    case SHOP_SET_TO_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          isOpen: action.payload,
        },
      };
    case SHOP_SET_SEARCH_KEYWORD:
      return {
        ...state,
        search: {
          ...state.search,
          keyword: action.payload,
        },
      };
    case SHOP_SET_SEARCH_LOADING:
      return {
        ...state,
        search: {
          ...state.search,
          loading: action.payload,
        },
      };
    case SHOP_SET_SEARCH_DATA:
      return {
        ...state,
        search: {
          ...state.search,
          data: action.payload,
        },
      };
    case SHOP_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SHOP_SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SHOP_SET_FILTER: {
      const changeCheck = state.filters.map(
        (currFilter) => (currFilter.name === action.payload.name
          ? { ...currFilter, value: action.payload.value } : currFilter),
      );
      return {
        ...state,
        filters: changeCheck,
      };
    }
    case SHOP_GET_FILTER: {
      const checkDisabled = state.filters.map(
        (currFilter) => (!action.payload.includes(currFilter.name) && currFilter.name !== 'include'
          ? { ...currFilter, disabled: true } : currFilter),
      );
      return {
        ...state,
        filters: checkDisabled,
      };
    }
    case SHOP_CLEAN_FILTER:
      return {
        ...state,
        filters: state.filters.map((curFilter) => ({
          ...curFilter,
          value: false,
        })),
      };
    case SHOP_SET_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case SHOP_SET_TO_FILTER:
      return {
        ...state,
        isFilter: action.payload,
      };
    case SHOP_SET_SALES_CHANNEL:
      return {
        ...state,
        salesChannels: action.payload,
      };
    case SHOP_SET_TO_PRODUCT_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case SHOP_SET_TO_CONFIRM:
      return {
        ...state,
        confirm: action.payload,
      };
    case SHOP_REMOVE_PRODUCT:
      return {
        ...state,
        bag: CartFunctions.RemoveInCart(state.bag, action.payload),
      };
    case SHOP_SET_PARAMS:
      return {
        ...state,
        params: state?.params?.map((param) => {
          if (param.name === action.payload.name) {
            return {
              name: param.name,
              value: action.payload.value,
            };
          }
          return action.payload;
        }),
      };
    case SHOP_SET_CURRENT_SALES_CHANNEL:
      return {
        ...state,
        currentSalesChannel: action.payload,
      };
    case SHOP_SET_PRODUCT:
      return {
        ...state,
        productDetails: action.payload,
      };
    default:
      return state;
  }
};

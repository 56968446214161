/* eslint-disable no-console */
import { API, ApiOrgId } from 'services/api';
import { AppThunk } from 'store';
import { TransactionType } from 'types/globalTypes';
import { nanoid } from 'nanoid';
import { calcTaxes, checkStatusExpired, getStatus } from 'utils/functions';
import { calcSubtotal, calcTotalFees } from 'utils/price';
import {
  TRANSACTION_DOCUMENTS_GET_DATA,
  TRANSACTION_SET_DETAIL_ITEM,
  TransactionActions,
  TransactionTypes,
  TRANSACTION_GET_DATA,
  TRANSACTION_LOADING,
  TRANSACTION_MORE_PAGE,
  TRANSACTION_DOCUMENTS_MORE_GET_DATA,
  TRANSACTION_APP_SET_NOTIFICATION_TRANSACTION,
  ResTransaction,
  ResTransactionDetails,
  TRANSACTION_RECORD_PAYMENT_LOADING,
  TRANSACTION_RECORD_PAYMENT_SUCCESS,
  ReminderData,
  TRANSACTION_REMINDER_SUCCESS,
  TRANSACTION_REMINDER_LOADING,
  TRANSACTION_ACCEPT_SUCCESS,
  TRANSACTION_ACCEPT_LOADING,
} from './transactionTypes';

export const SetDetailItem = (payload: TransactionType): TransactionActions => ({
  type: TRANSACTION_SET_DETAIL_ITEM,
  payload,
});

export const TransactionLoadingAction = (payload: boolean): TransactionActions => ({
  type: TRANSACTION_LOADING,
  payload,
});

export const TransactionLoadingRecordPaymentAction = (payload: boolean): TransactionActions => ({
  type: TRANSACTION_RECORD_PAYMENT_LOADING,
  payload,
});

export const TransactionRecordPaymentIsSuccessAction = (
  payload: number | null,
): TransactionActions => ({
  type: TRANSACTION_RECORD_PAYMENT_SUCCESS,
  payload,
});

export const TransactionLoadingReminderAction = (payload: boolean): TransactionActions => ({
  type: TRANSACTION_REMINDER_LOADING,
  payload,
});

export const TransactionReminderAction = (payload: boolean): TransactionActions => ({
  type: TRANSACTION_REMINDER_SUCCESS,
  payload,
});

export const TransactionLoadingAcceptAction = (payload: boolean): TransactionActions => ({
  type: TRANSACTION_ACCEPT_LOADING,
  payload,
});

export const TransactionAcceptAction = (payload: boolean): TransactionActions => ({
  type: TRANSACTION_ACCEPT_SUCCESS,
  payload,
});

export const TransactionGetDataAction = (): TransactionActions => ({
  type: TRANSACTION_GET_DATA,
});

export const DocumentsGetDataAction = (
  payload: TransactionType[],
): TransactionActions => ({
  type: TRANSACTION_DOCUMENTS_GET_DATA,
  payload,
});

export const DocumentMoreGetDataAction = (
  payload: TransactionType[],
): TransactionActions => ({
  type: TRANSACTION_DOCUMENTS_MORE_GET_DATA,
  payload,
});

export const EndPagesDataAction = (payload: boolean): TransactionActions => ({
  type: TRANSACTION_MORE_PAGE,
  payload,
});

export const setNotificationTransactionAction = (
  payload: string | null,
): TransactionActions => ({
  type: TRANSACTION_APP_SET_NOTIFICATION_TRANSACTION,
  payload,
});

export const getTransactionDataDetail = (
  organisationId: string, type: string | null, id: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(TransactionLoadingAction(true));
    let data: ResTransactionDetails | null = null;
    let url: { url: string } | null = null;
    if (type === TransactionTypes.BILL) {
      data = await ApiOrgId(`/bills/${id}?alias=details`, organisationId);
      url = await ApiOrgId(`/bills/${id}?alias=url`, organisationId);
    }
    if (type === TransactionTypes.SALES_ORDER) {
      data = await ApiOrgId(`/sales-orders/${id}?alias=details`, organisationId);
      url = await ApiOrgId(`/sales-orders/${id}?alias=url`, organisationId);
    }
    if (type === TransactionTypes.PURCHASE_ORDER) {
      data = await ApiOrgId(`/purchase-orders/${id}?alias=details`, organisationId);
      url = await ApiOrgId(`/purchase-orders/${id}?alias=url`, organisationId);
    }
    if (type === TransactionTypes.INVOICE) {
      data = await ApiOrgId(`/invoices/${id}?alias=details`, organisationId);
      url = await ApiOrgId(`/invoices/${id}?alias=url`, organisationId);
    }
    if (!data) {
      return;
    }

    const totalFees = calcTotalFees(data.fees);
    const taxes = calcTaxes(data.items);
    const subtotal = calcSubtotal(data);
    const reference = url?.url?.split('/') || [];
    const TransactionDetailsData = {
      id: data?.id,
      contactId: data?.contactId,
      date: data?.date,
      type: TransactionTypes[data?.type as keyof typeof TransactionTypes],
      amount: data.total,
      status: checkStatusExpired(data),
      name: `${data?.contact?.firstName || ''} ${data?.contact?.lastName || ''}`,
      dueDate: data.dueDate,
      items: data.items,
      expiryDate: data.expiryDate,
      externalId: data.externalId,
      payments: data.payments,
      discountAmount: data.discountAmount,
      notes: data.notes,
      currencyISO: data.currencyISO,
      reference: reference[reference?.length - 1]?.substring(0, 5) || '',
      url: url?.url || '',
      balance: data.balance,
      totalTax: data.totalTax,
      fees: data.fees,
      contact: data.contact,
      terms: data.terms,
      updatedAt: data.updatedAt,
      price: {
        currencyIso: data?.currencyISO,
        subtotal,
        discount: data.discountAmount,
        total: subtotal - data.discountAmount + totalFees + taxes.excluded,
        paymentMethod: (data?.payments?.length && data?.payments?.length !== 0 ? data?.payments[0].paymentMethod : ''),
        fees: data.fees,
      },
    };
    dispatch(SetDetailItem(TransactionDetailsData));
    dispatch(TransactionLoadingAction(false));
  } catch (error) {
    console.error(error);
  }
};

export const getTabDataTransaction = (
  organisationId: string, type: string, pageNumber: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(TransactionLoadingAction(true));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: any | null = null;
    if (type.split(' ')[0] === 'Invoices') {
      data = await ApiOrgId(
        `/invoices?page=${pageNumber}`,
        organisationId,
      );
    }
    if (type.split(' ')[0] === 'Bills') {
      data = await ApiOrgId(
        `/bills?page=${pageNumber}`,
        organisationId,
      );
    }
    if (type.split(' ')[1] === 'Bills') {
      data = await ApiOrgId(
        `/bills?page=${pageNumber}&status=${type.split(' ')[0].toUpperCase()}`,
        organisationId,
      );
    }
    if (type.split(' ')[0] === 'Estimates') {
      data = await ApiOrgId(`/sales-orders?page=${pageNumber}`, organisationId);
    }
    if (type.split(' ')[0] === 'Quotes') {
      data = await ApiOrgId(`/purchase-orders?page=${pageNumber}`, organisationId);
    }
    if (type.split(' ')[1] === 'Invoices') {
      data = await ApiOrgId(
        `/invoices?page=${pageNumber}&status=${type.split(' ')[0].toUpperCase()}`,
        organisationId,
      );
    }
    if (type.split(' ')[0] === 'All') {
      data = await ApiOrgId(`/documents?page=${pageNumber}`, organisationId);
    }

    const items: TransactionType[] = data?.map((item: ResTransaction) => ({
      uniqId: nanoid(),
      id: item.id,
      date: item.date,
      type: TransactionTypes[item.type as keyof typeof TransactionTypes],
      amount: item.total,
      status: checkStatusExpired(item),
      name: `${item?.contact?.firstName || '-'} ${item?.contact?.lastName || '-'}`,
    }));
    dispatch(EndPagesDataAction(items.length < 10));
    dispatch(DocumentMoreGetDataAction(items));
    dispatch(TransactionGetDataAction());
  } catch (error) {
    console.error(error);
  }
};

export const createInvoicePayment = (
  orgId: string,
  data: {
    amount: number,
    paymentMethod: string,
  },
  invoiceId: string,
  type: string,
): AppThunk => async (dispatch) => {
  dispatch(TransactionLoadingRecordPaymentAction(true));
  try {
    if (type === TransactionTypes.INVOICE) {
      await API.post(`invoices/${invoiceId}/payments`, data, {
        headers: {
          'leja-organisation-id': orgId,
        },
      });
    }
    if (type === TransactionTypes.BILL) {
      await API.post(`bills/${invoiceId}/payments`, data, {
        headers: {
          'leja-organisation-id': orgId,
        },
      });
    }
    dispatch(TransactionRecordPaymentIsSuccessAction(data.amount));
    dispatch(TransactionLoadingRecordPaymentAction(false));
    dispatch(getTransactionDataDetail(orgId, type, invoiceId));
  } catch (error) {
    dispatch(TransactionLoadingRecordPaymentAction(false));
    console.error(error);
  }
};

export const createReminder = (
  orgId: string,
  data: ReminderData,
  orgExternalId?: string,
): AppThunk => async (dispatch) => {
  dispatch(TransactionLoadingReminderAction(true));
  try {
    await API.post('/reminders', data, {
      headers: {
        'Content-Type': 'application/json',
        'leja-organisation-id': orgId,
        'leja-organisation-external-id': orgExternalId,
      },
    });
    dispatch(TransactionReminderAction(true));
    dispatch(TransactionLoadingReminderAction(false));
  } catch (error) {
    dispatch(TransactionLoadingReminderAction(false));
    console.error(error);
  }
};

export const acceptQuoteAction = (
  id: string,
  transaction: TransactionType,
  orgId?: string,
): AppThunk => async (dispatch) => {
  dispatch(TransactionLoadingAcceptAction(true));
  try {
    const { data } = await API.patch(`/purchase-orders/${id}`, { status: 'ACCEPTED' }, {
      headers: {
        'leja-organisation-id': orgId,
      },
    });
    dispatch(SetDetailItem({
      ...transaction,
      status: getStatus(data.statusId),
    }));
    dispatch(TransactionAcceptAction(true));
    dispatch(TransactionLoadingAcceptAction(false));
  } catch (error) {
    console.error(error);
    dispatch(TransactionLoadingAcceptAction(false));
  }
};

import {
  OrderState,
  OrderActions,
  ORDER_SET_LOADING,
  ORDER_SET_DATA,
  ORDER_SET_PAYMENT,
} from './orderTypes';

const initialState: OrderState = {
  loading: false,
  data: undefined,
  payment: {
    loading: false,
    error: undefined,
    success: false,
    pay: false,
  },
};

export const orderReducer = (
  state: OrderState = initialState,
  action: OrderActions,
): OrderState => {
  switch (action.type) {
    case ORDER_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ORDER_SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case ORDER_SET_PAYMENT:
      return {
        ...state,
        payment: action.payload,
      };
    default:
      return state;
  }
};

import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import {
  ContactType,
  Items,
  IOrganisationData,
  Fees,
  TransactionStatus,
} from 'types/globalTypes';

// Action Types
export const QUOTE_LOADING = 'QUOTE_LOADING';
export const QUOTE_GET_DATA = 'QUOTE_GET_DATA';
export const QUOTE_LOADING_SENT = 'QUOTE_LOADING_SENT';
export const QUOTE_SEND_ITEMS_MODAL = 'QUOTE_SEND_ITEMS_MODAL';
export const QUOTE_SEND_ITEMS = 'QUOTE_SEND_ITEMS';
export const QUOTE_SET_ITEM = 'QUOTE_SET_ITEM';
export const QUOTE_SET_DISCOUNT = 'QUOTE_SET_DISCOUNT';
export const QUOTE_SET_FEE = 'QUOTE_SET_FEE';

// Reducer type
export interface QuoteState {
  loading: boolean;
  data: QuoteData | null;
  sent: boolean;
  loadingSent: boolean;
  buttonActive: boolean;
}

export interface QuoteData {
  price: QuotePriceType,
  organisation: IOrganisationData
  contact: ContactType
  details: QuoteDetailsType
  items: Items[]
  itemsQuantity?: number
  includeTax?: boolean
  termsPayment: QuoteTermsPaymentType
  status?: string
}

export type QuotePriceType = {
  subtotal?: number;
  discount?: number;
  total?: number;
  currencyIso: string;
  currencySymbol?: string;
  fees?: Fees[],
  taxesIncluded?: number;
  taxesExcluded?: number;
};

export type QuoteTermsPaymentType = {
  notes: string
  expiryDate: string
  updatedAt?: string
  isExpired: boolean;
}

export type QuoteDetailsType = {
  type: TransactionTypes,
  quoteNumber?: string,
  createdAt: string,
  currencyIso?: string,
  status: TransactionStatus;
  reference: string;
  expired?: null | boolean;
  isItems?: boolean

}

interface QuoteLoadingAction {
  type: typeof QUOTE_LOADING
  payload: boolean
}

interface GetQuoteDataAction {
  type: typeof QUOTE_GET_DATA
  payload: QuoteData
}

interface QuoteLoadingSentAction {
  type: typeof QUOTE_LOADING_SENT
  payload: boolean
}

interface SendItemsModalAction {
  type: typeof QUOTE_SEND_ITEMS_MODAL
  payload: boolean
}

interface SendItemsAction {
  type: typeof QUOTE_SEND_ITEMS
  payload: boolean
}

interface SetItemAction {
  type: typeof QUOTE_SET_ITEM
  payload: Items
}

interface QuoteSetDiscount {
  type: typeof QUOTE_SET_DISCOUNT
  payload: {
    discount: number;
  }
}

interface QuoteSetFee {
  type: typeof QUOTE_SET_FEE
  payload: {
    fees: [{
      [key: string]: number
    }]
  }
}

export type QuoteActions =
  QuoteLoadingAction |
  GetQuoteDataAction |
  QuoteLoadingSentAction |
  SendItemsAction |
  SendItemsModalAction |
  QuoteSetDiscount |
  QuoteSetFee |
  SetItemAction;

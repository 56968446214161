import {
  AccountActions,
  AccountState,
  ACCOUNT_IS_NEXT_PAGE_ORDERS_LOADING_ACTION,
  ACCOUNT_SET_CONTACT,
  ACCOUNT_SET_ERROR,
  ACCOUNT_SET_LOADING,
  ACCOUNT_SET_MORE_ORDERS,
  ACCOUNT_SET_ORDERS,
} from './accountTypes';

const initialState: AccountState = {
  contact: null,
  orders: null,
  loading: null,
  error: null,
  isNextPageOrdersLoading: false,
};

export const AccountReducer = (
  state: AccountState = initialState,
  action: AccountActions,
): AccountState => {
  switch (action.type) {
    case ACCOUNT_SET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case ACCOUNT_SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ACCOUNT_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACCOUNT_SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case ACCOUNT_SET_MORE_ORDERS: {
      if (state.orders?.length) {
        return {
          ...state,
          orders: [...state.orders, ...action.payload],
        };
      }

      return {
        ...state,
        orders: action.payload,
      };
    }
    case ACCOUNT_IS_NEXT_PAGE_ORDERS_LOADING_ACTION:
      return {
        ...state,
        isNextPageOrdersLoading: action.payload,
      };
    default:
      return state;
  }
};

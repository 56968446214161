import React, { useMemo } from 'react';
import cn from 'classnames';
// Types
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
import { Items, PriceType } from 'types/globalTypes';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { calcTaxes } from 'utils/functions';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Styles
import classes from './Total.module.scss';

interface Props {
  translation: TranslationStorefrontModel['cart']['cart_item'];
  price: PriceType;
  items: Items[];
}

const Total: React.FC<Props> = ({ translation, price, items }) => {
  const formatCurrencyByISO = useMemo(
    () => CurrencyFormatByISO(price?.currencyIso),
    [price?.currencyIso],
  );

  const taxes = useMemo(() => calcTaxes(items), [items]);
  const translations = useTranslationsStorefront();

  const subtotals = useMemo(
    () => [
      {
        name: translation.subtotal,
        value: formatCurrencyByISO(price.subtotal),
      },
      {
        name: translations?.documents?.total?.tax_included,
        value: formatCurrencyByISO(taxes.included),
      },
      {
        name: translations?.documents?.total?.tax_excluded,
        value: formatCurrencyByISO(taxes.excluded),
      },
      {
        name: translation.discount,
        value: `(${formatCurrencyByISO(price.discount)})`,
      },
    ],
    [
      formatCurrencyByISO,
      price.discount,
      translations,
      price.subtotal,
      taxes.excluded,
      taxes.included,
      translation.discount,
      translation.subtotal,
    ],
  );

  return (
    <div className={classes.root}>
      <div className={classes.subtotal_list}>
        {subtotals.map((subtotal) => (
          <div
            key={subtotal.name}
            className={cn(classes.subtotal, {
              [classes.subtotal_included]: subtotal.name === 'Tax (Incl.)',
              [classes.subtotal_discount]:
                subtotal.name === translation.discount,
            })}
          >
            <p>{subtotal.name}</p>
            <p>{subtotal.value}</p>
          </div>
        ))}
        {price.fees?.map((fee) => (
          <div className={classes.subtotal} key={Object.keys(fee)[0]}>
            <p>{Object.keys(fee)[0]}</p>
            <p>{formatCurrencyByISO(fee[Object.keys(fee)[0]])}</p>
          </div>
        ))}
      </div>
      <div className={classes.total}>
        <p>{translation.total}</p>
        <p>{formatCurrencyByISO(price.total)}</p>
      </div>
    </div>
  );
};

export default Total;

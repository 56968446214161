import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { IFulfillmentDetails } from 'store/storefront/order/orderTypes';
import {
  Fees, IPayments, Items, PriceType, TermsPaymentType,
} from 'types/globalTypes';

// Action Types
export const SET_DATA_CHART = 'SET_DATA_CHART';
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const PAYMENTS_METHODS_SET_DATA = 'PAYMENTS_METHODS_SET_DATA';
export const APP_SET_NOTIFICATION_ORDER = 'APP_SET_NOTIFICATION_ORDER';
export const ORDER_SET_LOADING = 'ORDER_SET_LOADING';
export const SET_UPDATE_DETAILS = 'SET_UPDATE_DETAILS';
export const SET_ORDERS = 'SET_ORDERS';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const MORE_PAGE = 'MORE_PAGE';
export const ORDERS_SET_SUMMARY = 'ORDERS_SET_SUMMARY';
export const ORDERS_IS_REMINDER = 'ORDERS_IS_REMINDER';

export type OrderState = {
  orderDetails: OrderDetailsType | null;
  paymentsMethods: PaymentsMethods[];
  notification: string | null;
  loading: string;
  summary: OrdersSummary | null;
  orders: OrderType[];
  morePage: boolean;
  isRemind: boolean;
};

export enum OrderTabsEnum {
  All = 'All',
  ProcessedOrders = 'Processed Orders',
  UnprocessedOrders = 'Unprocessed Orders',
  CanceledOrders = 'Canceled Orders',
}

export const OrderTabsNames = ['All', 'Processed', 'Unprocessed', 'Canceled'];

export interface ResOrderDetails {
  id: number;
  associations?: null | {
    type: 'ORDER' | 'ESTIMATE',
    id: number
  }
  balance: number;
  contactId: number;
  contact: {
    address?: {
      city: string;
      address1: string;
      address2: string;
      postalCode: string;
      country?: string;
    };
    createdAt: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    mobileNumber: string;
    organisationId: number;
    organisationName: string;
    updatedAt: string;
  }
  createdAt: string;
  currencyISO: string;
  date: string;
  discountAmount: number | null;
  dueDate: string;
  expiryDate: string | null;
  fees: Fees[] | null;
  statusId: number;
  updatedAt: string;
  reference: string;
  terms: TermsPaymentType | null;
  publicId: string;
  salesChannel: string | null;
  fulfillmentDetails?: IFulfillmentDetails;
  type: string;
  total: number;
  totalTax: number;
  notes: string | null;
  items: Items[];
  payments: IPayments[] | [] | null;
  organisationId: number;
}

export type OrderData = {
  total: number;
  percent: number;
};

export type OrderPercent = {
  percent: number;
  name: string;
};
interface SalesChannel {
  saleschannel: string | null;
  sales: number;
  percentage: number;
}

export type OrdersSummary = {
  orders: {
    processedCount: number | null;
    processedAmount: number | null;
    unprocessedCount: number | null;
    unprocessedAmount: number | null;
  };
  canceled: {
    onlineCount: number | null;
    onlineAmount: number | null;
    instoreCount: number | null;
    instoreAmount: number | null;
  };
  locations: SalesChannel[] | [];
};

export type OrderType = {
  id: string;
  date: string;
  client: string;
  currencyISO: string;
  uniqId?: string;
  salesChannel: string;
  amount: number;
  balance: number;
  numberOrder?: number;
  status: string;
  items: Items[];
};

export type OrderDetailsType = {
  status?: string;
  src?: string;
  publicId: string;
  url: string;
  dueDate: string;
  fulfillmentDetails?: IFulfillmentDetails;
  balance?: number;
  notes: null | string;
  type: string;
  orderName: string;
  price: PriceType;
  associations?: null | {
    type: 'ORDER' | 'ESTIMATE',
    id: number
  }
  contact?: {
    address?: {
      city: string;
      address1: string;
      address2: string;
      postalCode: string;
      country?: string;
    };
    createdAt: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    mobileNumber: string;
    organisationId: number;
    organisationName: string;
    updatedAt: string;
  };
  created: string;
  source: string;
  fulfillmentName?: DeliveryMethodsEnum;
  paymentWith?: string | null;
  paidOn?: string;
  cancelledOn: string;
  items: Items[];
  totalTax: number;
  updatedAt: string;
  currencyISO: string;
  total: number;
  shipping: number;
  discount: number;
  refunds: string | null;
};

export type PaymentsMethods = {
  id: number;
  name: string;
  alias: string;
};

export enum OrderStatus {
  UNPROCESSED = 'Unprocessed',
  PROCESSED = 'Processed',
  CANCELLED = 'Canceled',
  PENDING = 'Pending',
}

interface SetOrderDetails {
  type: typeof SET_ORDER_DETAILS;
  payload: OrderDetailsType;
}

interface PaymentsMethodsActions {
  type: typeof PAYMENTS_METHODS_SET_DATA;
  payload: PaymentsMethods[];
}

interface SetNotificationOrder {
  type: typeof APP_SET_NOTIFICATION_ORDER;
  payload: string | null;
}

interface OrderLoadingAction {
  type: typeof ORDER_SET_LOADING;
  payload: string;
}

interface SetUpdateDetails {
  type: typeof SET_UPDATE_DETAILS;
  payload: Omit<OrderDetailsType, 'contact'>;
}

interface SetOrders {
  type: typeof SET_ORDERS;
  payload: OrderType[];
}

interface ClearOrders {
  type: typeof CLEAR_ORDERS;
  payload: OrderType[];
}

interface MorePages {
  type: typeof MORE_PAGE;
  payload: boolean;
}

interface OrdersSetSummary {
  type: typeof ORDERS_SET_SUMMARY;
  payload: OrdersSummary;
}

interface OrderIsReminderAction {
  type: typeof ORDERS_IS_REMINDER;
  payload: boolean;
}

export type OrderActions =
  | SetOrderDetails
  | PaymentsMethodsActions
  | SetNotificationOrder
  | OrderLoadingAction
  | SetUpdateDetails
  | SetOrders
  | ClearOrders
  | OrdersSetSummary
  | MorePages
  | OrderIsReminderAction;

import {
  combineReducers,
  createStore,
  applyMiddleware,
  Action,
  Middleware,
  Store,
} from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk, { ThunkAction } from 'redux-thunk';
// Reducers
import { transactionReducer } from './dashboard/transaction/transactionReducer';
import { authReducer } from './auth/authReducer';
import { billReducer } from './bill/billReducer';
import { invoiceReducer } from './invoice/invoiceReducer';
import { estimateReducer } from './estimate/estimateReducer';
import { quoteReducer } from './quote/quoteReducer';
import dashboardReducers, { DashboardState } from './dashboard';
import storefrontReducers, { StorefrontState } from './storefront';
import { OrderDashboardReducer } from './dashboard/orders/orderReducer';

// Types
import { TransactionState } from './dashboard/transaction/transactionTypes';
import { AuthState } from './auth/authTypes';
import { InvoiceState } from './invoice/invoiceTypes';
import { BillState } from './bill/billTypes';
import { EstimateState } from './estimate/estimateTypes';
import { QuoteState } from './quote/quoteTypes';
import { OrderState } from './dashboard/orders/orderTypes';
import { DirectoryState } from './dashboard/directory/directoryTypes';
import { DirectoryReducer } from './dashboard/directory/directoryReducer';
import { PaymentLinkReducer } from './paymentLink/paymentLinkReducer';
import { PaymentLinkState } from './paymentLink/paymentLinkTypes';

interface StoreState {
  auth: AuthState;
  invoice: InvoiceState;
  bill: BillState;
  estimate: EstimateState;
  quote: QuoteState;
  dashboard: DashboardState;
  storefront: StorefrontState;
  transaction: TransactionState;
  order: OrderState;
  directory: DirectoryState;
  paymentLink: PaymentLinkState;
}

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  invoice: invoiceReducer,
  bill: billReducer,
  estimate: estimateReducer,
  quote: quoteReducer,
  dashboard: dashboardReducers,
  storefront: storefrontReducers,
  transaction: transactionReducer,
  order: OrderDashboardReducer,
  directory: DirectoryReducer,
  paymentLink: PaymentLinkReducer,
});

const middleware: Middleware[] = [reduxThunk];

export const store: Store<StoreState> = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

// Store types
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { getDirectorySummary } from 'store/dashboard/directory/directoryActions';
// Hooks
import { useUser } from 'hooks/useUser';
import { useTranslations } from 'hooks/useTranslations';
// Components
import DashboardLayout from 'layouts/DashboardLayout';
import DirectoryTabs from './DirectoryTabs';

const DirectoryPage: React.FC = () => {
  const user = useUser();
  const translations = useTranslations();
  const dispatch = useDispatch();
  const tsDirectory = translations.directory;

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(getDirectorySummary(user?.currentOrganisation?.id));
    }
  }, [dispatch, user?.currentOrganisation?.id]);

  return (
    <DashboardLayout>
      <DirectoryTabs tabs={tsDirectory.tabs} />
    </DashboardLayout>
  );
};

export default DirectoryPage;

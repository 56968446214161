import React from 'react';
import { isIOS, isMacOs } from 'react-device-detect';
import { PayMethodsEnum } from 'constants/paymentsMethods';
import { IPaymentMethods } from 'utils/storefront/PaymentFunctions';
import GoogleIcon from 'assets/payment-icons/g-pay.svg';
import AppleIcon from 'assets/payment-icons/apple-pay.png';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Styles
import classes from './ExpressPaymentButton.module.scss';

interface Props {
  type: PayMethodsEnum
  handlePay: (paymentMethodTypes: PayMethodsEnum) => Promise<void>
  expressOption: IPaymentMethods
  checkSupportStripe: boolean
}

const ExpressPaymentButton: React.FC<Props> = ({
  type,
  expressOption,
  handlePay,
  checkSupportStripe,
}) => {
  const { checkout } = useTranslationsStorefront();

  if (type === PayMethodsEnum.GRABPAY) {
    return (
      <button type="button" onClick={() => handlePay(PayMethodsEnum.GRABPAY)} className={classes.grab_pay}>
        {expressOption.logos?.map((logo) => (
          <React.Fragment key={logo}>
            <img src={logo} alt={logo} />
          </React.Fragment>
        ))}
      </button>
    );
  }

  if (type === PayMethodsEnum.GOOGLE) {
    return (
      <div className={classes.btn_wrapper}>
        <button
          type="button"
          onClick={() => handlePay(PayMethodsEnum.GOOGLE)}
          className={classes.google_pay}
          disabled={!checkSupportStripe}
        >
          <img src={GoogleIcon} alt="googleIcon" />
        </button>
        {!checkSupportStripe && (
          <p className={classes.error}>
            {checkout.errors.stripe_available_google}
          </p>
        )}
      </div>
    );
  }

  if (type === PayMethodsEnum.APPLE && (isIOS || isMacOs)) {
    return (
      <div className={classes.btn_wrapper}>
        <button
          type="button"
          onClick={() => handlePay(PayMethodsEnum.APPLE)}
          className={classes.apple_pay}
          disabled={!checkSupportStripe}
        >
          <img src={AppleIcon} alt="appleIcon" />
        </button>
        {!checkSupportStripe && (
          <p className={classes.error}>
            {checkout.errors.stripe_available_apple}
          </p>
        )}
      </div>
    );
  }

  if (type === PayMethodsEnum.ALIPAY) {
    return (
      <button type="button" onClick={() => handlePay(PayMethodsEnum.ALIPAY)} className={classes.ali_pay}>
        {expressOption.logos?.map((logo) => (
          <React.Fragment key={logo}>
            <img src={logo} alt={logo} />
          </React.Fragment>
        ))}
      </button>
    );
  }

  return (
    null
  );
};

export default ExpressPaymentButton;

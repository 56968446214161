/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
// Hooks
import { useMobileScreen } from 'hooks/useMobileScreen';
// Utils
import { capitalizeFirstLetter } from 'utils/strings';
// Types
import { TransactionType } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Assets
import { ReactComponent as Email } from 'assets/icons/email.svg';
// Components
import TextButton from 'components/Buttons/TextButton';
// Styles
import classes from './SocialTransaction.module.scss';

interface Props {
  translations: TranslationDashboardModel
  transaction: TransactionType
}

const SocialTransaction: React.FC<Props> = ({ translations, transaction }) => {
  const ts = translations.transactions;
  const { isMobile } = useMobileScreen();
  const [copy, setCopy] = useState(false);
  const CopyToBuffer = (): void => {
    if (transaction?.url) {
      navigator.clipboard.writeText(
        transaction?.url,
      )
        .then(() => {
          setCopy(true);
        }, () => {
          setCopy(false);
        });
    }
  };

  return (
    <div className={classes.social_card}>
      <div className={classes.wrap_share}>
        <p className={classes.share}>
          {translations.home.card.share}
        </p>
      </div>
      <div className={classes.wrap_social}>
        {!isMobile
          && (
            <div className={classes.wrap_icon_container}>
              <div className={classes.border_icon}>
                {ts.email}
              </div>
              <a href={`mailto:?subject=I wanted you to see this site&body= ${transaction?.url}`}>
                <Email />
              </a>
            </div>
          )}
      </div>
      <div className={classes.social_card_link}>
        <p>
          {transaction?.url}
        </p>
        <TextButton onClick={CopyToBuffer} style={{ fontSize: '16px', fontWeight: '500' }}>
          {copy ? capitalizeFirstLetter(ts.copied) : ts.copy}
        </TextButton>
      </div>
    </div>
  );
};

export default SocialTransaction;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { setNotificationAction } from 'store/storefront/app/appActions';
// Styles
import classes from './Notification.module.scss';

interface Props {
  active: boolean;
  text: string;
}

const Notification: React.FC<Props> = ({ active, text }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timer: NodeJS.Timeout;

    if (active) {
      timer = setTimeout(() => {
        dispatch(setNotificationAction(null));
      }, 3000);
    }

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div className={cn(classes.root, {
      [classes.root_active]: active,
    })}
    >
      <p>
        {text}
      </p>
    </div>
  );
};

export default Notification;

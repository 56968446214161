import React from 'react';
// Assets
import { ReactComponent as CheckIcon } from 'assets/icons/check-checkout.svg';
// Components
import CustomSpinner from 'components/CustomSpinner';
// Classes
import { useTypeSelector } from 'hooks/useTypeSelector';
import Notification from 'components/StorefrontComponents/Checkout/Notification';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import classes from './PlacingOrder.module.scss';

const PlacingOrder: React.FC = () => {
  const { items } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { checkout } = useTranslationsStorefront();
  const placingOrderTs = checkout.placing_order_modal;

  return (
    <>
      <div className={classes.root}>
        <Notification
          active
          text={placingOrderTs.notification}
        />
        <div className={classes.loading}>
          <h2>
            {placingOrderTs.title}
          </h2>
          <CustomSpinner variant="two" />
        </div>
        <div className={classes.process_payment}>
          <CheckIcon />
          <p>
            {placingOrderTs.processing_payment_text}
          </p>
        </div>
        <div className={classes.send_order}>
          <div className={classes.send_order_top}>
            <CheckIcon />
            <p>
              {placingOrderTs.sending_your_order_text}
            </p>
          </div>
          <div className={classes.items_list} />
          {items.map((item) => (
            <div className={classes.item} key={item.name}>
              <div className={classes.quantity}>
                {item.quantity}
              </div>
              <div className={classes.name}>
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PlacingOrder;

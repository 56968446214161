import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
import React from 'react';
import Item from './Item/Item';
// Styles
import classes from './ItemList.module.scss';

const ItemList: React.FC = () => {
  const { items } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const currencyIso = currentSalesChannel?.address.currencyISO || 'EUR';

  const { cart, checkout } = useTranslationsStorefront();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {checkout.items_list.title}
      </div>
      <div className={classes.items_list_wrapper}>
        {items.map((item) => (
          <Item
            key={item.id}
            item={item}
            translation={cart}
            currencyISO={currencyIso}
          />
        ))}
      </div>
    </div>
  );
};

export default ItemList;

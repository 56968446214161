import React from 'react';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Actions
import { orderSelectContact, selectOrderDetails } from 'store/dashboard/orders/orderSelectors';
// Types
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import { TransactionStatus } from 'types/globalTypes';
// Styles
import classes from './Contacts.module.scss';

const Contacts: React.FC = () => {
  const { contacts } = useTypeSelector(orderSelectContact);
  const orderDetails = useTypeSelector(selectOrderDetails);
  const user = useUser();
  const translations = useTranslations();
  const ts = translations.orders.details.contact_title;
  const { isMobile } = useMobileScreen();

  if (!contacts || !orderDetails) {
    return null;
  }

  const isActive = orderDetails.status === TransactionStatus.UNPROCESSED;

  return (
    <div className={classes.contacts}>
      <div className={classes.contacts_wrapper}>
        <div className={classes.contact}>
          <h2 className={classes.contact_title}>
            {user?.currentOrganisation?.name}
          </h2>
          <div className={classes.contact_address}>
            {isMobile ? (
              <p>
                {user?.currentOrganisation?.address?.line1}
                {user?.currentOrganisation?.address?.line2}
                {user?.currentOrganisation?.address?.city},
                {user?.currentOrganisation?.address?.postalCode},
                {user?.currentOrganisation?.country?.name}
              </p>
            ) : (
              <>
                <p>
                  {user?.currentOrganisation?.address?.line1}
                </p>
                <p>
                  {user?.currentOrganisation?.address?.line2}
                </p>
                <p>
                  {user?.currentOrganisation?.address?.city},
                  {user?.currentOrganisation?.address?.postalCode},
                  {user?.currentOrganisation?.country?.name}
                </p>
              </>
            )}
            <p>
              {user?.currentOrganisation?.phoneNumber}
            </p>
            <p>
              {user?.currentOrganisation?.email}
            </p>
          </div>
          {!isMobile && (
            <div className={classes.contact_transaction_title}>
              <p className={classes.contact_transaction_title_name}>
                {`${translations.transaction_type[orderDetails.type as keyof TranslationDashboardModel['transaction_type']]} # ${orderDetails.orderName}`}
              </p>
              <p className={isActive
                ? classes.contact_transaction_title_status
                : classes.contact_transaction_title_status_paid}
              >
                {translations.status[orderDetails.status as keyof TranslationDashboardModel['status']]}
              </p>
            </div>
          )}
        </div>
        <div className={classes.contact_user}>
          <h2 className={classes.contact_title}>
            {ts}
          </h2>
          <div className={classes.contact_address}>
            <p>
              {`${contacts.firstName} ${contacts.lastName}`}
            </p>
            {isMobile ? (
              <p>
                {contacts.address?.address1}
                {contacts.address?.address2}
                {contacts.address?.city}
                {contacts.address?.postalCode}
                {contacts.address?.country}

              </p>
            ) : (
              <>
                <p>
                  {contacts.address?.address1}
                </p>
                <p>
                  {contacts.address?.address2}
                </p>
                <p>
                  {contacts.address?.city}{' '}
                  {contacts.address?.postalCode}{' '}
                  {contacts.address?.country}{' '}
                </p>
              </>
            )}
            <p>
              {contacts.mobileNumber}
            </p>
            <p>
              {contacts.email}
            </p>
          </div>
          {isMobile && (
            <div className={classes.contact_transaction_title}>
              <p className={classes.contact_transaction_title_name}>
                {`${translations.transaction_type[orderDetails.type as keyof TranslationDashboardModel['transaction_type']]} # ${orderDetails.orderName}`}
              </p>
              <p className={isActive
                ? classes.contact_transaction_title_status
                : classes.contact_transaction_title_status_paid}
              >
                {translations.status[orderDetails.status as keyof TranslationDashboardModel['status']]}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contacts;

import AccountLayout from 'layouts/StorefrontLayout/AccountLayout';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
// Assets
import { ReactComponent as BoxIcon } from 'assets/storefront-icons/box.svg';
import { ReactComponent as LocationIcon } from 'assets/storefront-icons/location.svg';
import { ReactComponent as LockIcon } from 'assets/storefront-icons/lock.svg';
// Components
import Card from './Card';
// Styles
import classes from './Account.module.scss';

const Account: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const translations = useTranslationsStorefront();

  const {
    getAccountAddressPath,
    getAccountSecurityPath,
    getAccountOrderPath,
  } = useStorefrontRoutes();

  const accountCards = useMemo(() => [
    {
      name: translations?.account?.account_cards?.orders_name,
      description: translations?.account?.account_cards?.orders_description,
      Icon: BoxIcon,
      link: getAccountOrderPath(id),
    },
    {
      name: translations?.account?.account_cards?.address_name,
      description: translations?.account?.account_cards?.address_description,
      Icon: LocationIcon,
      link: getAccountAddressPath(id),
    },
    {
      name: translations?.account?.account_cards?.security_name,
      description: translations?.account?.account_cards?.security_description,
      Icon: LockIcon,
      link: getAccountSecurityPath(id),
    },
  ], [getAccountAddressPath, getAccountOrderPath, getAccountSecurityPath, id, translations]);

  return (
    <AccountLayout>
      <div className={classes.cards}>
        {accountCards.map((accountCard) => (
          <Card
            key={accountCard.name}
            name={accountCard.name}
            description={accountCard.description}
            Icon={accountCard.Icon}
            link={accountCard.link}
          />
        ))}
      </div>
    </AccountLayout>
  );
};

export default Account;

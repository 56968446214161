import React, { useCallback } from 'react';
// Hooks
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslations } from 'hooks/useTranslations';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useUser } from 'hooks/useUser';
// Actions
import { setMobileMenuOpenAction } from 'store/dashboard/app/appActions';
// Types
import { DashboardRoutePath } from 'router/Routes/Dashboard';
import { IOrganisationData } from 'types/globalTypes';
// Icons
import { ReactComponent as DeleteIcon } from 'assets/icons/icn-delete.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// Components
import BackBtnWithArrow from 'components/Buttons/BackBtnWithArrow';
import MobileMenu from '../MobileMenu';
// Styles
import classes from './NavBar.module.scss';

interface Props {
  isBack?: boolean;
  onBackClick?: () => void;
  isCancel?: boolean;
  onCancelClick?: () => void;
  isDeletable?: boolean;
  onDeleteClick?: () => void;
  switchOrganisation: (org: IOrganisationData) => void;
}

const NavBar: React.FC<Props> = ({
  isBack,
  onBackClick,
  isCancel,
  onCancelClick,
  isDeletable,
  onDeleteClick,
  switchOrganisation,
}) => {
  const {
    isMobileMenuOpen,
    activeTranslation,
  } = useTypeSelector(
    ({ dashboard }) => dashboard.app,
  );
  const { isMobile } = useMobileScreen();
  const transactions = useTranslations();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params: { orgId: string, reportName: string } = useParams();
  const user = useUser();
  const mobileMenuHandler = () => {
    dispatch(setMobileMenuOpenAction(!isMobileMenuOpen));
  };

  const navBarName = useCallback(
    () => {
      if (pathname === DashboardRoutePath.PROFILE) {
        return `${user?.firstName} ${user?.lastName}`;
      }
      if (pathname === DashboardRoutePath.ORDERS) {
        return transactions.orders.orders;
      }

      if (pathname === DashboardRoutePath.DIRECTORY) {
        return transactions.sidebar.directory;
      }

      if (pathname === DashboardRoutePath.ORGANISATION.replace(':orgId', params.orgId).replace(':reportName', params.reportName)) {
        if (isMobileMenuOpen) {
          return user?.currentOrganisation?.name;
        }
        return transactions.dashboard;
      }

      if (pathname === DashboardRoutePath.CATALOG) {
        return transactions.sidebar.catalog;
      }

      if (pathname === DashboardRoutePath.TRANSACTIONS) {
        return transactions.sidebar.transactions;
      }

      return transactions.dashboard;
    },
    [isMobileMenuOpen,
      params.orgId,
      params.reportName,
      pathname,
      transactions,
      user?.currentOrganisation?.name,
      user?.firstName,
      user?.lastName],
  );

  if (isCancel) {
    return (
      <div className={classes.navbar}>
        <button type="button" className={classes.cancel_btn} onClick={onCancelClick}>
          {activeTranslation.navbar.cancel_import_text}
          <CloseIcon />
        </button>
      </div>
    );
  }

  if (isBack) {
    return (
      <div className={classes.navbar}>
        <BackBtnWithArrow
          title={activeTranslation.navbar.back_btn_text}
          onClick={onBackClick}
        />
        {isDeletable && (
          <DeleteIcon className={classes.delete_icon} onClick={onDeleteClick} />
        )}
      </div>
    );
  }

  if (isMobile) {
    return (
      <>
        {isMobileMenuOpen ? (
          <div className={classes.navbar}>
            {navBarName() === user?.currentOrganisation?.name
              ? (
                <NavLink
                  exact
                  aria-current="true"
                  to={DashboardRoutePath.ORGANIZATION}
                  className={classes.navbar_title_open}
                >
                  {user?.currentOrganisation?.name}
                </NavLink>
              )
              : (
                <h1 className={classes.navbar_title}>
                  {navBarName()}
                </h1>
              )}
            <button
              className={`${classes.hamburger} ${classes.hamburger_active}`}
              type="button"
              onClick={mobileMenuHandler}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
        ) : (
          <div className={classes.navbar}>
            {navBarName() === user?.currentOrganisation?.name
              ? (
                <NavLink
                  exact
                  aria-current="true"
                  to={DashboardRoutePath.ORGANIZATION}
                  className={classes.organization}
                >
                  <p className={classes.organization_name}>
                    {user?.currentOrganisation?.name}
                  </p>
                </NavLink>
              )
              : (
                <h1 className={classes.navbar_title}>
                  {navBarName()}
                </h1>
              )}

            <button
              className={classes.hamburger}
              type="button"
              onClick={mobileMenuHandler}
            >
              <span />
              <span />
              <span />
            </button>
          </div>

        )}
        <MobileMenu switchOrganisation={switchOrganisation} />
      </>
    );
  }

  return (
    null
  );
};

export default NavBar;

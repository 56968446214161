import { combineReducers } from '@reduxjs/toolkit';
import { itemsReducer } from './items/itemsReducer';
// Types
import { ItemsState } from './items/itemsTypes';

export interface CatalogState {
  items: ItemsState;
}

const catalogReducers = combineReducers<CatalogState>({
  items: itemsReducer,
});

export default catalogReducers;

import React, { useMemo } from 'react';
import DashboardLayout from 'layouts/DashboardLayout';
import { useTranslations } from 'hooks/useTranslations';
import TransactionTabs from './TransactionTabs/TransactionTabs';

const Transaction: React.FC = () => {
  const translations = useTranslations();
  const tabsName = translations.transactions.tabs;

  const tabs = useMemo(() => [
    tabsName.sales,
    tabsName.expenses,
    tabsName.unpaid_invoices,
    tabsName.unpaid_bills,
    tabsName.estimates,
    tabsName.quotes,
  ], [
    tabsName.estimates,
    tabsName.expenses,
    tabsName.quotes, tabsName.sales, tabsName.unpaid_bills, tabsName.unpaid_invoices]);

  return (
    <DashboardLayout>
      <TransactionTabs tabs={tabs} />
    </DashboardLayout>
  );
};

export default Transaction;

import React, { useMemo } from 'react';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
// Utils
import { getYear } from 'utils/dates';
import { format } from 'd3';
import { CurrencyFormatByISO } from 'utils/price';
// Components
import DonutChart from './DonutChart';
// Styles
import classes from './DonutChartCard.module.scss';

interface Props {
  title: string;
  percentage?: number;
  value?: number | null;
  type?: 'two';
  data?: {
    subtitle: string;
    value: string | number | null;
    format?: string;
  }
}

const DonutChartCard: React.FC<Props> = ({
  title,
  percentage,
  value,
  type,
  data,
}) => {
  const user = useUser();
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.country?.currencyISO || 'USD');
  const translations = useTranslations();
  const percent = useMemo(() => {
    if (percentage !== 0 && percentage) {
      if (percentage === 100) {
        return `${(Math.trunc(percentage) * 100) / 100}%`;
      }
      return `${percentage.toFixed(1)}%`;
    }

    return '';
  }, [percentage]);

  if (type === 'two' && data) {
    return (
      <div className={classes.root}>
        <h2 className={value ? classes.title_two : classes.title_no_data}>
          {title}
        </h2>
        {value
          ? (
            <>
              <div className={classes.chart_wrapper}>
                <DonutChart percentage={percentage} />
                <p className={classes.percent}>{format(data.format || '.0%')(value).toUpperCase()}</p>
              </div>
              <h3 className={classes.subtitle}>{data.subtitle}</h3>
              <span className={classes.second_value}>{data.value}</span>
            </>
          ) : (
            <div className={classes.nodata}>
              <p>
                {translations.home.report.no_data_available_yet}
              </p>
            </div>
          )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>
        {title}
      </h2>
      {value ?? !Number.isNaN(Number(percentage))
        ? (
          <>
            <div className={classes.chart_wrapper}>
              <DonutChart percentage={percentage} />
              <p className={classes.percent}>{percent}</p>
            </div>
            <p className={classes.value}>{value ? formatCurrencyByISO(value) : ''}</p>
            <p className={classes.ytd}>
              {`${getYear()} ${translations.home.report.ytd}`}
            </p>
          </>
        ) : (
          <div className={classes.nodata}>
            <p>
              {translations.home.report.no_data_available_yet}
            </p>
          </div>
        )}
    </div>
  );
};

export default DonutChartCard;

import React, { useCallback } from 'react';
// Types
import QRCode from 'react-qr-code';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
import { IOrderData } from 'store/storefront/order/orderTypes';
import { dateToFormat } from 'utils/dates';
// Assets
import { ReactComponent as PhoneIcon } from 'assets/storefront-icons/phone.svg';
// Styles
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { useTypeSelector } from 'hooks/useTypeSelector';
import classes from './DeliveryContact.module.scss';

interface Props {
  fulfillmentMethod: DeliveryMethodsEnum;
  translation: TranslationStorefrontModel['order']['shipping_info'];
  status: IOrderData['status']['name'];
  fulfillmentDetails: IOrderData['fulfillmentDetails'];
  orderId: string;
  handleTradingHoursModal: () => void;
}

const DeliveryContact: React.FC<Props> = ({
  fulfillmentMethod, translation, status, fulfillmentDetails, orderId, handleTradingHoursModal,
}) => {
  const { data } = useTypeSelector(({ storefront }) => storefront.order);

  const showInMapClicked = useCallback(() => {
    window.open(`https://www.google.com/maps/place/${fulfillmentDetails.address}`);
  }, [fulfillmentDetails.address]);

  if (status === 'UNPAID' || status === 'UNPROCESSED' || status === 'CANCELLED') {
    return null;
  }

  if (fulfillmentMethod === DeliveryMethodsEnum.PICKUP && status === 'ACCEPTED') {
    return (
      <div className={classes.pickup_accepted}>
        <p className={classes.title}>
          {translation.details}
        </p>
        <div className={classes.pickup_accepted_bottom}>
          <p className={classes.pickup_accepted_bottom_status}>
            {translation.picked_up}
          </p>
          <div className={classes.pickup_accepted_bottom_date}>
            <p>
              {dateToFormat(data?.date, ' DD MMM YYYY')}
            </p>
            <p>
              {dateToFormat(data?.date, 'hh:mm A')}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (fulfillmentMethod === DeliveryMethodsEnum.PICKUP) {
    return (
      <div className={classes.pickup}>
        <div className={classes.title}>
          <p>
            {translation.pickup_qr_code}
          </p>
          <div className={classes.qr}>
            {orderId && (<QRCode value={`https://appdev.leja.co/dashboard/orders/${orderId}`} size={44} level="Q" />)}
            <p>
              {translation.qr_title}
            </p>
          </div>
        </div>
        <div className={classes.pickup_right}>
          <p>
            {translation.about_pickup}
          </p>
          <button type="button" onClick={showInMapClicked}>
            {translation.get_directions}
          </button>
          <button type="button" onClick={handleTradingHoursModal}>
            {translation.opening_hours}
          </button>
        </div>
      </div>
    );
  }

  if (fulfillmentMethod === DeliveryMethodsEnum.DELIVERY) {
    return (
      <div className={classes.root}>
        <p className={classes.title}>
          {translation.delivery_info}
        </p>
        <div className={classes.info}>
          <div className={classes.info_delivery}>
            <p className={classes.info_delivery_label}>
              {status === 'ACCEPTED' ? translation.delivery_by : translation.delivery_person}
            </p>
            <div className={classes.info_delivery_value}>
              <p>
                {fulfillmentDetails.shippingCourier}
              </p>
              {status === 'ACCEPTED' && (
                <p>
                  {dateToFormat(data?.date, 'hh:mm A')}
                </p>
              )}
            </div>
          </div>
          {status !== 'ACCEPTED' && (
            <div className={classes.info_tracking}>
              <p className={classes.info_tracking_label}>
                {translation.contact_number}
              </p>
              <div className={classes.info_tracking_value}>
                <a href={`tel:${fulfillmentDetails.shippingPhone}`}>
                  <PhoneIcon />
                  {fulfillmentDetails.shippingPhone}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (fulfillmentMethod === DeliveryMethodsEnum.SHIPPING) {
    return (
      <div className={classes.root}>
        <p className={classes.title}>
          {translation.tracking_information}
        </p>
        <div className={classes.info}>
          <div className={classes.info_provider}>
            <p className={classes.info_provider_label}>
              {translation.shipped_via}
            </p>
            <p className={classes.info_provider_value}>
              {fulfillmentDetails.providerName}
            </p>
          </div>
          <div className={classes.info_tracking}>
            <p className={classes.info_tracking_label}>
              {translation.tracing_number}
            </p>
            <div className={classes.info_tracking_value}>
              <p>
                {fulfillmentDetails.trackingId}
              </p>
              {status === 'ACCEPTED' && (
                <p className={classes.info_tracking_value_delivered}>
                  {translation.delivered.replace('{date}', dateToFormat(data?.date, 'DD MMM'))}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default DeliveryContact;

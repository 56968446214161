import { Documents } from 'types/globalTypes';
import { calcTaxesQuote } from 'utils/functions';

interface QuotePrice {
  subtotal?: number,
  totalFees: number,
  total?: number,
  taxIncluded?: number,
  taxExcluded?: number,
}

export const quotePrice = (data: Documents): QuotePrice => {
  const subtotal = () => {
    if (data.items.some((i) => i.unitPrice)) {
      return data.items.reduce(
        (acc: number, item) => acc + (item.unitPrice || 0) * item.quantity,
        0,
      );
    }
    return undefined;
  };

  const totalFees = () => {
    if (data?.fees) {
      return data?.fees.reduce((acc, curr) => acc + Object.values(curr)[0], 0);
    }
    return 0;
  };
  const taxes = calcTaxesQuote(data.items);

  const total = () => {
    const sbtl = subtotal();
    if (typeof sbtl === 'undefined') {
      return undefined;
    }
    return sbtl - data.discountAmount + totalFees() + (taxes.excluded ?? 0);
  };

  return {
    subtotal: subtotal(),
    totalFees: totalFees(),
    total: total(),
    taxIncluded: taxes.included,
    taxExcluded: taxes.excluded,
  };
};

import { useMobileScreen } from 'hooks/useMobileScreen';
import React from 'react';
import { OrderType } from 'store/dashboard/orders/orderTypes';
import { formatReg } from 'utils/dates';
import { CurrencyFormatByISO } from 'utils/price';
import { useTranslations } from 'hooks/useTranslations';
import { Link } from 'react-router-dom';
import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
import { capitalizeFirstLetter } from 'utils/strings';
// Types
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Styles
import classes from './Order.module.scss';

interface Props {
  item: OrderType;
}

const Order: React.FC<Props> = ({ item }) => {
  const { isMobile } = useMobileScreen();
  const { pathToOrderDetails } = useDashboardRoutes();
  const translations = useTranslations();
  const formatCurrencyByISO = CurrencyFormatByISO(item.currencyISO);

  return isMobile ? (
    <Link key={item.id} className={classes.item_mb} to={pathToOrderDetails(`${item.id}`)}>
      <div className={classes.item_mb_header}>
        <p className={classes.item_mb_header_date}>{formatReg(item.date)}</p>
        <p className={classes.item_mb_header_status}>{translations.status[item.status as keyof TranslationDashboardModel['status']]}</p>
      </div>
      <div className={classes.item_mb_name}>{item.client}</div>
      <div className={classes.item_mb_source}>
        {capitalizeFirstLetter(item.salesChannel)}
      </div>
      <div className={classes.item_mb_value}>{formatCurrencyByISO(item.amount)}</div>
      <div className={classes.item_mb_bottom}>
        <div className={classes.item_mb_bottom_first_row}>
          <div>{translations.orders.paid}</div>
          <div>{item.balance ? translations.orders.no : translations.orders.yes}</div>
        </div>
        <div className={classes.item_mb_bottom_second_row}>
          <div>{translations.orders.count_items}</div>
          <div>{item.items.reduce((acc, next) => acc + next.quantity, 0)}</div>
        </div>
      </div>
    </Link>
  ) : (
    <Link
      key={item.id}
      className={classes.table_content_item}
      to={pathToOrderDetails(`${item.id}`)}
    >
      <p>{formatReg(item.date)}</p>
      <p>{item.client}</p>
      <p>{capitalizeFirstLetter(item?.salesChannel || '')}</p>
      <p>{item.balance ? translations.orders.unpaid : translations.orders.paid}</p>
      <p>{item.amount ? formatCurrencyByISO(item.amount) : '-'}</p>
      <p>{item.items.reduce((acc, next) => acc + next.quantity, 0)}</p>
      <p>{translations.status[item.status as keyof TranslationDashboardModel['status']]}</p>
    </Link>
  );
};

export default Order;

import React, { useEffect, useMemo, useState } from 'react';
import { useMobileScreen } from 'hooks/useMobileScreen';

import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import Card from 'components/Card';
import CardSelect from 'components/CardSelect';
import { ReactSelectTime } from 'types/globalTypes';
import { getYear } from 'utils/dates';
import { getReportSelectCards, getSalesTaxesData } from 'store/dashboard/report/reportActions';
import { ReportReq } from 'store/dashboard/report/reportTypes';
import Graph from 'components/Graph';
import CustomSpinner from 'components/CustomSpinner';
import MobileExpensesTab from './MobileTab';
import classes from './SalesTaxesTab.module.scss';

export interface SalesTaxesData {
  month: string;
  value: number;
}

const SalesTaxesTab: React.FC = () => {
  const { isMobile } = useMobileScreen();
  const dispatch = useDispatch();
  const params: { orgId?: string, reportName?: string } = useParams();
  const user = useUser();
  const [time, setTime] = useState<ReactSelectTime>(ReactSelectTime.yesterday);
  const {
    salesTaxesChart, reportSelectCards, monthlyAverage, loading,
  } = useTypeSelector(
    ({ dashboard }) => dashboard.report,
  );

  const otherTax = reportSelectCards ? Object.keys(reportSelectCards)[2] : null;
  const translations = useTranslations();
  const ts = translations.home.report;
  const months = useMemo(() => [
    ts.re_jan,
    ts.re_feb,
    ts.re_mar,
    ts.re_apr,
    ts.re_may,
    ts.re_jun,
    ts.re_jul,
    ts.re_aug,
    ts.re_sep,
    ts.re_oct,
    ts.re_nov,
    ts.re_dec,
  ], [
    ts.re_apr,
    ts.re_aug,
    ts.re_dec,
    ts.re_feb,
    ts.re_jan, ts.re_jul, ts.re_jun, ts.re_mar, ts.re_may, ts.re_nov, ts.re_oct, ts.re_sep]);

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(getSalesTaxesData(user.currentOrganisation.id, months));
    }
  }, [dispatch, months, params.orgId, user?.currentOrganisation?.id]);

  useEffect(() => {
    if (!user || !params.orgId || !params.reportName) {
      return;
    }
    if (!(user?.currentOrganisation?.id)) {
      return;
    }
    dispatch(getReportSelectCards(user.currentOrganisation.id, time, ReportReq.salestaxes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.orgId, params.reportName, time, user]);
  const getTotal = () => salesTaxesChart.data.map((item) => item.value).reduce((a, b) => a + b, 0);

  if (loading) {
    return <CustomSpinner variant="two" />;
  }

  return isMobile ? (
    <MobileExpensesTab
      total={getTotal()}
      time={time}
      setTime={setTime}
    />
  ) : (
    <>
      <div className={classes.content_container}>
        <div className={classes.chart_container}>
          <div className={classes.chart}>
            <div className={classes.chart_title}>
              <div className={classes.chart_years}>
                {getYear()} {ts.ytd}
              </div>
              <div className={getTotal() ? classes.chart_text : classes.chart_text_nodata}>
                {ts.tabs.re_sales_taxes}
              </div>
            </div>
            {!getTotal() ? (
              <div className={classes.nodata}>
                <div className={classes.lines}>
                  <div className={classes.line} />
                  <div className={classes.line} />
                  <div className={classes.line} />
                </div>
                <p>{ts.no_data_available_yet}</p>
              </div>
            ) : (
              <Graph
                datasets={salesTaxesChart.data}
                name={ts.tabs.re_sales_taxes}
                color="rgba(107, 89, 237, 1)"
                isIso
              />
            )}
          </div>
        </div>
        <div className={classes.monthly_average}>
          <CardMonthlyAverage
            title={ts.sales_taxes.month}
            value={monthlyAverage?.value}
            change={monthlyAverage?.percentage}
          />
        </div>
        <div className={classes.high}>
          <Card
            data={{
              title: ts.highest_month,
              label: salesTaxesChart.highestMonth?.month,
              value: salesTaxesChart.highestMonth?.value,
              second: ts.high,
              change: salesTaxesChart.highestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.low}>
          <Card
            data={{
              title: ts.lowest_month,
              label: salesTaxesChart.lowestMonth?.month,
              value: salesTaxesChart.lowestMonth?.value,
              second: ts.low,
              change: salesTaxesChart.lowestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.total}>
          <CardSelect
            data={{
              title: ts.all,
              label: ts.sales_taxes.taxes,
              value: reportSelectCards?.all?.entrytotal,
              change: reportSelectCards?.all?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        <div className={classes.paid}>
          <CardSelect
            data={{
              title: ts.all,
              label: ts.sales_taxes.vat,
              value: reportSelectCards?.VAT?.entrytotal,
              change: reportSelectCards?.VAT?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        {
          otherTax
          && (
            <div className={classes.unpaid}>
              <CardSelect
                data={{
                  title: reportSelectCards ? `${reportSelectCards[otherTax]}` : '',
                  label: ts.all.toUpperCase(),
                  value: reportSelectCards?.[otherTax]?.entrytotal,
                  change: reportSelectCards?.[otherTax]?.change || 0,
                }}
                time={time}
                setTime={setTime}
              />
            </div>
          )
        }
      </div>
    </>
  );
};

export default SalesTaxesTab;

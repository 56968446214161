import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Actions
import { reauthenticateWithCredential } from 'store/storefront/account/accountActions';
// Components
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import FormError from 'components/StorefrontComponents/FormError';
import Modal from 'components/Modal';
import PasswordInput from 'components/Inputs/PasswordInput';
// Styles
import classes from './LoginModal.module.scss';

interface Props {
  setActive: () => void;
  active: boolean;
}

const LoginModal: React.FC<Props> = ({ setActive, active }) => {
  const {
    register, handleSubmit, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [wrongPassword, setWrongPassword] = useState<boolean>(false);
  const { checkout } = useTranslationsStorefront();
  const dispatch = useDispatch();

  const onSubmit = useCallback((data) => {
    setActive();
    dispatch(reauthenticateWithCredential(data));
  }, [dispatch, setActive]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timer: NodeJS.Timeout;

    if (wrongPassword) {
      setActive();
      timer = setTimeout(() => {
        setWrongPassword(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [setActive, wrongPassword]);

  return (
    <Modal active={active} setActive={setActive}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <HeaderModal onClick={() => setActive()} className={classes.header} />
          <div className={classes.content}>
            <div className={classes.inputs}>
              <PasswordInput
                register={register}
                {...register('password', {
                  required: true,
                  minLength: 6,
                })}
                autoComplete="current-password"
                isEye={false}
                placeholder={checkout.card_form.password_placeholder}
              >
                <FormError
                  active={wrongPassword}
                  text={checkout.card_form.password_error}
                  direction="up"
                />
              </PasswordInput>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <button type="submit" disabled={!isValid}>
            {checkout.customer_info_modal.sign_in_btn}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default LoginModal;

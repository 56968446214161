import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
// Hooks
import { useMobileScreen } from 'hooks/useMobileScreen';
// Actions
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { setCurrentOrganisationAction } from 'store/auth/authActions';
import {
  getUpdatedMenuLinks,
  setMenuLinksAction,
} from 'store/dashboard/app/appActions';
// Types
import { IOrganisationData } from 'types/globalTypes';
// assets
import { ReactComponent as BackToTheTopIcon } from 'assets/icons/scroll-to-top.svg';
// Components
import LoadingPage from 'pages/LoadingPage';
import NotFullSetProfileModal from 'pages/Dashboard/Home/NotFullSetProfileModal';
import NavBar from './NavBar';
import SideBar from './SideBar';
import Search from './Search';
// Styles
import classes from './DashboardLayout.module.scss';

interface Props {
  isBack?: boolean;
  isDeletable?: boolean;
  isCancel?: boolean;
  onCancelClick?: () => void;
  onBackClick?: () => void;
  onDeleteClick?: () => void;
}

const DashboardLayout: React.FC<Props> = ({
  children,
  isBack,
  onBackClick,
  isCancel,
  onCancelClick,
  isDeletable,
  onDeleteClick,
}) => {
  const myRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { isMobile } = useMobileScreen();
  const { user, loading } = useTypeSelector(({ auth }) => auth);
  const { activeTranslation, menuLinks } = useTypeSelector(
    ({ dashboard }) => dashboard.app,
  );
  const { products, categories, search } = useTypeSelector(
    ({ dashboard }) => dashboard.catalog.items,
  );

  const [visible, setVisible] = useState(false);
  const ts = activeTranslation.home;
  const executeScroll = useCallback(() => {
    if (!myRef.current) {
      return;
    }

    myRef.current.scrollTop = 0;
    window.scrollTo(0, 0);
    setVisible((prev) => !prev);
  }, []);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = isMobile ? window.scrollY : myRef.current?.scrollTop;

      if (!scrolled) {
        return;
      }

      if (scrolled > 1000) {
        setVisible(true);
      }

      if (scrolled <= 1000) {
        setVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisible);

    return () => { window.removeEventListener('scroll', toggleVisible); };
  }, [isMobile]);

  useEffect(() => {
    if (products.data && categories) {
      dispatch(
        setMenuLinksAction(
          getUpdatedMenuLinks(
            menuLinks,
            products.data.length,
            categories.length,
          ),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products?.data?.length, categories?.length]);

  const switchOrganisationHandler = useCallback(
    (org: IOrganisationData) => {
      dispatch(setCurrentOrganisationAction(org));
      localStorage.setItem('leja-org-id', `${org.id}`);
    },
    [dispatch],
  );

  if (loading || !user) {
    return <LoadingPage title={activeTranslation.layout.loading_text} />;
  }

  return (
    <div className={classes.dashboard}>
      <NavBar
        isBack={isBack}
        onBackClick={onBackClick}
        isDeletable={isDeletable}
        isCancel={isCancel}
        onCancelClick={onCancelClick}
        onDeleteClick={onDeleteClick}
        switchOrganisation={switchOrganisationHandler}
      />
      <div className={classes.dashboard__wrapper}>
        {!isMobile && <SideBar />}
        <div
          className={
            search.data.length !== 0
              ? `${classes.dashboard__content} ${classes.dashboard__content_lockscroll}`
              : classes.dashboard__content
          }
        >
          <Search />
          {isMobile && (
            <div
              className={classes.backToTheTopButton}
              tabIndex={0}
              role="button"
              style={{ display: visible ? 'flex' : 'none' }}
              onClick={executeScroll}
              ref={myRef}
            >
              <p>{ts.back_to_the_top}</p>
              <BackToTheTopIcon />
            </div>
          )}
          {user.currentOrganisation?.id ? children : <NotFullSetProfileModal />}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;

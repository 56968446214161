import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import React, { useMemo } from 'react';
import { DirectoryType } from 'store/dashboard/directory/directoryTypes';
import { makeValue } from 'utils/functions';
// Classes
import classes from './Overview.module.scss';

interface Props {
  handleOpenTransactions: (tabIndex?: number) => void;
}

const Overview: React.FC<Props> = ({ handleOpenTransactions }) => {
  const { details } = useTypeSelector(({ directory }) => directory);
  const translations = useTranslations();
  const overviewTs = translations.directory.details.overview;

  const info = useMemo(() => {
    if (details.type === DirectoryType.customer) {
      return [
        makeValue(overviewTs.pending_invoices, details.summary.customers.pendingInvoices?.count ? 'view' : overviewTs.none),
        makeValue(overviewTs.pending_estimates, details.summary.customers.pendingSalesOrders?.count ? 'view' : overviewTs.none),
        makeValue(overviewTs.pending_orders, details.summary.customers.pendingOrders?.count ? 'view' : overviewTs.none),
      ];
    }

    if (details.type === DirectoryType.supplier) {
      return [
        makeValue(overviewTs.pending_bills, details.summary.suppliers.pendingBills?.count ? 'view' : overviewTs.none),
        makeValue(overviewTs.pending_quote_requests, details.summary.suppliers.purchaseOrdersRequested ? 'view' : overviewTs.none),
      ];
    }

    return [
      makeValue(overviewTs.pending_invoices, details.summary.customers.pendingInvoices?.count ? 'view' : overviewTs.none),
      makeValue(overviewTs.pending_estimates, details.summary.customers.pendingSalesOrders?.count ? 'view' : overviewTs.none),
      makeValue(overviewTs.pending_orders, details.summary.customers.pendingOrders?.count ? 'view' : overviewTs.none),
      makeValue(overviewTs.pending_bills, details.summary.suppliers.pendingBills?.count ? 'view' : overviewTs.none),
      makeValue(overviewTs.pending_quote_requests, details.summary.suppliers.purchaseOrdersRequested ? 'view' : overviewTs.none),
    ];
  }, [details, overviewTs]);

  const handleView = (name?: string) => {
    if (name === overviewTs.pending_invoices) {
      handleOpenTransactions(2);
    }

    if (name === overviewTs.pending_estimates) {
      handleOpenTransactions(5);
    }

    if (name === overviewTs.pending_orders) {
      handleOpenTransactions(7);
    }

    if (name === overviewTs.pending_bills) {
      handleOpenTransactions(4);
    }

    if (name === overviewTs.pending_quote_requests) {
      handleOpenTransactions(6);
    }
  };

  return (
    <div className={classes.overview}>
      {info.map((item) => (
        <div className={classes.overview_item} key={item.name}>
          <p className={classes.overview_item_name}>
            {item.name}
          </p>
          {item.value === 'view' ? (
            <button type="button" className={classes.view_btn} onClick={() => handleView(item.name)}>
              {overviewTs.view}
            </button>
          ) : (
            <p className={classes.overview_item_value}>
              {item.value}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Overview;

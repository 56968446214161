import React, {
  useCallback, useRef, useState,
} from 'react';
import { Stripe } from '@stripe/stripe-js';
import { FieldValues } from 'react-hook-form';
import { Elements } from '@stripe/react-stripe-js';
// Constants
import { PayMethodsEnum } from 'constants/paymentsMethods';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Types
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
import { CheckoutType } from 'store/storefront/checkout/checkoutTypes';
// Utils
import { IPaymentMethods } from 'utils/storefront/PaymentFunctions';
// Components
import Modal from 'components/Modal';
import FooterPaymentModal from 'components/StorefrontComponents/Checkout/FooterPaymentModal';
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import PaymentTitle from 'components/StorefrontComponents/PaymentTitle';
import { dateToDue } from 'utils/dates';
import { InvoicePaymentLoadingEnum, StripeKeys } from 'store/invoice/invoiceTypes';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';
import PaymentLaterForm from './PaymentLaterForm';
import PaymentLaterOption from './PaymentLaterOption/PaymentLaterOption';
import StripeConfirm from './StripeConfirm';
import { settings } from './SliderSettings';
// Styles
import classes from './PaymentLaterOptionsModal.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Props {
  setActive: () => void;
  active: boolean;
  allPayMethods: PayMethodsEnum[];
  payLaterOptions: IPaymentMethods[];
  currencyIso: string;
  total: number;
  handleLoading: (loading: InvoicePaymentLoadingEnum) => void;
  handleSuccess:() => void;
  getStripe: (checkoutType?: CheckoutType) => void;
  stripe?: StripeKeys;
  paymentTerm?: number;
  stripePromise: Stripe | null;
}

type TPaymentsMethod =
  keyof TranslationStorefrontModel['checkout']['payment_page']['payment_methods'];

const PaymentLaterOptionsModal: React.FC<Props> = ({
  active,
  setActive,
  payLaterOptions,
  handleLoading,
  handleSuccess,
  getStripe,
  stripe,
  total,
  currencyIso,
  stripePromise,
  paymentTerm,
}) => {
  const sliderRef = useRef<Slider>(null);
  const translations = useTranslationsStorefront();
  const paymentsMethodsTs = translations.checkout.payment_page.payment_methods;

  const [payLaterOption, setPayLaterOption] = useState<PayMethodsEnum | null>(
    null,
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<FieldValues | null>();
  const [pay, setPay] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    handleLoading(InvoicePaymentLoadingEnum.PAY);

    if (stripePromise) {
      setPay(true);
    }

    if (
      payLaterOption
      && (!stripePromise || payLaterOption === PayMethodsEnum.PAY_LATER)
    ) {
      getStripe('LATER');
      setPay(true);
    }

    if (payLaterOption === PayMethodsEnum.PAY_LATER) {
      handleSuccess();
    }
  }, [getStripe, handleLoading, handleSuccess, payLaterOption, stripePromise]);

  return (
    <Modal
      active={active}
      setActive={setActive}
      className={classes.payment_later_options_modal}
    >
      <div className={classes.root}>
        <div>
          <HeaderModal onClick={setActive} />
          <PaymentTitle currencyIso={currencyIso} value={total} />
          <div className={classes.subtitle}>
            {translations.checkout.payment_modal.subtitle}
          </div>
          <div className={classes.pay_later_options}>
            <div className={classes.pay_later_options_title}>
              {translations.checkout.pay_later_modal.title}
            </div>
            {isMobile ? (
              <Slider {...settings} ref={sliderRef}>
                {payLaterOptions.map((option) => (
                  <PaymentLaterOption
                    key={option.name}
                    check={option.name === payLaterOption}
                    onClick={() => setPayLaterOption(option.name)}
                  >
                    {option.name === PayMethodsEnum.PAY_LATER ? (
                      <div className={classes.pay_later_option_pay_later}>
                        <p className={classes.pay_later_option_pay_later_title}>
                          {
                            translations.checkout.pay_later_modal
                              .pay_later_invoice.title
                          }
                        </p>
                        <div
                          className={
                            classes.pay_later_option_pay_later_due_title
                          }
                        >
                          {
                            translations.checkout.pay_later_modal
                              .pay_later_invoice.term
                          }
                          <p
                            className={
                              classes.pay_later_option_pay_later_due_value
                            }
                          >
                            {dateToDue(paymentTerm)}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.pay_later_option}>
                        {option.logos.map((logo) => (
                          <img src={logo} alt={logo} key={logo} />
                        ))}
                        <p>
                          {
                            paymentsMethodsTs[option.name as TPaymentsMethod]
                              .name
                          }
                        </p>
                        <p>
                          {
                            paymentsMethodsTs[option.name as TPaymentsMethod]
                              .description
                          }
                        </p>
                      </div>
                    )}
                  </PaymentLaterOption>
                ))}
              </Slider>
            ) : (
              <div className={classes.pay_later_options_list}>
                {payLaterOptions.map((option) => (
                  <PaymentLaterOption
                    key={option.name}
                    check={option.name === payLaterOption}
                    onClick={() => setPayLaterOption(option.name)}
                  >
                    {option.name === PayMethodsEnum.PAY_LATER ? (
                      <div className={classes.pay_later_option_pay_later}>
                        <p className={classes.pay_later_option_pay_later_title}>
                          {
                            translations.checkout.pay_later_modal
                              .pay_later_invoice.title
                          }
                        </p>
                        <div
                          className={
                            classes.pay_later_option_pay_later_due_title
                          }
                        >
                          {
                            translations.checkout.pay_later_modal
                              .pay_later_invoice.term
                          }
                          <p
                            className={
                              classes.pay_later_option_pay_later_due_value
                            }
                          >
                            {dateToDue(paymentTerm)}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.pay_later_option}>
                        {option.logos.map((logo) => (
                          <img src={logo} alt={logo} key={logo} />
                        ))}
                        <p>
                          {
                            paymentsMethodsTs[option.name as TPaymentsMethod]
                              .name
                          }
                        </p>
                        <p>
                          {
                            paymentsMethodsTs[option.name as TPaymentsMethod]
                              .description
                          }
                        </p>
                      </div>
                    )}
                  </PaymentLaterOption>
                ))}
              </div>
            )}
          </div>
          <PaymentLaterForm
            type={payLaterOption}
            setDisabled={setDisabled}
            setData={setDataForm}
          />
          {payLaterOption && payLaterOption !== PayMethodsEnum.PAY_LATER && (
            <div className={classes.description}>
              {translations.checkout.description_bank_redirect_pay}
            </div>
          )}
        </div>
        {payLaterOption ? (
          <FooterPaymentModal
            disabled={!disabled || !payLaterOption}
            isPayLaterInvoice={payLaterOption === PayMethodsEnum.PAY_LATER}
            onClick={handleClick}
          />
        ) : (
          <FooterPaymentModal
            onClick={isMobile ? handleClick : undefined}
            disabled
          />
        )}
      </div>
      {stripePromise && stripe?.client_secret && payLaterOption && pay && (
        <Elements stripe={stripePromise}>
          <StripeConfirm
            type={payLaterOption}
            values={dataForm}
            setPay={setPay}
            clientSecret={stripe?.client_secret}
          />
        </Elements>
      )}
    </Modal>
  );
};

export default PaymentLaterOptionsModal;

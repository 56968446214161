import React from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useDispatch } from 'react-redux';
import SettingsPage from 'components/DashboardComponents/SettingsPage';
import { useHistory } from 'react-router-dom';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { getTranslations } from 'store/dashboard/app/appActions';
import cn from 'classnames';
import { TranslationsType } from 'types/TranslationDashboardModel';
// Styles
import classes from './Languages.module.scss';

const Languages: React.FC = () => {
  const {
    currentLanguage,
    isTranslationsFetched,
  } = useTypeSelector(({ dashboard }) => dashboard.app);
  const history = useHistory();
  const dispatch = useDispatch();
  const translations = useTranslations();

  const options = [
    { value: 'en', label: translations.login.language.en },
    { value: 'fr', label: translations.login.language.fr },
    { value: 'pt', label: translations.login.language.pt },
  ];

  const handleLanguages = async (value: string) => {
    try {
      if (!isTranslationsFetched || currentLanguage !== value) {
        dispatch(getTranslations(value as keyof TranslationsType));
      }
    } finally {
      history.goBack();
    }
  };

  return (
    <SettingsPage handleClose={() => history.goBack()} title={translations.language}>
      <div
        className={classes.languages}
      >
        {options.map((option) => (
          <div
            className={cn(classes.setting_box, {
              [classes.setting_box_active]: currentLanguage === option.value,
            })}
            key={option.value}
            tabIndex={0}
            role="button"
            onClick={() => handleLanguages(option.value)}
          >
            <p className={classes.setting_box_name}>
              {option.label}
            </p>
          </div>
        ))}
      </div>
    </SettingsPage>
  );
};

export default Languages;

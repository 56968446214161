import React from 'react';
// Types
import { Items, PriceType } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Components
import Item from './Item';
import Price from './Price';
// Styles
import classes from './SideBar.module.scss';

interface Props {
  items: Items[];
  price: PriceType;
  translations: TranslationDashboardModel['sidebar_transaction']
}

const SideBar: React.FC<Props> = ({ items, price, translations }) => (
  <div className={classes.sidebar}>
    <h2 className={classes.sidebar_title}>
      {translations.items}
    </h2>
    <div className={classes.sidebar_items}>
      {items.map((item) => (
        <React.Fragment key={item.sku || item.id || item.name}>
          <Item
            key={item.sku}
            data={item}
            currencyIso={price.currencyIso}
            translations={translations.transaction_item}
          />
        </React.Fragment>
      ))}
    </div>
    <Price
      price={price}
      items={items}
      translations={translations.price}
    />
  </div>
);

export default SideBar;

import React from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
// Types
import { TabsEnum } from 'types/globalTypes';
// Components
import DirectoryTab from './DirectoryTab';
// Styles
import classes from './DirectoryTabs.module.scss';

interface Props {
  tabs: string[],
  defaultIndex: number;
}

const DirectoryTabs: React.FC<Props> = ({ tabs, defaultIndex = 0 }) => (
  <Tabs
    selectedTabPanelClassName={classes.react_tabs__tab_panel__selected}
    selectedTabClassName={classes.react_tabs__tab__selected}
    className={classes.react_tabs}
    defaultIndex={defaultIndex}
  >
    <TabList className={classes.react_tabs__tab_list}>
      {tabs.map((tab) => (
        <Tab key={tab} className={classes.react_tabs__tab}>
          {tab}
        </Tab>
      ))}
    </TabList>
    <TabPanel>
      <DirectoryTab name={TabsEnum.all} />
    </TabPanel>
    <TabPanel>
      <DirectoryTab name={TabsEnum.paidInvoice} />
    </TabPanel>
    <TabPanel>
      <DirectoryTab name={TabsEnum.unpaidInvoice} />
    </TabPanel>
    <TabPanel>
      <DirectoryTab name={TabsEnum.paidBills} />
    </TabPanel>
    <TabPanel>
      <DirectoryTab name={TabsEnum.unpaidBills} />
    </TabPanel>
    <TabPanel>
      <DirectoryTab name={TabsEnum.estimates} />
    </TabPanel>
    <TabPanel>
      <DirectoryTab name={TabsEnum.quotes} />
    </TabPanel>
    <TabPanel>
      <DirectoryTab name={TabsEnum.pendingOrders} />
    </TabPanel>
  </Tabs>
);

export default DirectoryTabs;

import React, { useEffect } from 'react';
import cn from 'classnames';
// Styles
import classes from './FormInfo.module.scss';

interface Props {
  active: boolean;
  text: string;
  setActive: (value: boolean) => void;
  direction?: 'up' | 'down'
}

const FormInfo: React.FC<Props> = ({
  active, text, setActive, direction = 'down',
}) => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timer: NodeJS.Timeout;

    if (active) {
      timer = setTimeout(() => {
        setActive(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [active, setActive]);

  return (
    <div className={cn(classes.info, {
      [classes.info_up]: direction === 'up',
      [classes.info_active]: active,
    })}
    >
      <div className={classes.info_triangle} />
      <p>{text}</p>
    </div>
  );
};

export default FormInfo;

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  NavLink, useHistory, useLocation, useParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Services
import { auth } from 'services/firebase';
// Hooks
import { useQuery } from 'hooks/useQuery';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Assets
import { ReactComponent as UserIcon } from 'assets/storefront-icons/user.svg';
// Utils
import { isEmptyObject } from 'utils/functions';
// Actions
import { getGeoInfo, getTranslations } from 'store/storefront/app/appActions';
import { getSalesChannel, getShop } from 'store/storefront/shop/shopActions';
import { getCustomerContact, signOut } from 'store/storefront/account/accountActions';
// Types
import BackBtnCircleWithArrow from 'components/Buttons/BackBtnCircleWithArrow';
import { LoadingEnum } from 'store/storefront/account/accountTypes';
// Components
import Breadcrumbs from 'components/Breadcrumbs';
import CompanyAvatar from 'components/StorefrontComponents/CompanyAvatar';
import LoadingPage from 'pages/LoadingPage';
import Menu from 'pages/Storefront/Shop/Menu';
import Footer from '../Footer';
// Styles
import classes from './AccountLayout.module.scss';

const AccountLayout: React.FC = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const { isTranslationsFetched, geoInfo } = useTypeSelector(({ storefront }) => storefront.app);
  const { contact, loading } = useTypeSelector(({ storefront }) => storefront.account);
  const { shop, currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const history = useHistory();
  const {
    goBack,
    getAccountPath,
    getAccountAddressPath,
    getAccountSecurityPath,
    getAccountOrderPath,
    goToShop,
  } = useStorefrontRoutes();
  const { isMobile } = useMobileScreen();
  const [isUser, setIsUser] = useState(false);
  const location = useLocation();
  const translations = useTranslationsStorefront();
  const query = useQuery();
  const dispatch = useDispatch();

  const accountPaths = useMemo(() => ([{
    name: translations?.account?.menu.your_address,
    path: getAccountAddressPath(id),
  }, {
    name: translations?.account?.menu.your_order,
    path: getAccountOrderPath(id),
  }, {
    name: translations?.account?.menu.login_security,
    path: getAccountSecurityPath(id),
  }]),
  [
    getAccountAddressPath,
    getAccountOrderPath,
    getAccountSecurityPath,
    id, translations]);

  const handleSignOut = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  const handleShop = useCallback(() => {
    goToShop(id, location.search);
  }, [goToShop, id, location.search]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const handleMenu = useCallback(() => {
    setMenuIsOpen(!menuIsOpen);
  }, [menuIsOpen]);

  useEffect(() => {
    if (isEmptyObject(shop) && typeof id !== 'undefined') {
      dispatch(getShop(id));
    }
  }, [id, dispatch, shop]);

  useEffect(() => {
    if (typeof id !== 'undefined' && !currentSalesChannel && shop.businessAs) {
      dispatch(getSalesChannel(id, query.get('scid')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop.businessAs]);

  useEffect(() => {
    if (!geoInfo) {
      dispatch(getGeoInfo());
    }
  }, [dispatch, geoInfo]);

  useEffect(() => {
    if (!isTranslationsFetched || !translations.shopTranslations) {
      dispatch(getTranslations('en'));
    }
  }, [isTranslationsFetched, dispatch, translations.shopTranslations]);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setIsUser(true);
      } else {
        setIsUser(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isUser) {
      history.push(`/s/${id}`);
    }
  }, [goBack, history, id, isUser]);

  useEffect(() => {
    if (!contact && shop.businessAs) {
      dispatch(getCustomerContact(id));
    }
  }, [id, shop.businessAs, contact, dispatch]);

  if (!shop.businessAs || !translations.shopTranslations) {
    return (
      <LoadingPage title={translations?.checkout?.layout.loading_text} />
    );
  }

  return (
    <div className={classes.root}>
      { loading === LoadingEnum.WAIT
      && <LoadingPage title={translations?.checkout?.layout.loading_text} />}
      <header className={classes.header}>
        <div className={classes.avatar}>
          {currentSalesChannel?.name
            && (
              <CompanyAvatar
                title={currentSalesChannel?.name}
                logoUrl={currentSalesChannel?.logoUrl}
                onClick={handleShop}
              />
            )}
        </div>
        <div className={classes.header_container}>
          <div className={classes.header_container_left}>
            {!isMobile || location.pathname === getAccountPath(id) ? (
              <button
                className={classes.hamburger}
                type="button"
                onClick={handleMenu}
              >
                <span />
                <span />
                <span />
              </button>
            ) : (
              <div>
                <BackBtnCircleWithArrow onClick={goBack} />
              </div>
            )}
            {!isMobile ? (
              <Breadcrumbs>
                <NavLink exact to={getAccountPath(id)}>
                  {translations?.account?.title}
                </NavLink>
                {accountPaths.map((account) => (
                  <NavLink
                    exact
                    key={account.path}
                    style={(isActive) => ({
                      display: isActive ? 'inline-block' : 'none',
                    })}
                    to={account.path}
                  >
                    {account.name}
                  </NavLink>
                ))}
              </Breadcrumbs>
            ) : (
              <div className={classes.header_mob_title}>
                {accountPaths.map((account) => (location.pathname === account.path ? (
                  <div key={account.name}>
                    {account.name}
                  </div>
                ) : null))}
                {!accountPaths.filter((account) => (location.pathname === account.path)).length && (
                <div className={classes.header_mob_title}>
                  {translations.account.title}
                </div>
                )}
              </div>
            )}
          </div>
          <button type="button" className={classes.sign_out} onClick={handleSignOut}>
            <UserIcon />
            {translations.account.sign_out_btn}
          </button>
        </div>
      </header>
      <div className={classes.main}>
        {children}
      </div>
      <Footer className={classes.footer} />
      <Menu isOpen={menuIsOpen} closeHandler={handleMenu} type="account" />
    </div>
  );
};

export default AccountLayout;

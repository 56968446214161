import { AgingBillsData } from 'pages/Dashboard/Home/HomeTabs/UnpaidBillsTab/UnpaidBillsTab';
import { AgingInvoiceData } from 'pages/Dashboard/Home/HomeTabs/UnpaidInvoiceTab/UnpaidInvoiceTab';
import { ExpensesChartData } from 'pages/Dashboard/Home/HomeTabs/ExpensesTab/ExpensesTab';
import { IncomeStatementData } from 'pages/Dashboard/Home/HomeTabs/IncomeStatementTab/IncomeStatementTab';
import { SalesChartData } from 'pages/Dashboard/Home/HomeTabs/SalesTab/SalesTab';
import { SalesTaxesData } from 'pages/Dashboard/Home/HomeTabs/SalesTaxesTab/SalesTaxesTab';
import { ReactSelectTime } from 'types/globalTypes';

export const REPORT_SET_REPORT_SELECT_CARDS = 'REPORT_SET_REPORT_SELECT_CARDS';
export const REPORT_SET_INVOICES = 'REPORT_SET_INVOICES';
export const REPORT_SET_CURRENT_TIME = 'REPORT_SET_CURRENT_TIME';
export const REPORT_SET_SALES_CHART = 'REPORT_SET_SALES_CHART';
export const REPORT_SET_EXPENSES_CHART = 'REPORT_SET_EXPENSES_CHART';
export const REPORT_SET_AGING_INVOICE_CHART = 'REPORT_SET_AGING_INVOICE_CHART';
export const REPORT_SET_AGING_BILLS_CHART = 'REPORT_SET_AGING_BILLS_CHART';
export const REPORT_SET_ORDERS_CHART = 'REPORT_SET_ORDERS_CHART';
export const REPORT_SET_ESTIMATES_CHART = 'REPORT_SET_ESTIMATES_CHART';
export const REPORT_SET_QUOTES_CHART = 'REPORT_SET_QUOTES_CHART';
export const REPORT_SET_SALES_TAXES_CHART = 'REPORT_SET_SALES_TAXES_CHART';
export const REPORT_SET_CLIENTS_CHART = 'REPORT_SET_CLIENTS_CHART';
export const REPORT_SET_VENDORS_CHART = 'REPORT_SET_VENDORS_CHART';
export const REPORT_SET_INCOME_STATEMENT = 'REPORT_SET_INCOME_STATEMENT';
export const REPORT_CHANGE_AVERAGE = 'REPORT_CHANGE_AVERAGE';
export const REPORT_SET_LOADING_SELECT = 'REPORT_SET_LOADING_SELECT';
export const REPORT_SET_LOADING = 'REPORT_SET_LOADING';
export interface ReportSelectCardsItem {
  [key: string]: number;
}

export enum ReportReq {
  sales = 'sales',
  expenses = 'expenses',
  agedinvoices = 'invoices-aging',
  agedbills = 'bills-aging',
  orders = 'orders',
  'sales-orders' = 'sales-orders',
  'purchase-orders' = 'purchase-orders',
  estimates = 'sales-orders',
  quotes = 'purchase-orders',
  salestaxes = 'taxes',
  clients = 'customers',
  vendors = 'suppliers',
  incomestatement = 'Income Statement'
}

export type ReportResponseAnnual = {
  interval: number;
  total: number;
}

export interface ReportSelectCards {
  [key: string]: ReportSelectCardsItem;
}

export interface DataChart {
  month: string;
  value: number;
  value1?: number;
  value2?: number;
}

export enum DataChartTypeEnum {
  ONE = 1,
  TWO = 2
}

export interface DataChartStacked {
  month: string;
  type?: DataChartTypeEnum;
  value: number;
  value1?: number;
  value2?: number;
}

export interface Invoice {
  balance: number;
  total: number;
}

interface HighLow {
  value?: number;
  month?: string;
  percentage?: number;
}

export interface ReportState {
  reportSelectCards?: ReportSelectCards;
  currentTime: ReactSelectTime;
  loadingSelect: boolean;
  loading: boolean;
  monthlyAverage?: {
    value?: number;
    percentage?: number;
  }
  salesChart: {
    data: SalesChartData[]
    highestMonth?: HighLow
    lowestMonth?: HighLow
  };
  expensesChart: {
    data: ExpensesChartData[];
    highestMonth?: HighLow
    lowestMonth?: HighLow
  }
  agingInvoiceChart: {
    data: AgingInvoiceData[];
    highestMonth?: HighLow
    lowestMonth?: HighLow
  };
  agingBillsChart: {
    data: AgingBillsData[];
    highestMonth?: HighLow
    lowestMonth?: HighLow
  };
  ordersChart: {
    data: DataChart[];
    highestMonth?: HighLow
    lowestMonth?: HighLow
  }
  estimatesChart: {
    data: DataChartStacked[];
    highestMonth?: HighLow
    lowestMonth?: HighLow
  }
  quotesChart: {
    data: DataChartStacked[];
    highestMonth?: HighLow
    lowestMonth?: HighLow
  }
  salesTaxesChart: {
    data: SalesTaxesData[];
    highestMonth?: HighLow
    lowestMonth?: HighLow
  }
  clientsChart: {
    data: DataChart[];
    highestMonth?: HighLow
    lowestMonth?: HighLow
  }
  vendorsChart: {
    data: DataChart[];
    highestMonth?: HighLow
    lowestMonth?: HighLow
  }
  incomeStatement?: IncomeStatementData;
}

interface ReportSetIncomeStatementAction {
  type: typeof REPORT_SET_INCOME_STATEMENT;
  payload: IncomeStatementData;
}
interface ReportSetExpensesChartAction {
  type: typeof REPORT_SET_EXPENSES_CHART;
  payload: ExpensesChartData[];
}
interface ReportSetAgingBillsChartAction {
  type: typeof REPORT_SET_AGING_BILLS_CHART;
  payload: AgingBillsData[];
}
interface ReportSetAgingInvoiceChartAction {
  type: typeof REPORT_SET_AGING_INVOICE_CHART;
  payload: AgingInvoiceData[];
}

interface ReportSetOrdersChartAction {
  type: typeof REPORT_SET_ORDERS_CHART;
  payload: DataChart[];
}

interface ReportSetEstimatesChartAction {
  type: typeof REPORT_SET_ESTIMATES_CHART;
  payload: DataChartStacked[];
}

interface ReportSetQuotesChartAction {
  type: typeof REPORT_SET_QUOTES_CHART;
  payload: DataChartStacked[];
}

interface ReportSetSalesChartAction {
  type: typeof REPORT_SET_SALES_CHART;
  payload: SalesChartData[];
}
interface ReportSetSalesTaxesChartAction {
  type: typeof REPORT_SET_SALES_TAXES_CHART;
  payload: SalesTaxesData[];
}

interface ReportSetClientsChartAction {
  type: typeof REPORT_SET_CLIENTS_CHART;
  payload: DataChart[];
}

interface ReportSetVendorsChartAction {
  type: typeof REPORT_SET_VENDORS_CHART;
  payload: DataChart[];
}
interface ReportSetReportSelectCardAction {
  type: typeof REPORT_SET_REPORT_SELECT_CARDS;
  payload: ReportSelectCards;
}

interface ReportSetInvoicesAction {
  type: typeof REPORT_SET_INVOICES;
  payload: Invoice[];
}
interface ReportSetCurrentTimeAction {
  type: typeof REPORT_SET_CURRENT_TIME;
  payload: ReactSelectTime;
}

interface ChangeAverageMonth {
  type: typeof REPORT_CHANGE_AVERAGE;
  payload: {
    selectedMonth: Record<string, string | number | boolean> | null
    datasets: DataChart[] | DataChartStacked[] | null;
  }
}
interface SetLoadingSelectAction {
  type: typeof REPORT_SET_LOADING_SELECT;
  payload: boolean;
}

interface SetLoadingAction {
  type: typeof REPORT_SET_LOADING;
  payload: boolean;
}

export type ReportActions =
  | ReportSetReportSelectCardAction
  | ReportSetInvoicesAction
  | ReportSetCurrentTimeAction
  | ReportSetSalesChartAction
  | ReportSetExpensesChartAction
  | ReportSetAgingInvoiceChartAction
  | ReportSetAgingBillsChartAction
  | ReportSetSalesTaxesChartAction
  | ReportSetIncomeStatementAction
  | ChangeAverageMonth
  | ReportSetOrdersChartAction
  | ReportSetEstimatesChartAction
  | ReportSetQuotesChartAction
  | ReportSetClientsChartAction
  | ReportSetVendorsChartAction
  | SetLoadingSelectAction
  | SetLoadingAction;

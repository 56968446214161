import React from 'react';
import { NavLink } from 'react-router-dom';
import { DashboardRoutePath } from 'router/Routes/Dashboard';
// Hooks
import { useUser } from 'hooks/useUser';
// Types
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import { IOrganisationData } from 'types/globalTypes';
// Styles
import classes from './NavbarDropdown.module.scss';

interface Props {
  translation: TranslationDashboardModel['navbar'];
  switchOrganisation: (org: IOrganisationData) => void;
}

const NavbarDropdown: React.FC<Props> = ({ translation }) => {
  const user = useUser();
  const userHasOrganisations = user?.organisations.length === 0;
  const currentOrganisation = user?.currentOrganisation;

  return (
    <NavLink
      exact
      aria-current="true"
      to={DashboardRoutePath.ORGANIZATION}
      className={classes.organization}
    >
      <div className={classes.organization_header}>
        <div className={classes.organization_avatar}>
          {currentOrganisation?.name.charAt(0).toLocaleUpperCase()}
        </div>
        <p className={classes.organization_name}>
          {userHasOrganisations
            ? translation.no_organisations
            : currentOrganisation?.name.toLocaleUpperCase()}
        </p>
      </div>
    </NavLink>
  );
};

export default NavbarDropdown;

import React, { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Components
import EyeButton from 'components/Buttons/EyeButton';
import FormError from 'components/StorefrontComponents/FormError';
import FormInfo from 'components/StorefrontComponents/FormInfo';
// Styles
import classes from './InputCheckPassword.module.scss';

interface Props {
  register: UseFormReturn['register']
  error?: string;
  watch: UseFormReturn['watch']
}

const InputCheckPassword: React.FC<Props> = ({
  register, error, watch,
}) => {
  const [info, setInfo] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const { checkout: { card_form } } = useTranslationsStorefront();
  const password = watch('password');
  const checkPassword = watch('repassword');

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const onFocus = useCallback(() => {
    setInfo(true);
  }, []);

  const onBlur = useCallback(() => {
    setInfo(false);
  }, []);

  return (
    <div className={classes.input_wrapper}>
      <input
        type={showPassword ? 'password' : 'text'}
        {...register('repassword', {
          required: true,
          validate: (val: string) => password === val,
        })}
        onFocus={onFocus}
        onBlur={onBlur}
        className={classes.input}
        placeholder={card_form.repassword_placeholder}
        autoComplete="new-password"
      />
      <EyeButton
        active={!showPassword}
        setActive={handleShowPassword}
        disabled={!checkPassword}
      />
      <FormError
        active={error === 'validate'}
        text={card_form.repassword_error}
        direction="up"
      />
      <FormInfo
        active={info}
        setActive={setInfo}
        text={card_form.repassword_info}
        direction="up"
      />
    </div>
  );
};

export default InputCheckPassword;

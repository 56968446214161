import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslations } from 'hooks/useTranslations';
// Actions
import { getCategories, getProducts, getProductsSummary } from 'store/dashboard/catalog/items/itemsActions';
import { setNotificationAction } from 'store/dashboard/app/appActions';
// Components
import DashboardLayout from 'layouts/DashboardLayout';
import Notifications from 'layouts/DashboardLayout/Notifications';
import ItemsSummary from './ItemsSummary';
import ItemsTable from './ItemsTable';

const Catalog: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useTypeSelector((state) => state.auth);
  const translations = useTranslations();
  const ts = translations.catalog;
  const { notification } = useTypeSelector(({ dashboard }) => dashboard.app);
  const {
    products,
    summary,
    categories,
  } = useTypeSelector((state) => state.dashboard.catalog.items);

  useEffect(() => {
    if (user?.currentOrganisation?.id && !products.data) {
      dispatch(getProducts(user.currentOrganisation.id));
    }
  }, [user?.currentOrganisation?.id, dispatch, products.data]);

  useEffect(() => {
    if (user?.currentOrganisation?.id && summary === null) {
      dispatch(getProductsSummary(user.currentOrganisation.id));
    }
  }, [dispatch, summary, user?.currentOrganisation?.id]);

  useEffect(() => {
    if (!categories && user?.currentOrganisation?.id) {
      dispatch(getCategories(user?.currentOrganisation?.id));
    }
  }, [categories, dispatch, user?.currentOrganisation?.id]);

  const closeNotificationModal = () => {
    dispatch(setNotificationAction(null));
  };

  return (
    <DashboardLayout>
      {summary && (
        <ItemsSummary
          summary={summary}
          translations={ts.summary}
        />
      )}
      <ItemsTable />
      <Notifications
        active={!!notification?.includes('{delete}')}
        onClick={closeNotificationModal}
        btnTitle={ts.notification.button_title}
        title={ts.notification.title}
        text={ts.notification.text.replace('{CatalogItemName}', `${notification?.replace('{delete}', ' ')}`)}
        variant="two"
      />
    </DashboardLayout>
  );
};

export default Catalog;

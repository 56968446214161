import React from 'react';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Types
import { SalesChannel } from 'store/storefront/shop/shopTypes';
// Utils
import { StoreFrontFunctions } from 'utils/storefront/StoreFrontFunctions';
// styles
import { IOrganisationData } from 'types/globalTypes';
import classes from './Footer.module.scss';

interface Props {
  location?: SalesChannel | null;
  organisation: IOrganisationData;
}

const Footer: React.FC<Props> = ({ location, organisation }) => {
  const translations = useTranslationsStorefront();
  const fullMobileNumber = `${organisation.country.mobileIDD} ${organisation.phoneNumber}`;

  if (!location) {
    return (
      <div className={classes.footer}>
        <div className={classes.footer_left}>
          <p className={classes.footer_title}>
            {translations.documents.footer.title}
          </p>
          <div className={classes.footer_address}>
            <p>{organisation?.name}</p>
            <p>{organisation?.address.line1}</p>
            <p>{organisation?.address.line2}</p>
            <p>
              {`${organisation.country.name}
              ${organisation?.address.postalCode}
              ${organisation.country.name}`}
            </p>
          </div>
        </div>
        <div className={classes.footer_right}>
          <a href={`mailto:${organisation.email}`}>
            {organisation.email}
          </a>
          <a href={`tel: ${fullMobileNumber}`}>
            {fullMobileNumber}
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.footer}>
      <div className={classes.footer_left}>
        <p className={classes.footer_title}>
          {translations.documents.footer.title}
        </p>
        <div className={classes.footer_address}>
          <p>{location?.name}</p>
          <p>{location?.address.line1}</p>
          <p>{location?.address.line2}</p>
          <p>
            {`${location?.address.country} ${location?.address.postalCode} ${location?.address.country}`}
          </p>
        </div>
      </div>
      <div className={classes.footer_right}>
        <a href={`mailto:${location?.customerService?.email}`}>
          {location?.customerService?.email}
        </a>
        <a href={`tel: ${location?.customerService?.phoneNumber}`}>
          {location?.customerService?.phoneNumber}
        </a>
        <div className={classes.socials}>
          {location
            && StoreFrontFunctions.getContactSellerLinks(
              location?.customerService,
            ).map(
              (social) => social && (
              <a
                href={social.link}
                key={social.link}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.social}
              >
                <social.image />
              </a>
              ),
            )}
        </div>
      </div>
    </div>
  );
};

export default Footer;

import { PaymentIntentResult, Stripe, StripeEpsBankElement } from '@stripe/stripe-js';

export const epsPay = async (
  clientSecret: string,
  stripe: Stripe,
  bank: StripeEpsBankElement,
  name: string,
): Promise<PaymentIntentResult> => {
  const paymentIntentResult = await stripe.confirmEpsPayment(clientSecret, {
    payment_method: {
      eps: bank,
      billing_details: {
        name,
      },
    },
    // Return URL where the customer should be redirected after the authorization.
    return_url: window.location.href,
  });

  return paymentIntentResult;
};

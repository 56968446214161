import { useTranslations } from 'hooks/useTranslations';
import React from 'react';
import Card from 'components/Card';
import { useTypeSelector } from 'hooks/useTypeSelector';
import CardSelect from 'components/CardSelect/CardSelect';
import { ReactSelectTime } from 'types/globalTypes';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import { getYear } from 'utils/dates';
import Graph from 'components/Graph';
// Styles
import classes from './MobileCustomerTab.module.scss';

export interface AgingBillsData {
  month: string;
  type: 1 | 2;
  value: number;
}

interface Props {
  total: number;
  time: ReactSelectTime;
  setTime: React.Dispatch<React.SetStateAction<ReactSelectTime>>
}

const MobileClientsTab: React.FC<Props> = ({ total, time, setTime }) => {
  const translations = useTranslations();
  const ts = translations.home.report;
  const { clientsChart, reportSelectCards, monthlyAverage } = useTypeSelector(
    ({ dashboard }) => dashboard.report,
  );

  return (
    <>
      <div className={classes.content_container}>
        <div className={classes.chart_container}>
          <div className={classes.chart}>
            <div className={classes.chart_title}>
              {!total ? (
                <div className={classes.chart_years}>
                  {`${getYear()} ${ts.year_to_date}`}
                </div>
              ) : (
                <div className={classes.chart_years}>
                  {`${getYear()} ${ts.ytd}`}
                </div>
              )}
              <div className={classes.chart_text}>
                {ts.tabs.re_customers}
              </div>
            </div>
            {!total ? (
              <div className={classes.nodata}>
                <div className={classes.lines}>
                  <div className={classes.line} />
                  <div className={classes.line} />
                  <div className={classes.line} />
                </div>
                <p>{ts.no_data_available_yet}</p>
              </div>
            ) : (
              <Graph datasets={clientsChart.data} color="rgba(25, 28, 35, 0.8)" name={ts.customers} />
            )}
          </div>
        </div>
        <div className={classes.monthly_average}>
          <CardMonthlyAverage
            title={ts.clients.month}
            value={monthlyAverage?.value}
            change={monthlyAverage?.percentage}
            unit={ts.customers}
          />
        </div>
        <div className={classes.high}>
          <Card
            data={{
              title: ts.highest_month,
              label: clientsChart.highestMonth?.month,
              value: clientsChart.highestMonth?.value,
              second: ts.high,
              change: clientsChart.highestMonth?.percentage,
            }}
            unit={ts.customers}
          />
        </div>
        <div className={classes.low}>
          <Card
            data={{
              title: ts.lowest_month,
              label: clientsChart.lowestMonth?.month,
              value: clientsChart.lowestMonth?.value,
              second: ts.low,
              change: clientsChart.lowestMonth?.percentage,
            }}
            unit={ts.customers}
          />
        </div>
        <div className={classes.total}>
          <CardSelect
            data={{
              title: ts.all,
              label: ts.per_customer,
              value: reportSelectCards?.customers?.entrytotal,
              change: reportSelectCards?.customers?.change || 0,
            }}
            time={time}
            setTime={setTime}
            unit={ts.tabs.re_orders.toLowerCase()}
          />
        </div>
        <div className={classes.paid}>
          <CardSelect
            data={{
              title: ts.clients.average,
              label: ts.clients.order_customer,
              value: reportSelectCards?.orders?.entrytotal,
              change: reportSelectCards?.orders?.change || 0,
            }}
            time={time}
            setTime={setTime}
            unit={ts.tabs.re_orders.toLowerCase()}
          />
        </div>
        <div className={classes.unpaid}>
          <CardSelect
            data={{
              title: ts.clients.average,
              label: ts.clients.sales_customer,
              value: reportSelectCards?.sales?.entrytotal,
              change: reportSelectCards?.sales?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
      </div>
    </>
  );
};

export default MobileClientsTab;

// constants
import { STOREFRONT_MENU_LINKS } from 'constants/menuLinks';
// Fallback translations
import en from 'translations/storefront/en.json';

import {
  APP_SET_MENULINKS,
  APP_SET_MOBILE_MENU_OPEN,
  APP_SET_NOTIFICATION,
  APP_SET_SHARE_OPEN,
  APP_SET_TRANSLATIONS_FETCHED,
  APP_SET_TRANSLATIONS,
  AppState,
  AppActions,
  APP_SET_CURRENT_LANGUAGE,
  APP_SET_ACTIVE_TRANSLATION,
  APP_SET_GEO_INFO,
} from './appTypes';

const initialState: AppState = {
  menuLinks: STOREFRONT_MENU_LINKS,
  translations: null,
  isTranslationsFetched: false,
  isMobileMenuOpen: false,
  isShareOpen: false,
  notification: null,
  currentLanguage: 'en',
  activeTranslation: en,
  geoInfo: null,
};

export const appReducer = (
  state: AppState = initialState,
  action: AppActions,
): AppState => {
  switch (action.type) {
    case APP_SET_MENULINKS:
      return {
        ...state,
        menuLinks: action.payload,
      };
    case APP_SET_MOBILE_MENU_OPEN:
      return {
        ...state,
        isMobileMenuOpen: action.payload,
      };
    case APP_SET_SHARE_OPEN:
      return {
        ...state,
        isShareOpen: action.payload,
      };
    case APP_SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case APP_SET_TRANSLATIONS_FETCHED:
      return {
        ...state,
        isTranslationsFetched: action.payload,
      };
    case APP_SET_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload,
      };
    case APP_SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case APP_SET_ACTIVE_TRANSLATION:
      return {
        ...state,
        activeTranslation: action.payload,
      };
    case APP_SET_GEO_INFO:
      return {
        ...state,
        geoInfo: action.payload,
      };
    default:
      return state;
  }
};

import CustomSpinner from 'components/CustomSpinner';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
import TransactionTabContent from 'pages/Dashboard/Transaction/TransactionTabContent';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getReportSelectCards } from 'store/dashboard/report/reportActions';
import {
  DocumentsGetDataAction,
  getTabDataTransaction,
} from 'store/dashboard/transaction/transactionActions';
import { getNameSummaryOfYearEnum, TransactionNameTabDataEnum, TransactionTabsEnum } from 'store/dashboard/transaction/transactionTypes';
import { ReactSelectTime } from 'types/globalTypes';
import { percent } from 'utils/price';
// Styles
import classes from './TransactionTab.module.scss';

interface TransactionTabProps {
  name: TransactionTabsEnum;
}

const TransactionTab: React.FC<TransactionTabProps> = ({ name }) => {
  const { loading, documents } = useTypeSelector(
    ({ transaction }) => transaction,
  );
  const { reportSelectCards } = useTypeSelector(({ dashboard }) => dashboard.report);
  const dispatch = useDispatch();
  const user = useUser();
  const [pages, setPages] = useState<number>(0);
  const { isTablet } = useMobileScreen();
  const translations = useTranslations();
  const ts = translations.transactions;
  const tsGraphName = ts.graph_name;

  const transactionCardData = useMemo(() => {
    if (name === TransactionTabsEnum.SALES) {
      return [{
        name: isTablet ? tsGraphName.all_sales : tsGraphName.total_sales,
        percent: percent(
          reportSelectCards?.revenue?.entrytotal,
          reportSelectCards?.revenue?.entrytotal,
        ),
        total: reportSelectCards?.revenue?.entrytotal,
      },
      {
        name: tsGraphName.paid_invoices,
        percent: percent(
          reportSelectCards?.paid_you?.entrytotal,
          reportSelectCards?.revenue?.entrytotal,
        ),
        total: reportSelectCards?.paid_you?.entrytotal,
      },
      {
        name: tsGraphName.unpaid_invoices,
        percent: percent(
          reportSelectCards?.owes_you?.entrytotal,
          reportSelectCards?.revenue?.entrytotal,
        ),
        total: reportSelectCards?.owes_you?.entrytotal,
      }];
    }
    if (name === TransactionTabsEnum.EXPENSES) {
      return [{
        name: isTablet
          ? tsGraphName.all_expenses
          : tsGraphName.total_expenses,
        percent: percent(
          reportSelectCards?.expenses?.entrytotal,
          reportSelectCards?.expenses?.entrytotal,
        ),
        total: reportSelectCards?.expenses?.entrytotal,
      },
      {
        name: tsGraphName.paid_bills,
        percent: percent(
          reportSelectCards?.you_paid?.entrytotal,
          reportSelectCards?.expenses?.entrytotal,
        ),
        total: reportSelectCards?.you_paid?.entrytotal,
      },
      {
        name: tsGraphName.unpaid_bills,
        percent: percent(
          reportSelectCards?.you_owe?.entrytotal,
          reportSelectCards?.expenses?.entrytotal,
        ),
        total: reportSelectCards?.you_owe?.entrytotal,
      }];
    }
    if (name === TransactionTabsEnum.UNPAID_INVOICES) {
      const totalUnpaidInvoice = (reportSelectCards?.aging30?.totalamount || 0) + (reportSelectCards?.['aging30+']?.totalamount || 0);
      return [{
        name: isTablet
          ? tsGraphName.all_unpaid_invoices
          : tsGraphName.total_unpaid_invoices,
        percent: percent(
          totalUnpaidInvoice,
          totalUnpaidInvoice,
        ),
        total: totalUnpaidInvoice,
      },
      {
        name: tsGraphName.past_due_first,
        percent: percent(
          reportSelectCards?.aging30?.totalamount,
          totalUnpaidInvoice,
        ),
        total: reportSelectCards?.aging30?.totalamount,
      },
      {
        name: tsGraphName.past_due_second,
        percent: percent(
          reportSelectCards?.['aging30+']?.totalamount,
          totalUnpaidInvoice,
        ),
        total: reportSelectCards?.['aging30+']?.totalamount,
      }];
    }
    if (name === TransactionTabsEnum.UNPAID_BILLS) {
      const totalUnpaidBills = (reportSelectCards?.aging30?.totalamount || 0) + (reportSelectCards?.['aging30+']?.totalamount || 0);
      return [{
        name: isTablet
          ? tsGraphName.all_unpaid_bills
          : tsGraphName.total_unpaid_bills,
        percent: percent(
          totalUnpaidBills,
          totalUnpaidBills,
        ),
        total: totalUnpaidBills,
      },
      {
        name: tsGraphName.past_due_first,
        percent: percent(
          reportSelectCards?.aging30?.totalamount,
          totalUnpaidBills,
        ),
        total: reportSelectCards?.aging30?.totalamount,
      },
      {
        name: tsGraphName.past_due_second,
        percent: percent(
          reportSelectCards?.['aging30+']?.totalamount,
          totalUnpaidBills,
        ),
        total: reportSelectCards?.['aging30+']?.totalamount,
      }];
    }

    if (name === TransactionTabsEnum.ESTIMATES) {
      return [{
        name: isTablet ? tsGraphName.all_estimates : tsGraphName.total_estimates,
        percent: percent(
          reportSelectCards?.all?.entrytotal,
          reportSelectCards?.all?.entrytotal,
        ),
        total: reportSelectCards?.all?.entrytotal,
      },
      {
        name: tsGraphName.accepted,
        percent: percent(
          reportSelectCards?.accepted?.entrytotal,
          reportSelectCards?.all?.entrytotal,
        ),
        total: reportSelectCards?.accepted?.entrytotal,
      },
      {
        name: tsGraphName.pending,
        percent: percent(
          reportSelectCards?.sent?.entrytotal,
          reportSelectCards?.all?.entrytotal,
        ),
        total: reportSelectCards?.sent?.entrytotal,
      }];
    }

    if (name === TransactionTabsEnum.QUOTES) {
      return [{
        name: isTablet ? tsGraphName.all_quotes : tsGraphName.total_quote_requests,
        percent: percent(
          reportSelectCards?.all?.entrytotal,
          reportSelectCards?.all?.entrytotal,
        ),
        total: reportSelectCards?.all?.entrytotal,
      },
      {
        name: tsGraphName.priced,
        percent: percent(
          reportSelectCards?.responded?.entrytotal,
          reportSelectCards?.all?.entrytotal,
        ),
        total: reportSelectCards?.responded?.entrytotal,
      },
      {
        name: tsGraphName.pending,
        percent: percent(
          reportSelectCards?.sent?.entrytotal,
          reportSelectCards?.all?.entrytotal,
        ),
        total: reportSelectCards?.sent?.entrytotal,
      }];
    }

    return null;
  }, [isTablet, name, reportSelectCards, tsGraphName]);

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(DocumentsGetDataAction([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.currentOrganisation]);

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(
        getTabDataTransaction(user.currentOrganisation.id,
          TransactionNameTabDataEnum[name], pages),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.currentOrganisation, pages, name]);

  useEffect(() => {
    if (!user?.currentOrganisation?.id) {
      return;
    }

    dispatch(getReportSelectCards(
      user.currentOrganisation.id,
      ReactSelectTime.lastYear,
      getNameSummaryOfYearEnum[name],
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.currentOrganisation?.id]);

  return (
    <div className={classes.tab}>
      {loading && pages === 0 ? (
        <div className={classes.loading}>
          <CustomSpinner variant="two" />
        </div>
      ) : (
        <TransactionTabContent
          transactionCardData={transactionCardData}
          items={documents}
          setPages={setPages}
          pages={pages}
          name={name}
        />
      )}
    </div>
  );
};

export default TransactionTab;

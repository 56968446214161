import React, { useEffect, useRef } from 'react';
import {
  arc, DefaultArcObject, interpolate, pie, select,
} from 'd3';
// styles
import classes from './DonutChart.module.scss';

interface Props {
  percentage?: number;
}

type Data = {
  value: number;
}

const DonutChart: React.FC<Props> = ({ percentage }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tau = (Math.PI / 180);

  useEffect(() => {
    const data = [
      { value: Number(percentage) },
    ];

    if (!svgRef || !wrapperRef.current) {
      return;
    }

    const svg = select(svgRef.current);
    const { width, height } = wrapperRef.current.getBoundingClientRect();
    const colors = ['#6B59ED'];

    function arcTween(newAngle: number) {
      return (d: DefaultArcObject) => {
        const inter = interpolate(d.endAngle, newAngle);
        return (t: number) => {
          d.endAngle = inter(t);
          return arcGenerator(d);
        };
      };
    }

    select(svgRef.current).select('svg').remove();

    const fullArc = arc<Data | DefaultArcObject>().innerRadius(100)
      .innerRadius(width - 5)
      .outerRadius(width)
      .startAngle(() => 0)
      .endAngle(() => 360);

    const arcGenerator = arc<Data | DefaultArcObject>()
      .innerRadius(width - 5)
      .outerRadius(width)
      .startAngle(() => 0);

    const pieGenerator = pie<Data>()
      .startAngle(-2 * Math.PI)
      .value((d) => d.value);

    svg
      .attr('height', height)
      .attr('width', width)
      .append('svg')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('class', 'chart1')
      .attr('height', height)
      .attr('width', width)
      .attr('viewBox', `0 0 ${width * 2} ${height * 2}`);

    if (percentage !== 0) {
      svg
        .selectAll('.chart1')
        .append('g')
        .attr('transform', `translate(${width}, ${height})`)
        .selectAll()
        .data(pieGenerator(data))
        .enter()
        .append('path')
        .attr('d', arcGenerator)
        .style('fill', (d, i) => colors[i % data.length])
        .transition()
        .duration(1000)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .attrTween('d', arcTween(((Number(percentage) * 360) / 100) * tau) as any);
    }

    svg
      .selectAll('.chart1')
      .append('g')
      .attr('transform', `translate(${width}, ${height})`)
      .selectAll()
      .data(pieGenerator(data))
      .enter()
      .append('path')
      .attr('d', fullArc)
      .style('fill', 'RGBA(107, 105, 116, 0.1)');
  }, [percentage, tau]);

  return (
    <div ref={wrapperRef} className={classes.graph_wrapper}>
      <svg ref={svgRef}> </svg>
    </div>
  );
};

export default DonutChart;

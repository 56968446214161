import React, { useMemo } from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { SalesChannel } from 'store/storefront/shop/shopTypes';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Assets
import { ReactComponent as Check } from 'assets/storefront-icons/check-circle.svg';
// Components
import { capitalizeFirstLetter } from 'utils/strings';
import { useTypeSelector } from 'hooks/useTypeSelector';
import ShareBar from '../ShareBar';
// Styles
import classes from './DetailsDeliveryOptions.module.scss';

interface Props {
  shopName?: string;
  itemName?: string;
  itemDescription?: string;
  currentSalesChannel?: SalesChannel | null;
}

const DetailsDeliveryOptions: React.FC<Props> = ({
  shopName = '',
  itemName,
  itemDescription,
  currentSalesChannel,
}) => {
  const translations = useTranslationsStorefront();
  const tabs = useMemo(() => ([
    translations.checkout.items_details.details_label,
    translations.menu_links.shipping,
  ]), [translations.checkout.items_details.details_label, translations.menu_links.shipping]);

  const { geoInfo } = useTypeSelector(({ storefront }) => storefront.app);

  const shippingDelivery = currentSalesChannel?.fulfilment?.shipping?.countries?.find(
    (country) => country.iso === geoInfo?.countryCode,
  );

  const pickupDelivery = currentSalesChannel?.fulfilment?.pickup?.locations?.find(
    (location) => location.country.iso === geoInfo?.countryCode,
  );

  const delivery = currentSalesChannel?.fulfilment?.delivery?.serviceAreas?.find(
    (area) => area.country.iso === geoInfo?.countryCode,
  );

  return (
    <Tabs
      selectedTabClassName={classes.react_tabs__tab__selected}
      className={classes.react_tabs}
    >
      <TabList className={classes.react_tabs__tab_list}>
        {tabs.map((tab) => (
          <Tab key={tab} className={classes.react_tabs__tab}>
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanel className={classes.react_tabs__tab_panel}>
        <p>
          {itemDescription}
        </p>
        <ShareBar shopName={shopName} itemName={itemName || ''} itemURL={window.location.href} />

      </TabPanel>
      <TabPanel className={classes.react_tabs__tab_panel}>
        <div className={classes.delivery_options}>
          {shippingDelivery && (
            <div className={classes.delivery_option}>
              <Check />
              <p>
                {translations.checkout.price_details.shipping_label}
              </p>
            </div>
          )}

          {pickupDelivery && (
            <div className={classes.delivery_option}>
              <Check />
              <p>
                {translations.checkout.delivery_method.pickup.title}
              </p>
            </div>
          )}

          {delivery && (
            <div className={classes.delivery_option}>
              <Check />
              <p>
                {capitalizeFirstLetter(translations.shipping_page.delivery.title)}
              </p>
            </div>
          )}
        </div>
      </TabPanel>
    </Tabs>
  );
};

export default DetailsDeliveryOptions;

export const DASHBOARD_MENU_LINKS = [
  {
    title: 'dashboard',
    route: '/dashboard',
    icon: 'home',
  },
  {
    title: 'transactions',
    route: '/dashboard/transactions',
    icon: 'transactions',
  },
  {
    title: 'catalog',
    route: '/dashboard/catalog',
    icon: 'catalog',
  },
  {
    title: 'orders',
    route: '/dashboard/orders',
    icon: 'orders',
  },
  {
    title: 'directory',
    route: '/dashboard/directory',
    icon: 'directory',
  },
];

export const PROFILE_MENU_LINKS = {
  title: 'Profile',
  route: '/dashboard/profile',
  icon: 'user',
};

export const STOREFRONT_MENU_LINKS = [
  {
    title: 'About',
    route: '/s/about',
    icon: 'share',
  },
];

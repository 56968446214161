import { useState, useEffect } from 'react';

/**
 * Custom hook to find window size
 * Uses to dectect is mobile screen or not
 * Mobile screen sets to 772px
 */
type IMobileScreen = {
  isTablet: boolean;
  isMobile: boolean,
  isDesktop: boolean,
  windowWidth: number | undefined
}

export const useMobileScreen = (): IMobileScreen => {
  const [isTablet, setIsTablet] = useState<boolean>(window.innerWidth <= 772);
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 450);
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth <= 1024);

  const widthHandler = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    widthHandler();

    window.addEventListener('resize', widthHandler);

    return () => {
      window.removeEventListener('resize', widthHandler);
    };
  }, [isTablet, isMobile]);

  useEffect(() => {
    if (windowWidth) {
      if (windowWidth <= 772) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    }
  }, [windowWidth]);

  useEffect(() => {
    if (windowWidth) {
      if (windowWidth <= 1024) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    }
  }, [windowWidth]);

  useEffect(() => {
    if (windowWidth) {
      if (windowWidth <= 450) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [windowWidth]);

  return {
    isTablet, isMobile, isDesktop, windowWidth,
  };
};

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUser } from 'hooks/useUser';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { getMoreOrders, getOrders } from 'store/dashboard/orders/orderActions';
// Types
import { OrderTabsEnum } from 'store/dashboard/orders/orderTypes';
// Components
import CustomSpinner from 'components/CustomSpinner';
import OrderTable from './OrderTable';
// Styles
import classes from './OrderTab.module.scss';
import OrderSummary from './OrderSummary';

interface OrderTabProps {
  name: OrderTabsEnum;
}

const OrderTab: React.FC<OrderTabProps> = ({ name }) => {
  const [page, setPage] = useState<number>(1);
  const { loading, orders, summary } = useTypeSelector(
    ({ order }) => order,
  );
  const dispatch = useDispatch();
  const user = useUser();

  const handleMoreClicked = useCallback(() => {
    if (!user?.currentOrganisation?.id) {
      return;
    }

    setPage((prev) => prev + 1);
    dispatch(getMoreOrders(name, user.currentOrganisation.id, page, orders));
  }, [dispatch, page, user, orders, name]);

  useEffect(() => {
    if (!user?.currentOrganisation?.id) {
      return;
    }

    dispatch(getOrders(name, user.currentOrganisation.id));
  }, [user, name, dispatch]);

  return (
    <div className={classes.tab}>
      {(loading && !orders.length) || !summary
        ? (
          <div className={classes.loading}>
            <CustomSpinner variant="two" />
          </div>
        ) : (
          <>
            <OrderSummary summary={summary} />
            <OrderTable
              items={orders}
              handleMoreOrders={handleMoreClicked}
              pages={page}
            />
          </>
        )}
    </div>
  );
};

export default OrderTab;

import { CalcHighLowMonth } from 'utils/functions';
import { calcAverageMonth, calcAverageMonthStackedChart } from 'utils/price';
import {
  DirectoryActions,
  DirectoryDocuments,
  DirectoryState,
  DIRECTORY_ADD_DOCUMENTS_ACTION,
  DIRECTORY_CLEAR_LIST,
  DIRECTORY_DETAILS_END_PAGE,
  DIRECTORY_END_PAGE,
  DIRECTORY_SET_ADD_LIST,
  DIRECTORY_SET_AVERAGE_DATA,
  DIRECTORY_SET_AVERAGE_MONTH,
  DIRECTORY_SET_BILLS,
  DIRECTORY_SET_CHART_DATA,
  DIRECTORY_SET_DOCUMENTS,
  DIRECTORY_SET_INVOICES,
  DIRECTORY_SET_LIST,
  DIRECTORY_SET_LOADING,
  DIRECTORY_SET_PROFILE,
  DIRECTORY_SET_SUMMARY,
  DIRECTORY_SET_TYPE_CONTACT,
} from './directoryTypes';

const initialState: DirectoryState = {
  contacts: [],
  loading: '',
  endPage: false,
  summary: {
    total: 0,
    customers: 0,
    suppliers: 0,
  },
  details: {
    averageMonth: null,
    summary: {
      customers: {
        chartData: [],
        sum: 0,
      },
      suppliers: {
        chartData: [],
        sum: 0,
      },
    },
    average: {
      customers: [],
      suppliers: [],
    },
  },
  documents: {
    pendingOrders: {
      page: 1,
      data: null,
    },
    all: {
      page: 1,
      data: null,
    },
    paidInvoice: {
      page: 1,
      data: null,
    },
    unpaidInvoice: {
      page: 1,
      data: null,
    },
    unpaidBills: {
      page: 1,
      data: null,
    },
    paidBills: {
      page: 1,
      data: null,
    },
    estimates: {
      page: 1,
      data: null,
    },
    quotes: {
      page: 1,
      data: null,
    },
    processedOrders: {
      page: 1,
      data: null,
    },
    unprocessedOrders: {
      page: 1,
      data: null,
    },
    canceledOrders: {
      page: 1,
    },
  },
};

export const DirectoryReducer = (
  state: DirectoryState = initialState,
  action: DirectoryActions,
): DirectoryState => {
  switch (action.type) {
    case DIRECTORY_SET_LIST:
      return {
        ...state,
        contacts: action.payload,
      };
    case DIRECTORY_SET_ADD_LIST:
      return {
        ...state,
        contacts: [...state.contacts, ...action.payload],
      };
    case DIRECTORY_SET_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case DIRECTORY_END_PAGE:
      return {
        ...state,
        endPage: action.payload,
      };
    case DIRECTORY_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DIRECTORY_SET_TYPE_CONTACT:
      return {
        ...state,
        details: {
          ...state.details,
          type: action.payload,
        },
      };
    case DIRECTORY_CLEAR_LIST:
      return {
        ...state,
        contacts: action.payload,
      };
    case DIRECTORY_SET_INVOICES: {
      const sortedInvoicePaid = action.payload?.paid?.sort(
        (a, b) => (a.date && b.date ? Date.parse(b.date) - Date.parse(a.date) : 0),
      );
      const sortedInvoiceUnpaid = action.payload?.unpaid?.sort(
        (a, b) => (a.date && b.date ? Date.parse(b.date) - Date.parse(a.date) : 0),
      );
      return {
        ...state,
        documents: {
          ...state.documents,
          paidInvoice: {
            data: sortedInvoicePaid || [],
            page: 0,
          },
          unpaidInvoice: {
            data: sortedInvoiceUnpaid || [],
            page: 0,
          },
        },
      };
    }
    case DIRECTORY_SET_BILLS: {
      const sortedBillsPaid = action.payload?.paid?.sort(
        (a, b) => (a.date && b.date ? Date.parse(b.date) - Date.parse(a.date) : 0),
      );
      const sortedBillsUnpaid = action.payload?.unpaid?.sort(
        (a, b) => (a.date && b.date ? Date.parse(b.date) - Date.parse(a.date) : 0),
      );
      return {
        ...state,
        documents: {
          ...state.documents,
          paidBills: {
            data: sortedBillsPaid || [],
            page: 0,
          },
          unpaidBills: {
            data: sortedBillsUnpaid || [],
            page: 0,
          },
        },
      };
    }
    case DIRECTORY_SET_DOCUMENTS: {
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.payload.name]: {
            ...state.documents[action.payload.name],
            data: action.payload.documents,
          },
        },
      };
    }
    case DIRECTORY_ADD_DOCUMENTS_ACTION: {
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.payload.name]: {
            ...state.documents[action.payload.name],
            data: [
              ...action.payload.documents,
              ...state.documents[action.payload.name as keyof DirectoryDocuments].data || []],
            page: action.payload.page,
          },
        },
      };
    }
    case DIRECTORY_DETAILS_END_PAGE:
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.payload.name]: {
            ...state.documents[action.payload.name as keyof DirectoryDocuments],
            endPage: action.payload.endPage,
          },
        },
      };
    case DIRECTORY_SET_CHART_DATA: {
      const highLowMonth = CalcHighLowMonth(action.payload.data.chartData);
      return {
        ...state,
        details: {
          ...state.details,
          summary: {
            ...state.details.summary,
            [action.payload.type]: {
              ...action.payload.data,
              highestMonth: highLowMonth?.highestMonth,
              lowestMonth: highLowMonth?.lowestMonth,
            },
          },
        },
      };
    }
    case DIRECTORY_SET_AVERAGE_DATA:
      return {
        ...state,
        details: {
          ...state.details,
          average: {
            ...state.details.average,
            [action.payload.type]: action.payload.data.reverse(),
          },
        },
      };
    case DIRECTORY_SET_PROFILE:
      return {
        ...state,
        details: {
          ...state.details,
          profile: action.payload,
        },
      };
    case DIRECTORY_SET_AVERAGE_MONTH: {
      const average = calcAverageMonth(action.payload.datasets, action.payload.selectedMonth);
      let averageAgingInvoices = null;
      let averageContactAgingBills = null;

      if (state.details.summary.customers.agingInvoices) {
        averageAgingInvoices = calcAverageMonthStackedChart(
          state.details.summary.customers.agingInvoices,
          action.payload.selectedMonth,
        );
      }

      if (state.details.summary.suppliers.contactAgingBills) {
        averageContactAgingBills = calcAverageMonthStackedChart(
          state.details.summary.suppliers.contactAgingBills,
          action.payload.selectedMonth,
        );
      }

      return {
        ...state,
        details: {
          ...state.details,
          averageMonth: {
            ...state.details.averageMonth,
            [action.payload.type]: average,
            agingInvoices: averageAgingInvoices,
            agingBills: averageContactAgingBills,
          },
        },
      };
    }
    default:
      return state;
  }
};

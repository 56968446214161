import React from 'react';
import { ICheckoutFulfillment } from 'store/storefront/checkout/checkoutTypes';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Assets
import { ReactComponent as PlusIcon } from 'assets/storefront-icons/plus.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
// Styles
import classes from './CustomerInfoCard.module.scss';

interface Props {
  onClick: () => void;
  fulfillment: ICheckoutFulfillment;
  handleEdit: () => void;
}

const CustomerInfoCard: React.FC<Props> = ({ onClick, fulfillment, handleEdit }) => {
  const { checkout } = useTranslationsStorefront();

  if (fulfillment.contact?.id && fulfillment.contact?.email) {
    return (
      <div className={classes.customer_info_card}>
        <div className={classes.customer_info_card_title}>
          {checkout.customer_info.title}
        </div>
        <div className={classes.customer_info_card_active}>
          <div>
            <p>
              {fulfillment.contact?.firstName}{' '}
              {fulfillment.contact?.lastName}
            </p>
            {fulfillment.contact?.mobileNumber
              && (
                <p>
                  {fulfillment.contact?.mobileNumber}
                </p>
              )}
            <p>
              {`${fulfillment.contact?.address?.line1}, 
          ${fulfillment.contact?.address?.line2 || ''} 
          ${fulfillment.contact?.address?.city}
          ${fulfillment.contact?.address?.postalCode}
          ${fulfillment.country.name}`}
            </p>
            {fulfillment.contact?.email && (
              <p>
                {fulfillment.contact?.email}
              </p>
            )}
          </div>
          <div>
            <EditIcon
              type="button"
              className={classes.edit_icon}
              onClick={handleEdit}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.customer_info_card}>
      <div className={classes.customer_info_card_title}>
        {checkout.customer_info.title}
      </div>
      <div
        role="button"
        className={classes.customer_info_card_bottom}
        tabIndex={0}
        onClick={onClick}
      >
        <button type="button" className={classes.customer_info_card_bottom_button}>
          <PlusIcon className={classes.icon} />
        </button>
        <p>
          {checkout.customer_info.text}
        </p>

      </div>
    </div>
  );
};

export default CustomerInfoCard;

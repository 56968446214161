import { sortFunction } from 'utils/dates';
import {
  OrderDetailsType, OrderType, OrderState,
} from './orderTypes';

export const selectContactDetails = ({
  order,
}: {
  order: OrderState;
}): OrderDetailsType['contact'] | null => {
  if (order.orderDetails) {
    return order.orderDetails.contact;
  }
  return null;
};

export const orderSelectContact = ({ order }: { order: OrderState }): {
  contacts: OrderDetailsType['contact'] | null,
  address: boolean,
} => {
  if (order?.orderDetails && order?.orderDetails?.contact) {
    const addressContact = typeof order.orderDetails.contact.address === 'object' && order.orderDetails.contact.address !== null;
    return {
      contacts: order.orderDetails.contact,
      address: addressContact,
    };
  }
  return {
    contacts: null,
    address: false,
  };
};

export const selectOrderDetails = ({
  order,
}: {
  order: OrderState;
}): OrderDetailsType | null => {
  if (order.orderDetails) {
    return order.orderDetails;
  }
  return null;
};

export const selectPaymentMethods = ({
  order,
}: {
  order: OrderState;
}): OrderState['paymentsMethods'] => order.paymentsMethods;

export const selectOrders = ({
  order,
}: {
  order: OrderState;
}): OrderType[] => order.orders.sort(sortFunction);

import { useTranslations } from 'hooks/useTranslations';
import React from 'react';
// Components
import DirectoryTabs from '../DirectoryTabs';
// Classes
import classes from './DirectoryDetailsTransactions.module.scss';

interface Props {
  transactionTabIndex: number
}

const DirectoryDetailsTransactions: React.FC<Props> = ({ transactionTabIndex }) => {
  const translations = useTranslations();
  const tabsNames = translations.directory.details.tabs;

  return (
    <div className={classes.tabs}>
      <DirectoryTabs tabs={tabsNames} defaultIndex={transactionTabIndex} />
    </div>
  );
};

export default DirectoryDetailsTransactions;

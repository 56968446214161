import React, { useMemo } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import SettingsPage from 'components/DashboardComponents/SettingsPage';
import { useHistory } from 'react-router-dom';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Styles
import classes from './Profile.module.scss';

const Profile: React.FC = () => {
  const { user, subscription } = useTypeSelector(({ auth }) => auth);
  const history = useHistory();
  const translations = useTranslations();
  const tsProfile = translations.profile;

  const profileDetails = useMemo(() => [
    {
      label: tsProfile.name,
      name: `${user?.firstName} ${user?.lastName}`,
    },
    {
      label: tsProfile.business,
      name: `${user?.currentOrganisation?.name}`,
    },
    {
      label: tsProfile.subscription_plan,
      name: subscription?.subscriptionData?.toLowerCase(),
    },
  ], [
    subscription?.subscriptionData,
    user?.currentOrganisation?.name,
    user?.firstName,
    user?.lastName,
    tsProfile,
  ]);

  return (
    <SettingsPage handleClose={() => history.goBack()} title={tsProfile.account}>
      <div className={classes.profile__details}>
        {profileDetails.map((detail) => (
          <div className={classes.setting_box} key={detail.label}>
            <label className={classes.setting_box_label}>
              {detail.label}
            </label>
            <p className={classes.setting_box_name}>
              {detail.name}
            </p>
          </div>
        ))}
      </div>
    </SettingsPage>
  );
};

export default Profile;

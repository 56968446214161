import React from 'react';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
// Types
import { ReactSelectTime } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Utils
import { getYear } from 'utils/dates';
// Components
import Card from 'components/Card';
import CardSelect from 'components/CardSelect/CardSelect';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import Graph from 'components/Graph';
// Styles
import classes from './MobileSalesTab.module.scss';

interface Props {
  total: number;
  time: ReactSelectTime;
  setTime: React.Dispatch<React.SetStateAction<ReactSelectTime>>
  translations: TranslationDashboardModel['home']['report']
}

const MobileSalesTab: React.FC<Props> = ({
  total, time, setTime, translations,
}) => {
  const {
    reportSelectCards,
    salesChart,
    monthlyAverage,
  } = useTypeSelector(({ dashboard }) => dashboard.report);

  return (
    <div className={classes.content_container}>
      <div className={classes.chart_container}>
        <div className={classes.chart}>
          <div className={classes.chart_title}>
            {!total ? (
              <div className={classes.chart_years}>
                {`${getYear()} ${translations.year_to_date}`}
              </div>
            ) : (
              <div className={classes.chart_years}>
                {`${getYear()} ${translations.ytd}`}
              </div>
            )}
            <div className={classes.chart_text}>
              {translations.tabs.re_sales}
            </div>
          </div>
          {!total ? (
            <div className={classes.nodata}>
              <div className={classes.lines}>
                <div className={classes.line} />
                <div className={classes.line} />
                <div className={classes.line} />
              </div>
              <p>{translations.no_data_available_yet}</p>
            </div>
          ) : (
            <Graph
              datasets={salesChart.data}
              color="rgba(0, 211, 200, 0.8)"
              name={translations.tabs.re_sales}
              isIso
            />
          )}
        </div>
      </div>
      <div className={classes.monthly_average}>
        <CardMonthlyAverage
          title={translations.sales_report.month}
          value={monthlyAverage?.value}
          change={monthlyAverage?.percentage}
        />
      </div>
      <div className={classes.high}>
        <Card
          data={{
            title: translations.highest_month,
            label: salesChart.highestMonth?.month,
            value: salesChart.highestMonth?.value,
            second: translations.high,
            change: salesChart.highestMonth?.percentage,
          }}
        />
      </div>
      <div className={classes.low}>
        <Card
          data={{
            title: translations.lowest_month,
            label: salesChart.lowestMonth?.month,
            value: salesChart.lowestMonth?.value,
            second: translations.low,
            change: salesChart.lowestMonth?.percentage,
          }}
        />
      </div>
      <div className={classes.total}>
        <CardSelect
          data={{
            title: translations.all,
            value: reportSelectCards?.revenue?.entrytotal,
            label: translations.sales_report.total_sales,
            change: reportSelectCards?.revenue?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
      <div className={classes.paid}>
        <CardSelect
          data={{
            title: translations.sales_report.paid_invoice,
            value: reportSelectCards?.paid_you?.entrytotal,
            label: `${reportSelectCards?.paid_you?.contacts ? `${reportSelectCards?.paid_you?.contacts}  ${translations.customers}` : ''}`,
            change: reportSelectCards?.paid_you?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
      <div className={classes.unpaid}>
        <CardSelect
          data={{
            title: translations.sales_report.unpaid_invoice,
            value: reportSelectCards?.owes_you?.entrytotal,
            label: `${reportSelectCards?.owes_you?.contacts ? `${reportSelectCards?.owes_you?.contacts}  ${translations.customers}` : ''}`,
            change: reportSelectCards?.owes_you?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
    </div>
  );
};

export default MobileSalesTab;

import React from 'react';
import sprite from 'assets/dashboard-icons/sprite.svg';

interface SvgSpriteProps {
  className?: string
  name: string
  width?: number
  height?: number
  fill?: string
  stroke?: string
  onClick?: () => void
}

const SvgSprite: React.FC<SvgSpriteProps> = ({
  className, name, fill, stroke, onClick, ...rest
}) => (
  <svg className={className} onClick={onClick} fill={fill} stroke={stroke} {...rest}>
    <use href={`${sprite}#${name}`} />
  </svg>
);

export default SvgSprite;

import React, { useEffect, useMemo, useState } from 'react';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { getClientsData, getReportSelectCards } from 'store/dashboard/report/reportActions';
import CardSelect from 'components/CardSelect';
import { ReactSelectTime } from 'types/globalTypes';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import Card from 'components/Card';
import { getYear } from 'utils/dates';
import { ReportReq } from 'store/dashboard/report/reportTypes';
import Graph from 'components/Graph';
import CustomSpinner from 'components/CustomSpinner';
import MobileClientsTab from './MobileTab';
import classes from './CustomerTab.module.scss';

export interface AgingBillsData {
  month: string;
  type: 1 | 2;
  value: number;
}

const CustomersTab: React.FC = () => {
  const { isMobile } = useMobileScreen();
  const dispatch = useDispatch();
  const params: { orgId?: string, reportName?: string } = useParams();
  const user = useUser();
  const [time, setTime] = useState<ReactSelectTime>(ReactSelectTime.yesterday);
  const {
    clientsChart, reportSelectCards, monthlyAverage, loading,
  } = useTypeSelector(
    ({ dashboard }) => dashboard.report,
  );
  const translations = useTranslations();
  const ts = translations.home.report;
  const months = useMemo(() => [
    ts.re_jan,
    ts.re_feb,
    ts.re_mar,
    ts.re_apr,
    ts.re_may,
    ts.re_jun,
    ts.re_jul,
    ts.re_aug,
    ts.re_sep,
    ts.re_oct,
    ts.re_nov,
    ts.re_dec,
  ], [
    ts.re_apr,
    ts.re_aug,
    ts.re_dec,
    ts.re_feb,
    ts.re_jan, ts.re_jul, ts.re_jun, ts.re_mar, ts.re_may, ts.re_nov, ts.re_oct, ts.re_sep]);

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(getClientsData(user.currentOrganisation.id, months));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [months, params.orgId, user?.currentOrganisation?.id]);

  useEffect(() => {
    if (!user || !params.orgId || !params.reportName) {
      return;
    }
    if (!(user?.currentOrganisation?.id)) {
      return;
    }
    dispatch(getReportSelectCards(user.currentOrganisation.id, time, ReportReq.clients));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.orgId, time, user, params.reportName]);
  const getTotal = () => clientsChart.data.map((item) => item.value).reduce((a, b) => a + b, 0);

  if (loading) {
    return <CustomSpinner variant="two" />;
  }

  return isMobile ? (
    <MobileClientsTab
      total={getTotal()}
      time={time}
      setTime={setTime}
    />
  ) : (
    <div className={classes.content_container}>
      <div className={classes.chart_container}>
        <div className={classes.chart}>
          <div className={classes.chart_title}>
            <div className={classes.chart_years}>
              {getYear()} {ts.ytd}
            </div>
            <div className={getTotal() ? classes.chart_text : classes.chart_text_nodata}>
              {ts.tabs.re_customers}
            </div>
          </div>
          {!getTotal() ? (
            <div className={classes.nodata}>
              <div className={classes.lines}>
                <div className={classes.line} />
                <div className={classes.line} />
                <div className={classes.line} />
              </div>
              <p>{ts.no_data_available_yet}</p>
            </div>
          ) : (
            <Graph datasets={clientsChart.data} color="rgba(25, 28, 35, 0.8)" name={ts.customers} />
          )}
        </div>
      </div>
      <div className={classes.monthly_average}>
        <CardMonthlyAverage
          title={ts.clients.month}
          value={monthlyAverage?.value}
          change={monthlyAverage?.percentage}
          unit={ts.customers}
        />
      </div>
      <div className={classes.high}>
        <Card
          data={{
            title: ts.highest_month,
            label: clientsChart.highestMonth?.month,
            value: clientsChart.highestMonth?.value,
            second: ts.high,
            change: clientsChart.highestMonth?.percentage,
          }}
          unit={ts.customers}
        />
      </div>
      <div className={classes.low}>
        <Card
          data={{
            title: ts.lowest_month,
            label: clientsChart.lowestMonth?.month,
            value: clientsChart.lowestMonth?.value,
            second: ts.low,
            change: clientsChart.lowestMonth?.percentage,
          }}
          unit={ts.customers}
        />
      </div>
      <div className={classes.total}>
        <CardSelect
          data={{
            title: ts.all,
            label: ts.per_customer,
            value: reportSelectCards?.customers?.entrytotal,
            change: reportSelectCards?.customers?.change || 0,
          }}
          time={time}
          setTime={setTime}
          unit={ts.tabs.re_orders.toLowerCase()}
        />
      </div>
      <div className={classes.paid}>
        <CardSelect
          data={{
            title: ts.clients.average,
            label: ts.clients.order_customer,
            value: reportSelectCards?.orders?.entrytotal,
            change: reportSelectCards?.orders?.change || 0,
          }}
          time={time}
          setTime={setTime}
          unit={ts.tabs.re_orders.toLowerCase()}
        />
      </div>
      <div className={classes.unpaid}>
        <CardSelect
          data={{
            title: ts.clients.average,
            label: ts.clients.sales_customer,
            value: reportSelectCards?.sales?.entrytotal,
            change: reportSelectCards?.sales?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
    </div>
  );
};

export default CustomersTab;

import React from 'react';
// Images
import { ReactComponent as CloseIcon } from 'assets/storefront-icons/close.svg';
// Components
import ProductAvatar from 'components/StorefrontComponents/ProductAvatar';
import QuantityButtons from 'components/StorefrontComponents/QuantityButtons';
// Classes
import classes from './Header.module.scss';

interface Props {
  toggleClose: () => void;
  avatar: {
    name: string;
    images?: string[];
  }
  btn: {
    title: string;
    onClick: () => void;
    disabled: boolean;
  }
  quantity: {
    disabled: boolean;
    quantity: number;
    setQuantity: React.Dispatch<React.SetStateAction<number>>;
    stock: number;
  }
}

const Header: React.FC<Props> = ({
  toggleClose, btn, quantity, avatar,
}) => (
  <div className={classes.header}>
    <div className={classes.close_icon} tabIndex={0} role="button" onClick={toggleClose}>
      <CloseIcon />
    </div>
    <div className={classes.header_bottom}>
      <div className={classes.header_bottom_image}>
        <QuantityButtons
          disabled={quantity.disabled}
          quantity={quantity.quantity}
          setQuantity={quantity.setQuantity}
          stock={quantity.stock}
        />
        <div className={classes.product_avatar_wrapper}>
          <ProductAvatar name={avatar.name} images={avatar.images} />
        </div>
      </div>
      <button type="button" className={classes.header_bottom_button} onClick={btn.onClick} disabled={btn.disabled}>
        {btn.title}
      </button>
    </div>
  </div>
);

export default Header;

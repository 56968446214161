/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FieldValues, useForm } from 'react-hook-form';
// Stripe
import { StripeError } from '@stripe/stripe-js';
import {
  EpsBankElement,
  IbanElement,
  P24BankElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
// Utils
// Constants
import { PayMethodsEnum } from 'constants/paymentsMethods';
// Types
import { SalesChannel } from 'store/storefront/shop/shopTypes';
// Components
import { InvoicePaymentLoadingEnum, StripeKeys } from 'store/invoice/invoiceTypes';
import { giropay } from 'utils/storefront/PaymentMethods/GiroPay';
import { bancontactPay } from 'utils/storefront/PaymentMethods/BancontactPay';
import { sofortPay } from 'utils/storefront/PaymentMethods/SofortPay';
import FooterPaymentModal from 'components/StorefrontComponents/Checkout/FooterPaymentModal';
import { p24Pay } from 'utils/storefront/PaymentMethods/P24';
import { sepaPay } from 'utils/storefront/PaymentMethods/SepaPay';
import { epsPay } from 'utils/storefront/PaymentMethods/EpsPay';
import BankPaymentForm from './BankPaymentForm';

interface Props {
  activeBank: PayMethodsEnum | null;
  handleLoading: (loading: InvoicePaymentLoadingEnum) => void;
  handleError: (error?: StripeError | unknown) => void;
  handleSuccess: () => void;
  stripeKeys: StripeKeys;
  location: SalesChannel | null;
  currencyIso: string;
  fullName: string;
  email?: string;
}

const BankPaymentStripe: React.FC<Props> = ({
  activeBank,
  handleSuccess,
  handleLoading,
  stripeKeys,
  handleError,
  location,
  fullName,
  currencyIso,
  email,
}) => {
  const [disabledEl, setDisabledEl] = useState<boolean>(false);
  const elements = useElements();
  const stripe = useStripe();

  const {
    register,
    getValues,
    trigger,
    formState: { isValid },
  } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        email,
        fullName,
      }),
      [email, fullName],
    ),
  });

  const disabled = useMemo(() => {
    if (
      activeBank === PayMethodsEnum.P24
      || activeBank === PayMethodsEnum.SEPA
      || activeBank === PayMethodsEnum.EPS
    ) {
      return !isValid || !disabledEl;
    }
    return !isValid;
  }, [activeBank, disabledEl, isValid]);

  const returnUrl = useMemo(
    () => (window.location.href.includes('?')
      ? `${window.location.href}&acc=${stripeKeys?.stripeAccount}&stripe=${stripeKeys?.key}`
      : `${window.location.href}?acc=${stripeKeys?.stripeAccount}&stripe=${stripeKeys?.key}`),
    [stripeKeys?.key, stripeKeys?.stripeAccount],
  );

  const handlePay = useCallback(async () => {
    handleLoading(InvoicePaymentLoadingEnum.PAY);
    if (
      !isValid
      || !location?.address.countryISO
      || !stripe
      || !elements
      || !stripeKeys.client_secret
    ) {
      return;
    }

    if (activeBank === PayMethodsEnum.GIROPAY) {
      const { error } = await giropay(
        stripeKeys.client_secret,
        stripe,
        getValues('fullName'),
        returnUrl,
      );

      if (error) {
        handleError(error);
        return;
      }
    }

    if (activeBank === PayMethodsEnum.BANCONTACT) {
      const { error } = await bancontactPay(
        stripeKeys.client_secret,
        stripe,
        getValues('fullName'),
        returnUrl,
      );

      if (error) {
        handleError(error);
        return;
      }
    }

    if (activeBank === PayMethodsEnum.SOFORT) {
      const { error } = await sofortPay(
        stripeKeys.client_secret,
        stripe,
        {
          countryIso: currencyIso,
          fullName: getValues('fullName'),
          email: getValues('email') || '',
        },
        returnUrl,
      );

      if (error) {
        handleError(error);
        return;
      }
    }

    if (activeBank === PayMethodsEnum.P24) {
      const { error } = await p24Pay(
        stripeKeys.client_secret,
        stripe,
        getValues('email') || '',
        elements.getElement(P24BankElement)!,
        getValues('fullName'),
        returnUrl,
      );

      if (error) {
        handleError(error);
        return;
      }
    }

    if (activeBank === PayMethodsEnum.SEPA) {
      const { error } = await sepaPay(
        stripeKeys.client_secret,
        stripe,
        getValues('fullName'),
        getValues('email') || '',
        elements.getElement(IbanElement)!,
      );

      if (error) {
        handleError(error);
        return;
      }

      handleSuccess();
      return;
    }

    if (activeBank === PayMethodsEnum.EPS) {
      const { error } = await epsPay(
        stripeKeys.client_secret,
        stripe,
        elements.getElement(EpsBankElement)!,
        getValues('fullName'),
      );

      if (error) {
        handleError(error);
        return;
      }
    }

    handleLoading(InvoicePaymentLoadingEnum.END);
  }, [
    isValid,
    location?.address.countryISO,
    stripe,
    elements,
    stripeKeys.client_secret,
    handleLoading,
    activeBank,
    getValues,
    returnUrl,
    handleError,
    currencyIso,
    handleSuccess,
  ]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (!elements) {
      return;
    }

    elements.getElement(P24BankElement)?.on('change', (event) => {
      if (event.complete) {
        setDisabledEl(true);
      }
    });

    elements.getElement(IbanElement)?.on('change', (event) => {
      if (event.complete) {
        setDisabledEl(true);
      }
    });

    elements.getElement(EpsBankElement)?.on('change', (event) => {
      if (event.complete) {
        setDisabledEl(true);
      }
    });
  }, [elements]);

  return (
    <>
      <BankPaymentForm activeBank={activeBank} register={register} />
      <FooterPaymentModal disabled={disabled} onClick={handlePay} />
    </>
  );
};

export default BankPaymentStripe;

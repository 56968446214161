/* eslint-disable comma-dangle */
import { ReactSelectTime } from 'types/globalTypes';
import { CalcHighLowMonth } from 'utils/functions';
import { calcAverageMonth, calcReportSelectCards } from 'utils/price';
import {
  ReportActions,
  ReportState,
  REPORT_SET_AGING_BILLS_CHART,
  REPORT_SET_AGING_INVOICE_CHART,
  REPORT_SET_CURRENT_TIME,
  REPORT_SET_EXPENSES_CHART,
  REPORT_SET_INCOME_STATEMENT,
  REPORT_SET_REPORT_SELECT_CARDS,
  REPORT_SET_SALES_CHART,
  REPORT_SET_SALES_TAXES_CHART,
  REPORT_CHANGE_AVERAGE,
  REPORT_SET_ORDERS_CHART,
  REPORT_SET_ESTIMATES_CHART,
  REPORT_SET_QUOTES_CHART,
  REPORT_SET_CLIENTS_CHART,
  REPORT_SET_VENDORS_CHART,
  REPORT_SET_LOADING_SELECT,
  REPORT_SET_LOADING,
} from './reportTypes';

const initialState: ReportState = {
  currentTime: ReactSelectTime.yesterday,
  loadingSelect: false,
  loading: false,
  expensesChart: {
    data: [],
  },
  salesChart: {
    data: [],
  },
  agingInvoiceChart: {
    data: [],
  },
  agingBillsChart: {
    data: [],
  },
  ordersChart: {
    data: [],
  },
  estimatesChart: {
    data: [],
  },
  quotesChart: {
    data: [],
  },
  salesTaxesChart: {
    data: [],
  },
  clientsChart: {
    data: [],
  },
  vendorsChart: {
    data: [],
  }
};

export const reportReducer = (
  state: ReportState = initialState,
  action: ReportActions
): ReportState => {
  switch (action.type) {
    case REPORT_SET_REPORT_SELECT_CARDS: {
      return {
        ...state,
        reportSelectCards: calcReportSelectCards(action.payload),
      };
    }
    case REPORT_SET_CURRENT_TIME:
      return { ...state, currentTime: action.payload };
    case REPORT_SET_SALES_CHART: {
      const highLowMonth = CalcHighLowMonth(action.payload);
      return {
        ...state,
        salesChart: {
          data: action.payload,
          highestMonth: highLowMonth?.highestMonth,
          lowestMonth: highLowMonth?.lowestMonth,
        },
      };
    }
    case REPORT_SET_SALES_TAXES_CHART: {
      const highLowMonth = CalcHighLowMonth(action.payload);
      return {
        ...state,
        salesTaxesChart: {
          data: action.payload,
          highestMonth: highLowMonth?.highestMonth,
          lowestMonth: highLowMonth?.lowestMonth,
        },
      };
    }
    case REPORT_SET_EXPENSES_CHART: {
      const highLowMonth = CalcHighLowMonth(action.payload);
      return {
        ...state,
        expensesChart: {
          data: action.payload,
          highestMonth: highLowMonth?.highestMonth,
          lowestMonth: highLowMonth?.lowestMonth,
        },
      };
    }
    case REPORT_SET_AGING_INVOICE_CHART: {
      const sum = action.payload.reduce((prev: { [key: string]: number }, curr) => {
        if (prev[curr.month]) {
          return Object.assign(
            prev, { [curr.month]: curr.value + prev[curr.month] }
          );
        }
        return Object.assign(
          prev, { [curr.month]: curr.value }
        );
      }, {});
      const data = Object.keys(sum).map(
        (item, index) => ({ month: item, value: Object.values(sum)[index] })
      );
      const highLowMonth = CalcHighLowMonth(data);
      return {
        ...state,
        agingInvoiceChart: {
          data: action.payload,
          highestMonth: highLowMonth?.highestMonth,
          lowestMonth: highLowMonth?.lowestMonth,
        },
      };
    }
    case REPORT_SET_AGING_BILLS_CHART: {
      const sum = action.payload.reduce((prev: { [key: string]: number }, curr) => {
        if (prev[curr.month]) {
          return Object.assign(
            prev, { [curr.month]: curr.value + prev[curr.month] }
          );
        }
        return Object.assign(
          prev, { [curr.month]: curr.value }
        );
      }, {});
      const data = Object.keys(sum).map(
        (item, index) => ({ month: item, value: Object.values(sum)[index] })
      );
      const highLowMonth = CalcHighLowMonth(data);
      return {
        ...state,
        agingBillsChart: {
          data: action.payload,
          highestMonth: highLowMonth?.highestMonth,
          lowestMonth: highLowMonth?.lowestMonth,
        },
      };
    }
    case REPORT_SET_ORDERS_CHART: {
      const highLowMonth = CalcHighLowMonth(action.payload);
      return {
        ...state,
        ordersChart: {
          data: action.payload,
          highestMonth: highLowMonth?.highestMonth,
          lowestMonth: highLowMonth?.lowestMonth,
        },
      };
    }
    case REPORT_SET_ESTIMATES_CHART: {
      const sum = action.payload.reduce((prev: { [key: string]: number }, curr) => {
        if (prev[curr.month]) {
          return Object.assign(
            prev, { [curr.month]: curr.value + prev[curr.month] }
          );
        }
        return Object.assign(
          prev, { [curr.month]: curr.value }
        );
      }, {});
      const data = Object.keys(sum).map(
        (item, index) => ({ month: item, value: Object.values(sum)[index] })
      );
      const highLowMonth = CalcHighLowMonth(data);
      return {
        ...state,
        estimatesChart: {
          data: action.payload,
          highestMonth: highLowMonth?.highestMonth,
          lowestMonth: highLowMonth?.lowestMonth,
        },
      };
    }
    case REPORT_SET_QUOTES_CHART: {
      const sum = action.payload.reduce((prev: { [key: string]: number }, curr) => {
        if (prev[curr.month]) {
          return Object.assign(
            prev, { [curr.month]: curr.value + prev[curr.month] }
          );
        }
        return Object.assign(
          prev, { [curr.month]: curr.value }
        );
      }, {});
      const data = Object.keys(sum).map(
        (item, index) => ({ month: item, value: Object.values(sum)[index] })
      );
      const highLowMonth = CalcHighLowMonth(data);
      return {
        ...state,
        quotesChart: {
          data: action.payload,
          highestMonth: highLowMonth?.highestMonth,
          lowestMonth: highLowMonth?.lowestMonth,
        },
      };
    }
    case REPORT_SET_CLIENTS_CHART: {
      const highLowMonth = CalcHighLowMonth(action.payload);
      return {
        ...state,
        clientsChart: {
          data: action.payload,
          highestMonth: highLowMonth?.highestMonth,
          lowestMonth: highLowMonth?.lowestMonth,
        },
      };
    }
    case REPORT_SET_VENDORS_CHART: {
      const highLowMonth = CalcHighLowMonth(action.payload);
      return {
        ...state,
        vendorsChart: {
          data: action.payload,
          highestMonth: highLowMonth?.highestMonth,
          lowestMonth: highLowMonth?.lowestMonth,
        },
      };
    }
    case REPORT_SET_INCOME_STATEMENT:
      return { ...state, incomeStatement: action.payload };
    case REPORT_CHANGE_AVERAGE: {
      const average = calcAverageMonth(action.payload.datasets, action.payload.selectedMonth);
      return {
        ...state,
        monthlyAverage: {
          value: average.value,
          percentage: average.percentage
        },
      };
    }
    case REPORT_SET_LOADING_SELECT: {
      return {
        ...state,
        loadingSelect: action.payload,
      };
    }
    case REPORT_SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};

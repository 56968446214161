import React, { useRef } from 'react';
// Hooks
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Types
import { AutocompletePrediction } from 'utils/google';
// Utils
import { replaceKeywordWithTag } from 'utils/strings';
// Styles
import classes from './GooglePlaces.module.scss';

interface Props {
  places: AutocompletePrediction[] | null;
  keyword: string;
  setAutocomplete: (placeId: string) => void;
  loading: boolean;
  handleAddManually: () => void;
  handleCloseAddress: () => void;
}

const GooglePlaces: React.FC<Props> = ({
  places, keyword, setAutocomplete, handleAddManually, handleCloseAddress,
}) => {
  const dropdownRef = useRef(null);
  const { checkout } = useTranslationsStorefront();

  useOnClickOutside(dropdownRef, () => {
    handleCloseAddress();
  });

  if (places && places.length === 0 && keyword.length > 2) {
    return (
      <div className={classes.root} ref={dropdownRef}>
        <div className={classes.add__manually}>
          <p className={classes.add__manually_label}>
            {checkout.address_input.add_manually_label}
          </p>
          <button
            type="button"
            className={classes.add__manually_btn}
            onClick={handleAddManually}
          >
            {checkout.address_input.add_manually_btn}
          </button>
        </div>
        <p className={classes.root_notfound_text}>
          {checkout.address_input.address_not_found}
        </p>
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      id="cityname"
      ref={dropdownRef}
    >
      <div className={classes.add__manually}>
        <p className={classes.add__manually_label}>
          {checkout.address_input.add_manually_label}
        </p>
        <button
          type="button"
          className={classes.add__manually_btn}
          onClick={handleAddManually}
        >
          {checkout.address_input.add_manually_btn}
        </button>

      </div>
      {places && places.map((place) => (
        <div
          key={place.place_id}
          className={classes.root_place}
          role="button"
          tabIndex={0}
          onClick={() => setAutocomplete(place.place_id)}
        >
          <h3
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `${replaceKeywordWithTag(place.description, keyword)} ${replaceKeywordWithTag(place.description, place.terms[2].value)}`,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default GooglePlaces;

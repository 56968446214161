/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Dispatch, SetStateAction, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useUser } from 'hooks/useUser';
// Actions
import { selectOrderDetails } from 'store/dashboard/orders/orderSelectors';
import { createReminder } from 'store/dashboard/orders/orderActions';
// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/back-arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/dashboard-icons/close-icon.svg';
// Components
import Modal from 'components/Modal';
// Styles
import classes from './SendReminderModal.module.scss';

interface ModalProps {
  setActive: Dispatch<SetStateAction<boolean>>;
  active: boolean;
}

const SendReminderModal: React.FC<ModalProps> = ({
  setActive, active,
}) => {
  const user = useUser();
  const dispatch = useDispatch();
  const params: { orderId?: string } = useParams();
  const { isMobile } = useMobileScreen();
  const translations = useTranslations();
  const orderDetails = useTypeSelector(selectOrderDetails);
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });

  const handleClose = () => {
    setActive(false);
  };

  const sendReminder = useCallback((data: { email: string, msg: string }) => {
    if (!user?.currentOrganisation?.id || !orderDetails?.publicId || !params.orderId) {
      return;
    }

    const date = new Date();
    setActive(false);
    dispatch(createReminder(
      user?.currentOrganisation?.id,
      orderDetails?.publicId,
      {
        entityId: params.orderId,
        entity: orderDetails?.type.toUpperCase(),
        interval: 1,
        startDate: date.toISOString(),
        endDate: orderDetails?.dueDate,
        data: {
          contactName: `${orderDetails?.contact?.firstName} ${orderDetails?.contact?.lastName}`,
          dueDate: orderDetails?.dueDate,
          paymentAmount: String(orderDetails?.price.total),
          email: data.email,
          message: data.msg,
        },
      },
    ));
  }, []);

  if (!orderDetails) {
    return null;
  }

  return (
    <Modal active={active} setActive={setActive} type="three">
      <div className={classes.content}>
        {isMobile ? (
          <ArrowIcon
            className={classes.close_icon}
            onClick={handleClose}
          />
        ) : (
          <CloseIcon
            className={classes.close_icon}
            onClick={handleClose}
          />
        )}
        <div className={classes.send_reminder}>
          <form onSubmit={handleSubmit(sendReminder)} className={classes.send_reminder_form}>
            <div className={classes.send_reminder_info}>
              <h1 className={classes.title}>
                {translations.orders.reminder_modal.title}
              </h1>
              <p className={classes.name}>
                {orderDetails.contact?.firstName} {orderDetails.contact?.lastName}
              </p>
              <div className={classes.email}>
                <p className={classes.email_label}>
                  {translations.orders.reminder_modal.email_label}
                </p>
                <input
                  defaultValue={orderDetails.contact?.email}
                  placeholder={translations.orders.reminder_modal.enter_email}
                  className={classes.email_input}
                  {...register('email', {
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: translations.orders.reminder_modal.email_err,
                    },
                  })}
                  type="email"
                  required
                />
              </div>
            </div>
            <div className={classes.send_reminder_footer}>
              <div className={classes.message}>
                <p className={classes.message_label}>
                  {translations.orders.reminder_modal.message}
                </p>
                <textarea
                  className={classes.message_input}
                  defaultValue={translations.orders.reminder_modal.msg
                    .replace('{FirstName}', orderDetails.contact?.firstName || '')
                    .replace('{OrderID}', params.orderId || '')
                    .replace('{url}', orderDetails.url || '')
                    .replace('{OrgName}', user?.currentOrganisation?.name || '')}
                  {...register('msg')}
                  required
                />
              </div>
              <div className={classes.send_reminder_button_wrapper}>
                <input
                  disabled={!formState.isValid}
                  className={classes.send_reminder_button}
                  type="submit"
                  value={translations.orders.reminder_modal.send_reminder}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default SendReminderModal;

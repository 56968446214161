import React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { SearchResult } from 'store/dashboard/app/appTypes';
import { useTranslations } from 'hooks/useTranslations';
import SearchItem from '../SearchItem';
// Classes
import 'react-virtualized/styles.css';

interface Props {
  data: SearchResult[],
  hasNextPage: boolean;
  loading: string
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const SearchResultList: React.FC<Props> = ({
  data, loading,
}) => {
  const { search } = useTranslations();

  const rowRenderer = ({ key, index, style }: {
    key: string,
    index: number,
    style: React.CSSProperties
  }) => {
    if (loading === 'LoadingMoreItems') {
      <div key={key} style={style}>
        {search.loading_text}
      </div>;
    }

    return (
      <div key={key} style={style}>
        <SearchItem key={key} item={data[index]} />
      </div>
    );
  };

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          rowRenderer={rowRenderer}
          height={500}
          rowHeight={60}
          rowCount={data.length}
          width={width}
          style={{
            height: 'auto',
            maxHeight: '600px',
          }}
        />
      )}
    </AutoSizer>
  );
};

export default SearchResultList;

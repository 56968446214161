import React, { useMemo } from 'react';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
// Assets
import { ReactComponent as PlusIcon } from 'assets/storefront-icons/plus.svg';
import { ReactComponent as MoreInfo } from 'assets/storefront-icons/more-info.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
// Styles
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import classes from './ShippingCard.module.scss';

interface Props {
  onClick: () => void;
  existInfoCustomer: boolean;
  handleOpenMoreInfo: () => void;
  isShipping?: boolean;
}

const ShippingCard: React.FC<Props> = ({
  onClick,
  existInfoCustomer,
  handleOpenMoreInfo,
  isShipping,
}) => {
  const { checkout } = useTranslationsStorefront();
  const { fulfillment } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const formatCurrencyByISO = useMemo(
    () => CurrencyFormatByISO(currentSalesChannel?.address.currencyISO),
    [currentSalesChannel?.address.currencyISO],
  );

  if (isShipping && existInfoCustomer) {
    return null;
  }

  if (fulfillment.method === DeliveryMethodsEnum.DELIVERY) {
    return (
      <div className={classes.shipping_card_active}>
        <div className={classes.shipping_card_title}>
          {checkout.shipping_card.title}
        </div>
        <div className={classes.shipping_card_shipping}>
          <div>
            <p>
              {checkout.shipping_card.delivery_card.local_delivery_text}
            </p>
            <p>
              {checkout.shipping_card.delivery_card.delivery_fee_text}
              {' '}
              {formatCurrencyByISO(fulfillment.amount.fee)}
            </p>
            <p>
              {checkout.shipping_card.delivery_card.delivery_bottom_text}
            </p>
          </div>
          <div>
            <EditIcon
              type="button"
              className={classes.edit_icon}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    );
  }

  if (fulfillment.method === DeliveryMethodsEnum.PICKUP) {
    return (
      <div className={classes.shipping_card_active}>
        <div className={classes.shipping_card_title}>
          {checkout.shipping_card.title}
        </div>
        <div className={classes.shipping_card_pickup}>
          <div>
            <p>
              {checkout.shipping_card.pickup_card.pickup_from_text}
              {fulfillment.location?.name}
            </p>
            <p>
              {`${fulfillment.location?.address}`}
            </p>
            <p>
              {checkout.shipping_card.pickup_card.pickup_fee_text}
              {' '}
              {formatCurrencyByISO(fulfillment.amount.fee)}
            </p>
            <button type="button" onClick={handleOpenMoreInfo}>
              {checkout.shipping_card.pickup_card.more_info_text}
              <MoreInfo />
            </button>
          </div>
          <div>
            <EditIcon
              type="button"
              className={classes.edit_icon}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    );
  }

  if (fulfillment.method === DeliveryMethodsEnum.SHIPPING) {
    return (
      <div className={classes.shipping_card_active}>
        <div className={classes.shipping_card_title}>
          {checkout.shipping_card.title}
        </div>
        <div className={classes.shipping_card_shipping}>
          <div>
            <p>
              {checkout.shipping_card.shipping_card.shipping_to_text}
              {' '}
              {fulfillment.country?.name}
            </p>
            <p>
              {checkout.shipping_card.shipping_card.shipping_fee_text}
              {' '}
              {formatCurrencyByISO(fulfillment.amount.fee)}
            </p>
            <p>
              {checkout.shipping_card.shipping_card.shipping_bottom_text}
            </p>
          </div>
          <div>
            <EditIcon
              type="button"
              className={classes.edit_icon}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={existInfoCustomer ? classes.shipping_card_active : classes.shipping_card}>
      <div className={classes.shipping_card_title}>
        {checkout.shipping_card.title}
      </div>
      <button className={classes.shipping_card_bottom} type="button" onClick={onClick} disabled={!existInfoCustomer}>
        <div className={classes.shipping_card_bottom_button}>
          <PlusIcon className={classes.icon} />
        </div>
        <p>
          {checkout.shipping_card.text}
        </p>
      </button>
    </div>
  );
};

export default ShippingCard;

import { PaymentIntentResult, Stripe } from '@stripe/stripe-js';

export const sofortPay = async (
  clientSecret: string,
  stripe: Stripe,
  billingData: {
    countryIso: string,
    fullName: string,
    email: string,
  },
  returnUrl: string,

): Promise<PaymentIntentResult> => {
  const result = await stripe.confirmSofortPayment(clientSecret, {
    payment_method: {
      sofort: {
        country: billingData.countryIso,
      },
      billing_details: {
        name: billingData.fullName,
        email: billingData.email,
      },
    },
    return_url: returnUrl,
  });

  return result;
};

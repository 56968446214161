import React from 'react';
// Components
import CustomSpinner from 'components/CustomSpinner';
// Styles
import classes from './LoadingPage.module.scss';

interface Props {
  title?: string;
  backgroundColor?: string;
}

const LoadingPage: React.FC<Props> = ({ title, backgroundColor }) => (
  <div className={classes.modal_show} style={{ background: backgroundColor || 'rgba(32, 39, 43, 1)' }}>
    <div className={classes.loading}>
      <CustomSpinner variant="two" />
      <h2>
        {title}
      </h2>
    </div>
  </div>
);

export default LoadingPage;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { SetAddToBagAction } from 'store/storefront/shop/shopActions';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
// Utils
import { CurrencyFormat } from 'utils/price';
import { CartFunctions } from 'utils/storefront/CartFunctions';
// Types
import { CartItemType } from 'store/storefront/shop/shopTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Components
import QuantityButtons from 'components/StorefrontComponents/QuantityButtons';
import Remove from 'components/StorefrontComponents/Remove';
// Styles
import ProductAvatar from 'components/StorefrontComponents/ProductAvatar';
import { useParams } from 'react-router-dom';
import classes from './ShoppingCartItem.module.scss';

interface Props {
  item: CartItemType;
  currencyISO: string;
  deleteItem: (id: CartItemType) => void;
  translation: TranslationStorefrontModel['cart'];
}

const ShoppingCartItem: React.FC<Props> = ({
  item,
  currencyISO,
  deleteItem,
  translation,
}) => {
  const { id }: { id: string } = useParams();
  const [quantity, setQuantity] = useState<number>(item.quantity);
  const [start, setStart] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { bag, currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const price = CurrencyFormat(item.variations[0].price, currencyISO);

  useEffect(() => {
    setQuantity(item.quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!start) {
      setStart(true);
      return;
    }

    const updatedBag = CartFunctions.updateCart(
      bag, item.id, quantity, item.variations[0].id, true,
    );

    localStorage.setItem(`leja-${id}-cart`, JSON.stringify(updatedBag.bag));
    dispatch(SetAddToBagAction(updatedBag.bag));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, id]);

  const itemVariationStock = useMemo(() => {
    const itemInventory = item.variations[0]?.inventories?.find(
      (inventory) => inventory.locationId === currentSalesChannel?.id,
    );

    return itemInventory?.quantity;
  }, [currentSalesChannel?.id, item.variations]);

  return (
    <div className={classes.product}>
      <div className={classes.product_top}>
        <div className={itemVariationStock
          ? classes.product_description
          : classes.product_description_sold_out}
        >
          {!!itemVariationStock
            && itemVariationStock < 6
            && (
              <div className={classes.product_description_count}>
                {itemVariationStock
                  ? `${itemVariationStock} ${translation.warning.left}`
                  : `${translation.cart_item.quantity_sold_out}`}
              </div>
            )}
          {!itemVariationStock && (
            <div className={classes.product_description_count}>
              {translation.cart_item.quantity_sold_out}
            </div>
          )}
          <div className={classes.product_description_name}>
            {item.name}
          </div>
          <div className={classes.product_description_price}>
            <p>
              {price}
            </p>
            <p>
              {item.variations[0].name}
            </p>
          </div>
        </div>
        <ProductAvatar name={item.name} images={item.images} />
      </div>
      {item.taxes && (
        <div className={itemVariationStock ? classes.taxes : classes.taxes_sold_out}>
          {item.taxes.map((tax) => (
            <React.Fragment key={tax.name}>
              <div className={classes.tax_name}>
                {`${tax.name} - ${tax.percentage}% ${tax.included
                  ? translation.cart_item.taxes_included
                  : translation.cart_item.taxes_excluded}`}
              </div>
              <div className={classes.tax_amount}>
                {CurrencyFormat(tax.amount, currencyISO)}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      <div className={itemVariationStock
        ? classes.product_bottom
        : classes.product_bottom_sold_out}
      >
        <div className={classes.quantity}>
          <QuantityButtons
            quantity={quantity}
            setQuantity={setQuantity}
            stock={itemVariationStock}
            disabled={itemVariationStock === 0}
          />
          <div className={classes.delete}>
            <Remove deleteItem={deleteItem} item={item} />
          </div>
        </div>
        <div className={classes.item_price}>
          {CurrencyFormat(item.variations[0].price * item.quantity, currencyISO)}
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartItem;

import React from 'react';
// Assets
import { ReactComponent as VisaIcon } from 'assets/payment-icons/accepted-icons/visa.svg';
import { ReactComponent as GPayIcon } from 'assets/payment-icons/accepted-icons/gpay.svg';
import { ReactComponent as ApplePayIcon } from 'assets/payment-icons/accepted-icons/apple-pay.svg';
import { ReactComponent as BankTransferIcon } from 'assets/payment-icons/accepted-icons/bank-transfer.svg';
import { ReactComponent as AmexIcon } from 'assets/payment-icons/accepted-icons/amex.svg';
import { ReactComponent as MasterCardIcon } from 'assets/payment-icons/accepted-icons/mastercard.svg';
import { ReactComponent as PayPalIcon } from 'assets/payment-icons/accepted-icons/paypal.svg';
// classes
import classes from './PaymentAccepted.module.scss';

interface Props {
  title: string;
}

const PaymentAccepted: React.FC<Props> = ({ title }) => (
  <div className={classes.payments__accepted_container}>
    <h3>{title}</h3>
    <div className={classes.payments__accepted_list}>
      <VisaIcon />
      <GPayIcon />
      <ApplePayIcon />
      <AmexIcon />
      <MasterCardIcon />
      <PayPalIcon />
      <BankTransferIcon />
    </div>
  </div>
);

export default PaymentAccepted;

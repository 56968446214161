import React, { useState } from 'react';
// Hooks
import { useSubscription } from 'hooks/useSubscription';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
// Types
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
// Utils
import { openInNewTab } from 'utils/functions';
import { CurrencyFormatByISO } from 'utils/price';
// Styles
import MoreButton from 'components/Buttons/MoreButton';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useDispatch } from 'react-redux';
import { getMoreVariations } from 'store/dashboard/catalog/items/itemsActions';
import classes from './ItemDetailsOptions.module.scss';

interface Props {
  product: ProductItem;
}

const ItemDetailsOptions: React.FC<Props> = ({ product }) => {
  const subscription = useSubscription();
  const {
    isMoreVariations,
  } = useTypeSelector(({ dashboard }) => dashboard.catalog.items);
  const user = useUser();
  const dispatch = useDispatch();
  const [pages, setPages] = useState<number>(1);
  const { catalog } = useTranslations();
  const currencyISO = user?.currentOrganisation?.country?.currencyISO || 'EUR';
  const formatCurrencyByISO = CurrencyFormatByISO(currencyISO);
  const handleUpgrade = () => {
    openInNewTab('https://play.google.com/store');
  };

  const handleMoreVariations = () => {
    if (!user?.currentOrganisation?.id) {
      return;
    }

    setPages(pages + 1);
    dispatch(getMoreVariations(user?.currentOrganisation?.id, product, pages));
  };

  if (subscription?.subscriptionData === 'BASICS') {
    return (
      <div className={classes.root}>
        <h2 className={classes.title}>
          {catalog.item_details.details_options.title}
        </h2>
        <button type="button" className={classes.upgrade_btn} onClick={handleUpgrade}>
          {catalog.item_details.no_subscription.button_title}
        </button>
        <p className={classes.no_subscription_description}>
          {catalog.item_details.details_options.description_no_subscription}
        </p>
        <div className={classes.no_subscription_available}>
          <p className={classes.no_subscription_available_title}>
            {catalog.item_details.details_options.available}
          </p>
          <p className={classes.no_subscription_available_subtitle}>
            {catalog.item_details.no_subscription.subtitle}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>
        {catalog.item_details.details_options.title}
      </h2>
      <div className={classes.variants}>
        {product.variations.map((variant) => (
          <div className={classes.variant} key={variant.id}>
            <div className={classes.variant_description}>
              <p className={classes.variant_name}>
                {variant.name.replace(',', ', ')}
              </p>
              <p className={classes.variant_sku}>
                {catalog.item_details.details_options.sku.replace('{sku}', variant.sku || '')}
              </p>
            </div>
            <div className={classes.variant_price}>
              {formatCurrencyByISO(variant.price)}
            </div>
          </div>
        ))}
        {isMoreVariations && (
          <div onClick={handleMoreVariations} tabIndex={0} role="button" className={classes.more_btn}>
            <MoreButton />
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemDetailsOptions;

// Types
import { IOrganisationData } from 'types/globalTypes';
import { TranslationDashboardModel, TranslationsType } from 'types/TranslationDashboardModel';

export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_SET_TYPE = 'AUTH_SET_TYPE';
export const AUTH_SET_LOADING = 'AUTH_SET_LOADING';
export const AUTH_SET_ERROR = 'AUTH_SET_ERROR';
export const AUTH_SET_LOGIN = 'AUTH_SET_LOGIN';
export const AUTH_SET_CHECK_LOGIN = 'AUTH_SET_CHECK_LOGIN';
export const AUTH_SET_CURRENT_ORGANISATION = 'AUTH_SET_CURRENT_ORGANISATION';
export const APP_SET_TRANSLATIONS_FETCHED = 'APP_SET_TRANSLATIONS_FETCHED';
export const APP_SET_CURRENT_LANGUAGE = 'APP_SET_CURRENT_LANGUAGE';
export const APP_SET_ACTIVE_TRANSLATION = 'APP_SET_ACTIVE_TRANSLATION';
export const AUTH_SET_SUBSCRIPTION = 'AUTH_SET_SUBSCRIPTION';
export const APP_SET_SIGN_OUT = 'APP_SET_SIGN_OUT';

export type AuthType = 'PHONE' | null;

export interface UserAuthData {
  accountStatusId: string;
  accountId: number;
  accountUid: string;
  firstName: string;
  lastName: string;
  photoUrl: string | null;
  mobileNumber: string | null;
  uid: string;
  updatedAt: string | Date;
  createdAt: string | Date;
  email?: string;
  organisations: IOrganisationData[];
  currentOrganisation: IOrganisationData | null;
  status: string;
}

export interface ISubscription {
  subscriptionPlanId: number,
  status: string,
  subscriptionData?: string
}

export interface SubscriptionData {
  createdAt: string,
  id: number,
  sku: string,
  subscriptionPlanId: number,
  subscriptionToken: string,
  status: string,
  trialEndDate: string,
  updatedAt: string,
  organisationId: number,
  nextRenewal: string,
  trialPeriodRemaining: number
}

export enum StatusType {
  activated = 'ACTIVATED',
  created = 'CREATED'
}

export interface SubscriptionPlansData {
  id: number,
  name: string,
  alias: string,
  monthlyPrice: number,
  annualPrice: number,
  monthlyTrialPeriod: number,
  annualTrialPeriod: number,
  monthlyPlanSku: string,
  annualPlanSku: string
}

export interface AuthState {
  user: null | UserAuthData;
  subscription: ISubscription | null;
  authType: AuthType;
  loading: boolean;
  error: {
    msg: string;
    code: string;
  } | null;
  isLogin: boolean;
  isLoginCheck: boolean;
  isActivate: boolean;
  translations: TranslationsType | null;
  isTranslationsFetched: boolean;
  currentLanguage: string;
  activeTranslation: TranslationDashboardModel;
  signOut: boolean;
}

interface AuthSetUserAction {
  type: typeof AUTH_SET_USER;
  payload: UserAuthData | null;
}

interface AuthSetSubscriptionsAction {
  type: typeof AUTH_SET_SUBSCRIPTION;
  payload: ISubscription | null;
}

interface AuthSetTypeAction {
  type: typeof AUTH_SET_TYPE;
  payload: 'PHONE' | null;
}

interface AuthLoadingAction {
  type: typeof AUTH_SET_LOADING;
  payload: boolean;
}

interface AuthSetErrorAction {
  type: typeof AUTH_SET_ERROR;
  payload: { msg: string, code: string } | null;
}

interface AuthSetLoginAction {
  type: typeof AUTH_SET_LOGIN;
  payload: boolean;
}

interface AuthSetIsLoginCheckAction {
  type: typeof AUTH_SET_CHECK_LOGIN;
  payload: boolean;
}

interface AuthSetCurrentOrganisationAction {
  type: typeof AUTH_SET_CURRENT_ORGANISATION;
  payload: IOrganisationData;
}

interface AppSetTranslationsFetchedAction {
  type: typeof APP_SET_TRANSLATIONS_FETCHED;
  payload: boolean;
}

interface AppSetCurrentLanguage {
  type: typeof APP_SET_CURRENT_LANGUAGE;
  payload: string;
}

interface AppSetActiveTranslation {
  type: typeof APP_SET_ACTIVE_TRANSLATION;
  payload: TranslationDashboardModel;
}

interface AppSetSignOut {
  type: typeof APP_SET_SIGN_OUT;
  payload: boolean;
}

export type AuthActions =
  | AuthSetTypeAction
  | AuthLoadingAction
  | AuthSetErrorAction
  | AuthSetUserAction
  | AuthSetLoginAction
  | AuthSetIsLoginCheckAction
  | AuthSetCurrentOrganisationAction
  | AppSetTranslationsFetchedAction
  | AppSetCurrentLanguage
  | AppSetActiveTranslation
  | AuthSetSubscriptionsAction
  | AppSetSignOut;

import React, { Dispatch, SetStateAction } from 'react';
// Components
import Modal from '../Modal';
// Styles
import classes from './ConfirmModal.module.scss';

interface Props {
  setActive: Dispatch<SetStateAction<boolean>>;
  active: boolean;
  data: {
    title: string;
    description: string;
    button_first: string;
    button_second: string;
    onClick: () => void;
    className?: string;
  }
  closeModal?: () => void;
}

const ConfirmModal: React.FC<Props> = ({
  active, setActive, data, closeModal,
}) => {
  const handleClose = () => {
    setActive(false);
  };

  return (
    <Modal
      active={active}
      setActive={setActive}
      closeModal={closeModal}
    >
      <div className={data.className || classes.content}>
        <h1 className={classes.content_title}>
          {data.title}
        </h1>
        <p className={classes.content_description}>
          {data.description}
        </p>
        <div className={classes.content_buttons}>
          <button className={classes.content_buttons_cancel} type="button" onClick={closeModal || handleClose}>
            {data.button_first}
          </button>
          <button className={classes.content_buttons_continue} type="button" onClick={data.onClick}>
            {data.button_second}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

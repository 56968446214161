import React, { useCallback, useEffect, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import { PayMethodsEnum } from 'constants/paymentsMethods';
import { useTypeSelector } from 'hooks/useTypeSelector';
import {
  invoicePaymentError,
  invoicePaymentLoading,
  invoicePaymentSuccess,
} from 'store/invoice/invoiceActions';
import { InvoicePaymentLoadingEnum } from 'store/invoice/invoiceTypes';
import { getCountryIso } from 'utils/functions';
import { afterpayClearpay } from 'utils/storefront/PaymentMethods/AfterpayClearpay';
import { klarna } from 'utils/storefront/PaymentMethods/Klarna';

interface Props {
  type: PayMethodsEnum | null;
  values: FieldValues | null | undefined;
  setPay: React.Dispatch<React.SetStateAction<boolean>>;
  clientSecret: string;
}

const StripeConfirm: React.FC<Props> = ({
  type,
  values,
  setPay,
  clientSecret,
}) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { data, stripe: stripeKeys } = useTypeSelector(
    (state) => state.invoice,
  );

  const returnUrl = useMemo(
    () => (window.location.href.includes('?')
      ? `${window.location.href}&acc=${stripeKeys?.stripeAccount}&stripe=${stripeKeys?.key}`
      : `${window.location.href}?acc=${stripeKeys?.stripeAccount}&stripe=${stripeKeys?.key}`),
    [stripeKeys?.key, stripeKeys?.stripeAccount],
  );

  const countryIso = getCountryIso(data?.contact.address?.country)?.value || '';

  const handlePay = useCallback(async () => {
    if (!stripe || !stripeKeys) {
      return;
    }

    setPay(false);
    dispatch(invoicePaymentLoading(InvoicePaymentLoadingEnum.PAY));

    if (type === PayMethodsEnum.AFTERPAY_CLEARPAY) {
      const error = await afterpayClearpay(
        clientSecret,
        stripe,
        {
          email: values?.email,
          fullname: values?.fullName,
          address: values?.address1,
          address2: values?.address2,
          city: values?.city,
          state: values?.state,
          country: countryIso,
          postalCode: values?.postalCode,
          shipping: {
            fullname: values?.fullName,
            address: values?.address1,
            address2: values?.address2,
            city: values?.city,
            state: values?.state,
            postalCode: values?.postalCode,
          },
        },
        returnUrl,
      );

      if (error) {
        dispatch(invoicePaymentError(error.message || ''));
      } else {
        dispatch(invoicePaymentSuccess(true));
      }
    }

    if (type === PayMethodsEnum.KLARNA) {
      const error = await klarna(
        clientSecret,
        stripe,
        {
          email: values?.email,
          country: countryIso,
        },
        returnUrl,
      );

      if (error) {
        dispatch(invoicePaymentError(error.message || ''));
      } else {
        dispatch(invoicePaymentSuccess(true));
      }
    }
  }, [
    stripe,
    stripeKeys,
    setPay,
    dispatch,
    type,
    clientSecret,
    values?.email,
    values?.fullName,
    values?.address1,
    values?.address2,
    values?.city,
    values?.state,
    values?.postalCode,
    countryIso,
    returnUrl,
  ]);

  useEffect(() => {
    if (stripe && clientSecret) {
      handlePay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, clientSecret]);

  return null;
};

export default StripeConfirm;

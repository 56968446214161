import React from 'react';
import cn from 'classnames';
// Assets
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';
// Styles
import classes from './BackBtnCircleWithArrow.module.scss';

interface Props {
  onClick?: () => void;
  className?: string;
}

const BackBtnCircleWithArrow: React.FC<Props> = ({ onClick, className }) => (
  <LeftArrowIcon
    role="button"
    className={cn(
      className,
      className,
      [classes.left_arrow_icon],
    )}
    onClick={onClick}
  />
);

export default BackBtnCircleWithArrow;

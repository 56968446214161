import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
// Utils
import { CurrencyFormatRange } from 'utils/price';
// Types
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
// Styles
import { useTypeSelector } from 'hooks/useTypeSelector';
import classes from './TableItem.module.scss';

interface Props {
  item: ProductItem;
  pathToDetails: string;
  currencyISO: string;
}

const TableItem: React.FC<Props> = ({
  item,
  currencyISO = 'EUR',
  pathToDetails,
}) => {
  const { categories } = useTypeSelector((state) => state.dashboard.catalog.items);

  const itemCategory = useMemo(
    () => categories?.find((category) => item.categoryId === category.id),
    [categories, item.categoryId],
  );

  return (
    <Link to={pathToDetails} className={classes.table_item}>
      <div
        className={classes.table_item_title}
      >
        {item.name}
      </div>
      <div className={classes.table_item_col}>
        {itemCategory?.name || '--'}
      </div>
      <div className={classes.table_item_col}>
        <span>
          {item.countOptions}
        </span>
      </div>
      <div className={classes.table_item_col}>
        <span>
          {item.stock}
        </span>
      </div>
      <div className={classes.table_item_col}>
        {item.salesChannelCount || 0}
      </div>
      <div className={classes.table_item_col}>
        <span>
          {CurrencyFormatRange(item.priceRange, currencyISO)}
        </span>
      </div>
    </Link>
  );
};

export default TableItem;

/* eslint-disable max-len */
import { useUser } from 'hooks/useUser';
import React from 'react';
import { CurrencyFormatByISO } from 'utils/price';
import { ReactComponent as ArrowDown } from 'assets/dashboard-icons/arrow-down.svg';
import SelectorCard from 'pages/Dashboard/Home/SelectorCard';
import { ReactSelectTime } from 'types/globalTypes';
import { useTranslations } from 'hooks/useTranslations';
import classNames from 'classnames';
import { useTypeSelector } from 'hooks/useTypeSelector';
import CustomSpinner from 'components/CustomSpinner';
// Styles
import classes from './CardSelect.module.scss';

interface Props {
  data: {
    title: string;
    label?: string;
    value?: number;
    change?: number;
    colorValue?: string;
  };
  time: string;
  setTime: React.Dispatch<React.SetStateAction<ReactSelectTime>>;
  unit?: string;
}

const CardSelect: React.FC<Props> = ({
  data, time, setTime, unit,
}) => {
  const {
    loadingSelect,
  } = useTypeSelector(({ dashboard }) => dashboard.report);
  const user = useUser();
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.country?.currencyISO);
  const translations = useTranslations();
  const ts = translations.home.report;
  const isNoData = classNames(data.value, classes.subtitle, classes.subtitle_nodata);
  const setColorValue = data.colorValue ? data.colorValue : 'rgba(107, 89, 237, 1)';
  const isUnit = (v: number | undefined) => {
    if (unit && v) {
      return (
        <div>
          {(Math.trunc(v * 100) / 100)}
          &nbsp;
          <span className={classes.unit}>
            {unit}
          </span>
        </div>
      );
    }
    if (unit) {
      return `0 ${unit}`;
    }

    return formatCurrencyByISO(v);
  };

  return (
    <div className={classes.select}>
      {loadingSelect
        ? (
          <div className={classes.loading}>
            <CustomSpinner variant="two" />
          </div>
        )
        : (
          <>
            <h2 className={classes.title}>{data.title}</h2>
            <h3 className={isNoData}>
              {(!!data.label && !!data.value) ? data.label : '--'}
            </h3>
            <div className={classes.details_select}>
              <p className={classes.value} style={data.value ? { color: setColorValue } : { color: 'rgb(203, 198, 215)' }}>
                {isUnit(data.value)}
              </p>
              {data.value
                ? (
                  <div className={classes.right_side}>
                    <div className={Math.sign(data.change ? data.change : 0) === 1
                      ? classes.percents_container_growth
                      : classes.percents_container_decline}
                    >
                      {data.change !== 0 && (
                        <div>
                          <ArrowDown />
                        </div>
                      )}
                      <p className={`${data.change}%` === '0%' ? classes.percents_zero : classes.percents}>
                        {Math.sign(data.change ? data.change : 0) === 1
                          ? `+${data.change && ((Math.round(data.change) * 100) / 100)}% ${ts.growth}`
                          : `${data.change ? ((Math.round(data.change) * 100) / 100) : 0}% ${data.change !== 0 ? ts.decline : ''}`}
                      </p>
                    </div>
                    <SelectorCard
                      time={time}
                      setTime={setTime}
                    />
                  </div>
                ) : (
                  <div className={classes.right_side}>
                    <p className={classes.nodata}>{ts.no_data_available_yet}</p>
                    <SelectorCard
                      time={time}
                      setTime={setTime}
                    />
                  </div>
                )}
            </div>
          </>
        )}
    </div>
  );
};

export default CardSelect;

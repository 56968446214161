import React, {
  Dispatch, SetStateAction, useEffect, useMemo, useState,
} from 'react';
import Select from 'react-select';
// Hooks
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Actions
import { selectTransactionDetails } from 'store/dashboard/transaction/transactionSelectors';
import { createInvoicePayment } from 'store/dashboard/transaction/transactionActions';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
// Assets
import { ReactComponent as CloseIcon } from 'assets/dashboard-icons/close-icon.svg';
// Types
import { ReactSelectOption } from 'types/globalTypes';
import { PaymentOptionsEnum } from 'constants/paymentsMethods';
// Components
import Modal from 'components/Modal';
// Styles
import { customStyles } from './styles';
import classes from './RecordPayment.module.scss';

interface ModalProps {
  setActive: Dispatch<SetStateAction<boolean>>;
  active: boolean;
  totalAmountPaymentSchedule?: number;
  nextSchedulePayment?: number;
}

const RecordPayment: React.FC<ModalProps> = ({
  setActive, active, totalAmountPaymentSchedule, nextSchedulePayment,
}) => {
  const translations = useTranslations();
  const { isMobile } = useMobileScreen();
  const transaction = useTypeSelector(selectTransactionDetails);
  const user = useUser();
  const dispatch = useDispatch();
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.country?.currencyISO || 'USD');
  const [amountPay, setAmountPay] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const paymentMethods = useMemo(() => [
    { label: translations.payment_methods.bank_transfer, value: PaymentOptionsEnum.BANK_TRANSFER },
    { label: translations.payment_methods.card, value: PaymentOptionsEnum.CARD },
    { label: translations.payment_methods.cash, value: PaymentOptionsEnum.CASH },
    { label: translations.payment_methods.google_pay, value: PaymentOptionsEnum.GOOGLE_PAY },
    { label: translations.payment_methods.apple_pay, value: PaymentOptionsEnum.APPLE_PAY },
    { label: translations.payment_methods.other, value: PaymentOptionsEnum.OTHER },
  ], [translations]);

  const handleRecordPayment = () => {
    if (!user?.currentOrganisation?.id || !transaction || !transaction.id) {
      return;
    }

    setActive(false);
    dispatch(createInvoicePayment(
      user?.currentOrganisation?.id,
      {
        amount: +amountPay,
        paymentMethod,
      },
      transaction.id,
      transaction.type,
    ));
  };

  const handleClose = () => {
    setActive(false);
  };

  const changeAmountPay = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, min, max } = e.target;
    if (!value) {
      setAmountPay('');
      return;
    }

    setAmountPay(String(Math.max(Number(min), Math.min(Number(max), Number(value)))));
  };

  const handleChange = (e: ReactSelectOption | null) => {
    setPaymentMethod(String(e?.value));
  };

  useEffect(() => {
    if (active) {
      setAmountPay('');
    }
  }, [active]);

  if (!transaction) {
    return null;
  }

  return (
    <Modal active={active} setActive={setActive}>
      <div className={classes.content}>
        <CloseIcon
          className={classes.close_icon}
          onClick={handleClose}
        />
        <div className={classes.record_payment}>
          <h1 className={classes.title}>
            {translations.transactions.record_payment}
          </h1>
          <div className={classes.total_balance_remaining}>
            <p className={classes.total_balance_remaining_label}>
              {translations.transactions.total_balance_remaining}
            </p>
            <p className={classes.total_balance_remaining_value}>
              {formatCurrencyByISO(totalAmountPaymentSchedule)}
            </p>
          </div>
          <div className={nextSchedulePayment
            ? classes.next_schelude
            : classes.next_schelude_disabled}
          >
            <p className={classes.next_schelude_label}>
              {translations.transactions.next_scheduled_payment_amount}
            </p>
            <p className={classes.next_schelude_value}>
              {nextSchedulePayment
                ? formatCurrencyByISO(nextSchedulePayment)
                : translations.transactions.na}
            </p>
          </div>
          <div className={classes.amount_pay}>
            <p className={classes.amount_pay_label}>
              {translations.transactions.amount_to_pay.replace('{symbol}', `${user?.currentOrganisation?.country?.currencySymbol}`)}
            </p>
            <input
              className={classes.amount_pay_input}
              type="number"
              value={amountPay}
              onChange={changeAmountPay}
              placeholder={translations.transactions.enter_amount}
              max={totalAmountPaymentSchedule}
              min="1"
            />
          </div>
          <div className={classes.payment_method}>
            <p className={classes.payment_method_label}>
              {translations.orders.details.payment_method}
            </p>
            <Select
              name="paymentMethod"
              placeholder={translations.transactions.choose}
              options={paymentMethods}
              styles={customStyles}
              onChange={handleChange}
              menuPlacement={isMobile ? 'top' : 'bottom'}
            />
          </div>
          <button
            type="button"
            className={classes.record_payment_button}
            disabled={!(amountPay && paymentMethod)}
            onClick={handleRecordPayment}
          >
            {translations.transactions.record_payment}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RecordPayment;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
// Action
import { setNotificationOrderAction } from 'store/dashboard/orders/orderActions';
import { setNotificationTransactionAction } from 'store/dashboard/transaction/transactionActions';
// Images
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// Styles
import classes from './Notifications.module.scss';

interface Props {
  active: boolean;
  onClick: () => void;
  btnTitle: string;
  title: string;
  text: string;
  closeButton?: boolean;
  variant?: 'two';
  className?: string
}

const Notifications: React.FC<Props> = ({
  onClick, btnTitle, text, title, closeButton, variant, active, className,
}) => {
  const dispatch = useDispatch();
  const showHideClassName = active ? classes.modal_show : classes.modal_hide;

  useEffect(() => {
    setTimeout(() => {
      dispatch(setNotificationTransactionAction(null));
      dispatch(setNotificationOrderAction(null));
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames(
      variant,
      [classes.modal_second],
      [showHideClassName],
    )}
    >
      <div
        className={active ? classes.wrapper_active : classes.wrapper}
        tabIndex={0}
        role="button"
        onClick={(e) => e.stopPropagation()}
      >
        {closeButton && (
          <div
            aria-hidden="true"
            onClick={onClick}
            className={classes.close}
          >
            <CloseIcon />
          </div>
        )}
        <div className={className || classes.content}>
          <h1 className={classes.content_header}>
            {title}
          </h1>
          <p className={classes.subtitle}>
            {text}
          </p>
          <button
            type="button"
            onClick={onClick}
            className={classes.payment_pay_btn}
          >
            {btnTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notifications;

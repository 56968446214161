import React, { useCallback, useState } from 'react';
import { useQuery } from 'hooks/useQuery';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useHistory } from 'react-router-dom';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
// Components
import ShopProducts from '../ShopProducts';
// Styles
import 'react-tabs/style/react-tabs.scss';
import classes from './CategoriesTabs.module.scss';

interface CategoriesTabsProps {
  tabs: { id: string, name: string }[],
}

const CategoriesTabs: React.FC<CategoriesTabsProps> = ({ tabs }) => {
  const query = useQuery();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(tabs.findIndex((tab) => tab.id === query.get('categoryId')) + 1);
  const translations = useTranslationsStorefront();

  const changeCategories = useCallback((tab: { id: string, name: string | null }) => () => {
    if (!query.has('categoryId')) {
      query.append('categoryId', tab.id);
    } else {
      query.set('categoryId', tab.id);
    }

    if (query.get('categoryId') === 'all') {
      query.delete('categoryId');
    }

    history.replace({
      search: query.toString(),
    });
  }, [history, query]);

  return (
    <Tabs
      selectedTabPanelClassName={classes.react_tabs__tab_panel__selected}
      selectedTabClassName={classes.react_tabs__tab__selected}
      className={classes.react_tabs}
      selectedIndex={tabIndex}
      onSelect={(index) => setTabIndex(index)}
    >

      <TabList
        className={classes.react_tabs__tab_list}
      >
        <div className={classes.react_tabs__tab_wrapper}>
          <Tab
            key="all"
            className={classes.react_tabs__tab}
            onClick={changeCategories({ id: 'all', name: null })}
          >
            {translations.all}
          </Tab>
          {tabs.map((tab) => (
            <Tab key={tab.id} className={classes.react_tabs__tab} onClick={changeCategories(tab)}>
              {tab.name}
            </Tab>
          ))}
        </div>
      </TabList>
      <TabPanel key="all">
        <ShopProducts />
      </TabPanel>
      {tabs.map((tab) => (
        <TabPanel key={tab.id}>
          <ShopProducts />
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default CategoriesTabs;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Documents,
  Items, ItemType, TransactionStatus,
} from 'types/globalTypes';
import firebase from 'services/firebase';
import _ from 'lodash';
import 'firebase/remote-config';
import CountriesList from 'data/countries-list.json';
import {
  Axis,
  axisLeft, NumberValue, ScaleLinear, Series,
} from 'd3';
import { DataChart } from 'store/dashboard/report/reportTypes';
import phoneCodes from 'data/phone-codes.json';
import { ResTransaction, ResTransactionDetails } from 'store/dashboard/transaction/transactionTypes';
import { percentageRatio } from './price';

export const taxSum = (value: number, percent: number): number => (value * percent) / 100;

export const taxSumItem = (
  item: Items | ItemType,
): { excluded: number | null; included: number | null } => {
  let taxIncluded = null;
  let taxExcluded = null;
  if (item.taxes) {
    const included = item.taxes.filter(
      (tax: { included: boolean }): boolean => tax.included === true,
    );
    const excluded = item.taxes.filter((tax: { included: boolean }) => tax.included === false);
    if (included.length) {
      taxIncluded = included.reduce(
        (acc: number, tax: { amount: number; percentage: number }) => acc + tax.amount,
        0,
      );
    }
    if (excluded.length) {
      taxExcluded = excluded.reduce(
        (acc: number, tax: { amount: number; percentage: number }) => acc + tax.amount,
        0,
      );
    }
    return {
      excluded: Number((taxExcluded || 0).toFixed(2)),
      included: Number((taxIncluded || 0).toFixed(2)),
    };
  }
  return { excluded: 0, included: 0 };
};

export const calcTaxes = (
  items?: Items[],
): { excluded: number, included: number } => {
  if (items && items.some((item) => !!item.taxes)) {
    return {
      excluded: items.reduce(
        (acc, item) => acc + (taxSumItem(item).excluded || 0),
        0,
      ),
      included: items.reduce(
        (acc: number, item: Items) => acc + (taxSumItem(item).included || 0),
        0,
      ),
    };
  }

  return { excluded: 0, included: 0 };
};

export const calcTaxesQuote = (items: Items[]): { excluded?: number, included?: number } => {
  if (items && items.some((item) => !!item.taxes)) {
    return {
      excluded: items.reduce(
        (acc: number, item: Items) => acc + (taxSumItem(item).excluded || 0),
        0,
      ),
      included: items.reduce(
        (acc: number, item: Items) => acc + (taxSumItem(item).included || 0),
        0,
      ),
    };
  }
  return { excluded: undefined, included: undefined };
};

export const makeValue = (
  name?: string,
  value?: string,
): { name?: string; value?: string } => {
  if (name === undefined || value === undefined) {
    return { name: undefined, value: undefined };
  }
  return { name, value };
};

export const getStatus = (id: number): TransactionStatus => {
  if (id === 1) return TransactionStatus.UNPAID;
  if (id === 2) return TransactionStatus.PAID;
  if (id === 3) return TransactionStatus.PENDING;
  if (id === 4) return TransactionStatus.ACCEPTED;
  if (id === 5) return TransactionStatus.REJECTED;
  if (id === 6) return TransactionStatus.UNPROCESSED;
  if (id === 7) return TransactionStatus.PROCESSED;
  if (id === 8) return TransactionStatus.CANCELLED;
  return TransactionStatus.UNPAID;
};

export const currencyFormat = (price: number, currencyIso: string): string => {
  const formatCurrency = new Intl.NumberFormat('nb-NB', {
    style: 'currency',
    currency: currencyIso,
    currencyDisplay: 'narrowSymbol',
  })
    .format(price)
    .replace(/^(\D+)/, '$1 ');

  return formatCurrency;
};

export const getRemoteConfig = (): firebase.remoteConfig.RemoteConfig => {
  const defaultRemoteConfig = firebase.remoteConfig();
  defaultRemoteConfig.defaultConfig = {
    AppStoreURL: 'https://play.google.com/store/apps',
    AndroidApp: 'https://play.google.com/store/apps',
    IosApp: 'https://play.google.com/store/apps',
    HelpCenter: 'https://leja.crunch.help/',
  };

  return defaultRemoteConfig;
};

// Function opens new browser tab for download google spreadsheat
export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const isEmptyObject = <T>(obj: T): boolean => _.isEmpty(obj);

export const isEmptyArray = (arr: unknown[]): boolean => (arr).length === 0;

export const logEvent = (name: unknown) => (): void => {
  // eslint-disable-next-line no-console
  console.log(`[${name}]`);
};

export const deletePropObj = (
  object: Record<string, unknown>,
  prop: string,
): Record<string, unknown> => Object.keys(object).reduce((obj: { [key: string]: unknown }, key) => {
  if (key !== prop) {
    obj[key] = object[key];
  }
  return obj;
}, {});

export interface ICalcHighLowMonth {
  highestMonth: {
    value: number,
    month: string,
    percentage: number,
  },
  lowestMonth: {
    value: number,
    month: string,
    percentage: number,
  }
}

export const CalcHighLowMonth = (data: DataChart[]): ICalcHighLowMonth | null => {
  const sortedData = [...data].slice().sort((curr, prev) => curr.value - prev.value)
    .filter((i) => i.value !== 0);

  if (!sortedData.length) {
    return null;
  }

  const valuesArr = sortedData.map((sortItem) => sortItem.value);

  const minValue = Math.min.apply(null, valuesArr);

  const minData = sortedData.find((item) => item.value === minValue);

  const maxValue = Math.max.apply(null, valuesArr);

  const maxData = sortedData.find((item) => item.value === maxValue);

  const prevHighValue = sortedData[sortedData.length - 2]
    ? sortedData[sortedData.length - 2].value
    : 0;

  const prevLowValue = sortedData[1]
    ? sortedData[1].value
    : sortedData[0].value;

  return {
    highestMonth: {
      value: maxData?.value || 0,
      month: maxData?.month || '',
      percentage: percentageRatio(prevHighValue,
        maxData?.value || 0),
    },
    lowestMonth: {
      value: minData?.value || 0,
      month: minData?.month || '',
      percentage: percentageRatio(
        prevLowValue,
        minData?.value || 0,
      ),
    },
  };
};

export const calcLayer = (layer: Series<{
  [key: string]: number;
}, string>): Record<string, {
  data: string;
  type: number;
  value: number;
  month: string;
  extra?: boolean;
}>[] => {
  const chunk = _.chunk(layer, 2);
  const groupChunk = chunk.map((item) => item.sort((a, b) => {
    if (a.data.value < b.data.value) {
      return 1;
    }

    if (a.data.value > b.data.value) {
      return -1;
    }

    return 0;
  }));
  const addExtraEl = groupChunk.flatMap((item) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const extraEl: any = [...item[1]];
    extraEl.data = { ...item[1].data };
    extraEl.data.extra = 1;
    return [...item, extraEl];
  });
  return addExtraEl;
};

export const yAixDraw = (
  yScale: ScaleLinear<number, number, never>,
): Axis<NumberValue> => axisLeft(yScale).tickFormat(
  (d) => {
    if (d === 0) {
      return `${d}`;
    }

    return '';
  },
);

export const checkStatusExpired = (
  data: ResTransactionDetails | ResTransaction | Documents,
): TransactionStatus => {
  if (data.statusId === 6) {
    return TransactionStatus.PENDING;
  }

  if (data.statusId === 3 && data.type === 'PURCHASE_ORDER') {
    if (data.total) {
      return TransactionStatus.PRICED;
    }

    if (data?.expiryDate && Date.parse(data?.expiryDate) <= Date.now()) {
      return TransactionStatus.EXPIRED;
    }
  }

  if (
    data.statusId === 3
    && data?.terms?.paymentDueDate
    && Date.parse(data?.terms?.paymentDueDate) <= Date.now()) {
    return TransactionStatus.EXPIRED;
  }
  return getStatus(data.statusId);
};

export const defaultCountry = (
  iso: string | undefined,
): { label: string, value: string } | undefined => CountriesList.find(
  (item) => item.value === iso,
);

export const getCountryIso = (
  name: string | undefined,
): { label: string, value: string } | undefined => CountriesList.find(
  (item) => item.label === name,
);

export const getDefaultCode = (
  codes: typeof phoneCodes, countryIso: string | undefined,
): string => {
  const defaultCode = codes.find((code) => code.code === countryIso);

  return defaultCode?.dial_code || '';
};

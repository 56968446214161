import React from 'react';
// Constants
import { PaymentsOptions, PayMethodsEnum } from 'constants/paymentsMethods';
// Utils
import { PaymentFunctions } from 'utils/storefront/PaymentFunctions';
// Components
import { useTypeSelector } from 'hooks/useTypeSelector';
import PayPal from './PayPal';
// Styles
import classes from './ExpressPayments.module.scss';
import ExpressPaymentButton from './ExpressPaymentButton';

interface Props {
  handlePay: (paymentMethodTypes: PayMethodsEnum) => Promise<void>
  paymentModal: () => void
  checkSupportStripe: boolean
}

const ExpressPayments: React.FC<Props> = ({ handlePay, paymentModal, checkSupportStripe }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const applePay = (window as any).ApplePaySession;
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const currencyIso = currentSalesChannel?.address.currencyISO || 'EUR';

  const getExpressPaymentOptions = PaymentFunctions.expressPayments(
    applePay,
    PaymentsOptions,
    currentSalesChannel?.address?.countryISO,
    currencyIso,
  );

  return (
    <div className={classes.express_card_cards}>
      {
        getExpressPaymentOptions.map((expressOption) => (
          <ExpressPaymentButton
            key={expressOption.name}
            type={expressOption.name}
            expressOption={expressOption}
            handlePay={handlePay}
            checkSupportStripe={checkSupportStripe}
          />
        ))
      }
      <PayPal closePaymentModal={paymentModal} />
    </div>
  );
};

export default ExpressPayments;

/* eslint-disable no-undef */
export type AutocompletePrediction = google.maps.places.AutocompletePrediction;

export const getGooglePlaces = async (
  value: string, countryIso: string,
): Promise<AutocompletePrediction[] | null> => {
  try {
    const service = new google.maps.places.AutocompleteService();

    const options: google.maps.places.AutocompletionRequest = {
      input: value,
      componentRestrictions: { country: countryIso },
      types: ['address'],
    };

    const { predictions } = await service.getPlacePredictions(options);

    return predictions;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return null;
  }
};

export const getPlaceDetail = async (
  placeId: string,
): Promise<google.maps.places.PlaceResult | null> => {
  const service = new google.maps.places.PlacesService(document.createElement('div'));
  // return promise
  return new Promise((resolve, reject) => {
    // use getDetails method to retrieve Place data via the Place's place_id property
    service.getDetails({ placeId, fields: ['address_components', 'name'] }, (placeDetails, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        // upon successful request resolve place
        resolve(placeDetails);
      } else {
        // else reject with status
        reject(status);
      }
    });
  });
};

import { PaymentIntentResult, Stripe } from '@stripe/stripe-js';

export const grabPay = async (
  clientSecret: string,
  stripe: Stripe,
): Promise<PaymentIntentResult> => {
  const paymentIntentResult = await stripe.confirmGrabPayPayment(clientSecret, {
    return_url: window.location.href,
  });
  return paymentIntentResult;
};

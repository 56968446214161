import React from 'react';
import { UseFormReturn } from 'react-hook-form';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Styles
import classes from './Sofort.module.scss';

interface Props {
  register: UseFormReturn['register']
}

const Sofort: React.FC<Props> = ({ register }) => {
  const { checkout } = useTranslationsStorefront();

  return (
    <>
      <label
        htmlFor="fullName"
        className={classes.input}
      >
        <span>
          {checkout.card_form.name_label}
        </span>
        <input
          {...register('fullName', { required: true })}
        />
      </label>
      <label
        htmlFor="email"
        className={classes.input}
      >
        <span>
          {checkout.card_form.email_label}
        </span>
        <input
          {...register('email', {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: checkout.email_input.error,
            },
          })}
        />
      </label>
      <div className={classes.description}>
        {checkout.description_bank_redirect_pay}
      </div>
    </>
  );
};

export default Sofort;

import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
import AccountLayout from 'layouts/StorefrontLayout/AccountLayout';
import React, {
  useCallback, useMemo, useState,
} from 'react';
// Styles
import classes from './AccountAddress.module.scss';
import AddressModal from './AddressModal';

const AccountAddress: React.FC = () => {
  const { contact } = useTypeSelector(({ storefront }) => storefront.account);
  const translations = useTranslationsStorefront();
  const [addressModal, setAddressModal] = useState(false);

  const contactInfo = useMemo(() => [
    `${contact?.firstName} ${contact?.lastName}`,
    `${contact?.address?.line1}, ${contact?.address?.line2}`,
    `${contact?.address?.city} ${contact?.address?.postalCode} ${contact?.address?.country}`,
    contact?.mobileNumber,
    contact?.email,
  ], [contact]);

  const handleSetAddressModal = useCallback(() => {
    setAddressModal((prev) => !prev);
  }, []);

  if (!contact) {
    return <AccountLayout />;
  }

  return (
    <AccountLayout>
      <div className={classes.address}>
        <div className={classes.address_info}>
          {contactInfo.map((info, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={i}>
              {info}
            </p>
          ))}
        </div>
        <button type="button" className={classes.address_button} onClick={handleSetAddressModal}>
          {translations.account.address.edit_btn}
        </button>
      </div>
      <AddressModal active={addressModal} setActive={handleSetAddressModal} />
    </AccountLayout>
  );
};

export default AccountAddress;

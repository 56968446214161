import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
// Actions
import { acceptQuoteAction, TransactionAcceptAction } from 'store/dashboard/transaction/transactionActions';
import { selectTransactionDetails } from 'store/dashboard/transaction/transactionSelectors';
// Types
import { TransactionStatus } from 'types/globalTypes';
// Utils
import { dateToFormat } from 'utils/dates';
import { CurrencyFormatByISO } from 'utils/price';
// Components
import ConfirmModal from 'components/Modal/ConfirmModal';
import Create from '../CreateModal';
// Styles
import classes from './Quote.module.scss';

interface Props {
  handleSendReminderModal: () => void;
}

const Quote: React.FC<Props> = ({ handleSendReminderModal }) => {
  const user = useUser();
  const { acceptQuote } = useTypeSelector(({ transaction }) => transaction);
  const transaction = useTypeSelector(selectTransactionDetails);
  const [modalAcceptQuote, setModalAcceptQuoteActive] = useState(false);
  const [modalConfirmationQuote, setModalConfirmationQuoteActive] = useState(false);
  const [modalCreateBill, setModalCreateBillActive] = useState(false);
  const formatCurrencyByISO = CurrencyFormatByISO(transaction?.currencyISO);
  const dispatch = useDispatch();
  const translations = useTranslations();
  const isExpired = transaction?.status === TransactionStatus.EXPIRED;

  const handleAcceptQuote = () => {
    if (!transaction) {
      return;
    }
    dispatch(acceptQuoteAction(`${transaction?.id}`, transaction, user?.currentOrganisation?.id));
  };

  const handleCloseModal = () => {
    dispatch(TransactionAcceptAction(false));
    setModalConfirmationQuoteActive(false);
  };

  useEffect(() => {
    setModalConfirmationQuoteActive(acceptQuote.isSuccess);
  }, [acceptQuote.isSuccess]);

  if (!transaction) {
    return null;
  }

  if (transaction.status === TransactionStatus.PENDING) {
    return (
      <div className={classes.footer_pending}>
        <div className={classes.footer_remaining_balance}>
          <p className={classes.footer_remaining_balance_label}>
            {translations.transactions.quote_expires.replace('{date}', `${dateToFormat(transaction.dueDate, 'DD MMM')}`)}
          </p>
          <p className={classes.footer_remaining_balance_value}>
            {translations.transactions.pending_vendor_response}
          </p>
        </div>
        <div className={classes.footer_buttons}>
          <button
            className={classes.footer_remaining_balance_button}
            type="button"
            onClick={handleSendReminderModal}
          >

            {translations.transactions.send_reminder}
          </button>
        </div>
      </div>
    );
  }

  if (transaction.status === TransactionStatus.PRICED) {
    return (
      <div className={classes.footer_priced}>
        <div className={classes.footer_remaining_balance}>
          <p className={classes.footer_remaining_balance_label}>
            {translations.transactions.total_quote_amount}
          </p>
          <p className={classes.footer_remaining_balance_value}>
            {formatCurrencyByISO(transaction.price.total)}
          </p>
        </div>
        <div className={classes.footer_buttons}>
          <button
            className={classes.footer_record_payment_button}
            type="button"
            onClick={() => setModalAcceptQuoteActive(true)}
          >
            {translations.transactions.accept}
          </button>
        </div>
        <ConfirmModal
          setActive={setModalAcceptQuoteActive}
          active={modalAcceptQuote}
          data={{
            title: translations.transactions.accept_quote_modal.title,
            description: translations.transactions.accept_quote_modal.description.replace('{VendorName}', `${transaction.contact?.firstName}`),
            button_first: translations.transactions.accept_quote_modal.no,
            button_second: translations.transactions.accept_quote_modal.yes,
            onClick: handleAcceptQuote,
          }}
        />
      </div>
    );
  }

  return (
    <div className={classes.footer_paid}>
      <div className={classes.footer_remaining_balance}>
        <p className={classes.footer_remaining_balance_label}>
          {translations.documents.quotes.quote_total}
        </p>
        <p className={classes.footer_remaining_balance_value}>
          {isExpired ? formatCurrencyByISO('tbd') : formatCurrencyByISO(transaction.price.total)}
        </p>
      </div>
      <div className={classes.footer_buttons}>
        <button
          className={classes.footer_record_payment_button}
          type="button"
          disabled={isExpired}
          onClick={() => setModalCreateBillActive(true)}
        >
          {isExpired
            ? translations.documents.quotes.expired
            : translations.transactions.create_bill}
        </button>
      </div>
      <ConfirmModal
        setActive={setModalConfirmationQuoteActive}
        active={modalConfirmationQuote}
        data={{
          title: translations.transactions.create_bill_modal_first.title,
          description: translations.transactions.create_bill_modal_first.description.replace('{QuoteVendorName}', `${transaction.contact?.firstName}`),
          button_first: translations.transactions.create_bill_modal_first.later,
          button_second: translations.transactions.create_bill_modal_first.create_bill,
          onClick: handleAcceptQuote,
        }}
        closeModal={handleCloseModal}
      />
      <Create
        active={modalCreateBill}
        setActive={setModalCreateBillActive}
        type="Bill"
      />
    </div>
  );
};

export default Quote;

import { Stripe, StripeError } from '@stripe/stripe-js';

export const afterpayClearpay = async (
  clientSecret: string,
  stripe: Stripe,
  data: {
    fullname: string,
    email: string,
    address: string,
    address2: string,
    city: string,
    state: string,
    country: string
    postalCode: string,
    shipping: {
      fullname: string,
      address: string,
      address2: string,
      city: string,
      state: string,
      postalCode: string,
    }
  },
  returnUrl: string,
): Promise<StripeError | undefined> => {
  const { error } = await stripe.confirmAfterpayClearpayPayment(
    clientSecret,
    {
      payment_method: {
        billing_details: {
          name: data.fullname,
          email: data.email,
          address: {
            line1: data.address,
            line2: data.address2,
            city: data.city,
            state: data.state,
            country: data.country,
            postal_code: data.postalCode,
          },
        },
      },
      shipping: {
        name: data.shipping.fullname,
        address: {
          line1: data.shipping.address,
          line2: data.shipping.address2,
          city: data.shipping.city,
          state: data.shipping.state,
          country: data.country,
          postal_code: data.shipping.postalCode,
        },
      },
      return_url: returnUrl,
    },
  );
  return error;
};

import ProductAvatar from 'components/StorefrontComponents/ProductAvatar';
import React from 'react';
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
// Types
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Utils
import { CurrencyFormatRange } from 'utils/price';
// Classes
import classes from './Product.module.scss';

interface Props {
  product: ProductItem
  translations: TranslationStorefrontModel
  openModalDetails: (productId: string) => void
  currencyISO: string;
  variations: (product: ProductItem) => number
}

const Product: React.FC<Props> = ({
  product, translations, openModalDetails, currencyISO, variations,
}) => (
  <div
    className={classes.item_container}
  >
    <button
      className={classes.item}
      type="button"
      onClick={() => openModalDetails(product.id)}
      disabled={!product.stock}
    >
      <div className={product.stock
        ? classes.item_description
        : classes.item_description_sold__out}
      >
        {!!product.stock
          && product.stock < 6
          && (
            <div className={classes.item_description_count}>
              {`${product.stock} ${translations.cart.warning.left}`}
            </div>
          )}
        {!product.stock && (
          <div className={classes.item_description_count}>
            {translations.cart.cart_item.quantity_sold_out}
          </div>
        )}
        <h3 className={classes.item_description_name}>
          {product.name}
        </h3>
        <span className={classes.item_description_price}>
          {CurrencyFormatRange(
            product.priceRange,
            currencyISO,
          )}
        </span>
        {product?.options?.length > 1 && (
          <span className={classes.item_options}>
            {`${variations(product)} ${translations.options}`}
          </span>
        )}
      </div>
      <ProductAvatar name={product.name} images={product.images} />
    </button>
  </div>
);

export default Product;

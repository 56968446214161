import {
  CATALOG_ITEMS_SET_PRODUCTS,
  CATALOG_ITEMS_SET_CATEGORIES,
  CATALOG_ITEMS_SEARCH,
  CATALOG_ITEMS_SET_LOADING,
  CATALOG_ITEMS_SET_CURRENT_PRODUCT,
  CATALOG_ITEMS_SET_CURRENT_PRODUCT_LOADING,
  CATALOG_ITEMS_SET_CURRENT_PRODUCT_ERROR,
  ItemsState,
  ItemsActions,
  CATALOG_ITEMS_IS_NEXT_PAGE_ITEMS,
  CATALOG_ITEMS_SET_SUMMARY,
  CATALOG_ITEMS_SET_UNITS,
  CATALOG_ITEMS_SET_LOCATIONS,
  CATALOG_ITEMS_SET_MORE_PRODUCTS,
  CATALOG_ITEMS_SET_PRODUCTS_LOADING,
} from './itemsTypes';

const initialState: ItemsState = {
  products: {
    data: null,
    loading: false,
    isNextPage: false,
    error: null,
  },
  categories: null,
  search: {
    data: [],
    loading: false,
    error: false,
  },
  currentProduct: {
    product: null,
    loading: false,
    error: false,
  },
  loading: false,
  summary: null,
  units: [],
  locations: null,
  isMoreVariations: false,
};

export const itemsReducer = (
  state: ItemsState = initialState,
  action: ItemsActions,
): ItemsState => {
  switch (action.type) {
    case CATALOG_ITEMS_SET_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          data: action.payload,
        },
      };
    case CATALOG_ITEMS_SET_MORE_PRODUCTS:

      if (state.products.data?.length) {
        return {
          ...state,
          products: {
            ...state.products,
            data: [...state.products.data, ...action.payload],
          },
        };
      }

      return state;
    case CATALOG_ITEMS_SET_PRODUCTS_LOADING:
      return {
        ...state,
        products: {
          ...state.products,
          loading: action.payload,
        },
      };
    case CATALOG_ITEMS_SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case CATALOG_ITEMS_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CATALOG_ITEMS_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case CATALOG_ITEMS_SET_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          product: action.payload,
        },
      };
    case CATALOG_ITEMS_SET_CURRENT_PRODUCT_LOADING:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          loading: action.payload,
        },
      };
    case CATALOG_ITEMS_SET_CURRENT_PRODUCT_ERROR:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          error: action.payload,
        },
      };
    case CATALOG_ITEMS_IS_NEXT_PAGE_ITEMS:
      return {
        ...state,
        products: {
          ...state.products,
          isNextPage: action.payload,
        },
      };
    case CATALOG_ITEMS_SET_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case CATALOG_ITEMS_SET_UNITS:
      return {
        ...state,
        units: action.payload,
      };
    case CATALOG_ITEMS_SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    default:
      return state;
  }
};

import { useTypeSelector } from 'hooks/useTypeSelector';
import React from 'react';
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { QuotePriceType, QuoteTermsPaymentType } from 'store/quote/quoteTypes';
import { PriceType } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import { quoteCurrencyFormatByISO } from 'utils/price';
// Styles
import classes from './Footer.module.scss';

interface Props {
  handleClick?: () => void;
  isUnpaid?: boolean;
  type: TransactionTypes
  translations: TranslationDashboardModel;
  price: PriceType | QuotePriceType;
  termsPayment: QuoteTermsPaymentType
  status?: string;
}

const Footer: React.FC<Props> = ({
  isUnpaid,
  handleClick,
  type,
  translations,
  price,
  termsPayment,
}) => {
  const formatCurrencyByISO = quoteCurrencyFormatByISO(price.currencyIso);
  const { sent } = useTypeSelector((state) => state.quote);

  if (sent) {
    return (
      <div className={classes.footer}>
        <div className={classes.unpaid_invoice}>
          <p className={classes.unpaid_invoice_title}>
            {translations.documents.quotes.quote_total}
          </p>
          <p className={classes.unpaid_invoice_total}>
            {formatCurrencyByISO(price.total)}
          </p>
        </div>
        <button
          type="button"
          className={classes.btn}
          onClick={handleClick}
          disabled={!isUnpaid || sent}
        >
          {translations.documents.quotes.pricing_sent}
        </button>
      </div>
    );
  }

  if (!termsPayment.isExpired && type === TransactionTypes.PURCHASE_ORDER) {
    return (
      <div className={classes.footer}>
        <div className={classes.unpaid_invoice}>
          <p className={classes.unpaid_invoice_title}>
            {translations.documents.quotes.quote_total}
          </p>
          <p className={classes.unpaid_invoice_total}>
            {formatCurrencyByISO(price.total)}
          </p>
        </div>
        <button
          type="button"
          className={classes.btn}
          onClick={handleClick}
          disabled={!isUnpaid || sent || Number(price?.total) < 0}
        >
          {translations.documents.quotes.send_pricing}
        </button>
      </div>
    );
  }

  if (termsPayment.isExpired && type === TransactionTypes.PURCHASE_ORDER) {
    return (
      <div className={classes.footer}>
        <div className={classes.unpaid_invoice}>
          <p className={classes.unpaid_invoice_title}>
            {translations.documents.quotes.quote_total}
          </p>
          <p className={classes.unpaid_invoice_total}>
            {formatCurrencyByISO(price.total)}
          </p>
        </div>
        <button
          type="button"
          className={classes.btn}
          onClick={handleClick}
          disabled={!isUnpaid || sent}
        >
          {translations.documents.quotes.expired}
        </button>
      </div>
    );
  }

  return (
    <div className={classes.footer} />
  );
};

export default Footer;

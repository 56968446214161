import React from 'react';
// Styles
import classes from './PaymentNotification.module.scss';

interface Props {
  handleClick?: () => void;
  title: string;
  description: string;
  buttonName?: string;
  colorButton: string;
}

const PaymentNotification: React.FC<Props> = ({
  handleClick,
  title,
  description,
  buttonName,
  colorButton,
}) => (
  <div className={classes.root}>
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.content_wrapper}>
          <h3 className={classes.title}>
            {title}
          </h3>
          <p className={classes.description} style={{ marginTop: buttonName ? '0' : '50px' }}>
            {description}
          </p>
        </div>
        {buttonName && (
          <button
            type="button"
            className={classes.button}
            style={{ backgroundColor: colorButton }}
            onClick={handleClick}
          >
            {buttonName}
          </button>
        )}
      </div>
    </div>
  </div>
);

export default PaymentNotification;

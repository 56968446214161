import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Link, useLocation, useParams,
} from 'react-router-dom';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Assets
import { ReactComponent as UserIcon } from 'assets/storefront-icons/user.svg';
// Actions
import { signOut } from 'store/storefront/account/accountActions';
// Styles;
import { auth } from 'services/firebase';
import InfoModal from 'pages/Storefront/Checkout/InfoModal';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import classes from './Account.module.scss';

const Account: React.FC = () => {
  const dispatch = useDispatch();
  const { goToShop, getAccountPath } = useStorefrontRoutes();
  const [isUser, setIsUser] = useState(false);
  const [activeSignInModal, setSignInModal] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useMobileScreen();
  const location = useLocation();
  const translations = useTranslationsStorefront();
  const handleSignOut = useCallback(() => {
    dispatch(signOut());
    setIsUser(true);
    goToShop(id, location.search);
  }, [dispatch, goToShop, id, location]);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setIsUser(true);
      } else {
        setIsUser(false);
      }
    });
  }, []);

  const handleActiveModal = useCallback(() => {
    setSignInModal((prev) => !prev);
  }, []);

  if (isMobile) {
    return (
      <div className={classes.account}>
        {!isUser
          ? (
            <Link to={getAccountPath(id)} className={classes.link}>
              {translations.account.overview}
            </Link>
          ) : null}
        {!isUser
          ? (
            <button type="button" className={classes.sign_out} onClick={handleSignOut}>
              <UserIcon />
              {translations.account.sign_out_btn}
            </button>
          ) : (
            <button type="button" className={classes.sign_out} onClick={handleActiveModal}>
              <UserIcon />
              {translations.account.sign_in_btn}
            </button>
          )}
        <InfoModal active={activeSignInModal} setActive={handleActiveModal} />
      </div>
    );
  }

  return (
    <div className={classes.account}>
      <h3>
        {translations.account.title}
      </h3>
      {!isUser
        ? (
          <Link to={getAccountPath(id)} className={classes.link}>
            {translations.account.overview}
          </Link>
        ) : null}
      {!isUser
        ? (
          <button type="button" className={classes.sign_out} onClick={handleSignOut}>
            <UserIcon />
            {translations.account.sign_out_btn}
          </button>
        ) : (
          <button type="button" className={classes.sign_out} onClick={handleActiveModal}>
            <UserIcon />
            {translations.account.sign_in_btn}
          </button>
        )}
      <InfoModal active={activeSignInModal} setActive={handleActiveModal} />
    </div>
  );
};

export default Account;

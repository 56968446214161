/* eslint-disable prefer-destructuring */
import {
  TRANSACTION_SET_DETAIL_ITEM,
  TransactionActions,
  TransactionState,
  TRANSACTION_GET_DATA,
  TRANSACTION_LOADING,
  TRANSACTION_DOCUMENTS_GET_DATA,
  TRANSACTION_DOCUMENTS_MORE_GET_DATA,
  TRANSACTION_MORE_PAGE,
  TRANSACTION_APP_SET_NOTIFICATION_TRANSACTION,
  TRANSACTION_RECORD_PAYMENT_LOADING,
  TRANSACTION_RECORD_PAYMENT_SUCCESS,
  TRANSACTION_REMINDER_SUCCESS,
  TRANSACTION_REMINDER_LOADING,
  TRANSACTION_ACCEPT_LOADING,
  TRANSACTION_ACCEPT_SUCCESS,
} from './transactionTypes';

const initialState: TransactionState = {
  loading: false,
  documents: [],
  detailItem: null,
  morePage: false,
  notification: null,
  recordPayment: {
    loading: false,
    isSuccess: null,
  },
  reminder: {
    loading: false,
    isSuccess: false,
  },
  acceptQuote: {
    loading: false,
    isSuccess: false,
  },
};

export const transactionReducer = (
  state: TransactionState = initialState,
  action: TransactionActions,
): TransactionState => {
  switch (action.type) {
    case TRANSACTION_GET_DATA:
      return {
        ...state,
        loading: false,
      };
    case TRANSACTION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case TRANSACTION_MORE_PAGE:
      return {
        ...state,
        morePage: action.payload,
      };
    case TRANSACTION_DOCUMENTS_MORE_GET_DATA: {
      const sortedDocuments = [...state.documents, ...action.payload].sort(
        (a, b) => Date.parse(b.date) - Date.parse(a.date),
      );
      return {
        ...state,
        documents: sortedDocuments,
      };
    }

    case TRANSACTION_DOCUMENTS_GET_DATA: {
      return {
        ...state,
        documents: action.payload,
      };
    }

    case TRANSACTION_SET_DETAIL_ITEM:
      return {
        ...state,
        detailItem: action.payload,
      };
    case TRANSACTION_APP_SET_NOTIFICATION_TRANSACTION:
      return {
        ...state,
        notification: action.payload,
      };
    case TRANSACTION_RECORD_PAYMENT_LOADING:
      return {
        ...state,
        recordPayment: {
          ...state.recordPayment,
          loading: action.payload,
        },
      };
    case TRANSACTION_RECORD_PAYMENT_SUCCESS:
      return {
        ...state,
        recordPayment: {
          ...state.recordPayment,
          isSuccess: action.payload,
        },
      };
    case TRANSACTION_REMINDER_LOADING:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          loading: action.payload,
        },
      };
    case TRANSACTION_REMINDER_SUCCESS:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          isSuccess: action.payload,
        },
      };
    case TRANSACTION_ACCEPT_LOADING:
      return {
        ...state,
        acceptQuote: {
          ...state.acceptQuote,
          loading: action.payload,
        },
      };
    case TRANSACTION_ACCEPT_SUCCESS:
      return {
        ...state,
        acceptQuote: {
          ...state.acceptQuote,
          isSuccess: action.payload,
        },
      };
    default:
      return state;
  }
};

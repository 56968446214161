import React, { useMemo, useState } from 'react';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { selectTransactionDetails } from 'store/dashboard/transaction/transactionSelectors';
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
// Types
import { ResPaymentStatus, TransactionStatus } from 'types/globalTypes';
// Utils
import { dateToFormat } from 'utils/dates';
import { CurrencyFormatByISO } from 'utils/price';
// Components
import Create from './CreateModal';
import Quote from './Quote';
// Styles
import classes from './Footer.module.scss';

interface Props {
  handleSendReminderModal: () => void;
  handleOpenRecordPayment: (amount?: number) => void;
}

const Footer: React.FC<Props> = ({
  handleSendReminderModal,
  handleOpenRecordPayment,
}) => {
  const transaction = useTypeSelector(selectTransactionDetails);
  const [modalCreateInvoice, setModalCreateInvoiceActive] = useState(false);
  const formatCurrencyByISO = CurrencyFormatByISO(transaction?.currencyISO);
  const translations = useTranslations();
  const isPaid = transaction?.status === TransactionStatus.PAID;

  const handleOpenCreateInvoice = () => {
    setModalCreateInvoiceActive(true);
  };

  const remainingBalance = useMemo(() => {
    if (transaction?.terms) {
      const unpaidPaymentSchedule = transaction?.terms?.paymentSchedule?.filter(
        (schedule) => schedule.status !== ResPaymentStatus.PAID,
      );
      if (unpaidPaymentSchedule?.length) {
        return {
          amount: unpaidPaymentSchedule[0].balance || unpaidPaymentSchedule[0].amount,
          date: unpaidPaymentSchedule[0].date,
        };
      }

      if (transaction?.type === TransactionTypes.SALES_ORDER) {
        return {
          amount: transaction?.balance,
          date: transaction?.terms?.paymentDueDate,
        };
      }

      return {
        amount: null,
      };
    }
    return {
      amount: null,
    };
  }, [transaction?.type, transaction?.balance, transaction?.terms]);

  const remainingBalanceLabel = useMemo(() => {
    if (transaction?.type === TransactionTypes.SALES_ORDER) {
      if (transaction?.status === TransactionStatus.PENDING) {
        return translations.transactions.estimate_expires.replace('{date}', dateToFormat(remainingBalance.date, 'DD MMM'));
      }
      return translations.transactions.estimate_total;
    }

    if (remainingBalance.date) {
      return translations.transactions.overview_next_payment_due.replace('{date}', dateToFormat(remainingBalance.date, 'DD MMM'));
    }

    return translations.transactions.total_balance_remaining;
  }, [translations, transaction?.type, transaction?.status, remainingBalance?.date]);

  if (!transaction) {
    return null;
  }

  if (transaction.type === TransactionTypes.PURCHASE_ORDER) {
    return (
      <Quote
        handleSendReminderModal={handleSendReminderModal}
      />
    );
  }

  if (transaction.status === TransactionStatus.ACCEPTED
    || transaction.status === TransactionStatus.EXPIRED) {
    const buttonTitle = () => {
      if (transaction.status === TransactionStatus.EXPIRED) {
        return translations.documents.estimate.expired;
      }
      if (transaction.associations) {
        return translations.documents.estimate.accepted;
      }
      return translations.documents.estimate.create_invoice;
    };

    return (
      <div className={classes.footer_paid}>
        <div className={classes.footer_remaining_balance}>
          <p className={classes.footer_remaining_balance_label}>
            {remainingBalanceLabel}
          </p>
          <p className={classes.footer_remaining_balance_value}>
            {formatCurrencyByISO(remainingBalance.amount || transaction.balance)}
          </p>
        </div>
        <div className={classes.footer_buttons}>
          <button
            className={classes.footer_record_payment_button}
            type="button"
            disabled={(!!transaction.associations)
              || transaction.status === TransactionStatus.EXPIRED}
            onClick={handleOpenCreateInvoice}
          >
            {buttonTitle()}
          </button>
        </div>
        <Create
          active={modalCreateInvoice}
          setActive={setModalCreateInvoiceActive}
          type="Invoice"
        />
      </div>
    );
  }

  if (transaction?.type === TransactionTypes.SALES_ORDER
    && transaction.status === TransactionStatus.PENDING) {
    return (
      <div className={classes.footer_pending}>
        <div className={classes.footer_remaining_balance}>
          <p className={classes.footer_remaining_balance_label}>
            {remainingBalanceLabel}
          </p>
          <p className={classes.footer_remaining_balance_value}>
            {formatCurrencyByISO(remainingBalance.amount || transaction.balance)}
          </p>
        </div>
        <div className={classes.footer_buttons}>
          <button
            className={classes.footer_remaining_balance_button}
            type="button"
            onClick={handleSendReminderModal}
          >
            {translations.transactions.send_reminder}
          </button>
        </div>
      </div>
    );
  }

  if (isPaid || transaction?.type !== TransactionTypes.INVOICE) {
    return (
      <div className={classes.footer_paid}>
        <div className={classes.footer_remaining_balance}>
          <p className={classes.footer_remaining_balance_label}>
            {remainingBalanceLabel}
          </p>
          <p className={classes.footer_remaining_balance_value}>
            {formatCurrencyByISO(remainingBalance.amount || transaction.balance)}
          </p>
        </div>
        <div className={classes.footer_buttons}>
          <button
            className={classes.footer_record_payment_button}
            type="button"
            disabled={isPaid}
            onClick={() => handleOpenRecordPayment(remainingBalance.amount || 0)}
          >
            {isPaid ? translations.transactions.paid : translations.transactions.record_payment}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.footer}>
      <div className={classes.footer_remaining_balance}>
        <p className={classes.footer_remaining_balance_label}>
          {remainingBalanceLabel}
        </p>
        <p className={classes.footer_remaining_balance_value}>
          {formatCurrencyByISO(remainingBalance.amount || transaction.balance)}
        </p>
      </div>
      <div className={classes.footer_buttons}>
        <button
          className={classes.footer_remaining_balance_button}
          type="button"
          onClick={handleSendReminderModal}
        >
          {translations.transactions.send_reminder}
        </button>
        <button
          className={classes.footer_record_payment_button}
          type="button"
          disabled={isPaid}
          onClick={() => handleOpenRecordPayment(remainingBalance.amount || 0)}
        >
          {isPaid ? translations.transactions.paid : translations.transactions.record_payment}
        </button>
      </div>
    </div>

  );
};

export default Footer;

import React from 'react';
// Types
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Components
import PaymentNotification from 'components/PaymentNotification';

interface Props {
  payment: {
    loading: string | null;
    error: string | null;
    success: boolean;
  };
  translation: TranslationStorefrontModel['checkout']['notifications'];
  shopName: string;
}

const Notifications: React.FC<Props> = ({
  payment,
  translation,
  shopName,
}) => {
  if (payment.error) {
    return (
      <PaymentNotification
        title={translation.fail_label}
        description={translation.fail_text}
        colorButton="rgba(25, 28, 35, 1)"
      />
    );
  }

  if (payment.success) {
    return (
      <PaymentNotification
        title={translation.payment_link_success_title}
        description={translation.payment_link_success.replace('{ShopName}', shopName)}
        colorButton="rgba(107, 89, 237, 1)"
      />
    );
  }

  return null;
};

export default Notifications;

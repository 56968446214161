// constants
import { DASHBOARD_MENU_LINKS, PROFILE_MENU_LINKS } from 'constants/menuLinks';
// Fallback translations
import en from 'translations/dashboard/en.json';

import {
  APP_SET_MENULINKS,
  APP_SET_MOBILE_MENU_OPEN,
  APP_SET_NOTIFICATION,
  AppState,
  AppActions,
  APP_SET_TRANSLATIONS_FETCHED,
  APP_SET_TRANSLATIONS,
  APP_SET_IS_MOB_REQUIRED,
  APP_SET_CURRENT_LANGUAGE,
  APP_SET_ACTIVE_TRANSLATION,
  APP_SET_PREV_NAV_LINK,
  APP_SET_PREV_TRANSACTION,
  APP_SET_PREV_ORDER,
  APP_SET_PREV_DIRECTORY_TAB,
  APP_SET_SEARCH_RESULT,
  APP_SET_SEARCH_LOADING,
  APP_SET_ADD_SEARCH_RESULT,
  APP_SET_HAS_NEXT_PAGE,
} from './appTypes';

const initialState: AppState = {
  menuLinks: DASHBOARD_MENU_LINKS,
  search: {
    loading: '',
    data: null,
    hasNextPage: false,
  },
  profileLink: PROFILE_MENU_LINKS,
  isMobileMenuOpen: false,
  notification: null,
  isMobAppRequired: false,
  translations: null,
  isTranslationsFetched: false,
  currentLanguage: 'en',
  activeTranslation: en,
};

export const appReducer = (
  state: AppState = initialState,
  action: AppActions,
): AppState => {
  switch (action.type) {
    case APP_SET_MENULINKS:
      return {
        ...state,
        menuLinks: action.payload,
      };
    case APP_SET_MOBILE_MENU_OPEN:
      return {
        ...state,
        isMobileMenuOpen: action.payload,
      };
    case APP_SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case APP_SET_TRANSLATIONS_FETCHED:
      return {
        ...state,
        isTranslationsFetched: action.payload,
      };
    case APP_SET_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload,
      };
    case APP_SET_IS_MOB_REQUIRED:
      return {
        ...state,
        isMobAppRequired: action.payload,
      };
    case APP_SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case APP_SET_ACTIVE_TRANSLATION:
      return {
        ...state,
        activeTranslation: action.payload,
      };
    case APP_SET_PREV_NAV_LINK:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          prevLink: action.payload,
        },
      };
    case APP_SET_PREV_TRANSACTION:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          prevTransaction: action.payload,
        },
      };
    case APP_SET_PREV_ORDER:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          prevOrder: action.payload,
        },
      };
    case APP_SET_PREV_DIRECTORY_TAB:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          prevDirectoryTab: action.payload,
        },
      };
    case APP_SET_SEARCH_RESULT:
      return {
        ...state,
        search: {
          ...state.search,
          data: action.payload,
        },
      };
    case APP_SET_SEARCH_LOADING:
      return {
        ...state,
        search: {
          ...state.search,
          loading: action.payload,
        },
      };
    case APP_SET_ADD_SEARCH_RESULT:
      if (state.search.data) {
        return {
          ...state,
          search: {
            ...state.search,
            data: [...state.search.data, ...action.payload],
          },
        };
      }
      return state;
    case APP_SET_HAS_NEXT_PAGE:
      return {
        ...state,
        search: {
          ...state.search,
          hasNextPage: action.payload,
        },
      };
    default:
      return state;
  }
};

import React from 'react';
// Styles
import classes from './Breadcrumbs.module.scss';

const Breadcrumbs: React.FC = ({ children }) => (
  <div className={classes.breadcrumbs}>
    {children}
  </div>
);

export default Breadcrumbs;

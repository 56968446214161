import React, { useEffect, useMemo, useState } from 'react';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import Card from 'components/Card';
import { ReactSelectTime } from 'types/globalTypes';
import { useParams } from 'react-router-dom';
import { useUser } from 'hooks/useUser';
import { getOrdersData, getReportSelectCards } from 'store/dashboard/report/reportActions';
import { useDispatch } from 'react-redux';
import CardSelect from 'components/CardSelect';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import { getYear } from 'utils/dates';
import { ReportReq } from 'store/dashboard/report/reportTypes';
import Graph from 'components/Graph';
import CustomSpinner from 'components/CustomSpinner';
import classes from './OrdersTab.module.scss';
import MobileOrdersTab from './MobileTab';

export interface Props {
  month?: string;
  value?: number;
}

export interface SalesChartData {
  month: string;
  value: number;
}

const OrdersTab: React.FC<Props> = () => {
  const { isMobile } = useMobileScreen();
  const translations = useTranslations();
  const params: { orgId?: string, reportName?: string } = useParams();
  const ts = translations.home.report;
  const user = useUser();
  const dispatch = useDispatch();
  const [time, setTime] = useState<ReactSelectTime>(ReactSelectTime.lastMonth);

  const {
    reportSelectCards,
    ordersChart,
    monthlyAverage,
    loading,
  } = useTypeSelector(({ dashboard }) => dashboard.report);

  useEffect(() => {
    if (!user || !params.orgId || !params.reportName) {
      return;
    }
    if (!(user?.currentOrganisation?.id)) {
      return;
    }
    dispatch(getReportSelectCards(user.currentOrganisation.id, time, ReportReq.orders));
  }, [dispatch, params.orgId, time, user, params.reportName]);

  const getTotal = () => ordersChart.data.map((item) => item.value).reduce((a, b) => a + b, 0);

  const months = useMemo(
    () => [
      ts.re_jan,
      ts.re_feb,
      ts.re_mar,
      ts.re_apr,
      ts.re_may,
      ts.re_jun,
      ts.re_jul,
      ts.re_aug,
      ts.re_sep,
      ts.re_oct,
      ts.re_nov,
      ts.re_dec,
    ],
    [
      ts.re_apr,
      ts.re_aug,
      ts.re_dec,
      ts.re_feb,
      ts.re_jan,
      ts.re_jul,
      ts.re_jun,
      ts.re_mar,
      ts.re_may,
      ts.re_nov,
      ts.re_oct,
      ts.re_sep],
  );

  useEffect(() => {
    if (!user || !params.orgId) {
      return;
    }
    if (!user && !months) {
      return;
    }
    if (!user?.currentOrganisation?.id) {
      return;
    }
    dispatch(getOrdersData(user.currentOrganisation.id, months));
  }, [user, months, dispatch, params.orgId]);

  if (loading) {
    return <CustomSpinner variant="two" />;
  }

  return isMobile ? (
    <MobileOrdersTab
      total={getTotal()}
      time={time}
      setTime={setTime}
    />
  ) : (
    <div className={classes.content_container}>
      <div className={classes.chart_container}>
        <div className={classes.chart}>
          <div className={classes.chart_title}>
            <div className={classes.chart_years}>
              {getYear()} {ts.ytd}
            </div>
            <div className={getTotal() ? classes.chart_text : classes.chart_text_nodata}>
              {ts.tabs.re_orders}
            </div>
          </div>
          {!getTotal() ? (
            <div className={classes.nodata}>
              <div className={classes.lines}>
                <div className={classes.line} />
                <div className={classes.line} />
                <div className={classes.line} />
              </div>
              <p>{ts.no_data_available_yet}</p>
            </div>
          ) : (
            <Graph
              datasets={ordersChart.data}
              color="rgba(213, 0, 249, 1)"
              name={ts.tabs.re_orders}
            />
          )}
        </div>
      </div>
      <div className={classes.monthly_average}>
        <CardMonthlyAverage
          title={ts.orders_report.month}
          value={monthlyAverage?.value}
          change={monthlyAverage?.percentage}
          unit={ts.tabs.re_orders.toLowerCase()}
        />
      </div>
      <div className={classes.high}>
        <Card
          data={{
            title: ts.highest_month,
            label: ordersChart.highestMonth?.month,
            value: ordersChart.highestMonth?.value,
            second: ts.high,
            change: ordersChart.highestMonth?.percentage,
          }}
          unit={ts.tabs.re_orders}
        />
      </div>
      <div className={classes.low}>
        <Card
          data={{
            title: ts.lowest_month,
            label: ordersChart.lowestMonth?.month,
            value: ordersChart.lowestMonth?.value,
            second: ts.low,
            change: ordersChart.lowestMonth?.percentage,
          }}
          unit={ts.tabs.re_orders.toLowerCase()}
        />
      </div>
      <div className={classes.total}>
        <CardSelect
          data={{
            title: ts.all,
            value: reportSelectCards?.all?.entrytotal,
            label: `${reportSelectCards?.all?.count
              ? `${reportSelectCards?.all?.count} ${ts.tabs.re_orders}`
              : ''}`,
            change: reportSelectCards?.all?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
      <div className={classes.paid}>
        <CardSelect
          data={{
            title: ts.orders_report.in_store,
            value: reportSelectCards?.['in-store']?.entrytotal,
            label: `${reportSelectCards?.['in-store']?.count
              ? `${reportSelectCards?.['in-store']?.count} ${ts.tabs.re_orders}`
              : ''}`,
            change: reportSelectCards?.['in-store']?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
      <div className={classes.unpaid}>
        <CardSelect
          data={{
            title: ts.orders_report.online,
            value: reportSelectCards?.online?.entrytotal,
            label: `${reportSelectCards?.online?.count
              ? `${reportSelectCards?.online?.count.toLocaleString()} ${ts.tabs.re_orders}`
              : ''}`,
            change: reportSelectCards?.online?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
    </div>
  );
};

export default OrdersTab;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { FieldValues, useForm } from 'react-hook-form';
// Stripe
import { StripeError } from '@stripe/stripe-js';
import {
  EpsBankElement,
  IbanElement, P24BankElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
// Utils
// Constants
import { PayMethodsEnum } from 'constants/paymentsMethods';
// Types
import { LoadingEnum } from 'store/storefront/checkout/checkoutTypes';
// Actions
import {
  checkoutSetLocationAction, onError, onSuccess,
} from 'store/storefront/checkout/checkoutActions';
// Components
import { giropay } from 'utils/storefront/PaymentMethods/GiroPay';
import { bancontactPay } from 'utils/storefront/PaymentMethods/BancontactPay';
import { sofortPay } from 'utils/storefront/PaymentMethods/SofortPay';
import FooterPaymentModal from 'components/StorefrontComponents/Checkout/FooterPaymentModal';
import { p24Pay } from 'utils/storefront/PaymentMethods/P24';
import { sepaPay } from 'utils/storefront/PaymentMethods/SepaPay';
import { epsPay } from 'utils/storefront/PaymentMethods/EpsPay';
import BankPaymentForm from './BankPaymentForm';

interface Props {
  clientSecret: string
  activeBank: PayMethodsEnum | null;
  handleBankPaymentOptionModal: () => void;
}

const BankPaymentStripe: React.FC<Props> = ({
  activeBank,
  handleBankPaymentOptionModal,
  clientSecret,
}) => {
  const { fulfillment } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { order } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const [disabledEl, setDisabledEl] = useState<boolean>(false);
  const elements = useElements();
  const stripe = useStripe();

  const fullName = useMemo(
    () => `${fulfillment.contact?.firstName} ${fulfillment.contact?.lastName}`,
    [fulfillment.contact?.firstName, fulfillment.contact?.lastName],
  );

  const dispatch = useDispatch();
  const {
    register, getValues, trigger,
    formState: { isValid },
  } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({
      email: fulfillment.contact?.email,
      fullName,
    }), [fulfillment, fullName]),
  });

  const disabled = useMemo(() => {
    if (activeBank === PayMethodsEnum.P24
      || activeBank === PayMethodsEnum.SEPA
      || activeBank === PayMethodsEnum.EPS) {
      return !isValid || !disabledEl;
    }
    return !isValid;
  }, [activeBank, disabledEl, isValid]);

  const handleLoading = useCallback((type) => {
    if (type === LoadingEnum.PAY) {
      handleBankPaymentOptionModal();
    }

    dispatch(checkoutSetLocationAction(type));
  }, [dispatch, handleBankPaymentOptionModal]);

  const handleError = useCallback((error: StripeError | undefined | unknown) => {
    dispatch(onError(error, order));
  }, [dispatch, order]);

  const returnUrl = useMemo(() => (window.location.href.includes('?')
    ? `${window.location.href}&acc=${order.stripe?.stripeAccount}&stripe=${order.stripe?.key}`
    : `${window.location.href}?acc=${order.stripe?.stripeAccount}&stripe=${order.stripe?.key}`
  ), [order.stripe?.key, order.stripe?.stripeAccount]);

  const handlePay = useCallback(async () => {
    if (!isValid || !currentSalesChannel?.address.countryISO || !stripe || !elements) {
      return;
    }

    handleLoading(LoadingEnum.PAY);

    if (activeBank === PayMethodsEnum.GIROPAY) {
      const { error } = await giropay(
        clientSecret,
        stripe,
        getValues('fullName'),
        returnUrl,
      );

      if (error) {
        handleError(error);
        return;
      }
    }

    if (activeBank === PayMethodsEnum.BANCONTACT) {
      const { error } = await bancontactPay(
        clientSecret,
        stripe,
        getValues('fullName'),
        returnUrl,
      );

      if (error) {
        handleError(error);
        return;
      }
    }

    if (activeBank === PayMethodsEnum.SOFORT) {
      const { error } = await sofortPay(
        clientSecret,
        stripe,
        {
          countryIso: currentSalesChannel?.address.countryISO,
          fullName: getValues('fullName'),
          email: getValues('email') || '',
        },
        returnUrl,
      );

      if (error) {
        handleError(error);
        return;
      }
    }

    if (activeBank === PayMethodsEnum.P24) {
      const { error } = await p24Pay(
        clientSecret,
        stripe,
        getValues('email') || '',
        elements.getElement(P24BankElement)!,
        getValues('fullName'),
        returnUrl,
      );

      if (error) {
        handleError(error);
        return;
      }
    }

    if (activeBank === PayMethodsEnum.SEPA) {
      const { error } = await sepaPay(
        clientSecret,
        stripe,
        getValues('fullName'),
        getValues('email') || '',
        elements.getElement(IbanElement)!,
      );

      if (error) {
        handleError(error);
        return;
      }

      dispatch(onSuccess(order));
      return;
    }

    if (activeBank === PayMethodsEnum.EPS) {
      const { error } = await epsPay(
        clientSecret,
        stripe,
        elements.getElement(EpsBankElement)!,
        getValues('fullName'),
      );

      if (error) {
        handleError(error);
        return;
      }
    }

    handleLoading(LoadingEnum.SET_ORDER);
  }, [isValid,
    currentSalesChannel?.address.countryISO,
    handleLoading,
    elements,
    dispatch,
    order,
    activeBank,
    clientSecret,
    stripe,
    getValues,
    returnUrl,
    handleError,
  ]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (!elements) {
      return;
    }

    elements.getElement(P24BankElement)?.on('change', (event) => {
      if (event.complete) {
        setDisabledEl(true);
      }
    });

    elements.getElement(IbanElement)?.on('change', (event) => {
      if (event.complete) {
        setDisabledEl(true);
      }
    });

    elements.getElement(EpsBankElement)?.on('change', (event) => {
      if (event.complete) {
        setDisabledEl(true);
      }
    });
  }, [elements]);

  return (
    <>
      <BankPaymentForm
        activeBank={activeBank}
        register={register}
      />
      <FooterPaymentModal
        disabled={disabled}
        onClick={handlePay}
      />
    </>
  );
};

export default BankPaymentStripe;

import { ContactType } from 'types/globalTypes';
import { IOrderData } from '../order/orderTypes';

export const ACCOUNT_SET_CONTACT = 'ACCOUNT_SET_CONTACT';
export const ACCOUNT_SET_ERROR = 'ACCOUNT_SET_ERROR';
export const ACCOUNT_SET_LOADING = 'ACCOUNT_SET_LOADING';
export const ACCOUNT_SET_ORDERS = 'ACCOUNT_SET_ORDERS';
export const ACCOUNT_SET_MORE_ORDERS = 'ACCOUNT_SET_MORE_ORDERS';
export const ACCOUNT_IS_NEXT_PAGE_ORDERS_LOADING_ACTION = ' ACCOUNT_IS_NEXT_PAGE_ORDERS_LOADING_ACTION';

export interface AccountState{
  contact: ContactType | null;
  orders: IOrderData[] | null;
  loading: LoadingEnum | null;
  error: string | null;
  isNextPageOrdersLoading: boolean
}

export enum LoadingEnum {
  WAIT = 'waiting'
}

interface AccountSetContactAction {
  type: typeof ACCOUNT_SET_CONTACT,
  payload: ContactType
}

interface AccountSetErrorAction {
  type: typeof ACCOUNT_SET_ERROR,
  payload: string | null
}

interface AccountSetLoadingAction {
  type: typeof ACCOUNT_SET_LOADING,
  payload: LoadingEnum | null,
}

interface AccountSetOrdersAction {
  type: typeof ACCOUNT_SET_ORDERS,
  payload: IOrderData[]
}

interface AccountSetMoreOrdersAction {
  type: typeof ACCOUNT_SET_MORE_ORDERS,
  payload: IOrderData[]
}

interface AccountSetNextPageOrdersLoadingAction {
  type: typeof ACCOUNT_IS_NEXT_PAGE_ORDERS_LOADING_ACTION,
  payload: boolean
}

export type AccountActions =
AccountSetContactAction |
AccountSetErrorAction |
AccountSetLoadingAction |
AccountSetOrdersAction |
AccountSetMoreOrdersAction |
AccountSetNextPageOrdersLoadingAction;

import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { useHistory } from 'react-router';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { DocumentsGetDataAction } from 'store/dashboard/transaction/transactionActions';
import { TransactionTabsEnum } from 'store/dashboard/transaction/transactionTypes';
// Types
import { ReactSelectOption, TransactionType } from 'types/globalTypes';
// Components
import MoreButton from 'components/Buttons/MoreButton';
import CustomSpinner from 'components/CustomSpinner';
import MobileSort from 'components/DashboardComponents/MobileSort';
import DonutChartCard from 'components/DashboardComponents/DonutChartCard';
import TransactionItem from './TransactionItem';
import Header from './Header';
// Styles
import classes from './TransactionTabContent.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface TableProps {
  items: TransactionType[];
  name: TransactionTabsEnum;
  setPages: (num: number) => void;
  pages: number;
  transactionCardData:
    | {
        name: string;
        percent: number;
        total: number | undefined;
      }[]
    | null;
}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2.2,
  slidesToScroll: 1,
};

const TransactionTabContent: React.FC<TableProps> = ({
  items,
  setPages,
  pages,
  transactionCardData,
  name,
}) => {
  const sliderRef = useRef<Slider>(null);
  const { morePage, loading } = useTypeSelector(
    ({ transaction }) => transaction,
  );
  const [headerName, setHeaderName] = useState('');
  const { isMobile } = useMobileScreen();
  const translations = useTranslations();
  const tabsHeader = translations.transactions.tabs_header;
  const history = useHistory();
  const dispatch = useDispatch();
  const headers = useMemo(
    () => [
      tabsHeader.transaction_id,
      tabsHeader.date,
      name === 'expenses' || name === 'unpaidBills' || name === 'quotes'
        ? tabsHeader.vendor
        : tabsHeader.customer,
      tabsHeader.type,
      tabsHeader.status,
      tabsHeader.amount,
    ],
    [
      tabsHeader.transaction_id,
      tabsHeader.date,
      tabsHeader.vendor,
      tabsHeader.customer,
      tabsHeader.type,
      tabsHeader.status,
      tabsHeader.amount,
      name,
    ],
  );

  const handleMoreClicked = useCallback(() => {
    setPages(pages + 1);
  }, [pages, setPages]);

  const handleDetailsClicked = useCallback(
    (id: string, type: string) => {
      history.push(`/dashboard/transactions/${id}?type=${type}`);
    },
    [history],
  );

  const options = useMemo(
    () => [
      { value: tabsHeader.transaction_id, label: tabsHeader.transaction_id },
      { value: tabsHeader.date, label: tabsHeader.date },
      name === 'expenses' || name === 'unpaidBills' || name === 'quotes'
        ? { value: tabsHeader.vendor, label: tabsHeader.vendor }
        : { value: tabsHeader.customer, label: tabsHeader.customer },
      { value: tabsHeader.type, label: tabsHeader.type },
      { value: tabsHeader.status, label: tabsHeader.status },
      { value: tabsHeader.amount, label: tabsHeader.amount },
    ],
    [name, tabsHeader],
  );

  const sortItems = useCallback(
    (type: string, sortConfig = 'asc') => {
      setHeaderName(type);
      if (type === tabsHeader.transaction_id) {
        dispatch(
          DocumentsGetDataAction(
            items.sort((a, b) => {
              if (`${a.id}` > `${b.id}`) {
                return sortConfig === 'asc' ? -1 : 1;
              }

              if (`${a.id}` < `${b.id}`) {
                return sortConfig === 'asc' ? 1 : -1;
              }

              return 0;
            }),
          ),
        );
      }

      if (type === tabsHeader.date) {
        if (sortConfig === 'asc') {
          dispatch(
            DocumentsGetDataAction(
              items.sort(
                (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
              ),
            ),
          );
        } else {
          dispatch(
            DocumentsGetDataAction(
              items.sort(
                (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
              ),
            ),
          );
        }
      }

      if (type === tabsHeader.vendor || type === tabsHeader.customer) {
        dispatch(
          DocumentsGetDataAction(
            items.sort((a, b) => {
              if (`${a.name}` > `${b.name}`) {
                return sortConfig === 'asc' ? -1 : 1;
              }

              if (`${a.name}` < `${b.name}`) {
                return sortConfig === 'asc' ? 1 : -1;
              }

              return 0;
            }),
          ),
        );
      }

      if (type === tabsHeader.type) {
        dispatch(
          DocumentsGetDataAction(
            items.sort((a, b) => {
              if (`${a.type}` > `${b.type}`) {
                return sortConfig === 'asc' ? -1 : 1;
              }

              if (`${a.type}` < `${b.type}`) {
                return sortConfig === 'asc' ? 1 : -1;
              }

              return 0;
            }),
          ),
        );
      }

      if (type === tabsHeader.status) {
        dispatch(
          DocumentsGetDataAction(
            items.sort((a, b) => {
              if (`${a.status}` > `${b.status}`) {
                return sortConfig === 'asc' ? -1 : 1;
              }

              if (`${a.status}` < `${b.status}`) {
                return sortConfig === 'asc' ? 1 : -1;
              }

              return 0;
            }),
          ),
        );
      }

      if (type === tabsHeader.amount) {
        if (sortConfig === 'asc') {
          dispatch(
            DocumentsGetDataAction(items.sort((a, b) => b.amount - a.amount)),
          );
        } else {
          dispatch(
            DocumentsGetDataAction(items.sort((a, b) => a.amount - b.amount)),
          );
        }
      }
    },
    [
      dispatch,
      items,
      tabsHeader,
    ],
  );

  const handleChange = useCallback((e: ReactSelectOption | null) => {
    sortItems(String(e?.value));
  }, [sortItems]);

  const handleSort = useCallback((header: string, sortConfig: string) => {
    sortItems(header, sortConfig);
  }, [sortItems]);

  return (
    <div className={classes.table}>
      {isMobile ? (
        <div className={classes.slider}>
          <Slider {...settings} ref={sliderRef}>
            {transactionCardData?.map((item) => (
              <DonutChartCard
                key={item.name}
                title={item.name}
                percentage={item.percent}
                value={item.total}
              />
            ))}
          </Slider>
        </div>
      ) : (
        <div className={classes.table_summary}>
          {transactionCardData?.map((item) => (
            <DonutChartCard
              key={item.name}
              title={item.name}
              percentage={item.percent}
              value={item.total}
            />
          ))}
        </div>
      )}

      <div className={classes.transaction_list}>
        {isMobile ? (
          <MobileSort
            options={options}
            defaultValue={{
              value: tabsHeader.date,
              label: tabsHeader.date,
            }}
            handleChange={handleChange}
            title={translations.transactions.sort_by}
          />
        ) : (
          <div className={classes.table_headers}>
            {headers.map((header) => (
              <Header
                key={header}
                header={header}
                sortItems={handleSort}
                isIconShow={headerName === header}
              />
            ))}
          </div>
        )}

        <div className={classes.table_content}>
          {items.length ? (
            items.map((item) => (
              <TransactionItem
                key={item.uniqId}
                item={item}
                handleDetailsClicked={handleDetailsClicked}
              />
            ))
          ) : (
            <div className={classes.nodata}>
              <p>{translations.home.report.no_data_available_yet}</p>
            </div>
          )}
          {loading && pages !== 0 && (
            <div className={classes.loading}>
              <CustomSpinner variant="two" />
            </div>
          )}
          {!morePage && !loading && (
            <div
              onClick={handleMoreClicked}
              tabIndex={0}
              role="button"
              className={classes.more_btn}
            >
              <MoreButton />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionTabContent;

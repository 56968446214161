/* eslint-disable max-len */
import { useUser } from 'hooks/useUser';
import React from 'react';
import { CurrencyFormatByISO } from 'utils/price';
import { ReactComponent as ArrowDown } from 'assets/dashboard-icons/arrow-down.svg';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Styles
import { getYear } from 'utils/dates';
import { useTranslations } from 'hooks/useTranslations';
import classes from './CardMonthlyAverage.module.scss';

interface Props {
  title: string;
  label?: string;
  value?: number;
  second?: string;
  change?: number;
  unit?: string;
}

const CardMonthlyAverage: React.FC<Props> = ({
  title, value, change, unit,
}) => {
  const user = useUser();
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.country?.currencyISO);
  const translations = useTranslations();
  const ts = translations.home.report;
  const { isDesktop, windowWidth } = useMobileScreen();
  const width = windowWidth && windowWidth <= 1388;
  const isUnit = (v: number | undefined) => {
    if (unit && v) {
      return (
        <div>
          {v}
          &nbsp;
          <span className={classes.unit}>
            {unit}
          </span>
        </div>
      );
    }
    if (unit) {
      return `0 ${unit}`;
    }

    return formatCurrencyByISO(v);
  };
  return (
    <div className={classes.card}>
      <div className={classes.title}>
        <div>
          <h2 className={classes.title_text}>{ts.monthly_average}</h2>
          <h3 className={classes.subtitle}>
            {isDesktop ? title.substring(0, title.length - 3) : title}
          </h3>
        </div>
        {!width && (
          <div>
            <p className={classes.ytd}>{`${getYear()} ${ts.ytd}`}</p>
          </div>
        )}
      </div>
      <div className={value ? classes.details : classes.details_nodata}>
        <div className={classes.value} style={value ? { color: 'rgba(107, 89, 237, 1)' } : { color: 'rgb(203, 198, 215)' }}>
          {isUnit(value ? Math.trunc(value * 100) / 100 : undefined)}
        </div>
        {value
          ? (
            <div className={classes.right_side}>
              <div className={classes.right_side_value}>
                <div className={Math.sign(change || 0) === 1
                  ? classes.percents_container_growth
                  : classes.percents_container_decline}
                >
                  {change !== 0 && (
                    <div>
                      <ArrowDown />
                    </div>
                  )}
                  <p className={`${change}%` === '0%' ? classes.percents_zero : classes.percents}>
                    {Math.sign(change || 0) === 1
                      ? `+${change}% ${ts.growth}`
                      : `${change}% ${change !== 0 ? ts.decline : ''}`}
                  </p>
                </div>
                <p className={classes.vs_previous}>{ts.vs_previous_month}</p>
              </div>
            </div>
          ) : (
            <p className={classes.nodata}>{ts.no_data_available_yet}</p>
          )}
      </div>
      {width && (
        <div>
          <p className={classes.ytd}>{`${getYear()} ${ts.ytd}`}</p>
        </div>
      )}
    </div>
  );
};

export default CardMonthlyAverage;

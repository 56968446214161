import React, { useCallback, useEffect } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import ReactCountryFlag from 'react-country-flag';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// Components
import CompanyAvatar from 'components/StorefrontComponents/CompanyAvatar';
import StorefrontAccordion from 'components/Accordion/StorefrontAccordion';
import Footer from 'layouts/StorefrontLayout/Footer';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import { useLocation, useParams } from 'react-router-dom';
import ShopInfo from './ShopInfo';
import Account from './Account';
// Styles
import classes from './Menu.module.scss';

interface Props {
  isOpen: boolean;
  closeHandler: () => void;
  type?: 'account' | 'main'
}

const Menu: React.FC<Props> = ({ isOpen, closeHandler, type }) => {
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const { isMobile } = useMobileScreen();
  const { geoInfo } = useTypeSelector(({ storefront }) => storefront.app);
  const translations = useTranslationsStorefront();
  const { goToShop } = useStorefrontRoutes();
  const location = useLocation();
  const { id }: {id: string} = useParams();

  const handleShop = useCallback(() => {
    goToShop(id, location.search);
  }, [goToShop, id, location.search]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  if (isMobile && type === 'account') {
    return (
      <div className={isOpen
        ? `${classes.menu} ${classes.menu_active}`
        : classes.menu}
      >
        <header>
          {
            currentSalesChannel
            && (
              <CompanyAvatar
                title={currentSalesChannel.name}
                logoUrl={currentSalesChannel.logoUrl}
                onClick={handleShop}

              />
            )
          }
          <CloseIcon onClick={closeHandler} />
        </header>
        <div className={classes.menu_content}>
          <StorefrontAccordion title={translations.menu.shop_info}>
            <ShopInfo onClick={closeHandler} />
          </StorefrontAccordion>
          <StorefrontAccordion title={translations.account.title}>
            <Account />
          </StorefrontAccordion>
        </div>
        <Footer />
        <div className={classes.menu_bottom}>
          <p>
            {`${geoInfo?.countryName} (${getSymbolFromCurrency(geoInfo?.currency || 'USD')})`}
          </p>
          {geoInfo && <ReactCountryFlag countryCode={geoInfo.countryCode} svg />}
        </div>
      </div>
    );
  }

  if (type === 'account') {
    return (
      <div className={isOpen
        ? `${classes.menu} ${classes.menu_active}`
        : classes.menu}
      >
        <header>
          {
          currentSalesChannel && (
            <CompanyAvatar
              title={currentSalesChannel.name}
              logoUrl={currentSalesChannel.logoUrl}
              onClick={handleShop}

            />
          )
        }
          <CloseIcon onClick={closeHandler} />
        </header>
        <div className={classes.main}>
          <ShopInfo onClick={closeHandler} />
          <Account />
        </div>
        <div className={classes.menu_bottom}>
          <p>
            {`${geoInfo?.countryName} (${getSymbolFromCurrency(geoInfo?.currency || 'EUR')})`}
          </p>
          {geoInfo && <ReactCountryFlag countryCode={geoInfo.countryCode} svg />}
        </div>
      </div>
    );
  }

  if (isMobile) {
    return (
      <div className={isOpen
        ? `${classes.menu} ${classes.menu_active}`
        : classes.menu}
      >
        <header>
          {
            currentSalesChannel
            && (
              <CompanyAvatar
                title={currentSalesChannel.name}
                logoUrl={currentSalesChannel.logoUrl}
                onClick={handleShop}
              />
            )
          }
          <CloseIcon onClick={closeHandler} />
        </header>
        <div className={classes.menu_content}>
          <StorefrontAccordion title={translations.account.title}>
            <Account />
          </StorefrontAccordion>
          <StorefrontAccordion title={translations.menu.shop_info}>
            <ShopInfo onClick={closeHandler} />
          </StorefrontAccordion>
        </div>
        <Footer />
        <div className={classes.menu_bottom}>
          <p>
            {`${geoInfo?.countryName} (${getSymbolFromCurrency(geoInfo?.currency || 'USD')})`}
          </p>
          {geoInfo && <ReactCountryFlag countryCode={geoInfo.countryCode} svg />}
        </div>
      </div>

    );
  }

  return (
    <div className={isOpen
      ? `${classes.menu} ${classes.menu_active}`
      : classes.menu}
    >
      <header>
        {
          currentSalesChannel && (
            <CompanyAvatar
              title={currentSalesChannel.name}
              logoUrl={currentSalesChannel.logoUrl}
              onClick={handleShop}
            />
          )
        }
        <CloseIcon onClick={closeHandler} />
      </header>
      <div className={classes.main}>
        <ShopInfo onClick={closeHandler} />
        <Account />
      </div>
      <div className={classes.menu_bottom}>
        <p>
          {`${geoInfo?.countryName} (${getSymbolFromCurrency(geoInfo?.currency || 'EUR')})`}
        </p>
        {geoInfo && <ReactCountryFlag countryCode={geoInfo.countryCode} svg />}
      </div>
    </div>
  );
};

export default Menu;

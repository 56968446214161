import React from 'react';
// utils
import { twoLetterString } from 'utils/strings';
// Classes
import classes from './ProductAvatar.module.scss';

interface Props {
  images?: string[],
  name: string
}

const ProductAvatar: React.FC<Props> = ({ images, name }) => (!images || !images.length ? (
  <div className={classes.avatar}>
    <span>
      {twoLetterString(name)}
    </span>
  </div>
) : (
  <div className={classes.img}>
    <picture>
      <img src={images && images[0]} alt={name} />
    </picture>
  </div>
));

export default ProductAvatar;

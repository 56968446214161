import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import classes from './Card.module.scss';

interface Props {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  name: string;
  description: string;
  link: string;
}

const Card: React.FC<Props> = ({
  Icon, name, description, link,
}) => (
  <Link to={link} className={classes.card}>
    <Icon />
    <div>
      <p>{name}</p>
      <p>{description}</p>
    </div>
  </Link>
);

export default Card;

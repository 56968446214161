import React from 'react';
import classNames from 'classnames';
// Styles
import classes from './PaymentLaterOption.module.scss';

interface Props {
  check: boolean
  onClick: () => void;
}

const PaymentLaterOption: React.FC<Props> = ({ children, check, onClick }) => {
  const activeClassName = classNames(classes.payment_later_option,
    { [classes.payment_later_option_active]: check });

  return (
    <div
      className={activeClassName}
      tabIndex={0}
      role="button"
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default PaymentLaterOption;

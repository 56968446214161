/* eslint-disable no-console */
import { AppThunk } from 'store';
import dayjs from 'dayjs';
import {
  Documents,
  Items,
} from 'types/globalTypes';
// API_URL
import { API } from 'services/api';
import { getStatus } from 'utils/functions';
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { dateFormatA } from 'utils/dates';
import { nanoid } from 'nanoid';
import { quotePrice } from 'utils/documents/quotePrice';
import {
  QUOTE_LOADING,
  QuoteActions,
  QUOTE_GET_DATA,
  QUOTE_LOADING_SENT,
  QUOTE_SEND_ITEMS_MODAL,
  QUOTE_SET_ITEM,
  QUOTE_SET_DISCOUNT,
  QUOTE_SET_FEE,
  QUOTE_SEND_ITEMS,
  QuoteData,
} from './quoteTypes';

const QuoteLoadingAction = (payload: boolean): QuoteActions => ({
  type: QUOTE_LOADING,
  payload,
});

export const QuoteGetDataAction = (payload: QuoteData): QuoteActions => ({
  type: QUOTE_GET_DATA,
  payload,
});

export const QuoteLoadingSentAction = (payload: boolean): QuoteActions => ({
  type: QUOTE_LOADING_SENT,
  payload,
});

export const QuoteSendItemsActionModal = (payload: boolean): QuoteActions => ({
  type: QUOTE_SEND_ITEMS_MODAL,
  payload,
});

export const QuoteSendItemsAction = (payload: boolean): QuoteActions => ({
  type: QUOTE_SEND_ITEMS,
  payload,
});

export const quoteSetItem = (payload: Items): QuoteActions => ({
  type: QUOTE_SET_ITEM,
  payload,
});

export const quoteSetDiscount = (payload: {
  discount: number
}): QuoteActions => ({
  type: QUOTE_SET_DISCOUNT,
  payload,
});

export const quoteSetFee = (payload: {
  fees: [{
    [key: string]: number,
  }]
}): QuoteActions => ({
  type: QUOTE_SET_FEE,
  payload,
});

export const GetQuoteData = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(QuoteLoadingAction(true));

    const { data }: { data: Documents } = await API.get(`/p/purchase-orders/${id}`);

    if (data) {
      const price = quotePrice(data);

      const quoteData = {
        price: {
          currencyIso: data?.currencyISO,
          subtotal: price.subtotal,
          discount: data.discountAmount,
          total: price.total,
          paymentMethod: (data?.payments?.length ? data?.payments[0].paymentMethod : ''),
          fees: data.fees,
          taxesIncluded: price.taxIncluded,
          taxesExcluded: price.taxExcluded,
        },
        organisation: data.organisation,
        contact: {
          fullName: `${data.contact.firstName || ''} ${data.contact.lastName || ''}`,
          ...data.contact,
        },
        details: {
          type: TransactionTypes[data.type],
          reference: data.reference.substring(0, 5),
          createdAt: dateFormatA(data.createdAt),
          status: getStatus(data.status?.id || 1),
          isItems: data.items.some((item) => item.unitPrice),
        },
        items: data.items.map((item) => ({
          ...item,
          itemId: nanoid(),
        })),
        termsPayment: {
          notes: data.notes || '',
          expiryDate: dayjs(data.expiryDate).format('DD MMM YYYY') || '',
          updatedAt: dateFormatA(data?.updatedAt || ''),
          isExpired: data.expiryDate ? Date.parse(data?.expiryDate) <= Date.now() : false,
        },
      };
      dispatch(QuoteGetDataAction(quoteData));
      dispatch(QuoteLoadingAction(false));
    }
  } catch (error) {
    console.error(error);
  }
};

export const SendItemsData = (
  id: string, quoteData: QuoteData,
): AppThunk => async (dispatch) => {
  try {
    dispatch(QuoteLoadingSentAction(true));
    const updateItems = quoteData.items.map((item) => {
      delete item.itemId;
      return item;
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await API.patch(
      `/p/purchase-orders/${id}`,
      JSON.stringify({
        total: quoteData.price.total,
        discountAmount: quoteData.price.discount || 0,
        fees: quoteData.price.fees || [],
        items: updateItems,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (res.status === 200) {
      dispatch(QuoteSendItemsActionModal(true));
      dispatch(QuoteSendItemsAction(true));
      dispatch(QuoteGetDataAction({
        ...quoteData,
        termsPayment: {
          ...quoteData.termsPayment,
          updatedAt: dateFormatA(res?.data.updatedAt),
        },
      }));
    }

    dispatch(QuoteLoadingSentAction(false));
  } catch (error) {
    console.error(error);
  }
};

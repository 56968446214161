import { PaymentIntentResult, Stripe, StripeIbanElement } from '@stripe/stripe-js';

export const sepaPay = async (
  clientSecret: string,
  stripe: Stripe,
  name: string,
  email: string,
  iban: StripeIbanElement,
): Promise<PaymentIntentResult> => {
  const paymentIntentResult = await stripe.confirmSepaDebitPayment(
    clientSecret,
    {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name,
          email,
        },
      },
    },
  );
  return paymentIntentResult;
};

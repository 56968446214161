/* eslint-disable no-redeclare */
import React, { useCallback, useEffect, useState } from 'react';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useDispatch } from 'react-redux';
// Actions
import { setDirectoriesAction } from 'store/dashboard/directory/directoryActions';
// Types
import { DirectoryListType } from 'store/dashboard/directory/directoryTypes';
// Components
import Header from 'pages/Dashboard/Transaction/TransactionTabContent/Header';
import MoreButton from 'components/Buttons/MoreButton';
import CustomSpinner from 'components/CustomSpinner';
import Directory from './Directory/Directory';
// Styles
import classes from './DirectoryTable.module.scss';

interface Props {
  handleMoreDirectories: () => void;
  pages: number;
}

const DirectoryTable: React.FC<Props> = ({ handleMoreDirectories: handleMoreOrders, pages }) => {
  const [sort, setSort] = useState<string>();
  const { isMobile } = useMobileScreen();
  const dispatch = useDispatch();
  const { contacts, loading, endPage } = useTypeSelector(({ directory }) => directory);
  const translations = useTranslations();
  const directoryTs = translations.directory;

  const type = useCallback((item: DirectoryListType) => {
    if (item.sales && item.expenses) {
      return translations.directory.type.customer_vendor;
    }

    if (item.sales) {
      return translations.directory.type.customer;
    }

    return translations.directory.type.vendor;
  }, [translations]);

  useEffect(() => {
    if (isMobile) {
      dispatch(setDirectoriesAction(contacts.sort((a, b) => {
        if (`${a.activity}` > `${b.activity}`) return 1;
        if (`${a.activity}` < `${b.activity}`) return -1;
        return 0;
      })));
    }
  }, [contacts, dispatch, isMobile]);

  const sortOrders = useCallback((header: string, sortConfig = 'asc') => {
    setSort(header);
    if (header === directoryTs.table_headers[0]) {
      dispatch(setDirectoriesAction(contacts.sort((a, b) => {
        if (a.name < b.name) return sortConfig === 'asc' ? 1 : -1;
        if (a.name > b.name) return sortConfig === 'asc' ? -1 : 1;
        return 0;
      })));
    }

    if (header === directoryTs.table_headers[1]) {
      dispatch(setDirectoriesAction(contacts.sort((a, b) => {
        if (a.added > b.added) return sortConfig === 'asc' ? 1 : -1;
        if (a.added < b.added) return sortConfig === 'asc' ? -1 : 1;
        return 0;
      })));
    }

    if (header === directoryTs.table_headers[2]) {
      dispatch(setDirectoriesAction(contacts.sort((a, b) => {
        if (a.location > b.location) return sortConfig === 'asc' ? 1 : -1;
        if (a.location < b.location) return sortConfig === 'asc' ? -1 : 1;
        return 0;
      })));
    }

    if (header === directoryTs.table_headers[3]) {
      dispatch(setDirectoriesAction(contacts.sort((a, b) => {
        if (type(a) > type(b)) return sortConfig === 'asc' ? 1 : -1;
        if (type(a) < type(b)) return sortConfig === 'asc' ? -1 : 1;
        return 0;
      })));
    }

    if (header === directoryTs.table_headers[4]) {
      dispatch(setDirectoriesAction(contacts.sort((a, b) => {
        if (a.transactions > b.transactions) return sortConfig === 'asc' ? -1 : 1;
        if (a.transactions < b.transactions) return sortConfig === 'asc' ? 1 : -1;
        return 0;
      })));
    }

    if (header === directoryTs.table_headers[5]) {
      dispatch(setDirectoriesAction(contacts.sort((a, b) => {
        if (a.avgSpend > b.avgSpend) return sortConfig === 'asc' ? -1 : 1;
        if (a.avgSpend < b.avgSpend) return sortConfig === 'asc' ? 1 : -1;
        return 0;
      })));
    }

    if (header === directoryTs.table_headers[6]) {
      dispatch(setDirectoriesAction(contacts.sort((a, b) => {
        if (`${a.activity}` > `${b.activity}`) return sortConfig === 'asc' ? -1 : 1;
        if (`${a.activity}` < `${b.activity}`) return sortConfig === 'asc' ? 1 : -1;
        return 0;
      })));
    }
  }, [directoryTs.table_headers, dispatch, contacts, type]);

  return (
    <div className={classes.table}>
      {!isMobile && (
        <div className={!contacts.length ? classes.table_header_disabled : classes.table_header}>
          {directoryTs.table_headers.map((header) => (
            <Header
              key={header}
              header={header}
              sortItems={sortOrders}
              isIconShow={sort === header}
              disabled={!contacts.length}
              type="two"
            />
          ))}
        </div>
      )}
      <div className={classes.table_content}>
        {contacts.length ? contacts.map((item) => (
          <Directory item={item} key={item.id} />
        )) : (
          <div className={classes.nodata}>
            <p>{translations.home.report.no_data_available_yet}</p>
          </div>
        )}
        {loading && pages !== 0 && (
          <div className={classes.loading}>
            <CustomSpinner variant="two" />
          </div>
        )}
      </div>
      {!endPage && !loading && !!contacts.length && (
        <div
          role="button"
          tabIndex={0}
          onClick={handleMoreOrders}
          className={classes.more_btn}
        >
          <MoreButton />
        </div>
      )}
    </div>
  );
};

export default DirectoryTable;

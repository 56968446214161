import React, { useEffect, useState } from 'react';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Utils
import { CheckoutFunctions } from 'utils/storefront/CheckoutFunctions';
import { CurrencyFormatByISO } from 'utils/price';
// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/back-arrow.svg';
// Styles
import { CartFunctions } from 'utils/storefront/CartFunctions';
import classes from './Summary.module.scss';

interface Props {
  handleCheckout: () => void;
  handleContinueShopping: () => void;
}

const Summary: React.FC<Props> = ({ handleCheckout, handleContinueShopping }) => {
  const { bag, currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const [disableCheckout, setDisableCheckout] = useState<boolean>(false);

  const formatCurrencyByISO = CurrencyFormatByISO(currentSalesChannel?.address.currencyISO);
  const { cart } = useTranslationsStorefront();
  const tsSummary = cart.summary;
  const amount = CheckoutFunctions.calcAmount(bag, 0);

  useEffect(() => {
    const isDisabled = CartFunctions.isStockValid(bag, currentSalesChannel);
    setDisableCheckout(isDisabled);
  }, [bag, currentSalesChannel, disableCheckout]);

  return (
    <div className={classes.summary}>
      <h2 className={classes.summary_title}>
        {tsSummary.title}
      </h2>
      <div className={classes.summary_list}>
        <div className={classes.summary_list_subtotal}>
          <p>
            {tsSummary.subtotal_label}
          </p>
          <p>
            {formatCurrencyByISO(amount.subtotal)}
          </p>
        </div>
        <div className={classes.summary_list_shipping}>
          <p>
            {tsSummary.shipping_label}
          </p>
          <p>
            {tsSummary.shipping_text}
          </p>
        </div>
        <div className={classes.summary_list_taxes__included}>
          <p>
            {tsSummary.taxes_included_label}
          </p>
          <p>
            {formatCurrencyByISO(amount.taxesIncluded)}
          </p>
        </div>
        <div className={classes.summary_list_taxes__excluded}>
          <p>
            {tsSummary.taxes_excluded_label}
          </p>
          <p>
            {formatCurrencyByISO(amount.taxesExcluded)}
          </p>
        </div>
      </div>
      <div className={classes.summary_total}>
        <p>
          {tsSummary.total_label}
        </p>
        <p>
          {formatCurrencyByISO(amount.total)}
        </p>
      </div>
      <div className={classes.summary_buttons}>
        <button type="button" className={classes.summary_buttons_continue} onClick={handleContinueShopping}>
          {tsSummary.btn_continue_text}
          <div className={classes.summary_buttons_continue_icon}>
            <ArrowIcon />
          </div>
        </button>
        <button
          type="button"
          className={classes.summary_buttons_checkout}
          onClick={handleCheckout}
          disabled={disableCheckout}
        >
          {tsSummary.btn_checkout_text}
        </button>
      </div>
    </div>
  );
};

export default Summary;

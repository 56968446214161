import {
  QUOTE_LOADING,
  QUOTE_GET_DATA,
  QUOTE_LOADING_SENT,
  QUOTE_SEND_ITEMS_MODAL,
  QuoteState,
  QuoteActions,
  QUOTE_SEND_ITEMS,
} from './quoteTypes';

const initialState: QuoteState = {
  loading: false,
  data: null,
  loadingSent: false,
  sent: false,
  buttonActive: false,
};

export const quoteReducer = (
  state: QuoteState = initialState,
  action: QuoteActions,
): QuoteState => {
  switch (action.type) {
    case QUOTE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case QUOTE_GET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case QUOTE_SEND_ITEMS_MODAL:
      return {
        ...state,
        sent: action.payload,
      };
    case QUOTE_SEND_ITEMS: {
      return {
        ...state,
        buttonActive: action.payload,
      };
    }
    case QUOTE_LOADING_SENT: {
      return {
        ...state,
        loadingSent: action.payload,
      };
    }
    default:
      return state;
  }
};

import { SalesChannel as Location } from 'store/storefront/shop/shopTypes';
import { Unit } from 'types/globalTypes';

export const CATALOG_ITEMS_SET_LOADING = 'CATALOG_ITEMS_SET_LOADING';
export const CATALOG_ITEMS_SEARCH = 'CATALOG_ITEMS_SEARCH';
export const CATALOG_ITEMS_SET_PRODUCTS = 'CATALOG_ITEMS_SET_PRODUCTS';
export const CATALOG_ITEMS_SET_MORE_PRODUCTS = 'CATALOG_ITEMS_SET_MORE_PRODUCTS';
export const CATALOG_ITEMS_IS_NEXT_PAGE_ITEMS = 'CATALOG_ITEMS_IS_NEXT_PAGE_ITEMS';
export const CATALOG_ITEMS_SET_PRODUCTS_LOADING = 'CATALOG_ITEMS_SET_PRODUCTS_LOADING';
export const CATALOG_ITEMS_SET_CATEGORIES = 'CATALOG_ITEMS_SET_CATEGORIES';
export const CATALOG_ITEMS_SET_PRODUCT_VARIATIONS = 'CATALOG_ITEMS_SET_PRODUCT_VARIATIONS';
export const CATALOG_ITEMS_SET_CURRENT_PRODUCT = 'CATALOG_ITEMS_SET_CURRENT_PRODUCT';
export const CATALOG_ITEMS_SET_CURRENT_PRODUCT_LOADING = 'CATALOG_ITEMS_SET_CURRENT_PRODUCT_LOADING';
export const CATALOG_ITEMS_SET_CURRENT_PRODUCT_ERROR = 'CATALOG_ITEMS_SET_CURRENT_PRODUCT_ERROR';
export const CATALOG_ITEMS_SET_SUMMARY = 'CATALOG_ITEMS_SET_SUMMARY';
export const CATALOG_ITEMS_SET_UNITS = 'CATALOG_ITEMS_SET_UNITS';
export const CATALOG_ITEMS_SET_LOCATIONS = 'CATALOG_ITEMS_SET_LOCATIONS';
export interface ItemsState {
  loading: boolean;
  products: {
    data: ProductItem[] | null,
    loading: boolean,
    isNextPage: boolean,
    error: string | null,
  }
  categories: Category[] | null;
  search: SearchItemOrCategory;
  currentProduct: CurrentProductState;
  summary: SummaryItems | null;
  units: Unit[]
  locations: Location[] | null;
  isMoreVariations: boolean
}

export interface CurrentProductState {
  product: ProductItem | null;
  loading: boolean;
  error: boolean;
}

export type ProductItemOption = {
  id?: string;
  name?: string;
  variations?: { _id?: string, name?: string, created?: Date | number }[];
}

export interface SearchItemOrCategory {
  data: ProductItem[] | Category[];
  loading: boolean;
  error: boolean;
}

export interface Option {
  _id?: string;
  name: string;
  variant: string;
}
export interface Variation {
  id?: string;
  images?: string[];
  stock: number;
  name: string;
  sku: string | null;
  product?: string;
  itemId?: string;
  options: Option[];
  price: number;
  inventories: IInventory[];
}

export interface ProductItem {
  id: string
  images?: string[];
  brand: string | undefined;
  variations: Variation[];
  categoryId: string | null;
  sku: string | null;
  salesChannelCount?: number | null;
  catalogue: string;
  category?: { _id?: string, id?: string, name: string | null | undefined };
  name: string;
  description: string;
  inventories?: IInventory[];
  options: ProductItemOption[];
  stockInStore?: number;
  countOptions?: number;
  stock?: number;
  unitId?: string;
  unit?: {
    id?: string;
    name: string | null | undefined;
  }
  priceRange?: {
    min?: number;
    max?: number;
  };
  variationCount?: number;
  taxes?: {
    name: string;
    amount: number;
    percentage: number;
    included: boolean;
  }[];
}

export interface IInventory {
  locationId: string;
  quantity: number;
  lowStockCount: number;
}

export interface Category {
  id: string;
  createdAt: string;
  name: string;
  organisationId: string;
  updatedAt: string;
  products: number;
}

export interface SummaryItems {
  items: number | null,
  categories: number | null,
  inStock: number | null,
  lowStock: number | null,
  salesChannels: number | null,
  enabledSalesChannels: number | null,
}

interface ItemsSetProductsAction {
  type: typeof CATALOG_ITEMS_SET_PRODUCTS;
  payload: ProductItem[];
}

interface CatalogItemsSetMoreProductsAction {
  type: typeof CATALOG_ITEMS_SET_MORE_PRODUCTS;
  payload: ProductItem[];
}

interface CatalogItemsSetProductsLoadingAction {
  type: typeof CATALOG_ITEMS_SET_PRODUCTS_LOADING;
  payload: boolean;
}

interface ItemsSetProductVariationsAction {
  type: typeof CATALOG_ITEMS_SET_PRODUCT_VARIATIONS;
  payload: ProductItem;
}

interface ItemsSetCategoriesAction {
  type: typeof CATALOG_ITEMS_SET_CATEGORIES;
  payload: Category[];
}

interface ItemsSearchAction {
  type: typeof CATALOG_ITEMS_SEARCH;
  payload: SearchItemOrCategory;
}

interface ItemsSetLoadingAction {
  type: typeof CATALOG_ITEMS_SET_LOADING;
  payload: boolean;
}

interface ItemsSetCurrentProductAction {
  type: typeof CATALOG_ITEMS_SET_CURRENT_PRODUCT;
  payload: ProductItem;
}

interface ItemsSetCurrentProductLoadingAction {
  type: typeof CATALOG_ITEMS_SET_CURRENT_PRODUCT_LOADING;
  payload: boolean;
}

interface ItemsSetCurrentProductErrorAction {
  type: typeof CATALOG_ITEMS_SET_CURRENT_PRODUCT_ERROR;
  payload: boolean;
}

interface ItemsIsMoreProductsAction {
  type: typeof CATALOG_ITEMS_IS_NEXT_PAGE_ITEMS;
  payload: boolean;
}

interface ItemsSetSummaryAction {
  type: typeof CATALOG_ITEMS_SET_SUMMARY
  payload: SummaryItems;
}

interface ItemsSetUnits {
  type: typeof CATALOG_ITEMS_SET_UNITS
  payload: Unit[]
}

interface ItemsSetSalesChannelAction {
  type: typeof CATALOG_ITEMS_SET_LOCATIONS
  payload: Location[];
}

export type ItemsActions =
  ItemsSetProductsAction |
  ItemsSetProductVariationsAction |
  ItemsSetCategoriesAction |
  ItemsSearchAction |
  ItemsSetLoadingAction |
  ItemsSetCurrentProductAction |
  ItemsSetCurrentProductLoadingAction |
  ItemsSetCurrentProductErrorAction |
  ItemsIsMoreProductsAction |
  ItemsSetSummaryAction |
  ItemsSetSalesChannelAction |
  CatalogItemsSetMoreProductsAction |
  CatalogItemsSetProductsLoadingAction |
  ItemsSetUnits;

import React from 'react';
// Assets
import { ReactComponent as PlusIcon } from 'assets/storefront-icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/storefront-icons/minus.svg';
// Styles
import classes from './QuantityButtons.module.scss';

interface Props {
  disabled?: boolean;
  stock?: number;
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>
}

const QuantityButtons: React.FC<Props> = ({
  disabled,
  setQuantity,
  quantity,
  stock,
}) => {
  const setQuantityHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value, min, max } = event.target;
    setQuantity(Math.max(Number(min), Math.min(Number(max), Number(value))));
  };

  const addQuantityHandler: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (event.currentTarget.id === 'minus') {
      setQuantity((prev) => prev - 1);
    } else {
      setQuantity((prev) => prev + 1);
    }
  };

  return (
    <div className={classes.quantity}>
      <button
        className={classes.quantity_button}
        type="button"
        id="minus"
        onClick={addQuantityHandler}
        disabled={disabled || quantity <= 1}
      >
        <MinusIcon />
      </button>
      <input
        className={(stock && quantity > stock)
          ? classes.quantity_input_disabled : classes.quantity_input}
        type="number"
        placeholder="1"
        min="1"
        max={stock}
        value={quantity}
        onChange={setQuantityHandler}
        disabled
      />
      <button
        className={classes.quantity_button}
        type="button"
        id="plus"
        onClick={addQuantityHandler}
        disabled={disabled || quantity >= Number(stock)}
      >
        <PlusIcon />
      </button>
    </div>
  );
};

export default QuantityButtons;

import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
// Styles
import classes from './PayLaterInvoice.module.scss';

interface Props {
  register: UseFormReturn['register']
}

const PayLaterInvoice: React.FC<Props> = ({ register }) => {
  const { checkout } = useTranslationsStorefront();
  const { fulfillment } = useTypeSelector(({ storefront }) => storefront.checkout);
  const fullName = `${fulfillment.contact?.firstName} ${fulfillment.contact?.lastName}`;

  return (
    <div className={classes.pay_later_invoice}>
      <p className={classes.pay_later_invoice_title}>
        {checkout.pay_later_modal.pay_later_invoice.send_invoice_text}
      </p>
      <p className={classes.pay_later_invoice_name}>
        {fullName}
      </p>
      <p className={classes.pay_later_invoice_number}>
        {`${fulfillment.contact?.mobileNumber}`}
      </p>
      <p className={classes.pay_later_invoice_address}>
        {`${fulfillment.contact?.address?.line1} 
        ${fulfillment.contact?.address?.line2} 
        ${fulfillment.contact?.address?.city}
        ${fulfillment.contact?.address?.postalCode}
        ${fulfillment.country.name}
        `}
      </p>
      <label
        htmlFor="email"
        className={classes.input}
      >
        <span>
          {checkout.card_form.email_label}
        </span>
        <input
          {...register('email', {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: checkout.email_input.error,
            },
          })}
          placeholder={checkout.card_form.required_placeholder_label}
        />
      </label>
    </div>
  );
};

export default PayLaterInvoice;

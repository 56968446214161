import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Actions
import { GetBillData } from 'store/bill/billActions';
// Components
import LoadingPage from 'pages/LoadingPage';
import DocumentsLayout from 'layouts/DocumentsLayout';
import Title from 'components/Documents/Title';

interface IParams {
  id: string;
  publicId: string;
}

const Bill: React.FC = () => {
  const { id, publicId } = useParams<IParams>();
  const dispatch = useDispatch();
  const { loading, data } = useTypeSelector((state) => state.bill);
  const translations = useTranslationsStorefront();

  useEffect(() => {
    dispatch(GetBillData(id, publicId));
  }, [dispatch, id, publicId]);

  if (!data || loading) {
    return (
      <LoadingPage />
    );
  }

  return (
    <DocumentsLayout data={{
      location: data.location,
      organisation: data.organisation,
    }}
    >
      <Title name={translations.documents.estimate.estimate} number={data.details.reference} />
    </DocumentsLayout>
  );
};

export default Bill;

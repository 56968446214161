/* eslint-disable no-console */
import { useGroupById } from 'hooks/useGroupById';
import { ApiOrgId } from 'services/api';
import { AppThunk } from 'store';
import { Documents, TabsEnum } from 'types/globalTypes';
import { destructuringOrders, Destructuring, destructuringTransaction } from 'utils/dashboard/directory/destructuring';
import { createStackedDataset } from 'utils/dashboard/reports';
import { dateFromNow, getYear } from 'utils/dates';
import { DataChart } from '../report/reportTypes';
import {
  AverageData,
  Directory,
  DirectoryActions,
  DirectoryDocuments,
  directoryGroup,
  DirectoryListType,
  DirectoryProfile,
  DirectorySummary,
  DirectoryType,
  DIRECTORY_ADD_DOCUMENTS_ACTION,
  DIRECTORY_CLEAR_LIST,
  DIRECTORY_DETAILS_END_PAGE,
  DIRECTORY_END_PAGE,
  DIRECTORY_SET_ADD_LIST,
  DIRECTORY_SET_AVERAGE_DATA,
  DIRECTORY_SET_AVERAGE_MONTH,
  DIRECTORY_SET_BILLS,
  DIRECTORY_SET_CHART_DATA,
  DIRECTORY_SET_DOCUMENTS,
  DIRECTORY_SET_INVOICES,
  DIRECTORY_SET_LIST,
  DIRECTORY_SET_LOADING,
  DIRECTORY_SET_PROFILE,
  DIRECTORY_SET_SUMMARY,
  DIRECTORY_SET_TYPE_CONTACT,
  ResChartData,
  SummaryDirectoryDetails,
  SummaryDirectoryDetailsResType,
} from './directoryTypes';

export const setDirectoriesAction = (payload: DirectoryListType[]): DirectoryActions => ({
  type: DIRECTORY_SET_LIST,
  payload,
});

export const setAddDirectoriesAction = (payload: DirectoryListType[]): DirectoryActions => ({
  type: DIRECTORY_SET_ADD_LIST,
  payload,
});

export const directoryClearList = (payload: DirectoryListType[]): DirectoryActions => ({
  type: DIRECTORY_CLEAR_LIST,
  payload,
});

export const directorySetSummary = (payload: DirectorySummary): DirectoryActions => ({
  type: DIRECTORY_SET_SUMMARY,
  payload,
});

export const directorySetLoading = (payload: string): DirectoryActions => ({
  type: DIRECTORY_SET_LOADING,
  payload,
});

export const directoryEndPage = (payload: boolean): DirectoryActions => ({
  type: DIRECTORY_END_PAGE,
  payload,
});

export const directorySetTypeContact = (payload: DirectoryType): DirectoryActions => ({
  type: DIRECTORY_SET_TYPE_CONTACT,
  payload,
});

export const directorySetInvoices = (payload: directoryGroup): DirectoryActions => ({
  type: DIRECTORY_SET_INVOICES,
  payload,
});

export const directorySetBills = (payload: directoryGroup): DirectoryActions => ({
  type: DIRECTORY_SET_BILLS,
  payload,
});

export const directorySetAverageMonth = (payload: {
  type: DirectoryType;
  selectedMonth: { [key: string]: number } | null;
  datasets: DataChart[];
}): DirectoryActions => ({
  type: DIRECTORY_SET_AVERAGE_MONTH,
  payload,
});

export const directorySetChartData = (payload:
  { data: SummaryDirectoryDetails, type: string }): DirectoryActions => (
  {
    type: DIRECTORY_SET_CHART_DATA,
    payload,
  });

export const directorySetProfile = (payload: DirectoryProfile): DirectoryActions => ({
  type: DIRECTORY_SET_PROFILE,
  payload,
});

export const directorySetDocuments = (payload: {
  documents: Destructuring[];
  name: keyof DirectoryDocuments;
}): DirectoryActions => ({
  type: DIRECTORY_SET_DOCUMENTS,
  payload,
});

export const directoryAddDocumentsAction = (payload: {
  documents: Destructuring[];
  name: keyof DirectoryDocuments;
  page: number;
}): DirectoryActions => ({
  type: DIRECTORY_ADD_DOCUMENTS_ACTION,
  payload,
});

export const directoryDetailsEndPage = (payload:
  { endPage: boolean, name: string }): DirectoryActions => (
  {
    type: DIRECTORY_DETAILS_END_PAGE,
    payload,
  });

export const directorySetAverageData = (payload: {
  data: AverageData[],
  type: string,
}): DirectoryActions => ({
  type: DIRECTORY_SET_AVERAGE_DATA,
  payload,
});

export const getDirectories = (
  orgId: string,
  page: number,
  type: string | null,
  translations: { [key: string]: string },
): AppThunk => async (dispatch) => {
  dispatch(directorySetLoading('directories'));
  try {
    const directories: Directory[] = await ApiOrgId(`/contacts?page=${page}${type || ''}`, orgId);

    const directoriesFormatting = directories.map((directory) => ({
      name: `${directory.firstname}  ${directory.lastname}`,
      added: directory.createdat,
      location: directory.address ? `${directory.address.country}` : '',
      phone: directory.mobilenumber,
      transactions: directory.transactions,
      sales: directory.sales,
      expenses: directory.expenses,
      avgSpend: directory.salesPerMonth,
      id: directory.contactid,
      activity: dateFromNow(translations, directory.activity),
    }));

    dispatch(directoryEndPage(directoriesFormatting.length < 10));
    dispatch(setDirectoriesAction(directoriesFormatting));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(directorySetLoading(''));
  }
};

export const getAddDirectories = (
  orgId: string,
  page: number,
  type: string | null,
  translations: { [key: string]: string },
): AppThunk => async (dispatch) => {
  directorySetLoading('addDirectories');
  try {
    const directories: Directory[] = await ApiOrgId(`/contacts?page=${page}${type || ''}`, orgId);

    const directoriesFormatting = directories.map((directory) => ({
      name: `${directory.firstname}  ${directory.lastname}`,
      added: directory.createdat,
      phone: directory.mobilenumber,
      transactions: directory.transactions,
      sales: directory.sales,
      expenses: directory.expenses,
      avgSpend: directory.salesPerMonth,
      location: directory.address ? `${directory.address.country}` : '',
      id: directory.contactid,
      activity: dateFromNow(translations, directory.activity),
    }));

    dispatch(directoryEndPage(directoriesFormatting.length < 10));
    dispatch(setAddDirectoriesAction(directoriesFormatting));
  } catch (e) {
    console.error(e);
  } finally {
    directorySetLoading('');
  }
};

export const getDirectorySummary = (
  orgId: string,
): AppThunk => async (dispatch) => {
  try {
    const directorySummary: DirectorySummary = await ApiOrgId(
      `/summary/contacts?year=${getYear()}`,
      orgId,
    );
    dispatch(directorySetSummary(directorySummary));
  } catch (e) {
    console.error(e);
  }
};

export const directoryCheckContactType = (
  orgId: string,
  params: string,
): AppThunk => async (dispatch) => {
  dispatch(directorySetLoading('directoryDetails'));
  try {
    const [invoices, bills]: Documents[][] = await Promise.all([
      await ApiOrgId(`/invoices?${params}`, orgId),
      await ApiOrgId(`/bills?${params}`, orgId),
    ]);

    const isCustomer = invoices.length;
    const isVendor = bills.length;
    const invoicesRes = destructuringTransaction(invoices);
    const billsRes = destructuringTransaction(bills);
    dispatch(directorySetInvoices(useGroupById(invoicesRes)));
    dispatch(directorySetBills(useGroupById(billsRes)));
    if (isCustomer && isVendor) {
      dispatch(directorySetTypeContact(DirectoryType.both));
      return;
    }

    if (isVendor) {
      dispatch(directorySetTypeContact(DirectoryType.supplier));
      return;
    }

    dispatch(directorySetTypeContact(DirectoryType.customer));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(directorySetLoading(''));
  }
};

export const directoryGetDocuments = (
  orgId: string,
  clientId: number,
  name: keyof DirectoryDocuments,
  page = 0,
  more?: boolean,
): AppThunk => async (dispatch) => {
  try {
    dispatch(directorySetLoading(more ? 'directoryAddDocuments' : 'directoryDocuments'));
    let res: Documents[] = [];
    const OrdersTabs = name === TabsEnum.pendingOrders;

    if (name === TabsEnum.all) {
      const resTransaction: Documents[] = await ApiOrgId(
        `/documents?page=${page}&contactId=${clientId}`,
        orgId,
      );

      res = [...resTransaction];
    }

    if (name === TabsEnum.estimates.toLowerCase()) {
      res = await ApiOrgId(`/sales-orders?page=${page}&contactId=${clientId}`, orgId);
    }

    if (name === TabsEnum.quotes.toLowerCase()) {
      res = await ApiOrgId(
        `/purchase-orders?page=${page}&contactId=${clientId}`,
        orgId,
      );
    }

    if (name === TabsEnum.unpaidInvoice) {
      res = await ApiOrgId(
        `/invoices?page=${page}&status=UNPAID&contactId=${clientId}`,
        orgId,
      );
    }

    if (name === TabsEnum.paidInvoice) {
      res = await ApiOrgId(
        `/invoices?page=${page}&status=PAID&contactId=${clientId}`,
        orgId,
      );
    }

    if (name === TabsEnum.unpaidBills) {
      res = await ApiOrgId(
        `/bills?page=${page}&status=UNPAID&contactId=${clientId}`,
        orgId,
      );
    }

    if (name === TabsEnum.paidBills) {
      res = await ApiOrgId(
        `/bills?page=${page}&status=PAID&contactId=${clientId}`,
        orgId,
      );
    }

    if (name === TabsEnum.pendingOrders) {
      res = await ApiOrgId(
        `/shops/${clientId}/orders?page=0&status=UNPROCESSED&contactId=${clientId}`,
        orgId,
      );
    }

    if (more) {
      dispatch(
        directoryAddDocumentsAction({
          documents: !OrdersTabs ? destructuringTransaction(res) : destructuringOrders(res),
          name,
          page,
        }),
      );
    } else {
      dispatch(
        directorySetDocuments({
          documents: !OrdersTabs ? destructuringTransaction(res) : destructuringOrders(res),
          name,
        }),
      );
    }
    dispatch(directoryDetailsEndPage({ endPage: res.length < 10, name }));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(directorySetLoading(''));
  }
};

export const directoryGetChartData = (
  orgId: string,
  type: string,
  contactId: number,
  months: string[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(directorySetLoading('chartData'));
    const summaryRes: SummaryDirectoryDetailsResType = await ApiOrgId(
      `/dashboard/${type}/${contactId}/annual?year=${getYear()}`,
      orgId,
    );

    const dt = months.map((month, i) => {
      const exists = summaryRes.expenses
        ? summaryRes.expenses.find((item) => item.interval === i + 1)
        : summaryRes.sales?.find((item) => item.interval === i + 1);
      return {
        month,
        value: exists ? exists.total : 0,
      };
    });

    let dtAgingInvoices = null;
    let dtAgingBills = null;

    if (summaryRes.contactAgingBills) {
      dtAgingBills = createStackedDataset(summaryRes.contactAgingBills, months);
    }

    if (summaryRes.agingInvoices) {
      dtAgingInvoices = createStackedDataset(summaryRes.agingInvoices, months);
    }

    const summary = {
      chartData: dt,
      sum: summaryRes.sales
        ? summaryRes.sales?.reduce((acc, curr) => acc + (curr.total || 0), 0)
        : summaryRes.expenses?.reduce((acc, curr) => acc + (curr.total || 0), 0),
      pastDue30: summaryRes.pastDue30,
      pastDue30Plus: summaryRes.pastDue30Plus,
      purchaseOrdersRequested: summaryRes.purchaseOrdersRequested,
      purchaseOrdersResponded: summaryRes.purchaseOrdersResponded,
      pendingOrders: summaryRes.pendingOrders,
      pendingSalesOrders: summaryRes.pendingSalesOrders,
      agingInvoices: dtAgingInvoices,
      contactAgingBills: dtAgingBills,
    };

    dispatch(directorySetChartData({ data: summary, type }));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(directorySetLoading(''));
  }
};

export const directoryGetProfile = (
  orgId: string,
  contactId: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(directorySetLoading('directoryDetails'));
    const profile = await ApiOrgId(
      `contacts/${contactId}?alias=profile`,
      orgId,
    );

    dispatch(directorySetProfile(profile));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(directorySetLoading(''));
  }
};

import { StylesConfig } from 'react-select';
import { ReactSelectOption } from 'types/globalTypes';

type IsMulti = false;

export const customStyles: StylesConfig<ReactSelectOption, IsMulti> = {

  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'rgba(107, 105, 116, 0.5)' : 'rgba(107, 105, 116, 1)',
    background: '#FFF',
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: 'Visuelt Pro',
    lineHeight: '18px',
    fontWeight: 500,
    outline: 'none',
    '&:hover': {
      opacity: '0.5',
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '@media only screen and (max-width: 450px)': {
      fontSize: '12px',
      lineHeight: '12px',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    padding: '3px 0',
    textTransform: 'lowercase',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
    fontFamily: 'Visuelt Pro',
    color: 'rgba(107, 89, 237, 1)',
    '@media only screen and (max-width: 772px)': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    ':before': {
      content: '"vs "',
    },

  }),
  control: () => ({
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
  }),
  container: (provided, state) => ({
    position: 'relative',
    width: '142px',
    marginLeft: '-12px',
    fontSize: '12px',
    cursor: 'pointer',
    '&:hover': {
      opacity: state.selectProps.menuIsOpen ? 'inherit' : '0.5',
    },
    '@media only screen and (max-width: 450px)': {
      width: '100px',
      marginLeft: '0px',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    '@media only screen and (max-width: 450px)': {
      width: '100px',
      marginLeft: '0px',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  menu: (provided) => ({
    ...provided,
    border: '1px solid #FFFF',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 16px rgba(204, 188, 252, 0.15)',
    borderRadius: '4px',
    padding: '20px',
    '@media only screen and (max-width: 450px)': {
      padding: '10px',
    },
  }),
  // @ts-ignore
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'rgba(107, 89, 237, 1)',
    transition: 'all .2s ease',
    padding: 0,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    '@media only screen and (max-width: 450px)': {
      svg: {
        width: '10px',
        height: '10px',
      },
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
    color: 'rgba(32, 39, 43, 0.5)',
    '@media only screen and (max-width: 772px)': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  }),
  indicatorContainer: () => ({
    backgroundColor: '#7233CC',
    display: 'block',
    padding: 0,
  }),

  indicatorSeparator: () => ({}),
};

import React from 'react';
// Types
import { ProductItem, Variation } from 'store/dashboard/catalog/items/itemsTypes';
// Utils
import { CurrencyFormatByISO, CurrencyFormatRange } from 'utils/price';
// Components
import VariationComponent from './Variation';
// Styles
import classes from './Variations.module.scss';

interface Props {
  variations: Variation[];
  currentOption: string | null;
  product: ProductItem;
  setOption: React.Dispatch<React.SetStateAction<string | null>>;
  translation: {
    label: string;
    in_stock: string;
    out_stock: string;
  };
  currentVariant: Variation | undefined;
  currencyISO?: string;
}

const Variations: React.FC<Props> = ({
  variations,
  currentOption,
  setOption,
  translation,
  currentVariant,
  currencyISO = 'EUR',
  product,
}) => {
  const formatCurrencyByISO = CurrencyFormatByISO(currencyISO);

  return (
    <div className={classes.root}>
      <h2 className={classes.root_title}>
        {translation.label}
      </h2>
      <div className={classes.root_variations_list}>
        {typeof variations[0] !== 'string' && variations.map((variation) => (
          <VariationComponent
            key={variation.id}
            id={variation.id as string}
            variation={variation}
            checked={currentOption === variation.id}
            setOption={setOption}
            translation={{
              in_stock: translation.in_stock,
              out_stock: translation.out_stock,
            }}
          />
        ))}
      </div>
      <div className={classes.root_price}>
        {currentVariant?.price ? formatCurrencyByISO(currentVariant?.price) : CurrencyFormatRange(
          product.priceRange,
          currencyISO,
        )}
      </div>
    </div>
  );
};

export default Variations;

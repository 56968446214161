/* eslint-disable no-console */
import axios from 'axios';
import { STRAPI } from 'services/strapi';
import { AppThunk } from 'store';
import { TranslationStorefrontModel, TranslationsType } from 'types/TranslationStorefrontModel';
import {
  APP_SET_MENULINKS,
  APP_SET_MOBILE_MENU_OPEN,
  APP_SET_SHARE_OPEN,
  APP_SET_NOTIFICATION,
  APP_SET_TRANSLATIONS,
  APP_SET_TRANSLATIONS_FETCHED,
  AppActions,
  MenuLinks,
  APP_SET_ACTIVE_TRANSLATION,
  APP_SET_CURRENT_LANGUAGE,
  APP_SET_GEO_INFO,
  GeoInfo,
} from './appTypes';

export const setMenuLinksAction = (payload: MenuLinks[]): AppActions => ({
  type: APP_SET_MENULINKS,
  payload,
});

export const setMobileMenuOpenAction = (payload: boolean): AppActions => ({
  type: APP_SET_MOBILE_MENU_OPEN,
  payload,
});

export const setShareOpenAction = (payload: boolean): AppActions => ({
  type: APP_SET_SHARE_OPEN,
  payload,
});

export const setNotificationAction = (payload: string | null): AppActions => ({
  type: APP_SET_NOTIFICATION,
  payload,
});

const setTranslationsFetchedAction = (payload: boolean): AppActions => ({
  type: APP_SET_TRANSLATIONS_FETCHED,
  payload,
});

const setTranslationsAction = (payload: TranslationsType): AppActions => ({
  type: APP_SET_TRANSLATIONS,
  payload,
});

const setGeoInfoAction = (payload: GeoInfo): AppActions => ({
  type: APP_SET_GEO_INFO,
  payload,
});

export const setCurrentLanguage = (payload: string): AppActions => ({
  type: APP_SET_CURRENT_LANGUAGE,
  payload,
});

export const setActiveTranslations = (
  payload: TranslationStorefrontModel,
): AppActions => ({
  type: APP_SET_ACTIVE_TRANSLATION,
  payload,
});

export const getTranslations = (
  translations: keyof TranslationsType,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await STRAPI.get<{ id: number; translations: TranslationsType }[]>(
      '/shops?id=4',
    );

    if (
      typeof data[0].translations !== 'undefined'
      && typeof data[0].translations.en !== 'undefined'
    ) {
      dispatch(setTranslationsFetchedAction(true));
      dispatch(setTranslationsAction({ ...data[0].translations }));
      dispatch(setCurrentLanguage(translations));
      dispatch(setActiveTranslations(data[0].translations[translations]));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getGeoInfo = (): AppThunk => async (dispatch) => {
  axios
    .get('https://ipapi.co/json/')
    .then((response) => {
      const { data } = response;
      dispatch(setGeoInfoAction({
        countryName: data.country_name,
        countryCode: data.country_code,
        city: data.city,
        currency: data.currency,
      }));
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Actions
import { SetShopProductsAction } from 'store/storefront/shop/shopActions';
// Types
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
import { ShopCategoriesOrProducts } from 'store/storefront/shop/shopTypes';
// Components
import CustomSpinner from 'components/CustomSpinner';
import Product from './Product';
// Styles
import classes from './ProductsGrid.module.scss';

interface Props {
  products: ShopCategoriesOrProducts<ProductItem[]>;
  pathname: string;
  currencyISO?: string;
  handlePage: () => void;
  hasNextPage: boolean | undefined;
}

const ProductsGrid: React.FC<Props> = ({
  products,
  currencyISO = 'EUR',
  handlePage,
  hasNextPage,
}) => {
  const { id }: { id: string; productId: string } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const translations = useTranslationsStorefront();
  const { isMobile } = useMobileScreen();
  const myRef = useRef<HTMLDivElement>(null);
  const { goToProduct } = useStorefrontRoutes();
  const dispatch = useDispatch();
  const location = useLocation();

  const openModalDetails = (productId: string) => {
    goToProduct(id, productId, location);
  };

  const variations = (product: ProductItem) => product.options.reduce(
    (prev, curr) => prev + (curr.variations?.length || 0),
    0,
  );

  const scrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLElement;
    if (
      target.scrollHeight === target.scrollTop + target.clientHeight
      && hasNextPage
    ) {
      setIsFetching(true);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        isMobile
        && window.innerHeight + Math.round(document.documentElement.scrollTop)
          !== document.documentElement.offsetHeight
      ) {
        return;
      }
      setIsFetching(true);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!myRef?.current || isMobile) {
      return;
    }

    if (
      (myRef?.current?.offsetHeight === myRef?.current?.scrollHeight
        || myRef?.current?.scrollHeight + 15 - myRef?.current?.offsetHeight
          < 112)
      && hasNextPage
    ) {
      dispatch(
        SetShopProductsAction({
          ...products,
          loading: true,
          page: '1',
        }),
      );
      setIsFetching(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasNextPage, isMobile]);

  useEffect(() => {
    if (isFetching && hasNextPage) {
      handlePage();
      setIsFetching(false);
    }
  }, [handlePage, isFetching, hasNextPage]);

  if (products.loading && products.page === '0') {
    return (
      <div className={classes.loading}>
        <CustomSpinner variant="two" />
      </div>
    );
  }

  return (
    <div className={classes.shop_items} ref={myRef} onScroll={scrollHandler}>
      {products.data.map((product) => (
        <Product
          key={product.id}
          product={product}
          translations={translations}
          openModalDetails={openModalDetails}
          currencyISO={currencyISO}
          variations={variations}
        />
      ))}
      {products.loading && products.page !== '0' && (
        <div className={classes.loading_product}>
          <CustomSpinner variant="two" />
        </div>
      )}
    </div>
  );
};

export default ProductsGrid;

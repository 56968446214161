import { Stripe, StripeElements } from '@stripe/stripe-js';
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { IFulfillmentDetails } from 'store/storefront/order/orderTypes';
import { SalesChannel } from 'store/storefront/shop/shopTypes';

export type AddressType = {
      city: string;
      line1: string;
      line2: string;
      postalCode: string;
      country?: string;
};

export type TransactionType = {
  id: string;
  associations?: null | {
    type: 'ORDER' | 'ESTIMATE',
    id: string
  }
  date: string;
  contactId: number;
  type: TransactionTypes;
  status: TransactionStatus;
  dueDate: string;
  fees?: Fees[];
  items: Items[];
  discountAmount?: number;
  expiryDate?: string;
  totalTax?: number;
  balance?: number;
  reference?: string;
  payments: IPayments[] | [] | null;
  notes?: string;
  fulfillmentDetails?: {
    fee: number,
    name: string,
    note: string,
    type: string,
    country: {
      id: number,
      iso: string,
      name: string;
      currencyISO: string;
    }
  }
  contact?: {
    email: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    address?: {
      city: string;
      address1: string;
      address2: string;
      postalCode: string;
      country?: string;
    };
  };
  salesChannel?: string;
  terms?: TermsPaymentType
  uniqId?: string
  externalId: string
  total?: number;
  amount: number;
  name?: string;
  currencyISO?: string;
  url?: string;
  src?: string;
  shippingFees?: number;
  price: PriceType;
  updatedAt: string;
};

export interface IPayments {
  createdAt: string,
  id: number,
  invoiceId: string,
  updatedAt: string,
  reference: string,
  amount: string,
  paymentDate: string,
  paymentMethod: string,
  paymentDetails: null
}

export interface StripeKeys {
  client_secret: string;
  key: string;
  stripeAccount: string;
}

export enum TransactionStatus {
  UNPAID = 'unpaid',
  PENDING = 'pending',
  PAID = 'paid',
  QUOTE = 'quote',
  PROCESSED = 'processed',
  CANCELLED = 'cancelled',
  UNPROCESSED = 'unprocessed',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  PRICED = 'priced'
}

export enum ResPaymentStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export type ContactType = {
  id: string;
  createdAt?: string;
  address?: AddressType,
  firstName: string;
  lastName: string;
  organisationName: string;
  mobileNumber: string;
  email?: string;
  country?: string;
  postalCode?: string;
  city?: string;
  updatedAt: string;
  organisationId?: string;
  uid?: string
};

export type DetailsType = {
  type: TransactionTypes;
  reference: string;
  invoiceDate?: string;
  createdAt?: string;
  status: TransactionStatus;
  dueDate?: string;
  expired?: boolean | null;
};

export type ItemType = {
  brand?: string;
  category: string;
  id: number;
  name: string;
  description: string;
  options?: ItemOption[];
  quantity: number;
  unitPrice?: number;
  taxes?: TaxesRes[];
  src?: string;
};

export type PriceType = {
  subtotal: number;
  discount?: number;
  total: number;
  currencyIso: string;
  currencySymbol?: string;
  paymentMethod: string;
  fees?: Fees[],
};

export type Fees = {
  [key: string]: number
}

export type ScheduleItem = {
  date: string;
  amount: number;
  status: ResPaymentStatus;
  balance?: number;
};

export type TermsPaymentType = {
  dueDate?: string;
  date?: string;
  firstPaymentDueDate?: string;
  instalements: boolean;
  notes: string | null;
  payments: Payments[] | null;
  remainingBalance: number;
  paymentSchedule?: ScheduleItem[] | null;
  amountPaid?: number | string;
  updatedAt?: string;
  firstPaymentAmount?: number;
  paymentDueDate?: string;
};

export type PhoneCode = {
  name: string;
  dial_code: string;
  code: string;
};

export interface ReactSelectOption {
  label: string | number;
  value: string | number;
  key?: string;
}

export interface ReactSelectAction {
  action?: string;
  option?: string | undefined;
  name: string;
}

export interface PaymentProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
  stripePaymentIntent: string | undefined;
  name: string;
  id: string;
  handleClose: () => void;
}

export enum ReactSelectTime {
  yesterday = 'yesterday',
  lastWeek = 'lastWeek',
  lastMonth = 'lastMonth',
  lastYear = 'lastYear',
}

export interface Documents {
  balance: number;
  fees?: { [key: string]: number }[]
  publicId: string;
  location: SalesChannel;
  contact: ContactType;
  contactId: number;
  createdAt?: string;
  expiryDate?: null | string;
  currencyISO: string;
  date: string;
  discountAmount: number;
  dueDate: string;
  externalId: string;
  id: string;
  items: Items[];
  notes: string;
  organisation: IOrganisationData;
  terms: Terms;
  fulfillmentDetails: IFulfillmentDetails
  paymentSchedule: paymentSchedule[],
  total: number
  totalTax: number
  type: 'INVOICE' | 'BILL' | 'SALES_ORDER' | 'PURCHASE_ORDER';
  updatedAt: string;
  reference: string;
  salesChannel: null | string
  statusId: number;
  status?: {
    id: number;
  }
  payments: Payments[];
  updateAt: Date;
}

export interface Items {
  name: string;
  unit: null | string;
  taxes?: TaxesRes[];
  options: ItemOption[]
  category?: string;
  quantity: number
  unitPrice: number;
  description: string;

  categoryId?: string;
  unitId?: null | string;
  sku?: string;
  brand?: null
  src?: string;
  createdAt?: string;
  id?: number;
  invoiceId?: number;
  inventoryId?: string;
  updatedAt?: string;
  payments?: []
  reference?: string;
  salesChannel?: null
  statusId?: number;
  itemId?: string;
}

export interface ItemOption {
  name: string;
  variant: string;
}

export interface TaxesRes {
  amount: number;
  included: boolean;
  name: string
  percentage: number;
  createdAt?: string;
  id?: number;
  invoiceItemId?: string;
  updatedAt?: string;
}

export interface IOrganisationData {
  id: string;
  name: string;
  businessAs?: string;
  externalId?: string;
  email: string;
  phoneNumber: string;
  logo?: string | null;
  address: {
    line1: string;
    line2?: string;
    city?: string;
    postalCode: string;
  }
  country: {
    iso: string;
    name: string;
    currency: string;
    timezone: string;
    languages: string;
    mobileIDD: string;
    dateFormat: string;
    currencyISO: string;
    currencySymbol: string;
  }
  taxDetails?: {
    name: string;
    rate: number;
  }[];
  owner: string;
  publicId: string;
  createdAt: string;
  updatedAt: string;
}

export interface Terms {
  firstPaymentAmount: number;
  firstPaymentDueDate: string;
  numberOfInstallments: number;
  paymentDueDate?: string;
  paymentSchedule?: {
    date: string;
    amount: number;
    status: ResPaymentStatus;
  }[]
}

interface paymentSchedule {
  amount: number;
  date: string;
  status: string;
}

export interface Payments {
  amount: number;
  createdAt: string;
  id: number;
  invoiceId: number;
  paymentDate: string;
  paymentDetails: {
    brand: string;
    checks: {
      address_line1_check: null
      address_postal_code_check: null
      cvc_check: string;
    }
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    installments: null
    last4: string;
    network: string;
    three_d_secure: null
    wallet: null
  }
  paymentMethod: string;
  reference: string;
  updatedAt: string;
}

export interface Estimate {
  balance: number;
  fees?: {
    name: string;
    amount: number;
  }
  contact: ContactType;
  contactId: number;
  createdAt: string;
  expiryDate: null | string;
  currencyISO: string;
  date: string;
  discountAmount: number;
  dueDate: string;
  externalId: string;
  id: number;
  items: Items[];
  notes: string;
  organisation: IOrganisationData;
  terms: {
    paymentDueDate: string;
  };
  paymentSchedule: paymentSchedule[],
  total: number
  totalTax: number
  type: 'INVOICE' | 'BILL' | 'SALES_ORDER' | 'PURCHASE_ORDER';
  updatedAt: string;
  reference: string;
  status?: {
    id: number;
  }
  salesChannel: null
  statusId: number;
  payments: Payments[] | [] | null;
}

export enum TabsEnum {
  all = 'all',
  sales = 'Sales',
  pendingOrders = 'pendingOrders',
  expenses = 'Expenses',
  'sales-orders' = 'Estimates',
  'purchase-orders' = 'Quotes',
  agedinvoices = 'Aged Invoices',
  agedbills = 'Aged Bills',
  orders = 'Orders',
  estimates = 'Estimates',
  quotes = 'Quotes',
  salestaxes = 'Sales Taxes',
  clients = 'Clients',
  vendors = 'Vendors',
  incomestatement = 'Income Statement',
  paidInvoice = 'paidInvoice',
  unpaidInvoice = 'unpaidInvoice',
  processedOrders = 'processedOrders',
  unprocessedOrders = 'unprocessedOrders',
  canceledOrders = 'canceledOrders',
  paidBills = 'paidBills',
  unpaidBills = 'unpaidBills',
}

export const TabsReportEnum = [
  'sales',
  'expenses',
  'sales-orders',
  'purchase-orders',
  'agedinvoices',
  'agedbills',
  'orders',
  'estimates',
  'quotes',
  'salestaxes',
  'clients',
  'vendors',
  'incomestatement',
];

export interface Unit {
  createdAt: string;
  id: string;
  name: string;
  organisationId: string;
  updatedAt: string;
}

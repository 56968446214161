import { Reducer } from 'redux';
import {
  BILL_LOADING,
  BILL_GET_DATA,
  BillState,
  BillActions,
} from './billTypes';

const initialState: BillState = {
  loading: false,
  data: null,
};

export const billReducer: Reducer<BillState, BillActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case BILL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case BILL_GET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

import React, { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Components
import EyeButton from 'components/Buttons/EyeButton';
import FormError from 'components/StorefrontComponents/FormError';
import FormInfo from 'components/StorefrontComponents/FormInfo';
// Styles
import classes from './InputPassword.module.scss';

interface Props {
  register: UseFormReturn['register']
  error?: string;
  disabled?: boolean;
}

const InputPassword: React.FC<Props> = ({ register, error, disabled }) => {
  const [info, setInfo] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const { checkout } = useTranslationsStorefront();

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const onFocus = useCallback(() => {
    setInfo(true);
  }, []);

  const onBlur = useCallback(() => {
    setInfo(false);
  }, []);

  return (
    <div className={classes.input_wrapper}>
      <input
        type={showPassword ? 'password' : 'text'}
        {...register('password', {
          required: true,
          pattern: {
            value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/i,
            message: checkout.card_form.password_weak,
          },
        })}
        className={classes.input}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={checkout.card_form.create_password}
        autoComplete="new-password"
      />
      <EyeButton
        active={!showPassword}
        setActive={handleShowPassword}
        disabled={!!disabled}
      />
      <FormError
        active={!!error}
        text={error}
      />
      <FormInfo
        active={info}
        setActive={setInfo}
        text={checkout.card_form.password_info}
        direction="up"
      />
    </div>
  );
};

export default InputPassword;

import { StylesConfig } from 'react-select';

type OptionType = {
  value: string;
  label: string;
};

type IsMulti = false;

export const customStyles: StylesConfig<OptionType, IsMulti> = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'rgba(255, 255, 255, 1)' : 'rgba(25, 28, 35, 1)',
    background: state.isSelected ? '#6B59ED' : 'none',
    cursor: 'pointer',

    padding: '5px 25px',

    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',

    '&:hover': {
      background: '#6B59ED',
      color: 'rgba(255, 255, 255, 1)',
    },
  }),

  singleValue: (provided) => ({
    ...provided,
    padding: '3px 0',
    fontSize: '16px',
    color: 'rgba(25, 28, 35, 1)',
    fontWeight: 400,
    lineHeight: '24px',
    '@media only screen and (max-width: 772px)': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    },
  }),
  control: () => ({
    display: 'flex',
    height: '33px',
    width: '100%',
    paddingBottom: '13px',
    paddingLeft: '28px',
    borderBottom: '1px solid #E4EAEE',

    background: 'rgba(255, 255, 255, 0.8)',

    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',

    '@media (max-width: 768px)': {
      padding: '5px 10px 5px 20px',
      height: '40px',
    },
  }),

  container: (provided, state) => ({
    height: '33px',
    cursor: 'pointer',
    paddingBottom: '13px',
    borderBottom: '1px solid #E4EAEE',

    '&:hover': {
      opacity: state.selectProps.menuIsOpen ? 'inherit' : '0.5',
    },

    '@media only screen and (max-width: 772px)': {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      height: '40px',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    margin: '0px',
    left: '0px',
    backgroundColor: '#FFFFFF',

    boxShadow: '0px 5px 8px rgba(203, 203, 203, 0.35)',

    '@media (max-width: 768px)': {
      width: '100%',
      maxHeight: '176px',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '176px',

    '@media (max-width: 768px)': {
      width: '100%',
      maxHeight: '176px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(25, 28, 35, 0.5)',
      width: '4px',
    },

    '&::-webkit-scrollbar': {
      width: '4px',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
    color: 'rgba(32, 39, 43, 0.4)',
    '@media only screen and (max-width: 772px)': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  }),
};

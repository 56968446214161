import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
// Hooks
import { useFirebaseRemoteConfig } from 'hooks/useFirebaseRemoteConfig';
import { useTranslations } from 'hooks/useTranslations';
// Actions
import { setNotificationTransactionAction } from 'store/dashboard/transaction/transactionActions';
// Components
import Modal from 'components/Modal';
// Styles
import classes from './CreateModal.module.scss';

interface Props {
  setActive: Dispatch<SetStateAction<boolean>>;
  active: boolean;
  type: 'Invoice' | 'Bill'
}

const Create: React.FC<Props> = ({ active, setActive, type }) => {
  const { dynamicLink } = useFirebaseRemoteConfig();
  const dispatch = useDispatch();
  const translations = useTranslations();
  const handleClick = () => {
    if (isMobile) {
      window.open(dynamicLink);
      handleClose();
      return;
    }
    dispatch(setNotificationTransactionAction(translations.transactions.create.notification));
    handleClose();
  };

  const handleClose = () => {
    setActive(false);
  };

  if (type === 'Bill') {
    return (
      <Modal
        active={active}
        setActive={setActive}
      >
        <div className={classes.content}>
          <h1 className={classes.content_title}>
            {translations.transactions.create.bill_title}
          </h1>
          <p className={classes.content_description}>
            {translations.transactions.create.description_first}
            <span className={classes.content_description_continue}>
              {translations.transactions.create.continue}
            </span>
            {translations.transactions.create.description_second_bill}
          </p>
          <div className={classes.content_buttons}>
            <button className={classes.content_buttons_cancel} type="button" onClick={handleClose}>
              {translations.transactions.create.cancel}
            </button>
            <button className={classes.content_buttons_continue} type="button" onClick={handleClick}>
              {translations.transactions.create.continue}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      active={active}
      setActive={setActive}
    >
      <div className={classes.content}>
        <h1 className={classes.content_title}>
          {translations.transactions.create.invoice_title}
        </h1>
        <p className={classes.content_description}>
          {translations.transactions.create.description_first}
          <span className={classes.content_description_continue}>
            {translations.transactions.create.continue}
          </span>
          {translations.transactions.create.description_second}
        </p>
        <div className={classes.content_buttons}>
          <button className={classes.content_buttons_cancel} type="button" onClick={handleClose}>
            {translations.transactions.create.cancel}
          </button>
          <button className={classes.content_buttons_continue} type="button" onClick={handleClick}>
            {translations.transactions.create.continue}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Create;

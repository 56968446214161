import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { SetPrevTransaction } from 'store/dashboard/app/appActions';
import { TransactionTabsEnum, TransactionTabsName } from 'store/dashboard/transaction/transactionTypes';
import { mixpanelActions } from 'utils/mixpanel';
import TransactionTab from './TransactionTab';
// Styles
import classes from './TransactionTabs.module.scss';

interface TransactionTabsProps {
  tabs: string[],
}

const TransactionTabs: React.FC<TransactionTabsProps> = ({ tabs }) => {
  const [prevTab, setPrevTab] = useState<string>(TransactionTabsName[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    mixpanelActions.time_event('View Transactions / Sales Page');
    setPrevTab(TransactionTabsName[0]);
    dispatch(SetPrevTransaction(TransactionTabsName[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs
      selectedTabPanelClassName={classes.react_tabs__tab_panel__selected}
      selectedTabClassName={classes.react_tabs__tab__selected}
      className={classes.react_tabs}
      onSelect={(index) => {
        if (prevTab) {
          mixpanelActions.track(`View Transactions / ${prevTab} Page`, {
            'Page Name': `Transactions - ${prevTab}`,
          });
        }
        mixpanelActions.time_event(`View Transactions / ${TransactionTabsName[index]} Page`);
        setPrevTab(TransactionTabsName[index]);
        dispatch(SetPrevTransaction(TransactionTabsName[index]));
      }}
    >
      <TabList className={classes.react_tabs__tab_list}>
        {tabs.map((tab) => (
          <Tab key={tab} className={classes.react_tabs__tab}>
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanel>
        <TransactionTab name={TransactionTabsEnum.SALES} />
      </TabPanel>
      <TabPanel>
        <TransactionTab name={TransactionTabsEnum.EXPENSES} />
      </TabPanel>
      <TabPanel>
        <TransactionTab name={TransactionTabsEnum.UNPAID_INVOICES} />
      </TabPanel>
      <TabPanel>
        <TransactionTab name={TransactionTabsEnum.UNPAID_BILLS} />
      </TabPanel>
      <TabPanel>
        <TransactionTab name={TransactionTabsEnum.ESTIMATES} />
      </TabPanel>
      <TabPanel>
        <TransactionTab name={TransactionTabsEnum.QUOTES} />
      </TabPanel>
    </Tabs>
  );
};

export default TransactionTabs;

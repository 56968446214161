import React, { useMemo } from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { IPickupData } from 'store/storefront/shop/shopTypes';
// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';
// Assets
import ShippingOption from '../ShippingOption';
// Styles
import classes from './PickupLocationModal.module.scss';

interface Props {
  active: boolean;
  pickupCountry: IPickupData[] | undefined;
  handleModal: () => void;
  handlePickupLocationModal: () => void;
  pickupLocation: {
    name: string;
    address: string;
  } | undefined;
  handlePickupLocation: (location: {
    name: string;
    address: string;
  }) => () => void;
  handleContinue: () => void
}

const PickupLocationModal: React.FC<Props> = ({
  active,
  pickupCountry,
  handleModal, handlePickupLocationModal, pickupLocation, handlePickupLocation, handleContinue,
}) => {
  const showHideClassName = active ? classes.modal_show : classes.modal_hide;

  const { checkout } = useTranslationsStorefront();
  const locationModalTranslations = checkout.shipping_modal.location_modal;

  const cities = useMemo(() => {
    if (pickupCountry) {
      return pickupCountry.reduce(
        (state: {
          name: string
          value: {
            name: string,
            address: string,
          }[]
        }[], city) => {
          const getCityNext = city.name.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, '');
          const getName = city.name.replace(/.*\(|\).*/g, '');
          const index = state.findIndex(
            (item) => {
              const getCityState = item.name.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, '');

              return getCityState === getCityNext;
            },
          );

          if (index === -1) {
            state.push({
              name: getCityNext,
              value: [{
                name: getName,
                address: city.address,
              }],
            });
            return state;
          }

          state[index].value.push({
            name: getName,
            address: city.address,
          });

          return state;
        }, [],
      );
    }

    return null;
  }, [pickupCountry]);

  return (
    <div
      className={showHideClassName}
    >
      <div
        className={active ? classes.modal_wrapper_active : classes.modal_wrapper}
        tabIndex={0}
        role="button"
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.root}>
          <div className={classes.header_pickup_location}>
            <CloseIcon
              role="button"
              className={classes.close_icon}
              onClick={handleModal}
            />
            <LeftArrowIcon
              role="button"
              className={classes.left_arrow_icon}
              onClick={handlePickupLocationModal}
            />
          </div>
          <div className={classes.title}>
            {locationModalTranslations.title}
          </div>
          <Tabs
            selectedTabPanelClassName={classes.react_tabs__tab_panel__selected}
            selectedTabClassName={classes.react_tabs__tab__selected}
            className={classes.react_tabs}
          >

            <TabList
              className={classes.react_tabs__tab_list}
            >
              <div className={classes.react_tabs__tab_wrapper}>
                {cities?.map((city) => (
                  <Tab
                    key={city.name}
                    className={classes.react_tabs__tab}
                  >
                    {city.name}
                  </Tab>
                ))}
              </div>
            </TabList>
            {cities?.map((city) => (
              <TabPanel key={city.name}>
                <div className={classes.pickup_locations}>
                  {city.value.map((location) => (
                    <ShippingOption
                      key={location.address}
                      checked={pickupLocation?.address === location.address}
                      onCheck={handlePickupLocation({
                        name: `${city.name} ${location.name}`,
                        address: location.address,
                      })}
                      type={`${city.name} ${location.name}`}
                      fee_label={location.address}
                      variant="two"
                    />
                  ))}
                </div>
              </TabPanel>
            ))}
          </Tabs>
          <div className={classes.bottom}>
            <button
              type="button"
              className={classes.bottom_cancel}
              onClick={handleModal}
            >
              {locationModalTranslations.cancel_btn_text}
            </button>
            <button
              type="button"
              className={classes.bottom_submit}
              onClick={handleContinue}
              disabled={!pickupLocation}
            >
              {locationModalTranslations.add_btn_text}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickupLocationModal;

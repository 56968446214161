import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useUser } from 'hooks/useUser';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { dateToFormat } from 'utils/dates';
// Actions
import { selectTransactionDetails } from 'store/dashboard/transaction/transactionSelectors';
import { createReminder } from 'store/dashboard/transaction/transactionActions';
// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/back-arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/dashboard-icons/close-icon.svg';
// Types
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { ResPaymentStatus, TransactionStatus } from 'types/globalTypes';
// Components
import Modal from 'components/Modal';
// Styles
import classes from './SendReminderModal.module.scss';

interface ModalProps {
  setActive: Dispatch<SetStateAction<boolean>>;
  active: boolean;
}

const SendReminderModal: React.FC<ModalProps> = ({
  setActive, active,
}) => {
  const user = useUser();
  const dispatch = useDispatch();
  const { isMobile } = useMobileScreen();
  const translations = useTranslations();
  const transaction = useTypeSelector(selectTransactionDetails);
  const tsTransaction = translations.transactions;
  const formatCurrencyByISO = CurrencyFormatByISO(user?.currentOrganisation?.country?.currencyISO);
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });

  const remainingBalance = useMemo(() => {
    if (transaction?.terms) {
      const unpaidPaymentSchedule = transaction.terms.paymentSchedule?.filter(
        (schedule) => schedule.status !== ResPaymentStatus.PAID,
      );
      if (unpaidPaymentSchedule?.length) {
        return {
          amount: unpaidPaymentSchedule[0].balance || unpaidPaymentSchedule[0].amount,
          date: unpaidPaymentSchedule[0].date,
        };
      }

      if (transaction?.type === TransactionTypes.SALES_ORDER) {
        return {
          amount: transaction.balance,
          date: transaction.terms.paymentDueDate,
        };
      }

      return {
        amount: null,
      };
    }

    if (transaction?.type === TransactionTypes.PURCHASE_ORDER) {
      return {
        amount: transaction?.price.total,
        date: transaction?.dueDate,
      };
    }

    return {
      amount: null,
    };
  }, [
    transaction?.type,
    transaction?.balance,
    transaction?.dueDate,
    transaction?.price.total,
    transaction?.terms,
  ]);

  const msgPlaceholder = useMemo(() => {
    if (transaction?.type === TransactionTypes.SALES_ORDER) {
      return tsTransaction.reminder_modal.message_placeholder_estimate;
    }

    if (transaction?.type === TransactionTypes.PURCHASE_ORDER) {
      return tsTransaction.reminder_modal.message_placeholder_quote;
    }

    return tsTransaction.reminder_modal.message_placeholder;
  }, [transaction?.type, tsTransaction]);

  const amountDue = useMemo(
    () => String(remainingBalance.amount || transaction?.balance),
    [remainingBalance.amount, transaction?.balance],
  );

  const handleClose = () => {
    setActive(false);
  };

  const sendReminder = (data: { email: string, msg: string }) => {
    if (!user?.currentOrganisation?.id || !transaction || !remainingBalance.date) {
      return;
    }

    setActive(false);
    const date = new Date();
    dispatch(createReminder(
      user?.currentOrganisation?.id,
      {
        entityId: transaction.id,
        entity: transaction.type.toUpperCase(),
        interval: 1,
        startDate: date.toISOString(),
        endDate: remainingBalance.date,
        data: {
          contactName: `${transaction?.contact?.firstName} ${transaction?.contact?.lastName}`,
          dueDate: remainingBalance.date,
          paymentAmount: amountDue,
          email: data.email,
          message: data.msg,
        },
      },
      user?.currentOrganisation?.externalId,
    ));
  };

  if (!transaction) {
    return null;
  }

  return (
    <Modal active={active} setActive={setActive} type="two">
      <div className={classes.content}>
        {isMobile ? (
          <ArrowIcon
            className={classes.close_icon}
            onClick={handleClose}
          />
        ) : (
          <CloseIcon
            className={classes.close_icon}
            onClick={handleClose}
          />
        )}
        <div className={classes.send_reminder}>
          <h1 className={classes.title}>
            {transaction?.type === TransactionTypes.PURCHASE_ORDER
              || transaction?.type === TransactionTypes.SALES_ORDER
              ? tsTransaction.reminder_modal.email_reminder
              : tsTransaction.reminder_modal.email_payment_reminder}
          </h1>
          <p className={classes.name}>
            {transaction.contact?.firstName} {transaction.contact?.lastName}
          </p>
          {transaction?.type !== TransactionTypes.PURCHASE_ORDER && (
            <div className={classes.amount_due}>
              <p className={classes.amount_due_label}>
                {transaction.status === TransactionStatus.PENDING
                  ? tsTransaction.reminder_modal.estimate_total
                  : tsTransaction.reminder_modal.amount_due}
              </p>
              <p className={classes.amount_due_value}>
                {formatCurrencyByISO(amountDue)}
              </p>
            </div>
          )}
          <form onSubmit={handleSubmit(sendReminder)} className={classes.send_reminder_form}>
            <div className={classes.email}>
              <p className={classes.email_label}>
                {transaction?.type === TransactionTypes.PURCHASE_ORDER
                  ? tsTransaction.reminder_modal.vendor_email
                  : tsTransaction.reminder_modal.customer_email}
              </p>
              <input
                defaultValue={transaction.contact?.email}
                placeholder={tsTransaction.reminder_modal.enter_email}
                className={classes.email_input}
                {...register('email', {
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  },
                })}
                type="email"
                required
              />
            </div>
            <div className={classes.message}>
              <p className={classes.message_label}>
                {tsTransaction.reminder_modal.message}
              </p>
              <textarea
                className={classes.message_input}
                defaultValue={msgPlaceholder
                  .replace('{firstName}', `${transaction.contact?.firstName}`)
                  .replace('{amount}', `${formatCurrencyByISO(amountDue)}`)
                  .replace('{dueDate}', `${dateToFormat(remainingBalance?.date)}`)
                  .replace('{url}', `${transaction.url}`)
                  .replace('{orgName}', ` ${user?.currentOrganisation?.name}`)}
                {...register('msg')}
                required
              />
            </div>
            <input
              disabled={!formState.isValid}
              className={classes.send_reminder_button}
              type="submit"
              value={tsTransaction.send_reminder}
            />
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default SendReminderModal;

import React from 'react';
import { ReactComponent as Alert } from 'assets/icons/alert.svg';
// styles
import classes from './ModalFail.module.scss';

interface Props {
handleClick: () => void;
text: string;
buttonText: string;
}

const ModalFail:React.FC<Props> = ({ handleClick, text, buttonText }) => (
  <div className={classes.modal_fail}>
    <div className={classes.modal_fail_title}>
      <Alert />
      <div>
        <p>{text}</p>
      </div>
    </div>
    <div
      role="button"
      tabIndex={0}
      onClick={handleClick}
      className={classes.modal_fail_button}
    >
      {buttonText}
    </div>
  </div>
);

export default ModalFail;

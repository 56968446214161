import React from 'react';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Types
import { TransactionStatus } from 'types/globalTypes';
import { EstimateModal } from 'store/estimate/estimateTypes';
// Components
import Modal from 'components/Modal';
// Styles
import classes from './ModalEstimate.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
  type: EstimateModal;
  handleButton: (status: TransactionStatus) => void;
  organisationName: string;
}

const ModalEstimate: React.FC<Props> = ({
  active,
  setActive,
  type,
  handleButton,
  organisationName,
}) => {
  const translations = useTranslationsStorefront();
  const ts = translations.documents.estimate.modal;

  if (type === EstimateModal.decline) {
    return (
      <Modal active={active} setActive={setActive} className={classes.modal}>
        <div className={classes.container}>
          <p className={classes.title}>{ts.decline.title}</p>
          <p className={classes.description}>{ts.decline.description.replace('{OrgName}', organisationName)}</p>
          <div className={classes.container_buttons}>
            <button
              type="button"
              onClick={setActive}
              className={classes.cancel_btn}
            >
              {ts.cancel_btn}
            </button>
            <button
              type="button"
              onClick={() => handleButton(TransactionStatus.CANCELLED)}
              className={classes.accept_btn}
            >
              {ts.decline.button_text}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal active={active} setActive={setActive} className={classes.modal}>
      <div className={classes.container}>
        <p className={classes.title}>{ts.accept.title}</p>
        <p className={classes.description}>{ts.accept.text.replace('{OrgName}', organisationName)}</p>
        <div className={classes.container_buttons}>
          <button
            type="button"
            onClick={setActive}
            className={classes.cancel_btn}
          >
            {ts.cancel_btn}
          </button>
          <button
            type="button"
            onClick={() => handleButton(TransactionStatus.ACCEPTED)}
            className={classes.accept_btn}
          >
            {ts.accept.button_text}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEstimate;

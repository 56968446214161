import React, { useState } from 'react';
// Hooks
import { useDispatch } from 'react-redux';
import { useUser } from 'hooks/useUser';
import { useSubscription } from 'hooks/useSubscription';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { getMoreVariations } from 'store/dashboard/catalog/items/itemsActions';
// Types
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
// Components
import MoreButton from 'components/Buttons/MoreButton';
import ItemDetailsVariant from './ItemDetailsVariant';
// Styles
import classes from './ItemDetailsStock.module.scss';

interface Props {
  product: ProductItem;
}

const ItemDetailsStock: React.FC<Props> = ({ product }) => {
  const subscription = useSubscription();
  const isLowStock = product.variations.filter((variant) => variant.inventories.find(
    (inventory) => inventory?.quantity <= inventory.lowStockCount,
  ));

  const {
    isMoreVariations,
  } = useTypeSelector(({ dashboard }) => dashboard.catalog.items);
  const user = useUser();
  const dispatch = useDispatch();
  const [pages, setPages] = useState<number>(1);
  const { catalog } = useTranslations();

  const handleMoreVariations = () => {
    if (!user?.currentOrganisation?.id) {
      return;
    }

    setPages(pages + 1);
    dispatch(getMoreVariations(user?.currentOrganisation?.id, product, pages));
  };

  if (subscription?.subscriptionData !== 'PREMIUM') {
    return (
      <div className={classes.root}>
        <h2 className={classes.no_subscription_title}>
          {catalog.item_details.details_stock.label}
        </h2>
        <button type="button" className={classes.upgrade_btn}>
          {catalog.item_details.no_subscription.button_title}
        </button>
        <p className={classes.no_subscription_description}>
          {catalog.item_details.details_stock.description_no_subscription}
        </p>
        <div className={classes.no_subscription_available}>
          <p className={classes.no_subscription_available_title}>
            {catalog.item_details.details_stock.available_text}
          </p>
          <p className={classes.no_subscription_available_subtitle}>
            {catalog.item_details.no_subscription.subtitle}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>
        <p>
          {catalog.item_details.details_stock.label}
        </p>
        {!!isLowStock.length && (
          <p className={classes.low_stock}>
            {catalog.item_details.details_stock.low_stock_text}
          </p>
        )}
      </h2>
      <div className={classes.variants}>
        {product.variations.map((variant) => (
          <ItemDetailsVariant variant={variant} key={variant.id} />
        ))}
        {!!isMoreVariations && (
          <div onClick={handleMoreVariations} tabIndex={0} role="button" className={classes.more_btn}>
            <MoreButton />
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemDetailsStock;

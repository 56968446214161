import { Stripe, StripeError } from '@stripe/stripe-js';

export const klarna = async (
  clientSecret: string, stripe: Stripe, data: {
    email: string,
    country: string,
  },
  returnUrl: string,
): Promise<StripeError | undefined> => {
  const { error } = await stripe.confirmKlarnaPayment(
    clientSecret,
    {
      payment_method: {
        billing_details: {
          email: data.email,
          address: {
            country: data.country,
          },
        },
      },
      return_url: returnUrl,
    },
  );
  return error;
};

import React, { useCallback, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
// Actions
import { signOut } from 'store/auth/authActions';
import { setMobileMenuOpenAction } from 'store/dashboard/app/appActions';
// Utils
import { getRemoteConfig, openInNewTab } from 'utils/functions';
// Types
import { IOrganisationData } from 'types/globalTypes';
// Components
import SvgSprite from 'components/SvgSprite';
import { DashboardRoutePath } from 'router/Routes/Dashboard';
// Styles
import classes from './MobileMenu.module.scss';

interface Props {
  switchOrganisation: (org: IOrganisationData) => void;
}

const MobileMenu: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const {
    menuLinks,
    isMobileMenuOpen,
    profileLink,
  } = useTypeSelector(({ dashboard }) => dashboard.app);
  const user = useUser();
  const translations = useTranslations();
  const tsSidebar = translations.sidebar;
  const { pathname } = useLocation();
  const defaultRemoteConfig = getRemoteConfig();
  const linkHelpCenter = defaultRemoteConfig.getValue('HelpCenter');
  type P = typeof tsSidebar;
  const basePath = pathname.split('/');

  const handleLinkSideEffect = useCallback(() => {
    dispatch(setMobileMenuOpenAction(!isMobileMenuOpen));
  }, [dispatch, isMobileMenuOpen]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMobileMenuOpen]);

  const handleSignOut = useCallback(
    () => dispatch(signOut()),
    [dispatch],
  );

  const handleHelpCenter = useCallback(() => {
    dispatch(setMobileMenuOpenAction(!isMobileMenuOpen));
    openInNewTab(linkHelpCenter.asString());
  }, [dispatch, isMobileMenuOpen, linkHelpCenter]);

  return (
    <nav className={
      isMobileMenuOpen
        ? `${classes.mobile_menu} ${classes.mobile_menu_active}`
        : classes.mobile_menu
    }
    >
      <ul className={classes.links_list}>
        {menuLinks.map((link) => (
          <li className={classes.item} key={link.title}>
            <NavLink
              exact
              onClick={handleLinkSideEffect}
              aria-current="true"
              to={link.route}
              className={classes.link}
              activeClassName={classes.link_active}
              isActive={() => {
                if (basePath[2] !== `${user?.currentOrganisation?.id}`) {
                  return pathname === link.route;
                }
                return `/${basePath[1]}` === link.route;
              }}
            >
              <span>
                {tsSidebar[link.title as keyof P]}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>

      <div className={classes.links_list_second}>
        <div className={classes.item_second}>
          <div className={classes.link_second}>
            <SvgSprite name="help" />
            <span
              className={classes.link_second_name}
              role="button"
              tabIndex={0}
              onClick={handleHelpCenter}
            >
              {tsSidebar.help_center}
            </span>
          </div>
        </div>
        <NavLink
          to={DashboardRoutePath.LANGUAGES}
          className={classes.link_second}
          activeClassName={classes.link_active_second}
        >
          <SvgSprite name="globe" />
          <p className={classes.link_second_name}>
            {tsSidebar.language}
          </p>
        </NavLink>
        <div className={classes.item_second}>
          <NavLink
            to={profileLink.route}
            className={classes.link_second}
            onClick={handleLinkSideEffect}
            activeClassName={classes.link_active_second}
          >
            <SvgSprite name={profileLink.icon} />
            <p className={classes.link_second_name}>
              {user?.firstName ? `${user.firstName} ${user?.lastName}` : ''}
            </p>
          </NavLink>
          <div className={classes.link_second}>
            <SvgSprite name="logout" />
            <span
              className={classes.link_second_name}
              role="button"
              tabIndex={0}
              onClick={handleSignOut}
            >{tsSidebar.log_out}
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileMenu;

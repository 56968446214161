import { SalesChannel } from 'store/storefront/shop/shopTypes';
import {
  ContactType,
  PriceType,
  TermsPaymentType,
  Items,
  IOrganisationData,
  DetailsType,
} from 'types/globalTypes';

// Action Types
export const BILL_LOADING = 'BILL_LOADING';
export const BILL_GET_DATA = 'BILL_GET_DATA';

// Reducer type
export interface BillState {
  loading: boolean
  data: IBillData | null
}

export interface IBillData {
  organisation: IOrganisationData
  contact: ContactType;
  details: DetailsType;
  location: SalesChannel;
  items: Items[];
  price: PriceType;
  termsPayment: TermsPaymentType;
  paid: boolean;
}
interface BillLoadingAction {
  type: typeof BILL_LOADING
  payload: boolean
}

interface GetBillDataAction {
  type: typeof BILL_GET_DATA
  payload: BillState['data']
}

export type BillActions = BillLoadingAction | GetBillDataAction;

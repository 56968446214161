import React from 'react';
import cn from 'classnames';
// Assets
import { ReactComponent as Close } from 'assets/storefront-icons/close.svg';
import { ReactComponent as Check } from 'assets/icons/switch/check.svg';
// Styles
import classes from './YellowSwitch.module.scss';

interface Props {
  onClick?: () => void;
  active?: boolean;
}

const YellowSwitch: React.FC<Props> = ({ onClick, active }) => (
  <div
    tabIndex={0}
    role="button"
    className={classes.switch_wrapper}
    onClick={onClick}
  >
    <div className={cn(classes.switch, {
      [classes.switch_active]: active,
    })}
    >
      {active ? <Check /> : <Close />}
    </div>
  </div>
);

export default YellowSwitch;

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useDispatch } from 'react-redux';
import { getProducts, SetShopProductsAction } from 'store/storefront/shop/shopActions';
import { useQuery } from 'hooks/useQuery';
// Components
import ProductsGrid from 'components/StorefrontComponents/ProductsGrid';

const ShopProducts: React.FC = () => {
  const [page, setPage] = useState(0);
  const query = useQuery();
  const { id }: { id: string, productId: string } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { products, currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const handlePage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (page !== 0 && query.has('page')) {
      query.set('page', page.toString());
    } else {
      query.set('page', '0');
      setPage(0);
    }

    if (id) {
      dispatch(SetShopProductsAction({
        ...products,
        loading: true,
        page: query.get('page'),
      }));

      dispatch(getProducts(id, query));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page]);

  return (
    <ProductsGrid
      products={products}
      pathname={pathname}
      currencyISO={currentSalesChannel?.address?.currencyISO}
      handlePage={handlePage}
      hasNextPage={products.hasNextPage}
    />
  );
};

export default ShopProducts;

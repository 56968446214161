/* eslint-disable no-console */
import { AppThunk } from 'store';
// API_URL
import { ApiOrgPublicId } from 'services/api';
import {
  Documents,
  TransactionStatus,
} from 'types/globalTypes';
import { calcTaxes, getStatus } from 'utils/functions';
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
import { dateFormatA, formatReg } from 'utils/dates';
import { calcSubtotal, calcTotalFees } from 'utils/price';
import {
  BILL_LOADING,
  BillActions,
  BillState,
  BILL_GET_DATA,
} from './billTypes';

const BillLoadingAction = (payload: boolean): BillActions => ({
  type: BILL_LOADING,
  payload,
});

const BillGetDataAction = (payload: BillState['data']): BillActions => ({
  type: BILL_GET_DATA,
  payload,
});

export const GetBillData = (id: string, publicId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(BillLoadingAction(true));
    await ApiOrgPublicId('/p/organisations', publicId);
    const data: Documents = await ApiOrgPublicId(`/p/sales-orders/${id}`, publicId);
    const findSalesChannel = await ApiOrgPublicId(
      `/p/locations/${data.location || 'default'}`,
      publicId,
    );

    const payments = data?.payments
    && data.payments.sort((a, b) => (Date.parse(a.createdAt) <= Date.parse(b.createdAt) ? -1 : 1));

    const terms = data?.terms
      && data.terms.paymentSchedule?.sort(
        (a, b) => (Date.parse(a.date) <= Date.parse(b.date) ? -1 : 1),
      );

    if (data) {
      const totalFees = calcTotalFees(data.fees);
      const taxes = calcTaxes(data.items);
      const subtotal = calcSubtotal(data);

      const billData = {
        organisation: data.organisation,
        contact: {
          fullName: `${data?.contact?.firstName || ''} ${
            data?.contact?.lastName || ''
          }`,
          ...data?.contact,
        },
        details: {
          type: TransactionTypes[data.type as keyof typeof TransactionTypes],
          status: getStatus(data.statusId),
          billNumber: data.reference,
          reference: data.publicId.substring(0, 5),
          createdAt: dateFormatA(data.createdAt),
          amountPaid: data.balance,
          dueDate: formatReg(data.dueDate),
        },
        location: data.location,
        items: data.items,
        price: {
          currencyIso: data?.currencyISO,
          subtotal,
          discount: data.discountAmount,
          total:
              subtotal - data.discountAmount + totalFees + taxes.excluded,
          paymentMethod:
              data?.payments.length !== 0
                ? data?.payments[0].paymentMethod
                : '',
          fees: data.fees,
        },
        termsPayment: {
          instalements:
              getStatus(data.statusId) !== TransactionStatus.PAID
              && data?.terms !== null,
          notes: data.notes,
          payments,
          remainingBalance: data.balance,
          amountPaid: data?.payments.length
            && data.payments.reduce((acc, curr) => acc + curr.amount, 0),
          paymentSchedule: terms,
        },
        paid: getStatus(data.statusId) !== TransactionStatus.PAID,
      };
      dispatch(BillLoadingAction(false));
      dispatch(BillGetDataAction(billData));
    }
  } catch (error) {
    console.log(error);
  }
};

import { useTranslations } from 'hooks/useTranslations';
import React from 'react';
import Card from 'components/Card';
import { useTypeSelector } from 'hooks/useTypeSelector';
import CardSelect from 'components/CardSelect/CardSelect';
import { ReactSelectTime } from 'types/globalTypes';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import { getYear } from 'utils/dates';
import StackedGraph from 'components/StackedGraph';
// Styles
import classes from './MobileUnpaidInvoiceTab.module.scss';

export interface AgingBillsData {
  month: string;
  type: 1 | 2;
  value: number;
}
const allKeys = ['1'];

const colors = {
  1: 'rgb(65, 29, 117)',
  2: '#00D3C8',
};

interface Props {
  total: number;
  time: ReactSelectTime;
  setTime: React.Dispatch<React.SetStateAction<ReactSelectTime>>
}

const MobileUnpaidInvoiceTab: React.FC<Props> = ({ total, time, setTime }) => {
  const translations = useTranslations();
  const ts = translations.home.report;

  const { agingInvoiceChart, reportSelectCards, monthlyAverage } = useTypeSelector(
    ({ dashboard }) => dashboard.report,
  );

  return (
    <>
      <div className={classes.content_container}>
        <div className={classes.chart_container}>
          <div className={classes.chart}>
            <div className={classes.chart_title}>
              {!total ? (
                <div className={classes.chart_years}>
                  {`${getYear()} ${ts.year_to_date}`}
                </div>
              ) : (
                <div className={classes.chart_years}>
                  {`${getYear()} ${ts.ytd}`}
                </div>
              )}
              <div className={classes.chart_text}>
                {ts.tabs.re_aging_invoice}
              </div>
            </div>
            {!total ? (
              <div className={classes.nodata}>
                <div className={classes.lines}>
                  <div className={classes.line} />
                  <div className={classes.line} />
                  <div className={classes.line} />
                </div>
                <p>{ts.no_data_available_yet}</p>
              </div>
            ) : (
              <StackedGraph
                datasets={agingInvoiceChart.data}
                colors={colors}
                keys={allKeys}
                names={
                  {
                    first: ts.unpaid_invoices_report.past_due_first,
                    second: ts.unpaid_invoices_report.past_due_second,
                  }
                }
                isIso
              />
            )}
          </div>
        </div>
        <div className={classes.monthly_average}>
          <CardMonthlyAverage
            title={ts.unpaid_invoices_report.month}
            value={monthlyAverage?.value}
            change={monthlyAverage?.percentage}
          />
        </div>
        <div className={classes.high}>
          <Card
            data={{
              title: ts.highest_month,
              label: agingInvoiceChart.highestMonth?.month,
              value: agingInvoiceChart.highestMonth?.value,
              second: ts.high,
              change: agingInvoiceChart.highestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.low}>
          <Card
            data={{
              title: ts.lowest_month,
              label: agingInvoiceChart.lowestMonth?.month,
              value: agingInvoiceChart.lowestMonth?.value,
              second: ts.low,
              change: agingInvoiceChart.lowestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.total}>
          <CardSelect
            data={{
              title: ts.all,
              value: reportSelectCards?.all?.entrytotal,
              label: `${reportSelectCards?.all?.count
                ? `${reportSelectCards?.all?.count} ${ts.tabs.re_orders}`
                : ''}`,
              change: reportSelectCards?.all?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        <div className={classes.paid}>
          <CardSelect
            data={{
              title: ts.unpaid_invoices_report.past_due_first,
              value: reportSelectCards?.aging30?.totalamount,
              label: `${reportSelectCards?.aging30?.contacts ? `${reportSelectCards?.aging30?.contacts} ${ts.customers}` : ''}`,
              change: reportSelectCards?.aging30?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        <div className={classes.unpaid}>
          <CardSelect
            data={{
              title: ts.unpaid_invoices_report.past_due_second,
              value: reportSelectCards?.['aging30+']?.totalamount,
              label: `${reportSelectCards?.['aging30+']?.contacts ? `${reportSelectCards?.['aging30+']?.contacts} ${ts.customers}` : ''}`,
              change: reportSelectCards?.['aging30+']?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
      </div>
    </>
  );
};

export default MobileUnpaidInvoiceTab;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
// Constants
import { PayMethodsEnum } from 'constants/paymentsMethods';
// Types
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { IPaymentMethods } from 'utils/storefront/PaymentFunctions';
// Actions
import { checkout, checkoutSetOrder } from 'store/storefront/checkout/checkoutActions';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Assets
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// Components
import FooterPaymentModal from 'components/StorefrontComponents/Checkout/FooterPaymentModal';
import Modal from 'components/Modal';
import BankPaymentStripe from './BankPaymentStripe/BankPaymentStripe';
// Classes
import classes from './BankPaymentOptionModal.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
  handlePaymentModal: () => void;
  clientSecret: string;
  banks: IPaymentMethods[]
  allPayMethods: PayMethodsEnum[]
  setClientSecret: React.Dispatch<React.SetStateAction<string>>
}

type TPaymentsMethod = keyof TranslationStorefrontModel['checkout']['payment_page']['payment_methods'];

const BankPaymentOptionModal: React.FC<Props> = ({
  active,
  setActive,
  handlePaymentModal,
  clientSecret,
  banks,
  allPayMethods,
  setClientSecret,
}) => {
  const { id } = useParams<{ id: string, orderId: string }>();

  const { fulfillment, order, items } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const [activeBank, setActiveBank] = useState<PayMethodsEnum | null>(null);

  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  const translations = useTranslationsStorefront();
  const paymentModalTs = translations.checkout.payment_modal;
  const paymentsMethodsTs = translations.checkout.payment_page.payment_methods;

  const currencyIso = currentSalesChannel?.address.currencyISO || 'EUR';
  const formatCurrencyByISO = CurrencyFormatByISO(currencyIso);
  const dispatch = useDispatch();

  const handleCloseModals = useCallback(() => {
    handlePaymentModal();
    setActive();
  }, [handlePaymentModal, setActive]);

  const handleActiveBank = useCallback(
    async (type: PayMethodsEnum) => {
      setActiveBank(type);

      if (currentSalesChannel && !order.stripe) {
        const data = await checkout(
          id,
          'INSTANT',
          items,
          fulfillment,
          currentSalesChannel,
          'STRIPE',
          allPayMethods,
        );

        if (!data) {
          dispatch(checkoutSetOrder({
            ...order,
            loading: null,
            error: translations.server_error,
          }));
          return;
        }

        localStorage.setItem(`leja-${id}-order`, data.id);
        setClientSecret(data.client_secret);
        dispatch(checkoutSetOrder({
          loading: null,
          error: undefined,
          isPayed: false,
          id: data.id,
          stripe: {
            stripeAccount: data.stripeAccount,
            key: data.key,
          },
        }));
      }
    },
    [allPayMethods,
      currentSalesChannel,
      dispatch, fulfillment, id, items, order, setClientSecret, translations.server_error],
  );

  const iStripe = useCallback(
    async () => {
      if (order.stripe?.key && order.stripe?.stripeAccount) {
        setStripePromise(await loadStripe(order.stripe.key, {
          stripeAccount: order.stripe.stripeAccount,
        }));
      } else {
        dispatch(checkoutSetOrder({
          ...order,
          loading: null,
          error: 'Server error',
        }));
      }
    }, [dispatch, order],
  );

  useEffect(() => {
    if (order.stripe) {
      iStripe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.stripe]);

  return (
    <>
      <Modal
        active={active}
        setActive={setActive}
        className={classes.payment_modal}
      >
        <div className={classes.root}>
          <div className={classes.root_top}>
            <div className={classes.header}>
              <CloseIcon
                role="button"
                className={classes.close_icon}
                onClick={setActive}
              />
              <LeftArrowIcon
                role="button"
                className={classes.left_arrow_icon}
                onClick={setActive}
              />
            </div>
            <div className={classes.title}>
              {paymentModalTs.pay_text} {formatCurrencyByISO(fulfillment.amount.total)}
            </div>
            <p className={classes.subtitle}>
              {translations.checkout.payment_page.description}
            </p>
            <div className={classes.bank_list}>
              {banks.map((bank) => (
                <button
                  key={bank.name}
                  type="button"
                  className={activeBank === bank.name ? classes.bank_active : classes.bank}
                  onClick={() => handleActiveBank(bank.name)}
                >
                  <div className={classes.logo}>
                    {bank.logos.map((logo) => <img src={logo} alt={logo} key={logo} />)}
                  </div>
                  <p>
                    {paymentsMethodsTs[bank.name as TPaymentsMethod].name}
                  </p>
                </button>
              ))}
            </div>
          </div>
          {stripePromise && clientSecret ? (
            <Elements stripe={stripePromise}>
              <BankPaymentStripe
                clientSecret={clientSecret}
                activeBank={activeBank}
                handleBankPaymentOptionModal={handleCloseModals}
              />
            </Elements>
            // eslint-disable-next-line @typescript-eslint/no-empty-function
          ) : <FooterPaymentModal disabled onClick={() => { }} />}
        </div>
      </Modal>
    </>
  );
};

export default BankPaymentOptionModal;

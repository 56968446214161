import React, { useEffect, useMemo, useState } from 'react';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
import { getAgingInvoiceData, getReportSelectCards } from 'store/dashboard/report/reportActions';
import { useParams } from 'react-router-dom';
import { ReactSelectTime } from 'types/globalTypes';
import Card from 'components/Card';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import CardSelect from 'components/CardSelect';
import { getYear } from 'utils/dates';
import { ReportReq } from 'store/dashboard/report/reportTypes';
import StackedGraph from 'components/StackedGraph';
import CustomSpinner from 'components/CustomSpinner';
import MobileUnpaidInvoiceTab from './MobileTab';
import classes from './UnpaidInvoiceTab.module.scss';

export interface AgingInvoiceData {
  month: string;
  type: 1 | 2;
  value: number;
}

const allKeys = ['1', '2'];

const colors = {
  1: 'rgba(65, 29, 117, 1)',
  2: 'rgba(0, 211, 200, 0.8)',
};

const AgingInvoiceTab: React.FC = () => {
  const { isMobile } = useMobileScreen();
  const translations = useTranslations();
  const dispatch = useDispatch();
  const params: { orgId?: string, reportName?: string } = useParams();
  const ts = translations.home.report;
  const [time, setTime] = useState<ReactSelectTime>(ReactSelectTime.yesterday);
  const {
    agingInvoiceChart, reportSelectCards, monthlyAverage, loading,
  } = useTypeSelector(
    ({ dashboard }) => dashboard.report,
  );
  const user = useUser();

  const months = useMemo(() => [
    ts.re_jan,
    ts.re_feb,
    ts.re_mar,
    ts.re_apr,
    ts.re_may,
    ts.re_jun,
    ts.re_jul,
    ts.re_aug,
    ts.re_sep,
    ts.re_oct,
    ts.re_nov,
    ts.re_dec,
  ], [
    ts.re_apr,
    ts.re_aug,
    ts.re_dec,
    ts.re_feb,
    ts.re_jan, ts.re_jul, ts.re_jun, ts.re_mar, ts.re_may, ts.re_nov, ts.re_oct, ts.re_sep]);

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(
        getAgingInvoiceData(
          user.currentOrganisation.id,
          months,
        ),
      );
    }
  }, [dispatch, user, months]);

  useEffect(() => {
    if (!user || !params.orgId || !params?.reportName) {
      return;
    }
    if (!(user?.currentOrganisation?.id)) {
      return;
    }
    dispatch(getReportSelectCards(user.currentOrganisation.id, time, ReportReq.agedinvoices));
  }, [dispatch, params.orgId, params?.reportName, time, user]);

  const getTotal = () => agingInvoiceChart.data.map(
    (item) => item.value,
  ).reduce((a, b) => a + b, 0);

  if (loading) {
    return <CustomSpinner variant="two" />;
  }

  return isMobile ? (
    <MobileUnpaidInvoiceTab
      total={getTotal()}
      time={time}
      setTime={setTime}
    />
  ) : (
    <div className={classes.content_container}>
      <div className={classes.chart_container}>
        <div className={classes.chart}>
          <div className={classes.chart_title}>
            <div className={classes.chart_years}>
              {getYear()} {ts.ytd}
            </div>
            <div className={getTotal() ? classes.chart_text : classes.chart_text_nodata}>
              {ts.tabs.re_aging_invoice}
            </div>
          </div>
          {!getTotal() ? (
            <div className={classes.nodata}>
              <div className={classes.lines}>
                <div className={classes.line} />
                <div className={classes.line} />
                <div className={classes.line} />
              </div>
              <p>{ts.no_data_available_yet}</p>
            </div>
          ) : (
            <StackedGraph
              datasets={agingInvoiceChart.data.map((dataset) => (dataset.type === 1 ? {
                ...dataset,
                type: 2,
              } : {
                ...dataset,
                type: 1,
              }))}
              colors={colors}
              keys={allKeys}
              names={
                {
                  first: ts.unpaid_invoices_report.past_due_first,
                  second: ts.unpaid_invoices_report.past_due_second,
                }
              }
              isIso
            />
          )}
        </div>
      </div>
      <div className={classes.monthly_average}>
        <CardMonthlyAverage
          title={ts.unpaid_invoices_report.month}
          value={monthlyAverage?.value}
          change={monthlyAverage?.percentage}
        />
      </div>
      <div className={classes.high}>
        <Card
          data={{
            title: ts.highest_month,
            label: agingInvoiceChart.highestMonth?.month,
            value: agingInvoiceChart.highestMonth?.value,
            second: ts.high,
            change: agingInvoiceChart.highestMonth?.percentage,
          }}
        />
      </div>
      <div className={classes.low}>
        <Card
          data={{
            title: ts.lowest_month,
            label: agingInvoiceChart.lowestMonth?.month,
            value: agingInvoiceChart.lowestMonth?.value,
            second: ts.low,
            change: agingInvoiceChart.lowestMonth?.percentage,
          }}
        />
      </div>
      <div className={classes.total}>
        <CardSelect
          data={{
            title: ts.all,
            value: reportSelectCards?.all?.entrytotal,
            label: `${reportSelectCards?.all?.count
              ? `${reportSelectCards?.all?.count} ${ts.tabs.re_orders}`
              : ''}`,
            change: reportSelectCards?.all?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
      <div className={classes.paid}>
        <CardSelect
          data={{
            title: ts.unpaid_invoices_report.past_due_first,
            value: reportSelectCards?.aging30?.totalamount,
            label: `${reportSelectCards?.aging30?.contacts ? `${reportSelectCards?.aging30?.contacts} ${ts.customers}` : ''}`,
            change: reportSelectCards?.aging30?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
      <div className={classes.unpaid}>
        <CardSelect
          data={{
            title: ts.unpaid_invoices_report.past_due_second,
            value: reportSelectCards?.['aging30+']?.totalamount,
            label: `${reportSelectCards?.['aging30+']?.contacts ? `${reportSelectCards?.['aging30+']?.contacts} ${ts.customers}` : ''}`,
            change: reportSelectCards?.['aging30+']?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
    </div>
  );
};

export default AgingInvoiceTab;

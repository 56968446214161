import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// Hooks
import { useQuery } from 'hooks/useQuery';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
// Types
import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
// Assets
import { ReactComponent as SearchIcon } from 'assets/storefront-icons/search.svg';
import { ReactComponent as BackIcon } from 'assets/icons/back-arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// Actions
import { getProducts, SetShopProductsAction } from 'store/storefront/shop/shopActions';
// Components
import CustomSpinner from 'components/CustomSpinner';
import Product from 'components/StorefrontComponents/ProductsGrid/Product';
import CompanyAvatar from 'components/StorefrontComponents/CompanyAvatar';
// Styles
import classes from './Search.module.scss';

const Search: React.FC = () => {
  // eslint-disable-next-line no-undef
  const [text, setText] = useState<string>();
  const [active, setActive] = useState<boolean>();
  const { products } = useTypeSelector(({ storefront }) => storefront.shop);
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const query = useQuery();
  const { goToProduct } = useStorefrontRoutes();
  const dispatch = useDispatch();

  const translations = useTranslationsStorefront();

  const history = useHistory();
  const { search } = useTranslationsStorefront();

  const { id }: { id: string, productId: string } = useParams();
  const location = useLocation();
  const openModalDetails = (productId: string) => {
    goToProduct(id, productId, location);
  };

  const variations = (product: ProductItem) => product.options.reduce(
    (prev, curr) => prev + (curr.variations?.length || 0), 0,
  );

  const searchHandler = (value: string) => {
    if (!query.has('search')) {
      query.set('search', value);
    } else {
      query.set('search', value);
    }

    if (!value) {
      query.delete('search');
    }

    history.replace({
      search: query.toString(),
    });

    dispatch(getProducts(id, query));
  };

  useEffect(() => {
    const searchQuery = query.get('search');
    if (searchQuery) {
      setText(searchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(searchHandler, 1000), [query]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setText(value);
    if (value.length > 2 || value.length === 0) {
      dispatch(SetShopProductsAction({
        ...products,
        loading: true,
      }));
      debounceFn(value);
    }
  };

  const handleFocus = () => {
    setActive(true);

    query.set('search', '');

    history.replace({
      search: query.toString(),
    });
  };

  const clearInput = () => {
    setText('');
    query.set('search', '');

    history.replace({
      search: query.toString(),
    });
  };

  const handleClose = () => {
    setActive(false);
    setText('');
    query.delete('search');

    history.replace({
      search: query.toString(),
    });
  };

  useEffect(() => {
    if (query.has('search')) {
      setActive(true);
    }
  }, [query]);

  return (
    <div className={active ? classes.search_wrapper_active : classes.search_wrapper}>
      {active && currentSalesChannel
        && (
          <div className={classes.header}>
            <CompanyAvatar title={currentSalesChannel.name} logoUrl={currentSalesChannel.logoUrl} />
          </div>
        )}
      <div className={active ? classes.search_active : classes.search}>
        <BackIcon className={classes.back_icon} onClick={handleClose} />
        <SearchIcon className={classes.search_icon} />
        <input
          type="text"
          value={text}
          placeholder={search.input_navbar_placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        <CloseIcon
          className={
            active ? classes.close_icon_active : classes.close_icon
          }
          onClick={clearInput}
        />
      </div>
      {active && (text?.length || 0) > 2 && !!products.searchResult && (
        <div className={classes.shop_items}>
          {products.data.map((product) => (
            <Product
              key={product.id}
              product={product}
              translations={translations}
              openModalDetails={openModalDetails}
              currencyISO={currentSalesChannel?.address.countryISO || 'EUR'}
              variations={variations}
            />
          ))}
        </div>
      )}
      {
        !products.searchResult && !products.loading && (text?.length || 0) > 2 && (
          <div className={classes.not_found}>
            <SearchIcon className={classes.not_found_icon} />
            {search.no_results}
          </div>
        )
      }
      {products.loading && (text?.length || 0) > 2 && (
        <div className={classes.loading_product}>
          <CustomSpinner variant="two" />
        </div>
      )}
    </div>
  );
};

export default Search;

import React, { useEffect, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { PayMethodsEnum } from 'constants/paymentsMethods';
import { useTypeSelector } from 'hooks/useTypeSelector';
import AfterpayClearpay from './AfterpayClearpay';
import Klarna from './Klarna';
import PayLaterInvoice from './PayLaterInvoice';

interface Props {
  type: PayMethodsEnum | null;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setData: (data: FieldValues) => void;
}

const PaymentLaterForm: React.FC<Props> = ({ type, setDisabled, setData }) => {
  const { fulfillment } = useTypeSelector(({ storefront }) => storefront.checkout);
  const fullName = useMemo(
    () => `${fulfillment.contact?.firstName} ${fulfillment.contact?.lastName}`,
    [fulfillment.contact?.firstName, fulfillment.contact?.lastName],
  );

  const {
    register, getValues, trigger, reset,
    formState: { isValid },
  } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({
      email: fulfillment.contact?.email,
      fullName,
      line1: fulfillment.contact?.address?.line1,
      line2: fulfillment.contact?.address?.line2,
      city: fulfillment.contact?.address?.city,
      postalCode: fulfillment.contact?.address?.postalCode,
      shipping: {
        fullName,
        line1: fulfillment.contact?.address?.line1,
        line2: fulfillment.contact?.address?.line2,
        city: fulfillment.contact?.address?.city,
        postalCode: fulfillment.contact?.address?.postalCode,
      },
    }), [fulfillment, fullName]),
  });

  useEffect(() => {
    reset({
      fullName,
      email: fulfillment.contact?.email,
      city: fulfillment.contact?.address?.city,
      line1: fulfillment.contact?.address?.line1,
      line2: fulfillment.contact?.address?.line2,
      postalCode: fulfillment.contact?.address?.postalCode,
      shipping: {
        fullName,
        line1: fulfillment.contact?.address?.line1,
        line2: fulfillment.contact?.address?.line2,
        city: fulfillment.contact?.address?.city,
        postalCode: fulfillment.contact?.address?.postalCode,
      },
    });
    trigger();
    setData(getValues());
  }, [fulfillment, fullName, getValues, reset, setData, trigger]);

  useEffect(() => {
    setDisabled(isValid);
    setData(getValues());
  }, [getValues, isValid, setData, setDisabled]);

  if (type === PayMethodsEnum.KLARNA) {
    return (
      <Klarna register={register} />
    );
  }

  if (type === PayMethodsEnum.AFTERPAY_CLEARPAY) {
    return (
      <AfterpayClearpay register={register} />
    );
  }

  if (type === PayMethodsEnum.PAY_LATER) {
    return (
      <PayLaterInvoice register={register} />
    );
  }

  return null;
};

export default PaymentLaterForm;

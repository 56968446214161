/* eslint-disable no-console */
// Types
import { AppThunk } from 'store';
// Utils
import firebase, { auth } from 'services/firebase';
import { API, ApiOrgId } from 'services/api';
import { TranslationDashboardModel, TranslationsType } from 'types/TranslationDashboardModel';
import { v4 as uuidv4 } from 'uuid';
import { STRAPI } from 'services/strapi';

import { mixpanelActions } from 'utils/mixpanel';
import { IOrganisationData } from 'types/globalTypes';
import {
  AUTH_SET_USER,
  AUTH_SET_TYPE,
  AUTH_SET_LOADING,
  AUTH_SET_ERROR,
  AUTH_SET_LOGIN,
  AUTH_SET_CHECK_LOGIN,
  AUTH_SET_CURRENT_ORGANISATION,
  AuthType,
  AuthActions,
  UserAuthData,
  APP_SET_TRANSLATIONS_FETCHED,
  APP_SET_ACTIVE_TRANSLATION,
  APP_SET_CURRENT_LANGUAGE,
  SubscriptionData,
  AUTH_SET_SUBSCRIPTION,
  ISubscription,
  SubscriptionPlansData,
  APP_SET_SIGN_OUT,
} from './authTypes';

interface ReCaptcha extends Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recaptchaVerifier?: any;
  confirmationResult?: firebase.auth.ConfirmationResult;
}

export const setUserAction = (payload: UserAuthData | null): AuthActions => ({
  type: AUTH_SET_USER,
  payload,
});

export const setSubscriptionAction = (payload: ISubscription | null): AuthActions => ({
  type: AUTH_SET_SUBSCRIPTION,
  payload,
});

export const setAuthTypeAction = (payload: AuthType): AuthActions => ({
  type: AUTH_SET_TYPE,
  payload,
});

export const setAuthLoading = (payload: boolean): AuthActions => ({
  type: AUTH_SET_LOADING,
  payload,
});

export const setAuthError = (payload: { msg: string, code: string } | null): AuthActions => ({
  type: AUTH_SET_ERROR,
  payload,
});

export const setIsLoginAction = (payload: boolean): AuthActions => ({
  type: AUTH_SET_LOGIN,
  payload,
});

export const setIsLoginCheckAction = (payload: boolean): AuthActions => ({
  type: AUTH_SET_CHECK_LOGIN,
  payload,
});

export const setCurrentOrganisationAction = (payload: IOrganisationData): AuthActions => ({
  type: AUTH_SET_CURRENT_ORGANISATION,
  payload,
});

export const setTranslationsFetchedAction = (payload: boolean): AuthActions => ({
  type: APP_SET_TRANSLATIONS_FETCHED,
  payload,
});

export const setActiveTranslations = (payload: TranslationDashboardModel): AuthActions => ({
  type: APP_SET_ACTIVE_TRANSLATION,
  payload,
});

export const setCurrentLanguage = (payload: string): AuthActions => ({
  type: APP_SET_CURRENT_LANGUAGE,
  payload,
});

export const setSignOut = (payload: boolean): AuthActions => ({
  type: APP_SET_SIGN_OUT,
  payload,
});

export const setAuthByOTP = (phoneNumber: string): AppThunk => async (dispatch) => {
  const appVerifier = (window as ReCaptcha).recaptchaVerifier;
  dispatch(setAuthLoading(true));
  try {
    await auth.signInWithPhoneNumber(
      phoneNumber,
      appVerifier,
    ).then((result) => {
      (window as ReCaptcha).confirmationResult = result;
      if (result) {
        dispatch(setAuthTypeAction('PHONE'));
      }
    })
      .catch((err) => {
        dispatch(setAuthError({ msg: err.message, code: err.code }));
      });
    // eslint-disable-next-line
  } catch (err: any) {
    console.log(err);
    (window as ReCaptcha).recaptchaVerifier.reset();
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const setCodeConfirm = (authSmsCode: string): AppThunk => async (dispatch) => {
  dispatch(setAuthLoading(true));

  try {
    // eslint-disable-next-line
    const { user } = await (window as ReCaptcha).confirmationResult!.confirm(
      authSmsCode,
    );
    if (user) {
      const uuid = uuidv4();
      mixpanelActions.alias(uuid, `${user?.uid}`);
      mixpanelActions.identify(`${user?.uid}`);
      mixpanelActions.people.set({
        $name: user.displayName,
        $email: user.email,
        $signupDate: user.metadata.creationTime,
        $phone: user.phoneNumber,
      });
      mixpanelActions.track('View App Login Page', {
        'Page Name': 'Dashboard Login',
      });
      mixpanelActions.track('Logged In', {
        'Page Name': 'Dashboard Login',
      });
      dispatch(setIsLoginAction(true));
      dispatch(setAuthTypeAction(null));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // eslint-disable-next-line
    console.log(error);
    if (error.code && error.code === 'auth/invalid-verification-code') {
      dispatch(setAuthError({ msg: error.message, code: error.code }));
      dispatch(setAuthLoading(false));
    }
    (window as ReCaptcha).recaptchaVerifier.reset();
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const signInWithPopup = (): AppThunk => async (dispatch) => {
  dispatch(setAuthLoading(true));
  const provider = new firebase.auth.GoogleAuthProvider();

  try {
    const { user } = await firebase.auth().signInWithPopup(provider);

    if (user) {
      dispatch(setIsLoginAction(true));
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const signOut = (): AppThunk => async (dispatch) => {
  try {
    auth.signOut();
    dispatch(setUserAction(null));
    dispatch(setIsLoginAction(false));
    dispatch(setSignOut(true));
  } catch (error) {
    console.log(error);
  }
};

export const checkIsLogin = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoginCheckAction(true));

    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        dispatch(setIsLoginAction(false));
        dispatch(setIsLoginCheckAction(false));
        return;
      }

      dispatch(setIsLoginAction(true));
      dispatch(setIsLoginCheckAction(false));
    });
  } catch (error) {
    console.log(error);
    dispatch(setIsLoginCheckAction(false));
  }
};

export const setUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setAuthLoading(true));

    const { data } = await API.get<UserAuthData>('/accounts/me');

    const { organisations, currentOrganisation } = await getUserOrganisations();

    if (!currentOrganisation) {
      dispatch(setAuthLoading(false));
      dispatch(setUserAction({
        ...data,
        photoUrl: auth.currentUser?.photoURL || null,
        organisations: [],
        currentOrganisation: null,
        status: data.status,
      }));
      return;
    }
    mixpanelActions.identify(data?.uid);
    dispatch(getSubscriptions(currentOrganisation.id));

    dispatch(setUserAction({
      ...data,
      photoUrl: auth.currentUser?.photoURL || null,
      organisations,
      currentOrganisation,
      status: data.status,
    }));

    dispatch(setAuthLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getUserOrganisations = async (): Promise<{
  organisations: IOrganisationData[],
  currentOrganisation: IOrganisationData | null,
}> => {
  try {
    const { data } = await API.get('/organisations');

    const currentOrganisationId = await localStorage.getItem('leja-org-id');

    const getCurrentOrganisation = (): IOrganisationData | null => {
      if (!currentOrganisationId && !data) {
        return null;
      }

      if (!currentOrganisationId && data) {
        return data[0];
      }

      const foundedOrganisation: IOrganisationData | undefined = data.find((
        org: IOrganisationData,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ) => org.id === currentOrganisationId!);

      return foundedOrganisation || null;
    };

    return {
      organisations: data,
      currentOrganisation: getCurrentOrganisation(),
    };
  } catch (error) {
    console.log(error);
    return {
      organisations: [],
      currentOrganisation: null,
    };
  }
};

export const getSubscriptions = (orgId: string): AppThunk => async (dispatch) => {
  try {
    const userSubscriptions = await ApiOrgId<SubscriptionData>('/subscriptions/latest', orgId);
    const subscriptionsPlans: SubscriptionPlansData[] = await ApiOrgId('/subscription-plans', orgId);
    const subscriptionData = subscriptionsPlans.find(
      (subscription) => subscription.id === userSubscriptions.subscriptionPlanId,
    );

    mixpanelActions.people.set({
      'Subscription Date': userSubscriptions.createdAt,
      'Subscription Plan Type': subscriptionData?.name,
      'Account Status': userSubscriptions.status,
    });

    dispatch(setSubscriptionAction({
      status: userSubscriptions.status,
      subscriptionPlanId: userSubscriptions.subscriptionPlanId,
      subscriptionData: subscriptionData?.name,
    }));
  } catch (error) {
    console.log(error);
  }
};

export const getTranslations = (
  translations: keyof TranslationsType,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const { data } = await STRAPI.get<{ id: number, translations: TranslationsType }[]>('/Homes?id=20');
    // eslint-disable-next-line dot-notation
    if (typeof data[0].translations !== 'undefined' && typeof data[0].translations.en !== 'undefined') {
      dispatch(setTranslationsFetchedAction(true));
      dispatch(setCurrentLanguage(translations));
      dispatch(setActiveTranslations(data[0].translations[translations]));
      dispatch(setAuthLoading(false));
    }
  } catch (error) {
    dispatch(setAuthLoading(false));
    console.log(error);
  }
};

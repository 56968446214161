/* eslint-disable no-console */
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { nanoid } from 'nanoid';
import { API, ApiOrgId } from 'services/api';
import { AppThunk } from 'store';
import {
  ContactType, Documents, Items, TransactionStatus,
} from 'types/globalTypes';
import { getYear } from 'utils/dates';
import { calcTaxes, getStatus } from 'utils/functions';
import { calcSubtotal, calcTotalFees } from 'utils/price';
import { capitalizeFirstLetter } from 'utils/strings';
import { ReminderData, ResTransactionDetails } from '../transaction/transactionTypes';
import {
  OrderActions,
  OrderDetailsType,
  SET_ORDER_DETAILS,
  PAYMENTS_METHODS_SET_DATA,
  PaymentsMethods,
  APP_SET_NOTIFICATION_ORDER,
  ORDER_SET_LOADING,
  SET_UPDATE_DETAILS,
  SET_ORDERS,
  CLEAR_ORDERS,
  OrderType,
  MORE_PAGE,
  ORDERS_SET_SUMMARY,
  OrdersSummary,
  OrderTabsEnum,
  ORDERS_IS_REMINDER,
  ResOrderDetails,
} from './orderTypes';

export const ordersSetDetails = (payload: OrderDetailsType): OrderActions => ({
  type: SET_ORDER_DETAILS,
  payload,
});

export const ordersSetPaymentsMethod = (payload: PaymentsMethods[]): OrderActions => ({
  type: PAYMENTS_METHODS_SET_DATA,
  payload,
});

export const setNotificationOrderAction = (payload: string | null): OrderActions => ({
  type: APP_SET_NOTIFICATION_ORDER,
  payload,
});

export const setOrderLoading = (payload: string): OrderActions => ({
  type: ORDER_SET_LOADING,
  payload,
});

export const ordersSetUpdateDetails = (
  payload: Omit<OrderDetailsType, 'contact'>,
): OrderActions => ({
  type: SET_UPDATE_DETAILS,
  payload,
});

export const setOrders = (payload: OrderType[]): OrderActions => ({
  type: SET_ORDERS,
  payload,
});

export const clearOrders = (payload: OrderType[]): OrderActions => ({
  type: CLEAR_ORDERS,
  payload,
});

export const endPagesDataAction = (payload: boolean): OrderActions => ({
  type: MORE_PAGE,
  payload,
});

export const ordersSetSummary = (payload: OrdersSummary): OrderActions => ({
  type: ORDERS_SET_SUMMARY,
  payload,
});

export const orderIsReminderAction = (payload: boolean): OrderActions => ({
  type: ORDERS_IS_REMINDER,
  payload,
});

export const getPaymentsMethods = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await API.get('payment-methods');
    dispatch(ordersSetPaymentsMethod(data));
  } catch (err) {
    console.error(err);
  }
};

export const getOrderDetails = (id: string, orgId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setOrderLoading('details'));
    const orderDetailsRes: ResOrderDetails = await ApiOrgId(
      `/sales-orders/${id}?alias=details`,
      orgId,
    );

    const fulfillmentDetailsFeesCount = () => {
      if (orderDetailsRes?.fulfillmentDetails) {
        return orderDetailsRes?.fulfillmentDetails.fee;
      }
      return 0;
    };

    const fulfillmentDetailsFees = () => {
      if (orderDetailsRes?.fulfillmentDetails?.fulfillmentType) {
        const name = orderDetailsRes?.fulfillmentDetails.fulfillmentType;
        return {
          [capitalizeFirstLetter(name.toLowerCase())]: orderDetailsRes?.fulfillmentDetails.fee,
        };
      }
      return null;
    };

    const fees = () => {
      const fulfillmentFees = fulfillmentDetailsFees();
      if (fulfillmentFees && orderDetailsRes.fees) {
        return [...orderDetailsRes.fees, fulfillmentFees];
      }

      if (fulfillmentFees) {
        return [fulfillmentFees];
      }

      if (orderDetailsRes.fees) {
        return [...orderDetailsRes.fees];
      }

      return undefined;
    };

    const taxes = calcTaxes(orderDetailsRes.items);
    const subtotal = calcSubtotal(orderDetailsRes);
    const url = await ApiOrgId(`/sales-orders/${id}?alias=url`, orgId);
    const orderDetailsData = {
      orderName: String(orderDetailsRes?.id) || '',
      type: orderDetailsRes.type,
      dueDate: orderDetailsRes.dueDate,
      publicId: orderDetailsRes?.publicId,
      associations: orderDetailsRes.associations,
      status: getStatus(orderDetailsRes.statusId),
      updatedAt: orderDetailsRes.updatedAt,
      contact: orderDetailsRes.contact,
      balance: orderDetailsRes.balance,
      created: orderDetailsRes.createdAt,
      url: url?.url || '',
      source:
        orderDetailsRes.salesChannel === 'whatsapp'
          ? orderDetailsRes.salesChannel.replace('whatsapp', 'WhatsApp')
          : capitalizeFirstLetter(orderDetailsRes?.salesChannel || ''),
      fulfillmentName: orderDetailsRes?.fulfillmentDetails?.fulfillmentType,
      paymentWith: orderDetailsRes?.payments && orderDetailsRes.payments[0].paymentMethod,
      paidOn: orderDetailsRes.payments ? orderDetailsRes.payments[0].paymentDate : '',
      cancelledOn: orderDetailsRes.updatedAt,
      items: orderDetailsRes.items,
      totalTax: orderDetailsRes.totalTax,
      currencyISO: orderDetailsRes.currencyISO,
      total: orderDetailsRes.total,
      notes: orderDetailsRes.notes,
      shipping: orderDetailsRes.fulfillmentDetails
        ? orderDetailsRes.fulfillmentDetails.fee
        : 0,
      discount: orderDetailsRes.discountAmount || 0,
      fulfillmentDetails: orderDetailsRes.fulfillmentDetails,
      refunds:
        orderDetailsRes.payments
        && orderDetailsRes.payments[0]
        && orderDetailsRes.payments[0].amount,
      price: {
        currencyIso: orderDetailsRes?.currencyISO,
        subtotal,
        discount: orderDetailsRes.discountAmount || 0,
        total: subtotal
          - (orderDetailsRes.discountAmount || 0)
          + calcTotalFees(orderDetailsRes.fees) + taxes.excluded + fulfillmentDetailsFeesCount(),
        paymentMethod: (orderDetailsRes?.payments?.length && orderDetailsRes?.payments?.length !== 0 ? orderDetailsRes?.payments[0].paymentMethod : ''),
        fees: fees(),
      },
    };
    dispatch(ordersSetDetails(orderDetailsData));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(setOrderLoading(''));
  }
};

export const cancelOrderAction = (
  id: string,
  orgId: string,
  status: TransactionStatus,
  orderDetails: OrderDetailsType,
): AppThunk => async (dispatch) => {
  dispatch(setOrderLoading('cancel'));
  try {
    const { data }: { data: ResTransactionDetails } = await API.patch(
      `/sales-orders/${id}?alias=details`,
      JSON.stringify({ status: status.toUpperCase() }),
      {
        headers: {
          'leja-organisation-id': orgId,
          'Content-Type': 'application/json',
        },
      },
    );

    const orderUpdateData = {
      ...orderDetails,
      status: getStatus(data.statusId),
      cancelledOn: data.updatedAt,
    };

    dispatch(ordersSetDetails(orderUpdateData));
    dispatch(setNotificationOrderAction(status));
  } catch (err) {
    dispatch(setNotificationOrderAction('err'));
    console.error(err);
  } finally {
    dispatch(setOrderLoading(''));
  }
};

export const getOrders = (type: OrderTabsEnum, orgId: string): AppThunk => async (dispatch) => {
  dispatch(setOrderLoading('orders'));
  try {
    let ordersRes = [];
    if (type === OrderTabsEnum.All) {
      ordersRes = await ApiOrgId('/sales-orders?page=0', orgId);
    }
    if (type === OrderTabsEnum.ProcessedOrders) {
      ordersRes = await ApiOrgId('/sales-orders?page=0&status=PROCESSED', orgId);
    }
    if (type === OrderTabsEnum.CanceledOrders) {
      ordersRes = await ApiOrgId('/sales-orders?page=0&status=CANCELLED', orgId);
    }

    if (type === OrderTabsEnum.UnprocessedOrders) {
      ordersRes = await ApiOrgId('/sales-orders?page=0&status=UNPROCESSED', orgId);
    }

    const data = ordersRes.map((item: {
      id: string,
      uniqId: string,
      createdAt: string,
      total: number,
      currencyISO: string,
      balance: number,
      items: Items[]
      salesChannel: string
      contact: ContactType
      statusId: number
     }) => ({
      id: item.id,
      uniqId: nanoid(),
      date: item.createdAt,
      amount: item.total,
      currencyISO: item.currencyISO,
      balance: item.balance,
      items: item.items,
      salesChannel:
        item.salesChannel === 'whatsapp'
          ? item.salesChannel.replace('whatsapp', 'WhatsApp')
          : item.salesChannel,
      client: `${item.contact.firstName} ${item.contact.lastName}`,
      status: getStatus(item.statusId),
    }));

    dispatch(endPagesDataAction(data.length < 10));
    dispatch(setOrders(data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setOrderLoading(''));
  }
};

export const getSearchResult = (text: string, orgId: string): AppThunk => async (dispatch) => {
  try {
    const searchRes = await ApiOrgId(`/sales-orders?search=${text}`, orgId);
    const data = searchRes.map((item: {
      entryid: string
      createdat: string,
      total: number,
      ordernumber: string,
      firstname: string,
      lastname: string,
      statusId: number,
    }) => ({
      id: item.entryid,
      date: item.createdat,
      amount: item.total,
      numberOrder: item.ordernumber.substring(0, 5),
      client: `${item.firstname} ${item.lastname}`,
      status: getStatus(item.statusId),
    }));
    dispatch(clearOrders(data));
  } catch (error) {
    console.log(error);
  }
};

export const ordersGetSummary = (orgId: string): AppThunk => async (dispatch) => {
  try {
    const res = await ApiOrgId(`/summary/orders?year=${getYear()}`, orgId);
    dispatch(ordersSetSummary(res));
  } catch (error) {
    console.error(error);
  }
};

export const getMoreOrders = (
  type: OrderTabsEnum,
  orgId: string,
  page: number,
  orders: OrderType[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(setOrderLoading('more orders'));
    let ordersRes = [];
    if (type === OrderTabsEnum.All) {
      ordersRes = await ApiOrgId(`/sales-orders?page=${page}`, orgId);
    }

    if (type === OrderTabsEnum.ProcessedOrders) {
      ordersRes = await ApiOrgId(
        `/sales-orders?page=${page}&status=PROCESSED`,
        orgId,
      );
    }

    if (type === OrderTabsEnum.CanceledOrders) {
      ordersRes = await ApiOrgId(
        `/sales-orders?page=${page}&status=CANCELLED`,
        orgId,
      );
    }

    if (type === OrderTabsEnum.UnprocessedOrders) {
      ordersRes = await ApiOrgId(
        `/sales-orders?page=${page}&status=UNPROCESSED`,
        orgId,
      );
    }

    const ordersNew = ordersRes.map((item: Documents) => ({
      id: item.id,
      uniqId: nanoid(),
      date: item.createdAt,
      amount: item.total,
      currencyISO: item.currencyISO,
      balance: item.balance,
      salesChannel:
        item.salesChannel === 'whatsapp'
          ? item.salesChannel.replace('whatsapp', 'WhatsApp')
          : item.salesChannel,
      client: `${item.contact.firstName} ${item.contact.lastName}`,
      status: getStatus(item.statusId),
    }));

    dispatch(endPagesDataAction(ordersRes.length < 10));
    dispatch(setOrders([...ordersNew, ...orders]));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setOrderLoading(''));
  }
};

interface SentProcessData {
  address?: string;
  trackingId?: string;
  shippingCourier?: string;
  shippingPhone?: string;
  providerName?: string;
}

export const processOrder = (
  id: string,
  orgId: string,
  status: TransactionStatus,
  orderDetails: OrderDetailsType,
  sentProcessShipped: SentProcessData,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setOrderLoading('process'));
    const { data }: { data: ResTransactionDetails } = await API.patch(
      `/sales-orders/${id}?alias=details`,
      JSON.stringify({
        status: status.toUpperCase(),
        fulfillmentDetails: {
          ...orderDetails.fulfillmentDetails,
          ...sentProcessShipped,
        },
      }),
      {
        headers: {
          'leja-organisation-id': orgId,
          'Content-Type': 'application/json',
        },
      },
    );

    const orderUpdateData = {
      ...orderDetails,
      status: getStatus(data.statusId),
      fulfillmentDetails: data.fulfillmentDetails,
      updatedAt: data.updatedAt,
    };

    dispatch(ordersSetDetails(orderUpdateData));
    dispatch(setNotificationOrderAction(status));
  } catch (err) {
    dispatch(setNotificationOrderAction('err'));
    console.error(err);
  } finally {
    dispatch(setOrderLoading(''));
  }
};

export const createReminder = (
  orgId: string,
  orgExternalId: string,
  data: ReminderData,
): AppThunk => async (dispatch) => {
  dispatch(setOrderLoading('reminder'));
  try {
    await API.post('/reminders', data, {
      headers: {
        'Content-Type': 'application/json',
        'leja-organisation-id': orgId,
        'leja-organisation-external-id': orgExternalId,
      },
    });
    dispatch(orderIsReminderAction(true));
    dispatch(setNotificationOrderAction('reminder'));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setOrderLoading(''));
  }
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import {
  Route, useHistory, RouteProps, useParams, useLocation,
} from 'react-router-dom';
// Check login action
import { checkIsLogin, getTranslations, setUser } from 'store/auth/authActions';
import { useUser } from 'hooks/useUser';
import { useTranslations } from 'hooks/useTranslations';
import { mixpanelActions } from 'utils/mixpanel';

const DashboardRoute: React.FC<RouteProps> = (props) => {
  const dispatch = useDispatch();
  const { isLogin, isLoginCheck } = useTypeSelector(({ auth }) => auth);
  const { isTranslationsFetched } = useTypeSelector(({ dashboard }) => dashboard.app);
  const history = useHistory();
  const translations = useTranslations();
  const ts = translations?.home?.report;
  const location = useLocation();
  const params: { orgId?: string } = useParams();
  const user = useUser();

  useEffect(() => {
    if (!isLogin) {
      dispatch(checkIsLogin());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  useEffect(() => {
    if (!isLogin && !isLoginCheck) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, isLoginCheck, history]);

  useEffect(() => {
    if (!isTranslationsFetched || !translations.dashboard) {
      dispatch(getTranslations('en'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTranslationsFetched, translations]);

  useEffect(() => {
    if (isLogin && !isLoginCheck && !user) {
      dispatch(setUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, isLoginCheck, user]);

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      if (!params.orgId) {
        if (user?.organisations[0]
          && user?.organisations[0].id) {
          if (!user?.currentOrganisation?.id) {
            return;
          }

          mixpanelActions.people.set({
            'Org Name': user.currentOrganisation.name,
            'Org ID': user.currentOrganisation.id,
          });

          history.push(
            `/dashboard/${user?.currentOrganisation?.id}/${ts?.tabs?.re_sales.toLowerCase()}`,
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history,
    params.orgId,
    user?.organisations,
    ts?.tabs?.re_sales,
    location.pathname,
    user?.currentOrganisation?.id]);

  return (
    <Route {...props} />
  );
};

export default DashboardRoute;

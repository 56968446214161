import React, { useState, useRef } from 'react';
import classNames from 'classnames';
// Icons
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_x_icon.svg';
// Phone codes
import phoneCodes from 'data/phone-codes.json';
// Types
import { PhoneCode } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Hooks
import useOnClickOutside from 'hooks/useOnClickOutside';
// Styles
import { useMobileScreen } from 'hooks/useMobileScreen';
import classes from './PhoneInput.module.scss';

interface Props {
  code: string | null;
  setCode: (code: string | null) => void;
  setPhone: (phone: string) => void;
  className?: string;
  handlerPressEnter: () => void;
  phone: string;
  translations: TranslationDashboardModel['login'];
}

const PhoneInput: React.FC<Props> = ({
  code,
  setCode,
  setPhone,
  className,
  handlerPressEnter,
  phone,
  translations,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [search, setSearch] = useState('');
  const [countryPhones, setCountryPhones] = useState<PhoneCode[]>(phoneCodes);
  const dropdownRef = useRef(null);
  const { windowWidth } = useMobileScreen();
  const mobileWidth = !!windowWidth && windowWidth <= 425;
  const tsLoginInput = translations.phone_input;
  const handleClickOutside = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  useOnClickOutside(dropdownRef, handleClickOutside);

  const handlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
    setPhoneNumber(event.target.value);
  };

  const onKeyPress = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      handlerPressEnter();
    }
  };

  const handlerSetCode = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => {
    const dialCode = event.currentTarget.getAttribute('data-code');
    setCode(dialCode);
    setOpen(false);
  };

  const handlerCodeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setSearch(event.target.value);
    }
    if (!(/^[a-z]+$/i.test(event.target.value))) {
      return;
    }
    setSearch(event.target.value);
    const countryName = event.target.value.trim().toLowerCase();
    const countryPhoneCodes = phoneCodes.filter((item) => (
      item.name.toLowerCase().match(countryName)
    ));

    setCountryPhones(countryPhoneCodes);
  };

  return (
    <div className={classNames(classes.phone__input, className)}>
      <div className={classes.phone__input_dropdown_btn_wrapper} ref={dropdownRef}>
        <button
          className={classes.phone__input_dropdown_btn}
          type="button"
          onClick={handleOpen}
        >
          <div>
            <span>
              {code}
            </span>
            <label>{phoneCodes.find((phoneCode) => phoneCode.dial_code === code)?.name}</label>
          </div>
          <div
            className={classes.phone__input_dropdown_icon}
            style={open
              ? { transform: 'rotate(45deg)', transition: '0.3s' }
              : { transform: 'rotate(45deg)', transition: '0.3s' }}
          />
        </button>
        {open && (
          <div className={classes.phone__input_list_mobile}>
            {mobileWidth
              && (
                <CloseIcon onClick={() => setOpen(false)} />
              )}
            <div className={classes.phone__input_list_select}>
              <div className={classes.phone__input_list_search}>
                <label htmlFor="country">
                  <SearchIcon />
                </label>
                <input
                  value={search}
                  onChange={handlerCodeSearch}
                  type="text"
                  name="country"
                  id="country"
                />
              </div>
              <ul className={classes.phone__input_list}>
                {countryPhones.map((item: PhoneCode) => (
                  <li
                    key={item.name}
                  >
                    <div
                      className={classes.phone__input_list_item}
                      role="button"
                      tabIndex={0}
                      data-code={item.dial_code}
                      onClick={handlerSetCode}
                      onKeyPress={handlerSetCode}
                    >
                      <span>{item.name}</span>
                      <span>{item.dial_code}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className={classes.phone__input_input_container}>
        <input
          type="number"
          name="phone"
          id="phone"
          required
          value={phone}
          onChange={handlerChange}
          className={classes.phone__input_input}
          onKeyPress={onKeyPress}
        />
        <span className={classes.phone__input_floating_label}>
          {phoneNumber ? tsLoginInput.mobile_phone_number : tsLoginInput.enter_your_mobile}
        </span>
      </div>
    </div>
  );
};

export default PhoneInput;

import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { StorefrontRoutePath } from 'router/Routes/Storefront';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Utils
import { getYear } from 'utils/dates';
// Assets
import { ReactComponent as WhatsappIcon } from 'assets/storefront-icons/socials/whatsapp.svg';
// Components
import AboutUs from './AboutUs';
import PaymentAccepted from './PaymentAccepted';
import ShareShop from './ShareShop';
// classes
import classes from './Footer.module.scss';

interface Props {
  className?: string;
}

const Footer: React.FC<Props> = ({ className }) => {
  const { id } = useParams<{ id: string, orderId: string }>();
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const translations = useTranslationsStorefront();

  return (
    <div className={`${classes.footer} ${className}`}>
      <div className={classes.footer_top}>
        <PaymentAccepted title={translations.footer.payment_accepted} />
        <div className={classes.footer_shipping}>
          <p>{translations.menu_links.shipping}</p>
          <NavLink
            to={StorefrontRoutePath.SHIPPING.replace(':id', id)}
            className={classes.footer_shipping_link}
          >
            {translations.footer.view_details}
          </NavLink>
        </div>
        <ShareShop />
      </div>
      <div className={classes.footer_center}>
        <AboutUs />
        <div className={classes.footer_help}>
          <p>
            {translations.footer.need_help}
          </p>
          {currentSalesChannel?.customerService?.whatsapp && (
            <a
              href={`https://wa.me/${currentSalesChannel?.customerService?.whatsapp}`}
              className={classes.contact}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsappIcon />
              <span>{currentSalesChannel?.customerService?.whatsapp}</span>
            </a>
          )}
        </div>
      </div>
      <span className={classes.year}>
        {translations.footer.label.replace('{year}', getYear().toString()).replace('{shopName}', currentSalesChannel?.name || '')}
      </span>
    </div>
  );
};

export default Footer;

import { combineReducers } from '@reduxjs/toolkit';
import { AppState } from './app/appTypes';
import { appReducer } from './app/appReducer';
import { ShopState } from './shop/shopTypes';
import { shopReducer } from './shop/shopReducer';
import { checkoutReducer } from './checkout/checkoutReducer';
import { CheckoutState } from './checkout/checkoutTypes';
import { orderReducer } from './order/orderReducer';
import { OrderState } from './order/orderTypes';
import { AccountReducer } from './account/accountReducer';
import { AccountState } from './account/accountTypes';

export interface StorefrontState {
  app: AppState;
  shop: ShopState;
  checkout: CheckoutState;
  order: OrderState;
  account: AccountState;
}

const storefrontReducers = combineReducers<StorefrontState>({
  app: appReducer,
  shop: shopReducer,
  checkout: checkoutReducer,
  order: orderReducer,
  account: AccountReducer,
});

export default storefrontReducers;

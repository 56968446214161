import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Types
import { TranslationsType } from 'types/TranslationDashboardModel';
// Actions
import {
  checkIsLogin,
  setAuthByOTP,
  setSignOut,
} from 'store/auth/authActions';
import { getTranslations } from 'store/dashboard/app/appActions';
import { useTranslations } from 'hooks/useTranslations';
// Utils
import firebase from 'services/firebase';
import { formatPhoneForE164, isValidPhoneNumber } from 'utils/phoneNumber';
import { mixpanelActions } from 'utils/mixpanel';
import { openInNewTab } from 'utils/functions';
// Icons
import { ReactComponent as LejaLogo } from 'assets/icons/logo-leja-login.svg';
import { ReactComponent as BackArrow } from 'assets/icons/back-arrow.svg';
// Components
import PhoneInput from 'components/Inputs/PhoneInput';
import ModalSuccess from 'components/Modal/ModalSuccess';
import LoginPhone from './LoginPhone';
import MessageError from './MessageError';
import Menu from './Menu';
import SelectLanguages from './SelectLanguages';
// Styles
import classes from './Login.module.scss';

const Login: React.FC = () => {
  const { error } = useTypeSelector((state) => state.auth);
  const {
    user,
    isLogin,
    authType,
    isLoginCheck,
    currentLanguage,
    isTranslationsFetched,
    activeTranslation,
    signOut,
  } = useTypeSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const captchaRef = useRef(null);
  const history = useHistory();
  const [countryPhoneCode, setCountryPhoneCode] = useState<string | null>('+33');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [authSmsCode, setAuthSmsCode] = useState<string>('');
  const [isMenu, setIsMenu] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [authTypeState, setAuthTypeState] = useState<string | null>(authType);
  const [selected, isSelected] = useState<string>(currentLanguage);
  const { login } = useTranslations();
  const { windowWidth } = useMobileScreen();
  const mobileWidth = !!windowWidth && windowWidth <= 425;

  const options = [
    { value: 'en', label: login?.language.en },
    { value: 'fr', label: login?.language.fr },
    { value: 'pt', label: login?.language.pt },
  ];
  const defaultLang = options.find((lang) => currentLanguage === lang?.value);

  useEffect(() => {
    if (!user) {
      dispatch(checkIsLogin());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mixpanelActions.time_event('View App Login Page');
  }, []);

  const handleLinkToTheAppStore = () => {
    openInNewTab('https://webstaging.leja.co/');
  };

  useEffect(() => {
    if (!isTranslationsFetched || currentLanguage !== selected || !activeTranslation.dashboard) {
      dispatch(getTranslations(selected as keyof TranslationsType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTranslationsFetched, selected, currentLanguage, activeTranslation]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    isSelected(e.value);
  };

  const authByOTPHandler = () => {
    dispatch(setAuthByOTP(formatPhoneForE164(`${countryPhoneCode}${phoneNumber}`)));
  };

  const handlerPressEnter = () => {
    if (disabled) {
      authByOTPHandler();
    }
  };

  const handleSignOut = () => {
    dispatch(setSignOut(false));
  };

  const handleBack = () => {
    window.location.reload();
  };

  const handleError = () => {
    window.location.reload();
  };

  useEffect(() => {
    dispatch(checkIsLogin());
  }, [dispatch]);

  useEffect(() => {
    setAuthTypeState(authType);
  }, [authType]);

  useEffect(() => {
    // Recaptcha needed for firebase OTP login
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(captchaRef.current, {
      'size': 'invisible',
    });

    // eslint-disable-next-line
  }, [countryPhoneCode, phoneNumber]);

  useEffect(() => {
    if (isLogin && !isLoginCheck) {
      history.push('/home');
    }
  }, [isLogin, history, isLoginCheck]);

  useEffect(() => {
    setDisabled(isValidPhoneNumber(`${countryPhoneCode}${phoneNumber}`));
  }, [countryPhoneCode, phoneNumber]);

  if (!activeTranslation.dashboard) {
    return null;
  }

  if (authTypeState === 'PHONE') {
    return (
      <>
        <div id="recaptcha-container" ref={captchaRef} />
        <div className={classes.root}>
          <div className={classes.root_container}>
            <div className={classes.back}>
              <button type="button" onClick={handleBack}>
                <BackArrow /> <span>{login.go_back}</span>
              </button>
            </div>
            <div className={classes.overlay}>
              <LoginPhone
                countryPhoneCode={countryPhoneCode}
                phoneNumber={phoneNumber}
                authSmsCode={authSmsCode}
                setAuthSmsCode={setAuthSmsCode}
                translations={login}
              />
            </div>
            <div />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div id="recaptcha-container" ref={captchaRef} />
      {signOut
        && (
          <ModalSuccess
            onClick={handleSignOut}
            btnTitle={login.logged_out_modal.ok}
            title={login.logged_out_modal.title}
            text={login.logged_out_modal.text}
          />
        )}
      <div className={classes.root}>
        <div className={classes.root_container}>
          <div className={classes.root_top}>
            <h1 className={classes.root_top_title}>
              <LejaLogo />
            </h1>
            {!mobileWidth
              ? (
                <button
                  type="button"
                  className={classes.root_top_btn}
                  onClick={handleLinkToTheAppStore}
                >
                  {login?.learn_more}
                </button>
              )
              : (
                <button
                  className={classes.hamburger}
                  type="button"
                  onClick={() => setIsMenu(!isMenu)}
                >
                  <span className={classes.hamburger_lines} />
                  <span className={classes.hamburger_lines} />
                  <span className={classes.hamburger_lines} />
                </button>
              )}
          </div>
          <div className={classes.overlay}>
            {isMenu && (
              <Menu
                options={options}
                isSelected={isSelected}
                onClick={handleLinkToTheAppStore}
                defaultValue={defaultLang}
              />
            )}
            {!isMenu && (
              <div className={classes.login}>
                <div className={classes.login_phone}>
                  <h1>
                    {login?.sign_in}
                  </h1>
                  <PhoneInput
                    className={classes.login_phone_input}
                    code={countryPhoneCode}
                    translations={login}
                    setCode={setCountryPhoneCode}
                    phone={phoneNumber}
                    setPhone={setPhoneNumber}
                    handlerPressEnter={handlerPressEnter}
                  />
                  <button
                    id="sign-in-button"
                    disabled={!disabled}
                    className={classes.login_phone_btn}
                    type="button"
                    onClick={authByOTPHandler}
                  >
                    {login?.get_log_in_code}
                  </button>
                  <MessageError
                    handleError={handleError}
                    error={error}
                    translations={login?.messages_error}
                  />
                </div>
              </div>
            )}
          </div>
          {!mobileWidth
            && (
              <SelectLanguages
                options={options}
                handleChange={handleChange}
                defaultLang={defaultLang}
              />
            )}
        </div>
        <div />
      </div>
    </>
  );
};

export default Login;

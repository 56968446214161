/* eslint-disable no-console */
import { ApiOrgPublicId } from 'services/api';
import { AppThunk } from 'store';
import { StripeKeys } from 'store/invoice/invoiceTypes';
import {
  OrderActions,
  ORDER_SET_LOADING,
  ORDER_SET_DATA,
  ORDER_SET_PAYMENT,
  OrderState,
  IOrderData,
} from './orderTypes';

export const orderSetLoadingAction = (payload: boolean): OrderActions => ({
  type: ORDER_SET_LOADING,
  payload,
});

const orderSetAction = (payload: IOrderData): OrderActions => ({
  type: ORDER_SET_DATA,
  payload,
});

export const orderSetPaymentAction = (payload: OrderState['payment']): OrderActions => ({
  type: ORDER_SET_PAYMENT,
  payload,
});

export const getOrder = (shopId: string, orderId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(orderSetLoadingAction(true));
    const data = await ApiOrgPublicId<IOrderData>(`/p/sales-orders/${orderId}`, shopId);
    dispatch(orderSetAction(data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(orderSetLoadingAction(false));
  }
};

export const getClientSecret = async (
  shopId: string,
  orderId: string,
): Promise<StripeKeys | undefined> => {
  try {
    const data = await ApiOrgPublicId(`/p/sales-orders/${orderId}/accept-payment`, shopId);

    return data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const createOrderPayPal = async (
  shopId: string,
  orderId: string,
): Promise<unknown> => {
  try {
    const data = await ApiOrgPublicId(`/p/sales-orders/${orderId}/accept-payment?paymentMethod=PAYPAL`, shopId);

    return data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Input from 'react-phone-number-input/input';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Actions
import { setSendSms } from 'store/invoice/invoiceActions';
// Styles
import classes from './DocumentsGetApp.module.scss';

interface Props {
  type: string;
  name?: string;
  phone?: string;
  text: string;
}

const DocumentsGetApp: React.FC<Props> = ({
  type, name, phone, text,
}) => {
  const [inputValue, setInputValue] = useState(phone);
  const location = useLocation();
  const translations = useTranslationsStorefront();
  const ts = translations.documents.send_message;
  const sendSms = useCallback(() => {
    setSendSms({
      message: ts.message_text,
      number: inputValue,
      data: {
        documentId: location.pathname.split('/')[2],
        documentType: type.toLocaleLowerCase(),
      },
    });
  }, [inputValue, location.pathname, ts.message_text, type]);

  return (
    <div className={classes.container}>
      <div className={classes.container_title_wrapper}>
        <div className={classes.container_title}>{`${name},`}</div>
        <div className={classes.container_text}>{text}</div>
      </div>
      <div className={classes.container_phone_number_wrapper}>
        <div className={classes.container_phone_number}>
          <Input
            placeholder={phone}
            value={inputValue}
            onChange={(v: string) => setInputValue(v)}
          />
          <button type="button" onClick={sendSms}>
            {ts.get_app}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentsGetApp;

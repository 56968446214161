import { DataChartTypeEnum, ReportResponseAnnual } from 'store/dashboard/report/reportTypes';

interface Dataset {
  month: string;
  value: number
}

interface Data {
  interval: number;
  total1: number | null;
  total2: number | null;
}

interface StackedDataset {
  month: string;
  type: DataChartTypeEnum;
  value: number;
}

export const createDataset = (
  months: string[], data: ReportResponseAnnual[],
): Dataset[] => months.map((month, i) => {
  const exists = data.find((item) => item.interval === i + 1);
  return {
    month,
    value: exists?.total ?? 0,
  };
});

export const createStackedDataset = (data: Data[], months: string[]): StackedDataset[] => {
  const totalsOne = data.map(({ interval, total1 }) => ({
    month: months[interval - 1],
    type: DataChartTypeEnum.ONE,
    value: total1 ?? 0,
  }));

  const totalsTwo = data.map(({ interval, total2 }) => ({
    month: months[interval - 1],
    type: DataChartTypeEnum.TWO,
    value: total2 ?? 0,
  }));

  return months.flatMap((month) => {
    const itemOne = totalsOne.find((item) => item.month === month);
    const itemTwo = totalsTwo.find((item) => item.month === month);
    return [
      { month, type: DataChartTypeEnum.ONE, value: itemOne ? itemOne.value : 0 },
      { month, type: DataChartTypeEnum.TWO, value: itemTwo ? itemTwo.value : 0 },
    ];
  });
};

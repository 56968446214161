/* eslint-disable no-undef */
import React, { useMemo, useState } from 'react';
import 'firebase/remote-config';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useDashboardRoutes } from 'hooks/useDashboardRoutes';
// Utils
import { dateFormatA } from 'utils/dates';
import { makeValue } from 'utils/functions';
// Types
import { TransactionStatus } from 'types/globalTypes';
import { selectOrderDetails } from 'store/dashboard/orders/orderSelectors';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Styles
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import { DeliveryMethodsEnum } from 'constants/deliveryMethods';
import { capitalizeFirstLetter } from 'utils/strings';
import classes from './OverviewSection.module.scss';
import SendReminderModal from './SendReminderModal';

type DeliveryMethods = keyof TranslationDashboardModel['orders']['details']['delivery_methods']

const OverviewSection: React.FC = () => {
  const [modalReminderActive, setModalReminderActive] = useState(false);
  const translations = useTranslations();
  const orderDetails = useTypeSelector(selectOrderDetails);
  const ts = translations.orders.details.summary;
  const tsDetails = translations.orders.details;
  const { goToTransactionDetails } = useDashboardRoutes();
  const isUnpaid = orderDetails?.balance;
  const deliveryMethods = tsDetails.delivery_methods[
    orderDetails?.fulfillmentName as DeliveryMethods];

  const infoContent = useMemo(() => {
    if (orderDetails?.status === TransactionStatus.PROCESSED && !isUnpaid) {
      if (orderDetails?.fulfillmentName === DeliveryMethodsEnum.PICKUP) {
        return [
          makeValue(ts.source_text, orderDetails?.source),
          makeValue(ts.placed_on, dateFormatA(orderDetails?.created)),
          makeValue(ts.paid_on_text, dateFormatA(orderDetails?.paidOn)),
          makeValue(ts.paid_with_text, capitalizeFirstLetter(orderDetails?.paymentWith || '')),
          makeValue(ts.processed_text, dateFormatA(orderDetails?.updatedAt)),
          makeValue(ts.delivery_method_text, `${deliveryMethods} (${orderDetails.fulfillmentDetails?.address})`),
          makeValue(
            ts.order_invoice_text, orderDetails?.associations?.id ? 'view' : undefined,
          ),
        ];
      }

      if (orderDetails?.fulfillmentName === DeliveryMethodsEnum.DELIVERY) {
        return [
          makeValue(ts.source_text, orderDetails?.source),
          makeValue(ts.placed_on, dateFormatA(orderDetails?.created)),
          makeValue(ts.paid_on_text, dateFormatA(orderDetails?.paidOn)),
          makeValue(ts.paid_with_text, capitalizeFirstLetter(orderDetails?.paymentWith || '')),
          makeValue(ts.processed_text, dateFormatA(orderDetails?.updatedAt)),
          makeValue(ts.delivery_method_text, deliveryMethods),
          makeValue(ts.delivery_text, `${orderDetails.fulfillmentDetails?.shippingCourier} (${orderDetails.fulfillmentDetails?.shippingPhone})`),
          makeValue(
            ts.order_invoice_text, orderDetails?.associations?.id ? 'view' : undefined,
          ),
        ];
      }

      return [
        makeValue(ts.source_text, orderDetails?.source),
        makeValue(ts.placed_on, dateFormatA(orderDetails?.created)),
        makeValue(ts.paid_on_text, dateFormatA(orderDetails?.paidOn)),
        makeValue(ts.paid_with_text, capitalizeFirstLetter(orderDetails?.paymentWith || '')),
        makeValue(ts.processed_text, dateFormatA(orderDetails?.updatedAt)),
        makeValue(ts.delivery_method_text, `${deliveryMethods} (${orderDetails?.fulfillmentDetails?.providerName})`),
        makeValue(ts.tracking_text, orderDetails?.fulfillmentDetails?.trackingId),
        makeValue(
          ts.order_invoice_text, orderDetails?.associations?.id ? 'view' : undefined,
        ),
      ];
    }

    if (orderDetails?.status === TransactionStatus.UNPROCESSED && isUnpaid) {
      return [
        makeValue(ts.source_text, orderDetails?.source),
        makeValue(ts.placed_on, dateFormatA(orderDetails?.created)),
        makeValue(ts.paid_text, orderDetails?.balance ? ts.no : ts.yes),
        makeValue(ts.delivery_method_text, deliveryMethods),
      ];
    }

    if (orderDetails?.status === TransactionStatus.UNPROCESSED && !isUnpaid) {
      return [
        makeValue(ts.source_text, orderDetails?.source),
        makeValue(ts.placed_on, dateFormatA(orderDetails?.created)),
        makeValue(ts.paid_text, orderDetails?.balance ? ts.no : ts.yes),
        makeValue(ts.paid_on_text, dateFormatA(orderDetails?.paidOn)),
        makeValue(ts.paid_with_text, capitalizeFirstLetter(orderDetails?.paymentWith || '')),
        makeValue(ts.delivery_method_text, deliveryMethods),
      ];
    }

    return [
      makeValue(
        ts.source_text, orderDetails?.source,
      ),
      makeValue(
        ts.canceled, dateFormatA(orderDetails?.cancelledOn),
      ),
    ];
  }, [isUnpaid, orderDetails, ts, deliveryMethods]);

  const handleOpenInvoice = () => {
    goToTransactionDetails(`${orderDetails?.associations?.id}`, 'Invoice');
  };

  const openModalReminder = () => {
    setModalReminderActive((prev) => !prev);
  };

  const rightColumn = (value?: string, name?: string) => {
    if (value === 'view') {
      return (
        <button type="button" className={classes.view_btn} onClick={handleOpenInvoice}>
          {ts.view}
        </button>
      );
    }

    if (name === ts.delivery_method_text
      && orderDetails?.fulfillmentName === DeliveryMethodsEnum.SHIPPING
      && orderDetails?.status === TransactionStatus.PROCESSED && !isUnpaid
    ) {
      return (
        <div className={classes.value}>
          {deliveryMethods}{' '}
          <span>({orderDetails?.fulfillmentDetails?.providerName})</span>
        </div>
      );
    }

    if (name === ts.delivery_method_text
      && orderDetails?.fulfillmentName === DeliveryMethodsEnum.DELIVERY
      && orderDetails?.status === TransactionStatus.PROCESSED && !isUnpaid
    ) {
      return (
        <div className={classes.value}>
          <span>{deliveryMethods}</span>
        </div>
      );
    }

    if (name === ts.delivery_method_text
      && orderDetails?.fulfillmentName === DeliveryMethodsEnum.PICKUP
      && orderDetails?.status === TransactionStatus.PROCESSED && !isUnpaid
    ) {
      return (
        <div className={classes.value}>
          {deliveryMethods}{' '}
          <span>({orderDetails.fulfillmentDetails?.address})</span>
        </div>
      );
    }

    return (
      <div className={classes.value}>
        {value}
        {
          name === ts.paid_text && value === ts.no && (
            <button className={classes.send_reminder_btn} type="button" onClick={openModalReminder}>
              {ts.send_reminder}
            </button>
          )
        }
      </div>
    );
  };

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.overview_activity}>
          {infoContent.map(({ name, value }, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={key}>
              <p>{name}</p>
              {rightColumn(value, name)}
            </div>
          ))}
        </div>
      </div>
      <SendReminderModal
        setActive={setModalReminderActive}
        active={modalReminderActive}
      />
    </>
  );
};
export default OverviewSection;

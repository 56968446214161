import React, { useEffect, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
// Constants
import { PayMethodsEnum } from 'constants/paymentsMethods';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
// Components
import AfterpayClearpay from './AfterpayClearpay';
import Klarna from './Klarna';
import PayLaterInvoice from './PayLaterInvoice';

interface Props {
  type: PayMethodsEnum | null;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setData: (data: FieldValues) => void;
}

const PaymentLaterForm: React.FC<Props> = ({ type, setDisabled, setData }) => {
  const { data: invoice } = useTypeSelector(
    (state) => state.invoice,
  );

  const {
    register, getValues, trigger, reset,
    formState: { isValid },
  } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({
      email: invoice?.contact?.email,
      fullName: invoice?.contact?.fullName,
      line1: invoice?.contact?.address?.line1,
      line2: invoice?.contact?.address?.line2,
      city: invoice?.contact?.address?.city,
      postalCode: invoice?.contact?.address?.postalCode,
      shipping: {
        fullName: invoice?.contact?.fullName,
        line1: invoice?.contact?.address?.line1,
        line2: invoice?.contact?.address?.line2,
        city: invoice?.contact?.address?.city,
        postalCode: invoice?.contact?.address?.postalCode,
      },
    }), [invoice]),
  });

  useEffect(() => {
    reset({
      fullName: invoice?.contact?.fullName,
      email: invoice?.contact?.email,
      city: invoice?.contact?.address?.city,
      line1: invoice?.contact?.address?.line1,
      line2: invoice?.contact?.address?.line2,
      postalCode: invoice?.contact?.address?.postalCode,
      shipping: {
        fullName: invoice?.contact?.fullName,
        line1: invoice?.contact?.address?.line1,
        line2: invoice?.contact?.address?.line2,
        city: invoice?.contact?.address?.city,
        postalCode: invoice?.contact?.address?.postalCode,
      },
    });
    trigger();
    setData(getValues());
  }, [invoice, getValues, reset, setData, trigger]);

  useEffect(() => {
    setDisabled(isValid);
    setData(getValues());
  }, [getValues, isValid, setData, setDisabled]);

  if (type === PayMethodsEnum.KLARNA) {
    return (
      <Klarna register={register} />
    );
  }

  if (type === PayMethodsEnum.AFTERPAY_CLEARPAY) {
    return (
      <AfterpayClearpay register={register} />
    );
  }

  if (type === PayMethodsEnum.PAY_LATER) {
    return (
      <PayLaterInvoice register={register} />
    );
  }

  return null;
};

export default PaymentLaterForm;

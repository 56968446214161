import classNames from 'classnames';
import React from 'react';
// Assets
import { ReactComponent as FooterLogo } from 'assets/icons/logo-leja.svg';
// Utils
import { getYear } from 'utils/dates';
// Styles
import classes from './FooterSideBar.module.scss';

interface Props {
  className?: string;
}

const FooterSideBar: React.FC<Props> = ({ className }) => {
  const FooterClasses = classNames(className, className, classes.bottom);
  return (
    <div className={FooterClasses}>
      <div>
        <FooterLogo />
      </div>
      <div>
        © {getYear()}
      </div>
    </div>
  );
};

export default FooterSideBar;

import React, { useState } from 'react';
import { ReactComponent as SortIcon } from 'assets/icons/sort-arrow.svg';
// Styles
import classes from './Header.module.scss';

interface Props {
  header: string;
  sortItems: (header: string, sortConfig: string) => void
  isIconShow: boolean;
  disabled?: boolean;
  type?: 'two'
}

const Header: React.FC<Props> = ({
  header, sortItems, isIconShow, disabled, type,
}) => {
  const [sortField, setSortField] = useState('');
  const [sortConfig, setSortConfig] = useState('desc');

  const handleSortingChange = () => {
    const sortOrder = header === sortField && sortConfig === 'desc' ? 'asc' : 'desc';
    setSortField(header);
    setSortConfig(sortOrder);
    sortItems(header, sortOrder);
  };

  if (type) {
    return (
      <button
        type="button"
        key={header}
        className={classes.table_header_two}
        onClick={handleSortingChange}
        disabled={disabled}
      >
        <p className={classes.table_header_two_name}>{header}</p>
        <SortIcon style={{
          opacity: isIconShow ? 1 : 0,
          transform: sortConfig === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
        />
      </button>
    );
  }

  return (
    <button
      type="button"
      key={header}
      className={classes.table_header}
      onClick={handleSortingChange}
      disabled={disabled}
    >
      <p className={classes.table_header_name}>{header}</p>
      <SortIcon style={{
        opacity: isIconShow ? 1 : 0,
        transform: sortConfig === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
      />
    </button>
  );
};

export default Header;

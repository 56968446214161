/* eslint-disable @typescript-eslint/no-explicit-any */
export const useGroupById = (arr: any[]): any => {
  const temp = arr.reduce((acc: any[], elem: any) => {
    const status = elem.status.toLowerCase();
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(elem);
    return acc;
  }, {});
  return temp;
};

import { SalesChannel } from 'store/storefront/shop/shopTypes';
import {
  ContactType,
  DetailsType,
  PriceType,
  TermsPaymentType,
  Items,
  Payments,
  IOrganisationData,
} from 'types/globalTypes';

// Action Types
export const INVOICE_LOADING = 'INVOICE_LOADING';
export const INVOICE_GET_DATA = 'INVOICE_GET_DATA';
export const INVOICE_SET_CLIENT_SECRET = 'INVOICE_SET_CLIENT_SECRET';
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
export const SET_PAYMENT_SCHEDULE = 'SET_PAYMENT_SCHEDULE';
export const INVOICE_PAYMENT_LOADING = 'INVOICE_PAYMENT_LOADING';
export const INVOICE_PAYMENT_ERROR = 'INVOICE_PAYMENT_ERROR';
export const INVOICE_PAYMENT_SUCCESS = 'INVOICE_PAYMENT_SUCCESS';

// Reducer type
export interface InvoiceState {
  loading: boolean;
  loadingPay: boolean;
  stripe?: StripeKeys;
  paymentSchedule?: boolean;
  status?: StatusPayment;
  data: IData | null;
  payment: {
    loading: string | null;
    error: string | null;
    success: boolean;
  }
}

export enum InvoicePaymentLoadingEnum {
  PAY = 'PAY',
  PAYPAL = 'PAYPAL',
  END = ''
}

export interface IData {
  organisation: IOrganisationData
  contact: ContactType & {fullName: string}
  details: DetailsType
  location: SalesChannel | null
  date: Date;
  dueDate: string;
  items: Items[],
  price: PriceType
  payments: Payments[] | []
  termsPayment: TermsPaymentType
  paidInfo?: unknown
  updatedAt?: Date
}

export enum StatusPayment {
  resolve = 'resolved',
  reject = 'rejected',
  pending = 'pending',
  start = 'start',
}

export interface StripeKeys {
  client_secret?: string;
  key: string;
  stripeAccount: string;
}

interface InvoiceLoadingAction {
  type: typeof INVOICE_LOADING
  payload: boolean
}

interface setPaymentScheduleStatus {
  type: typeof SET_PAYMENT_SCHEDULE
  payload: boolean
}

interface GetInvoiceDataAction {
  type: typeof INVOICE_GET_DATA
  payload: InvoiceState['data']
}

interface SetClientSecret {
  type: typeof INVOICE_SET_CLIENT_SECRET
  payload: StripeKeys
}

interface SetPaymentStatus {
  type: typeof SET_PAYMENT_STATUS
  payload: { loading: boolean, status: StatusPayment }
}

interface InvoicePaymentLoadingAction {
  type: typeof INVOICE_PAYMENT_LOADING
  payload: string
}

interface InvoicePaymentErrorAction {
  type: typeof INVOICE_PAYMENT_ERROR
  payload: string
}

interface InvoicePaymentSuccessAction {
  type: typeof INVOICE_PAYMENT_SUCCESS
  payload: boolean
}

export type InvoiceActions =
  InvoiceLoadingAction
  | GetInvoiceDataAction
  | SetClientSecret
  | SetPaymentStatus
  | setPaymentScheduleStatus
  | InvoicePaymentLoadingAction
  | InvoicePaymentErrorAction
  | InvoicePaymentSuccessAction;

import React, { useCallback, useState } from 'react';
// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
// Constants
import { PayMethodsEnum } from 'constants/paymentsMethods';
// Types
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
import { IPaymentMethods } from 'utils/storefront/PaymentFunctions';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Assets
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// Components
import FooterPaymentModal from 'components/StorefrontComponents/Checkout/FooterPaymentModal';
import Modal from 'components/Modal';
import { InvoicePaymentLoadingEnum } from 'store/invoice/invoiceTypes';
import BankPaymentStripe from './BankPaymentStripe/BankPaymentStripe';
// Classes
import classes from './BankPaymentOptionModal.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
  handleError: (error: unknown) => void;
  handleSuccess: () => void;
  getStripe: () => void;
  handleLoading: (loading: InvoicePaymentLoadingEnum) => void;
  stripePromise: Stripe | null;
  banks: IPaymentMethods[]
  allPayMethods: PayMethodsEnum[]
  total: number;
}

type TPaymentsMethod = keyof TranslationStorefrontModel['checkout']['payment_page']['payment_methods'];

const BankPaymentOptionModal: React.FC<Props> = ({
  active,
  setActive,
  banks,
  total,
  handleError,
  handleSuccess,
  handleLoading,
  getStripe,
  stripePromise,
}) => {
  const { data, stripe } = useTypeSelector(
    (state) => state.invoice,
  );
  const [activeBank, setActiveBank] = useState<PayMethodsEnum | null>(null);

  const translations = useTranslationsStorefront();
  const paymentModalTs = translations.checkout.payment_modal;
  const paymentsMethodsTs = translations.checkout.payment_page.payment_methods;

  const currencyIso = data?.location?.address.currencyISO || 'EUR';
  const formatCurrencyByISO = CurrencyFormatByISO(currencyIso);

  const handleActiveBank = useCallback(
    async (type: PayMethodsEnum) => {
      setActiveBank(type);

      if (data?.location && !stripe) {
        getStripe();
      }
    },
    [data?.location, getStripe, stripe],
  );

  return (
    <>
      <Modal
        active={active}
        setActive={setActive}
        className={classes.payment_modal}
      >
        <div className={classes.root}>
          <div className={classes.root_top}>
            <div className={classes.header}>
              <CloseIcon
                role="button"
                className={classes.close_icon}
                onClick={setActive}
              />
              <LeftArrowIcon
                role="button"
                className={classes.left_arrow_icon}
                onClick={setActive}
              />
            </div>
            <div className={classes.title}>
              {paymentModalTs.pay_text} {formatCurrencyByISO(total)}
            </div>
            <p className={classes.subtitle}>
              {translations.checkout.payment_page.description}
            </p>
            <div className={classes.bank_list}>
              {banks.map((bank) => (
                <button
                  key={bank.name}
                  type="button"
                  className={activeBank === bank.name ? classes.bank_active : classes.bank}
                  onClick={() => handleActiveBank(bank.name)}
                >
                  <div className={classes.logo}>
                    {bank.logos.map((logo) => <img src={logo} alt={logo} key={logo} />)}
                  </div>
                  <p>
                    {paymentsMethodsTs[bank.name as TPaymentsMethod].name}
                  </p>
                </button>
              ))}
            </div>
          </div>
          {stripePromise && stripe?.client_secret && data?.location ? (
            <Elements stripe={stripePromise}>
              <BankPaymentStripe
                stripeKeys={stripe}
                handleError={handleError}
                handleSuccess={handleSuccess}
                location={data.location}
                fullName={data.contact.fullName}
                currencyIso={currencyIso}
                email={data.contact.email}
                activeBank={activeBank}
                handleLoading={handleLoading}
              />
            </Elements>
          ) : <FooterPaymentModal disabled />}
        </div>
      </Modal>
    </>
  );
};

export default BankPaymentOptionModal;

import { useStripe } from '@stripe/react-stripe-js';
import { PayMethodsEnum } from 'constants/paymentsMethods';
import { useTypeSelector } from 'hooks/useTypeSelector';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { checkoutSetOrder, onError, onSuccess } from 'store/storefront/checkout/checkoutActions';
import { LoadingEnum } from 'store/storefront/checkout/checkoutTypes';
import { afterpayClearpay } from 'utils/storefront/PaymentMethods/AfterpayClearpay';
import { klarna } from 'utils/storefront/PaymentMethods/Klarna';

interface Props {
  type: PayMethodsEnum | null;
  values: FieldValues | null | undefined;
  setPay: React.Dispatch<React.SetStateAction<boolean>>;
  clientSecret: string;
}

const StripeConfirm: React.FC<Props> = ({
  type, values, setPay, clientSecret,
}) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { fulfillment, order } = useTypeSelector(({ storefront }) => storefront.checkout);

  const returnUrl = useMemo(() => (window.location.href.includes('?')
    ? `${window.location.href}&acc=${order.stripe?.stripeAccount}&stripe=${order.stripe?.key}`
    : `${window.location.href}?acc=${order.stripe?.stripeAccount}&stripe=${order.stripe?.key}`
  ), [order.stripe?.key, order.stripe?.stripeAccount]);

  const handlePay = useCallback(async () => {
    if (!stripe || !order.stripe) {
      return;
    }

    setPay(false);
    dispatch(checkoutSetOrder({
      ...order,
      loading: LoadingEnum.SET_ORDER,
    }));

    if (type === PayMethodsEnum.AFTERPAY_CLEARPAY) {
      const error = await afterpayClearpay(
        clientSecret,
        stripe,
        {
          email: values?.email,
          fullname: values?.fullName,
          address: values?.address1,
          address2: values?.address2,
          city: values?.city,
          state: values?.state,
          country: fulfillment.country.iso,
          postalCode: values?.postalCode,
          shipping: {
            fullname: values?.fullName,
            address: values?.address1,
            address2: values?.address2,
            city: values?.city,
            state: values?.state,
            postalCode: values?.postalCode,
          },
        },
        returnUrl,
      );

      if (error) {
        dispatch(onError(error, order));
      } else {
        dispatch(onSuccess(order));
      }
    }

    if (type === PayMethodsEnum.KLARNA) {
      const error = await klarna(
        clientSecret,
        stripe,
        {
          email: values?.email,
          country: fulfillment.country.iso,
        },
        returnUrl,
      );

      if (error) {
        dispatch(onError(error, order));
      } else {
        dispatch(onSuccess(order));
      }
    }
  }, [clientSecret,
    dispatch,
    returnUrl,
    fulfillment.country.iso,
    order,
    setPay,
    stripe,
    type,
    values]);

  useEffect(() => {
    if (stripe && clientSecret) {
      handlePay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, clientSecret]);

  return null;
};

export default StripeConfirm;

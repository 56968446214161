import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
import AccountLayout from 'layouts/StorefrontLayout/AccountLayout';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from 'services/firebase';
import { accountSetErrorAction } from 'store/storefront/account/accountActions';
import { partialEmail } from 'utils/strings';
// Classes
import classes from './AccountSecurity.module.scss';
import LoginModal from './LoginModal';
import UpdateEmailModal from './UpdateEmailModal';
import UpdatePasswordModal from './UpdatePasswordModal';

const AccountSecurity: React.FC = () => {
  const { error } = useTypeSelector(({ storefront }) => storefront.account);
  const [updateEmailModal, setUpdateEmailModal] = useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const translations = useTranslationsStorefront();
  const dispatch = useDispatch();

  const handleLoginModal = useCallback(() => {
    setLoginModal((prev) => !prev);
    if (error === 'auth/requires-recent-login') {
      dispatch(accountSetErrorAction(null));
    }
  }, [dispatch, error]);

  const handleUpdateEmailModal = useCallback(() => {
    setUpdateEmailModal((prev) => !prev);
  }, []);

  const handleUpdatePasswordModal = useCallback(() => {
    setUpdatePasswordModal((prev) => !prev);
  }, []);

  useEffect(() => {
    if (error === 'auth/requires-recent-login') {
      setLoginModal(true);
    }
  }, [error]);

  return (
    <AccountLayout>
      <div className={classes.security}>
        <div className={classes.login}>
          <div className={classes.login_description}>
            {auth?.currentUser?.email && (
            <p>
              {partialEmail(auth.currentUser.email)}
            </p>
            )}
            <p>
              {translations.account.security.login.title}
            </p>
          </div>
          <button type="button" onClick={handleUpdateEmailModal}>
            {translations.account.address.edit_btn}
          </button>
        </div>
        <div className={classes.password}>
          <div className={classes.password_description}>
            <p>
              {translations.account.security.password.value}
            </p>
            <p>
              {translations.account.security.password.title}
            </p>
          </div>
          <button type="button" onClick={handleUpdatePasswordModal}>
            {translations.account.address.edit_btn}
          </button>
        </div>
      </div>
      <UpdateEmailModal active={updateEmailModal} setActive={handleUpdateEmailModal} />
      <UpdatePasswordModal active={updatePasswordModal} setActive={handleUpdatePasswordModal} />
      <LoginModal active={loginModal} setActive={handleLoginModal} />
    </AccountLayout>
  );
};

export default AccountSecurity;

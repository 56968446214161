import React from 'react';
// Hooks
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Utils
import { dateFormatA } from 'utils/dates';
// Types
import { TransactionStatus } from 'types/globalTypes';
import { EstimateModal } from 'store/estimate/estimateTypes';
// Styles
import classes from './PaymentTerms.module.scss';

interface Props {
  status: TransactionStatus;
  handleType: (type: EstimateModal) => void;
  updateDate?: string;
}

const PaymentTerms: React.FC<Props> = ({ status, handleType, updateDate }) => {
  const translations = useTranslationsStorefront();
  const ts = translations.documents.estimate.payment_terms;

  if (status === TransactionStatus.PENDING) {
    return (
      <div className={classes.payment_terms}>
        <div className={classes.payment_terms_left}>
          <p className={classes.payment_terms_left_title}>{ts.title}</p>
          <p className={classes.payment_terms_left_text}>{ts.description}</p>
        </div>
        <div className={classes.payment_terms_right_btns}>
          <button
            type="button"
            className={classes.payment_terms_right_btns_cancel_btn}
            onClick={() => handleType(EstimateModal.decline)}
          >
            {ts.btn_decline}
          </button>
          <button
            type="button"
            className={classes.payment_terms_right_btns_accept_btn}
            onClick={() => handleType(EstimateModal.accept)}
          >
            {ts.btn_accept}
          </button>
        </div>
      </div>
    );
  }

  if (status === TransactionStatus.ACCEPTED) {
    return (
      <div className={classes.payment_terms}>
        <div className={classes.payment_terms_left}>
          <p className={classes.payment_terms_left_title}>{ts.title}</p>
          <p className={classes.payment_terms_left_text}>{ts.description}</p>
        </div>
        <div className={classes.payment_terms_right}>
          <p className={classes.payment_terms_right_title}>
            {ts.estimate_accepted}
          </p>
          <p className={classes.payment_terms_right_text}>
            {dateFormatA(updateDate)}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.payment_terms}>
      <div className={classes.payment_terms_left}>
        <p className={classes.payment_terms_left_title}>{ts.title}</p>
        <p className={classes.payment_terms_left_text}>{ts.description}</p>
      </div>
      <div className={classes.payment_terms_right}>
        <p className={classes.payment_terms_right_title}>
          {ts.estimate_declined}
        </p>
        <p className={classes.payment_terms_right_text}>
          {dateFormatA(updateDate)}
        </p>
      </div>
    </div>
  );
};

export default PaymentTerms;

/* eslint-disable no-console */
import { AgingBillsData } from 'pages/Dashboard/Home/HomeTabs/UnpaidBillsTab/UnpaidBillsTab';
import { AgingInvoiceData } from 'pages/Dashboard/Home/HomeTabs/UnpaidInvoiceTab/UnpaidInvoiceTab';
import { ExpensesChartData } from 'pages/Dashboard/Home/HomeTabs/ExpensesTab/ExpensesTab';
import { SalesChartData } from 'pages/Dashboard/Home/HomeTabs/SalesTab/SalesTab';
import { AppThunk } from 'store';
import { ApiOrgId } from 'services/api';
import { auth } from 'services/firebase';
import { SalesTaxesData } from 'pages/Dashboard/Home/HomeTabs/SalesTaxesTab/SalesTaxesTab';
import { IncomeStatementData } from 'pages/Dashboard/Home/HomeTabs/IncomeStatementTab/IncomeStatementTab';
import { getDate, getYear } from 'utils/dates';
import { ReactSelectTime } from 'types/globalTypes';
import { createDataset, createStackedDataset } from 'utils/dashboard/reports';
import {
  ReportActions,
  ReportSelectCards,
  REPORT_SET_AGING_BILLS_CHART,
  REPORT_SET_AGING_INVOICE_CHART,
  REPORT_SET_CURRENT_TIME,
  REPORT_SET_EXPENSES_CHART,
  REPORT_SET_INCOME_STATEMENT,
  REPORT_SET_REPORT_SELECT_CARDS,
  REPORT_SET_SALES_CHART,
  REPORT_SET_SALES_TAXES_CHART,
  REPORT_CHANGE_AVERAGE,
  REPORT_SET_ORDERS_CHART,
  DataChart,
  REPORT_SET_ESTIMATES_CHART,
  DataChartStacked,
  REPORT_SET_QUOTES_CHART,
  REPORT_SET_CLIENTS_CHART,
  REPORT_SET_VENDORS_CHART,
  REPORT_SET_LOADING_SELECT,
  REPORT_SET_LOADING,
  DataChartTypeEnum,
  ReportResponseAnnual,
} from './reportTypes';

export const setReportSelectCardsAction = (payload: ReportSelectCards): ReportActions => ({
  type: REPORT_SET_REPORT_SELECT_CARDS,
  payload,
});

export const setCurrentTimeAction = (payload: ReactSelectTime): ReportActions => ({
  type: REPORT_SET_CURRENT_TIME,
  payload,
});

export const setExpensesChartAction = (payload: ExpensesChartData[]): ReportActions => ({
  type: REPORT_SET_EXPENSES_CHART,
  payload,
});

export const setSalesChartAction = (payload: SalesChartData[]): ReportActions => ({
  type: REPORT_SET_SALES_CHART,
  payload,
});

export const setAgingInvoiceChartAction = (payload: AgingInvoiceData[]): ReportActions => ({
  type: REPORT_SET_AGING_INVOICE_CHART,
  payload,
});

export const setAgingBillsChartAction = (payload: AgingBillsData[]): ReportActions => ({
  type: REPORT_SET_AGING_BILLS_CHART,
  payload,
});

export const setOrdersChartAction = (payload: DataChart[]): ReportActions => ({
  type: REPORT_SET_ORDERS_CHART,
  payload,
});

export const setEstimatesChartAction = (payload: DataChartStacked[]): ReportActions => ({
  type: REPORT_SET_ESTIMATES_CHART,
  payload,
});

export const setQuotesChartAction = (payload: DataChartStacked[]): ReportActions => ({
  type: REPORT_SET_QUOTES_CHART,
  payload,
});

export const setSalesTaxesChartAction = (payload: SalesTaxesData[]): ReportActions => ({
  type: REPORT_SET_SALES_TAXES_CHART,
  payload,
});

export const setClientsChartAction = (payload: DataChart[]): ReportActions => ({
  type: REPORT_SET_CLIENTS_CHART,
  payload,
});

export const setVendorsChartAction = (payload: DataChart[]): ReportActions => ({
  type: REPORT_SET_VENDORS_CHART,
  payload,
});

export const setIncomeStatementAction = (payload: IncomeStatementData): ReportActions => ({
  type: REPORT_SET_INCOME_STATEMENT,
  payload,
});

export const setLoadingSelectAction = (payload: boolean): ReportActions => ({
  type: REPORT_SET_LOADING_SELECT,
  payload,
});

export const setLoadingDataAction = (payload: boolean): ReportActions => ({
  type: REPORT_SET_LOADING,
  payload,
});

export const setCurrentTime = (period: ReactSelectTime): AppThunk => async (dispatch) => {
  dispatch(setCurrentTimeAction(period));
};

export const changeAverageMonth = (payload: {
  selectedMonth: Record<string, number | string | boolean> | null,
  datasets: DataChart[] | DataChartStacked[] | null;
}): ReportActions => ({
  type: REPORT_CHANGE_AVERAGE,
  payload,
});

export const getReportSelectCards = (
  organisationId: string,
  period: ReactSelectTime,
  section: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoadingSelectAction(true));
    const date = getDate(period);
    if (auth.currentUser) {
      const data = await ApiOrgId(
        `/dashboard/${section}?startDate=${date?.startDate}&endDate=${date?.endDate}`,
        organisationId,
      );

      dispatch(setLoadingSelectAction(false));
      dispatch(setReportSelectCardsAction(data));
    }
  } catch (error) {
    dispatch(setLoadingSelectAction(false));
    console.log(error);
  }
};

export const getSalesData = (
  organisationId: string,
  months: string[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoadingDataAction(true));
    if (auth.currentUser) {
      const data: { interval: number; total: number }[] = await ApiOrgId(
        `/dashboard/sales/annual?year=${getYear()}`,
        organisationId,
      );

      const dt = createDataset(months, data);
      dispatch(setSalesChartAction(dt));
      dispatch(setLoadingDataAction(false));
    }
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

export const getExpensesData = (
  organisationId: string,
  months: string[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoadingDataAction(true));
    if (auth.currentUser) {
      const data: ReportResponseAnnual[] = await ApiOrgId<ReportResponseAnnual[]>(
        `/dashboard/expenses/annual?year=${getYear()}`,
        organisationId,
      );

      const dt = createDataset(months, data);
      dispatch(setExpensesChartAction(dt));
      dispatch(setLoadingDataAction(false));
    }
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

export const getAgingInvoiceData = (
  organisationId: string,
  months: string[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoadingDataAction(true));
    const data: {
      interval: number;
      total1: number | null;
      total2: number | null;
    }[] = await ApiOrgId(
      `/dashboard/invoices-aging/annual?year=${getYear()}`,
      organisationId,
    );

    const resultData = createStackedDataset(data, months);
    dispatch(setAgingInvoiceChartAction(resultData));
    dispatch(setLoadingDataAction(false));
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

export const getAgingBillsData = (
  organisationId: string,
  months: string[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoadingDataAction(true));
    if (auth.currentUser) {
      const data: {
        interval: number;
        total1: number | null;
        total2: number | null
      }[] = await ApiOrgId(
        `/dashboard/bills-aging/annual?year=${getYear()}`,
        organisationId,
      );
      const resultData = createStackedDataset(data, months);
      dispatch(setAgingBillsChartAction(resultData));
      dispatch(setLoadingDataAction(false));
    }
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

export const getSalesTaxesData = (
  organisationId: string,
  months: string[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoadingDataAction(true));

    if (auth.currentUser) {
      const data: ReportResponseAnnual[] = await ApiOrgId(
        `/dashboard/taxes/annual?year=${getYear()}`,
        organisationId,
      );

      const dt = createDataset(months, data);
      dispatch(setSalesTaxesChartAction(dt));
      dispatch(setLoadingDataAction(false));
    }
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

export const getIncomeStatementData = (organisationId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoadingDataAction(true));
    if (auth.currentUser) {
      const data = await ApiOrgId(
        `/reports/statement/overview?range=YEARLY&year=${getYear()}`,
        organisationId,
      );
      dispatch(setIncomeStatementAction(data));
      dispatch(setLoadingDataAction(false));
    }
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

export const getOrdersData = (
  organisationId: string,
  months: string[],
): AppThunk => async (dispatch) => {
  dispatch(setLoadingDataAction(true));
  try {
    if (auth.currentUser) {
      const data: ReportResponseAnnual[] = await ApiOrgId(
        `/dashboard/orders/annual?year=${getYear()}`,
        organisationId,
      );

      const dt = createDataset(months, data);
      dispatch(setOrdersChartAction(dt));
      dispatch(setLoadingDataAction(false));
    }
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

export const getEstimatesData = (
  organisationId: string,
  months: string[],
): AppThunk => async (dispatch) => {
  dispatch(setLoadingDataAction(true));
  try {
    if (auth.currentUser) {
      const data: {
        interval: number;
        accepted: number | null;
        sent: number | null
      }[] = await ApiOrgId(
        `/dashboard/sales-orders/annual?year=${getYear()}`,
        organisationId,
      );
      const totalsOne = data.map(({ interval, accepted }) => ({
        month: months[interval - 1],
        type: 1,
        value: accepted || 0,
      }));
      const totalsTwo = data.map(({ interval, sent }) => ({
        month: months[interval - 1],
        type: 2,
        value: sent || 0,
      }));

      const resultData = months.flatMap((month) => {
        const itemOne = totalsOne.find((item) => item.month === month);
        const itemTwo = totalsTwo.find((item) => item.month === month);
        return [
          { month, type: DataChartTypeEnum.ONE, value: itemOne ? itemOne.value : 0 },
          { month, type: DataChartTypeEnum.TWO, value: itemTwo ? itemTwo.value : 0 },
        ];
      });
      dispatch(setEstimatesChartAction(resultData));
      dispatch(setLoadingDataAction(false));
    }
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

export const getQuotesData = (
  organisationId: string,
  months: string[],
): AppThunk => async (dispatch) => {
  dispatch(setLoadingDataAction(true));
  try {
    if (auth.currentUser) {
      const data: {
        interval: number;
        responded: number | null;
        sent: number | null
      }[] = await ApiOrgId(
        `/dashboard/purchase-orders/annual?year=${getYear()}`,
        organisationId,
      );

      const totalsOne = data.map(({ interval, responded }) => ({
        month: months[interval - 1],
        type: 1,
        value: responded || 0,
      }));

      const totalsTwo = data.map(({ interval, sent }) => ({
        month: months[interval - 1],
        type: 2,
        value: sent || 0,
      }));

      const resultData = months.flatMap((month) => {
        const itemOne = totalsOne.find((item) => item.month === month);
        const itemTwo = totalsTwo.find((item) => item.month === month);
        return [
          { month, type: DataChartTypeEnum.ONE, value: itemOne ? itemOne.value : 0 },
          { month, type: DataChartTypeEnum.TWO, value: itemTwo ? itemTwo.value : 0 },
        ];
      });
      dispatch(setQuotesChartAction(resultData));
      dispatch(setLoadingDataAction(false));
    }
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

export const getClientsData = (
  organisationId: string,
  months: string[],
): AppThunk => async (dispatch) => {
  dispatch(setLoadingDataAction(true));
  try {
    if (auth.currentUser) {
      const data: ReportResponseAnnual[] = await ApiOrgId(
        `/dashboard/customers/annual?year=${getYear()}`,
        organisationId,
      );

      const dt = createDataset(months, data);
      dispatch(setClientsChartAction(dt));
      dispatch(setLoadingDataAction(false));
    }
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

export const getVendorsData = (
  organisationId: string,
  months: string[],
): AppThunk => async (dispatch) => {
  dispatch(setLoadingDataAction(true));
  try {
    if (auth.currentUser) {
      const data: ReportResponseAnnual[] = await ApiOrgId(
        `/dashboard/suppliers/annual?year=${getYear()}`,
        organisationId,
      );

      const dt = createDataset(months, data);
      dispatch(setVendorsChartAction(dt));
      dispatch(setLoadingDataAction(false));
    }
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(setLoadingDataAction(false));
  }
};

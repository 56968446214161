import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUser } from 'hooks/useUser';
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Actions
import { cancelOrderAction } from 'store/dashboard/orders/orderActions';
// Selectors
import { selectOrderDetails } from 'store/dashboard/orders/orderSelectors';
// Utils
import { CurrencyFormatByISO } from 'utils/price';
// Types
import { TransactionStatus } from 'types/globalTypes';
// Components
import ConfirmModal from 'components/Modal/ConfirmModal';
import ProcessModal from './ProcessModal';
// Styles
import classes from './OrderDetailsFooter.module.scss';

const OrderDetailsFooter: FC = () => {
  const [modalProcessActive, setModalProcessActive] = useState(false);
  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const { isMobile } = useMobileScreen();
  const orderDetails = useTypeSelector(selectOrderDetails);
  const params: { orderId?: string } = useParams();
  const user = useUser();
  const { windowWidth } = useMobileScreen();
  const dispatch = useDispatch();
  const translations = useTranslations();
  const formatCurrencyByISO = CurrencyFormatByISO(orderDetails?.price.currencyIso);
  const isUnpaid = !!orderDetails?.balance;
  const tsFooter = translations.orders.details.footer;
  const tsCancelModal = translations.orders.details.cancel_modal;

  const openModalProcess = () => {
    setModalProcessActive((prev) => !prev);
  };

  const openCancelOrderModal = () => {
    setCancelOrderModal((prev) => !prev);
  };

  const cancelOrder = useCallback(() => {
    if (user?.currentOrganisation?.id && params.orderId && orderDetails) {
      setCancelOrderModal((prev) => !prev);
      dispatch(cancelOrderAction(params.orderId,
        user?.currentOrganisation?.id,
        TransactionStatus.CANCELLED,
        orderDetails));
    }
  }, [dispatch, orderDetails, params.orderId, user?.currentOrganisation?.id]);

  if (!orderDetails) {
    return null;
  }

  if (orderDetails.status === TransactionStatus.UNPROCESSED) {
    return (
      <>
        {windowWidth && windowWidth <= 850
          ? (
            <div className={classes.footer}>
              <div className={classes.footer_left}>
                <p className={classes.footer_left_label}>
                  {isUnpaid ? tsFooter.order_payment_pending : tsFooter.total_order_value}
                </p>
                <p className={classes.footer_left_price}>
                  {formatCurrencyByISO(orderDetails.price.total)}
                </p>
              </div>
              <div className={classes.footer_right}>
                <button type="button" onClick={openModalProcess} className={classes.footer_process_order_btn} disabled={isUnpaid}>
                  {tsFooter.process_btn}
                </button>
                <button type="button" className={classes.footer_cancel_order_btn} onClick={openCancelOrderModal}>
                  {tsFooter.cancel_btn}
                </button>
              </div>
            </div>
          ) : (
            <div className={classes.footer}>
              <div className={classes.footer_left}>
                <p className={classes.footer_left_label}>
                  {isUnpaid ? tsFooter.order_payment_pending : tsFooter.total_order_value}
                </p>
                <button type="button" onClick={openModalProcess} className={classes.footer_process_order_btn} disabled={isUnpaid}>
                  {tsFooter.process_btn}
                </button>
              </div>
              <div className={classes.footer_right}>
                <p>
                  {formatCurrencyByISO(orderDetails.price.total)}
                </p>
                <button type="button" className={classes.footer_cancel_order_btn} onClick={openCancelOrderModal}>
                  {tsFooter.cancel_btn}
                </button>
              </div>
            </div>
          )}
        <ProcessModal
          active={modalProcessActive}
          setActive={setModalProcessActive}
        />
        <ConfirmModal
          setActive={setCancelOrderModal}
          active={cancelOrderModal}
          data={{
            className: classes.cancel_modal,
            title: tsCancelModal.title,
            description: tsCancelModal.description,
            button_first: tsCancelModal.dismiss,
            button_second: isMobile
              ? tsCancelModal.button_title_mobile
              : tsCancelModal.button_title,
            onClick: cancelOrder,
          }}
        />
      </>
    );
  }

  if (orderDetails.status === TransactionStatus.PROCESSED) {
    return (
      <>
        <div className={classes.footer}>
          <div className={classes.footer_left}>
            <p className={classes.footer_left_label}>
              {isUnpaid ? tsFooter.order_payment_pending : tsFooter.total_order_value}
            </p>
            <p className={classes.footer_left_price}>
              {formatCurrencyByISO(orderDetails.price.total)}
            </p>
          </div>
          <div className={classes.footer_right}>
            <button type="button" className={classes.footer_cancel_order_btn} onClick={openCancelOrderModal}>
              {tsFooter.cancel_btn}
            </button>
          </div>
        </div>
        <ConfirmModal
          setActive={setCancelOrderModal}
          active={cancelOrderModal}
          data={{
            className: classes.cancel_modal,
            title: tsCancelModal.title,
            description: tsCancelModal.description,
            button_first: tsCancelModal.dismiss,
            button_second: isMobile
              ? tsCancelModal.button_title_mobile
              : tsCancelModal.button_title,
            onClick: cancelOrder,
          }}
        />
      </>
    );
  }

  return (
    <div className={classes.footer}>
      <div className={classes.footer_left}>
        <p className={classes.footer_left_label}>
          {isUnpaid ? tsFooter.order_payment_pending : tsFooter.total_order_value}
        </p>
        <p className={classes.footer_left_price}>
          {formatCurrencyByISO(orderDetails.price.total)}
        </p>
      </div>
      <div className={classes.footer_right}>
        <button type="button" className={classes.footer_cancel_order_btn} disabled>
          {tsFooter.canceled_btn}
        </button>
      </div>
    </div>
  );
};

export default OrderDetailsFooter;

import { openInNewTab } from 'utils/functions';
import React, { useCallback } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useFirebaseRemoteConfig } from 'hooks/useFirebaseRemoteConfig';
import { useDispatch } from 'react-redux';
import { signOut } from 'store/auth/authActions';
// Styles
import classes from './NotFullSetProfileModal.module.scss';

const NotFullSetProfileModal: React.FC = () => {
  const translations = useTranslations();
  const dispatch = useDispatch();
  const { dynamicLink } = useFirebaseRemoteConfig();
  const redirectToAppHandler = () => {
    openInNewTab(dynamicLink);
  };

  const handleSignOut = useCallback(
    () => {
      dispatch(signOut());
    },
    [dispatch],
  );

  return (
    <div className={classes.modal_show}>
      <div className={classes.modal_wrapper}>
        <div className={classes.modal_content}>
          <p className={classes.modal_content_header}>
            {translations.home.report.re_profile_not_yet_set_up}
          </p>
          <p className={classes.modal_content_text}>
            {translations.home.report.re_profile_not_yet_set_up_text}
          </p>
          <div className={classes.modal_footer}>
            <button type="button" className={classes.modal_footer_button} onClick={handleSignOut}>
              {translations.navbar.sign_out}
            </button>
            <button type="button" onClick={redirectToAppHandler} className={classes.modal_footer_button}>
              {`${translations.home.report.download_app}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFullSetProfileModal;

import Modal from 'components/Modal';
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// Styles
import PasswordInput from 'components/Inputs/PasswordInput';
import FormError from 'components/StorefrontComponents/FormError';
import { useDispatch } from 'react-redux';
import { updatePassword } from 'store/storefront/account/accountActions';
import { useTypeSelector } from 'hooks/useTypeSelector';
import classes from './UpdatePasswordModal.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
}

const UpdatePasswordModal: React.FC<Props> = ({ active, setActive }) => {
  const [wrongPassword, setWrongPassword] = useState<boolean>(false);
  const { error } = useTypeSelector(({ storefront }) => storefront.account);
  const { checkout, account } = useTranslationsStorefront();
  const dispatch = useDispatch();
  const {
    register, handleSubmit, watch, reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  const watchPassword = watch('newPassword');

  const handleUpdatePassword = useCallback((data) => {
    dispatch(updatePassword(data, setActive));
    reset();
  }, [dispatch, reset, setActive]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timer: NodeJS.Timeout;
    if (wrongPassword) {
      timer = setTimeout(() => {
        setWrongPassword(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [wrongPassword]);

  useEffect(() => {
    if (error === 'auth/wrong-password') {
      setWrongPassword(true);
    }
  }, [error]);

  return (
    <Modal active={active} setActive={setActive} className={classes.update_password}>
      <form onSubmit={handleSubmit(handleUpdatePassword)} className={classes.root}>
        <div>
          <HeaderModal onClick={setActive} className={classes.header} />
          <h2 className={classes.title}>
            {account.security.password.update_password_title}
          </h2>
          <div className={classes.content}>
            <PasswordInput
              register={register}
              {...register('oldPassword', {
                required: true,
                minLength: 6,
              })}
              autoComplete="new-password"
              placeholder={checkout.card_form.update_password.current_password}
              isEye={false}
            >
              <FormError
                active={wrongPassword}
                text={checkout.card_form.password_error}
                direction="up"
              />
            </PasswordInput>
            <div className={classes.inputs}>
              <PasswordInput
                register={register}
                {...register('newPassword', {
                  required: true,
                  minLength: 6,
                  pattern: {
                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/i,
                    message: checkout.card_form.password_weak,
                  },
                })}
                autoComplete="new-password"
                placeholder={checkout.card_form.update_password.new_password}
              >
                <FormError
                  active={!!errors?.newPassword?.message}
                  text={errors?.newPassword?.message}
                  direction="up"
                />
              </PasswordInput>
              <PasswordInput
                register={register}
                {...register('confirmPassword', {
                  required: true,
                  minLength: 6,
                  validate: (val: string) => watchPassword === val,
                })}
                autoComplete="new-password"
                placeholder={checkout.card_form.update_password.re_new_password}
              >
                <FormError
                  active={errors?.confirmPassword?.type === 'validate'}
                  text={checkout.card_form.password_error}
                  direction="up"
                />
              </PasswordInput>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <button type="button" onClick={setActive}>
            {checkout.customer_info_modal.cancel_btn}
          </button>
          <button type="submit" disabled={!isValid}>
            {checkout.customer_info_modal.edit.update_btn}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdatePasswordModal;

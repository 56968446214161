import React, { InputHTMLAttributes } from 'react';
import { UseFormReturn } from 'react-hook-form';
// Styles
import classes from './DefaultInput.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement>{
  register: UseFormReturn['register']
  name: string
  placeholder: string
}

const DefaultInput: React.FC<Props> = ({ register, name, ...rest }) => (
  <input
    {...register(name)}
    {...rest}
    className={classes.input}
  />
);

export default DefaultInput;

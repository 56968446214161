/* eslint-disable no-nested-ternary */
import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import classNames from 'classnames';
// styles
import classes from './ModalSuccess.module.scss';

interface Props {
  onClick: () => void;
  btnTitle: string;
  title: string;
  text: string;
  closeButton?: boolean;
  variant?: 'two';
}

const ModalSuccess: React.FC<Props> = ({
  onClick, btnTitle, text, title, closeButton, variant,
}) => (
  <div className={classNames(
    variant,
    [classes.modal_second],
    [classes.modal],
  )}
  >
    <div className={classes.wrapper}>
      {closeButton && (
        <div
          aria-hidden="true"
          onClick={onClick}
          className={classes.close}
        >
          <CloseIcon />
        </div>
      )}
      <div className={classes.content}>
        <div className={classes.content_header}>
          {title}
        </div>
        <p className={classes.subtitle}>
          {text}
        </p>
        <div
          role="button"
          tabIndex={0}
          onClick={onClick}
          className={classes.payment_pay_btn}
        >
          {btnTitle}
        </div>
      </div>
    </div>
  </div>
);

export default ModalSuccess;

import React from 'react';
import { CartItemType } from 'store/storefront/shop/shopTypes';
// Images
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-red.svg';
// Styles
import classes from './Remove.module.scss';

interface Props {
  deleteItem: (item: CartItemType) => void;
  item: CartItemType;
}

const Remove: React.FC<Props> = ({ deleteItem, item }) => (
  <button type="button" onClick={() => deleteItem(item)} className={classes.remove}>
    <DeleteIcon />
  </button>
);

export default Remove;

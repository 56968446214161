import React from 'react';
import { IOrderData } from 'store/storefront/order/orderTypes';
import { TranslationStorefrontModel } from 'types/TranslationStorefrontModel';
import { calcTaxes } from 'utils/functions';
import Accordion from 'components/Accordion';
import { CurrencyFormatByISO } from 'utils/price';
import { useMobileScreen } from 'hooks/useMobileScreen';
import OrderItems from './OrderItems';
// Styles
import classes from './OrderCart.module.scss';

interface IOrderInfo {
  currencyISO: string;
  shipping?: number;
  discount?: number;
  total: number;
  itemLength: number;
  number: string;
  placed: Date;
}

interface Props {
  orderInfo: IOrderInfo;
  translation: TranslationStorefrontModel['checkout']['price_details'];
  translationItem: TranslationStorefrontModel['cart']['cart_item']
  items: IOrderData['items'];
  pay: boolean;
}

const OrderCart: React.FC<Props> = ({
  translation,
  orderInfo,
  items,
  translationItem,
  pay,
}) => {
  const { isTablet } = useMobileScreen();
  const formatCurrencyByISO = CurrencyFormatByISO(orderInfo.currencyISO);
  const taxes = calcTaxes(items);
  const subtotal = orderInfo.total
    - (orderInfo?.shipping || 0)
    - (orderInfo?.discount || 0)
    - taxes.excluded;

  if (pay && isTablet) {
    return (
      <div className={classes.checkoutMobile_details}>
        <Accordion title={translation.title} className={classes.details_accordion}>
          <div className={classes.root_pay}>
            <div className={classes.root_pay_main}>
              <h2>{translation.title}</h2>
              <OrderItems
                items={items}
                currencyIso={orderInfo.currencyISO}
                translation={translationItem}
              />
            </div>
            <div className={classes.checkoutMobile_details_price}>
              <div>
                <h3>{translation.subtotal_label}</h3>
                <span>{formatCurrencyByISO(subtotal)}</span>
              </div>
              {!!orderInfo?.shipping && (
                <div>
                  <h3>{translation.fee_label}</h3>
                  <span>{formatCurrencyByISO(orderInfo.shipping)}</span>
                </div>
              )}
              {(orderInfo?.discount || orderInfo?.discount === 0) && (
                <div>
                  <h3>{translation.discount}</h3>
                  <span>{formatCurrencyByISO(orderInfo.discount)}</span>
                </div>
              )}
              <div className={classes.taxes_included}>
                <h3>{translation.taxes_included_label}</h3>
                <span>{formatCurrencyByISO(taxes.included)}</span>
              </div>
              <div>
                <h3>{translation.taxes_excluded_label}</h3>
                <span>{formatCurrencyByISO(taxes.excluded)}</span>
              </div>
            </div>
          </div>
        </Accordion>
        <div className={classes.checkoutMobile_details_subtotal}>
          <h3>{translation.subtotal_label}</h3>
          <span>{formatCurrencyByISO(orderInfo.total)}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.root_main}>
        <h2>{translation.title}</h2>
        <OrderItems
          items={items}
          currencyIso={orderInfo.currencyISO}
          translation={translationItem}
        />
      </div>
      <div className={classes.root_price}>
        <div>
          <h3>{translation.subtotal_label}</h3>
          <span>{formatCurrencyByISO(subtotal)}</span>
        </div>
        {!!orderInfo?.shipping && (
          <div>
            <h3>{translation.fee_label}</h3>
            <span>{formatCurrencyByISO(orderInfo.shipping)}</span>
          </div>
        )}
        {(orderInfo?.discount || orderInfo?.discount === 0) && (
          <div>
            <h3>{translation.discount}</h3>
            <span>{formatCurrencyByISO(orderInfo.discount)}</span>
          </div>
        )}
        <div className={classes.taxes_included}>
          <h3>{translation.taxes_included_label}</h3>
          <span>{formatCurrencyByISO(taxes.included)}</span>
        </div>
        <div>
          <h3>{translation.taxes_excluded_label}</h3>
          <span>{formatCurrencyByISO(taxes.excluded)}</span>
        </div>
        <div>
          <h3>{translation.subtotal_label}</h3>
          <span>{formatCurrencyByISO(orderInfo.total)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderCart;

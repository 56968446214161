import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useQuery } from 'hooks/useQuery';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
// Actions
import { getTranslations, setNotificationAction } from 'store/storefront/app/appActions';
import {
  getSalesChannel,
  getShop, SetAddToBagAction,
} from 'store/storefront/shop/shopActions';
import { getOrder } from 'store/storefront/order/orderActions';
import { checkoutSetOrder } from 'store/storefront/checkout/checkoutActions';
// Utils
import { isEmptyObject } from 'utils/functions';
import { StoreFrontFunctions } from 'utils/storefront/StoreFrontFunctions';
// Types
import { CartItemType, ICustomerService, NotificationEnum } from 'store/storefront/shop/shopTypes';
// Assets
import { ReactComponent as UserIcon } from 'assets/storefront-icons/user.svg';
// Components
import Notification from 'components/StorefrontComponents/Checkout/Notification';
import CompanyAvatar from 'components/StorefrontComponents/CompanyAvatar';
// Styles
import classes from './OrderLayout.module.scss';

interface Props {
  contacts: ICustomerService | undefined
}

const OrderLayout: React.FC<Props> = ({ children }) => {
  const { order } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { goToAccount } = useStorefrontRoutes();
  const { id, orderId } = useParams<{ id: string, orderId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useMobileScreen();
  const location = useLocation();
  const translations = useTranslationsStorefront();
  const { data } = useTypeSelector(({ storefront }) => storefront.order);
  const {
    activeTranslation,
    isTranslationsFetched,
    notification,
  } = useTypeSelector(({ storefront }) => storefront.app);
  const {
    shop, products, bag, currentSalesChannel,
  } = useTypeSelector(({ storefront }) => storefront.shop);
  const query = useQuery();

  const handleAccount = useCallback(() => {
    goToAccount(id, location);
  }, [goToAccount, location, id]);

  useEffect(() => {
    if (isEmptyObject(shop) && typeof id !== 'undefined') {
      dispatch(getShop(id));
    }
  }, [id, dispatch, shop]);

  useEffect(() => {
    if (!products.loading && products.data.length === 0 && typeof id !== 'undefined') {
      return;
    }
    const cachedCart = localStorage.getItem(`leja-${id}-cart`);

    if (!cachedCart || typeof cachedCart === 'undefined') {
      return;
    }

    const cart: CartItemType[] = JSON.parse(cachedCart);
    if (isEqual(cart, bag)) {
      return;
    }
    dispatch(SetAddToBagAction(cart));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.loading, id]);

  useEffect(() => {
    if (!isTranslationsFetched || !translations.shopTranslations) {
      dispatch(getTranslations('en'));
    }
  }, [isTranslationsFetched, dispatch, activeTranslation, translations.shopTranslations]);

  useEffect(() => {
    if (typeof id !== 'undefined' && !currentSalesChannel && shop.businessAs) {
      dispatch(getSalesChannel(id, query.get('scid')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop.businessAs]);

  useEffect(() => {
    if (shop.businessAs) {
      dispatch(getOrder(id, orderId));
      history.replace(location);
      if (order.isPayed) {
        dispatch(setNotificationAction(NotificationEnum.SUCCESS_PAY));
      }

      dispatch(checkoutSetOrder({
        loading: null,
        orderId: undefined,
        error: undefined,
        isPayed: false,
        stripe: undefined,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop.businessAs]);

  if (!translations.shopTranslations) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <button type="button" className={classes.go_to_account} onClick={handleAccount}>
            <UserIcon />
            {isMobile
              ? activeTranslation.order.my_account_text
              : activeTranslation.order.go_to_my_account_text}
          </button>
          <CompanyAvatar
            title={currentSalesChannel?.name || ''}
            logoUrl={currentSalesChannel?.logoUrl}
          />
        </div>
        <div className={classes.title}>
          <p className={classes.title_name}>
            {activeTranslation.order.order_details.order_label}
          </p>
          <p className={classes.title_order}>
            {activeTranslation.order.order_details.order_number.replace('{order}', data?.id.substring(0, 8) || '')}
          </p>
        </div>
        <div className={classes.order_content}>
          {children}
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.footer_left}>
          <p className={classes.footer_title}>
            {activeTranslation.footer.title}
          </p>
          <div className={classes.footer_address}>
            <p>
              {currentSalesChannel?.name}
            </p>
            <p>
              {currentSalesChannel?.address.line1}
            </p>
            <p>
              {currentSalesChannel?.address.line2}
            </p>
            <p>
              {`${currentSalesChannel?.address.country} ${currentSalesChannel?.address.postalCode} ${currentSalesChannel?.address.country}`}
            </p>
          </div>
        </div>
        <div className={classes.footer_right}>
          <a href={`mailto:${currentSalesChannel?.customerService?.email}`}>
            {currentSalesChannel?.customerService?.email}
          </a>
          <a href={`tel: ${currentSalesChannel?.customerService?.phoneNumber}`}>
            {currentSalesChannel?.customerService?.phoneNumber}
          </a>
          <div className={classes.socials}>
            {currentSalesChannel
              && StoreFrontFunctions.getContactSellerLinks(currentSalesChannel?.customerService)
                .map((social) => (
                  social && (
                    <a
                      href={social.link}
                      key={social.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.social}
                    >
                      <social.image />
                    </a>
                  )
                ))}
          </div>
        </div>
      </div>
      <Notification
        active={notification === NotificationEnum.SUCCESS_PAY}
        text={activeTranslation.order.payment_successful_notification}
      />
    </div>
  );
};

export default OrderLayout;

import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { PayMethodsEnum } from 'constants/paymentsMethods';
// Payment options
import Giropay from '../GiroPay';
import Bancontact from '../Bancontact';
import Sofort from '../Sofort';
import P24 from '../P24';
import SEPA from '../SEPA';
import EPS from '../EPS';

interface Props {
  activeBank: PayMethodsEnum | null;
  register: UseFormReturn['register']
}

const BankPaymentForm: React.FC<Props> = ({
  activeBank,
  register,
}) => {
  if (activeBank === PayMethodsEnum.GIROPAY) {
    return (
      <Giropay register={register} />
    );
  }

  if (activeBank === PayMethodsEnum.BANCONTACT) {
    return (
      <Bancontact register={register} />
    );
  }

  if (activeBank === PayMethodsEnum.SOFORT) {
    return (
      <Sofort register={register} />
    );
  }

  if (activeBank === PayMethodsEnum.P24) {
    return (
      <P24 register={register} />
    );
  }

  if (activeBank === PayMethodsEnum.SEPA) {
    return (
      <SEPA register={register} />
    );
  }

  if (activeBank === PayMethodsEnum.EPS) {
    return (
      <EPS
        register={register}
      />
    );
  }

  return null;
};

export default BankPaymentForm;

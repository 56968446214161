/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { directoryGetDocuments, directorySetDocuments } from 'store/dashboard/directory/directoryActions';
import { DirectoryDocuments } from 'store/dashboard/directory/directoryTypes';
// Utils
import { lowerFirstLetter } from 'utils/strings';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useUser } from 'hooks/useUser';
// Assets
import { ReactComponent as NodataIcon } from 'assets/icons/transaction-nodata.svg';
import { TabsEnum } from 'types/globalTypes';
// Components
import MoreButton from 'components/Buttons/MoreButton';
import CustomSpinner from 'components/CustomSpinner';
// Styles
import Header from 'pages/Dashboard/Transaction/TransactionTabContent/Header';
import classes from './DirectoryTab.module.scss';
import Transaction from './Transaction';

interface Props {
  name: string;
}

const DirectoryTab: React.FC<Props> = ({ name }) => {
  const { loading, documents } = useTypeSelector(({ directory }) => directory);
  const [sort, setSort] = useState<string>();
  const dispatch = useDispatch();
  const user = useUser();
  const documentType = lowerFirstLetter(name) as keyof DirectoryDocuments;
  const [pages, setPages] = useState<number>(documents[documentType].page);
  const translations = useTranslations();
  const params: { clientId?: string } = useParams();
  const OrdersTabs = name === TabsEnum.pendingOrders;
  const checkMoreButton = useMemo(
    () => !loading
      && !documents[documentType].endPage
      && !((documents[documentType]?.data?.length || 0) < 9),
    [documentType, documents, loading],
  );

  const handleMoreClicked = useCallback(() => {
    if (!user?.currentOrganisation?.id) {
      return;
    }
    setPages(pages + 1);
    dispatch(
      directoryGetDocuments(
        user?.currentOrganisation?.id,
        Number(params.clientId),
        documentType,
        pages,
        true,
      ),
    );
  }, [dispatch, documentType, pages, params.clientId, user?.currentOrganisation?.id]);

  const sortOrders = useCallback((header: string, sortConfig = 'asc') => {
    setSort(header);
    if (header === translations.directory.details.headers[0]) {
      dispatch(directorySetDocuments({
        documents: documents[documentType].data?.sort((a, b) => {
          if (Number(a.id) < Number(b.id)) return sortConfig === 'asc' ? -1 : 1;
          if (Number(a.id) > Number(b.id)) return sortConfig === 'asc' ? 1 : -1;
          return 0;
        }) || [],
        name: documentType,
      }));
    }

    if (header === translations.directory.details.headers[1]) {
      dispatch(directorySetDocuments({
        documents: documents[documentType].data?.sort((a, b) => {
          if (a.date && b.date) {
            if (a?.date > b?.date) return sortConfig === 'asc' ? 1 : -1;
            if (a.date < b.date) return sortConfig === 'asc' ? -1 : 1;
          }
          return 0;
        }) || [],
        name: documentType,
      }));
    }

    if (header === translations.directory.details.headers[2]) {
      dispatch(directorySetDocuments({
        documents: documents[documentType].data?.sort((a, b) => {
          if (a.name > b.name) return sortConfig === 'asc' ? 1 : -1;
          if (a.name < b.name) return sortConfig === 'asc' ? -1 : 1;
          return 0;
        }) || [],
        name: documentType,
      }));
    }

    if (header === translations.directory.details.headers[3]) {
      dispatch(directorySetDocuments({
        documents: documents[documentType].data?.sort((a, b) => {
          if (a.type < b.type) return sortConfig === 'asc' ? -1 : 1;
          if (a.type > b.type) return sortConfig === 'asc' ? 1 : -1;
          return 0;
        }) || [],
        name: documentType,
      }));
    }

    if (header === translations.directory.details.headers[4]) {
      dispatch(directorySetDocuments({
        documents: documents[documentType].data?.sort((a, b) => {
          if (a.status > b.status) return sortConfig === 'asc' ? 1 : -1;
          if (a.status < b.status) return sortConfig === 'asc' ? -1 : 1;
          return 0;
        }) || [],
        name: documentType,
      }));
    }

    if (header === translations.directory.details.headers[5]) {
      dispatch(directorySetDocuments({
        documents: documents[documentType].data?.sort((a, b) => {
          if (a.amount > b.amount) return sortConfig === 'asc' ? 1 : -1;
          if (a.amount < b.amount) return sortConfig === 'asc' ? -1 : 1;
          return 0;
        }) || [],
        name: documentType,
      }));
    }
  }, [dispatch, documentType, documents, translations.directory.details.headers]);

  useEffect(() => {
    if (!user?.currentOrganisation?.id) {
      return;
    }
    if (!name && !documentType) {
      return;
    }

    if (!documents[documentType].data) {
      dispatch(
        directoryGetDocuments(
          user.currentOrganisation.id,
          Number(params.clientId),
          documentType,
        ),
      );
    }
  }, [dispatch,
    documentType, documents, name, params.clientId, user?.currentOrganisation?.id]);

  return (
    <div className={classes.tab}>
      {loading === 'directoryDocuments' ? (
        <div className={classes.loading_two}>
          <CustomSpinner variant="two" />
        </div>
      ) : (
        <div className={classes.table}>
          <div className={classes.table_header}>
            {translations.directory.details.headers.map((header) => (
              <Header
                key={header}
                header={header}
                sortItems={sortOrders}
                isIconShow={sort === header}
              />
            ))}
          </div>
          <div className={classes.table_content}>
            {documents[documentType].data?.length ? (
              documents[documentType].data?.map((item) => (
                <Transaction item={item} isOrders={OrdersTabs} key={item.uniqId} />
              ))
            ) : (
              <div className={classes.nodata}>
                <NodataIcon />
                {translations.home.report.no_data_available_yet}
              </div>
            )}
            {loading === 'directoryAddDocuments'
              && (
                <div className={classes.loading}>
                  <CustomSpinner variant="two" />
                </div>
              )}
          </div>
          {checkMoreButton && (
            <div
              onClick={handleMoreClicked}
              tabIndex={0}
              role="button"
              className={classes.more_btn}
            >
              <MoreButton />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DirectoryTab;

import React, {
  useCallback, useEffect, useState,
} from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Services
import { auth } from 'services/firebase';
// Assets
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
// Actions
import { authEmailAndPassword, checkoutSetFulfillmentAction } from 'store/storefront/checkout/checkoutActions';
// Components
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import Checkbox from 'components/Checkbox';
import CustomSpinner from 'components/CustomSpinner';
import FormError from 'components/StorefrontComponents/FormError';
import EyeButton from 'components/Buttons/EyeButton';
// Styles
import classes from './SignIn.module.scss';

interface Props {
  setActive: () => void;
  handleSignUp: () => void;
  handleResetPassword: () => void;
}

const SignIn: React.FC<Props> = ({ setActive, handleSignUp, handleResetPassword }) => {
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);
  const {
    fulfillment,
    order,
  } = useTypeSelector(({ storefront }) => storefront.checkout);
  const {
    register, setValue, handleSubmit, watch, trigger, formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [wrongPassword, setWrongPassword] = useState<boolean>(false);
  const { checkout } = useTranslationsStorefront();
  const dispatch = useDispatch();

  const watchPassword = watch('password');
  const watchEmail = watch('email');

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const handleForgotPassword = useCallback(() => {
    handleResetPassword();
    const updateFulfillment = {
      ...fulfillment,
      contact: {
        ...fulfillment.contact,
        email: watchEmail,
      },
    };

    dispatch(checkoutSetFulfillmentAction(updateFulfillment));
  }, [dispatch, fulfillment, handleResetPassword, watchEmail]);

  const validEmail = useCallback(async () => {
    const email = await trigger('email');

    if (email) {
      setIsValidEmail(true);
      setLoading(true);
      const emailInFirebase = await auth.fetchSignInMethodsForEmail(watchEmail);
      setIsEmail(!!emailInFirebase.length);
      setLoading(false);
    } else {
      setIsValidEmail(false);
    }
  }, [trigger, watchEmail]);

  const onSubmit = useCallback((data) => {
    dispatch(authEmailAndPassword(
      data, order,
      setWrongPassword,
      setActive,
    ));
  }, [dispatch, order, setActive]);

  useEffect(() => {
    if (watchEmail) {
      validEmail();
    }
  }, [validEmail, watchEmail]);

  useEffect(() => (
    setValue('email', fulfillment.contact?.email)
  ), [fulfillment.contact?.email, setValue]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timer: NodeJS.Timeout;

    if (wrongPassword) {
      timer = setTimeout(() => {
        setWrongPassword(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [setActive, wrongPassword]);

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <HeaderModal onClick={() => setActive()} className={classes.header} />
        <div className={classes.content}>
          <p className={classes.title}>{checkout.customer_info_modal.title_information}</p>
          <p className={classes.subtitle}>{checkout.customer_info_modal.subtitle}</p>
          <div className={classes.inputs}>
            <div className={classes.inputs_wrapper}>
              <input
                autoComplete="username"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: checkout.email_input.error,
                  },
                })}
                style={{
                  textDecoration: isEmail ? 'underline' : 'none',
                }}
                placeholder={checkout.card_form.email_label}
                className={classes.input}
              />
              <SuccessIcon className={cn(classes.success, {
                [classes.success_active]: isEmail && !loading,
              })}
              />
              {loading && (
                <div className={classes.spinner_wrapper}>
                  <CustomSpinner variant="two" size="small" />
                </div>
              )}
              <FormError
                active={!isEmail && isValidEmail && !loading}
                text={checkout.card_form.email_not_found_error}
              />
            </div>
            <div className={classes.inputs_wrapper}>
              <input
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                {...register('password', {
                  required: true,
                  minLength: 6,
                })}
                className={classes.input}
                disabled={!isEmail}
                placeholder={checkout.card_form.password_placeholder}
              />
              <EyeButton
                active={showPassword}
                setActive={handleShowPassword}
                disabled={!watchPassword || !isEmail}
              />
              <FormError
                active={wrongPassword}
                text={checkout.card_form.password_error}
                direction="up"
              />
            </div>
          </div>
          <div className={classes.wrapper}>
            <div className={cn(classes.checkbox, {
              [classes.checkbox_disabled]: !isEmail,
            })}
            >
              <Checkbox register={register} name="remember" disabled={!isEmail} />
              <p>{checkout.customer_info_modal.remember_me_text}</p>
            </div>
            <button className={classes.forgot_password} disabled={!isEmail} type="button" onClick={handleForgotPassword}>
              {checkout.customer_info_modal.forgot_your_password_btn}
            </button>
          </div>
          <div className={classes.sign_up}>
            <div className={classes.sign_up_title}>
              {checkout.customer_info_modal.sign_up.title.replace('{StoreName}', currentSalesChannel?.name || '')}
            </div>
            <div className={classes.sign_up_subtitle}>
              {checkout.customer_info_modal.sign_up.subtitle_one_text}
              {' '}
              <button type="button" onClick={handleSignUp}>
                {checkout.customer_info_modal.sign_up.sign_up_btn}
              </button>
              {' '}
              {checkout.customer_info_modal.sign_up.subtitle_two_text}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <button type="button" onClick={handleSignUp}>
          {checkout.customer_info_modal.sign_up.sign_up_btn}
        </button>
        <button type="submit" disabled={!isEmail || !isValid}>
          {checkout.customer_info_modal.sign_in_btn}
        </button>
      </div>
    </form>
  );
};

export default SignIn;

import { TranslationStorefrontModel, TranslationsType } from 'types/TranslationStorefrontModel';

export const APP_SET_MENULINKS = 'APP_SET_MENULINKS';
export const APP_SET_MOBILE_MENU_OPEN = 'APP_SET_MOBILE_MENU_OPEN';
export const APP_SET_SHARE_OPEN = 'APP_SET_SHARE_OPEN';
export const APP_SET_NOTIFICATION = 'APP_SET_NOTIFICATION';
export const APP_SET_TRANSLATIONS_FETCHED = 'APP_SET_TRANSLATIONS_FETCHED';
export const APP_SET_TRANSLATIONS = 'APP_SET_TRANSLATIONS';
export const APP_SET_CURRENT_LANGUAGE = 'APP_SET_CURRENT_LANGUAGE';
export const APP_SET_ACTIVE_TRANSLATION = 'APP_SET_ACTIVE_TRANSLATION';
export const APP_SET_GEO_INFO = 'APP_SET_GEO_INFO';

export interface Sublinks {
  title: string;
  route: string;
}

export interface MenuLinks {
  title: string,
  route: string,
  icon: string,
  sublinks?: Sublinks[];
}

export interface GeoInfo {
  countryName: string;
  countryCode: string;
  city: string;
  currency: string;
}

export interface AppState {
  menuLinks: MenuLinks[];
  translations: TranslationsType | null;
  isTranslationsFetched: boolean;
  isMobileMenuOpen: boolean;
  isShareOpen: boolean;
  notification: string | null;
  currentLanguage: string;
  activeTranslation: TranslationStorefrontModel;
  geoInfo: GeoInfo | null;
}

interface AppSetMenuLinksAction {
  type: typeof APP_SET_MENULINKS;
  payload: MenuLinks[];
}

interface AppMobileMenuOpenAction {
  type: typeof APP_SET_MOBILE_MENU_OPEN;
  payload: boolean;
}

interface AppShareOpenAction {
  type: typeof APP_SET_SHARE_OPEN;
  payload: boolean;
}

interface AppSetNotificationAction {
  type: typeof APP_SET_NOTIFICATION;
  payload: string | null;
}

interface AppSetTranslationsFetchedAction {
  type: typeof APP_SET_TRANSLATIONS_FETCHED;
  payload: boolean;
}
interface AppSetTranslationsAction {
  type: typeof APP_SET_TRANSLATIONS;
  payload: TranslationsType;
}

interface AppSetGeoInfoAction {
  type: typeof APP_SET_GEO_INFO;
  payload: GeoInfo;
}

interface AppSetCurrentLanguage {
  type: typeof APP_SET_CURRENT_LANGUAGE;
  payload: string;
}

interface AppSetActiveTranslation {
  type: typeof APP_SET_ACTIVE_TRANSLATION;
  payload: TranslationStorefrontModel;
}

export type AppActions =
  AppSetMenuLinksAction |
  AppSetNotificationAction |
  AppMobileMenuOpenAction |
  AppShareOpenAction |
  AppSetTranslationsFetchedAction |
  AppSetTranslationsAction |
  AppSetActiveTranslation |
  AppSetGeoInfoAction |
  AppSetCurrentLanguage;

import { Destructuring } from 'utils/dashboard/directory/destructuring';
import { ICalcHighLowMonth } from 'utils/functions';
import { DataChart } from '../report/reportTypes';

// Action Types
export const DIRECTORY_SET_LIST = 'DIRECTORY_SET_LIST';
export const DIRECTORY_SET_ADD_LIST = 'DIRECTORY_SET_ADD_LIST';
export const DIRECTORY_SET_SUMMARY = 'DIRECTORY_SET_SUMMARY';
export const DIRECTORY_SET_LOADING = 'DIRECTORY_SET_LOADING';
export const DIRECTORY_END_PAGE = 'DIRECTORY_END_PAGE';
export const DIRECTORY_SET_TYPE_CONTACT = 'DIRECTORY_SET_TYPE_CONTACT';
export const DIRECTORY_CLEAR_LIST = 'DIRECTORY_CLEAR_LIST';
export const DIRECTORY_SET_DOCUMENTS = 'DIRECTORY_SET_DOCUMENTS';
export const DIRECTORY_SET_INVOICES = 'DIRECTORY_SET_INVOICES';
export const DIRECTORY_SET_BILLS = 'DIRECTORY_SET_BILLS';
export const DIRECTORY_DETAILS_END_PAGE = 'DIRECTORY_DETAILS_END_PAGE';
export const DIRECTORY_SET_CHART_DATA = 'DIRECTORY_SET_CHART_DATA';
export const DIRECTORY_SET_AVERAGE_DATA = 'DIRECTORY_SET_AVERAGE_DATA';
export const DIRECTORY_SET_PROFILE = 'DIRECTORY_SET_PROFILE';
export const DIRECTORY_SET_AVERAGE_MONTH = 'DIRECTORY_SET_AVERAGE_MONTH';
export const DIRECTORY_ADD_DOCUMENTS_ACTION = 'DIRECTORY_ADD_DOCUMENTS_ACTION';

export type DirectoryState = {
  contacts: DirectoryListType[] | [];
  loading: string;
  endPage: boolean;
  summary: DirectorySummary;
  documents: DirectoryDocuments;
  details: {
    type?: DirectoryType;
    summary: {
      customers: SummaryDirectoryDetails,
      suppliers: SummaryDirectoryDetails,
    }
    average: {
      customers?: AverageData[],
      suppliers?: AverageData[],
    },
    averageMonth: {
      suppliers?: {
        value: number,
        percentage: number,
      }
      customers?: {
        value: number,
        percentage: number,
      }
      agingInvoices?: {
        '1-30': {
          change: number,
          value: number,
        }
        '30+': {
          change: number,
          value: number,
        }
      } | null
      agingBills?: {
        '1-30': {
          change: number,
          value: number,
        }
        '30+': {
          change: number,
          value: number,
        }
      } | null
    } | null
    profile?: DirectoryProfile,
  };
};

export interface Directory {
  contactid: number,
  firstname: string,
  lastname: string,
  createdat: string,
  mobilenumber: string,
  activity: string,
  transactions: number,
  sales: number,
  expenses: number,
  salesPerMonth: number
  address: {
    city: string;
    line1: string;
    line2: string;
    country: string;
    postalCode: string;
  } | null
}

export interface DirectorySummary {
  customers: number;
  suppliers: number;
  total: number;
}

export interface DirectoryProfile {
  address: null | {
    city: string;
    address1: string;
    postalCode: string;
  },
  createdAt: string,
  ordersPerMonth: number,
  activity: string;
  firstName: string;
  id: number;
  lastName: string;
  organisationName: string,
  mobileNumber: string,
  email: string,
  updatedAt: string
  transactions: number,
  organisationId: number,
  sales: number,
  expenses: null | string;
}

export interface ChartsData {
  customers?: DataChart[];
  suppliers?: DataChart[];
}

export interface AverageData {
  label: string;
  totalamount: number | null;
  change: number | null;
}

export type SummaryDirectoryDetailsResType = {
  expenses?: ResChartData[],
  contactAgingBills?: {
    interval: number;
    total1: number | null;
    total2: number | null;
  }[]
  sales?: ResChartData[],
  agingInvoices?: {
    interval: number;
    total1: number | null;
    total2: number | null;
  }[],
  pastDue30: number,
  pastDue30Plus: number,
  purchaseOrdersRequested?: number,
  purchaseOrdersResponded?: number
  pendingOrders?: {
    total: number,
    count: number
  },
  pendingSalesOrders?: {
    total: number,
    count: number
  }
}

export interface SummaryDirectoryDetails {
  chartData: DataChart[],
  agingInvoices?: DataChart[] | null,
  contactAgingBills?: DataChart[] | null,
  pendingBills?: {
    total: number,
    count: number
  }
  sum?: number,
  pastDue30?: number,
  pastDue30Plus?: number,
  purchaseOrdersRequested?: number,
  purchaseOrdersResponded?: number,
  pendingInvoices?: {
    total: number,
    count: number
  }
  pendingOrders?: {
    total: number,
    count: number
  },
  pendingSalesOrders?: {
    total: number,
    count: number
  },
  highestMonth?: ICalcHighLowMonth['highestMonth']
  lowestMonth?: ICalcHighLowMonth['lowestMonth']
}

export interface ResChartData {
  interval: number;
  total: number;
}

export interface DirectoryDocuments {
  pendingOrders: {
    data: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  },
  all: {
    data: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  };
  unpaidInvoice: {
    data: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  };
  paidInvoice: {
    data: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  };
  paidBills: {
    data: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  };
  estimates: {
    data: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  };
  processedOrders: {
    data: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  };
  unprocessedOrders: {
    data: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  };
  canceledOrders: {
    data?: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  };
  quotes: {
    data: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  };
  unpaidBills: {
    data: Destructuring[] | null;
    page: number;
    endPage?: boolean;
  };
}

export enum DirectoryType {
  customer = 'customers',
  supplier = 'suppliers',
  both = 'both',
}

export interface DirectoryListType {
  name: string;
  location: string;
  added: string;
  phone: string;
  transactions: number;
  sales: number;
  expenses: number;
  id: number;
  avgSpend: number;
  activity: string;
}

export interface directoryGroup {
  [key: string]: Destructuring[];
}
interface DirectorySetList {
  type: typeof DIRECTORY_SET_LIST;
  payload: DirectoryListType[];
}

interface DirectorySetAddList {
  type: typeof DIRECTORY_SET_ADD_LIST;
  payload: DirectoryListType[];
}

interface DirectoryClearList {
  type: typeof DIRECTORY_CLEAR_LIST;
  payload: DirectoryListType[];
}

interface DirectorySetSummary {
  type: typeof DIRECTORY_SET_SUMMARY;
  payload: DirectorySummary;
}

interface DirectorySetLoading {
  type: typeof DIRECTORY_SET_LOADING;
  payload: string;
}

interface DirectoryEndPage {
  type: typeof DIRECTORY_END_PAGE;
  payload: boolean;
}

interface DirectorySetTypeContact {
  type: typeof DIRECTORY_SET_TYPE_CONTACT;
  payload: DirectoryType;
}

interface DirectorySetDocuments {
  type: typeof DIRECTORY_SET_DOCUMENTS;
  payload: {
    documents: Destructuring[];
    name: keyof DirectoryDocuments;
  };
}

interface DirectorySetInvoices {
  type: typeof DIRECTORY_SET_INVOICES;
  payload: directoryGroup;
}

interface DirectorySetBills {
  type: typeof DIRECTORY_SET_BILLS;
  payload: directoryGroup;
}
interface DirectoryDetailsEndPage {
  type: typeof DIRECTORY_DETAILS_END_PAGE;
  payload: {
    endPage: boolean;
    name: string;
  };
}

interface DirectorySetChartData {
  type: typeof DIRECTORY_SET_CHART_DATA;
  payload: { data: SummaryDirectoryDetails, type: string };
}

interface DirectorySetAverageData {
  type: typeof DIRECTORY_SET_AVERAGE_DATA;
  payload: {
    data: AverageData[],
    type: string
  };
}

interface DirectorySetProfile {
  type: typeof DIRECTORY_SET_PROFILE;
  payload: DirectoryProfile
}

interface DirectorySetAverageMonthAction {
  type: typeof DIRECTORY_SET_AVERAGE_MONTH;
  payload: {
    type: DirectoryType,
    selectedMonth: {
      [key: string]: number;
    } | null;
    datasets: DataChart[],
  }
}

interface DirectoryAddDocumentsAction {
  type: typeof DIRECTORY_ADD_DOCUMENTS_ACTION;
  payload: {
    documents: Destructuring[];
    name: keyof DirectoryDocuments;
    page: number
  };
}

export type DirectoryActions =
  | DirectorySetList
  | DirectorySetAddList
  | DirectorySetSummary
  | DirectorySetLoading
  | DirectoryEndPage
  | DirectorySetTypeContact
  | DirectoryClearList
  | DirectorySetDocuments
  | DirectorySetInvoices
  | DirectorySetBills
  | DirectoryDetailsEndPage
  | DirectorySetChartData
  | DirectorySetAverageData
  | DirectorySetProfile
  | DirectorySetAverageMonthAction
  | DirectoryAddDocumentsAction;

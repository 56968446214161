import React, { useEffect, useState } from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { OrderTabsEnum, OrderTabsNames } from 'store/dashboard/orders/orderTypes';
import { mixpanelActions } from 'utils/mixpanel';
import { useDispatch } from 'react-redux';
import { SetPrevOrder } from 'store/dashboard/app/appActions';
import OrderTab from './OrderTab';
// Styles
import classes from './OrdersTabs.module.scss';

interface TransactionTabsProps {
  tabs: string[],
  show: boolean,
}

const OrdersTabs: React.FC<TransactionTabsProps> = ({ tabs }) => {
  const [prevTab, setPrevTab] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    mixpanelActions.time_event('View Orders / All Page');
    setPrevTab(OrderTabsNames[0]);
    dispatch(SetPrevOrder(OrderTabsNames[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs
      selectedTabPanelClassName={classes.react_tabs__tab_panel__selected}
      selectedTabClassName={classes.react_tabs__tab__selected}
      className={classes.react_tabs}
      onSelect={(index) => {
        if (prevTab) {
          mixpanelActions.track(`View Orders / ${prevTab} Page`, {
            'Page Name': `Orders - ${prevTab}`,
          });
        }

        mixpanelActions.time_event(`View Orders / ${OrderTabsNames[index]} Page`);
        setPrevTab(OrderTabsNames[index]);
        dispatch(SetPrevOrder(OrderTabsNames[index]));
      }}
    >
      <TabList className={classes.react_tabs__tab_list}>
        {tabs.map((tab) => (
          <Tab key={tab} className={classes.react_tabs__tab}>
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanel>
        <OrderTab name={OrderTabsEnum.All} />
      </TabPanel>
      <TabPanel>
        <OrderTab name={OrderTabsEnum.ProcessedOrders} />
      </TabPanel>
      <TabPanel>
        <OrderTab name={OrderTabsEnum.UnprocessedOrders} />
      </TabPanel>
      <TabPanel>
        <OrderTab name={OrderTabsEnum.CanceledOrders} />
      </TabPanel>
    </Tabs>
  );
};

export default OrdersTabs;

/* eslint-disable no-console */
import { AppThunk } from 'store';
import { setNotificationAction } from 'store/dashboard/app/appActions';
// Utils
import { SalesChannel } from 'store/storefront/shop/shopTypes';
import { ApiOrgId, DeleteOrgId } from 'services/api';
import { getYear } from 'utils/dates';
import { Unit } from 'types/globalTypes';
import {
  CATALOG_ITEMS_SET_PRODUCTS,
  CATALOG_ITEMS_SET_PRODUCT_VARIATIONS,
  CATALOG_ITEMS_SET_CATEGORIES,
  CATALOG_ITEMS_SEARCH,
  CATALOG_ITEMS_SET_LOADING,
  CATALOG_ITEMS_SET_CURRENT_PRODUCT,
  CATALOG_ITEMS_SET_CURRENT_PRODUCT_LOADING,
  CATALOG_ITEMS_SET_CURRENT_PRODUCT_ERROR,
  CATALOG_ITEMS_SET_SUMMARY,
  CATALOG_ITEMS_SET_UNITS,
  CATALOG_ITEMS_SET_LOCATIONS,
  CATALOG_ITEMS_IS_NEXT_PAGE_ITEMS,
  ItemsActions,
  ProductItem,
  Category,
  Variation,
  SearchItemOrCategory,
  SummaryItems,
  CATALOG_ITEMS_SET_MORE_PRODUCTS,
  CATALOG_ITEMS_SET_PRODUCTS_LOADING,
} from './itemsTypes';

export const CatalogItemsSetProductsAction = (payload: ProductItem[]): ItemsActions => ({
  type: CATALOG_ITEMS_SET_PRODUCTS,
  payload,
});

export const CatalogItemsSetMoreProductsAction = (payload: ProductItem[]): ItemsActions => ({
  type: CATALOG_ITEMS_SET_MORE_PRODUCTS,
  payload,
});

const CatalogItemsIsNextPageProductsAction = (payload: boolean): ItemsActions => ({
  type: CATALOG_ITEMS_IS_NEXT_PAGE_ITEMS,
  payload,
});

const CatalogItemsSetProductsLoadingAction = (payload: boolean): ItemsActions => ({
  type: CATALOG_ITEMS_SET_PRODUCTS_LOADING,
  payload,
});

const SetProductVariationsAction = (payload: ProductItem): ItemsActions => ({
  type: CATALOG_ITEMS_SET_PRODUCT_VARIATIONS,
  payload,
});

const SetCategoriesAction = (payload: Category[]): ItemsActions => ({
  type: CATALOG_ITEMS_SET_CATEGORIES,
  payload,
});

export const SetItemsOrCategoriesSearchAction = (payload: SearchItemOrCategory): ItemsActions => ({
  type: CATALOG_ITEMS_SEARCH,
  payload,
});

const SetLoadingAction = (payload: boolean): ItemsActions => ({
  type: CATALOG_ITEMS_SET_LOADING,
  payload,
});

const SetCurrentItemAction = (payload: ProductItem): ItemsActions => ({
  type: CATALOG_ITEMS_SET_CURRENT_PRODUCT,
  payload,
});

const SetCurrentItemLoadingAction = (payload: boolean): ItemsActions => ({
  type: CATALOG_ITEMS_SET_CURRENT_PRODUCT_LOADING,
  payload,
});

const SetCurrentItemErrorAction = (payload: boolean): ItemsActions => ({
  type: CATALOG_ITEMS_SET_CURRENT_PRODUCT_ERROR,
  payload,
});

const SetSummaryAction = (payload: SummaryItems): ItemsActions => ({
  type: CATALOG_ITEMS_SET_SUMMARY,
  payload,
});

export const SetUnitsAction = (payload: Unit[]): ItemsActions => ({
  type: CATALOG_ITEMS_SET_UNITS,
  payload,
});

export const CatalogItemsSetLocationsAction = (payload: SalesChannel[]): ItemsActions => ({
  type: CATALOG_ITEMS_SET_LOCATIONS,
  payload,
});

export const getProducts = (
  organisationId: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(SetLoadingAction(true));
    const data = await ApiOrgId<ProductItem[]>('/items?include=variations&page=0', organisationId);
    dispatch(CatalogItemsIsNextPageProductsAction(data.length === 10));
    dispatch(CatalogItemsSetProductsAction(data));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(SetLoadingAction(false));
  }
};

export const getMoreProducts = (
  organisationId: string,
  page: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(CatalogItemsSetProductsLoadingAction(true));
    const data = await ApiOrgId<ProductItem[]>(`/items?page=${page}&include=variations`, organisationId);
    dispatch(CatalogItemsIsNextPageProductsAction(data.length === 10));
    dispatch(CatalogItemsSetMoreProductsAction(data));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(CatalogItemsSetProductsLoadingAction(false));
  }
};

export const getProductVariations = (
  organisationId: string,
  product: ProductItem,
): AppThunk => async (dispatch) => {
  try {
    const data = await ApiOrgId<Variation[]>(`/items/${product.id}/variations`, organisationId);

    dispatch(SetProductVariationsAction({
      ...product,
      variations: data,
    }));
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentProductVariation = (
  organisationId: string,
  productId: ProductItem['id'],
): AppThunk => async (dispatch) => {
  dispatch(SetCurrentItemLoadingAction(true));
  try {
    const productData = await ApiOrgId<ProductItem>(`/items/${productId}?include=variations`, organisationId);

    const variations = await ApiOrgId<Variation[]>(`/items/${productId}/variations`, organisationId);
    dispatch(SetCurrentItemAction({
      ...productData,
      variations,
    }));
    dispatch(SetCurrentItemLoadingAction(false));
  } catch (error) {
    console.log(error);
    dispatch(SetCurrentItemLoadingAction(false));
    dispatch(SetCurrentItemErrorAction(true));
  }
};

export const getProductsBySearch = (
  searchData: SearchItemOrCategory,
  searchString: string,
  organisationId: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(SetItemsOrCategoriesSearchAction({
      ...searchData,
      loading: true,
    }));

    const data = await ApiOrgId<ProductItem[]>(`/items?search=${searchString}`, organisationId);

    dispatch(SetItemsOrCategoriesSearchAction({
      ...searchData,
      data,
      loading: false,
    }));
  } catch (error) {
    console.log(error);
    dispatch(SetItemsOrCategoriesSearchAction({
      data: [],
      loading: false,
      error: true,
    }));
  }
};

export const deleteProduct = (
  productId: string,
  organisationId: string,
  notificationMessage: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(SetLoadingAction(true));
    const data = await DeleteOrgId(`/items/${productId}`, organisationId);
    if (data) {
      dispatch(getProducts(organisationId));
      dispatch(setNotificationAction(`${notificationMessage} {delete}`));
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(SetLoadingAction(false));
  }
};

export const deleteVariation = (
  productId: string,
  variationId: string,
  organisationId: string,
  notificationMessage: string,
): AppThunk => async (dispatch) => {
  try {
    const data = await DeleteOrgId(`/items/${productId}/variations/${variationId}`, organisationId);

    if (data) {
      dispatch(getProducts(organisationId));
      dispatch(setNotificationAction(notificationMessage));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getProductsSummary = (organisationId: string): AppThunk => async (dispatch) => {
  try {
    const data: SummaryItems = await ApiOrgId<SummaryItems>(`/summary/items?year=${getYear()}`, organisationId);
    dispatch(SetSummaryAction(data));
  } catch (error) {
    console.log(error);
  }
};

export const getUnits = (organisationId: string): AppThunk => async (dispatch) => {
  try {
    const units: Unit[] = await ApiOrgId<Unit[]>('/units', organisationId);
    dispatch(SetUnitsAction(units));
  } catch (error) {
    console.log(error);
  }
};

export const getLocations = (organisationId: string): AppThunk => async (dispatch) => {
  try {
    const salesChannels: SalesChannel[] = await ApiOrgId<SalesChannel[]>('/locations', organisationId);
    dispatch(CatalogItemsSetLocationsAction(salesChannels));
  } catch (error) {
    console.log(error);
  }
};

export const getMoreVariations = (
  organisationId: string,
  product: ProductItem,
  page: number,
): AppThunk => async (dispatch) => {
  try {
    const data = await ApiOrgId<ProductItem[]>(`/items/${product.id}/variations?page=${page}`, organisationId);
    dispatch(SetCurrentItemAction({
      ...product,
      variations: [...product.variations, ...data],
    }));
  } catch (error) {
    console.log(error);
  }
};

export const getCategories = (organisationId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(SetLoadingAction(true));
    const data = await ApiOrgId<Category[]>('/categories', organisationId);
    dispatch(SetCategoriesAction(data));
  } catch (error) {
    console.log(error);
  }
};

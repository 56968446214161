import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Services
import { auth } from 'services/firebase';
// Actions
import { accountSetErrorAction } from 'store/storefront/account/accountActions';
// Assets
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
// Components
import Modal from 'components/Modal';
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import CustomSpinner from 'components/CustomSpinner';
import FormError from 'components/StorefrontComponents/FormError';
// Styles
import classes from './UpdateEmailModal.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
}

const UpdateEmailModal: React.FC<Props> = ({ active, setActive }) => {
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const { error } = useTypeSelector(({ storefront }) => storefront.account);
  const { checkout, account } = useTranslationsStorefront();
  const dispatch = useDispatch();
  const {
    register, handleSubmit, watch, reset, trigger,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const watchEmail = watch('email');

  const updateEmail = useCallback((data) => {
    auth?.currentUser?.verifyBeforeUpdateEmail(data.newEmail)
      .then(() => {
        auth?.currentUser?.updateEmail(data.newEmail).then(() => {
          setActive();
          reset();
        }).catch((err) => {
          dispatch(accountSetErrorAction(err.code));
        });
      })
      .catch((err) => {
        dispatch(accountSetErrorAction(err.code));
      });
  }, [dispatch, reset, setActive]);

  const validEmail = useCallback(async () => {
    const email = await trigger('email');

    if (email) {
      setIsValidEmail(true);
      setLoading(true);
      if (auth.currentUser?.email === watchEmail) {
        const emailInFirebase = await auth.fetchSignInMethodsForEmail(watchEmail);
        setIsEmail(!!emailInFirebase.length);
      }
      setLoading(false);
    } else {
      setIsValidEmail(false);
    }
  }, [trigger, watchEmail]);

  useEffect(() => {
    if (watchEmail) {
      validEmail();
    }
  }, [validEmail, watchEmail]);

  return (
    <Modal active={active} setActive={setActive} className={classes.update_email}>
      <form onSubmit={handleSubmit(updateEmail)} className={classes.root}>
        <div>
          <HeaderModal onClick={setActive} className={classes.header} />
          <h2 className={classes.title}>
            {account.security.login.update_title}
          </h2>
          <div className={classes.content}>
            <div className={classes.inputs_wrapper}>
              <input
                autoComplete="username"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: checkout.email_input.error,
                  },
                })}
                placeholder={checkout.card_form.update_email.placeholder_curr_email}
                className={classes.input}
              />
              <SuccessIcon className={cn(classes.success, {
                [classes.success_active]: isEmail && !loading && isValidEmail,
              })}
              />
              <FormError
                active={auth.currentUser?.email !== watchEmail && isValidEmail}
                text={checkout.card_form.update_email.error}
              />
              {loading && (
              <div className={classes.spinner_wrapper}>
                <CustomSpinner variant="two" size="small" />
              </div>
              )}
            </div>
            <div className={classes.inputs_wrapper}>
              <input
                {...register('newEmail', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: checkout.email_input.error,
                  },
                })}
                placeholder={checkout.card_form.update_email.placeholder_new_email}
                className={classes.input}
                disabled={!(isEmail && !loading && isValidEmail)}
              />
              <FormError
                active={error === 'auth/email-already-in-use'}
                text={checkout.card_form.email_error}
              />
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <button type="button" onClick={setActive}>
            {checkout.customer_info_modal.cancel_btn}
          </button>
          <button type="submit" disabled={!isEmail || !isValid}>
            {checkout.customer_info_modal.edit.update_btn}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateEmailModal;

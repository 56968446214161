import React, { useEffect, useMemo, useState } from 'react';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useTranslations } from 'hooks/useTranslations';
import { useUser } from 'hooks/useUser';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { getEstimatesData, getReportSelectCards } from 'store/dashboard/report/reportActions';
import CardSelect from 'components/CardSelect';
import { ReactSelectTime } from 'types/globalTypes';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import Card from 'components/Card';
import { getYear } from 'utils/dates';
import { ReportReq } from 'store/dashboard/report/reportTypes';
import StackedGraph from 'components/StackedGraph';
import { capitalizeFirstLetter } from 'utils/strings';
import CustomSpinner from 'components/CustomSpinner';
import MobileUnpaidBillsTab from './MobileTab';
// Styles
import classes from './EstimateTab.module.scss';

export interface AgingBillsData {
  month: string;
  type: '1' | '2';
  value: number;
}

const allKeys = ['1'];

const colors = {
  '1': 'rgb(62, 78, 184)',
  '2': 'rgb(0, 145, 255)',
};

const EstimateTab: React.FC = () => {
  const { isMobile } = useMobileScreen();

  const dispatch = useDispatch();
  const translations = useTranslations();
  const ts = translations.home.report;
  const params: { orgId?: string; reportName?: string } = useParams();
  const [time, setTime] = useState<ReactSelectTime>(ReactSelectTime.yesterday);

  const {
    estimatesChart, reportSelectCards, monthlyAverage, loading,
  } = useTypeSelector(
    ({ dashboard }) => dashboard.report,
  );
  const user = useUser();
  const months = useMemo(() => [
    ts.re_jan,
    ts.re_feb,
    ts.re_mar,
    ts.re_apr,
    ts.re_may,
    ts.re_jun,
    ts.re_jul,
    ts.re_aug,
    ts.re_sep,
    ts.re_oct,
    ts.re_nov,
    ts.re_dec,
  ], [
    ts.re_apr,
    ts.re_aug,
    ts.re_dec,
    ts.re_feb,
    ts.re_jan, ts.re_jul, ts.re_jun, ts.re_mar, ts.re_may, ts.re_nov, ts.re_oct, ts.re_sep]);

  useEffect(() => {
    if (user?.currentOrganisation?.id) {
      dispatch(
        getEstimatesData(
          user.currentOrganisation.id,
          months,
        ),
      );
    }
  }, [dispatch, params.orgId, user?.currentOrganisation?.id, months]);

  useEffect(() => {
    if (!user || !params.orgId || !params?.reportName) {
      return;
    }
    if (!(user?.currentOrganisation?.id)) {
      return;
    }
    dispatch(getReportSelectCards(user.currentOrganisation.id, time, ReportReq.estimates));
  }, [dispatch, params.orgId, params?.reportName, time, user]);

  const getTotal = () => estimatesChart.data.map(
    (item) => item.value,
  ).reduce((a, b) => a + b, 0);

  if (loading) {
    return <CustomSpinner variant="two" />;
  }

  return isMobile ? (
    <MobileUnpaidBillsTab
      total={getTotal()}
      time={time}
      setTime={setTime}
    />
  ) : (
    <>
      <div className={classes.content_container}>
        <div className={classes.chart_container}>
          <div className={classes.chart}>
            <div className={classes.chart_title}>
              <div className={classes.chart_years}>
                {getYear()} {ts.ytd}
              </div>
              <div className={getTotal() ? classes.chart_text : classes.chart_text_nodata}>
                {ts.tabs.re_estimates}
              </div>
            </div>
            {!getTotal() ? (
              <div className={classes.nodata}>
                <div className={classes.lines}>
                  <div className={classes.line} />
                  <div className={classes.line} />
                  <div className={classes.line} />
                </div>
                <p>{ts.no_data_available_yet}</p>
              </div>
            ) : (
              <StackedGraph
                datasets={estimatesChart.data}
                colors={colors}
                keys={allKeys}
                names={{
                  first: capitalizeFirstLetter(ts.estimates_report.sent),
                  second: capitalizeFirstLetter(ts.estimates_report.accepted),
                }}
                isIso
              />
            )}
          </div>
        </div>
        <div className={classes.monthly_average}>
          <CardMonthlyAverage
            title={ts.estimates_report.month}
            value={monthlyAverage?.value}
            change={monthlyAverage?.percentage}
          />
        </div>
        <div className={classes.high}>
          <Card
            data={{
              title: ts.highest_month,
              label: estimatesChart.highestMonth?.month,
              value: estimatesChart.highestMonth?.value,
              second: ts.high,
              change: estimatesChart.highestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.low}>
          <Card
            data={{
              title: ts.lowest_month,
              label: estimatesChart.lowestMonth?.month,
              value: estimatesChart.lowestMonth?.value,
              second: ts.low,
              change: estimatesChart.lowestMonth?.percentage,
            }}
          />
        </div>
        <div className={classes.total}>
          <CardSelect
            data={{
              title: ts.all,
              value: reportSelectCards?.all?.entrytotal,
              label: `${reportSelectCards?.all?.count
                ? `${reportSelectCards?.all?.count} ${ts.tabs.re_estimates}`
                : ''}`,
              change: reportSelectCards?.all?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        <div className={classes.paid}>
          <CardSelect
            data={{
              title: ts.estimates_report.accepted,
              value: reportSelectCards?.accepted?.entrytotal,
              label: `${reportSelectCards?.accepted?.count
                ? `${reportSelectCards?.accepted?.count} ${ts.tabs.re_estimates}`
                : ''}`,
              change: reportSelectCards?.accepted?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
        <div className={classes.unpaid}>
          <CardSelect
            data={{
              title: ts.estimates_report.sent,
              value: reportSelectCards?.sent?.entrytotal,
              label: `${reportSelectCards?.sent?.count
                ? `${reportSelectCards?.sent?.count} ${ts.tabs.re_estimates}`
                : ''}`,
              change: reportSelectCards?.sent?.change || 0,
            }}
            time={time}
            setTime={setTime}
          />
        </div>
      </div>
    </>
  );
};

export default EstimateTab;

import React from 'react';
import { useTabs } from 'hooks/useTabs';
// Components
import DashboardLayout from 'layouts/DashboardLayout';
import HomeTabs from './HomeTabs/HomeTabs';
// Styles
import classes from './Home.module.scss';

const Home: React.FC = () => {
  const tabs = useTabs();
  return (
    <DashboardLayout>
      <div className={classes.home_wrapper}>
        <HomeTabs tabs={tabs} />
      </div>
    </DashboardLayout>
  );
};

export default Home;

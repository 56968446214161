import React, { useMemo, useRef } from 'react';
import Slider from 'react-slick';
import { useMobileScreen } from 'hooks/useMobileScreen';
// Types
import { SummaryItems } from 'store/dashboard/catalog/items/itemsTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Components
import DonutChartCard from 'components/DashboardComponents/DonutChartCard';
// Styles
import classes from './ItemsSummary.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2.2,
  slidesToScroll: 1,
};

interface Props {
  summary: SummaryItems
  translations: TranslationDashboardModel['catalog']['summary']
}

const ItemsSummary: React.FC<Props> = ({ summary, translations }) => {
  const sliderRef = useRef<Slider>(null);
  const { isMobile } = useMobileScreen();
  const itemsSummary = useMemo(() => [{
    name: translations.items,
    percent: 100,
    total: summary.items,
    data: {
      subtitle: translations.categories,
      value: summary.categories,
      format: '.2s',
    },
  },
  {
    name: translations.stock,
    percent: 100,
    total: summary.inStock,
    data: {
      subtitle: translations.low_stock,
      value: summary.lowStock,
      format: '.2s',
    },
  },
  {
    name: translations.locations,
    percent: 100,
    total: summary.salesChannels,
    data: {
      subtitle: translations.activated,
      value: `${summary.enabledSalesChannels} / ${summary.salesChannels}`,
      format: ' ',
    },
  },
  ], [summary, translations]);

  return (isMobile ? (
    <div className={classes.slider}>
      <Slider {...settings} ref={sliderRef}>
        {itemsSummary.map((item) => (
          <DonutChartCard
            key={item.name}
            title={item.name}
            percentage={item.percent}
            value={item.total}
            type="two"
            data={item.data}
          />
        ))}
      </Slider>
    </div>
  ) : (
    <div className={classes.table_summary}>
      {itemsSummary?.map((item) => (
        <DonutChartCard
          key={item.name}
          title={item.name}
          percentage={item.percent}
          value={item.total}
          type="two"
          data={item.data}
        />
      ))}
    </div>
  )
  );
};

export default ItemsSummary;

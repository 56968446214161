import React, { useEffect, useState } from 'react';
// Paypal
import {
  PayPalScriptProvider,
  PayPalButtons,
} from '@paypal/react-paypal-js';
import { useLocation, useParams } from 'react-router-dom';
// Constants
import { supportCurrencyPayPal } from 'constants/supportCurrencyPayPal';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Actions
import { createPayPalOrder, onApprovePayPalOrder } from 'store/invoice/invoiceActions';
// Types
import { InvoicePaymentLoadingEnum } from 'store/invoice/invoiceTypes';
// Styles
import classes from './PayPal.module.scss';

const style = { layout: 'horizontal', color: 'gold' };

interface Props {
  total: number
  handleSuccess: () => void;
  handleLoading: (loading: InvoicePaymentLoadingEnum) => void;
  handleError: (error: unknown) => void;
}

const PayPal: React.FC<Props> = ({
  total, handleLoading, handleSuccess, handleError,
}) => {
  const translations = useTranslationsStorefront();
  const location = useLocation();
  const { data: invoice } = useTypeSelector((state) => state.invoice);

  const [approvePayPal, setApprovePayPal] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();
  const currencyIso = invoice?.location?.address.currencyISO || 'USD';
  const availablePayPal = supportCurrencyPayPal.includes(currencyIso);

  useEffect(() => {
    if (approvePayPal) {
      handleSuccess();
      setApprovePayPal(false);
    }
  }, [approvePayPal, handleSuccess, id, location]);

  if (!invoice?.location) {
    return null;
  }

  return (
    <div className={classes.paypal}>
      <PayPalScriptProvider
        options={{
          'client-id': `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
          components: 'buttons',
          currency: !availablePayPal ? 'USD' : currencyIso,
        }}
      >
        <PayPalButtons
          // @ts-ignore
          style={style}
          disabled={!availablePayPal}
          forceReRender={[total, currencyIso, style]}
          fundingSource={undefined}
          createOrder={() => {
            handleLoading(InvoicePaymentLoadingEnum.PAYPAL);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return createPayPalOrder(total, id).then((res: any) => (res.id));
          }}
          onError={() => {
            handleError('PayPal Error');
          }}
          onCancel={() => {
            handleLoading(InvoicePaymentLoadingEnum.END);
          }}
          onApprove={async (data) => {
            onApprovePayPalOrder(total, data, id);
            setApprovePayPal(true);
          }}
        />
      </PayPalScriptProvider>
      {!availablePayPal && (
        <p className={classes.error}>
          {translations.checkout.errors.pay_pal_not_supported}
        </p>
      )}
    </div>
  );
};

export default PayPal;

import { useTranslations } from 'hooks/useTranslations';
import React from 'react';
import Card from 'components/Card';
import { useTypeSelector } from 'hooks/useTypeSelector';
import CardSelect from 'components/CardSelect/CardSelect';
import { ReactSelectTime } from 'types/globalTypes';
import CardMonthlyAverage from 'components/CardMontlyAverage';
import { getYear } from 'utils/dates';
import StackedGraph from 'components/StackedGraph';
import { capitalizeFirstLetter } from 'utils/strings';
// Styles
import classes from './MobileQuoteTab.module.scss';

interface Props {
  total: number;
  time: ReactSelectTime;
  setTime: React.Dispatch<React.SetStateAction<ReactSelectTime>>
}

const allKeys = ['1'];

const colors = {
  1: 'rgb(234, 144, 122)',
  2: 'rgb(145, 112, 17)',
};

const MobileQuoteTab: React.FC<Props> = ({ total, time, setTime }) => {
  const translations = useTranslations();
  const ts = translations.home.report;
  const {
    reportSelectCards,
    quotesChart,
    monthlyAverage,
  } = useTypeSelector(({ dashboard }) => dashboard.report);

  return (
    <div className={classes.content_container}>
      <div className={classes.chart_container}>
        <div className={classes.chart}>
          <div className={classes.chart_title}>
            {!total ? (
              <div className={classes.chart_years}>
                {`${getYear()} ${ts.year_to_date}`}
              </div>
            ) : (
              <div className={classes.chart_years}>
                {`${getYear()} ${ts.ytd}`}
              </div>
            )}
            <div className={classes.chart_text}>
              {ts.tabs.re_quotes}
            </div>
          </div>
          {!total ? (
            <div className={classes.nodata}>
              <div className={classes.lines}>
                <div className={classes.line} />
                <div className={classes.line} />
                <div className={classes.line} />
              </div>
              <p>{ts.no_data_available_yet}</p>
            </div>
          ) : (
            <StackedGraph
              datasets={quotesChart.data}
              colors={colors}
              keys={allKeys}
              names={{
                first: capitalizeFirstLetter(ts.quotes.requested),
                second: capitalizeFirstLetter(ts.quotes.responded),
              }}
            />
          )}
        </div>
      </div>
      <div className={classes.monthly_average}>
        <CardMonthlyAverage
          title={ts.quotes.month}
          value={monthlyAverage?.value}
          change={monthlyAverage?.percentage}
          unit={ts.requests}
        />
      </div>
      <div className={classes.high}>
        <Card
          data={{
            title: ts.highest_month,
            label: quotesChart.highestMonth?.month,
            value: quotesChart.highestMonth?.value,
            second: ts.high,
            change: quotesChart.highestMonth?.percentage,
          }}
          unit={ts.requests}
        />
      </div>
      <div className={classes.low}>
        <Card
          data={{
            title: ts.lowest_month,
            label: quotesChart.lowestMonth?.month,
            value: quotesChart.lowestMonth?.value,
            second: ts.low,
            change: quotesChart.lowestMonth?.percentage,
          }}
          unit={ts.requests}
        />
      </div>
      <div className={classes.total}>
        <CardSelect
          data={{
            title: ts.all,
            value: reportSelectCards?.all?.entrytotal,
            label: `${reportSelectCards?.all?.count
              ? `${reportSelectCards?.all?.count} ${ts.requests}`
              : ''}`,
            change: reportSelectCards?.all?.change || 0,
          }}
          time={time}
          setTime={setTime}
          unit={ts.requests.toLowerCase()}
        />
      </div>
      <div className={classes.paid}>
        <CardSelect
          data={{
            title: ts.quotes.responded,
            value: reportSelectCards?.responded?.entrytotal,
            label: `${reportSelectCards?.responded?.count
              ? `${reportSelectCards?.responded?.count} ${ts.requests}`
              : ''}`,
            change: reportSelectCards?.responded?.change || 0,
          }}
          time={time}
          setTime={setTime}
        />
      </div>
      <div className={classes.unpaid}>
        <CardSelect
          data={{
            title: ts.quotes.requested,
            value: reportSelectCards?.sent?.entrytotal,
            label: `${reportSelectCards?.sent?.count
              ? `${reportSelectCards?.sent?.count} ${ts.requests}`
              : ''}`,
            change: reportSelectCards?.sent?.change || 0,
          }}
          time={time}
          setTime={setTime}
          unit={ts.requests.toLowerCase()}
        />
      </div>
    </div>
  );
};

export default MobileQuoteTab;

/* eslint-disable indent */
import React, {
  useEffect, useMemo, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { directoryGetChartData } from 'store/dashboard/directory/directoryActions';
// Types
import { ChartsData, DirectoryType } from 'store/dashboard/directory/directoryTypes';
// Hooks
import { useTranslations } from 'hooks/useTranslations';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useUser } from 'hooks/useUser';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Components
import Card from 'components/Card';
import Slider from 'react-slick';
import CustomSpinner from 'components/CustomSpinner';
import Chart from './Chart';
// Styles
import classes from './DirectorySummary.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Props {
  type: DirectoryType;
  contactId: number;
}

const DirectorySummary: React.FC<Props> = ({
  type, contactId,
}) => {
  const sliderRef = useRef<Slider>(null);
  const { isDesktop, isMobile, isTablet } = useMobileScreen();
  const user = useUser();
  const { details, loading } = useTypeSelector(({ directory }) => directory);
  const dispatch = useDispatch();
  const summary = details.summary[type as keyof ChartsData];
  const translations = useTranslations();
  const ts = translations.home.report;

  const settings = useMemo(() => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isTablet ? 2.5 : 3,
    slidesToScroll: 1,
  }), [isTablet]);

  const months = useMemo(
    () => [
      ts.re_jan,
      ts.re_feb,
      ts.re_mar,
      ts.re_apr,
      ts.re_may,
      ts.re_jun,
      ts.re_jul,
      ts.re_aug,
      ts.re_sep,
      ts.re_oct,
      ts.re_nov,
      ts.re_dec,
    ],
    [
      ts.re_apr,
      ts.re_aug,
      ts.re_dec,
      ts.re_feb,
      ts.re_jan,
      ts.re_jul,
      ts.re_jun,
      ts.re_mar,
      ts.re_may, ts.re_nov, ts.re_oct, ts.re_sep,
    ],
  );

  useEffect(() => {
    if (!user?.currentOrganisation?.id || !months || !type) {
      return;
    }

    dispatch(
      directoryGetChartData(user?.currentOrganisation?.id, type, contactId, months),
    );
  }, [months, type, user?.currentOrganisation?.id, contactId, dispatch]);

  if (loading === 'chartData') {
    return (
      <div className={classes.loading}>
        <CustomSpinner variant="two" />
      </div>
    );
  }

  return (
    <div className={classes.directoryDetails}>
      <div className={classes.chart_wrapper}>
        <Chart type={type} />
        {(!isDesktop || isMobile)
          && (
            <>
              <div className={classes.card_highest_month}>
                <Card
                  type="two"
                  data={{
                    title: details.type === DirectoryType.both
                      ? ts.highest_month_type.replace('type', type === DirectoryType.customer
                        ? ts.income_statement.sales.toLocaleUpperCase()
                        : ts.income_statement.expenses.toLocaleUpperCase())
                      : ts.highest_month,
                    label: summary?.highestMonth?.month,
                    value: summary?.highestMonth?.value,
                    second: ts.high,
                    change: summary?.highestMonth?.percentage,
                  }}
                />
              </div>
              <div className={classes.card_lowest_month}>
                <Card
                  type="two"
                  data={{
                    title: details.type === DirectoryType.both
                      ? ts.lowest_month_type.replace('type', type === DirectoryType.customer
                        ? ts.income_statement.sales.toLocaleUpperCase()
                        : ts.income_statement.expenses.toLocaleUpperCase())
                      : ts.lowest_month,
                    label: summary?.lowestMonth?.month,
                    value: summary?.lowestMonth?.value,
                    second: ts.low,
                    change: summary?.lowestMonth?.percentage,
                  }}
                />
              </div>
              <div className={classes.card_before_thirty_days}>
                <Card
                  type="two"
                  data={{
                    title: ts.unpaid_invoices_report.past_due_first,
                    label: type === DirectoryType.customer
                      ? ts.tabs.re_aging_invoice
                      : ts.bills,
                    value: type === DirectoryType.customer ? details.averageMonth?.agingInvoices?.['1-30'].value : details.averageMonth?.agingBills?.['1-30'].value,
                    second: ts.high,
                    change: type === DirectoryType.customer ? details.averageMonth?.agingInvoices?.['1-30'].change : details.averageMonth?.agingBills?.['1-30'].change,
                  }}
                />
              </div>
              <div className={classes.card_after_thirty_days}>
                <Card
                  type="two"
                  data={{
                    title: ts.unpaid_invoices_report.past_due_second,
                    label: type === DirectoryType.customer
                      ? ts.tabs.re_aging_invoice
                      : ts.bills,
                    value: type === DirectoryType.customer ? details.averageMonth?.agingInvoices?.['30+'].value : details.averageMonth?.agingBills?.['30+'].value,
                    second: ts.high,
                    change: type === DirectoryType.customer ? details.averageMonth?.agingInvoices?.['30+'].change : details.averageMonth?.agingBills?.['30+'].change,
                  }}
                />
              </div>
            </>
          )}
      </div>
      {isDesktop && !isMobile && (
        <Slider {...settings} ref={sliderRef}>
          <div className={classes.card_highest_month}>
            <Card
              type="two"
              data={{
                title: ts.highest_month,
                label: summary?.highestMonth?.month,
                value: summary?.highestMonth?.value,
                second: ts.high,
                change: summary?.highestMonth?.percentage,
              }}
            />
          </div>
          <div className={classes.card_lowest_month}>
            <Card
              type="two"
              data={{
                title: ts.lowest_month,
                label: summary?.lowestMonth?.month,
                value: summary?.lowestMonth?.value,
                second: ts.low,
                change: summary?.lowestMonth?.percentage,
              }}
            />
          </div>
          <div className={classes.card_before_thirty_days}>
            <Card
              type="two"
              data={{
                title: ts.unpaid_invoices_report.past_due_first,
                label: type === DirectoryType.customer
                  ? ts.tabs.re_aging_invoice
                  : ts.bills,
                value: type === DirectoryType.customer ? details.averageMonth?.agingInvoices?.['1-30'].value : details.averageMonth?.agingBills?.['1-30'].value,
                second: ts.high,
                change: type === DirectoryType.customer ? details.averageMonth?.agingInvoices?.['1-30'].change : details.averageMonth?.agingBills?.['1-30'].change,
              }}
            />
          </div>
          <div className={classes.card_after_thirty_days}>
            <Card
              type="two"
              data={{
                title: ts.unpaid_invoices_report.past_due_second,
                label: type === DirectoryType.customer
                  ? ts.tabs.re_aging_invoice
                  : ts.bills,
                value: type === DirectoryType.customer ? details.averageMonth?.agingInvoices?.['30+'].value : details.averageMonth?.agingBills?.['30+'].value,
                second: ts.high,
                change: type === DirectoryType.customer ? details.averageMonth?.agingInvoices?.['30+'].change : details.averageMonth?.agingBills?.['30+'].change,
              }}
            />
          </div>
        </Slider>
      )}
    </div>
  );
};

export default DirectorySummary;

import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Styles
import classes from './Giropay.module.scss';

interface Props {
  register: UseFormReturn['register']
}

const Giropay: React.FC<Props> = ({ register }) => {
  const { checkout } = useTranslationsStorefront();

  return (
    <>
      <label
        htmlFor="fullName"
        className={classes.input}
      >
        <span>
          {checkout.card_form.name_label}
        </span>
        <input
          {...register('fullName', { required: true })}
        />
      </label>

      <div className={classes.description}>
        {checkout.description_bank_redirect_pay}
      </div>
    </>
  );
};

export default Giropay;

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { ITradingHours } from 'store/storefront/shop/shopTypes';

export const getDayName = (dayNumber: number): string => dayjs().day(dayNumber).format('ddd');

export const getWeekTradingHours = (tradingHours: ITradingHours[] | undefined): ITradingHours[] => {
  const week = [1, 2, 3, 4, 5, 6, 7];

  return week.map((day) => {
    const existTradingHours = tradingHours?.find((item) => item.day === day);

    if (existTradingHours) {
      return existTradingHours;
    }

    return {
      day,
      closed: true,
    };
  });
};

export const dateToIso = (date?: Date | string | number): string => {
  if (!date) {
    return dayjs().toISOString();
  }

  return dayjs(date).toISOString();
};

export const dateToFormat = (date: Date | string | number | undefined, format = 'MMMM DD YYYY HH:mm'): string => {
  dayjs.extend(localizedFormat);

  const currentDate = dayjs(date);
  return dayjs(currentDate).format(format);
};

export const dayToExpired = (
  date: Date | string | undefined,
  endDate: Date | string | undefined,
): number => dayjs(date).diff(dayjs(endDate), 'day');

export const dateFormatA = (date: Date | string | number | undefined): string => dateToFormat(date, 'DD MMM YYYY hh:mm A');

export const formatReg = (date: Date | string | number | undefined): string => dateToFormat(date, 'DD MMM YYYY');

export const dateToDue = (daysToDue: number | undefined, format = 'll'): string | undefined => {
  dayjs.extend(localizedFormat);

  if (daysToDue) {
    const today = new Date();
    today.setDate(today.getDate() + daysToDue);
    return dayjs(today).format(format);
  }

  return undefined;
};

export const getDate = (type: string): { startDate: string, endDate: string } | null => {
  if (type === 'yesterday') {
    const date = (dayjs().valueOf() - 1000 * 60 * 60 * 24);
    const prev = dayjs(date).toISOString();
    return {
      startDate: prev,
      endDate: dayjs().toISOString(),
    };
  }
  if (type === 'lastWeek') {
    const date = (dayjs().valueOf() - 1000 * 60 * 60 * 24 * 7);
    const prev = dayjs(date).toISOString();
    return {
      startDate: prev,
      endDate: dayjs().toISOString(),
    };
  }
  if (type === 'lastMonth') {
    const prevMonth = dayjs().month(dayjs().get('month') - 1).toISOString();
    return {
      startDate: prevMonth,
      endDate: dayjs().toISOString(),
    };
  }
  if (type === 'lastYear') {
    const prev = dayjs().year(dayjs().get('year') - 1).toISOString();
    return {
      startDate: prev,
      endDate: dayjs().toISOString(),
    };
  }
  return null;
};

export const sortFunction = (a:
  {
    date: dayjs.ConfigType
  }, b: {
    date: dayjs.ConfigType
  }): number => {
  const dateA = dayjs(a.date).valueOf();
  const dateB = dayjs(b.date).valueOf();
  return dateA > dateB ? 1 : -1;
};

export const getYear = (): number => dayjs().year();

export const dateFromNow = (translations: { [key: string]: string }, time: string): string => {
  const config = {
    thresholds: [
      { l: 's', r: 1 },
      { l: 'ss', r: 59, d: 'second' },
      { l: 'm', r: 1 },
      { l: 'mm', r: 59, d: 'minute' },
      { l: 'h', r: 1 },
      { l: 'hh', r: 23, d: 'hour' },
      { l: 'd', r: 1 },
      { l: 'dd', r: 6, d: 'day' },
      { l: 'w', r: 1 },
      { l: 'ww', r: 3, d: 'week' },
      { l: 'M', r: 1 },
      { l: 'MM', r: 11, d: 'month' },
      { l: 'y' },
      { l: 'yy', d: 'year' }],
  };

  dayjs.extend(relativeTime, config);
  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', {
    relativeTime: translations,
  });

  return dayjs(time).fromNow();
};

import {
  PAYMENT_LINK_LOADING,
  PAYMENT_LINK_GET_DATA,
  PaymentLinkState,
  PaymentLinkActions,
  PAYMENT_LINK_SET_PAYMENT,
} from './paymentLinkTypes';

const initialState: PaymentLinkState = {
  loading: false,
  payment: {
    loading: false,
    success: false,
    cancel: {
      loading: false,
      canceled: false,
    },
  },
};

export const PaymentLinkReducer = (
  state: PaymentLinkState = initialState,
  action: PaymentLinkActions,
): PaymentLinkState => {
  switch (action.type) {
    case PAYMENT_LINK_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PAYMENT_LINK_GET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case PAYMENT_LINK_SET_PAYMENT:
      return {
        ...state,
        payment: action.payload,
      };
    default:
      return state;
  }
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Action
import { setNotificationAction } from 'store/dashboard/app/appActions';
// Images
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// Styles
import classes from './Notification.module.scss';

interface Props {
  active: boolean;
  handleClose: () => void;
  text: string;
  variant?: 'one' | 'two';
  onClick?: () => void;
  textBtn?: string;
}

const Notification: React.FC<Props> = ({
  text, active, handleClose, variant, onClick, textBtn: text_btn,
}) => {
  const dispatch = useDispatch();
  const showHideClassName = active ? classes.modal_show : classes.modal_hide;

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timer: NodeJS.Timeout;

    if (active) {
      timer = setTimeout(() => {
        dispatch(setNotificationAction(null));
      }, 3000);
    }

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  if (variant === 'two') {
    return (
      <div className={active ? classes.modal_two_show : classes.modal_two_hide}>
        <div
          className={active ? classes.wrapper_two_active : classes.wrapper_two}
          tabIndex={0}
          role="button"
          onClick={(e) => e.stopPropagation()}
        >
          <div className={classes.subtitle}>
            <p>
              {text}
            </p>
            <button
              className={classes.subtitle_btn}
              type="button"
              onClick={onClick}
            >
              {text_btn}
            </button>
          </div>
          <div
            aria-hidden="true"
            onClick={handleClose}
            className={classes.close}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={showHideClassName}>
      <div
        className={active ? classes.wrapper_active : classes.wrapper}
        tabIndex={0}
        role="button"
        onClick={(e) => e.stopPropagation()}
      >
        <p className={classes.subtitle}>
          {text}
        </p>
        <div
          aria-hidden="true"
          onClick={handleClose}
          className={classes.close}
        >
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default Notification;
